import { Box, Flex } from 'components/layout';
import {
  MSCProMunicipalities,
  MSCProMunicipalitiesTitles,
} from 'constants/msc-pro-municipalities';
import { useGlobalContext } from 'contexts/global';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Image, Modal } from 'components/elements';
import { css } from '@emotion/react';
import theme from 'theme';
import Footer from 'components/footer';
import { Text } from 'components/typography';
import Lottie from 'react-lottie';
import animationData from './animation.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const MunicipalityWrapperPage = ({
  children,
  title,
  backLink,
  hideFooter,
}: {
  children: ReactNode;
  title?: string;
  backLink?: string;
  hideFooter?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cityId } = useParams();

  const {
    globalState: { city, user },
    setShowLoginModal,
  } = useGlobalContext();
  const bannerUrl = `https://za2.forcelink.net/forcelink/images/custom/city_background.png?subscriberId=${city?.attributes.subscriberId}`;
  const [showServiceUnavailableModal, setShowServiceUnavailableModal] =
    useState(false);

  const handleCloseModal = () => {
    setShowServiceUnavailableModal(false);
    navigate('/');
  };

  useEffect(() => {
    if (city.value !== 0 && city.value.toString() !== cityId) {
      if (MSCProMunicipalities.includes(city.value)) {
        navigate(`/my-municipality/services/${city.value}`);
      } else {
        setShowServiceUnavailableModal(true);
      }
    }
  }, [cityId, city?.value]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    // eslint-disable-next-line prefer-const
    timeoutId = setTimeout(() => {
      if (
        user.uuid.localeCompare('false') === 0 ||
        user.uuid.localeCompare('init') === 0
      ) {
        setShowLoginModal(true);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [user.uuid]);

  return (
    <>
      <Modal
        isOpen={showServiceUnavailableModal}
        onClose={handleCloseModal}
        title="Service Not Available"
      >
        <Flex maxWidth="540px" mt="10px" flexDirection="column">
          <Text textAlign="center">
            This service is not available in your municipality.
          </Text>
          <Box>
            <Lottie options={defaultOptions} height={250} width={250} />
          </Box>
          <Text textAlign="center" mb="20px">
            Municipal Services are currently only available in:
            {MSCProMunicipalitiesTitles.map((m, i) => (
              <Text key={i} fontWeight={600}>
                - {m}
              </Text>
            ))}
            .
          </Text>
          <Button
            variant="cta"
            onClick={handleCloseModal}
            width="100%"
            mt="auto"
          >
            I Understand
          </Button>
        </Flex>
      </Modal>
      <Flex
        backgroundColor={theme.colors.backgroundGrey}
        width="100%"
        justifyContent="center"
        flexDirection="column"
      >
        <Flex
          flexDirection="column"
          p="20px"
          width="100%"
          backgroundColor="white"
          maxWidth="1200px"
          mx="auto"
          my="1%"
          borderRadius="4px"
          minHeight="400px"
          mobileCss={css`
            margin: 0;
            border-radius: 0;
            width: 100vw;
            padding: 10px;
          `}
        >
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            overrideCss={css`
              background-image: url(${bannerUrl});
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              height: 9vw;
            `}
            mobileCss={css`
              height: 15vh;
            `}
          >
            <Text
              textAlign="center"
              fontSize="50px"
              fontWeight="bold"
              color="white"
              mobileCss={css`
                font-size: 30px;
              `}
              overrideCss={css`
                text-shadow: 0px 0px 5px black;
              `}
            >
              {city.label}
            </Text>
          </Flex>
          <Button
            position="relative"
            variant="cta"
            width="100%"
            mt="10px"
            borderRadius={0}
            onClick={() =>
              navigate(backLink || `/my-municipality/services/${cityId}`)
            }
          >
            {!location.pathname.includes('services') && (
              <Image
                position="absolute"
                left="10px"
                source="icons/back-arrow-white.svg"
                alt="back"
                height="20px"
              />
            )}

            <Text> {title || `${city.label} Municipality`}</Text>
          </Button>
          <Flex flexDirection="column" mt="10px">
            {children}
          </Flex>
        </Flex>
      </Flex>
      {!hideFooter && <Footer />}
    </>
  );
};

export default MunicipalityWrapperPage;
