/* eslint-disable arrow-body-style */
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Title } from 'components/social/text';
import { Container, Divider, PageLink } from './breadcrumbs.styled';

const CRUMB_NAMES = {
  social: 'Social Feed',
  connections: 'Connections',
  profile: 'Profile',
  alerts: 'Alerts',
  post: 'Post',
  alert: 'Alert',
};

const getPathStr = (arr, index) => `/${arr.slice(0, index + 1).join('/')}`;

const getCrumbStr = (crumb) => CRUMB_NAMES[crumb] || crumb;

const Breadcrumbs = ({ userName }) => {
  const path = useLocation().pathname.replace(/^\/?|\/?$/g, '');
  let crumbs = path.split('/');

  // Remove uuid from breadcrumbs
  if (crumbs.includes('alert')) {
    crumbs = crumbs.splice(0, crumbs.length - 2);
  }

  // Remove post id and city from breadcrumbs
  if (crumbs.includes('post')) {
    crumbs = crumbs.splice(0, crumbs.length - 2);
  }

  // Replace user uuid with username on breadcrumbs
  if (userName && crumbs[crumbs.length - 1] !== 'profile') {
    crumbs[crumbs.length - 1] = userName;
  }

  return (
    <Container>
      <PageLink to="/">Home</PageLink>
      <Divider src="social/breadcrumb-icon.svg" alt="arrow" />
      {crumbs.map((crumb, index) => (
        index !== (crumbs.length - 1)
          ? (
            <>
              <PageLink
                to={getPathStr(crumbs, index)}
              >
                {getCrumbStr(crumb)}
              </PageLink>
              <Divider src="social/breadcrumb-icon.svg" alt="arrow" />
            </>
          )
          : (
            <Title>
              {getCrumbStr(crumb, index)}
            </Title>
          )
      ))}
    </Container>
  );
};

export default Breadcrumbs;
