import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Loader } from 'components/social/createpost';
import { useInTheCityContext } from 'contexts/inthecity';
import { getPlaces } from 'interface/inthecity/foursquare';
import { useGlobalContext } from 'contexts/global';
import { Search, CardsViewer, Breadcrumbs, Tabs } from 'components/inthecity';
import {
  TopRow,
  Title,
  ContentContainer,
  SearchContainer,
  // TabsContainer,
} from './placesOfInterest.styled';
import { PageLine } from './inthecity.styled';

/**
 * Places of Interest Page
 */
const PlacesOfInterestPage = () => {
  // context
  const { globalState } = useGlobalContext();
  const { places, selectedPOICategory, updateState } = useInTheCityContext();

  // state
  const [tab, setTab] = useState({
    id: 0,
    label: 'All',
    link: '/in-the-city/whats-on-in-your-city',
  });
  const [tabLinks, setTabLinks] = useState(null);
  const [distancePlaces, setDistancePlaces] = useState(null);
  const [popularityPlaces, setPopularityPlaces] = useState(null);
  const [ratingPlaces, setRatingPlaces] = useState(null);
  const [searchSuggestions, setSearchSuggestions] = useState(null);

  // Filter places and put into separate states
  const populateFilteredPlaces = (placeArray) => {
    if (placeArray && placeArray.length > 0) {
      // places are sorted by distance on default and is stored in distancePlaces
      setPopularityPlaces(
        _.sortBy(placeArray, (place) => place.popularity, 'desc')
      );
      setRatingPlaces(_.sortBy(placeArray, (place) => place.rating));
    } else {
      setPopularityPlaces([]);
      setRatingPlaces([]);
    }
  };

  // Get tab links & search suggestions from popular places' categories
  const populateTabLinksSearchSuggestions = () => {
    if (places && places.length > 0) {
      const popularPlaces = _.sortBy(
        places,
        (place) => place.popularity,
        'desc'
      );
      const suggestions = [];
      popularPlaces.forEach((event) => {
        suggestions.push(event.name);
      });
      setSearchSuggestions(suggestions.slice(-5));
    }
  };

  const filterCat = (placest) => {
    console.log('filterCat');
    const filteredPlaces = placest?.filter((place) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const cat of place.categories) {
        // console.log('TAB ess', tab?.label, cat.name, tab?.label === cat.name);
        if (tab.id < 1000) {
          if (tab.id === Math.round(cat.id / 1000)) {
            return true;
          }
        } else if (tab.id === cat.id) {
          return true;
        }
      }
      return false;
    });
    console.log(filteredPlaces);
    return filteredPlaces;
  };

  // filter places by category if tab is selected
  useEffect(() => {
    console.log('useEffect() tab', tab);
    if (tab.id === 0) {
      setDistancePlaces(places);
      populateFilteredPlaces(places);
    } else {
      console.log('TAB es', tab);
      const filteredPlaces = filterCat(places);
      console.log(filteredPlaces);
      setDistancePlaces(filteredPlaces);
      populateFilteredPlaces(filteredPlaces);
    }
    populateTabLinksSearchSuggestions();
  }, [tab]);

  useEffect(() => {
    const fetchData = async () => {
      // get places if no places data
      if (!places || places.length === 0) {
        const { results } = await getPlaces(
          `${globalState.location.latitude},${globalState.location.longitude}`
        );
        // update context state
        updateState({ places: results });
        setTab({
          id: 0,
          label: 'All',
          link: '/in-the-city/whats-on-in-your-city',
        });
      } else {
        // Populate the tabs
        const tabs = [tab];
        if (selectedPOICategory !== -1) {
          tabs.push(selectedPOICategory);
          setTab(selectedPOICategory);
        }

        const popPlaces = _.sortBy(places, (place) => place.popularity, 'desc');
        popPlaces.forEach((placeT) => {
          placeT.categories?.forEach((cat) => {
            let flag = true;
            for (let i = 0; i < tabs.length; i += 1) {
              if (tabs[i].id === cat.id) {
                flag = false;
                break;
              }
            }
            if (flag) {
              tabs.push({
                id: cat.id,
                label: cat.name,
                link: '/in-the-city/places-of-interest',
              });
            }
          });
        });
        setTabLinks(tabs);
        if (tab.id === 0) {
          setDistancePlaces(places);
          populateFilteredPlaces(places);
        } else {
          const filteredPlaces = filterCat(places);
          setDistancePlaces(filteredPlaces);
          populateFilteredPlaces(filteredPlaces);
        }
        populateTabLinksSearchSuggestions();
      }
    };
    if (
      globalState.location.latitude !== 0 &&
      globalState.location.longitude !== 0
    ) {
      fetchData();
    }
  }, [globalState.location.latitude, globalState.location.longitude]);

  useEffect(() => {
    // Populate the tabs
    const tabs = [tab];
    if (selectedPOICategory !== -1) {
      tabs.push(selectedPOICategory);
      setTab(selectedPOICategory);
    }

    const popPlaces = _.sortBy(places, (place) => place.popularity, 'desc');
    popPlaces.forEach((placeT) => {
      placeT.categories?.forEach((cat) => {
        let flag = true;
        for (let i = 0; i < tabs.length; i += 1) {
          if (tabs[i].id === cat.id) {
            flag = false;
          }
        }
        if (flag) {
          tabs.push({
            id: cat.id,
            label: cat.name,
            link: '/in-the-city/places-of-interest',
          });
        }
      });
    });
    setTabLinks(tabs);
  }, [places]);

  console.log('RENDER:', distancePlaces);
  return (
    <>
      <TopRow>
        <Breadcrumbs />
      </TopRow>

      <Title>Places of Interest</Title>

      <Tabs links={tabLinks || []} currentTab={tab} setTab={(s) => setTab(s)} />

      {/* <TabsContainer>
      </TabsContainer> */}

      <PageLine />

      <SearchContainer>
        <Search
          placeholder="Search places of interests"
          variation="dark"
          searchType="places"
          suggestions={searchSuggestions}
        />
      </SearchContainer>

      <ContentContainer>
        {!places || (places.length === 0 && <Loader />)}
        {places && places.length > 0 && (
          <>
            <CardsViewer
              places={distancePlaces}
              title="Near you"
              showDistance
            />
            <CardsViewer places={ratingPlaces} title="Top Picks" />
            <CardsViewer places={popularityPlaces} title="Most Popular" />
          </>
        )}
      </ContentContainer>
    </>
  );
};

export default PlacesOfInterestPage;
