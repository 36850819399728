import { css } from '@emotion/react';
import { Box, Flex, Grid } from 'components/layout';
import { ReactNode } from 'react';
import theme from 'theme';
import { Text } from 'components/typography';
import Footer from 'components/footer';
import { Button, Image } from 'components/elements';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setMobileDrawerOpen } from 'redux/account';
import { Loader } from 'components/inthecity';
import { NAVIGATION_ITEMS } from './navigator-items';
import NavHeader from './nav-header';
import NavItem from './nav-item';

const DRAWER_ANIMATION_DURATION = 300;

const AccountNavigator = ({
  children,
  loading,
}: {
  children: ReactNode;
  loading?: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    account: { isMobileDrawerOpen },
  } = useAppSelector((state) => state);
  return (
    <Flex flexDirection="column">
      <Flex
        hideForMobile
        py="20px"
        width="100%"
        borderB={`2px solid ${theme.colors.borderGrey}`}
        justifyContent="center"
      >
        <Text
          m={0}
          textAlign="center"
          color={theme.colors.darkGrey}
          fontSize="25px"
          fontWeight="500"
        >
          My Account
        </Text>
      </Flex>
      <Grid
        gridTemplateColumns="2fr 8fr"
        ml="10%"
        mr="27%"
        gridColumnGap="10%"
        my="5%"
        mobileCss={css`
          grid-template-columns: 1fr;
          margin: 20px;
        `}
      >
        <Box
          mobileCss={css`
            background-color: white;
            position: absolute;
            width: 100vw;
            height: 105vh;
            z-index: 10;
            padding: 20px;
            top: 60px;
            left: ${isMobileDrawerOpen ? 0 : '-100%'};
            transition: left ${DRAWER_ANIMATION_DURATION / 1000}s ease-in-out;
          `}
        >
          <Flex
            hideForDesktop
            hideForTablet
            py="10px"
            width="100%"
            borderB={`2px solid ${theme.colors.borderGrey}`}
            justifyContent="center"
            mb="20px"
          >
            <Text
              m={0}
              textAlign="center"
              color={theme.colors.darkGrey}
              fontSize="25px"
              fontWeight="500"
            >
              My Account
            </Text>
          </Flex>
          <Grid gridRowGap="20px">
            {NAVIGATION_ITEMS.map((group, index) => (
              <Box key={`g-${index}`}>
                <NavHeader title={group.title} />
                <Grid gridRowGap="7px">
                  {group.items.map((item, idx) => (
                    <Box key={`i-${idx}`}>
                      <NavItem
                        active={location.pathname.includes(item.link)}
                        label={item.label}
                        navigate={() => {
                          dispatch(setMobileDrawerOpen(false));
                          setTimeout(() => {
                            navigate(item.link);
                          }, DRAWER_ANIMATION_DURATION);
                        }}
                      />
                    </Box>
                  ))}
                </Grid>
              </Box>
            ))}
          </Grid>
        </Box>
        <Box>
          <Button
            onClick={() => dispatch(setMobileDrawerOpen(true))}
            hideForDesktop
            hideForTablet
          >
            <Flex>
              {' '}
              <Image source="icons/back-arrow.svg" alt="back arrow" />
              <Text
                ml="10px"
                fontSize="16px"
                fontWeight="bold"
                color={theme.colors.primary}
              >
                My Account
              </Text>
            </Flex>
          </Button>

          {loading ? <Loader /> : children}
        </Box>
      </Grid>
      <Footer />
    </Flex>
  );
};

export default AccountNavigator;
