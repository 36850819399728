import React, { useState } from 'react';

import { Modal } from 'components/social';
import {
  TabRow,
  Tab,
  Heading,
  AddBoard,
  // SelectButton,
  NumberSelector,
  BoardButton,
  ButtonLabel,
  ButtonBall,
  ScrollContainer,
  BottomContainer,
  Line,
  // TotalRow,
  Button,
  RemoveLink,
  ErrorLabel,
} from './lottoModal.styled';

const getBoardChar = (i) => String.fromCharCode(i + 65);

/**
 * Select Boards, and choose Quick Pick or Self Pick
 */
const LottoBoardsModal = (props) => {
  // props
  const {
    show,
    handleClose,
    handleChangeCategory,
    handleSelectBoard,
    boards,
    addBoard,
    removeBoard,
    handleNext,
    quickPickBoards,
    setQuickPickBoards,
    category,
    subcategory,
  } = props;

  // state
  const [quickpick, setQuickick] = useState(quickPickBoards);

  const handleQuickpick = (e) => {
    const regex = /^[0-9]*$/;
    const { value } = e.target;
    if (regex.test(value)) setQuickick(value);
  };

  // on selfpick, boards should be completed before continuing
  const selfpickValidation = () => {
    let isBoardsEmpty = false;
    boards.forEach((b) => {
      if (b.length === 0) {
        isBoardsEmpty = true;
      }
    });
    if (boards.length > 0 && boards.length <= 9 && !isBoardsEmpty) {
      return true;
    }
    return false;
  };

  // on quickpick, amount of boards should be between 1 and 20
  const quickpickValidation = () => (quickpick > 0 && quickpick <= 20);

  return (
    <Modal
      title={subcategory}
      show={show}
      handleClose={handleClose}
      backButton={false}
    >
      <TabRow>
        <Tab
          active={category === 'Lotto Selfpick'}
          onClick={() => handleChangeCategory('Lotto Selfpick')}
        >
          Play Lotto
        </Tab>
        <Tab
          active={category === 'Lotto Quickpick'}
          onClick={() => handleChangeCategory('Lotto Quickpick')}
        >
          Quick Pick
        </Tab>
      </TabRow>

      <ScrollContainer>
        {category === 'Lotto Selfpick' && (
          <>
            <Heading>Pick your numbers</Heading>

            {boards.map((b, index) => (
              <div style={{ position: 'relative' }}>
                <BoardButton
                  empty={b.length > 0}
                  onClick={() => handleSelectBoard(index)}
                >
                  <ButtonLabel>
                    {`Board ${getBoardChar(index)}`}
                  </ButtonLabel>

                  {b.length > 0 && b?.map((_, i) => (
                    <ButtonBall
                      number={i}
                      empty={i >= b.length}
                    >
                      {b[i]}
                    </ButtonBall>
                  ))}
                </BoardButton>

                <RemoveLink
                  onClick={() => removeBoard(index)}
                >
                  Remove Board
                </RemoveLink>
              </div>
            ))}

            {(boards.length < 8) && (
              <AddBoard
                onClick={addBoard}
              >
                <img src="inthecity/plus-icon.svg" alt="plus" />
                <p>Add Board</p>
              </AddBoard>
            )}
          </>
        )}
        {category === 'Lotto Quickpick' && (
          <>
            <Heading>The number of boards to play, from 1 to 20</Heading>

            <NumberSelector
              value={quickpick}
              onChange={handleQuickpick}
              type="text"
              inputmode="numeric"
            />

            {!(quickpick > 0 && quickpick <= 20) && (
              <ErrorLabel>Please enter a value between 1 and 20</ErrorLabel>
            )}
          </>
        )}
      </ScrollContainer>

      <BottomContainer>
        <Line />
        <Button
          disabled={(category === 'Lotto Quickpick')
            ? !quickpickValidation()
            : !selfpickValidation()}
          onClick={() => {
            if (category === 'Lotto Quickpick' && quickpickValidation()) {
              setQuickPickBoards(quickpick);
              handleNext();
            }
            if (category === 'Lotto Selfpick' && selfpickValidation()) {
              handleNext();
            }
          }}
        >
          Next
        </Button>
      </BottomContainer>
    </Modal>
  );
};

export default LottoBoardsModal;
