import styled from 'styled-components';

export const FilterColumn = styled.div`
  display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
  flex-direction: column;
  height: 100%;
  background: white;
  overflow: hidden;
  margin-right: 15px;


  @media only screen and (min-width: 1000px) {
    display: ${({ hideDesktop }) => (hideDesktop ? 'none' : 'flex')};
    width: 350px;
  }
`;

export const SubTitle = styled.h3`
  font: normal normal 500 16px/21px Roboto;
  color: #333333;
  line-height: 15px;
  margin: 20px 0px;
  position: relative;
  cursor: pointer;

  @media only screen and (min-width: 1000px) {
    margin-top: 30px;
  }
`;

export const ListItems = styled.h4`
  font: normal normal normal 16px/21px Roboto;
  color: #DF6E21;
  line-height: 30px;
  left: 0px;

  @media only screen and (min-width: 1000px) {
    padding-left: 0px;
  }
`;

export const MoreList = styled.h4`
  display: ${({ hideMobile }) => (hideMobile ? 'none' : 'none')};
  font: normal normal normal 16px/21px Roboto;
  color: #333333;
  line-height: 0px;
  cursor: pointer;
  margin:0px 10px 10px 0px;
  left: 0px;

  @media only screen and (min-width: 1000px) {
    display: ${({ hideDesktop }) => (hideDesktop ? 'none' : 'block')};
    padding-left: 0px;
  }
`;

export const MoreListIcon = styled.img`
  height: 10px;
  right: 10px;
  top: 10px;
  transform: ${(props) => (props.flip ? 'rotate(270deg)' : 'rotate(90deg)')};
  transition: transform 0.3s ease-in-out;
  margin: 10px 10px 10px 2px;
`;

export const MobileMoreListIcon = styled.img`
  height: 10px;
  right: 10px;
  transform: ${(props) => (props.flip ? 'rotate(270deg)' : 'rotate(90deg)')};
  position: absolute;
  transition: transform 0.3s ease-in-out;

  @media only screen and (min-width: 1000px) {
    display: none}
`;

export const ListIcon = styled.img`
  height: 10px;
  left: 0px;
  margin-right: 10px;
`;

export const Line = styled.div`
  opacity: 0.46;
  width: 100%;
  height: 2px;
  background-color: #DEE2E6;
`;
