/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SEO from 'components/seo';
import Footer from 'components/footer';
import { Heading, Paragraph } from 'components/home/text'; // Text,
// import MSCButton from 'components/home/button';
import Section, { PageWrapper } from 'components/section';
import {
  HeroContainer,
  ContentContainer,
  Content,
  ViewButton,
  AnimationContainer,
  ItemContainer,
  ItemImage,
  NameRow,
  ItemTitle,
  ItemBody,
  ItemTime,
} from './winners.styled';
import {
  QRCode,
  QRCodeImage,
  Overlay,
  QRText,
  OrangeLink,
  CenterRow,
  ButtonColumn,
  Button,
} from './competition.styled';

export const Hero = ({ handleScroll }) => (
  <HeroContainer>
    <ContentContainer>
      <Content>
        <h2>My Smart City Competition Winners</h2>
        <p>
          See all the competitions that we have hosted in the past, who the
          lucky winners were and the prizes that they won.
        </p>
        <ViewButton onClick={handleScroll}>View Past Winners</ViewButton>
      </Content>
    </ContentContainer>
    <AnimationContainer />
  </HeroContainer>
);

const WinnersPage = () => {
  const handleScroll = () => {
    document.getElementById('content').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <PageWrapper>
      <SEO
        title="My Smart City | Competition Winners"
        description="Competition winners"
      />
      <Hero handleScroll={handleScroll} />

      <Section color="white">
        <Row noGutters style={{ marginBottom: '80px' }}>
          <Col lg={7} sm={12}>
            <Heading
              color="dark"
              icon="marketing/competition/enter.svg"
              id="content"
            >
              Past Competition Winners
            </Heading>

            <Paragraph>
              We here at My Smart City host competitions regularly to reward
              existing and new users for being active citizens. Be sure to
              download and keep the appß on your phone for the chance to be
              randomly selected for amazing prizes.
            </Paragraph>
          </Col>
        </Row>

        <Row>
          <Col sm={6} xs={12}>
            <ItemContainer left>
              <ItemImage src="marketing/competition/winner1.jpg" alt="1" />
              <NameRow>
                <p>Winner: Haemish Kyd</p>
                <p>Prize: Holiday worth R 50 000</p>
              </NameRow>
              <ItemTitle>Won a holiday to Mauritius</ItemTitle>
              <ItemBody>
                Courtesy of My Smart City, a holiday to Victoria Beachcomber in
                Mauritius was up for grabs in three easy steps.
              </ItemBody>
              <ItemTime>01 October 2021 - 31 December 2021</ItemTime>
            </ItemContainer>
          </Col>
          <Col sm={6} xs={12}>
            <ItemContainer right>
              <ItemImage src="marketing/competition/winner2.jpg" alt="2" />
              <NameRow>
                <p>Winner: Jane Badham</p>
                <p>Prize: Garmin Watch</p>
              </NameRow>
              <ItemTitle>Won a Garmin Fenix 6s Smartwatch</ItemTitle>
              <ItemBody>
                Kickstarting the year was this competition with a chance to win
                a Garmin Fenix 6s Smart Watch.
              </ItemBody>
              <ItemTime>1 January 2022 - 28 February 2022</ItemTime>
            </ItemContainer>
          </Col>
        </Row>

        <CenterRow style={{ marginTop: '100px' }}>
          <ButtonColumn>
            <Button href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp">
              <img src="home/citizens/playStore.svg" alt="googleplay" />
            </Button>
            <Button
              href="https://apps.apple.com/app/mysmart-city/id1555236570"
              right
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="home/citizens/appStore.svg" alt="appstore" />
            </Button>
          </ButtonColumn>
          <QRCode>
            <Overlay>
              <div />
              <div />
              <div />
              <div />
              <QRCodeImage src="marketing/competition/qr.svg" />
            </Overlay>
            <QRText>Scan to Download App</QRText>
          </QRCode>
        </CenterRow>
        <CenterRow>
          <OrangeLink to="/competition-terms-and-conditions">
            Terms and Conditions Apply
          </OrangeLink>
        </CenterRow>
      </Section>

      <Footer />
    </PageWrapper>
  );
};

export default WinnersPage;
