import styled from 'styled-components';

export const Headline = styled.h1`
  padding: 0;
  margin: 0;
  color: ${({ theme, color }) => color || theme.color.headline};
  font: normal normal ${({ theme, fontWeight = '500' }) => `
    ${fontWeight} ${theme.fontSize.headline} ${theme.font}}
  `};
`;

export const Title = styled.h2`
  padding: 0;
  margin: 0;
  color: ${({ theme, color }) => color || theme.color.title};
  font: normal normal ${({ theme, fontWeight = '500' }) => `
    ${fontWeight} ${theme.fontSize.title} ${theme.font}}
  `};
`;

export const Subtitle = styled.h3`
  padding: 0;
  margin: 0;
  color: ${({ theme, color }) => color || theme.color.subtitle};
  font: normal normal ${({ theme, fontWeight = 'normal' }) => `
    ${fontWeight} ${theme.fontSize.subtitle} ${theme.font}}
  `};
`;

export const Body = styled.p`
  padding: 0;
  margin: 0;
  color: ${({ theme, color }) => color || theme.color.body};
  font: normal normal ${({ theme, fontWeight = 'normal' }) => `
    ${fontWeight} ${theme.fontSize.body} ${theme.font}}
  `};
`;
