import { MunicipalPageWrapper } from 'components/my-municipality';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GMApplication } from 'types/types';
import { Text } from 'components/typography';
import { Box, Flex, Grid } from 'components/layout';
import theme from 'theme';
import { Loader } from 'components/inthecity';
import { Button, TextField } from 'components/elements';
import { SmallLoader } from 'components/private-services/survey';
import ExternalLink from 'components/elements/external-link';
import { capitalize } from '@material-ui/core';

const getDocumentLinks = (application: GMApplication) => {
  const documentLinks: { fieldName: string; documentLink: string }[] = [];
  Object.entries(application['toolset-meta']['george-documents']).forEach(
    ([fieldName, fieldData]) => {
      if (fieldData.type === 'file' && fieldData.raw) {
        const documentLink = fieldData.raw;
        documentLinks.push({ fieldName, documentLink });
      }
    }
  );
  return documentLinks;
};

const ApplicationItem = ({ application }: { application: GMApplication }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Flex boxShadow={theme.elevationShadow} p="16px" flexDirection="column">
      <Grid gridTemplateColumns="1fr 100px" width="100%" gridColumnGap="10px">
        <Text>
          {application.title.rendered
            .replace('\\', '')
            .replace('&#8211;', '-')
            .replace('&amp;', '&')}
        </Text>
        <Button
          color={theme.colors.primary}
          fontWeight={600}
          my="auto"
          onClick={() => setShowMore((s) => !s)}
        >
          {showMore ? 'Show less' : 'Show more'}
        </Button>
      </Grid>
      {showMore && (
        <Grid gridRowGap="10px" width="100%">
          <ExternalLink
            href={application.link}
            target="__blank"
            color={theme.colors.primary}
            textDecoration="underline"
          >
            View on george.gov.za
          </ExternalLink>
          <Text fontWeight="bold">Document Downloads:</Text>
          {getDocumentLinks(application).map(({ fieldName, documentLink }) => (
            <ExternalLink
              key={fieldName}
              href={documentLink}
              target="__blank"
              color={theme.colors.primary}
              textDecoration="underline"
            >
              <Flex alignItems="center">
                <Box
                  mr="15px"
                  backgroundColor={theme.colors.primary}
                  borderRadius="50%"
                  p="2px"
                >
                  <img
                    className="profileNavDrop"
                    src="profile/download.svg"
                    alt="Logo"
                    style={{ height: '20px', width: '20px' }}
                  />
                </Box>
                <Text>{capitalize(fieldName.split('-').join(' '))}</Text>
              </Flex>
            </ExternalLink>
          ))}
        </Grid>
      )}
    </Flex>
  );
};

const ApplicationsPage = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [applications, setApplications] = useState<GMApplication[]>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const getApplications = useCallback(async (pageNumber: number) => {
    if (pageNumber === 1) {
      setLoading(true);
    } else {
      !loading && setLoadingMore(true);
    }
    setPage(pageNumber + 1);
    fetch(
      `https://www.george.gov.za/wp-json/wp/v2/george-document?filter[category_name]=application-forms&per_page=10&page=${pageNumber}&orderby=modified`
    )
      .then((res) => res.json())
      .then((data: GMApplication[]) => {
        setApplications((a) => [...a, ...data]);
        if (pageNumber === 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (pageNumber === 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
      });
  }, []);

  useEffect(() => {
    getApplications(page);
  }, []);

  const applicationsToDisplay = useMemo(
    () =>
      search === ''
        ? applications
        : applications.filter((application) =>
            application.title.rendered
              .toLowerCase()
              .includes(search.toLowerCase())
          ),
    [search, applications]
  );

  return (
    <MunicipalPageWrapper title="Applications">
      {loading ? (
        <Loader />
      ) : (
        <>
          <TextField
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            mb="20px"
          />
          <Grid gridRowGap="20px">
            {applicationsToDisplay.length > 0 ? (
              applicationsToDisplay.map((application) => (
                <ApplicationItem
                  key={`${application.id}-${application.date}`}
                  application={application}
                />
              ))
            ) : (
              <Text textAlign="center">
                No results on this page, please load more to view more results
              </Text>
            )}
          </Grid>
        </>
      )}
      <Flex width="100%" justifyContent="center" mt="20px">
        {loadingMore ? (
          <SmallLoader />
        ) : (
          <Button onClick={() => getApplications(page)} variant="cta" px="16px">
            Load more
          </Button>
        )}
      </Flex>
    </MunicipalPageWrapper>
  );
};

export default ApplicationsPage;
