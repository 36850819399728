/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useState, createContext, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { getAvailableCities, selectCity } from 'interface/map';
import { getCurrentSmartCityUser } from 'interface/app';
import findNearest from 'geolib/es/findNearest';
import { isEqual } from 'lodash';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { clearUserBooking } from 'redux/booking';
import { useNavigate } from 'react-router-dom';
import {
  updateUser,
  updateCity,
  clearUser,
  initialState as reduxInitState,
} from 'redux/global';
import { MSCGlobalState, SmartCity, SmartCityUser } from 'types/types';
import { guestUser } from 'functions/auth';
import { isAnonymousToken } from 'interface/login';
import { UserEvents, logFirebaseEvent } from 'functions/firebase';

interface GlobalContextType {
  globalState: MSCGlobalState;
  init: () => Promise<void>;
  checkAuthentication: () => Promise<void>;
  setUserAndCityFromUser: (user: SmartCityUser) => void;
  setCheckAuthentication: () => Promise<void>;
  setCurrentCity: (city: SmartCity) => Promise<void>;
  setShowLoginModal: (show: boolean) => void;
  logout: () => void;
}

const GlobalContext = createContext<GlobalContextType>({
  globalState: reduxInitState,
  init: () => Promise.resolve(),
  checkAuthentication: () => Promise.resolve(),
  setUserAndCityFromUser: () => null,
  setCheckAuthentication: () => Promise.resolve(),
  setCurrentCity: () => Promise.resolve(),
  setShowLoginModal: () => null,
  logout: () => null,
});
const cookies = new Cookies();

const GlobalProvider = (props: any) => {
  // Hooks
  const { global } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // States
  const [globalState, setGlobalState] =
    useState<MSCGlobalState>(reduxInitState);

  // Side Effects
  // Update user and city from redux
  useEffect(() => {
    setGlobalState((prev) => ({
      ...prev,
      user: global.user,
      city: global.city,
    }));
  }, [
    isEqual(globalState.user, global.user),
    isEqual(globalState.city, global.city),
  ]);

  // Functions
  const setUserAndCityFromUser = (user: SmartCityUser) => {
    console.log('setUserAndCityFromUser():', user);

    if (user.currentCitySubscriberId) {
      const [city] = globalState.availableCities.filter(
        (cityTemp) => user.currentCitySubscriberId === cityTemp.value
      );

      if (city) {
        dispatch(updateCity(city));
      } else {
        dispatch(updateCity(globalState.availableCities[0]));
      }
    }
    dispatch(updateUser(user));
  };

  const checkAuthentication = async () => {
    console.log('checkAuthentication()');
    const firebaseToken = await cookies.get('firebaseToken');
    if (firebaseToken && firebaseToken.length > 10) {
      const userTemp = await getCurrentSmartCityUser();
      if (userTemp.error) {
        const isAnonymous = await isAnonymousToken();
        if (isAnonymous) {
          dispatch(updateUser(guestUser));
          console.log('checkAuthentication(): Logged in as guest ');
        } else {
          console.log('checkAuthentication(): error', userTemp.error);
          dispatch(clearUser());
          cookies.remove('currentUser');
          cookies.remove('firebaseToken');
        }
      } else if (userTemp.uuid) {
        // Only update the user if it has changed since last check
        if (!isEqual(userTemp, global.user)) dispatch(updateUser(userTemp));
        console.log('checkAuthentication(): Logged in ');
      } else {
        // Otherwise clear all local user data, allowing the user to log in again
        dispatch(clearUser());
        cookies.remove('currentUser');
        cookies.remove('firebaseToken');
      }
    } else {
      // Otherwise clear all local user data, allowing the user to log in again
      dispatch(clearUser());
      cookies.remove('currentUser');
      cookies.remove('firebaseToken');
    }
    const allCities = await getAvailableCities();
    console.log('all', allCities);
    setGlobalState((prevState) => ({
      ...prevState,
      availableCities: allCities,
      FirebaseToken: firebaseToken,
    }));
  };

  const getLocation = async () => {
    let location: { latitude: string; longitude: string } = {
      latitude: '0.0',
      longitude: '0.0',
    };

    try {
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }
      );

      location = {
        latitude: position.coords.latitude.toString(),
        longitude: position.coords.longitude.toString(),
      };
    } catch (err) {
      console.log(
        'checkCity(): Browser location is turned off using ip location...'
      );
      const ip = await fetch('https://ipv4.icanhazip.com').then((res) =>
        res.text()
      );
      console.log('checkCity(): ip', ip);

      const position = await fetch(`https://ipapi.co/${ip}/json/`).then((res) =>
        res.json()
      );
      console.log('checkCity(): position', position);

      location = {
        latitude: `${position.latitude}`,
        longitude: `${position.longitude}`,
      };
    }
    setGlobalState((prevState) => ({
      ...prevState,
      location,
    }));
  };

  const checkCity = async () => {
    setGlobalState((prevState) => {
      const { location, availableCities, city } = prevState;
      if (!!city && city.value !== 0) {
        console.log('checkCity(): city already selected');
      } else {
        // If there is no city selected, find the closest city

        console.log('checkCity(): location', location, availableCities);
        const cityLocations = availableCities.map((c) => ({
          latitude: c.attributes.latitude,
          longitude: c.attributes.longitude,
        }));
        const nearestCityPos = findNearest(location, cityLocations);
        console.log('checkCity(): nearest city', nearestCityPos);

        const [closestCity] = availableCities.filter(
          (cityTemp) =>
            cityTemp.attributes.latitude.localeCompare(
              // @ts-ignore
              nearestCityPos.latitude
            ) === 0 &&
            cityTemp.attributes.longitude.localeCompare(
              // @ts-ignore
              nearestCityPos.longitude
            ) === 0
        );

        const selectedCity = closestCity ?? availableCities[0];
        dispatch(updateCity(selectedCity));
      }
      return prevState;
    });
  };

  const setCheckAuthentication = async () => {
    await checkAuthentication();
  };

  const setCurrentCity = async (city: SmartCity) => {
    console.log(city);
    await selectCity(city.value);
    dispatch(updateCity(city));
  };

  const logout = () => {
    dispatch(clearUser());
    dispatch(clearUserBooking());
    cookies.remove('currentUser');
    cookies.remove('firebaseToken');
    navigate('/');
    logFirebaseEvent(UserEvents.Logout);
  };

  const setShowLoginModal = (show: boolean) => {
    setGlobalState((prevState) => ({
      ...prevState,
      showLoginModal: show,
    }));
  };

  const init = async () => {
    console.log('init()');
    await checkAuthentication();
    await getLocation();
    await checkCity();
    console.log('init():', globalState);
    // setGlobalState(_.cloneDeep(globalState));
  };

  const data = {
    globalState,
    init,
    checkAuthentication,
    setUserAndCityFromUser,
    setCheckAuthentication,
    setCurrentCity,
    setShowLoginModal,
    logout,
  };

  return <GlobalContext.Provider value={data} {...props} />;
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
