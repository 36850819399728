/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGlobalContext } from 'contexts/global';
import {
  LottoBoardsModal,
  EditBoardModal,
  DetailsModal,
  PaymentModal,
  ConfirmationModal,
} from 'components/inthecity/lottoModal';

/**
 * The Lotto Modal for choosing and purchasing lotto products
 * Kinektek Lotto dual step products
 *
 * Props:
 * redirectState - {
 *  Array: boards,
 *  Number: quickPickBoards,
 *  Object: lookup,
 *  Object: product,
 *  String: checkout
 *  String: mobileNumber,
 * }
 * buy - {
 *  Object: product,
 *  String: mobileNumber
 * }
 */
const LottoModal = (props) => {
  // props
  const {
    show,
    handleClose,
    products,
    buy,
    subcategory, // 'Lotto', 'Lotto Daily', 'Lotto Powerball'
    redirectState,
    handleShowOrderDetails,
  } = props;

  console.log('lotto:props', props);

  // hooks
  const {
    globalState: { user },
  } = useGlobalContext();
  const navigate = useNavigate();

  // state
  const [stage, setStage] = useState(0); // modal stages
  const [category, setCategory] = useState('Lotto Selfpick');
  const [product, setProduct] = useState();
  const [prevProduct, setPrevProduct] = useState();
  const [quickPickBoards, setQuickPickBoards] = useState(1);
  const [boards, setBoards] = useState([[], []]);
  const [currentBoard, setCurrentBoard] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [plus, setPlus] = useState(0);

  // Update a specific board
  const updateBoard = (board, index) =>
    setBoards(boards.map((b, i) => (i === index ? board : b)));

  // Remove a specific board
  const removeBoard = (index) => {
    const temp = [];
    boards.forEach((b, i) => {
      if (i !== index) temp.push(b);
    });
    setBoards(temp);
  };

  // Add new board
  const addBoard = () => setBoards([...boards, []]);

  // Change (self/quick) Pick handler, also resets boards
  const handleChangeCategory = (c) => {
    setCategory(c);
    setBoards([[], []]);
  };

  // Handle select board, and go to Edit Board stage
  const handleSelectBoard = (i) => {
    setCurrentBoard(i);
    setStage(1);
  };

  // Handle change Lotto to Plus 1 or Plus 2, or reset.
  // 0: normal, 1: Plus1, 2: Plus2
  const handleChangePlus = (num) => {
    if (plus === 0) setPlus(num);
    else if (plus === 1 && num === 1) setPlus(0);
    else if (plus === 2 && num === 2) setPlus(1);
    else setPlus(num);
  };

  // Handle modal close, and reset state
  const handleResetAndClose = () => {
    setCategory('Lotto Selfpick');
    setProduct(null);
    setQuickPickBoards(1);
    setBoards([[], []]);
    setCurrentBoard(null);
    setMobileNumber(user?.mobileNumber);
    setStage(0);
    handleClose();
  };

  // Change phone number if the logged in user has changed
  useEffect(() => setMobileNumber(user?.mobileNumber), [user]);

  // go to purchase page if buy is not null
  useEffect(() => {
    if (buy) {
      setProduct(buy?.product);
      const b = JSON.parse(buy?.boards.replace(/;/g, ','));
      if (typeof b === 'object') setBoards(b);
      else setQuickPickBoards(b);
      setMobileNumber(buy?.mobileNumber);
      setStage(3);
    }
  }, [buy]);

  // go to confirmation page if redirectState object is not null, i.e. a purchase was made
  useEffect(() => {
    if (redirectState) setStage(4);
  }, [redirectState]);

  // Get product
  useEffect(() => {
    if (category && subcategory && products) {
      const { products: productsFromCategory } = products.find(
        ({ name }) => name === category
      );
      if (productsFromCategory) {
        const productSelected = productsFromCategory.find(
          ({ name }) => name === `${subcategory} ${category.split(' ')[1]}`
        ); // name.includes(subcategory)
        const { productID } = productSelected || {};
        console.log('lotto:product', {
          products,
          category,
          subcategory,
          product: productSelected,
        });
        if (productID) {
          setProduct(productSelected);
          setPrevProduct(productSelected);
        }
      } else console.log(`${category} not a Kinektek product category`);
    }
  }, [category, subcategory, products]);

  // Get plus 1 or 2 product
  useEffect(() => {
    if (category && subcategory && products) {
      if (plus === 0) setProduct(prevProduct);
      if (plus > 0) {
        const { products: productsFromCategory } = products.find(
          ({ name }) => name === category
        );
        if (productsFromCategory) {
          const productSelected = productsFromCategory.find(
            ({ name }) =>
              name ===
              `${subcategory} ${
                subcategory === 'Powerball' ? 'Plus' : `Plus ${plus}`
              } ${category.split(' ')[1]}`
          );
          const { productID } = productSelected || {};
          console.log('lotto:plus:product', productSelected);
          if (productID) setProduct(productSelected);
        } else console.log(`${category} not a Kinektek product category`);
      }
    }
  }, [plus]);

  switch (stage) {
    case 0:
      return (
        <LottoBoardsModal
          show={show}
          handleClose={handleResetAndClose}
          updateBoard={updateBoard}
          handleChangeCategory={handleChangeCategory}
          boards={boards}
          addBoard={addBoard}
          removeBoard={removeBoard}
          handleSelectBoard={handleSelectBoard}
          handleNext={() => setStage(2)}
          quickPickBoards={quickPickBoards}
          setQuickPickBoards={(n) => setQuickPickBoards(n)}
          category={category}
          subcategory={subcategory}
        />
      );

    case 1:
      return (
        <EditBoardModal
          show={show}
          handleClose={handleResetAndClose}
          updateBoard={updateBoard}
          addBoard={addBoard}
          boards={boards}
          currentBoard={currentBoard}
          handleBack={() => setStage(0)}
          product={product}
          category={category}
        />
      );

    case 2:
      return (
        <DetailsModal
          handleClose={handleResetAndClose}
          show={show}
          updateBoard={updateBoard}
          handleBack={(n) => {
            setMobileNumber(n);
            setStage(0); // go back to first Modal
          }}
          handleNext={(n) => {
            setMobileNumber(n);
            setStage(3); // go forward to Payment Modal
          }}
          boards={boards}
          currentBoard={currentBoard}
          mobileNumber={mobileNumber}
          category={category}
          subcategory={subcategory}
          product={product}
          handleChangePlus={handleChangePlus}
          plus={plus}
        />
      );

    case 3:
      return (
        <PaymentModal
          show={show}
          handleBack={buy ? () => {} : () => setStage(2)}
          handleClose={buy ? handleResetAndClose : () => {}}
          boards={boards}
          product={product}
          mobileNumber={mobileNumber}
          category={category}
          quickPickBoards={quickPickBoards}
          buy={buy}
        />
      );

    case 4:
      return (
        <ConfirmationModal
          show={show}
          handleClose={() => {
            handleResetAndClose();
            navigate('/in-the-city/purchase/lotto');
          }}
          redirectState={redirectState}
          handleShowOrderDetails={() => {
            handleResetAndClose();
            handleShowOrderDetails();
          }}
        />
      );

    default:
      return null;
  }
};

export default LottoModal;
