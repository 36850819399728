/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';

import { getDirectionsURL } from 'functions/inthecity';
import {
  MobileContainer,
  Container,
  Row,
  Title,
  Text,
  OrangeText,
  GreenText,
  Line,
  Button,
  LocationIcon,
  DateIcon,
  Divider,
  StyledMapContainer,
  StarIcon,
  PriceIcon,
} from './details.styled';

const getTicketPrice = (ticketsArray) => {
  if (ticketsArray && ticketsArray.length > 0) {
    return `From R ${ticketsArray[0]?.price}`;
  }
  return 'Free';
};

/**
 * Event Details component
 */
export const EventDetails = ({ event }) => {
  const {
    id,
    name,
    description,
    url,
    imageUrl,
    startDate,
    endDate,
    tickets,
    venue,
    dateCreated,
    lastModified,
    locality,
    organiser,
    categories,
  } = event;

  console.log('event:object', event);

  return (
    <Container>
      <Row>
        <Title>Event Details</Title>
      </Row>

      <Row>
        <PriceIcon />
        <OrangeText>
          {getTicketPrice(tickets)}
        </OrangeText>
      </Row>

      <Row>
        <DateIcon />
        <GreenText>{moment(startDate).fromNow()}</GreenText>
        <Divider>·</Divider>
        <Text style={{ width: 'calc(100% - 200px)' }}>{`${moment(startDate).format('ddd D MMM YY, hh:mm')} - ${moment(endDate).format('ddd D MMM YY, hh:mm')}`}</Text>
      </Row>
      {venue
        ? (
          <Row>
            <LocationIcon />
            <Text>{venue?.name}</Text>
          </Row>
        )
        : null}

      <Line />

      <Button
        target="_blank"
        href={url}
      >
        Purchase Tickets
      </Button>
    </Container>
  );
};

/**
 * Event Details component for the mobile view
 */
export const MobileEventDetails = ({ event }) => {
  const {
    id,
    name,
    description,
    url,
    imageUrl,
    startDate,
    endDate,
    tickets,
    venue,
    dateCreated,
    lastModified,
    locality,
    organiser,
    categories,
  } = event;

  return (
    <MobileContainer>
      <Row>
        <Title>{name}</Title>
      </Row>

      <Row>
        <PriceIcon />
        <OrangeText>
          {getTicketPrice(tickets)}
        </OrangeText>
      </Row>

      <Row>
        <DateIcon />
        <GreenText>{moment(startDate).fromNow()}</GreenText>
        <Divider>·</Divider>
        <Text style={{ width: 'calc(100% - 200px)' }}>{`${moment(startDate).format('ddd D MMM YY, hh:mm')} - ${moment(endDate).format('ddd D MMM YY, hh:mm')}`}</Text>
      </Row>

      {venue
        ? (
          <Row>
            <LocationIcon />
            <Text>{venue?.name}</Text>
          </Row>
        )
        : null}
    </MobileContainer>
  );
};

/**
 * Place Details component
 */
export const PlaceDetails = (props) => {
  const { place } = props;
  const {
    name,
    geocodes,
    location,
    photos,
    rating,
    stats,
    description,
    website,
    categories,
    hours,
    tel,
    distance,

  } = place;
  console.log(place);

  return (
    <Container>
      <Row>
        <Title>Place Details</Title>
      </Row>
      {rating
        ? (
          <Row>
            {Math.round((rating / 10) * 5) >= 1 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            {Math.round((rating / 10) * 5) >= 2 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            {Math.round((rating / 10) * 5) >= 3 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            {Math.round((rating / 10) * 5) >= 4 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            {Math.round((rating / 10) * 5) >= 5 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            <Text>{`${((rating / 10) * 5).toFixed(2)} (${stats ? stats.total_ratings : 'NA'})`}</Text>
          </Row>
        )
        : null }
      <Row>
        <DateIcon />
        {hours.open_now
          ? <GreenText>Open</GreenText>
          : <OrangeText>Closed</OrangeText>}
        {hours.display
          ? (
            <>
              <Divider>·</Divider>
              <Text>{hours.display}</Text>
            </>
          )
          : (
            <>
              <Divider>·</Divider>
              <Text>Trading hours not available </Text>
            </>
          )}
      </Row>
      <Row>
        <LocationIcon />
        {(location && location.address && location.address.length !== 0)
          ? (
            <Text>
              {location.address}
              {location.locality ? `, ${location.locality}` : null}
            </Text>
          )
          : (
            <>
              {(geocodes && geocodes.main)
                ? <Text style={{ width: 'calc(100% - 20px)' }}>{`${geocodes.main.latitude}, ${geocodes.main.longitude}`}</Text>
                : null }
            </>
          ) }

      </Row>
      <Line />
      {geocodes
        ? (
          <Button
            target="_blank"
            href={getDirectionsURL(null, geocodes.main.latitude, geocodes.main.longitude)}
          >
            Get Directions
          </Button>
        )
        : null}

    </Container>
  );
};

/**
 * Place Details component for the mobile view
 */
export const MobilePlaceDetails = (props) => {
  const { place } = props;
  const {
    name,
    geocodes,
    location,
    photos,
    rating,
    stats,
    description,
    website,
    categories,
    hours,
    tel,
    distance,
  } = place;

  return (
    <MobileContainer>
      <Row>
        <Title>Place Details</Title>
      </Row>

      {rating
        ? (
          <Row>
            {Math.round((rating / 10) * 5) >= 1 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            {Math.round((rating / 10) * 5) >= 2 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            {Math.round((rating / 10) * 5) >= 3 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            {Math.round((rating / 10) * 5) >= 4 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            {Math.round((rating / 10) * 5) >= 5 ? <StarIcon src="inthecity/star-icon.svg" alt="star" /> : <StarIcon src="inthecity/star-icon-grey.svg" />}
            <Text>{`${(rating / 10) * 5} (${stats ? stats.total_ratings : 'NA'})`}</Text>
          </Row>
        )
        : null }
      {hours
        ? (
          <Row>
            <DateIcon />
            {hours.open_now
              ? <GreenText>Open</GreenText>
              : <OrangeText>Closed</OrangeText>}
            {hours.display
              ? (
                <>
                  <Divider>·</Divider>
                  <Text style={{ width: 'calc(100% - 100px)' }}>{hours.display}</Text>
                </>
              ) : null}
          </Row>
        )
        : (
          <Row>
            <DateIcon />
            {' '}
            <OrangeText>unknown</OrangeText>
          </Row>
        )}

      <Row>
        <LocationIcon />
        {(location && location.address)
          ? (
            <Text style={{ width: 'calc(100% - 20px)' }}>
              {location.address}
              {location.locality ? `, ${location.locality}` : null}
            </Text>
          )
          : null }
        {(geocodes && geocodes.main && !location)
          ? <Text style={{ width: 'calc(100% - 20px)' }}>{`${geocodes.main.latitude}, ${geocodes.main.longitude}`}</Text>
          : null }
        {!(location && location.address) || !(geocodes && geocodes.main)
          ? (
            <Text>
              No location supplied
            </Text>
          )
          : null}
      </Row>
      {geocodes
        ? (
          <Row>
            <Button
              target="_blank"
              href={getDirectionsURL(null, geocodes.main.latitude, geocodes.main.longitude)}
            >
              Get Directions
            </Button>
          </Row>
        )
        : null}
    </MobileContainer>
  );
};

export const LocationMap = ({ position }) => {
  const icon = new L.Icon({ iconUrl: '/social/location-icon.svg', iconSize: [30, 44] });

  return (
    <StyledMapContainer
      center={position}
      zoom={11}
      minZoom={9}
      scrollWheelZoom
      easeLinearity={0.4}
      zoomControl={false}
      doubleClickZoom
      dragging
      animate
      attributionControl={false}
      closePopupOnClick={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker
        icon={icon}
        position={position}
      />
    </StyledMapContainer>
  );
};
