import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { VoteForm, FormModal } from 'components/home/form';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Section, { PageWrapper } from 'components/section';
import { DownloadBar } from 'components/home/hero';
import { Heading, Text, Paragraph } from 'components/home/text';
import Button from 'components/home/button';
import DownloadPopup from 'components/home/popup';
import {
  InfographicContainer,
  Image,
  CitiesRow,
  CitiesColumn,
  CityBubble,
  CitiesContainer,
  Hero,
} from './faults.styled';

const VotePopup = ({ show, onHide }) => (
  <FormModal header="Vote to add a city" show={show} onHide={onHide}>
    <VoteForm />
  </FormModal>
);

const FaultsPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showVote, setShowVote] = useState(false);

  return (
    <PageWrapper>
      <SEO
        title="My Smart City | Report Faults in your City"
        description="Report Faults in your city"
      />
      <Hero
        image="marketing/faults/hero.jpg"
        phone="marketing/faults/phone.png"
        title="Report Faults in your City"
        desktopSubtitle="Report faults you want resolved. Register today to get started."
        desktopWidth="60%"
        mediumWidth="60%"
        mobileWidth="60%"
      />
      <DownloadBar />

      <Section color="#dbe6eb" inverted>
        <Heading icon="icons/PhoneBlue.svg" color="orange">
          How Logging Faults with
          <Text bold color="blue">
            {' '}
            My Smart City{' '}
          </Text>
          Works
        </Heading>
        <Row noGutters>
          <Col lg={5} sm={12}>
            <Paragraph>
              Citizens now have a simple-to-use platform on which they can
              report faults with service providers.Simply register on My Smart
              City and begin reporting faults that you encounter in your city.
              Potholes, water supply issues, electricity faults, refuse concerns
              and more. Just drop a pin, let us know the details and we will
              follow up on the fault for you making the painful process of
              having issues and faults resolved, easier.
            </Paragraph>
            <Paragraph>
              No more frustratingly long phone calls with customer service
              agents, just log it on My Smart City and leave the rest to us. It
              can then be escalated, monitored, tracked and traced as the
              service provider is notified of your fault and works to resolve
              the issues that are important to you.
            </Paragraph>
          </Col>
          <Col lg={7} sm={12}>
            <InfographicContainer>
              <Image src="marketing/faults/infographic.png" alt="infographic" />
            </InfographicContainer>
          </Col>
        </Row>
      </Section>

      <Section color="orange" diagonal>
        <div className="map-container-mask">
          <div
            className="map-image-section-desktop"
            style={{ backgroundImage: 'url("/home/desktopMap.jpg")' }}
          />
        </div>
        <Row noGutters>
          <Col lg={4} md={5} sm={12}>
            <Heading color="white" icon="home/citizens/view.svg">
              Report a Fault Now
            </Heading>
            <Paragraph>
              Let’s get started. Access the My Smart City real-time map to view,
              report, upvote, track and manage requests, issues, faults and
              more. Register your account today and begin reporting your faults.
            </Paragraph>
            <img
              src="home/mobileMap.png"
              alt="map"
              className="map-image-section-mobile"
            />
            <div className="emergency-create-buttons">
              <Button label="log fault now" to="/map" arrow dark fullWidth />
              <Paragraph marginTop alignCenter>
                <Text bold color="white">
                  OR
                </Text>
              </Paragraph>
              <Button
                label="download now"
                onClick={() => setShowPopup(true)}
                arrow
                outlined
                fullWidth
              />
              <DownloadPopup
                show={showPopup}
                onHide={() => setShowPopup(false)}
              />
            </div>
          </Col>
        </Row>
      </Section>

      <Section color="light" diagonal>
        <Row noGutters>
          <Col lg={5} sm={12}>
            <Heading icon="home/citizens/vote.svg" color="dark">
              Am I in a
              <Text bold color="blue" text=' "My Smart City" ' />
              Enabled City?
            </Heading>
            <Paragraph>
              My Smart City is available in the City of Johannesburg, City of
              Cape Town, City of Tshwane, and the City of Ekurhuleni.
            </Paragraph>
            <Paragraph>
              Is your city still living in the stone ages? Vote to make your
              city a My Smart City today and once we have enough votes, we will
              reach out to your local municipality to bring your city onboard.
            </Paragraph>
            <Button label="vote now" arrow onClick={() => setShowVote(true)} />
          </Col>
          <Col lg={7} sm={12}>
            <CitiesContainer>
              <CitiesRow>
                <CitiesColumn>
                  <CityBubble>
                    <img src="marketing/faults/tshwane.png" alt="tshwane" />
                    <p>Tshwane</p>
                  </CityBubble>
                  <CityBubble>
                    <img src="marketing/faults/cape_town.png" alt="cape_town" />
                    <p>Cape Town</p>
                  </CityBubble>
                </CitiesColumn>
                <CitiesColumn>
                  <CityBubble>
                    <img src="marketing/faults/joburg.png" alt="joburg" />
                    <p>Johannesburg</p>
                  </CityBubble>
                  <CityBubble>
                    <img
                      src="marketing/faults/ekurhuleni.png"
                      alt="ekurhuleni"
                    />
                    <p>Ekurhuleni</p>
                  </CityBubble>
                </CitiesColumn>
              </CitiesRow>
            </CitiesContainer>
          </Col>
        </Row>
        <VotePopup show={showVote} onHide={() => setShowVote(false)} />
      </Section>

      <Footer />
    </PageWrapper>
  );
};

export default FaultsPage;
