/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CustomerPremises,
  SmartCityService,
  SmartCityServiceBooking,
} from 'types/types';

export interface ReduxBookingState {
  booking: SmartCityServiceBooking;
  price: number;
  totalDuration: number;
  maxDuration: number;
  bookingStep: 'details' | 'summary';
  address: CustomerPremises | null;
}

const initialState: ReduxBookingState = {
  booking: {
    smartCityServiceGroups: [],
    bookingInformation: null,
  },
  price: 0,
  totalDuration: 1,
  maxDuration: 8,
  bookingStep: 'details',
  address: null,
};

export const bookingSlice = createSlice({
  name: 'currentBooking',
  initialState,
  reducers: {
    setPrice: (state, action: PayloadAction<number>) => {
      state.price = action.payload;
    },
    setTotalDuration: (state, action: PayloadAction<number>) => {
      state.totalDuration = action.payload;
    },
    setMaxDuration: (state, action: PayloadAction<number>) => {
      state.maxDuration = action.payload;
    },
    setBookingStep: (state, action: PayloadAction<'details' | 'summary'>) => {
      state.bookingStep = action.payload;
    },
    setBooking: (state, action: PayloadAction<SmartCityServiceBooking>) => {
      state.booking = action.payload;
    },
    setAddress: (state, action: PayloadAction<CustomerPremises>) => {
      state.address = action.payload;
    },
    setServiceOption: (
      state,
      action: PayloadAction<{
        service: SmartCityService;
        group: string;
        renderIdx: number;
      }>
    ) => {
      const { service, group, renderIdx } = action.payload;
      const groupIndex = state.booking.smartCityServiceGroups.findIndex(
        (g) => g.heading === group
      );

      state.booking.smartCityServiceGroups[groupIndex].smartCityServices.splice(
        renderIdx,
        1,
        service
      );
    },
    clearUserBooking: (state) => {
      state.booking = {
        smartCityServiceGroups: [],
        bookingInformation: null,
      };
      state.price = 0;
      state.totalDuration = 1;
      state.maxDuration = 8;
      state.bookingStep = 'details';
      state.address = null;
    },
  },
});

export const bookingSelector = (state: ReduxBookingState) => state;

export const {
  setPrice,
  setTotalDuration,
  setMaxDuration,
  setBookingStep,
  setBooking,
  setAddress,
  setServiceOption,
  clearUserBooking,
} = bookingSlice.actions;
export default bookingSlice.reducer;
