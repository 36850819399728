/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex, Box, Grid } from 'components/layout';
import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Button } from 'components/elements';
import theme from 'theme';
import { Loader } from 'components/inthecity';

const AnimatedCarousel = ({
  carouselItems,
  lastSlideCallback,
}: {
  carouselItems: ReactNode[];
  lastSlideCallback?: () => void;
}) => {
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const gridRef = useRef<HTMLDivElement>(null);
  const [gridItemWidth, setGridItemWidth] = useState(0);
  const [gridItemWidthMobile, setGridItemWidthMobile] = useState(0);
  const [scrollToTop, setScrollToTop] = useState(false);

  const getGridItemWidth = useCallback(
    () => (gridRef.current ? Math.floor(gridRef.current?.clientWidth / 3) : 0),
    [carouselItems, gridRef?.current?.clientWidth, gridRef.current]
  );

  const getGridItemWidthMobile = useCallback(
    () => (gridRef.current ? Math.floor(gridRef.current?.clientWidth) : 0),
    [carouselItems, gridRef.current?.clientWidth, gridRef.current]
  );

  const isOnLastSlide = currentCarouselIndex === carouselItems.length - 1;

  useLayoutEffect(() => {
    setGridItemWidth(getGridItemWidth());
    setGridItemWidthMobile(getGridItemWidthMobile());
  }, [gridRef.current?.clientWidth]);

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setScrollToTop(false);
    }
  }, [scrollToTop]);

  return (
    <Box position="relative">
      <div
        ref={gridRef}
        css={css`
          display: flex;
          width: 100%;
          overflow-x: hidden;
        `}
      >
        {gridItemWidth === 0 ? (
          <Loader />
        ) : (
          <Grid
            height="fit-content"
            gridTemplateColumns={`repeat(${carouselItems.length}, ${gridItemWidth}px)`}
            gridColumnGap="0"
            mt="20px"
            mobileCss={css`
              grid-template-columns: repeat(
                ${carouselItems.length},
                ${gridItemWidthMobile}px
              );
              width: 100vw;
              transform: translateX(
                -${gridItemWidthMobile * currentCarouselIndex}px
              );
            `}
            overrideCss={css`
              @media ${theme.mediaQueries.tabletUp} {
                ${currentCarouselIndex === 0 &&
                css`
                  transform: translateX(
                    calc(50% - ${Math.ceil(gridItemWidth)}px)
                  );
                `}
                ${currentCarouselIndex > 1 &&
                css`
                  transform: translateX(
                    -${gridItemWidth * (currentCarouselIndex - 1)}px
                  );
                `}
              }

              transition: all 0.3s ease;
            `}
          >
            {Array.from({ length: carouselItems.length }).map((_, i) => (
              <Box
                my="auto"
                key={i}
                mobileCss={css`
                  margin-top: unset;
                  margin-bottom: auto;
                  min-width: 320px;
                  overflow-x: hidden;
                  opacity: 1;
                  transform: scale(1);
                `}
                overrideCss={css`
                  transition: all 0.3s ease;
                  transform: scale(${currentCarouselIndex === i ? 1 : 0.7});
                  opacity: ${currentCarouselIndex === i
                    ? 1
                    : currentCarouselIndex - 1 === i
                    ? 0.5
                    : currentCarouselIndex + 1 === i
                    ? 0.5
                    : 0};
                  display: ${currentCarouselIndex === i
                    ? 'block'
                    : currentCarouselIndex - 1 === i
                    ? 'block'
                    : currentCarouselIndex + 1 === i
                    ? 'block'
                    : 'hidden'};
                `}
              >
                {carouselItems[i]}
              </Box>
            ))}
          </Grid>
        )}
      </div>
      <Flex
        width="100%"
        justifyContent="space-between"
        mobileCss={css`
          background-color: white;
          width: 100vw;
          padding: 16px;
          position: fixed;
          bottom: 0px;
          left: 0px;
          box-shadow: ${theme.elevationShadow};
          z-index: 100;
        `}
      >
        <Button
          variant="cta"
          width="100%"
          desktopCss={css`
            width: fit-content;
          `}
          mr="10px"
          disabled={currentCarouselIndex === 0}
          onClick={() => {
            setCurrentCarouselIndex((i) => (i >= 0 ? i - 1 : 0));
            setScrollToTop(true);
          }}
        >
          Previous
        </Button>
        <Button
          desktopCss={css`
            width: fit-content;
          `}
          width="100%"
          variant="cta"
          disabled={!lastSlideCallback && isOnLastSlide}
          onClick={() => {
            setScrollToTop(true);
            if (lastSlideCallback && isOnLastSlide) {
              lastSlideCallback();
            } else {
              setCurrentCarouselIndex((i) =>
                i >= carouselItems.length ? carouselItems.length : i + 1
              );
            }
          }}
        >
          {lastSlideCallback && isOnLastSlide ? 'Finish' : 'Next'}
        </Button>
      </Flex>
    </Box>
  );
};

export default AnimatedCarousel;
