import styled, { keyframes } from 'styled-components';

export const PageContainer = styled.div`
  width: 100vw;
  min-height: 100vh; 
`;

export const TopRow = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
  padding: 0 20px;
  flex-direction: column;
  align-items: flex-start;
  &::after {
    flex: 1;
    content: '';
  }
  @media only screen and (min-width: 1200px) {
    margin-top: 22px;
    padding: 0 50px;
    flex-direction: row;
    align-items: center;
  }
  @media only screen and (min-width: 1000px) {
    /* padding: 0 75px; */
  }
  @media only screen and (min-width: 1400px) {
    padding: 0 100px;
  }
`;

export const Title = styled.h1`
  font: normal normal 500 ${(props) => (props.theme.mobileFontSizes[3])} ${(props) => (props.theme.font)};
  color: ${(props) => (props.theme.colors.dark)};
  width: 100%;
  text-align: center;
  margin: 18px 0;

  @media only screen and (min-width: 1200px) {
    font: normal normal 500 ${(props) => (props.theme.fontSizes[3])} ${(props) => (props.theme.font)};
    margin: 35px 0;
  }
`;

export const Banner = styled.div`
  height: 100px;
  width: 100%;

  background: url(${(props) => props.src}) no-repeat;
  background-position: center;
  background-size: cover;
`;

export const WardComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justified-content: stretch;
  margin: -25px 20px 47px 20px;

  @media only screen and (min-width: 1200px) {
    margin: -34px 125px 30px 125px;
    justified-content: center;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
  
  @media only screen and (min-width: 1200px) {
    margin-bottom: 25px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    margin-bottom: 16px;
  }
`;

export const CompareRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LocationLabel = styled.p`
  font: normal normal normal 16px/21px Roboto;
  color: #0A0918;
  margin: 0;
  padding: 0;
  
  @media only screen and (min-width: 1000px) {
    font: normal normal normal 25px/30px Roboto;
  }
`;

export const Grid = styled.div`
  width: calc(100% - 40px);
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(11, minmax(0, 1fr));
  grid-auto-rows: minmax(150px, auto);
  margin: 0 20px 20px 20px;
  
  @media only screen and (min-width: 1200px) {
    width: calc(100% - 40px);
    grid-column-gap: 40px;
    grid-row-gap: 24px;
    grid-template-columns: repeat(11, minmax(0, 1fr));
    grid-auto-rows: minmax(150px, auto); // change to auto
    margin: 0 20px 20px 20px;
  }

  @media only screen and (min-width: 1600px) {
    width: calc(100% - 160px);
    margin: 0 80px 80px 80px;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;    
  border: 1px solid #DEE2E6;
  border-radius: 5px;

  grid-column: 1 / 12;
  grid-row: auto;

  transition: grid-area 1s ease-in-out;
  
  @media only screen and (min-width: 1200px) {
    grid-area: ${(props) => props.area};
    padding: 12px 20px;
    border: 1px solid #DEE2E6;
    border-radius: 5px;
  }

  @media only screen and (min-width: 1400px) {
    padding: 24px 40px;
  }
`;

export const GridHeader = styled.div`
  display: flex;
  margin-bottom: 8px;

  @media only screen and (min-width: 1200px) {
    margin-bottom: 16px;   
  }
`;

export const Line = styled.div`
  height: 6px;
  width: 100%;
  background: rgba(222, 226, 230, 0.2);
  margin-bottom: 12px;
`;

export const ItemHeading = styled.h3`
  font: normal normal 500 ${(props) => (props.theme.fontSizes[0])} ${(props) => (props.theme.font)};
  color: #0A0918;
  padding: 0;
  margin: 0;

  @media only screen and (min-width: 1200px) {
    font: normal normal 500 ${(props) => (props.theme.fontSizes[0])} ${(props) => (props.theme.font)};
  }
`;

export const CompareButton = styled.div`
  cursor: pointer;
  color: white;
  height: 24px;
  font: normal normal normal 12px/24px Roboto;
  background: #E16E21;
  box-shadow: 0px 0px 30px #00000014;
  border-radius: 5px;
  padding: 0 20px;

  @media only screen and (min-width: 1200px) {
    height: 34px;
    font: normal normal normal 20px/34px Roboto;
  }
`;

export const TabRow = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(178, 188, 193, 0.3);
  width: 100%;
  padding-left: 25px;
  margin-bottom: 20px;
`;

export const Tab = styled.div`
  cursor: pointer;
  font: normal normal normal 12px/14px Roboto;
  padding: 8px;
  color: ${(props) => (props.active ? '#F16522' : '#0A0918')};
  border-bottom: 1px solid ${(props) => (props.active ? '#F16522' : 'rgba(178, 188, 193, 0.3)')};
  margin-bottom: -1px;
`;

export const OrgUnitRow = styled.div`
  display: flex;
  width : 100%;
  height: 200px;

  /* @media only screen and (min-width: 1200px) {
  } */
`;

export const OrgUnitList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 200px; */
  overflow-y: auto;
  scrollbar-width: thin;
  padding-right: 0px;
  margin: 0 10px;

  &:not(:first-child()) {
    margin-left: 35px;
  }
  
  @media only screen and (min-width: 1200px) {
    margin: 10px 10px;
  }
`;

export const OrgUnitItem = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #D9DEE2;
  min-height: 30px;
  max-height: 30px;
  align-items: center;

  img {
    width: 15px;
    margin-right: 15px;
    filter: ${(props) => (props.primary ? 'invert(51%) sepia(100%) saturate(1121%) hue-rotate(343deg) brightness(99%) contrast(98%)' : 'invert(47%) sepia(89%) saturate(430%) hue-rotate(148deg) brightness(89%) contrast(88%)')};
  }

  p {
    font: normal normal normal 12px/14px Roboto;
    color: #0A0918;
    margin: 0;
    padding: 0;
  }
`;

export const ReportCount = styled.div`
  font: normal normal 500 10px/12px Roboto;
  color: ${(props) => {
    if (props.primary) return '#F16522';
    if (props.secondary) return '#2096BA';
    return '#D9DEE2';
  }};
  ${(props) => ((props.primary || props.secondary)
    ? `
    margin-left: auto;
  `
    : `
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #D9DEE2;
  `)}
`;

export const MapLegend = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  p {
    font: normal normal normal ${(props) => (props.theme.mobileFontSizes[0])} ${(props) => (props.theme.font)};
    color: #0A0918;
    margin: 0;
    padding: 0;
    margin-right: 15px;
  }
  div {
    height: 8px;
    width: 20px;
    border-radius: 2px;
    margin-left: 2px;
    &:nth-of-type(1) { background: rgba(255, 88, 10, 0.2); }
    &:nth-of-type(2) { background: rgba(255, 88, 10, 0.4); }
    &:nth-of-type(3) { background: rgba(255, 83, 3, 0.6); }
    &:nth-of-type(4) { background: rgba(255, 100, 28, 0.8); }
    &:nth-of-type(5) { background: #F16522; }
  }
  @media only screen and (min-width: 1200px) {
    p {
      font: normal normal normal 14px/16px Roboto;
    }
    div {
      height: 8px;
      width: 65px;
    }
  }
`;

export const PieChartContainer = styled.div`
  display: flex;
  width: 100%;
  margin: auto 0;
  
  &:nth-of-type(1) {
    border-top: 2px solid #D9DEE2;
  }
`;

export const PieChartWrapperContainer = styled.div`
  max-width: calc(100% - 60px);
  width: 100%;
`;

export const PieLegend = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const PieLegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  
  div {
    background: ${(props) => (props.fill || '#D9DEE2')};
    border-radius: 2px;
    height: 10px;
    width: 10px;
    margin-right: 4px;
    flex-shrink: 0;
  }
  
  p {
    font: normal normal normal 14px/16px Roboto;
    color: #0A0918;
  }
`;

export const TimeContainer = styled.div`
  flex-shrink: 0;  
  position: relative;
  margin-left: auto;
`;

export const TimeButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  
  p {
    font: normal normal normal 12px/14px Roboto;
    color: #DF6E21;
    margin: 0;
    padding: 0;
  }

  img {
    margin-left: 8px;
    height: 6px;
    filter: invert(51%) sepia(100%) saturate(1121%) hue-rotate(343deg) brightness(99%) contrast(98%);
  }
`;

export const TimeDropdown = styled.div`
  z-index: 2;
  position: absolute;
  right: 0;
  width: 175px;
  top: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000017;
  border-radius: 10px;
  padding: 12px;

  @media only screen and (min-width: 1200px) {
    border-radius: 16px;
    padding: 25px;
  }
`;

export const TimeOption = styled.div`
  font: normal normal normal 12px/14px Roboto;
  color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding: 0 15px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const KPIRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 10px;
`;

export const KPIContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const KPINumber = styled.div`
  /* padding: 5px 5px 5px 5px; */
  font: normal normal 500 24px/29px Roboto;
  color: ${({ primary = false }) => (primary ? '#E6632B' : '#299ECC')};

  @media only screen and (min-width: 1200px) {
    /* padding: 8px 8px 8px 8px; */
    /* font: normal normal 500 24px/29px Roboto; */
  }
`;

export const KPILabel = styled.p`
  color: #0A0918;
  font: normal normal normal 10px/12px Roboto;
  border-top: 1px solid #D9DEE2;
  padding-top: 8px;
  margin-top: 8px;
  max-width: 100px;

  @media only screen and (min-width: 1200px) {
    font: normal normal normal 12px/14px Roboto;
  }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  margin: auto auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #DF6E21;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spinAnimation} 1s linear infinite;
  flex-shrink: 0;
`;

export const ResolutionChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 1200px) {
    flex-direction: row;

    &:nth-child() {
      flex: 1;
    }
  }
`;
