import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'styles/selectCityModal.scss';

export default class OutsideBoundsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  show = async () => this.setState({ show: true });

  hide = () => this.setState({ show: false });

  render() {
    const { show } = this.state;
    return (
      <Modal
        id="selectCityModal"
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => this.hide()}
      >
        <Modal.Body
          className="selectCityModalBody"
        >
          <div className="modalBodyAlert">
            <div className="modalHeadingAlert">
              Location not supported
            </div>

            <div className="modalTextAlert">
              It looks like you tried to log a report outside of your selected
              {' '}
              <div className="textAccentOrange">
                smart city zone.
              </div>
              {' '}
              Try creating a report within the orange area on the map.
            </div>

            <Button
              className="homeButton"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                fontSize: '1.2rem',
                padding: '10px 32px',
                float: 'initial',
                margin: '30px 0px 0px',
              }}
              onClick={() => this.hide()}
            >
              CLOSE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
