/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

import { submitForm } from 'interface/firebase';
import Button from 'components/home/button';
import { recaptchaSiteKey } from 'constants/app';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  font-family: Gotham;
  color: #b3b3b3;
  border: 1px solid ${({ error = false }) => (error ? '#de6e21' : '#b0b0b0')};
  border-radius: 8px;
  padding: 1.75rem;
  margin-bottom: 3rem;
  width: 100%;
  font-size: 1.75rem;
  @media only screen and (max-width: 650px) {
    font-size: 1.25rem;
    padding: 1.25rem;
    margin-bottom: 2rem;
  }
  ::placeholder {
    opacity: 1;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 10px #cccccc;
  }
`;

const InputIcon = styled(Input)`
  background-image: url(/${({ icon }) => icon});
  background-size: 2rem;
  background-position: 1.5rem 50%;
  background-repeat: no-repeat;
  padding-left: 6rem;
  @media only screen and (max-width: 650px) {
    background-size: 1.25rem;
    background-position: 1.25rem 50%;
    padding-left: 4rem;
  }
`;

const Textarea = styled.textarea`
  font-family: Gotham;
  color: #b3b3b3;
  font-size: 1.75rem;
  background-image: url(/${({ icon }) => icon});
  background-size: 2rem;
  background-position: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  padding: 1.75rem;
  padding-left: 6rem;
  margin-bottom: 3rem;
  color: #b3b3b3;
  border: 1px solid ${({ error = false }) => (error ? '#de6e21' : '#b0b0b0')};
  border-radius: 8px;
  width: 100%;
  @media only screen and (max-width: 650px) {
    background-size: 1.25rem;
    padding: 1.25rem;
    padding-left: 4rem;
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 10px
      ${({ error = false }) => (error ? '#e97324' : '#cccccc')};
  }
  ::placeholder {
    opacity: 1;
  }
`;

const Select = styled.select`
  appearance: none;
  font-family: Gotham;
  color: #b3b3b3;
  background-color: white;
  border: 1px solid ${({ error = false }) => (error ? '#de6e21' : '#b0b0b0')};
  border-radius: 8px;
  padding: 1.75rem;
  ${({ icon }) =>
    icon &&
    `
    padding-left: 6rem;
  `}
  margin-bottom: 3rem;
  width: 100%;
  font-size: 1.75rem;
  background-image: url('/home/dropdown.svg'), url(/${({ icon }) => icon});
  background-size: 2rem, 2rem;
  background-position: 95% 50%, 1.5rem 50%;
  background-repeat: no-repeat, no-repeat;
  @media only screen and (max-width: 650px) {
    font-size: 1.25rem;
    padding: 1.25rem;
    ${({ icon }) =>
      icon &&
      `
      padding-left: 4rem;
    `}
    margin-bottom: 2rem;
    background-size: 1.25rem;
  }
  ::placeholder {
    opacity: 1;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 10px
      ${({ error = false }) => (error ? '#de6e21' : '#cccccc')};
  }
`;

const Option = styled.option`
  padding: 1.75rem;
  font-size: 1.75rem;
  color: #b3b3b3;
  @media only screen and (max-width: 650px) {
    font-size: 1.25rem;
    padding: 1.25rem;
  }
`;

const Label = styled.label`
  display: block;
  color: #b3b3b3;
  font-size: 1.75rem;
  line-height: 2rem;
  margin: 1.5rem 0;
  @media only screen and (max-width: 650px) {
    font-size: 1.25rem;
    margin: 1rem 0;
  }
`;

const Error = styled.label`
  display: block;
  margin: 0;
  color: #de6e21;
  font-size: 1rem;
  line-height: 1rem;
  margin-top: -2rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 650px) {
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-top: -1.5rem;
    margin-bottom: 0.75rem;
  }
`;

const ButtonContainer = styled.div`
  margin: 1.5rem auto;
`;

export function VoteForm() {
  const [isHuman, setIsHuman] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (isHuman) {
      const response = submitForm('vote-to-add-city', data);
      alert('Form successfully submitted');
      console.log('firebase response: ', response);
      console.log('formdata: ', data);
      reset();
    } else {
      alert('Please complete ReCAPTCHA');
    }
  };
  const onHuman = (value) => {
    console.log('Captcha value:', value);
    setIsHuman(true);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputIcon
        error={errors.fullName}
        placeholder="Full Name"
        icon="home/name.svg"
        {...register('fullName', { required: true })}
      />
      {errors.fullName && <Error>Full name is required</Error>}

      <InputIcon
        error={errors.email}
        placeholder="Email"
        icon="home/email.svg"
        {...register('email', {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        })}
      />
      {errors.email && (
        <Error>{errors.email.message || 'Email is required'}</Error>
      )}

      <Label>
        Please specify the city that you would like to turn into a My Smart
        City:
      </Label>
      <Input
        error={errors.city}
        placeholder="Type out a city"
        {...register('city', { required: true })}
      />
      {errors.city && <Error>City is required</Error>}

      <Label>
        Do you reside in the city that you wish to make a My Smart City?
      </Label>
      <Select
        error={errors.resident}
        {...register('resident', { required: true })}
      >
        <Option value="yes">Yes</Option>
        <Option value="no">No</Option>
      </Select>
      {errors.resident && <Error>Resident is required</Error>}

      <ButtonContainer>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={onHuman}
          size="compact"
        />
      </ButtonContainer>
      <ButtonContainer>
        <Button label="submit" formSubmit arrow />
      </ButtonContainer>
    </Form>
  );
}

export function PartnerForm() {
  const [isHuman, setIsHuman] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const validate = () => getValues('query') !== '';

  const onSubmit = (data) => {
    if (isHuman) {
      const response = submitForm('become-service-partner', data);
      alert('Form successfully submitted');
      console.log('firebase response: ', response);
      console.log('formdata: ', data);
      reset();
    } else {
      alert('Please complete ReCAPTCHA');
    }
  };
  const onHuman = (value) => {
    console.log('Captcha value:', value);
    setIsHuman(true);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputIcon
        error={errors.fullName}
        placeholder="Full Name"
        icon="home/name.svg"
        {...register('fullName', { required: true })}
      />
      {errors.fullName && <Error>Full name is required</Error>}

      <InputIcon
        error={errors.email}
        placeholder="Email"
        icon="home/email.svg"
        {...register('email', {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        })}
      />
      {errors.email && <Error>Full name is required</Error>}

      <InputIcon
        error={errors.contactNumber}
        placeholder="Contact Number"
        icon="home/phone.svg"
        {...register('contactNumber', { required: true })}
      />
      {errors.contactNumber && <Error>Full name is required</Error>}

      <InputIcon
        error={errors.organisation}
        placeholder="Organisation"
        icon="home/organisation.svg"
        {...register('organisation', { required: true })}
      />
      {errors.organisation && <Error>Full name is required</Error>}

      <Label>How did you hear about My Smart City?</Label>
      <Select
        error={errors.howHeardAboutMSC}
        {...register('howHeardAboutMSC', { required: true, validate })}
      >
        <Option disabled selected value="">
          Choose one
        </Option>
        <Option value="social media">Social media</Option>
        <Option value="word-of-mouth">Word-of-mouth</Option>
        <Option value="radio">Radio</Option>
        <Option value="print advertisement">Print advertisement</Option>
      </Select>
      {errors.howHeardAboutMSC && <Error>This is a required field</Error>}

      <ButtonContainer>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={onHuman}
          size="compact"
        />
      </ButtonContainer>
      <ButtonContainer>
        <Button label="submit" formSubmit arrow />
      </ButtonContainer>
    </Form>
  );
}

export function BecomeMSCForm() {
  const [isHuman, setIsHuman] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const validate = () => getValues('query') !== '';

  const onSubmit = (data) => {
    if (isHuman) {
      const response = submitForm('become-my-smart-city', data);
      alert('Form successfully submitted');
      console.log('firebase response: ', response);
      console.log('formdata: ', data);
      reset();
    } else {
      alert('Please complete ReCAPTCHA');
    }
  };
  const onHuman = (value) => {
    console.log('Captcha value:', value);
    setIsHuman(true);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputIcon
        error={errors.fullName}
        placeholder="Full Name"
        icon="home/name.svg"
        {...register('fullName', { required: true })}
      />
      {errors.fullName && <Error>Full name is required</Error>}

      <InputIcon
        error={errors.email}
        placeholder="Email"
        icon="home/email.svg"
        {...register('email', {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        })}
      />
      {errors.email && <Error>Email is required</Error>}

      <InputIcon
        error={errors.contactNumber}
        placeholder="Contact Number"
        icon="home/phone.svg"
        {...register('contactNumber', { required: true })}
      />
      {errors.contactNumber && <Error>Contact Number is required</Error>}

      <Label>Please specify the city/cities that you represent:</Label>
      <Input
        error={errors.city}
        placeholder="Type something out"
        {...register('city', { required: true })}
      />
      {errors.city && <Error>City is required</Error>}

      <Label>How did you hear about My Smart City?</Label>
      <Select
        error={errors.howHeardAboutMSC}
        {...register('howHeardAboutMSC', { required: true, validate })}
      >
        <Option disabled selected value="">
          Choose one
        </Option>
        <Option value="social media">Social media</Option>
        <Option value="word-of-mouth">Word-of-mouth</Option>
        <Option value="radio">Radio</Option>
        <Option value="print advertisement">Print advertisement</Option>
      </Select>
      {errors.howHeardAboutMSC && <Error>This is a required field</Error>}

      <ButtonContainer>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={onHuman}
          size="compact"
        />
      </ButtonContainer>
      <ButtonContainer>
        <Button label="submit" formSubmit arrow />
      </ButtonContainer>
    </Form>
  );
}

export function ContactForm() {
  const [isHuman, setIsHuman] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const onSubmit = (data) => {
    if (isHuman) {
      const response = submitForm('contact-us', data);
      alert('Form successfully submitted');
      console.log('firebase response: ', response);
      console.log('formdata: ', data);
      reset();
    } else {
      alert('Please complete ReCAPTCHA');
    }
  };
  const onHuman = (value) => {
    console.log('Captcha value:', value);
    setIsHuman(true);
  };

  const validate = () => getValues('query') !== '';

  const Container = styled.div`
    margin-top: 1rem;
    margin-left: auto;
  `;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputIcon
        error={errors.fullName}
        placeholder="Full Name"
        icon="home/name.svg"
        {...register('fullName', { required: true })}
      />
      {errors.fullName && <Error>Full name is required</Error>}

      <InputIcon
        error={errors.email}
        placeholder="Email"
        icon="home/email.svg"
        {...register('email', {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        })}
      />
      {errors.email && <Error>Email is required</Error>}

      <InputIcon
        error={errors.contactNumber}
        placeholder="Contact Number"
        icon="home/phone.svg"
        {...register('contactNumber', { required: true })}
      />
      {errors.contactNumber && <Error>Contact Number is required</Error>}

      <Select
        error={errors.query}
        icon="contactus/query.svg"
        {...register('query', { required: true, validate })}
      >
        <Option disabled selected value="">
          Query type
        </Option>
        <Option value="customer service">Customer Service</Option>
        <Option value="privacy issues">Privacy Issues</Option>
        <Option value="technical issues">Technical Issues</Option>
        <Option value="suggestions">Suggestions</Option>
        <Option value="other">Other</Option>
      </Select>
      {errors.query && <Error>Query is required</Error>}

      <Textarea
        error={errors.description}
        placeholder="Description of how we can assist?"
        icon="contactus/pencil.svg"
        rows="4"
        {...register('description', { required: true })}
      />
      {errors.description && <Error>Description is required</Error>}

      <ButtonContainer>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={onHuman}
          size="compact"
        />
      </ButtonContainer>
      <Container>
        <Button label="submit" formSubmit arrow />
      </Container>
    </Form>
  );
}

export function UnsubscribeForm() {
  const [isHuman, setIsHuman] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const onSubmit = (data) => {
    if (isHuman) {
      const response = submitForm('crm-unsubscribe', data);
      alert('Form successfully submitted');
      console.log('firebase response: ', response);
      console.log('formdata: ', data);
      reset();
    } else {
      alert('Please complete ReCAPTCHA');
    }
  };
  const onHuman = (value) => {
    console.log('Captcha value:', value);
    setIsHuman(true);
  };

  const validate = () => getValues('query') !== '';

  const Container = styled.div`
    margin-top: 1rem;
    margin: 0 auto;
  `;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputIcon
        error={errors.email}
        placeholder="Your email address"
        icon="home/email.svg"
        {...register('email', {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        })}
      />
      {errors.email && <Error>Email is required</Error>}

      <Label>Unsubscribe me from:</Label>
      <Select
        error={errors.unsubscribe}
        icon="contactus/query.svg"
        {...register('unsubscribe', { required: true, validate })}
      >
        <Option defaultValue value="everything">
          Everything
        </Option>
        <Option value="emails">Newsletters</Option>
      </Select>
      {errors.unsubscribe && <Error>This is required</Error>}

      <ButtonContainer>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={onHuman}
          size="compact"
        />
      </ButtonContainer>
      <Container>
        <Button label="unsubscribe" formSubmit arrow />
      </Container>
    </Form>
  );
}

export function FormModal({ show, onHide, header, children }) {
  const StyledModal = styled(Modal)`
    color: #979797;
    .modal-content {
      border-radius: 15px !important;
      @media only screen and (max-width: 650px) {
        border-radius: 15px !important;
      }
    }
  `;
  const Header = styled(Modal.Header)`
    border: 0;
    padding: 3rem;
    @media only screen and (max-width: 650px) {
      padding: 1rem;
    }
  `;
  const Title = styled.div`
    margin-left: auto;
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-left: 2rem;
    line-height: 2rem;
    @media only screen and (max-width: 650px) {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  `;
  const Body = styled(Modal.Body)`
    margin-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    @media only screen and (max-width: 650px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  `;

  return (
    <StyledModal
      show={show}
      size="lg"
      centered
      scrollable
      onHide={() => onHide()}
    >
      <Header closeButton>
        <Title>{header}</Title>
      </Header>
      <Body>{children}</Body>
    </StyledModal>
  );
}
