/** @jsxImportSource @emotion/react */
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { Button, Image, Modal, TextField } from 'components/elements';
import theme from 'theme';
import {
  SmartCityServiceBooking,
  CustomerPremises,
  LocalStorageIndex,
  UserPaymentMethod,
} from 'types/types';
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { getResourceProfilePic } from 'functions/private-services';
import StarRating from 'components/star-rating';
import { convertToReadableDate } from 'functions/date';
import { useMakeABookingContext } from 'contexts/booking';
import { Loader } from 'components/inthecity';
import { peachSource } from 'constants/peach';
import {
  formatForcelinkAddress,
  shortenWithEllipses,
} from 'functions/address-utils';
import { useGlobalContext } from 'contexts/global';
import { isValidWorkOrder } from 'interface/app';
import { SmallLoader } from 'components/private-services/survey';
import { PrivateServicesEvents, logFirebaseEvent } from 'functions/firebase';

const BookingSectionWrapper = ({
  children,
  title,
  isLast,
}: {
  children: React.ReactNode;
  title: string;
  isLast?: boolean;
}) => (
  <Box
    borderB={!isLast ? `1px solid ${theme.colors.borderGrey}` : ''}
    pb="20px"
    mt="10px"
    overrideCss={css`
      @media ${theme.mediaQueries.mobileOnly} {
        padding: 0 10px 20px 10px;
        ${!isLast &&
        css`
          border-bottom: 5px solid ${theme.colors.borderGrey};
        `}
      }
    `}
  >
    <Text
      fontSize="20px"
      fontWeight="bold"
      mb="10px"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          font-size: 14px;
          color: ${theme.colors.lightGrey};
          font-weight: normal;
        }
      `}
    >
      {title}
    </Text>
    {children}
  </Box>
);

const Payment = ({
  price,
  insured,
  booking,
  couponCode,
  couponDiscount,
}: {
  price: number;
  insured?: boolean;
  booking: SmartCityServiceBooking;
  couponCode?: string;
  couponDiscount: number;
}) => {
  const {
    globalState: {
      user: { cards },
    },
  } = useGlobalContext();
  const savedPaymentMethods: UserPaymentMethod[] = cards || [];
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [bookingId, setBookingId] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<UserPaymentMethod | null>(savedPaymentMethods[0]);
  const [paymentMethodsModalOpen, setPaymentMethodsModalOpen] = useState(false);
  const [loadingValidBooking, setLoadingValidBooking] = useState(false);
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);

  const {
    loading: isSubmitting,
    response: bookingResponse,
    callApi: makeBooking,
    error,
  } = useSmartCityManager(SCManagerEndpoints.BookService);

  const {
    loading: peachInitializing,
    response: purchaseId,
    callApi: doInitializePeach,
    // error: peachInitilizationError,
  } = useSmartCityManager(SCManagerEndpoints.DoInitializePeach);

  const { callApi: confirmCostFree, response: confirmCostFreeResponse } =
    useSmartCityManager(SCManagerEndpoints.ConfirmCostFreeReschedule);

  useEffect(() => {
    if (!error && bookingResponse) {
      setBookingId(bookingResponse);
    }
  }, [error, bookingResponse]);

  useEffect(() => {
    const proceedBooking = async () => {
      if (bookingId) {
        const validBooking = await isValidWorkOrder(bookingId);
        setLoadingValidBooking(false);
        if (validBooking.valid) {
          if (price - couponDiscount === 0) {
            setLoadingModalOpen(true);
            confirmCostFree({ queryParams: `productId=${bookingId}` });
          } else {
            setIsPaymentModalOpen(true);
            doInitializePeach({
              queryParams: `price=${(price - couponDiscount).toFixed(
                2
              )}&productId=${bookingId}&privateServices=true${
                selectedPaymentMethod
                  ? `&cardUUID=${selectedPaymentMethod.uuid}`
                  : ''
              }`,
            });
          }
        }
      }
    };
    proceedBooking();
  }, [bookingId]);

  useEffect(() => {
    if (confirmCostFreeResponse) {
      setLoadingModalOpen(false);
      if (confirmCostFreeResponse === 'Invalid') {
        alert(
          'Unfortunately, this coupon reached its usage limit. Please retry for a reduced discount if available.'
        );
      } else {
        navigate('/my-bookings');
      }
    }
  }, [confirmCostFreeResponse]);

  const handleMakeBookingClick = () => {
    setLoadingValidBooking(true);
    makeBooking({
      body: booking,
    });
    localStorage.setItem(
      LocalStorageIndex.LATEST_BOOKING,
      JSON.stringify(booking)
    );
    logFirebaseEvent(PrivateServicesEvents.MakeBooking);
  };

  return (
    <Box
      width="100%"
      backgroundColor="rgb(243, 245, 246)"
      borderRadius={theme.borderRadius.small}
      p="12px"
      height="fit-content"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100vw;
          background-color: white;
          border-top: ${theme.borders.standard1px};
          border-radius: 0;
          box-shadow: 0px -4px 10px -1px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      {/** Loading while validating free booking */}
      <Modal
        isOpen={loadingModalOpen}
        onClose={() => setLoadingModalOpen(false)}
        title=""
      >
        <Loader />
      </Modal>
      {/** Payment method selection */}
      <Modal
        isOpen={paymentMethodsModalOpen}
        onClose={() => setPaymentMethodsModalOpen(false)}
        title="Select a payment method"
      >
        <Flex flexDirection="column">
          {savedPaymentMethods.map((method, idx) => (
            <Button
              onClick={() => {
                setSelectedPaymentMethod(method);
                setPaymentMethodsModalOpen(false);
              }}
              key={idx}
              mb="10px"
            >
              <Flex
                border={
                  selectedPaymentMethod?.bin === method.bin
                    ? `1px solid ${theme.colors.primary}`
                    : theme.borders.standard1px
                }
                borderRadius={theme.borderRadius.small}
                p="10px"
                justifyContent="space-between"
                alignItems="center"
                backgroundColor={
                  selectedPaymentMethod?.bin === method.bin
                    ? theme.colors.selectionPrimary
                    : 'white'
                }
              >
                <Image source="icons/card.svg" alt="card" />
                <Text fontWeight="bold">
                  **** **** **** {savedPaymentMethods[0].lastDigits}
                </Text>
                <Text color={theme.colors.lightGrey}>
                  Expiry: {savedPaymentMethods[0].expiryMonth}/
                  {savedPaymentMethods[0].expiryYear}
                </Text>
              </Flex>
            </Button>
          ))}
          <Button
            variant="outlined"
            mt="10px"
            fontWeight="bold"
            onClick={() => {
              setSelectedPaymentMethod(null);
              setPaymentMethodsModalOpen(false);
              handleMakeBookingClick();
            }}
          >
            Use a different payment method
          </Button>
        </Flex>
      </Modal>
      {/** Payment modal */}
      <Modal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        slideFromBottomMobile
        title="Payment"
      >
        {peachInitializing ? (
          <Loader />
        ) : (
          <iframe
            srcDoc={peachSource(purchaseId, `${location.pathname}`, false)}
            width="100%"
            height="400px"
            title="payment"
            css={css`
              border: none;
            `}
          />
        )}
      </Modal>
      <Flex
        justifyContent="space-between"
        py="10px"
        borderB={theme.borders.standard1px}
      >
        <Text>Service (incl VAT)</Text>
        <Text>{`R${
          insured ? (price - 15).toFixed(2) : price.toFixed(2)
        }`}</Text>
      </Flex>
      {couponCode !== '' && couponDiscount > 0 && (
        <Flex
          justifyContent="space-between"
          py="10px"
          borderB={theme.borders.standard1px}
        >
          <Text
            overrideCss={css`
              color: ${theme.colors.green};
            `}
          >
            Coupon Discount Amount
          </Text>
          <Text
            overrideCss={css`
              color: ${theme.colors.green};
            `}
          >{`-R${couponDiscount.toFixed(2)}`}</Text>
        </Flex>
      )}
      {insured && (
        <Flex
          justifyContent="space-between"
          py="10px"
          borderB={theme.borders.standard1px}
        >
          <Text>Insurance</Text>
          <Text>R15.00</Text>
        </Flex>
      )}
      <Flex justifyContent="space-between" py="10px">
        <Text fontSize="16px" fontWeight="bold">
          Total
        </Text>
        <Text fontSize="16px" fontWeight="bold">
          {price - couponDiscount >= 0
            ? `R${(price - couponDiscount).toFixed(2)}`
            : 'Fetching...'}
        </Text>
      </Flex>
      {savedPaymentMethods.length > 0 && (
        <Flex flexDirection="column" my="10px">
          <Flex
            border={theme.borders.standard1px}
            borderRadius={theme.borderRadius.small}
            p="10px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Image source="icons/card.svg" alt="card" />
            <Text fontWeight="bold">
              **** **** **** {savedPaymentMethods[0].lastDigits}
            </Text>
            <Text color={theme.colors.lightGrey}>
              Expiry: {savedPaymentMethods[0].expiryMonth}/
              {savedPaymentMethods[0].expiryYear}
            </Text>
          </Flex>
          <Button
            variant="outlined"
            mt="10px"
            fontWeight="bold"
            onClick={() => setPaymentMethodsModalOpen(true)}
          >
            Choose a different payment method
          </Button>
        </Flex>
      )}
      <Button
        onClick={handleMakeBookingClick}
        variant="filled"
        width="100%"
        fontWeight="bold"
        mt="10px"
        color="white"
        disabled={isSubmitting || loadingValidBooking}
        mobileCss={css`
          padding: 15px 10px;
          font-size: 16px;
        `}
      >
        {isSubmitting || loadingValidBooking ? 'Submitting...' : 'Make booking'}
      </Button>
    </Box>
  );
};

const ResourceSummary = ({ resourceId }: { resourceId: number }) => {
  const { serviceId } = useParams();
  const { response, callApi } = useSmartCityManager(
    SCManagerEndpoints.GetPrivateServicesResource
  );
  const [resource, setResource] = useState<any>(null);
  useEffect(() => {
    callApi({
      queryParams: `id=${resourceId}&serviceTypeId=${serviceId}`,
    });
  }, []);

  useEffect(() => {
    if (response) {
      setResource(response);
    }
  }, [response]);

  if (!resource) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      minWidth="100%"
      height="100%"
      border={theme.borders.standard1px}
      mr="20px"
      borderRadius={theme.borderRadius.small}
      p="10px"
    >
      <Grid gridTemplateColumns="1fr 5fr" gridColumnGap="5px">
        <Image
          source={getResourceProfilePic(resource.profilePic)}
          alt="Profile"
          height="40px"
          width="40px"
          borderRadius="50%"
          objectFit="cover"
        />
        <Grid gridTemplateRows="auto auto" ml="10px">
          <Flex justifyContent="space-between" width="100%" height="auto">
            <Text m={0} p={0} textAlign="left">
              {shortenWithEllipses(resource.fullName, 20)}
            </Text>
            {resource.preferredSupplier && (
              <Flex
                p="2px"
                height="fit-content"
                borderRadius={theme.borderRadius.small}
                fontSize="10px"
                backgroundColor="#00A3001A"
                color="#00A300"
              >
                <Text m={0} p={0} textAlign="center">
                  Preferred
                </Text>
              </Flex>
            )}
          </Flex>
          {resource.rating && <StarRating rating={resource.rating} />}
        </Grid>
      </Grid>
    </Flex>
  );
};

const BookingSummary = ({
  booking,
  duration,
  selectedAddress,
  backToEdit,
  openAddressModal,
  openScheduleModal,
}: {
  booking: SmartCityServiceBooking;
  duration: number;
  selectedAddress: CustomerPremises | null;
  backToEdit: () => void;
  openScheduleModal: () => void;
  openAddressModal: () => void;
}) => {
  const {
    loading: checkingCoupon,
    response: couponResponse,
    callApi: checkCoupon,
  } = useSmartCityManager(SCManagerEndpoints.IsValidCoupon);
  const { heading } = useParams();
  const { setBooking, price } = useMakeABookingContext();
  const [couponCode, setCouponCode] = useState('');

  const [isValidCouponResponse, setIsValidCouponResponse] = useState<{
    valid: Boolean;
    message: string;
    discountedAmount: number;
  }>({
    valid: false,
    message: '',
    discountedAmount: 0,
  });

  const couponMessage =
    isValidCouponResponse &&
    (isValidCouponResponse.valid
      ? isValidCouponResponse.message || 'Coupon applied successfully'
      : isValidCouponResponse.message || 'This coupon is invalid');

  useEffect(() => {
    if (couponResponse) {
      setIsValidCouponResponse(couponResponse);
    }
    if (couponResponse?.valid) {
      setBooking({
        ...booking,
        bookingInformation: {
          ...booking.bookingInformation,
          couponCode,
        },
      });
    }
  }, [couponResponse]);

  useEffect(() => {
    if (couponCode.length > 3) {
      checkCoupon({
        body: {
          ...booking,
          bookingInformation: { ...booking.bookingInformation, couponCode },
        },
      });
    }
    if (couponCode.length === 0) {
      setIsValidCouponResponse({
        valid: false,
        message: '',
        discountedAmount: 0,
      });
    }
  }, [couponCode]);

  const HandleEvent = () => {
    logFirebaseEvent(PrivateServicesEvents.BookInsurance);
    setBooking({
      ...booking,
      bookingInformation: {
        ...booking.bookingInformation,
        insured: true,
      },
    });
  };
  return (
    <Flex
      justifyContent="center"
      width="100%"
      py="50px"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          padding: 10px 0;
          height: 100%;
        }
      `}
    >
      <Grid
        gridColumnGap="70px"
        gridTemplateColumns="1fr 1fr"
        width="100%"
        maxWidth="900px"
        mx="30px"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            display: flex;
            flex-direction: column;
            margin: 0;
          }
        `}
      >
        <Box>
          <BookingSectionWrapper title="Service">
            <Box
              mb="10px"
              border={theme.borders.standard1px}
              borderRadius={theme.borderRadius.small}
              p="10px"
            >
              <Text mb="5px" fontWeight="bold">
                {heading}
              </Text>
              <Grid gridTemplateColumns="repeat(3, auto)">
                {booking.smartCityServiceGroups.map((group, idx) => (
                  <div key={idx}>
                    {group.smartCityServices.map((service, serviceIdx) =>
                      service.label && service.current > 0 ? (
                        <Text
                          key={serviceIdx}
                          color={theme.colors.lightGrey}
                          fontSize="12px"
                        >
                          {`\u2022 ${
                            service.serviceType === 1
                              ? `${service.current} `
                              : ''
                          }${service.label}`}
                        </Text>
                      ) : null
                    )}
                  </div>
                ))}
              </Grid>
            </Box>
            {booking.bookingInformation?.resourceId && (
              <ResourceSummary
                resourceId={booking.bookingInformation?.resourceId}
              />
            )}
            <Button
              variant="outlined"
              onClick={backToEdit}
              color={theme.colors.primary}
              width="100%"
              mt="10px"
            >
              Edit booking
            </Button>
          </BookingSectionWrapper>
          <BookingSectionWrapper title="Address">
            {selectedAddress && (
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Image
                  source="privateServices/gray-pin.svg"
                  alt="pin"
                  backgroundColor="rgb(248, 248, 249)"
                  borderRadius="50%"
                  p="7px"
                  mr="10px"
                  height="23px"
                  width="29px"
                />

                <Box>
                  <Text fontWeight="bold" mb="5px">
                    {selectedAddress.premiseType}
                  </Text>
                  <Text color={theme.colors.lightGrey}>
                    {formatForcelinkAddress(selectedAddress.addressString)}
                  </Text>
                </Box>
                <Button
                  height="100%"
                  ml="20px"
                  mt="5px"
                  onClick={() => {
                    backToEdit();
                    openAddressModal();
                  }}
                  mr="20px"
                >
                  <Image source="icons/edit.svg" alt="edit" height="18px" />
                </Button>
              </Flex>
            )}
          </BookingSectionWrapper>
          <BookingSectionWrapper title="Schedule">
            {booking.bookingInformation?.startDateTime && (
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Flex>
                  <Image
                    source="privateServices/calendar.svg"
                    alt="calendar"
                    mr="10px"
                  />
                  <Box>
                    <Text fontWeight="bold" mb="5px">
                      {convertToReadableDate(
                        booking.bookingInformation?.startDateTime || 0
                      )}
                    </Text>
                    <Text color={theme.colors.green}>
                      {`${
                        booking.bookingInformation?.typeId === 27147
                          ? duration + 1
                          : duration
                      } hours work`}
                    </Text>
                  </Box>
                </Flex>
                <Button
                  height="100%"
                  ml="20px"
                  mt="5px"
                  onClick={() => {
                    backToEdit();
                    openScheduleModal();
                  }}
                  mr="20px"
                >
                  <Image source="icons/edit.svg" alt="edit" height="18px" />
                </Button>
              </Flex>
            )}
          </BookingSectionWrapper>
          <BookingSectionWrapper title="Coupon">
            <Flex alignItems="center" gap="24px">
              <Box>
                <TextField
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder="Enter coupon code"
                />
              </Box>
              {checkingCoupon && <SmallLoader />}
            </Flex>
            {couponMessage && couponCode.length > 0 && !checkingCoupon && (
              <Text
                color={isValidCouponResponse?.valid ? 'green' : 'red'}
                mt="5px"
              >
                {couponMessage}
              </Text>
            )}
          </BookingSectionWrapper>
          <BookingSectionWrapper title="Insurance" isLast>
            <Flex>
              <Button
                onClick={() =>
                  setBooking({
                    ...booking,
                    bookingInformation: {
                      ...booking.bookingInformation,
                      insured: false,
                    },
                  })
                }
                p="7px"
                width="100%"
                mr="2px"
                border={`1px solid ${
                  !booking.bookingInformation?.insured
                    ? theme.colors.primary
                    : theme.colors.borderGrey
                }`}
                backgroundColor={
                  !booking.bookingInformation?.insured
                    ? 'rgba(223, 117, 49, 0.26)'
                    : 'white'
                }
                borderRadius={theme.borderRadius.small}
              >
                No
              </Button>

              <Button
                onClick={HandleEvent}
                p="7px"
                ml="2px"
                width="100%"
                border={`1px solid ${
                  booking.bookingInformation?.insured
                    ? theme.colors.primary
                    : theme.colors.borderGrey
                }`}
                backgroundColor={
                  booking.bookingInformation?.insured
                    ? 'rgba(223, 117, 49, 0.26)'
                    : 'white'
                }
                borderRadius={theme.borderRadius.small}
              >
                Yes (R15.00)
              </Button>
            </Flex>
          </BookingSectionWrapper>
          <Box height="290px" hideForTablet hideForDesktop />
        </Box>
        <Payment
          price={price}
          insured={booking.bookingInformation?.insured}
          booking={booking}
          couponCode={couponCode}
          couponDiscount={isValidCouponResponse.discountedAmount}
        />
      </Grid>
    </Flex>
  );
};

export default BookingSummary;
