/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ActionBar } from 'components';
import CreatePostMenu from 'components/social/createpostmenu';
import { getNewsFeedWorkRequest } from 'interface/social/posts';
import { SocialProvider } from 'contexts/social';
import { useGlobalContext } from 'contexts/global';
import {
  Post,
  CreatePost,
  Breadcrumbs,
  Alerts,
  Search,
} from 'components/social';
import { SuggestionList } from 'components/social/connection';
import { Heading } from 'components/social/text';
import {
  NotFoundContainer,
  ContentRow,
  TopRow,
  PageContainer,
  FilterColumn,
  FeedColumn,
  FeedHeader,
  ConnectionsColumn,
  Feed,
} from './social.styled';
import { Title, TextLink } from './connections.styled';

const PostPage = () => {
  const { id, city } = useParams();
  const navigate = useNavigate();

  if (!id || !city) {
    navigate('/social');
  }

  const [showCreatePost, setShowCreatePost] = useState(false);
  const [createPostType, setCreatePostType] = useState(null);
  const [post, setPost] = useState(null);
  const { city: userCity } = useGlobalContext();

  const handleCreatePost = (type) => {
    if (type !== null) {
      setShowCreatePost(true);
      setCreatePostType(type);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const resWr = await getNewsFeedWorkRequest(id);
      if (resWr.id) {
        setPost(resWr);
      } else {
        navigate('/social');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // if user change city, redirect to /social
    if (userCity?.value && Number(city) !== Number(userCity.value)) {
      navigate('/social');
    }
  }, [userCity]);

  return (
    <SocialProvider>
      <CreatePost
        show={showCreatePost}
        type={createPostType}
        handleClose={() => setShowCreatePost(false)}
      />
      <ActionBar>
        <CreatePostMenu handleCreatePost={handleCreatePost} />
        <Search />
      </ActionBar>

      <PageContainer>
        <TopRow>
          <Breadcrumbs />
        </TopRow>

        <ContentRow>
          <FilterColumn>{/* <Filter /> */}</FilterColumn>

          <FeedColumn>
            <Heading>Alerts</Heading>

            <Alerts />

            <FeedHeader>
              <h2>Post</h2>
            </FeedHeader>
            <Feed>
              {!post?.id && (
                <NotFoundContainer>
                  <h2>No post found</h2>
                </NotFoundContainer>
              )}
              {post?.id && (
                <Post
                  id={post.id}
                  isFirst={false}
                  uuid={post.user.uuid}
                  refNum={post.code}
                  username={post.user.fullName}
                  verified={post.user.verified}
                  role={post.user.smartCityUserRoles[0].description}
                  location={post.address}
                  timestamp={post.createdDate}
                  status={post.statusDescription}
                  wrIcon={post.customIcon}
                  type={post.typeDescription}
                  title={
                    post.announcement || post.petition
                      ? post.custom1
                      : post.title
                  }
                  text={post.description}
                  images={post.imageIds}
                  upvotes={post.upVoteCount}
                  comments={post.commentCount}
                  reposts={post.repostCount}
                  shares={post.shareCount}
                  canUpVote={post.canUpVote}
                  canRepost={post.canRepost}
                  petition={post.petition}
                  announcement={post.announcement}
                  loggedByMe={post.loggedByMe}
                  currentStatusProgress={post.currentStatusProgress}
                  totalNumberOfStatus={post.totalNumberOfStatus}
                  isRepost={post.reposted}
                  repostedUser={post.repostedUser}
                  rePostDate={post.rePostDate}
                />
              )}
            </Feed>
          </FeedColumn>

          <ConnectionsColumn>
            <Heading style={{ marginBottom: '23px' }}>Connections</Heading>
            <Title>Suggested Follows</Title>
            <SuggestionList />
            <TextLink to="/social/connections">See more suggestions</TextLink>
          </ConnectionsColumn>
        </ContentRow>
      </PageContainer>
    </SocialProvider>
  );
};

export default PostPage;
