/* Modules */
import React, {
} from 'react';
import 'styles/map.scss';
import { useNavigate } from 'react-router-dom';

export default function HistoryPush({
  pushTo,
  setState,
}) {
  const navigate = useNavigate();
  console.log('HistoryPush', pushTo);
  if (pushTo !== '') {
    console.log('HistoryPush!!!!!!!', pushTo);
    navigate(pushTo);
    setState({ pushTo: '' });
  }
  return (
    <div id="HistoryPush" style={{ display: 'none' }} />
  );
}
