import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { getPrivateServiceImage } from 'functions/private-services';
import theme from 'theme';
import { SmartCityService, SmartCityServiceGroup } from 'types/types';
import { ReactSVG } from 'react-svg';
import { useMakeABookingContext } from 'contexts/booking';

const ExtraButton = ({
  service,
  onClick,
  isSelected,
  disabled,
}: {
  service: SmartCityService;
  onClick: () => void;
  isSelected: boolean;
  disabled: boolean;
}) => (
  <Button
    disabled={disabled}
    p="7px"
    onClick={onClick}
    border={`1px solid ${
      isSelected ? theme.colors.primary : theme.colors.borderGrey
    }`}
    backgroundColor={isSelected ? 'rgba(223, 117, 49, 0.26)' : 'white'}
    borderRadius={theme.borderRadius.small}
  >
    <Grid gridTemplateColumns="auto 40px">
      <Box>
        <Box
          border={`1px solid ${
            isSelected ? theme.colors.primary : theme.colors.borderGrey
          }`}
          borderRadius="50%"
          width="20px"
          height="20px"
          p={0}
        >
          {isSelected && (
            <Image
              m={0}
              p={0}
              source="icons/successicon.svg"
              alt="tick"
              width="20px"
              height="20px"
              ml="-1px"
              mt="-1px"
            />
          )}
        </Box>
        <Text m={0} mt="10px" fontWeight="medium" textAlign="left">
          {service.label}
        </Text>
      </Box>
      <Box
        overrideCss={css`
          path,
          rect,
          circle,
          ellipse {
            fill: ${isSelected && theme.colors.primary} !important;
          }
        `}
      >
        <ReactSVG src={getPrivateServiceImage(service.icon)} />
      </Box>
    </Grid>
  </Button>
);

const ExtrasWidget = ({
  serviceGroups,
  shouldRender,
  setExtraCurrent,
  duration,
  setBookingDuration,
}: {
  serviceGroups: SmartCityServiceGroup | undefined;
  shouldRender?: boolean;
  setExtraCurrent: (extra: string, current: number, renderIdx: number) => void;
  duration: number;
  setBookingDuration: (duration: number) => void;
}) => {
  const { maxDuration } = useMakeABookingContext();
  if (!shouldRender || !serviceGroups) {
    return null;
  }

  return (
    <Box
      borderB={`1px solid ${theme.colors.borderGrey}`}
      pb="20px"
      mt="10px"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          padding: 0 10px 20px 10px;
          border-bottom: 5px solid ${theme.colors.borderGrey};
        }
      `}
    >
      <Text fontSize="20px" m={0} mb="10px">
        Extras
      </Text>
      <Grid
        gridRowGap="10px"
        gridColumnGap="10px"
        gridTemplateColumns="1fr 1fr"
      >
        {serviceGroups.smartCityServices.map(
          (service: SmartCityService, idx: number) => {
            const dur = service.duration || 0;
            return (
              <ExtraButton
                disabled={duration + dur > maxDuration && service.current === 0}
                isSelected={service.current === 1}
                service={service}
                key={idx}
                onClick={() => {
                  if (service.current === 0) {
                    setExtraCurrent(service.label, 1, idx);
                    setBookingDuration(duration + dur);
                  } else {
                    setExtraCurrent(service.label, 0, idx);
                    setBookingDuration(duration - dur);
                  }
                }}
              />
            );
          }
        )}
      </Grid>
    </Box>
  );
};

export default ExtrasWidget;
