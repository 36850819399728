import styled from 'styled-components';

export const FooterEmail = styled.div`
  background-image: url('/footer/email.png');
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
  width: 21px;
  height: 21px;
  margin-right: 15px;
`;

export const FooterPhone = styled.div`
  background-image: url('/footer/phone1.png');
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
  width: 20px;
  height: 20px;
  margin-right: 15px;
`;
