import { apiUrl, apiUrlMSC } from 'constants/app';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const fetchMSC = (endpoint, method, onSuccess, onFail, body) =>
  fetch(`${apiUrlMSC}/smartcitymanager/${endpoint}`, {
    method,
    body: body ? JSON.stringify(body) : null,
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
      credentials: 'include',
    },
  })
    .then((res) => res.json())
    .then(onSuccess)
    .catch(onFail);

// Get current user
export const getCurrentSmartCityUser = async () =>
  fetch(`${apiUrl}smartcitymanager/getCurrentSmartCityUser`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return {};
    });

/**
 * Checks if booking is still valid
 */
export const isValidWorkOrder = (productUUID) => {
  const firebaseToken = cookies.get('firebaseToken');
  return fetch(
    `${apiUrl}smartcitymanager/isValidWorkOrder?productUUID=${productUUID}`,
    {
      method: 'GET',
      async: true,
      headers: {
        firebase_token: firebaseToken,
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
      },
    }
  ).then((res) =>
    res
      .json()
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.error(err);
        return {
          isValid: false,
          message: 'Failed to verify booking',
          discountedAmount: 0,
        };
      })
  );
};
