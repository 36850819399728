/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { EventCard, PlaceCard } from 'components/inthecity';
import { Title } from 'components';
import {
  Container,
  Header,
  SeeAll,
  CardsGrid,
  LeftButton,
  RightButton,
} from './cardsViewer.styled';

/**
 * Cards Viewer Component
 */
const CardsViewer = (props) => {
  // props
  const {
    title,
    events = [],
    places = [],
    showNavigation = true,
    showDistance = false,
  } = props;

  // hooks
  const location = useLocation();
  const tab = location.state?.tab;
  const ref = useRef();

  const category = 'Museum';

  // state
  const [page, setPage] = useState(0);
  const [more, setMore] = useState(false);
  const [maxColumns, setMaxColumns] = useState(null);

  const handleClickMore = () => {
    setMore(!more);
    setPage(0);
  };

  const handleClickLeft = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleClickRight = () => {
    const length = events?.length || places?.length;

    if (page < length / 2 - 1) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    let c = 5;
    if (window.innerWidth > 800) {
      c = Math.floor(window.innerWidth / 325);
    } else {
      c = Math.floor(window.innerWidth / 200);
    }
    setMaxColumns(c);
  }, []);

  useEffect(() => {
    // reset if category changes
    setPage(0);
    setMore(false);
  }, [tab]);

  if (events?.length || places?.length) {
    return (
      <Container>
        <Header>
          <Title>{title}</Title>

          {showNavigation && (
            <>
              <SeeAll
                onClick={handleClickMore}
              >
                {more ? 'See Less' : `See All (${events?.length || places?.length})`}
              </SeeAll>
              {more ? null : (
                <>
                  <LeftButton
                    onClick={handleClickLeft}
                    active={page > 0}
                  />
                  <RightButton
                    onClick={handleClickRight}
                    active={page < (events?.length || places?.length) / 2 - 1}
                  />
                </>
              )}
            </>
          )}
        </Header>

        <div ref={ref}>
          {maxColumns && (
            <CardsGrid
              allRows={more}
              columns={maxColumns}
            >
              {/* events cards */}
              {(events && events?.length > 0) && events.slice(page * 2, more ? events.length : (page * 2) + maxColumns).map((e, index) => (
                <EventCard
                  event={e}
                  category={category}
                  key={index}
                />
              ))}

              {/* places cards */}
              {(places && places?.length > 0) && places.slice(page * 2, more ? places.length : (page * 2) + maxColumns).map((p, index) => {
                if (p.location || p.geocodes) {
                  return (
                    <PlaceCard
                      place={p}
                      showDistance={showDistance}
                      key={index}
                    />
                  );
                }
                return null;
              })}
            </CardsGrid>
          )}
        </div>
      </Container>
    );
  }
  return null;
};

export default CardsViewer;
