/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Modal } from 'components/social';
import { getImageURL } from 'interface/inthecity/inthecity';
import {
  ScrollContainer,
  BottomContainer,
  ProductItem,
  ProductContent,
  ProductTotal,
  Button,
  Line,
  Heading,
  Input,
  ErrorLabel,
  Label,
} from 'components/inthecity/lottoModal/lottoModal.styled';
import {
  ProgressLabel,
  StageCountCircle,
  ProgressHolder,
  Line as AmountLine,
  Item,
  Image,
  InfoColumn,
  ModalInfo,
  ProgressLine,
  ConfirmationLabel,
} from './amountModal.styled';

/**
 * A user selects their own amount (Direct Topup), or chooses a bundle
 */
const AmountModal = (props) => {
  // props
  const {
    show,
    handleClose,
    product,
    handleNext,
    handleBack,
    category,
    subcategory,
    handleChangeOwnAmount,
    ownAmount,
  } = props;

  // state
  const [amount, setAmount] = useState(ownAmount);
  const [valid, setValid] = useState(!(category === 'Airtime' && subcategory === 'Direct Topup'));

  const handleChangeAmount = (e) => setAmount(e.target.value);

  useEffect(() => {
    const isAmountValid = (a) => !(category === 'Airtime' && subcategory === 'Direct Topup') || (/([0-9]+)/.test(a) && a >= 5 && a <= 1000);
    setValid(isAmountValid(amount));
  }, [amount]);

  return (
    <Modal
      title={`${category} Amount`}
      show={show}
      handleClose={handleClose}
      backButton
      handleBack={handleBack}
    >
      <ProgressHolder>
        <StageCountCircle active>
          1
          <ProgressLabel>Amount</ProgressLabel>
        </StageCountCircle>
        <ProgressLine />
        <StageCountCircle>
          2
          <ProgressLabel>Phone</ProgressLabel>
        </StageCountCircle>
        <ProgressLine />
        <StageCountCircle>
          3
          <ProgressLabel>Payment</ProgressLabel>
        </StageCountCircle>
        <ProgressLine />
        <StageCountCircle>
          4
          <ConfirmationLabel>Confirmation</ConfirmationLabel>
        </StageCountCircle>
      </ProgressHolder>

      <ScrollContainer>
        <ProductItem>
          <img src={getImageURL(product?.imageName)} alt={product?.name} />
          <ProductContent>
            <p>{product?.name}</p>
          </ProductContent>
          {product?.price && (
            <ProductTotal>{`R ${(product?.price / 100).toFixed(2)}`}</ProductTotal>
          )}
        </ProductItem>

        <AmountLine />

        <Heading>Amount</Heading>

        {(category === 'Airtime' && subcategory === 'Direct Topup') && (
          <>
            <Input
              type="text"
              inputmode="numeric"
              value={amount}
              placeholder="R 0.00"
              onChange={handleChangeAmount}
            />

            <Label>Between R 5.00 and R 1000.00</Label>

            {(!valid && amount) && (
              <ErrorLabel>Please enter a valid amount.</ErrorLabel>
            )}
          </>
        )}
        {!(category === 'Airtime' && subcategory === 'Direct Topup') && (
          <Input
            readOnly
            type="text"
            value={`R ${(product?.price / 100).toFixed(2)}`}
          />
        )}
      </ScrollContainer>

      <BottomContainer>
        <Line />
        <Button
          disabled={!valid}
          onClick={valid ? () => {
            handleChangeOwnAmount(Number(amount));
            handleNext();
          } : () => {}}
        >
          Next
        </Button>
      </BottomContainer>
    </Modal>
  );
};

export default AmountModal;
