import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'styles/selectCityModal.scss';

export default class SelectCityModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      promiseInfo: {},
      availableCities: [],
    };
  }

  // Note that is promise is refed in map as myRefSelectCityModal
  show = async (availableCities) => new Promise((resolve, reject) => {
    this.setState({
      show: true,
      availableCities,
      promiseInfo: {
        resolve,
        reject,
      },
    });
  });

  hide = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    const { show, promiseInfo, availableCities } = this.state;
    console.log('city', availableCities);
    return (
      <Modal
        id="selectCityModal"
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          className="selectCityModalBody"
        >
          <div className="modalHeading">
            We’re sorry
          </div>

          <div className="modalText">
            It appears that you are currently outside of a supported
            {' '}
            <div className="textAccentOrange">
              My Smart City zone.
            </div>
          </div>
          <div className="modalText" style={{ marginTop: '10px', marginBottom: '30px' }}>
            Please choose an
            {' '}
            <div className="textAccentOrange">available zone </div>
            from one of the options below.
          </div>

          {availableCities.map((city) => (
            <Button
              className="cityButton"
              key={city.value}
              onClick={() => {
                this.hide();
                promiseInfo.resolve(city);
              }}
            >
              {city.label}
            </Button>
          ))}
        </Modal.Body>
      </Modal>
    );
  }
}
