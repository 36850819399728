/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
// import moment from 'moment';

import { Loader } from 'components/inthecity';
import { Modal } from 'components/social';
import {
  ScrollContainer,
  BottomContainer,
  Line,
  Button,
  SlipLine,
} from 'components/inthecity/lottoModal/lottoModal.styled';
import {
  pingKinektekPurchase,
  confirmKinektekPurchase,
} from 'interface/inthecity/inthecity';

/**
 * Order Details Modal
 */
const OrderDetailsModal = (props) => {
  // props
  const {
    show,
    handleClose,
    orderDetails,
    sessionId,
  } = props;

  // state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const {
        status,
        slipData,
        sessionID,
        statusReason,
      } = await pingKinektekPurchase(sessionId);

      if (status === 'CONFIRMED') {
        setData(slipData);
      }

      if (status === 'SUCCESS') {
        await confirmKinektekPurchase(sessionID);
        setData(slipData);
      }

      if (status === 'NO_PURCHASE') {
        setData([{ text: statusReason }]);
      }

      setLoading(false);
    }

    if (!orderDetails && sessionId) fetchData();
    else if (orderDetails) setData(orderDetails?.slipData);
  }, [sessionId, orderDetails]);

  return (
    <Modal
      title="Order Details"
      show={show}
      backButton={false}
      handleClose={() => {
        setData(null);
        handleClose();
      }}
    >
      <ScrollContainer>
        {loading && <Loader />}

        {data && data.map(({ text }) => (
          <SlipLine>
            {text}
          </SlipLine>
        ))}
      </ScrollContainer>

      <BottomContainer>
        <Line />
        <Button onClick={() => {
          setData(null);
          handleClose();
        }}
        >
          Close
        </Button>
      </BottomContainer>
    </Modal>
  );
};

export default OrderDetailsModal;
