/* eslint-disable max-len */
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import SEO from 'components/seo';

// import { Navbar } from 'components/navbar';
import Footer from 'components/footer';
import Section from 'components/section';
import { BlogPostHero } from 'components/home/hero';
import { Paragraph, Text } from 'components/home/text';
import Button from 'components/home/button';

const SocialShare = ({ title, post }) => {
  const Heading = styled.h2`
    color: #e17531;
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1rem;
    font-family: GothamMedium;
  `;
  const SocialRow = styled.div`
    display: flex;
    height: 3rem;
  `;
  const Icon = styled.div`
    height: 100%;
    margin-right: 2rem;
    > img {
      height: 100%;
      &:hover {
        filter: saturate(100%) brightness(85%);
      }
    }
  `;
  return (
    <>
      <Heading>Share this blog</Heading>
      <SocialRow>
        <Icon
          role="button"
          tabIndex={0}
          onClick={() => {
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=mysmart.city/blog/post/${post}`,
              '_blank'
            );
          }}
        >
          <img src="map/socialMedia/facebook.svg" alt="Category icon" />
        </Icon>
        <Icon
          role="button"
          tabIndex={0}
          onClick={() => {
            window.open(
              `http://twitter.com/share?text=${title}&url=https://mysmart.city/blog/post/${post}&hashtags=mysmartcity`,
              '_blank'
            );
          }}
        >
          <img src="map/socialMedia/twitter.svg" alt="Category icon" />
        </Icon>
        <Icon
          role="button"
          tabIndex={0}
          onClick={() => {
            navigator.clipboard.writeText(
              `https://mysmart.city/blog/post/${post}`
            );
          }}
        >
          <img src="map/socialMedia/magnetlink.svg" alt="Category icon" />
        </Icon>
      </SocialRow>
    </>
  );
};

const Post1 = () => (
  <>
    <BlogPostHero
      image="city/cape_town.jpg"
      title="What is My Smart City"
      author="Terence Rei"
      date="21 July 2021"
    />
    <Section color="white">
      <Paragraph>
        Simply put My Smart City is a community platform that empowers citizens
        with a means of engaging with service providers in their city and
        tracking the progress of those interactions. This includes the likes of
        municipal services, emergency services, private service providers and
        others.
      </Paragraph>
      <Paragraph>
        Designed by-citizens-for-citizens, My Smart City is not affiliated with
        the service providers that the citizen engages with on the platform. As
        such, the primary purpose of the My Smart City is to improve the
        citizens’ interaction with service providers in their city using
        technology while providing trackability.
      </Paragraph>
      <Paragraph>
        <Text bold>What does that all mean for me?</Text>
      </Paragraph>
      <Paragraph>
        That means that you, the citizen, can utilise My Smart city to:
      </Paragraph>
      <Paragraph>
        <ul>
          <li>Find and communicate with service providers in your city</li>
          <li>
            Log issues/faults for these service providers, allowing the service
            provider to address it
          </li>
          <li>Track the progress of the resolution of the issues/faults</li>
          <li>
            Gain information from service providers, whether related to an
            issue/fault or otherwise
          </li>
          <li>
            View the issues/faults that have been raised by other citizens
          </li>
          <li>
            Upvote issues/faults, indicating to service providers the urgency
            and number of individuals that are affected by the issue
          </li>
          <li>
            Provide detailed descriptions, photographs and GPS coordinates to
            the issues to assist service providers with the resolution of the
            issues
          </li>
          <li>
            Create petitions for the resolution of issues/faults by service
            providers
          </li>
        </ul>
      </Paragraph>
      <SocialShare title="What is My Smart City" post="1" />
    </Section>
  </>
);

const Post2 = () => {
  const Image = styled.img`
    width: 90%;
    margin-bottom: 6rem;
  `;

  const Container = styled.div`
    text-align: center;
  `;

  return (
    <>
      <BlogPostHero
        image="blog/post2/banner.jpg"
        title="What Is The PoPI Act?"
        author="Terence Rei"
        date="8 July 2021"
      />
      <Section color="white">
        <Paragraph>
          <Text bold>
            What Is The PoPI Act, How Does It Affect Me And Is My Smart City
            PoPI Compliant?
          </Text>
        </Paragraph>
        <Paragraph>
          You may have noticed a number of emails finding their way into your
          inbox recently informing you of your service providers&apos;
          compliance with the PoPI Act. But what does it all mean for you?
          Don&apos;t worry the My Smart City team is here to help clear things
          up for you.
        </Paragraph>
        <Row>
          <Col sm={3} xs={12}>
            <Container>
              <Image src="blog/post2/image1.jpg" />
            </Container>
          </Col>
          <Col sm={9} xs={12}>
            <Paragraph>
              <Text bold>What is the PoPI Act?</Text>
            </Paragraph>
            <Paragraph>
              The Protection of Personal Information Act sets out a number of
              conditions for service providers to abide by when handling the
              personal information of data subjects (you and I). This provides
              service providers with guidelines on how your personal information
              is stored, protected and used in the future.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col sm={9} xs={12}>
            <Paragraph>
              <Text bold>How does the PoPI Act Affect Me?</Text>
            </Paragraph>
            <Paragraph>
              The PoPI Act is there to protect you from harm like theft and
              discrimination. Ensuring that service providers are held
              responsible for online communications and engagements. The Service
              providers that do not comply with the PoPI Act risk reputational
              damage, fines and even imprisonment.
            </Paragraph>
          </Col>
          <Col sm={3} xs={12}>
            <Container>
              <Image src="blog/post2/image2.jpg" />
            </Container>
          </Col>
        </Row>
        <Row>
          <Col sm={3} xs={12}>
            <Container>
              <Image src="blog/post2/image3.jpg" />
            </Container>
          </Col>
          <Col sm={9} xs={12}>
            <Paragraph>
              <Text bold>Is There Anything That I Have To Do?</Text>
            </Paragraph>
            <Paragraph>
              This all depends on whether or not you wish to continue receiving
              communications from your service providers. Have a look at the
              service providers that have sent you emails and decide whether or
              not you would like for them to continue communicating with you. If
              not, then unsubscribe from their mailing list. Alternatively, if
              you enjoy the content that is being shared with you and you want
              to continue receiving communications from the service provider,
              then no action is required.
            </Paragraph>
          </Col>
        </Row>
        <img
          style={{ width: '100%', margin: '6rem 0' }}
          src="blog/post2/phones.jpg"
          alt="phones"
        />
        <Paragraph>
          <Text bold>Is My Smart City PoPI Compliant?</Text>
        </Paragraph>
        <Paragraph>
          Absolutely, My Smart City takes the protection of your personal
          information very seriously, complying with all PoPI Act regulations.
          Should you wish to unsubscribe from our mailing lists at any time you
          can simply hit the unsubscribe button in the footer of our emails.
        </Paragraph>
        <Paragraph>
          <Text bold>Reasons To Remain Subscribed to My Smart City.</Text>
        </Paragraph>
        <Paragraph>
          The team here at My Smart City are determined to bring the citizens
          relevant and helpful content, such as this one. We work hard to ensure
          that My Smart City subscribers are kept informed, providing you with
          insights, helpful information and tips to better your life and your
          city. We hope that you will remain with us throughout the journey so
          that we can help you improve your city, service delivery from your
          service providers and the lives of both you and your families.
        </Paragraph>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Paragraph alignCenter>
            <Text bold>View Our Privacy Policy Here:</Text>
          </Paragraph>
          <Button label="Privacy" to="/privacy-policy" arrow />
        </div>
        <SocialShare title="What Is The PoPI Act" post="2" />
      </Section>
    </>
  );
};

const Post3 = () => {
  const Image = styled.img`
    width: 90%;
    margin: auto;
    @media only screen and (max-width: 575px) {
      margin: 0 auto 4rem auto;
    }
  `;

  const Link = styled.a`
    font-family: GothamMedium;
    color: #e17531;
    &:hover {
      color: #e17531;
      border-bottom: 2px solid #e17531;
    }
  `;

  return (
    <>
      <BlogPostHero
        image="blog/post3/hero.jpg"
        title="Officially launched in Johannesburg and Cape Town"
        author="Terence Rei"
        date="15 July 2021"
      />
      <Section color="white">
        {/* <Paragraph>
          15th July 2021 Johannesburg.
        </Paragraph> */}
        <Paragraph>
          Today Acumen Software officially launched the My Smart City citizen
          platform in Johannesburg and Cape Town. The platform is a citizen
          platform that will allow citizens to engage their local municipality
          and other Service Providers and facilities in their city. My Smart
          City is an independent platform that gives citizens the power to speak
          with one very commanding united voice to ensure that service delivery
          meets their expectations. My Smart City will also assist
          municipalities to cut through the noise and focus on the 20% of the
          issues that affect 80% of citizens, thus improving their service
          delivery, and improve the city.
        </Paragraph>
        <Paragraph>
          This initial launch sees the Municipal and Emergency Services go live
          Johannesburg and Cape Town. This will be followed shortly by the roll
          out of further Emergency Services functionality, community Social
          Media feed, Private Services, which will include Large Private
          Services and then later single person disruptive services, and finally
          In the City Services, which will include entertainment, tourism and
          leisure.
        </Paragraph>
        <Paragraph>
          We will expand into other major cities in South Africa and some of the
          cities in some neighboring countries over the next 6 months.
        </Paragraph>
        <Row noGutters>
          <Col sm={4} xs={12}>
            <Image src="blog/post3/image1.jpg" />
          </Col>
          <Col sm={8} xs={12}>
            <Paragraph>
              Joao Zoio, CEO of Acumen Software “The launch of the My Smart City
              platform is the result of 3 years of hard work and overcoming of
              many challenges. We are very proud of what we have created and we
              believe that it will change the way in which citizens engage
              service providers in their city. Furthermore My Smart City will
              create over 50,000 much needed, disruptive jobs in the next 5
              years in South Africa alone.”
            </Paragraph>
            <Paragraph>
              My Smart City was recognised by the UK Department of International
              Trade as one of the best Smart City solutions out of South Africa
              in late 2018. This led to us being selected as one of 8 out of 260
              applicants to complete the PwC South East scale program in the UK
              in 2019. We will continue our drive into the UK market once the
              pandemic is under control.
            </Paragraph>
            <Paragraph>
              Get rid of the many different apps on your phone that you
              currently use to engage all of the different service providers in
              your city; stop spending your time on the phone waiting for a call
              agent to take your call, and replace it with the My Smart City
              platform to log a pothole,report a crime, report a riot, order a
              private service, start a petition (such as getting a speed bump in
              your road), and engage your community on a focused social platform
              so that you speak with one united voice.
            </Paragraph>
          </Col>
        </Row>
        <Paragraph>
          My Smart City is available on the Google Play store, Apple store, and
          as a mobile friendly Web app on our website{' '}
          <Link href="/">www.mysmart.city</Link>
        </Paragraph>
        <Paragraph>
          Help us build My Smart City into one powerful voice that will be
          heard.
        </Paragraph>
        <Paragraph>Register now, it&apos;s for free!</Paragraph>
        <Button label="Register" to="/map" arrow />
        <SocialShare title="What is My Smart City" post="1" />
      </Section>
    </>
  );
};

const Post4 = () => {
  const Image = styled.img`
    width: 90%;
    height: 80%;
    margin: 2rem 2rem;
    object-fit: cover;
  `;

  const DownloadRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 3rem;
    @media only screen and (min-width: 575px) {
      height: 4rem;
    }
  `;

  const AppButton = styled.a`
    width: 200px;
    height: 100%;
    &:not(:last-child) {
      margin-right: 15px;
    }
  `;

  const AppImage = styled.img`
    height: 100%;
  `;

  return (
    <>
      <BlogPostHero
        image="blog/post4/hero.jpg"
        title="WE HAVE A WINNER TO OUR MAURITIUS TRIP"
        author="My Smart City Team"
        date="31 January 2022"
      />
      <Section color="white">
        <Paragraph>
          In October 2021, we launched our Mauritius Holiday giveaway which was
          met with loads of excitement. Thousands of excited My Smart City fans
          waged their chances of winning by downloading and registering on our
          platform. With the competition coming to an end on the 31st of
          December 2021, the inevitable question began to flood in, “When are
          you announcing the winner?”. Well, the results are in and we&apos;re
          happy to announce we&apos;ve found our winner!
        </Paragraph>

        <Row noGutters>
          <Col sm={8} xs={12}>
            <Paragraph>
              Congratulations to Haemish Kyd and his partner who&apos;ll be
              flying off to Mauritius for a 7-day trip to the Victoria
              Beachcomber Resort and Spa courtesy of #MySmartCity!
            </Paragraph>
            <Paragraph>
              The 42-year-old Software Developer had this to say when he stopped
              by our offices, &quot;I came across the My Smart City app last
              year and really appreciated the idea of being able to report and
              track municipal issues within an app. The idea appealed enough to
              make me download and register. The competition came as a pleasant
              surprise since I did not really download the app for the
              competition. We really appreciate the functionality the app gives
              us but now appreciate it that little bit more.&quot;
            </Paragraph>
          </Col>
          <Col sm={4} xs={12}>
            <Image src="blog/post4/preview.jpg" />
          </Col>
        </Row>
        <Paragraph>
          <Text bold>MORE COMPETITIONS TO COME!</Text>
        </Paragraph>

        <Paragraph>
          Don&apos;t be disheartened if you never won the trip to Mauritius, we
          will be giving away tons of prizes throughout the year. Simply remain
          a My Smart City registered user and you will be automatically entered
          into future competitions.
        </Paragraph>

        <Paragraph>
          <Text bold>Not a My Smart City Registered User?</Text>
          <Text text=" Get started today, download and register on the My Smart City platform and start improving your city. Not only are we rewarding My Smart City registered users with the opportunity to win amazing prizes but you can also play your part in improving your city. Log track and manage potholes, street and traffic lights that are out, water outages and more with My Smart City." />
        </Paragraph>

        <Paragraph alignCenter>
          <Text bold>Download and Register Now:</Text>
        </Paragraph>
        <DownloadRow>
          <AppButton
            id="blogpost-applink-playstore"
            href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppImage src="home/citizens/home/playStore.svg" alt="playstore" />
          </AppButton>
          <AppButton
            id="blogpost-applink-appstore"
            href="https://apps.apple.com/app/mysmart-city/id1555236570"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppImage src="home/citizens/home/appStore.svg" alt="appstore" />
          </AppButton>
        </DownloadRow>

        <SocialShare title="WE HAVE A WINNER TO OUR MAURITIUS TRIP" post="3" />
      </Section>
    </>
  );
};

// eslint-disable-next-line no-unused-vars
const Post5 = () => {
  const Image = styled.img`
    width: 100%;
    height: 90%;
    margin: 2rem 0;
    object-fit: cover;
    @media only screen and (min-width: 575px) {
      width: 90%;
      margin: 0 2rem 2rem 0;
    }
  `;

  const SecondImage = styled.img`
    float: right;
    width: 100%;
    height: 90%;
    margin: 2rem 0;
    object-fit: cover;
    @media only screen and (min-width: 575px) {
      width: 90%;
      margin: 0 0 2rem 2rem;
    }
  `;

  const DownloadRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 3rem;
    @media only screen and (min-width: 575px) {
      height: 4rem;
    }
  `;

  const AppButton = styled.a`
    width: 200px;
    height: 100%;
    &:not(:last-child) {
      margin-right: 15px;
    }
  `;

  const AppImage = styled.img`
    height: 100%;
  `;

  const Link = styled.a`
    font-family: GothamMedium;
    color: #e17531;
    &:hover {
      color: #e17531;
      border-bottom: 2px solid #e17531;
    }
  `;

  return (
    <>
      <BlogPostHero
        image="city/ethekwini.jpg"
        title="MY SMART CITY LAUNCHES IN ETHEKWINI"
        author="My Smart City Team"
        date="1 March 2022"
      />
      <Section color="white">
        <Paragraph>
          After our initial launch over 6 months ago, we&apos;ve connected the
          residents of Cape Town, Joburg, Ekurhuleni and Tshwane to My Smart
          City. Citizens in these cities have been able to use our class-leading
          tech to log, track and manage issues and services providers have
          responded in kind.
        </Paragraph>

        <Paragraph>
          The residents of eThekwini have long awaited a channel in which to
          report concerns in their municipality and now we are giving them a
          voice. Welcome to the My Smart City family eThekwini, we are happy to
          be here to support you in improving your communities.
        </Paragraph>

        <Paragraph>
          Long response times, endless hours on emails and calls are now a thing
          of the past. Log issues such as water and power outages, street and
          traffic light repairs, waste removal and pothole repairs with just a
          single tap on the app. Once reported, our dedicated team takes your
          reported issues and raises them with the correct department within
          your local municipality on your behalf.
        </Paragraph>

        <Row noGutters>
          <Col sm={4} xs={12}>
            <Image src="blog/post5/banner.jpg" />
          </Col>
          <Col sm={8} xs={12}>
            <Paragraph>
              <Text>
                My Smart City has successfully launched in Cape Town,
                Johannesburg, Ekurhuleni and Tshwane before adding eThekwini to
                its expansion plan. CEO of Acumen Software (the developers of My
                Smart City), Joao Zoio said,
              </Text>
              <Text bold>
                We are delighted to roll out the MySmartCity platform in
                eThekwini. Despite the social unrest in July 2021, there are
                exciting and positive developments in the metro and we are
                thrilled to be part of providing residents with a platform where
                they can share their service delivery issues, track and monitor
                progress and see issues resolved expeditiously,”
              </Text>
            </Paragraph>
          </Col>
        </Row>

        <Paragraph>
          <Row noGutter>
            <Col sm={8} xs={12}>
              Logging issues is not the only offering that My Smart City has for
              our users. With the introduction of the My Smart City Social Feed,
              citizens can now engage, connect and grow with their community.
              Engage with community leaders, ward councillors and fellow
              neighbours on topics, announcements and alerts concerning your
              neighbourhood and city. Connect with like-minded individuals and
              gather support for petitions. Grow with your community as you help
              build a safe and well-run city with My Smart City.
            </Col>
            <Col sm={4} xs={12}>
              <SecondImage src="blog/post5/image.jpg" />
            </Col>
          </Row>
        </Paragraph>

        <Paragraph>
          My Smart City is available FOR FREE on the Google Play Store, Apple
          Store, and as a mobile-friendly Web app on our website{' '}
          <Link href="https://www.mysmart.city/map">www.mysmart.city/map</Link>.
          Download and Register on the app today!
        </Paragraph>
        {/* <Paragraph alignCenter>
          <Text bold>
            Download and Register Now:
          </Text>
        </Paragraph> */}
        <DownloadRow>
          <AppButton
            id="blogpost-applink-playstore"
            href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppImage src="home/citizens/home/playStore.svg" alt="playstore" />
          </AppButton>
          <AppButton
            id="blogpost-applink-appstore"
            href="https://apps.apple.com/app/mysmart-city/id1555236570"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppImage src="home/citizens/home/appStore.svg" alt="appstore" />
          </AppButton>
        </DownloadRow>

        <SocialShare title="What is My Smart City" post="5" />
      </Section>
    </>
  );
};

// eslint-disable-next-line no-unused-vars
const Post6 = () => {
  // const Image = styled.img`
  //   width: 100%;
  //   height: 90%;
  //   margin: 2rem 0;
  //   object-fit: cover;
  //   @media only screen and (min-width: 575px) {
  //     width: 90%;
  //     margin: 0 2rem 2rem 0;
  //   }
  // `;

  const DownloadRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 3rem;
    @media only screen and (min-width: 575px) {
      height: 4rem;
    }
  `;

  const AppButton = styled.a`
    width: 200px;
    height: 100%;
    &:not(:last-child) {
      margin-right: 15px;
    }
  `;

  const AppImage = styled.img`
    height: 100%;
  `;

  const Link = styled.a`
    font-family: GothamMedium;
    color: #e17531;
    &:hover {
      color: #e17531;
      border-bottom: 2px solid #e17531;
    }
  `;

  return (
    <>
      <BlogPostHero
        image="blog/post6/banner.jpg"
        title="My Smart City’s Social Feed is here!"
        author="My Smart City Team"
        date="15 February 2022"
      />
      <Section color="white">
        <Paragraph>
          <Text>Today Acumen Software officially launched the</Text>{' '}
          <Text bold>My Smart City Social Feed</Text>
          <Text>
            . The newly integrated function on an already robust My Smart City
            platform currently rolled out in Cape Town, Johannesburg, Ekurhuleni
            and Tshwane.
          </Text>
        </Paragraph>

        <Paragraph>
          The My Smart City Social Feed is a community engagement and
          collaborative feature. Not only does it enable citizens to share
          community concerns, it also aggregates information, alerts and
          announcements that are relevant to your community and the broader city
          one lives in.
        </Paragraph>

        <Paragraph>
          This hyper-personalised experience allows the citizen to extract
          noteworthy, reliable and relevant information from the convenience of
          one platform. Users will be able to access alerts such as load
          shedding schedules, planned water outages, power outages and natural
          disasters, as well as announcements made by concerned community
          members, leaders and other reliable sources.
        </Paragraph>

        <Paragraph>
          This high-level functionality assists users by relieving them of the
          multitude of messaging groups and “community spamming” that tends to
          take place on messaging apps. One is capable of opting into specific
          notifications and filtering out unnecessary noise. This allows users
          to remain current with what is going on in their neighbourhood without
          feeling overwhelmed by numerous messages.
        </Paragraph>

        <Paragraph>
          My Smart City is available FOR FREE on the Google Play Store, Apple
          Store, and as a mobile-friendly Web app on our website{' '}
          <Link href="https://www.mysmart.city/map">www.mysmart.city/map</Link>.
          Download and Register on the app today!
        </Paragraph>

        {/* <Paragraph alignCenter>
          <Text bold>
            Download and Register Now:
          </Text>
        </Paragraph> */}

        <DownloadRow>
          <AppButton
            id="blogpost-applink-playstore"
            href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppImage src="home/citizens/home/playStore.svg" alt="playstore" />
          </AppButton>
          <AppButton
            id="blogpost-applink-appstore"
            href="https://apps.apple.com/app/mysmart-city/id1555236570"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppImage src="home/citizens/home/appStore.svg" alt="appstore" />
          </AppButton>
        </DownloadRow>

        <SocialShare title="My Smart City’s Social Feed is here!" post="6" />
      </Section>
    </>
  );
};

const Post7 = () => {

  const DownloadRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 3rem;
    @media only screen and (min-width: 575px) {
      height: 4rem;
    }
  `;

  const AppButton = styled.a`
    width: 200px;
    height: 100%;
    &:not(:last-child) {
      margin-right: 15px;
    }
  `;

  const AppImage = styled.img`
    height: 100%;
  `;

  return (
    <>
      <BlogPostHero
        image="blog/post7/preview.jpg"
        title="Celebrating Youth Month: The Passionate Young Team Behind My Smart City"
        author="My Smart City Team"
        date="17 June 2024"
      />
      <Section color="white">
        <Paragraph>
          <Text>
            Hey everyone! As we celebrate Youth Month, we at Acumen Software want to give
            a huge shoutout to the amazing young people who make My Smart City what it
            is today. We're a team full of passion, dedication, and a whole lot of heart,
            and we're thrilled to share our journey with you.
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            Our team is dedicated to building a better South Africa through technology.
            My Smart City isn't just another app; it's citizen-built and citizen-run platform.
            We designed it to make urban living easier and more connected for everyone in
            South Africa. Using the solid base of Forcelink, our Mobile Field Services ERP
            Solution, we collect data logged by citizens and share it with municipalities
            in an effort to reduce response times to issues and enhance service delivery
            across the country. It's all about making sure your voices are heard and your
            problems are solved faster.
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            Our young team is constantly refining and expanding My Smart City. We've added
            features that let you report service issues, access private homecare services,
            buy airtime and lotto tickets, and so much more. We're always looking for ways
            to make your life easier and your community stronger. We like to think of My Smart
            City as a Swiss Army knife; a versatile multi-tool which can be carried in your
            pocket with you everywhere.
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            Need to know the load-shedding schedule? Check. Want live updates on election
            results? Got it. Our platform is designed to keep you informed and engaged with
            your community. Need data to make informed decisions about property investments
            and other community matters? We have it. Want to start a petition? Let's help you.
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            One of the coolest parts of My Smart City is the social feed. This isn't just a
            place to scroll mindlessly—it's a space where you can communicate with other
            residents, ward councillors and community groups, share thoughts, and view
            relevant news for your area.
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            At Acumen Software, we're all about the "Improvement Movement." We believe in the
            power of youth to drive change and make the world a better place. Every single
            member of our team puts their heart and soul into My Smart City because we believe
            in our country and want to be part of its growth and improvement. Our greatest aim
            is to give the citizens of South Africa a united and powerful voice, because only
            together can we drive the improvement movement.
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            Although we place high focus on our municipal issue logging processes for the
            improvement of each of our cities, we never neglect our mission to create job
            opportunities in South Africa. With our private homecare services, we strive for a
            means to address unemployment, creating jobs for citizens and private contractors
            by connecting them with citizens via the platform.
          </Text>
          </Paragraph>

        <Paragraph>
          <Text>
            This Youth Month, we're celebrating our fantastic young team. Their hard work,
            creativity, and passion are what make My Smart City possible. We're so proud of
            what we've achieved together and can't wait to see what the future holds.
            Our team isn't just building a platform—we're building the world we want to
            live in and raise our families in.
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            So here's to our young minds, our brilliant innovators, and our
            tireless problem-solvers. Thank you for everything you do, and keep shining as
            we continue this incredible journey together.
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            Join us in celebrating Youth Month and the amazing team behind My Smart City.
            Together, let's create a brighter, more connected future for South Africa!
          </Text>
        </Paragraph>

        <Paragraph>
          <Text>
            Want to get involved or learn more? Check out MySmart.City and download
            our platform today!
          </Text>
        </Paragraph>

        <DownloadRow>
          <AppButton
            id="blogpost-applink-playstore"
            href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppImage src="home/citizens/home/playStore.svg" alt="playstore" />
          </AppButton>
          <AppButton
            id="blogpost-applink-appstore"
            href="https://apps.apple.com/app/mysmart-city/id1555236570"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppImage src="home/citizens/home/appStore.svg" alt="appstore" />
          </AppButton>
        </DownloadRow>

        <SocialShare title="Celebrating Youth Month: The Passionate Young Team Behind My Smart City" post="7" />
      </Section>
    </>
  );
};


const posts = {
  1: <Post1 />,
  2: <Post2 />,
  3: <Post3 />,
  4: <Post4 />,
  5: <Post5 />,
  6: <Post6 />,
  7: <Post7 />,
};

const NoPostFound = () => (
  <Section color="white">
    <Paragraph>Sorry,</Paragraph>
    <Paragraph>No blog post found.</Paragraph>
  </Section>
);

export default function BlogPostPage() {
  const { num } = useParams();

  return (
    <>
      <SEO title="My Smart City | Blog" description="My Smart City Blog" />
      {posts[num] || <NoPostFound />}
      <Footer />
    </>
  );
}
