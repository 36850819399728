import styled from 'styled-components';

import { Headline } from 'components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  @media only screen and (min-width: 800px) {
    margin-bottom: 50px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @media only screen and (min-width: 800px) {
    padding: 0 50px;
  }
  @media only screen and (min-width: 1400px) {
    padding: 0 100px;
  }
`;

export const SearchContainer = styled.div`
  margin: 16px auto;
  width: 300px;
  background: ${({ color }) => (color)};

  @media only screen and (min-width: 500px) {
    width: 400px;
  }

  @media only screen and (min-width: 800px) {
    margin: 28px auto 35px auto;
    width: 600px;
  }
  
  @media only screen and (min-width: 1000px) {
    width: 780px;
  }

`;

export const FilterColumn = styled.div`
  display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
  flex-direction: column;
  height: 100%;
  background: white;
  overflow: hidden;
  margin: 0px 15px;


  @media only screen and (min-width: 1000px) {
    display: ${({ hideDesktop }) => (hideDesktop ? 'none' : 'flex')};
    width: 350px;
  }
`;

export const Title = styled(Headline)`
  color: #333;
  margin-bottom: 14px;

  @media only screen and (min-width: 800px) {
    margin-bottom: 40px;
  }
`;

// export const SubTitle = styled.h3`
//   font: normal normal medium 20px/26px Roboto;
//   color: #333333;
//   line-height: 15px;
//   margin: 20px 0px;
//   position: relative;
//   cursor: pointer;

//   @media only screen and (min-width: 1000px) {
//     margin-top: 30px;
//   }
// `;

// export const ListItems = styled.h4`
//   font: normal normal normal 16px/21px Roboto;
//   color: #DF6E21;
//   line-height: 30px;
//   left: 0px;
//   margin-left: 40px;

//   @media only screen and (min-width: 1000px) {
//     padding-left: 0px;
//   }
// `;

// export const MoreList = styled.h4`
//   display: ${({ hideMobile }) => (hideMobile ? 'none' : 'none')};
//   font: normal normal normal 16px/21px Roboto;
//   color: #333333;
//   line-height: 0px;
//   cursor: pointer;
//   margin:0px 10px 10px 0px;
//   left: 0px;
//   margin-left: 40px;

//   @media only screen and (min-width: 1000px) {
//     display: ${({ hideDesktop }) => (hideDesktop ? 'none' : 'block')};
//     padding-left: 0px;
//   }
// `;

export const MoreListIcon = styled.img`
  height: 10px;
  right: 10px;
  top: 10px;
  transform: ${(props) => (props.flip ? 'rotate(270deg)' : 'rotate(90deg)')};
  transition: transform 0.3s ease-in-out;
  margin:10px 10px 10px 2px;
`;

export const MobileMoreListIcon = styled.img`
  height: 10px;
  right: 10px;
  transform: ${(props) => (props.flip ? 'rotate(270deg)' : 'rotate(90deg)')};
  position: absolute;
  transition: transform 0.3s ease-in-out;

  @media only screen and (min-width: 1000px) {
    display: none
  }
`;

export const ListIcon = styled.img`
  height: 10px;
  left: 0px;
  margin-right: 10px;
`;

export const Line = styled.div`
  opacity: 0.46;
  width: 100%;
  height: 2px;
  background-color: #DEE2E6;
`;

export const PageLine = styled.div`
  height: 2px;
  width: 100%;
  background: #DEE2E6;
`;

export const PageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
`;
