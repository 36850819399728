/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import moment from 'moment';

import { Modal } from 'components/social';
import {
  getImageURL,
  postPurchaseOrder,
  pingKinektekPurchase,
  confirmKinektekPurchase,
} from 'interface/inthecity/inthecity';
import { useGlobalContext } from 'contexts/global';
import {
  ScrollContainer,
  BottomContainer,
  Line,
  Button,
  ProductItem,
  ProductContent,
  ProductTotal,
  StatusMessage,
  StatusAnimation,
  GrayButton,
  ConfirmLine,
} from './lottoModal.styled';

/**
 * Lotto Confirmation Modal
 */
const ConfirmationModal = (props) => {
  // props
  const { show, handleClose, redirectState, handleShowOrderDetails } = props;

  const { boards, quickPickBoards, product, checkout, lookup, mobileNumber } =
    redirectState || {};

  console.log('redirectState', {
    boards,
    quickPickBoards,
    product,
    checkout,
    lookup,
    mobileNumber,
  });

  // hooks
  const {
    globalState: { user },
  } = useGlobalContext();

  // refs
  const ref = useRef();

  // state
  const [status, setStatus] = useState();
  const [orderDetails, setOrderDetails] = useState();
  console.log(status);

  const numberOfBoards = boards?.length || quickPickBoards;
  const amountDue = (lookup.amountDue * numberOfBoards) / 100;

  // animation
  useEffect(() => {
    const animation = {
      container: ref.current,
      renderer: 'svg',
      loop: status === 'loading',
      autoplay: true,
      path: `animations/status/${status}.json`,
    };
    lottie.loadAnimation(animation);
    return () => {
      lottie.destroy();
    };
  }, [status]);

  // Kinektek payment
  async function processPurchase() {
    setStatus('loading');

    const purchaseOrder = {
      productId: product.productID,
      mobileNumber,
      smartcityuser_uuid: user?.uuid,
      dualStep: true,
      timeStamp: moment().unix(),
      amount: amountDue,
      currency: 'ZAR',
      paymentType: 'DB',
      customerDetail: `name:${product.name},issuer:${
        product.issuer
      },mobileNumber:${mobileNumber},boards:${
        boards ? JSON.stringify(boards).replace(/,/g, ';') : quickPickBoards
      },imageName:${product.imageName}`,
      checkoutId: checkout,
      sessionId: lookup.sessionID,
    };

    const purchaseOrderResponse = await postPurchaseOrder(purchaseOrder);

    console.log('lotto:purchaseOrder:request', purchaseOrder);
    console.log('lotto:purchaseOrder:response', purchaseOrderResponse);

    // Check Kinektek purchase status and slip
    const pingResponse = await pingKinektekPurchase(
      purchaseOrderResponse?.sessionID
    );
    setOrderDetails(pingResponse);

    if (pingResponse.status === 'SUCCESS') {
      // client received the slip
      const confirmResponse = await confirmKinektekPurchase(
        purchaseOrderResponse?.sessionID
      );
      if (confirmResponse) setStatus('success');
    } else setStatus('error');
  }

  // initiate payment process on mount
  useEffect(() => {
    if (redirectState && checkout) processPurchase();
  }, []);

  return (
    <Modal
      title="Confirmation"
      show={show}
      backButton={false}
      closeButton={false}
      handleClose={status === 'loading' ? () => {} : handleClose}
    >
      <ScrollContainer>
        <StatusAnimation ref={ref} />

        <StatusMessage>
          {status === 'loading' && 'Processing payment'}
          {status === 'success' && 'Thank you for your purchase'}
          {status === 'error' && 'Payment Rejected'}
        </StatusMessage>

        <ProductItem>
          <img src={getImageURL(product?.imageName)} alt={product?.name} />
          <ProductContent>
            <p>{`${lookup.description} Ticket${
              numberOfBoards > 1 ? 's' : ''
            }`}</p>
            <p>{mobileNumber}</p>
            <p>{`${moment().format('DD MMM YYYY')} · ${numberOfBoards} Board${
              numberOfBoards > 1 ? 's' : ''
            }`}</p>
          </ProductContent>
          <ProductTotal>
            {lookup ? `R ${Number(amountDue.toFixed(2))}` : 'Getting price'}
          </ProductTotal>
        </ProductItem>

        <ConfirmLine />

        {orderDetails && (
          <GrayButton onClick={() => handleShowOrderDetails(orderDetails)}>
            Order Details
          </GrayButton>
        )}
      </ScrollContainer>

      <BottomContainer>
        <Line />
        <Button
          disabled={status === 'loading'}
          onClick={status === 'loading' ? () => {} : handleClose}
        >
          Done
        </Button>
      </BottomContainer>
    </Modal>
  );
};

export default ConfirmationModal;
