/* eslint-disable max-len */
import { apiUrl } from 'constants/app';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

/**
 * Data for wards
 * @param {number} period time in months
 * @param {string} address optional address
 * @param {number} latitude optional latitude
 * @param {number} longitude optional longitiude
 * @returns {Promise}
 */

const errorinsights = {
  wardNo: 'Data is not available for this location',
  smartCityInsightMonths: [],
  callsLoggedForPeriod: 0,
  callsInProgress: 0,
  callsResolvedForPeriod: 0,
  totalForPeriod: 0,
  categoryCounts: [],
  differenceLoggedCalls: 0,
  differenceResolvedCalls: 0,
  averageResolveTime: '00:00:00',
  averageOpenTime: '00:00:00',
  differenceAverageResolveTime: '00:00:0',
  total: 0,
  address: null,
};
const errorrestime = {
  180: '0',
  270: '02',
  150: '0',
  360: '0',
  240: '0',
  120: '0',
  0: '0',
  330: '0',
  210: '0',
  300: '0',
  90: '0',
  60: '0',
  30: '0',
};

export const getSmartCityInsights = async (
  period = 12,
  address,
  latitude,
  longitude
) =>
  fetch(
    `${apiUrl}smartcitymanager/getSmartCityInsights` +
      `?period=${period}` +
      // + '&wardNo=2'
      `${address ? `&address=${address}` : ''}` +
      `${latitude ? `&latitude=${latitude}` : ''}` +
      `${longitude ? `&longitude=${longitude}` : ''}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return errorinsights;
    })
    .catch((e) => {
      console.log(e);
      return errorinsights;
    });

/**
 * Data for city
 * @param {number} period time in months
 * @param {string} cityId subscriber id
 * @returns {Promise}
 */
export const getSmartCityInsightsForCity = async (
  period = 12,
  cityId,
  subscriberId
) =>
  fetch(
    `${apiUrl}smartcitymanager/getSmartCityInsightsForCity` +
      `?period=${period}` +
      `${cityId ? `&cityId=${cityId}` : ''}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        subscriberId,
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return errorinsights;
    })
    .catch((e) => {
      console.log(e);
      return errorinsights;
    });

/**
 * Resolution Time Graph
 * @returns {Promise}
 */
export const getInsightsResolutionTimeGraph = async (wardNo, cityId) =>
  fetch(
    `${apiUrl}smartcitymanager/getInsightsResolutionTimeGraph` +
      `?wardNo=${wardNo}&cityId=${cityId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return errorrestime;
    })
    .catch((e) => {
      console.log(e);
      return errorrestime;
    });

/**
 * Resolution Time Graph for a city
 * @returns {Promise}
 */
export const getInsightsResolutionTimeGraphForCity = async (cityId) =>
  fetch(
    `${apiUrl}smartcitymanager/getInsightsResolutionTimeGraphForCity?cityId=${cityId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return errorrestime;
    })
    .catch((e) => {
      console.log(e);
      return errorrestime;
    });

/**
 * Open Time Graph
 * @returns {Promise}
 */
export const getInsightsOpenTimeGraph = async (wardNo, cityId) =>
  fetch(
    `${apiUrl}smartcitymanager/getInsightsOpenTimeGraph` +
      `?wardNo=${wardNo}&cityId=${cityId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return errorrestime;
    })
    .catch((e) => {
      console.log(e);
      return errorrestime;
    });

/**
 * Open Time Graph for a city
 * @returns {Promise}
 */
export const getInsightsOpenTimeGraphForCity = async (cityId) =>
  fetch(
    `${apiUrl}smartcitymanager/getInsightsOpenTimeGraphForCity?cityId=${cityId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return errorrestime;
    })
    .catch((e) => {
      console.log(e);
      return errorrestime;
    });

/**
 * Category Insights For Period
 * @returns {Promise}
 */
export const getSmartCityCategoryInsightsForPeriod = async (
  period = 12,
  wardNo
) =>
  fetch(
    `${apiUrl}smartcitymanager/getSmartCityCategoryInsightsForPeriod?period=${period}&wardNo=${wardNo}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return false;
    })
    .catch((e) => e);

/**
 * Category Insights For Period For City
 * @returns {Promise}
 */
export const getSmartCityCategoryInsightsForPeriodAndCity = async (
  period = 12,
  cityId
) =>
  fetch(
    `${apiUrl}smartcitymanager/getSmartCityCategoryInsightsForPeriodAndCity?period=${period}&cityId=${cityId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return false;
    })
    .catch((e) => e);

/**
 * Map Ward and Ticket Counts
 * @returns {Promise}
 */
export const getMapWardTicketCounts = async (period = 12) =>
  fetch(`${apiUrl}smartcitymanager/getMapWardTicketCounts?period=${period}`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return false;
    })
    .catch((e) => e);

/**
 * Map City and Ticket Counts
 * @returns {Promise}
 */
export const getMapWardTicketCountsForCity = async (cityId, period = 12) =>
  fetch(
    `${apiUrl}smartcitymanager/getMapWardTicketCountsForCity` +
      `?period=${period}&cityId=${cityId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return false;
    })
    .catch((e) => e);

/**
 * Initialize PurchaseOrder, get and returns checkoutId for Peach Payments
 *
 * price
 * productId,
 *
 * @returns {Promise}
 */
export const initializePurchaseOrder = async (price, productId) =>
  fetch(
    `${apiUrl}smartcitymanager/doInitializePeachPurchase?price=${price.toFixed(
      2
    )}&productId=${productId}&privateServices=false`,
    {
      method: 'POST',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.text())
    .catch((e) => e);

/**
 * PurchaseOrder
 *
 * productId,
 * mobileNumber,
 * smartcityuserUuid,
 * cardBin,
 * cardLastFourDigits,
 * cardExpiryMonth,
 * cardExpiryYear,
 * cardholder,
 * dualStep,
 * timeStamp,
 * amount,
 * currency,
 * paymentType,
 * customerDetail,
 * validated,
 * sessionId,
 * paymentId,
 *
 * @returns {Promise}
 */
export const postPurchaseOrder = async (purchaseOrderData) =>
  fetch(`${apiUrl}smartcitymanager/postKinektekPurchaseOrder`, {
    method: 'POST',
    async: true,
    body: JSON.stringify(purchaseOrderData),
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

/**
 * Kinektek Catalogue
 * @returns {Promise}
 */
export const getKinektekCatalogue = async () =>
  fetch(`${apiUrl}smartcitymanager/getKinektekCatalogue`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

/**
 * Kinektek Catalogue by category
 * @returns {Promise}
 */
export const getKinektekCatalogueByCategory = async (category) =>
  fetch(
    // `${apiUrl}smartcitymanager/getKinektekCatalogueByCategory?category=${category}`,
    `${apiUrl}smartcitymanager/getKinektekCatalogueByCategory?category=${category}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .then((res) => {
      // try again after timeout (happens very frequently)
      if (res?.message === 'Endpoint request timed out') {
        return getKinektekCatalogueByCategory(category);
      }
      return res;
    })
    .catch((e) => e);

/**
 * Lookup Kinektek product
 * @returns {Promise}
 */
export const lookUpKinektekProduct = async (productID, reference) =>
  fetch(`${apiUrl}smartcitymanager/lookUpKinektekProduct`, {
    method: 'POST',
    async: true,
    body: JSON.stringify({
      productID,
      reference,
    }),
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .then((res) => {
      // try again after timeout (happens very frequently)
      if (res?.message === 'Endpoint request timed out') {
        return lookUpKinektekProduct(productID, reference);
      }
      return res;
    })
    .catch((e) => e);

/**
 * Ping Kinektek Dual Step Kinektek Payment
 * @returns {Promise}
 */
export const pingKinektekPurchase = async (sessionId) =>
  fetch(`${apiUrl}smartcitymanager/pingKinektekPurchase?uuid=${sessionId}`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .then((res) => {
      // try again after timeout (happens very frequently) and if status is Pending
      if (res?.message === 'Endpoint request timed out') {
        return pingKinektekPurchase(sessionId);
      }
      if (res?.status === 'PENDING') {
        return pingKinektekPurchase(sessionId);
      }
      return res;
    })
    .catch((e) => e);

/**
 * Confirm Kinektek Purchase
 * @returns {Promise}
 */
export const confirmKinektekPurchase = async (sessionId) =>
  fetch(`${apiUrl}smartcitymanager/confirmKinektekPurchase?uuid=${sessionId}`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

/**
 * Kinektek Transaction History
 * @returns {Promise}
 */
export const getKinektekTransactionHistory = async () =>
  fetch(`${apiUrl}smartcitymanager/getKinektekTransactionHistory?size=25`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

/**
 * Get Banners for In-the-City carousel
 * @returns {Promise}
 */
export const getBanners = async () =>
  fetch(`${apiUrl}smartcitymanager/getSmartCityBanners`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

const KINEKTEK_IMAGE_URL = 'https://d1ajknnc7blcog.cloudfront.net/';

// Get URL for Kinektek image
export const getImageURL = (image) => KINEKTEK_IMAGE_URL + image;
