import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Marker,
} from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import {
  Button,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function SearchMarker({
  searchMarker,
  city,
  handleChangeCity,
  serviceType,
  thisOfOverlay,
}) {
  // const [draggable, setDraggable] = useState(false);
  const navigate = useNavigate();
  console.log('searchMarker', searchMarker);
  if (!searchMarker.res.geometry.location.lat) {
    return null;
  }
  let option = '';
  let pin = '';
  // let popupPin = '';
  if (searchMarker.res1.currentCity) {
    // You are in a smart city
    // Are you currently in the right one?
    console.log('citycity', city, searchMarker.res1.currentCity.attributes.subscriberId);
    if (city === searchMarker.res1.currentCity.attributes.subscriberId) {
      // You are
      option = 'true';
      // popupPin = 'map/search/searchPopupInside.svg';
      pin = 'map/search/searchPinInside.svg';
    } else {
      // You're not
      option = 'switch';
      // popupPin = 'map/search/searchPopupOutside.svg';
      pin = 'map/search/searchPinOutside.svg';
    }
  } else {
    option = 'false';
    // popupPin = 'map/search/searchPopupOutside.svg';
    pin = 'map/search/searchPinOutside.svg';
  }

  console.log('option', option);

  const [position] = useState({
    lat: searchMarker.res.geometry.location.lat,
    lng: searchMarker.res.geometry.location.lng,
  });
  const markerRef = useRef(null);

  const icon = L.divIcon({
    className: 'custom icon',
    iconAnchor: [30, 60],
    popupAnchor: [-3, -70],
    html: ReactDOMServer.renderToString(
      <img
        style={{ height: '60px' }}
        alt="FavIcon"
        src={pin}
      />,
    ),
  });

  useEffect(() => {
    if (option !== 'true') {
      thisOfOverlay.setState({
        showAlert: true,
        alertBody:
      (
        <div style={{ textAlign: 'center' }}>
          <div
            style={{
              textAlign: 'center',
              fontSize: '2.0rem',
              fontFamily: 'GothamMedium',
              marginBottom: '15px',
              padding: '0px 50px',
              lineHeight: '2rem',
            }}
          >
            {option === 'false' ? 'Outside smart city' : null}
            {option === 'switch' ? 'Outside smart city' : null}
            {option === 'true' ? 'Inside smart city' : null}
          </div>

          <div style={{ fontSize: '1.2rem' }}>
            {searchMarker.res.name}
          </div>

          {option === 'false'
            ? (
              <div
                style={{
                  fontSize: '1.2rem',
                  margin: '20px 0px',
                  color: '#e17531',
                  fontFamily: 'GothamMedium',
                }}
              >
                Your address is outside a smart city zone. Please choose another location.
              </div>
            )
            : null}

          {option === 'switch'
            ? (
              <div
                style={{
                  fontSize: '1.2rem',
                  margin: '20px 0px',
                  color: '#e17531',
                  fontFamily: 'GothamMedium',
                }}
              >
                Your address is in another smart city zone. Would you like to switch to it?
                <br />
                <Button
                  className="homeButtonNoFloat"
                  style={{
                    marginTop: '25px',
                  }}
                  onClick={() => {
                    thisOfOverlay.setState({ showAlert: false });
                    handleChangeCity(
                      searchMarker.res1.currentCity.attributes.subscriberId,
                    ).then(() => {
                      navigate(`/map/${searchMarker.res1.currentCity.attributes.subscriberId}/${serviceType}`);
                    });
                  }}
                >
                  SWITCH
                </Button>
              </div>
            )
            : null}
        </div>
      ),
      });
    }
  }, []);

  return (
    <Marker
      position={position}
      ref={markerRef}
      icon={icon}
    />
  );
}
