import { css } from '@emotion/react';
import { Box, Flex } from 'components/layout';
import { UtilityAccount } from 'types/types';
import { Button, Image } from 'components/elements';
import { Text } from 'components/typography';

const getAccountStatusData = (
  statusId: number
): {
  icon: string;
  iconBackground: string;
  color: string;
  text: string;
  statusIcon: string;
} =>
  statusId === 1
    ? {
        icon: 'municipal-accounts/icon-yellow.svg',
        iconBackground: '#FEF3CC',
        color: '#FFD600',
        text: 'Pending',
        statusIcon: 'municipal-accounts/busy.svg',
      }
    : statusId === 2
    ? {
        icon: 'municipal-accounts/icon-green.svg',
        iconBackground: '#E6F6E6',
        color: '#00A300',
        text: 'Verified',
        statusIcon: 'municipal-accounts/check-circle.svg',
      }
    : {
        icon: 'municipal-accounts/icon-red.svg',
        iconBackground: '#FDE8E8',
        color: '#EA1B1B',
        text: 'Verification failed',
        statusIcon: 'municipal-accounts/declined.svg',
      };

const UtilityAccountItem = ({
  account,
  handleRemoveAccount,
  onClick,
}: {
  account: UtilityAccount;
  handleRemoveAccount?: (account: UtilityAccount) => void;
  onClick?: () => void;
}) => {
  const accStatus = getAccountStatusData(account.statusId);
  return (
    <Button
      className={onClick ? 'clickable' : ''}
      onClick={onClick}
      mb="20px"
      width="100%"
      p="9px 21px 9px 16px"
      key={account.accountNumber}
      boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
      overrideCss={css`
        border-left: 6px solid ${accStatus.color};
        cursor: ${onClick ? 'pointer' : 'default'} !important;
      `}
      borderRadius="4px"
    >
      <Flex justifyContent="space-between" flexShrink="0" alignItems="center">
        <Flex alignItems="center">
          <Box
            backgroundColor={accStatus.iconBackground}
            borderRadius="50%"
            mr="20px"
            p="10px"
          >
            <Image source={accStatus.icon} alt={account.status} width="25px" />
          </Box>
          <Box>
            <Text fontSize="16px">{account.orgUnitDescription}</Text>
            <Text>{account.accountNumber}</Text>
          </Box>
        </Flex>
        <Flex alignItems="center">
          <Image source={accStatus.statusIcon} alt={account.status} />
          {!!handleRemoveAccount && (
            <Button onClick={() => handleRemoveAccount(account)}>
              <Image
                source="municipal-accounts/delete.svg"
                alt="delete"
                ml="10px"
              />
            </Button>
          )}
        </Flex>
      </Flex>
    </Button>
  );
};

export default UtilityAccountItem;
