/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';

import theme from 'theme';
import { Text } from 'components/typography';
import { Link, useParams } from 'react-router-dom';
import ExternalLink from 'components/elements/external-link';

const GoGeorgePage = () => {
  const { cityId } = useParams();

  return (
    <MunicipalPageWrapper title="Go George">
      {cityId && (
        <Grid
          gridTemplateColumns="repeat(3, 1fr)"
          gridColumnGap="10px"
          gridRowGap="10px"
          mx="15%"
          mobileCss={css`
            grid-template-columns: 1fr;
            margin: 0;
          `}
        >
          <Link to="/map/george/public-transport">
            <Text
              p="16px"
              fontSize="16px"
              boxShadow="0px 3px 10px rgba(0, 0, 0, 0.05)"
              borderRadius="4px"
              textAlign="center"
              color="black"
              hoverCss={css`
                color: ${theme.colors.primary};
              `}
            >
              View on map
            </Text>
          </Link>
          <ExternalLink
            href="https://www.gogeorge.org.za/timetables"
            target="_blank"
            p="16px"
            fontSize="16px"
            boxShadow="0px 3px 10px rgba(0, 0, 0, 0.05)"
            borderRadius="4px"
            textAlign="center"
            color="black"
            hoverCss={css`
              color: ${theme.colors.primary};
            `}
          >
            View timetables
          </ExternalLink>
          <ExternalLink
            href="https://www.gogeorge.org.za/current-routes"
            target="_blank"
            p="16px"
            fontSize="16px"
            boxShadow="0px 3px 10px rgba(0, 0, 0, 0.05)"
            borderRadius="4px"
            textAlign="center"
            color="black"
            hoverCss={css`
              color: ${theme.colors.primary};
            `}
          >
            View routes
          </ExternalLink>
        </Grid>
      )}
    </MunicipalPageWrapper>
  );
};

export default GoGeorgePage;
