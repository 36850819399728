import React from 'react';
import { Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import 'leaflet-contextmenu';
// import { Context } from 'functions/context';
// SCSS
import 'styles/map.scss';
import 'styles/app.scss';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
import hasher from 'node-object-hash';
// Interface
import { updateProfile, getEmergencyContacts } from 'interface/profile';
import {
  getTopLevelOrgUnits,
  getAllLookupData,
  getTopLevelPetitionWorkRequestTypes,
  getAvailableCities,
  getCustomerPremises,
  selectCity,
  getGoogleMapsEntries,
  // getGoogleMapsEntryID,
  getMyMapWorkRequests,
  getMyMapPetitions,
  getMapWorkRequestsNearMeInViewPort,
  getMapPetitionsNearMeInViewPort,
  getWorkRequest,
} from 'interface/map';
import { logOut } from 'interface/login';
import { getUnreadNotifications } from 'interface/notifications';

import HistoryPush from 'components/historyPush';
import SelectCityModal from 'components/map/selectCityModal';
import OutsideBoundsModal from 'components/map/outsideBoundsModal';
import MapContainerContainer from 'components/map/mapContainerContainer';
// Functions
import { getMapBounds, isMarkerInsidePolygon } from 'functions/map';
import { authenticate } from 'functions/app';
import Overlay from 'components/map/overlay';
import { GIStoMSCObject } from 'functions/map-utils';

const ipLocation = require('iplocation');
const publicIp = require('public-ip');

const center = {
  lat: -33.9658589,
  lng: 18.8392618,
};

const myRefSelectCityModal = React.createRef();
const myRefAlertModal = React.createRef();
let mouseDown = 0;
let touchDown = 0;

document.body.onmousedown = () => {
  console.log('mouseDown!');
  mouseDown = 1;
};

document.body.onmouseup = () => {
  mouseDown = 0;
};

document.body.ontouchstart = () => {
  console.log('mouseDown touchstart!');
  touchDown = 1;
};

document.body.ontouchend = () => {
  console.log('mouseDown touchend!');
  touchDown = 0;
};
export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.globalMap = null;
    this.state = {
      polyLines: null,
      parkingSites: null,
      allLabels: [],
      theme: 'light',
      readyToRedirect: false,
      redirectTo: '',
      previousServiceType: '',
      currentServices: [],
      customerPremises: [],
      workRequests: { clusters: [], mapWorkRequests: [] },
      myWorkRequests: [],
      availableCities: [],
      currentCity: { attributes: {} },
      currentUser: {},
      loginModal: false,
      searchAddress: '',
      searchingAddress: false,
      searchAddressResults: [],
      profileImage: '',
      wards: [],
      toggleWards: false,
      searchReports: '',
      myLocation: center,
      viewportLocation: center,
      cityBounds: [],
      pushTo: '',
      viewport: {},
      callBackAction: '',
      showNotificationsModalMenu: false,
      emergencyContacts: [],
      firebaseUID: '8e5ee12f-7241-4412-9bcf-702de1740fcc',
    };
    this.markerClusterGroup = React.createRef();
    this.intervalNotifications = React.createRef();
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.hashSortCoerce = hasher({ sort: true, coerce: true, alg: 'sha1' });
    this.thisOfOverlay = React.createRef();
    this.thisOfMapContainerContainer = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    // check auth in context
    const { user } = this.props;
    if (user?.uuid !== prevProps.user?.uuid) {
      if (user?.uuid !== 'init' && user?.uuid !== 'false') {
        this.onSignIn();
      }
      if (user?.uuid === 'false') {
        this.setState({
          authenticatedState: false,
          currentUser: {},
          myWorkRequests: [],
          firebaseUID: '8e5ee12f-7241-4412-9bcf-702de1740fcc',
        });
        clearInterval(this.intervalNotifications);
        this.getWorkRequests(null, true, null, false);
      }
    }

    // do other thing
    Object.entries(this.props).forEach(
      ([key, val]) =>
        prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(
        ([key, val]) =>
          prevState[key] !== val && console.log(`State '${key}' changed`)
      );
    }
  }

  handleChangeCity = async (city) => {
    this.thisOfOverlay.setState({
      loadingMap: true,
      loadingMapA: true,
    });
    const { authenticatedState, currentUser, availableCities } = this.state;
    let cityObject = {};
    console.log('changing city to:', city, currentUser);
    if (authenticatedState) {
      await selectCity(city.value);
    }
    console.log('handleChangeCity', city);
    availableCities.forEach((x) => {
      console.log('currentCitycurrentCity', x.attributes.subscriberId, city);
      if (x.attributes.subscriberId === city) {
        cityObject = _.cloneDeep(x);
      }
    });

    this.globalMap.setView(
      [cityObject.attributes.latitude, cityObject.attributes.longitude],
      10,
      2
    );

    const currentAllLabels = await getAllLookupData(
      cityObject.attributes.subscriberId
    );
    const { wards, cityBounds } = getMapBounds(currentAllLabels);
    if (authenticatedState) {
      // Set the new city
      const currentUserT = _.cloneDeep(currentUser);
      currentUserT.currentCitySubscriberId = cityObject.value;
      const currentUserTT = await updateProfile(currentUserT);
      const customerPremisesTemp = await getCustomerPremises();
      const currentServicesTemp = await this.reloadFilters(
        false,
        false,
        false,
        city
      );
      const emergencyContacts = await getEmergencyContacts();
      // Get my work requests
      const { serviceType } = this.props;
      let tempMyWorkRequests = [];
      if (`${serviceType}` === 'petitions') {
        tempMyWorkRequests = await getMyMapPetitions(1, 200);
      }
      if (`${serviceType}` === 'municipal') {
        tempMyWorkRequests = await getMyMapWorkRequests(1, 200);
      }
      this.setState({
        emergencyContacts,
        myWorkRequests: tempMyWorkRequests,
        wards,
        cityBounds,
        currentUser: currentUserTT,
        customerPremises: customerPremisesTemp,
        allLabels: currentAllLabels,
        currentCity: cityObject,
        currentServices: currentServicesTemp,
        previousServiceType: '',
      });
    } else {
      const currentServicesTemp = await this.reloadFilters(
        false,
        false,
        false,
        city
      );
      this.setState({
        wards,
        cityBounds,
        allLabels: currentAllLabels,
        currentCity: cityObject,
        currentServices: currentServicesTemp,
        previousServiceType: '',
      });
    }
    this.thisOfOverlay.setState({
      loadingMap: false,
    });
    this.getWorkRequests(false, true);
    return null;
  };

  componentDidMount = async () => {
    const {
      authenticatedState,
      currentUser: cu,
      firebaseUID,
    } = await authenticate();
    let currentUser = _.cloneDeep(cu);
    let firebaseUIDT = firebaseUID;
    console.log('UPDAT!!E', currentUser, firebaseUID);
    const { city } = this.props;
    if (city === 'login' || city === 'signup') {
      this.setState({
        callBackAction: city,
        pushTo: '/map/',
      });
    }
    // Find location using browser location if not allowed use IP
    let myLocation = {};
    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        myLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log('geolocation position', myLocation);
      } catch (e) {
        console.log(e);
      }
    }
    // Locate based on IP
    if (!myLocation.lat) {
      const location = await ipLocation(await publicIp.v4());
      console.log('ipLocation position', location);
      myLocation = { lat: location.latitude, lng: location.longitude };
    }

    // Available Locations
    const availableCitiesTemp = await getAvailableCities();

    let selectC;
    // If user is not authenicated get location and set it to the subscriber id
    if (!authenticatedState) {
      firebaseUIDT = '8e5ee12f-7241-4412-9bcf-702de1740fcc';
      // console.log('selectCity', myLocation.lat, myLocation.lng);
      selectC = await selectCity(null, myLocation.lat, myLocation.lng);
      // console.log('selectCity', selectC);
      if (selectC && selectC.currentCity) {
        // You are inside the city
        console.log('location: IN CITY');
        currentUser.currentCitySubscriberId = selectC.currentCity.value;
      } else {
        console.log('location: OUTSIDE CITY');
        // You must now choose a city
        // but we have got your location from your IP
        // So we have set you to that city for now...
        // currentUser.currentCitySubscriberId

        if (!city || city === 'login' || city === 'signup') {
          const promis = await myRefSelectCityModal.current.show(
            availableCitiesTemp
          );
          currentUser.currentCitySubscriberId = promis.value;
        } else {
          // If there is a city in the URL
          let currentCityTemp2 = {};
          availableCitiesTemp.forEach((x) => {
            if (x.attributes.subscriberId === city) {
              currentCityTemp2 = _.cloneDeep(x);
            }
          });
          currentUser.currentCitySubscriberId = currentCityTemp2.value;
        }
        // Get the city bounds that the user selected.
        selectC = await selectCity(
          currentUser.currentCitySubscriberId,
          myLocation.lat,
          myLocation.lng
        );
        console.log('myRefPromiseModal', currentUser.currentCitySubscriberId);
      }
    } else if (!city || city === 'login' || city === 'signup') {
      if (!currentUser.currentCitySubscriberId) {
        const promis = await myRefSelectCityModal.current.show(
          availableCitiesTemp
        );
        selectC = await selectCity(
          promis.value,
          myLocation.lat,
          myLocation.lng,
          true // use token
        );
      } else {
        selectC = await selectCity(
          currentUser.currentCitySubscriberId,
          myLocation.lat,
          myLocation.lng,
          true // use token
        );
      }
    } else {
      let currentCityTemp2 = {};
      availableCitiesTemp.forEach((x) => {
        if (x.attributes.subscriberId === city) {
          currentCityTemp2 = _.cloneDeep(x);
        }
      });
      selectC = await selectCity(
        currentCityTemp2.value,
        myLocation.lat,
        myLocation.lng,
        true // use token
      );
      currentUser.currentCitySubscriberId = currentCityTemp2.value;
    }

    // Send to location
    if (selectC.myLocation && selectC.currentCity && selectC.currentLocation) {
      // If you are in the city fly to your location
      this.setState({
        viewportLocation: { lat: myLocation.lat, lng: myLocation.lng },
      });
      this.globalMap.setView([myLocation.lat, myLocation.lng], 16);
    } else {
      // If you are not in the city fly to the city bounds
      // eslint-disable-next-line
      if (selectC.northEast) {
        console.log('Fitting to bounds');
        this.setState({ viewportLocation: selectC.centerLatLong });
        this.globalMap.fitBounds(
          [
            [selectC.northEast.lat, selectC.northEast.lng],
            [selectC.southWest.lat, selectC.southWest.lng],
          ]
          // 16,
          // 2,
        );
      } else {
        this.setState({
          viewportLocation: { lat: myLocation.lat, lng: myLocation.lng },
        });
        this.globalMap.setView([myLocation.lat, myLocation.lng], 16);
      }
    }

    // --- Get the current city id
    // --- Set the current city
    let currentCityTemp = {};
    console.log('current city:', currentUser);
    console.log('availableCitiesTemp', availableCitiesTemp);
    availableCitiesTemp.forEach((x) => {
      if (x.value === currentUser.currentCitySubscriberId) {
        currentCityTemp = _.cloneDeep(x);
      }
    });
    if (!currentCityTemp?.attributes) {
      currentCityTemp = availableCitiesTemp[0];
    }
    console.log('current city:', currentCityTemp);
    // If the user just goes to /map redirect them to the city
    if (
      currentCityTemp?.attributes?.subscriberId &&
      city !== currentCityTemp.attributes.subscriberId
    ) {
      this.setState({
        pushTo: `/map/${currentCityTemp.attributes.subscriberId}/municipal`,
      });
    }

    // Get all of the Org units for the work order image look up
    const allLabelsTemp = await getAllLookupData(
      currentCityTemp.attributes.subscriberId
    );

    // Get wards and city bounds for the polygons on the map
    const { wards, cityBounds } = getMapBounds(allLabelsTemp);

    // Get the correct filters
    const currentServicesTemp = await this.reloadFilters(
      false,
      false,
      availableCitiesTemp
    );

    this.setState({
      allLabels: allLabelsTemp,
      currentServices: currentServicesTemp,
      availableCities: availableCitiesTemp,
      currentCity: currentCityTemp,
      wards,
      cityBounds,
      myLocation,
      firebaseUID: firebaseUIDT,
    });
    this.onSignIn();
  };

  onMoveEnd = () => {
    // Load the workorders
    const viewportLocation1 = this.globalMap.getCenter();
    const { viewport } = this.state;
    if (!viewport.northEast) {
      this.setState({
        viewportLocation: viewportLocation1,
        viewport: {
          northEast: this.globalMap.getBounds().getNorthEast(),
          southWest: this.globalMap.getBounds().getSouthWest(),
        },
      });
    }
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        if (mouseDown !== 1 && touchDown !== 1) {
          this.getWorkRequests(false, true);
          this.setState({
            viewportLocation: viewportLocation1,
          });
          console.log(
            'viewport',
            this.globalMap.getBounds().getNorthEast(),
            viewport
          );
        } else {
          this.onMoveEnd();
        }
      }, 1000);
    }
    this.debouncedFn();
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalNotifications);
  };

  renderMap = (map) => {
    this.globalMap = map;
    map.on('moveend', this.onMoveEnd);
    return null;
  };

  onSignIn = async () => {
    let { availableCities } = this.state;
    const { currentCity, callBackAction } = this.state;
    const { serviceType, city } = this.props;
    // This gets called when someone signs in.
    // We need to load user specific data.

    // authenticate
    const {
      authenticatedState,
      currentUser: currentUserTT,
      firebaseUID,
    } = await authenticate();

    let currentUser = _.cloneDeep(currentUserTT);
    if (availableCities.length === 0) {
      availableCities = await getAvailableCities();
      this.setState({ availableCities });
    }

    const currentServicesTemp = await this.reloadFilters(
      false,
      false,
      availableCities
    );
    if (authenticatedState) {
      // Check if you are in the right city.
      console.log(
        'currentUser',
        currentUser.currentCitySubscriberId,
        currentCity.value
      );
      if (currentUser.currentCitySubscriberId !== currentCity.value) {
        // Switch the user to the current city.
        currentUser.currentCitySubscriberId = currentCity.value;
        currentUser = await updateProfile(currentUser);
      }

      // Favourite locations
      const customerPremisesTemp = await getCustomerPremises();

      // Get my work requests
      let tempMyWorkRequests = [];
      if (`${serviceType}` === 'petitions') {
        tempMyWorkRequests = await getMyMapPetitions(1, 200);
      }
      if (`${serviceType}` === 'municipal') {
        tempMyWorkRequests = await getMyMapWorkRequests(1, 200);
      }

      const emergencyContacts = await getEmergencyContacts();
      this.setState({
        emergencyContacts,
        authenticatedState,
        currentUser,
        customerPremises: customerPremisesTemp,
        currentServices: currentServicesTemp,
        firebaseUID,
        myWorkRequests: tempMyWorkRequests,
      });
      this.getWorkRequests(false, true);
    }

    // Interval
    clearInterval(this.intervalNotifications);
    this.intervalNotifications = setInterval(async () => {
      const {
        myLocation,
        authenticatedState: authenticatedState1,
        myWorkRequests,
      } = this.state;
      if (authenticatedState1) {
        console.log('getUnreadNotifications');
        getUnreadNotifications().then((res) => {
          if (res === -1) {
            this.onSignOut();
          } else if (
            this.hashSortCoerce.hash(
              this.thisOfOverlay.state.unreadNotifications
            ) !== this.hashSortCoerce.hash(res)
          ) {
            this.thisOfOverlay.setState({ unreadNotifications: res });
            if (`${serviceType}` === 'petitions') {
              getMyMapPetitions(1, 200).then((res1) => {
                if (
                  this.hashSortCoerce.hash(myWorkRequests) !==
                  this.hashSortCoerce.hash(res1)
                ) {
                  this.setState({ myWorkRequests: res1 });
                }
              });
            }
            if (`${serviceType}` === 'municipal') {
              getMyMapWorkRequests(1, 200).then((res1) => {
                this.setState({ myWorkRequests: res1 });
              });
            }
          } else {
            console.log('Notifications are the same and no update required');
          }
        });
      }

      let myLocation1 = {};
      if (navigator.geolocation) {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          myLocation1 = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          console.log('geolocation position', myLocation1);
        } catch (e) {
          console.log(e);
        }
      }
      console.log('myLocation', myLocation, myLocation1);
      if (
        myLocation1.lat &&
        this.hashSortCoerce.hash(myLocation) !==
          this.hashSortCoerce.hash(myLocation1)
      ) {
        this.setState({
          myLocation: myLocation1,
        });
      }
      // Auto update
      this.getWorkRequests(false, true);
    }, 10000);

    const { currentWorkRequest } = this.props;
    if (`${currentWorkRequest}` !== 'undefined') {
      getWorkRequest(
        `${currentWorkRequest}`,
        currentCity.attributes.subscriberId,
        authenticatedState
      ).then((res) => {
        if (res === -1) {
          console.log(`No such ${serviceType} exists`);
          const temp2 = (
            <div className="modalBodyAlert">
              <div className="modalHeadingAlert">Invalid URL</div>

              <div className="modalTextAlert">
                Seems like the link that you clicked on is{' '}
                <div className="textAccentOrange">invalid.</div> Make sure that
                you have copied the link correctly.
                <Button
                  className="homeButton"
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    fontSize: '1.2rem',
                    padding: '10px 32px',
                    float: 'initial',
                    margin: '30px 0px 0px',
                  }}
                  onClick={() => this.setState({ showAlert: false })}
                >
                  CLOSE
                </Button>
              </div>
            </div>
          );
          this.setState({
            showAlert: true,
            alertBody: temp2,
          });
        } else {
          this.globalMap.flyTo([res.latitude, res.longitude], 16, 2);
          this.setState({ showMarkerView: true, showMarkerViewModalWR: res });
        }
      });

      this.setState({ pushTo: `/map/${city}/${serviceType}` });
    }
    this.thisOfOverlay.setState({ loadingMap: false });
    if (authenticatedState) {
      return true;
    }
    if (callBackAction === 'login' || callBackAction === 'signup') {
      this.setState({ loginModal: true });
    }
    this.setState({ authenticatedState });
    this.getWorkRequests(null, null, currentServicesTemp);

    return false;
  };

  onSignOut = async () => {
    await logOut();
    this.setState({
      authenticatedState: false,
      currentUser: {},
    });
    console.log('onSignOut');
    clearInterval(this.intervalNotifications);
    this.setState({
      myWorkRequests: [],
      firebaseUID: '8e5ee12f-7241-4412-9bcf-702de1740fcc',
    });
    this.getWorkRequests(null, true, null, false);
    return false;
  };

  reloadFilters = async (
    setState,
    forwardServiceType,
    availableCitiesT,
    cityt
  ) => {
    const { serviceType } = this.props;
    let { city } = this.props;
    let { availableCities } = this.state;
    let serviceTypet = serviceType;
    let currentCity = {};
    if (availableCitiesT) {
      availableCities = availableCitiesT;
    }
    if (cityt) {
      city = cityt;
    }
    console.log(
      'currentCitycurrentCity availableCities',
      availableCities,
      city
    );
    availableCities.forEach((x) => {
      if (x.attributes.subscriberId === city) {
        currentCity = _.cloneDeep(x);
      }
    });

    if (`${forwardServiceType}` !== 'false') {
      serviceTypet = forwardServiceType;
    } else {
      serviceTypet = serviceType;
    }
    console.log(
      'reloadFilters',
      forwardServiceType,
      setState,
      serviceTypet,
      currentCity
    );
    let returp = [];
    if (serviceTypet === 'municipal') {
      // --- Municipal Service Types
      const municipalServiceTemp1 = (
        await getTopLevelOrgUnits('MS', currentCity.attributes.subscriberId)
      ).data;
      const municipalServiceTemp = _.cloneDeep(municipalServiceTemp1);
      for (let i = 0; i < municipalServiceTemp1.length; i++) {
        municipalServiceTemp[i].selected = true;
      }
      returp = municipalServiceTemp;
    }
    if (serviceTypet === 'emergency') {
      // --- Emergency Services Types
      const emergencyServicesTemp = _.cloneDeep(
        (await getTopLevelOrgUnits('ES', currentCity.attributes.subscriberId))
          .data
      );
      for (let i = 0; i < emergencyServicesTemp.length; i += 1) {
        emergencyServicesTemp[i].selected = true;
      }
      returp = emergencyServicesTemp;
    }
    if (serviceTypet === 'petitions') {
      // --- Petitions Services
      const petitionServicesTemp = _.cloneDeep(
        await getTopLevelPetitionWorkRequestTypes(
          currentCity.attributes.subscriberId
        )
      );
      for (let i = 0; i < petitionServicesTemp.length; i += 1) {
        petitionServicesTemp[i].selected = true;
      }
      returp = petitionServicesTemp;
    }
    if (setState && forwardServiceType) {
      this.setState({ currentServices: returp });
    } else if (setState) {
      this.setState({ currentServices: returp });
    }
    return returp;
  };

  getWorkRequests = async (
    forwardServiceType,
    force,
    currentServicesT,
    authenticatedStateT
  ) => {
    const { serviceType } = this.props;
    const { previousServiceType, currentCity, viewportLocation, workRequests } =
      this.state;

    let { authenticatedState, currentServices } = this.state;
    let workRequestsT = _.cloneDeep(workRequests);
    if (!workRequestsT.clusters) {
      workRequestsT = { clusters: [], mapWorkRequests: [] };
    }
    if (authenticatedStateT) {
      authenticatedState = authenticatedStateT;
    }
    if (currentServicesT) {
      console.log('currentServicesT', currentServicesT);
      currentServices = currentServicesT;
    }
    if (typeof authenticatedState === 'undefined') {
      return;
    }
    let serviceTypet;
    if (forwardServiceType) {
      serviceTypet = forwardServiceType;
    } else {
      serviceTypet = serviceType;
    }
    this.thisOfOverlay.setState({
      loadingWorkRequests: true,
    });
    // Cluster rows and col

    const clusterColumns = Math.round(window.innerWidth / 200);
    const clusterRows = Math.round(window.innerHeight / 200);

    // Define current service types
    if (serviceTypet === 'municipal') {
      this.setState({
        parkingSites: null,
        polyLines: null,
      });
      // Load work orders that are not yours.
      let tempString = '';
      const selectedList = [];
      for (let i = 0; i < currentServices.length; i++) {
        if (currentServices[i].selected) {
          selectedList.push(currentServices[i]);
        }
      }

      for (let i = 0; i < selectedList.length; i++) {
        if (selectedList[i].selected && i !== selectedList.length - 1) {
          tempString += `${selectedList[i].value},`;
        }
        if (selectedList[i].selected && i === selectedList.length - 1) {
          tempString += `${selectedList[i].value}`;
        }
      }

      if (tempString.localeCompare('') === 0) {
        tempString = '-1';
      }
      const temp = {
        northEast: this.globalMap.getBounds().getNorthEast(),
        southWest: this.globalMap.getBounds().getSouthWest(),
      };
      console.log('viewport temp', temp);
      let ttemp = true;
      if (this.globalMap.getZoom() === 18) {
        ttemp = false;
      }
      const response = await getMapWorkRequestsNearMeInViewPort(
        false, // includeMyWork
        tempString, // Org unit id to be displayed
        1, // pageNo
        1000, // limitPerPage
        temp, // viewport
        currentCity.attributes.subscriberId, // subscriberId
        authenticatedState, // authenticatedState
        ttemp, // cluster
        clusterColumns,
        clusterRows
      );
      workRequestsT = response;
    }
    this.setState({
      polyLines: null,
    });
    if (serviceTypet === 'petitions') {
      this.setState({
        parkingSites: null,
        polyLines: null,
      });
      let tempString = '';
      const selectedList = [];
      for (let i = 0; i < currentServices.length; i++) {
        if (currentServices[i].selected) {
          selectedList.push(currentServices[i]);
        }
      }
      for (let i = 0; i < selectedList.length; i++) {
        if (selectedList[i].selected && i !== selectedList.length - 1) {
          tempString += `${selectedList[i].value},`;
        }
        if (selectedList[i].selected && i === selectedList.length - 1) {
          tempString += `${selectedList[i].value}`;
        }
      }

      if (tempString.localeCompare('') === 0) {
        tempString = '-1';
      }
      const temp = {
        northEast: this.globalMap.getBounds().getNorthEast(),
        southWest: this.globalMap.getBounds().getSouthWest(),
      };
      console.log('viewport temp', temp);
      let ttemp = true;
      if (this.globalMap.getZoom() === 18) {
        ttemp = false;
      }
      const response = await getMapPetitionsNearMeInViewPort(
        false, // includeMyWork
        tempString, // Org unit id to be displayed
        1, // pageNo
        1000, // limitPerPage
        temp, // viewport
        currentCity.attributes.subscriberId, // subscriberId
        authenticatedState, // authenticatedState
        ttemp, // cluster
        clusterColumns,
        clusterRows
      );
      workRequestsT = response;
    }
    if (serviceTypet === 'emergency' && forwardServiceType) {
      this.setState({
        parkingSites: null,
        polyLines: null,
      });
      // Now search on all of those fields (names)
      // query location radius
      if (currentCity.value !== 663) {
        const mylittlepromises = [];
        for (let i = 0; i < currentServices.length; i++) {
          if (currentServices[i].selected) {
            mylittlepromises.push(
              getGoogleMapsEntries(
                currentServices[i].attributes.googleSearchTerm,
                `${viewportLocation.lat}, ${viewportLocation.lng}`,
                500
              ).then((res) => {
                for (let r = 0; r < res.results.length; r++) {
                  res.results[r].id = res.results[r].place_id;
                  res.results[r].orgUnitId = currentServices[i].value;
                  res.results[r].customIcon =
                    currentServices[i].attributes.customIcon;
                }
                return res.results;
              })
            );
          }
        }
        const values = await Promise.all(mylittlepromises);
        const temp = values.flat();
        console.log('promise.all values', temp);
        workRequestsT = {
          clusters: [],
          mapWorkRequests: this.googleObjectToMscObject(temp),
        };
      } else {
        this.setState({
          parkingSites: null,
          polyLines: null,
        });
        const mylittlepromises = [];
        for (let i = 0; i < currentServices.length; i++) {
          if (currentServices[i].selected) {
            mylittlepromises.push(
              fetch(
                `https://gis.george.gov.za/server/rest/services/Public_App_Data/MapServer/48/query?where=Facility_category='${currentServices[i].attributes.googleSearchTerm}'&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Kilometer&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`
              )
                .then((res) => res.json())
                .then((json) =>
                  json.features.map((f) =>
                    GIStoMSCObject(
                      f,
                      currentServices[i].attributes.customIcon,
                      currentServices[i].value
                    )
                  )
                )
            );
          }
        }
        const values = await Promise.all(mylittlepromises);
        const temp = values.flat();
        console.log('promise.all values', temp);
        workRequestsT = {
          clusters: [],
          mapWorkRequests: temp,
        };
      }
    }
    if (serviceType === 'public-transport') {
      // This tab is currently George Municipality specific
      this.setState({
        parkingSites: null,
      });
      const temp = await fetch(
        'https://gis.george.gov.za/server/rest/services/Public_App_Data/MapServer/74/query?where=0=0&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Kilometer&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson'
      )
        .then((r) => r.json())
        .then((json) =>
          json.features.map((f) => {
            return {
              business_status: 'OPERATIONAL',
              formatted_address: `${f.properties.StopName} Bus Stop`,
              place_id: f.id,
              id: f.id,
              lng: f.geometry.coordinates[0],
              lat: f.geometry.coordinates[1],
              typeDescription: f.properties.TimeTableW,
              imageIds: [],
              custom1: f.properties.StopNumber,
              customIcon: null,
              emergencyService: false,
            };
          })
        );
      console.log('promise.all values', temp);
      workRequestsT = {
        clusters: [],
        mapWorkRequests: temp,
      };

      let polyLineData = [];
      const polyLinePhases = [76, 79, 82, 106];
      for (let i = 0; i < polyLinePhases.length; i++) {
        const lineData = await fetch(
          `https://gis.george.gov.za/server/rest/services/Public_App_Data/MapServer/${polyLinePhases[i]}/query?where=0=0&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Kilometer&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`
        ).then((r) => r.json());
        polyLineData.push(lineData);
      }
      const polyLinesFlat = polyLineData.flat();
      this.setState({
        polyLines: polyLinesFlat,
      });
    }
    if (serviceType === 'parking') {
      this.setState({
        polyLines: null,
      });
      workRequestsT = {
        clusters: [],
        mapWorkRequests: [],
      };
      const temp = await fetch(
        'https://gis.george.gov.za/server/rest/services/Public_App_Data/MapServer/57/query?where=0=0&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Kilometer&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson'
      ).then((r) => r.json());
      this.setState({
        parkingSites: temp.features,
      });
    }
    this.thisOfOverlay.setState({
      loadingWorkRequests: false,
    });
    if (
      this.hashSortCoerce.hash(workRequestsT) !==
        this.hashSortCoerce.hash(workRequests) ||
      this.hashSortCoerce.hash(serviceTypet) !==
        this.hashSortCoerce.hash(previousServiceType)
    ) {
      this.setState({
        previousServiceType: serviceTypet,
        workRequests: workRequestsT,
      });
    }
  };

  googleObjectToMscObject = (objects) => {
    const temp = _.cloneDeep(objects);
    for (let i = 0; i < temp.length; i++) {
      temp[i].lat = temp[i].geometry.location.lat;
      temp[i].lng = temp[i].geometry.location.lng;
      temp[i].address = temp[i].formatted_address;
      temp[i].typeDescription = temp[i].name;
      temp[i].imageIds = [];
      const type = `${temp[i].types[0]}`;
      temp[i].custom1 = type;
      temp[i].emergencyService = true;
      delete temp[i].geometry;
      delete temp[i].name;
      delete temp[i].types;
    }
    console.log('googleObjectToMscObject', temp);
    return temp;
  };

  changeLocation = (pos) => {
    this.globalMap.setView([pos.lat, pos.lng], 16);
  };

  logAReportHere = (e) => {
    const { cityBounds } = this.state;
    let temp = false;
    for (let i = 0; i < cityBounds.length; i++) {
      if (isMarkerInsidePolygon(e.latlng, cityBounds[i])) {
        temp = true;
        break;
      }
    }
    if (temp) {
      this.thisOfOverlay.setState({
        useThisLocationForLog: e.latlng,
        reportModal: true,
      });
    } else {
      myRefAlertModal.current.show();
    }
  };

  logAPetitionHere = (e) => {
    const { cityBounds } = this.state;
    let temp = false;
    for (let i = 0; i < cityBounds.length; i++) {
      if (isMarkerInsidePolygon(e.latlng, cityBounds[i])) {
        temp = true;
        break;
      }
    }
    if (temp) {
      this.thisOfOverlay.setState({
        useThisLocationForLog: e.latlng,
        petitionModal: true,
      });
    } else {
      myRefAlertModal.current.show();
    }
  };

  render() {
    console.warn(
      '====================================map.jsx rendered===================================='
    );
    if (this.globalMap) {
      const viewportLocation1 = this.globalMap.getBounds();
      console.log('viewportLocation1', viewportLocation1);
    }
    const { serviceType, currentWorkRequest, city } = this.props;
    const {
      readyToRedirect,
      redirectTo,
      workRequests,
      allLabels,
      theme,
      currentCity,
      currentUser,
      loginModal,
      authenticatedState,
      customerPremises,
      wards,
      toggleWards,
      myLocation,
      cityBounds,
      pushTo,
      viewportLocation,
      availableCities,
      showMarkerView,
      showMarkerViewModalWR,
      myWorkRequests,
      callBackAction,
      emergencyContacts,
      currentServices,
      firebaseUID,
      polyLines,
      parkingSites,
    } = this.state;

    console.log(polyLines);

    if (readyToRedirect && redirectTo !== '') {
      return <Navigate to={redirectTo} />;
    }
    // console.log('Current work order', currentWorkRequest, cityBounds);
    return (
      <div id="map">
        <HistoryPush pushTo={pushTo} setState={(s) => this.setState(s)} />
        {/* Loading */}
        <SelectCityModal
          ref={myRefSelectCityModal}
          setState={(s) => this.setState(s)}
        />
        <OutsideBoundsModal ref={myRefAlertModal} />
        <Overlay
          currentServices={currentServices}
          myLocation={myLocation}
          currentCity={currentCity}
          customerPremises={customerPremises}
          currentUser={currentUser}
          loginModal={loginModal}
          globalMap={this.globalMap}
          currentWorkRequest={currentWorkRequest}
          allLabels={allLabels}
          availableCities={availableCities}
          showMarkerView={showMarkerView}
          showMarkerViewModalWR={showMarkerViewModalWR}
          callBackAction={callBackAction}
          emergencyContacts={emergencyContacts}
          cityBounds={cityBounds}
          myWorkRequests={myWorkRequests}
          serviceType={serviceType}
          toggleWards={toggleWards}
          city={city}
          authenticatedState={authenticatedState}
          firebaseUID={firebaseUID}
          setState={(s) => this.setState(s)}
          reloadFilters={(
            setState,
            forwardServiceType,
            availableCitiesT,
            cityt
          ) =>
            this.reloadFilters(
              setState,
              forwardServiceType,
              availableCitiesT,
              cityt
            )
          }
          getWorkRequests={(
            forwardServiceType,
            force,
            currentServicesT,
            authenticatedStateT
          ) =>
            this.getWorkRequests(
              forwardServiceType,
              force,
              currentServicesT,
              authenticatedStateT
            )
          }
          handleChangeCity={(s) => this.handleChangeCity(s)}
          changeLocation={(s) => this.changeLocation(s)}
          onSignIn={(s) => this.onSignIn(s)}
          onSignOut={(s) => this.onSignOut(s)}
          // eslint-disable-next-line
          thisOfOverlay={(thisOfOverlay) =>
            (this.thisOfOverlay = thisOfOverlay)
          }
          thisOfMapContainerContainer={this.thisOfMapContainerContainer}
        />
        <MapContainerContainer
          viewportLocation={viewportLocation}
          authenticatedState={authenticatedState}
          customerPremises={customerPremises}
          myLocation={myLocation}
          serviceType={serviceType}
          city={city}
          polyLines={polyLines}
          parkingSites={parkingSites}
          theme={theme}
          cityBounds={cityBounds}
          toggleWards={toggleWards}
          wards={wards}
          workRequests={workRequests}
          myWorkRequests={myWorkRequests}
          currentCity={currentCity}
          map={this.globalMap}
          logAReportHere={(s) => this.logAReportHere(s)}
          logAPetitionHere={(s) => this.logAPetitionHere(s)}
          setState={(s) => this.setState(s)}
          renderMap={(s) => this.renderMap(s)}
          handleChangeCity={(s) => this.handleChangeCity(s)}
          thisOfOverlay={this.thisOfOverlay}
          // eslint-disable-next-line
          thisOfMapContainerContainer={(thisOfMapContainerContainer) =>
            (this.thisOfMapContainerContainer = thisOfMapContainerContainer)
          }
          markerClusterGroup={this.markerClusterGroup}
          getWorkRequests={(
            forwardServiceType,
            force,
            currentServicesTemp,
            authenticatedStateT
          ) =>
            this.getWorkRequests(
              forwardServiceType,
              force,
              currentServicesTemp,
              authenticatedStateT
            )
          }
        />
      </div>
    );
  }
}
