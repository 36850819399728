/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import SEO from 'components/seo';
import Footer from 'components/footer';
import { PageWrapper } from 'components/section';
import { AppSection, Hero } from '../../components/private-services/campaign';
import WhySmartCitySection from '../../components/private-services/campaign/whySmartCitySection';
import MediaCarousel from '../../components/private-services/campaign/carousel';

/**
 * Private Services Survey Page
 */
const PrivateServicesCampaignPage = () => {
  const fields = [];

  return (
    <PageWrapper>
      <SEO
        title="My Smart City | Private Services"
        description="Private Services campaign"
      />
      <Hero />
      <MediaCarousel />
      <WhySmartCitySection />
      <AppSection />
      <Footer />
    </PageWrapper>
  );
};

export default PrivateServicesCampaignPage;
