import React from 'react';
import 'styles/mapModal.scss';
import { Context } from 'functions/context';
import lottie from 'lottie-web';
import {
  upVoteWorkRequest,
  getMyMapWorkRequests,
} from 'interface/map';
import {
  Button,
} from 'react-bootstrap';

export default class UpVoteReportModal extends React.Component {
    static contextType = Context;

    constructor(props) {
      super(props);
      this.state = {
        loadingUpVoteReportModal: false,
        loadingUpVoteReportModalA: false,
      };
    }

    handleSubmit = async () => {
      console.log('EditReportModal: handleSubmit()');
      const {
        showUpVoteReportWR,
        setState,
        getWorkRequests,
        thisOfOverlay,
      } = this.props;

      // Set loading to true
      this.setState({
        loadingUpVoteReportModal: true,
        loadingUpVoteReportModalA: true,
      });
      await upVoteWorkRequest(showUpVoteReportWR.id);
      await getWorkRequests(
        null,
        true,
        null,
        true,
      );
      const myWorkRequests = await getMyMapWorkRequests(1, 200);

      this.setState({
        loadingUpVoteReportModal: false,
      });
      thisOfOverlay.setState({
        showUpVoteReport: false,
        showMarkerView: true,
      });
      setState({
        myWorkRequests,
      });
    };

    componentDidMount = async () => {
      // Loading
      const animLogin1 = lottie.loadAnimation({
        name: 'loadingUpVoteReportModal',
        container: this.animateLoading1,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'mscLogoGrey.json',
      });

      animLogin1.onLoopComplete = () => {
        const { loadingUpVoteReportModal, loadingUpVoteReportModalA } = this.state;
        console.log('onLoopComplete', loadingUpVoteReportModal, loadingUpVoteReportModalA);
        if (!loadingUpVoteReportModal) {
          lottie.stop('loadingloadingUpVoteReportModal');
          this.setState({ loadingUpVoteReportModalA: false });
          console.log('onLoopComplete: loadingloadingUpVoteReportModalSTOPPED');
        }
      };
    }

    componentWillUnmount = () => {
      lottie.destroy('loadingUpVoteReportModal');
    };

    render() {
      const {
        loadingUpVoteReportModal,
        loadingUpVoteReportModalA,
      } = this.state;
      const { thisOfOverlay } = this.props;

      if (loadingUpVoteReportModal) {
        lottie.play('loadingUpVoteReportModal');
      }
      return (
        <div className="mapModal">
          <div id="upVoteReportModal">
            <div
              id="LoadingLoginOverlayContainer"
              style={
              loadingUpVoteReportModalA
                ? {
                  display: 'block',
                }
                : {
                  display: 'none',
                }
              }
            >
              <div
                id="LoadingLoginOverlay"
                className={loadingUpVoteReportModal
                  ? 'fadeIn'
                  : 'fadeOut'}
              />
              <div
                ref={(ref) => { this.animateLoading1 = ref; }}
                id="LoadingLoginOverlayImage"
                style={
                loadingUpVoteReportModalA
                  ? {
                    display: 'block',
                  }
                  : {
                    display: 'none',
                  }
              }
              />
            </div>
            <div>
              <div className="title">
                Upvote
              </div>
              <div className="text">
                You are about to upvote this issue, are you sure?
              </div>
              <div style={{ textAlign: 'left' }}>
                <Button
                  className="modalButton"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  YES
                </Button>

                <Button
                  className="modalButton float-right"
                  onClick={() => {
                    thisOfOverlay.setState({
                      showUpVoteReport: false,
                      showMarkerView: true,
                    });
                  }}
                >
                  NO
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
