/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { Loader } from 'components/social/createpost';
import { useOnClickOutside } from 'functions/home';
import { Connection } from 'components/social/connection';
import { getSmartCityUsersByString, getSuggestedConnections } from 'interface/social';
import {
  MobileDropdown,
  BackButton,
  SearchButton,
  MobileContainer,
  MobileSearchInput,
  Title,
  SearchInput,
  Dropdown,
  ResetButton,
  Container,
  NotFoundContainer,
} from './search.styled';

const fetchSearchData = async (query, callback) => {
  const res = await getSmartCityUsersByString(query);
  callback(res);
};

const debouncedFetchSearchData = debounce((query, callback) => {
  if (query.length > 0) fetchSearchData(query, callback);
}, 500);

const Search = () => {
  const { pathname } = useLocation();
  const [searchData, setSearchData] = useState([]);
  const [connectionsData, setConnectionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');

  // mobile state
  const [width, setWidth] = useState(window.innerWidth);
  const [openMobile, setOpenMobile] = useState(false);
  const isMobile = width <= 800;

  // desktop dropdown
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (!(query.length > 0)) setOpen(false);
  });

  const handleClose = () => {
    setQuery('');
    setOpen(false);
    setOpenMobile(false);
  };

  const handleSearchClick = () => (isMobile ? setOpenMobile(true) : setOpen(true));

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    // set suggested followers
    const fetchData = async () => {
      setLoading(true);
      const res = await getSuggestedConnections();
      setConnectionsData(res?.data);
      setLoading(false);
    };
    fetchData();

    // event listener to check if on mobile
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    debouncedFetchSearchData(query, (res) => {
      setLoading(true);
      setSearchData(res?.data);
      setLoading(false);
    });
  }, [query]);

  // close search box when going to a different page
  useEffect(handleClose, [pathname]);

  return (
    <>
      {/* <SearchButton onClick={() => setOpenMobile(true)} /> */}
      {openMobile && (
        <>
          <MobileContainer>
            <BackButton
              onClick={handleClose}
            />
            <MobileSearchInput
              value={query}
              placeholder="Search people"
              onChange={handleQueryChange}
            />
          </MobileContainer>
          <MobileDropdown>
            {query.length > 0
              ? (
                <UserList
                  title="Results"
                  items={searchData}
                  loading={loading}
                />
              )
              : (
                <UserList
                  title="Suggested Followers"
                  items={connectionsData}
                  loading={loading}
                />
              )}
            {(query.length > 0 && searchData?.length === 0) && (
              <NotFoundContainer>
                <h2>Search not found</h2>
                <p>This may be because the person is in a different city or they are not in My Smart City yet.</p>
              </NotFoundContainer>
            )}
          </MobileDropdown>
        </>
      )}

      <div ref={ref}>
        <Container>
          <SearchInput
            value={query}
            placeholder="Search people"
            onChange={handleQueryChange}
            onClick={handleSearchClick}
          />
          <ResetButton
            show={query.length > 0}
            onClick={() => setQuery('')}
          />
        </Container>

        <Dropdown open={open || query.length > 0}>
          {query.length > 0
            ? (
              <UserList
                title="Results"
                items={searchData}
                loading={loading}
              />
            )
            : (
              <UserList
                title="Suggested Followers"
                items={connectionsData}
                loading={loading}
              />
            )}
          {(query.length > 0 && searchData?.length === 0) && (
            <NotFoundContainer>
              <h2>Search not found</h2>
              <p>This may be because the person is in a different city or they are not in My Smart City yet.</p>
            </NotFoundContainer>
          )}
        </Dropdown>
      </div>
    </>
  );
};

const UserList = ({ title, items, loading }) => {
  return (
    <>
      <Title>
        {title}
      </Title>
      {loading && <Loader />}
      {(Array.isArray(items) && items.length !== 0) ? items.map(({ uuid, imgUrl, fullName, currentCitySubscriberId, smartCityUserRoles, hasProfilePic, verified, followable, followed }) => (
        <Connection
          key={uuid}
          uuid={uuid}
          imgUrl={imgUrl}
          fullName={fullName}
          subscriberId={currentCitySubscriberId}
          role={smartCityUserRoles}
          isFollowing={followed}
          followable={followable}
          verified={verified}
        />
      )) : null}
    </>
  );
};

export default Search;
