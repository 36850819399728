import { apiBaseUrlBase } from 'constants/app';

export const imageUrl = (id, subscriberId) =>
  `${apiBaseUrlBase}modal/viewimagefile.html?id=${id}&subscriberId=${subscriberId}`;

export const profileImageUrl = (uuid) =>
  `${apiBaseUrlBase}images/profilepicture/?uuid=${uuid}&_=${Date.now()}`;

export const wrTypeIconUrl = (name, subscriberId) =>
  `${apiBaseUrlBase}images/custom/${name}?cityId=${subscriberId}`;

export const cityBackgroundUrl = (cityId) =>
  `${apiBaseUrlBase}images/custom/city_background.png?cityId=${cityId}`;
