import styled from 'styled-components';

export const StyledActionBar = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 60px;
  padding: 0 100px;
  background: #FFFFFF;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #DEE2E6;
`;

export const Button = styled.div`
  font: normal normal normal 14px/44px Roboto;
  cursor: pointer;
  user-select: none;
  height: 44px;
  padding: 0 35px;
  background-color: #DF6E21;
  color: #FFFFFF;
  border-radius: 5px;
  transition: all 0.2s;
  &:not(:last-child) {
    margin-right: 10px;  
  }

  &:hover {
    background: hsl(24.315789473684212, 74.80314960629921%, 55%);
  }
`;

export const Fab = styled.div`
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: #DF6E21 url('social/create-post-icon.svg') 55% 45% no-repeat padding-box;
  ${({ active }) => (active && `
    background: #DF6E21 url('social/cancel-icon.svg') 50% 50% no-repeat padding-box;
  `)}
  box-shadow: 0px 3px 24px #3333332B;
  cursor: pointer;
  &:hover, &:focus {
    background-color: #cb641e;
  }
  @media only screen and (min-width: 1150px) {
    display: none;
  }
`;
