/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';

import { useInTheCityContext } from 'contexts/inthecity';
import { searchEvents } from 'interface/inthecity/quicket';
import { Loader } from 'components/inthecity';
import { useOnClickOutside } from 'functions/home';
import {
  SearchInput,
  DropdownContainer,
  ResultContainer,
  ResultDescriptionContainer,
  ResultTitle,
  ResultAddress,
  ResultType,
  ResultPlaceSeparator,
  ResultPlaceRow,
  DropdownCategory,
  SuggestedContainer,
  SuggestedText,
  SuggestedItems,
  ResetButton,
} from './search.styled';

const purchaseKeywords = ['purchase', 'vas', 'lotto', 'lottery', 'airtime', 'data'];

const debouncedSearch = debounce(async (query, searchType, callback, places) => {
  callback({
    places: (searchType === 'all' || searchType === 'places')
      ? places?.filter((p) => p.name.toLowerCase().includes(query.toLowerCase()))
      : null,
    events: (searchType === 'all' || searchType === 'events')
      ? await searchEvents(query)
      : null,
    purchase: (searchType === 'all')
      ? query.length > 2 && purchaseKeywords.some((w) => w.includes(query.toLowerCase()))
      : null,
  });
}, 500);

/**
 * Search component for places and events
 */
const Search = (props) => {
  // props
  const {
    placeholder,
    variation = 'light',
    suggestions,
    searchType = 'all',
  } = props;

  // context
  const { places } = useInTheCityContext() || {};

  // state
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [placesData, setPlacesData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [purchaseData, setPurchaseData] = useState(false);

  // control dropdown
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleQueryChange = (e) => setQuery(e.target.value);

  useEffect(() => {
    // show results if user is typing
    if (query.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    // debounce search query
    debouncedSearch(query, searchType, ({ places: p, events, purchase }) => {
      setLoading(true);
      setPlacesData(p);
      setEventsData(events);
      setPurchaseData(purchase);
      setLoading(false);
    }, places);
  }, [query]);

  const handleClickSuggestion = (q) => {
    if (q && q.length > 0) {
      setQuery(q);
    }
  };

  return (
    <>
      <div style={{ position: 'relative' }} ref={ref}>
        <SearchInput
          value={query}
          placeholder={placeholder || 'Search'}
          onChange={handleQueryChange}
          variation={variation}
        />
        <ResetButton show={query.length > 0} onClick={() => setQuery('')} />
        <Dropdown
          places={placesData}
          events={eventsData}
          purchase={purchaseData}
          show={open}
          loading={loading}
        />
      </div>
      {suggestions && (
        <SuggestedSearches
          handleClickSuggestion={handleClickSuggestion}
          suggestions={suggestions}
        />
      )}
    </>
  );
};

/**
 * Show search results in a dropdown
 */
const Dropdown = (props) => {
  const {
    places,
    events,
    purchase,
    show,
    loading,
  } = props;

  console.log('places', places);

  return (
    <DropdownContainer show={show}>
      {places && (
        <>
          <DropdownCategory>
            Places of Interest
          </DropdownCategory>

          {(places.length === 0 && !loading) && <div style={{ marginBottom: '20px', marginLeft: '30px', fontFamily: 'Roboto' }}>No results</div>}
          {loading && <Loader />}

          {places?.slice(0, 5).map((place, index) => (
            <Link
              to="/in-the-city/places-of-interest/place"
              state={{ id: place, place }}
              key={index}
            >
              <ResultContainer>
                <img src={place.photos ? place.photos[0]?.prefix + '200' + place.photos[0]?.suffix : ''} alt="place" />
                <ResultDescriptionContainer>
                  <ResultTitle>{place?.name}</ResultTitle>
                  <ResultPlaceRow>
                    <ResultAddress>{place.location ? place.location.formatted_address : ''}</ResultAddress>
                    <ResultPlaceSeparator>·</ResultPlaceSeparator>
                    <ResultType>{place.categories[0]?.name}</ResultType>
                  </ResultPlaceRow>
                </ResultDescriptionContainer>
              </ResultContainer>
            </Link>
          ))}
        </>
      )}
      {events && (
        <>
          <DropdownCategory>
            What&apos;s on in your City
          </DropdownCategory>

          {(events.length === 0 && !loading) && <div style={{ marginBottom: '20px', marginLeft: '30px', fontFamily: 'Roboto' }}>No results</div>}
          {loading && <Loader />}

          {events.slice(0, 5).map((event) => (
            <Link
              to="/in-the-city/whats-on-in-your-city/event"
              state={{ id: event.id, event }}
              key={event.id}
            >
              <ResultContainer>
                <img src={`https:${event.ImageUrl ? event.ImageUrl : '/logo.svg'}`} alt={event.VenueName} />
                <ResultDescriptionContainer>
                  <ResultTitle>{event.ProductName}</ResultTitle>
                  <ResultPlaceRow>
                    <ResultAddress>{event.AddressFormatted === '' ? 'No location' : event.AddressFormatted}</ResultAddress>
                    <ResultPlaceSeparator>·</ResultPlaceSeparator>
                    <ResultType>{event.Categories[0]}</ResultType>
                  </ResultPlaceRow>
                </ResultDescriptionContainer>
              </ResultContainer>
            </Link>
          ))}
        </>
      )}
      {purchase && (
        <>
          <DropdownCategory>
            Purchase
          </DropdownCategory>

          <Link
            to="/in-the-city/purchase/airtime"
          >
            <ResultContainer>
              <img src="inthecity/tilesNaming/banner_Group_3518.png" alt="" />
              <ResultDescriptionContainer>
                <ResultTitle>Airtime &amp; Data</ResultTitle>
                <ResultPlaceRow>
                  <ResultAddress>Vodacom, Cell C, MTN, Telkom Mobile</ResultAddress>
                </ResultPlaceRow>
              </ResultDescriptionContainer>
            </ResultContainer>
          </Link>

          <Link
            to="/in-the-city/purchase/lotto"
          >
            <ResultContainer>
              <img src="inthecity/tilesNaming/banner_Group_3518.png" alt="" />
              <ResultDescriptionContainer>
                <ResultTitle>Lotto</ResultTitle>
                <ResultPlaceRow>
                  <ResultAddress>Lotto, Lotto Daily, Powerball</ResultAddress>
                </ResultPlaceRow>
              </ResultDescriptionContainer>
            </ResultContainer>
          </Link>
        </>
      )}
    </DropdownContainer>
  );
};

/**
 * Component that lets a user populate the search input with
 * any one of the predefined search suggestions
 */
const SuggestedSearches = (props) => {
  const { handleClickSuggestion, suggestions } = props;

  return (
    <SuggestedContainer>
      <SuggestedText>
        Suggested Searches:
      </SuggestedText>
      <SuggestedItems>
        {suggestions.map((item, i) => (
          <p key={i} onClick={() => handleClickSuggestion(item)}>
            {i === suggestions.length - 1 ? item : `${item},`}
          </p>
        ))}
      </SuggestedItems>
    </SuggestedContainer>
  );
};

export default Search;
