import { css } from '@emotion/react';
import { Button, Image, Modal } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import theme from 'theme';
import { GMAccountLineItem, GMMunicipalAccount } from 'types/types';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { Loader } from 'components/inthecity';
import useQuery from 'hooks/use-query';
import UtilityAccountItem from 'components/my-municipality/utility-account-item';
import { ResponsivePie } from '@nivo/pie';
import { convertGMLineItemToNivoGraphItem, hexToRGBA } from 'functions/graph';
import { useNavigate, useParams } from 'react-router-dom';

const AccountLineItem = ({
  item,
  inactive,
  onClick,
}: {
  item: GMAccountLineItem;
  inactive?: boolean;
  onClick: () => void;
}) => (
  <Button onClick={onClick}>
    <Flex
      borderT="1px solid #EBEBEB"
      p="10px"
      gap="19px"
      width="100%"
      opacity={inactive ? '0.6' : '1'}
    >
      <Image
        source={`https://mysmart.city/api/msc/images/custom/${item.icon}?subscriberId=george`}
        alt={item.label}
        height="35px"
      />
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Text>{item.label}</Text>
        <Text>R {item.amount.toFixed(2)}</Text>
      </Flex>
    </Flex>
  </Button>
);

const ViewAccountPage = () => {
  // Hooks
  const query = useQuery();
  const navigate = useNavigate();
  const { cityId } = useParams();

  // API Calls
  const {
    loading: loadingAccount,
    callApi: getAccount,
    response: accountResponse,
  } = useSmartCityManager(SCManagerEndpoints.GetMunicipalAccounts);

  // State management and constants
  const [customerAccount, setCustomerAccount] = useState<GMMunicipalAccount>();
  const [graphEndAngle, setGraphEndAngle] = useState(-204);
  const [activeSlice, setActiveSlice] = useState(null);
  const premiseId = query.get('premiseId');
  const accountNumber = query.get('accountNumber');
  const graphData =
    customerAccount?.lineItems.map((item) =>
      convertGMLineItemToNivoGraphItem(item)
    ) ?? [];
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

  // Side effects
  useEffect(() => {
    getAccount({
      queryParams: `premiseId=${premiseId}&accountNumber=${accountNumber}`,
    });
  }, []);

  useEffect(() => {
    if (accountResponse) {
      setCustomerAccount(accountResponse);
    }
  }, [accountResponse]);

  useLayoutEffect(() => {
    const animationDuration = 2000;
    const startAngle = -204;
    const endAngle = 360;

    let startTimestamp: number | null = null;
    let requestId: number | null = null;

    const animate = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;

      if (elapsed < animationDuration) {
        const progress = elapsed / animationDuration;
        const newAngle = startAngle + (endAngle - startAngle) * progress;
        setGraphEndAngle(newAngle);

        requestId = requestAnimationFrame(animate);
      } else {
        setGraphEndAngle(endAngle);
      }
    };

    requestId = requestAnimationFrame(animate);

    return () => {
      if (requestId) cancelAnimationFrame(requestId);
    };
  }, []);

  // Handlers
  const handleSliceClick = (slice: any) => {
    setActiveSlice(slice.id === activeSlice ? null : slice.id);
  };

  // Functions
  const getSliceColor = (slice: any) => {
    const sliceColor =
      graphData.find((item) => item.id === slice.id)?.color ?? '';

    if (slice.id !== activeSlice && activeSlice) {
      return hexToRGBA(sliceColor, 0.4) ?? slice.color;
    }
    return hexToRGBA(sliceColor, 1) ?? slice.color;
  };

  const currentItemPercentage = () => {
    const sliceValue = graphData.find((item) => item.id === activeSlice)?.value;
    console.log(sliceValue);
    if (sliceValue === undefined || !customerAccount?.total) return 0;
    return ((sliceValue * 100) / customerAccount?.total).toFixed(0);
  };

  return (
    <MunicipalPageWrapper>
      {loadingAccount || !(premiseId && accountNumber) ? (
        <Loader />
      ) : (
        <>
          <Flex
            maxWidth="650px"
            mx="auto"
            width="100%"
            justifyContent="center"
            flexDirection="column"
          >
            <Text textAlign="center" fontSize="18px" fontWeight={600} mb="12px">
              Your account
            </Text>
            <UtilityAccountItem
              account={{
                premiseId: parseInt(premiseId, 10),
                accountNumber,
                orgUnitId: 0,
                orgUnitDescription: 'Municipal Account',
                active: true,
                meters: [],
                externalId: '0',
                statusId: 2,
                status: 'Verified',
              }}
            />
            <Flex
              alignItems="center"
              p="0px 10px 0px 16px"
              width="100%"
              justifyContent="space-between"
              borderRadius="4px"
              boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
              height="74px"
            >
              <Flex alignItems="center">
                <Image
                  source="my-municipality/info-filled.svg"
                  alt="info"
                  height="34px"
                  mr="11px"
                />
                <Text>Disclaimer</Text>
              </Flex>
              <Button
                color={theme.colors.primary}
                fontWeight={600}
                fontSize="16px"
                mr="14px"
                onClick={() => setShowDisclaimerModal(true)}
              >
                Read
              </Button>
            </Flex>
          </Flex>
          <Modal
            isOpen={showDisclaimerModal}
            onClose={() => setShowDisclaimerModal(false)}
            title="Please note:"
          >
            <Box maxWidth="470px">
              <ol>
                <li>
                  <Text>
                    Payments processed may take up to 10 working days to reflect
                    in your account.
                  </Text>
                </li>
                <br />
                <li>
                  <Text>
                    Your account will be updated between the 25th of the current
                    month and the 4th of the following month.
                  </Text>
                </li>
              </ol>
            </Box>
            <Button
              variant="cta"
              onClick={() => setShowDisclaimerModal(false)}
              width="100%"
              mt="30px"
            >
              Close
            </Button>
          </Modal>

          <Grid
            borderRadius="4px"
            boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
            mt="24px"
            gridTemplateColumns="1fr 1fr"
            mobileCss={css`
              grid-template-columns: 1fr;
              padding: 0px 16px;
            `}
          >
            <Box
              height="385px"
              mobileCss={css`
                height: 300px;
              `}
              position="relative"
            >
              {!!activeSlice && customerAccount?.total && (
                <Text
                  fontSize="24px"
                  fontWeight={600}
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  {currentItemPercentage()} %
                </Text>
              )}
              <ResponsivePie
                data={graphData}
                colors={getSliceColor}
                margin={{ top: 40, right: 10, bottom: 40, left: 10 }}
                startAngle={-180}
                endAngle={graphEndAngle}
                innerRadius={0.65}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                motionConfig="gentle"
                fit={false}
                onClick={handleSliceClick}
              />
            </Box>
            <Flex
              width="100%"
              maxWidth="360px"
              my="auto"
              flexDirection="column"
              flex="1"
              alignContent="center"
            >
              <Flex width="100%" justifyContent="space-between" py="10px">
                <Text>Municipal Account</Text>
                <Text>Amount</Text>
              </Flex>
              {customerAccount?.lineItems.length &&
                [...customerAccount?.lineItems]
                  .sort((item) => (item.label === activeSlice ? -1 : 1))
                  .map((item) => (
                    <AccountLineItem
                      item={item}
                      inactive={!!activeSlice && activeSlice !== item.label}
                      onClick={() => handleSliceClick({ id: item.label })}
                    />
                  ))}
            </Flex>
          </Grid>
          <Flex
            mt="24px"
            alignItems="center"
            p="24px 16px"
            width="100%"
            justifyContent="space-between"
            borderRadius="4px"
            boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
          >
            <Button
              variant="cta"
              width="50%"
              desktopCss={css`
                width: 320px;
              `}
              onClick={() =>
                navigate(
                  `/my-municipality/municipal-accounts/${cityId}/pay-account?accountNumber=${accountNumber}&premiseId=${premiseId}`
                )
              }
            >
              Pay Account
            </Button>
            <Text>Total R {customerAccount?.total.toFixed(2)}</Text>
          </Flex>
        </>
      )}
    </MunicipalPageWrapper>
  );
};

export default ViewAccountPage;
