import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(246, 246, 249, 0.74) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 -20px;
  @media only screen and (min-width: 800px) {
    padding: 25px;
    margin: 0;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  border: 2px solid #B2BCC100;
  height: 50px;
  transition: 0.2s all ease-in-out;
  overflow-y: hidden;
  background: #FFFFFF;
  ${({ expand }) => (expand ? `
    box-shadow: 0 0 0 2px #DF6E213E;
    border: 1px solid #DF6E21;
    height: 130px;
    padding-bottom: 50px;
  ` : `
    &:hover {
      border: 2px solid #DF6E2143;
    } 
  `)}
`;

export const Input = styled.textarea`
  width: 100%;
  padding: 15px 25px 10px 25px;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #B2BCC1;
  background: #FFFFFF;
  height: 100%;
  resize: none;
  border: none;
  outline: none;
  transition: 0.2s all ease-in-out;
  overflow-y: auto;
  &::placeholder { opacity: 0.56; }
  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/21px Roboto;
  }
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  @media only screen and (min-width: 800px) {
    margin-bottom: 35px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(222, 226, 230, 0.46);
  @media only screen and (min-width: 800px) {
    border-bottom: 2px solid rgba(222, 226, 230, 0.46);
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 5px;
  @media only screen and (min-width: 800px) {
    margin-bottom: 9px;
  }
`;

export const Name = styled.p`
  font: normal normal 500 10px/12px Roboto;
  padding: 0;
  margin: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/14px Roboto;
  }
`;

export const Time = styled.p`
  padding: 0;
  margin: 0;
  position: absolute;
  top: -5px;
  right: 0;
  font: normal normal normal 10px/12px Roboto;
  color: #B2BCC1;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/14px Roboto;
  }
`;

export const Category = styled.p`
  padding: 0;
  margin: 0;
  font: normal normal normal 10px/12px Roboto;
  color: #B2BCC1;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/14px Roboto;
  }
`;

export const Content = styled.p`
  padding: 0;
  margin: 0;
  font: normal normal normal 10px/12px Roboto;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/14px Roboto;
  }
`;

export const ProfileImage = styled.img`
  margin-right: 16px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  @media only screen and (min-width: 800px) {
    margin-right: 30px;
  }
`;

export const Submit = styled.div`
  user-select: none;
  width: fit-content;
  height: 35px;
  position: absolute;
  right: 25px;
  bottom: 35px;
  padding: 0 20px;
  background: #DF6E21 0% 0% no-repeat padding-box;
  border-radius: 2px;
  font: normal normal normal 12px/35px Roboto;
  color: #FFFFFF;
  cursor: pointer;
`;

export const Loadmore = styled.div`
  cursor: pointer;
  font: normal normal 500 10px/12px Roboto;
  margin-top: -5px;
  margin-bottom: 13px;
  color: #DF6E21;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/21px Roboto;
    margin-top: -5px;
    margin-bottom: 13px;
  }
`;

export const Readmore = styled.div`
  cursor: pointer;
  font: normal normal 500 10px/12px Roboto;
  color: #DF6E21;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/21px Roboto;
    margin-top: -5px;
    margin-bottom: 13px;
  }
`;
