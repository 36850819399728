/** @jsxImportSource @emotion/react */
import { MunicipalServiceListing } from 'constants/msc-pro-municipalities';
import { Button, Image } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { css } from '@emotion/react';
import theme from 'theme';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalContext } from 'contexts/global';

const SERVICE_LISTING_TILE_WIDTH = 160;

const NavigationDots = ({
  length,
  activeIndex,
}: {
  length: number;
  activeIndex: number;
}) => (
  <Flex
    hideForDesktop
    hideForTablet
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="20px"
    mt="20px"
  >
    {Array.from({ length }).map((_, index) => (
      <Flex
        key={index}
        width={index === activeIndex ? '12px' : '8px'}
        height={index === activeIndex ? '12px' : '8px'}
        borderRadius="50%"
        backgroundColor={
          index === activeIndex ? theme.colors.primary : theme.colors.lightGrey
        }
        mx="5px"
        overrideCss={css`
          transition: all 0.2s ease-in-out;
        `}
      />
    ))}
  </Flex>
);

const ServiceListingInner = ({
  service,
}: {
  service: MunicipalServiceListing;
}) => (
  <Flex
    borderB={`1px solid ${theme.colors.primary}`}
    color="black"
    hoverCss={css`
      color: ${theme.colors.primary};
    `}
    justifyContent="center"
    alignItems="center"
    height="78px"
    minWidth="200px"
    mobileCss={css`
      flex-direction: column;
      height: 150px;
      min-width: ${SERVICE_LISTING_TILE_WIDTH}px;
      scroll-snap-align: start;
    `}
    boxShadow="0px 3px 10px rgba(0, 0, 0, 0.05);"
    p="18px 20px"
  >
    <Image
      source={`my-municipality/${service.icon}.svg`}
      alt="Icon"
      height="32px"
      mr="20px"
      mobileCss={css`
        margin-right: 0;
        margin-bottom: 10%;
        height: 35px;
        width: auto;
      `}
    />
    <Text fontSize="16px" textAlign="center">
      {service.label}
    </Text>
  </Flex>
);

const ServiceListing = ({
  service,
  cityId,
  customLink,
  requiresAuth = false,
}: {
  service: MunicipalServiceListing;
  cityId: string;
  customLink?: string;
  requiresAuth: boolean;
}) => {
  const { globalState, setShowLoginModal } = useGlobalContext();
  if (globalState.user.uuid === 'guest' && requiresAuth) {
    return (
      <Button onClick={() => setShowLoginModal(true)}>
        <ServiceListingInner service={service} />
      </Button>
    );
  }
  return (
    <Link
      to={
        customLink ||
        `/my-municipality${service.link}/${cityId}${
          service.query ? '?query='.concat(service.query) : ''
        }`
      }
    >
      <ServiceListingInner service={service} />
    </Link>
  );
};

const ServiceListingRow = ({
  services,
  category,
  cityId,
}: {
  services: MunicipalServiceListing[];
  category: string;
  cityId: string;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const calculateActiveIndex = useCallback(() => {
    const scrollLeft = containerRef?.current?.scrollLeft || 0;
    const ai = Math.round(scrollLeft / SERVICE_LISTING_TILE_WIDTH);
    setActiveIndex(ai);
  }, [containerRef]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', () =>
        calculateActiveIndex()
      );
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', () =>
          calculateActiveIndex()
        );
      }
    };
  }, [activeIndex, containerRef]);

  return (
    <Box borderB="1px solid #e0e0e0" pb="32px" mb="32px" overflow="hidden">
      <Text mb="16px" fontSize="16px" fontWeight={600}>
        {category}
      </Text>
      <Grid
        ref={containerRef}
        gridColumnGap="10px"
        gridRowGap="10px"
        gridTemplateColumns="repeat(auto-fit, minmax(100px, 200px))"
        hideScrollBar
        mobileCss={css`
          width: 100%;
          display: flex;
          overflow-x: scroll;
          scroll-snap-type: x mandatory;
        `}
      >
        {services.map((s) => (
          <ServiceListing
            requiresAuth={!!s.requiresAuth}
            key={s.label}
            service={s}
            cityId={cityId}
            customLink={
              s.label.toLowerCase() === 'parking'
                ? '/map/george/parking'
                : undefined
            }
          />
        ))}
        {services.length > 2 && (
          <Box
            hideForDesktop
            hideForTablet
            minWidth={`${SERVICE_LISTING_TILE_WIDTH}px`}
            overrideCss={css`
              scroll-snap-align: start;
            `}
          />
        )}
      </Grid>
      {services.length > 2 && (
        <NavigationDots length={services.length} activeIndex={activeIndex} />
      )}
    </Box>
  );
};

export default ServiceListingRow;
