/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { Loader } from 'components/inthecity';
import {
  getImageURL,
  lookUpKinektekProduct,
  initializePurchaseOrder,
} from 'interface/inthecity/inthecity';
import { Modal } from 'components/social';
import {
  Heading,
  // BoardButton,
  ScrollContainer,
  BottomContainer,
  Line,
  Button,
  BoardPreview,
  ButtonLabel,
  ButtonBall,
  ProductItem,
  ProductContent,
  ProductTotal,
  PeachDisclaimer,
} from './lottoModal.styled';

const getBoardChar = (i) => String.fromCharCode(i + 65);

const prefix = process.env.REACT_APP_API_BASE.split('.')[0];
const peachTestEnv = prefix.includes('dev');

/**
 * Lotto Payment Modal
 */
const PaymentModal = (props) => {
  // props
  const {
    boards,
    show,
    handleBack,
    handleClose,
    product, // get product id from this object
    mobileNumber,
    quickPickBoards,
    category,
    buy,
  } = props;

  console.log('lotto:payment:props', props);

  // state
  const [loading, setLoading] = useState(false);
  const [checkout, setCheckout] = useState();
  const [lookup, setLookup] = useState();

  const numberOfBoards = product?.name.includes('Selfpick') ? boards.length : quickPickBoards;

  // timeout counter
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          console.log('countdown reached');
          handleBack();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  // Step 1: Get SessionID and then get new checkout ID from Peach
  useEffect(() => {
    async function initializePayment() {
      setLoading(true);

      // dual step lookup to get session ID

      const { productID } = product;

      const reference = product?.name.includes('Selfpick')
        ? JSON.stringify(boards).slice(1, -1)
        : `${quickPickBoards}`;

      const lookupResult = await lookUpKinektekProduct(productID, reference);
      setLookup(lookupResult);

      const { amountDue } = lookupResult;
      const priceInRands = (amountDue * numberOfBoards) / 100;

      const checkoutResult = await initializePurchaseOrder(priceInRands, productID);
      setCheckout(checkoutResult);

      setLoading(false);
    }
    if (product && !checkout && !lookup) initializePayment();
  }, []);

  // Step 2: Get Peach Payment widget
  useEffect(() => {
    window.wpwlOptions = { style: 'plain' }; // copy&pay style
    const script = document.createElement('script'); // copy&pay script
    script.src = checkout ? `https://${peachTestEnv ? 'test.' : ''}oppwa.com/v1/paymentWidgets.js?checkoutId=${checkout}` : '';
    script.async = true;
    document.body.appendChild(script);

    // cleanup function
    return () => {
      console.log('cleaned up');
      document.body.removeChild(script);
      delete window.wpwlOptions;
    };
  }, [checkout]);

  const getMaxBoardNumbers = () => {
    if (product?.name === 'Daily Lotto Selfpick') return 5;
    return 6;
  };

  const redirectStateString = encodeURI(JSON.stringify({
    boards: product?.name.includes('Selfpick') ? boards : null,
    quickPickBoards: product?.name.includes('Quickpick') ? quickPickBoards : null,
    product,
    checkout,
    lookup,
    mobileNumber,
  }));

  console.log('lotto:payment:redirectState', {
    boards: product?.name.includes('Selfpick') ? boards : null,
    quickPickBoards: product?.name.includes('Quickpick') ? quickPickBoards : null,
    product,
    checkout,
    lookup,
    mobileNumber,
  });

  console.log('lotto:payment:redirectState', redirectStateString);

  const productBoardsString = () => {
    // (category === 'Lotto Selfpick')
    if (product?.name.includes('Selfpick')) {
      return `${boards.length} ${boards.length > 1 ? 'boards' : 'board'}`;
    }
    return `${quickPickBoards} ${quickPickBoards > 0 ? 'boards' : 'board'}`;
  };

  return (
    <Modal
      title="Payment"
      show={show}
      closeButton={buy}
      handleClose={handleClose}
      backButton={!buy}
      handleBack={handleBack}
    >
      <ScrollContainer style={{ fontFamily: 'Roboto' }}>
        {(product && lookup) && (
          <>
            <ProductItem>
              <img src={getImageURL(product?.imageName)} alt={product?.name} />
              <ProductContent>
                <p>{`${lookup?.description} Ticket${numberOfBoards > 1 ? 's' : ''}`}</p>
                <p>{mobileNumber}</p>
                <p>{`${moment().format('DD MMM YYYY')} · ${productBoardsString()}`}</p>
              </ProductContent>
              <ProductTotal>{lookup ? `R ${Number((lookup?.amountDue * numberOfBoards) / 100).toFixed(2)}` : 'Getting price'}</ProductTotal>
            </ProductItem>

            {/* category === 'Lotto Selfpick') */}
            {product?.name.includes('Selfpick') && (
              boards.map((b, i) => (
                <BoardPreview>
                  <ButtonLabel>{`Board ${getBoardChar(i)}`}</ButtonLabel>
                  {[...Array(getMaxBoardNumbers())].map((_, j) => (
                    <ButtonBall
                      number={j}
                      empty={j >= b.length}
                    >
                      {b[j]}
                    </ButtonBall>
                  ))}
                </BoardPreview>
              ))
            )}
          </>
        )}

        {/* Peach Payments Form */}
        {(product && checkout) && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Heading>Card Information</Heading>
              <Heading>{`Timeout ${minutes}:${seconds}`}</Heading>
            </div>
            <form
              action={`https://${process.env.REACT_APP_API_BASE}/in-the-city/purchase/lotto/${redirectStateString}/`}
              className="paymentWidgets"
              data-brands="VISA MASTER"
            />

            <PeachDisclaimer>
              <p>
                Peach Payments is PCI DSS compliant.
                This security standard is recognised by Visa, Mastercard,
                etc as an industry standard for security.
              </p>
              <img src="inthecity/purchase/peach-logo.svg" alt="PeachPayments Logo" />
            </PeachDisclaimer>
          </>
        )}

        {loading && <Loader />}
      </ScrollContainer>
    </Modal>
  );
};

export default PaymentModal;
