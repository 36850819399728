/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useInTheCityContext } from 'contexts/inthecity';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import { TopRow } from 'pages/inthecity/social.styled';
import { getKinektekCatalogueByCategory } from 'interface/inthecity/inthecity';
import {
  Breadcrumbs,
  PurchaseTabs,
  TransactionHistoryList,
  OrderDetailsModal,
  AirtimeDataModal,
  LottoModal,
  Loader,
} from 'components/inthecity';
import { PageContainer, PageLine } from './inthecity.styled';
import {
  Title,
  Banner,
  TabsContainer,
  PurchaseGrid,
  PurchaseTile,
  TileContainer,
} from './purchase.styled';
import { useGlobalContext } from 'contexts/global';

const tabs = [
  {
    id: 0,
    label: 'Prepaid Airtime & Data',
    link: '/in-the-city/purchase/airtime',
  },
  { id: 1, label: 'Lotto', link: '/in-the-city/purchase/lotto' },
];

const PurchasePage = () => {
  // hooks
  const params = useParams();
  const { type, redirectState } = params;
  const location = useLocation();
  const id = location.state?.tab?.id || 0;
  const [searchParams] = useSearchParams();
  const checkoutId = searchParams.get('id');
  const {
    globalState: { user },
  } = useGlobalContext();

  console.log('peach:id', checkoutId);
  console.log('peach:params', params);
  console.log('purchase:redirectState', redirectState);

  //  state
  const [loading, setLoading] = useState(false);
  const [showModalAirtime, setShowModalAirtime] = useState(false);
  const [showModalLotto, setShowModalLotto] = useState(false);
  const [showModalOrderDetails, setShowModalOrderDetails] = useState(false);
  const [sessionId, setSessionId] = useState();
  const [orderDetails, setOrderDetails] = useState();
  const [buy, setBuy] = useState();
  const [subcategory, setSubcategory] = useState();
  const [provider, setProvider] = useState();
  // const [catalogue, setCatalogue] = useState();
  const [airtimeProducts, setAirtimeProducts] = useState();
  const [lottoProducts, setLottoProducts] = useState();

  if (user.uuid === 'guest') {
    return null;
  }

  const resetState = () => {
    setBuy(null);
    setOrderDetails(null);
    setSessionId(null);
    setSubcategory(null);
    setProvider(null);
    setShowModalOrderDetails(false);
  };

  const handleProviderSelect = (prov) => {
    setProvider(prov);
    setShowModalAirtime(true);
  };

  const handleSubcategorySelect = (subCat) => {
    setSubcategory(subCat);
    setShowModalLotto(true);
  };

  // Go to the purchase stage with the product preselected
  const handleBuyAgain = (transaction) => {
    // find product object from id
    setBuy({
      product: {
        productID: transaction?.productId,
        name: transaction?.customerDetail.name,
        price: transaction?.amount * 100,
        imageName: transaction?.customerDetail?.imageName,
        dualStep: transaction?.dualStep,
        issuer: transaction?.customerDetail?.issuer,
      },
      ownAmount: transaction?.amount,
      mobileNumber: transaction?.customerDetail?.mobileNumber,
      boards: transaction?.customerDetail?.boards,
    });

    // airtime or lotto
    const isLotto = transaction?.customerDetail?.issuer === 'iThuba';
    if (isLotto) setShowModalLotto(true);
    else setShowModalAirtime(true);
  };

  // View Order Details
  const handleShowOrderDetails = (orderDetailsObj = null, sId = null) => {
    if (orderDetailsObj) setOrderDetails(orderDetailsObj);
    if (sId) setSessionId(sId);
    if (orderDetailsObj || sId) {
      setShowModalAirtime(false);
      setShowModalLotto(false);
      setShowModalOrderDetails(true);
    }
  };

  useEffect(() => {
    async function fetchData() {
      //
      // Get products (AIRTIME_AND_DATA, LOTTO_AND_BETTING)
      //
      setLoading(true);
      const airtimeResponse = await getKinektekCatalogueByCategory(
        'AIRTIME_AND_DATA'
      );
      const lottoResponse = await getKinektekCatalogueByCategory(
        'LOTTO_AND_BETTING'
      );
      airtimeResponse && setAirtimeProducts(airtimeResponse.subCatalogues);
      lottoResponse &&
        setLottoProducts(
          lottoResponse.subCatalogues.find((y) => y.name === 'Lotto')
            .subCatalogues
        );
      setLoading(false);
    }
    fetchData();
  }, []);

  //
  // Handle PeachPayments redirect after a payment is made
  //
  useEffect(() => {
    if (redirectState && type === 'airtime') setShowModalAirtime(true);
    if (redirectState && type === 'lotto') setShowModalLotto(true);
  }, [redirectState]);

  return (
    <>
      {/* Modals */}
      <OrderDetailsModal
        show={showModalOrderDetails}
        orderDetails={orderDetails}
        sessionId={sessionId}
        handleClose={resetState}
      />
      <AirtimeDataModal
        redirectState={
          redirectState && type === 'airtime'
            ? JSON.parse(decodeURI(redirectState))
            : null
        }
        buy={buy?.product?.issuer !== 'iThuba' ? buy : null}
        provider={provider}
        products={airtimeProducts}
        show={showModalAirtime}
        handleClose={() => {
          resetState();
          setShowModalAirtime(false);
        }}
        handleShowOrderDetails={handleShowOrderDetails}
      />
      <LottoModal
        redirectState={
          redirectState && type === 'lotto'
            ? JSON.parse(decodeURI(redirectState))
            : null
        }
        buy={buy?.product?.issuer === 'iThuba' ? buy : null}
        subcategory={subcategory}
        products={lottoProducts}
        show={showModalLotto}
        handleClose={() => {
          resetState();
          setShowModalLotto(false);
        }}
        handleShowOrderDetails={handleShowOrderDetails}
      />

      {/* Page */}
      <PageContainer>
        <TopRow>
          <Breadcrumbs />
        </TopRow>

        <Title>Purchase</Title>

        <TabsContainer>
          <PurchaseTabs links={tabs} />
        </TabsContainer>

        <PageLine />

        {loading && <Loader />}

        {Number(id) === 0 && airtimeProducts && (
          <TileContainer>
            <PurchaseGrid>
              <PurchaseTile
                src="inthecity/purchase/purchaseTile/tile_Vodacom.png"
                onClick={() => handleProviderSelect('Vodacom')}
              />
              <PurchaseTile
                src="inthecity/purchase/purchaseTile/tile_MTN.png"
                onClick={() => handleProviderSelect('MTN')}
              />
              <PurchaseTile
                src="inthecity/purchase/purchaseTile/tile_CellC.png"
                onClick={() => handleProviderSelect('Cell C')}
              />
              <PurchaseTile
                src="inthecity/purchase/purchaseTile/tile_Telkom.png"
                onClick={() => handleProviderSelect('Telkom Mobile')}
              />
            </PurchaseGrid>
          </TileContainer>
        )}

        {Number(id) === 1 && lottoProducts && (
          <TileContainer>
            <PurchaseGrid>
              <PurchaseTile
                src="inthecity/purchase/lotto/lotto.png"
                onClick={() => handleSubcategorySelect('Lotto')}
              />
              <PurchaseTile
                src="inthecity/purchase/lotto/daily.png"
                onClick={() => handleSubcategorySelect('Daily Lotto')}
              />
              <PurchaseTile
                src="inthecity/purchase/lotto/powerball.png"
                onClick={() => handleSubcategorySelect('Powerball')}
              />
            </PurchaseGrid>
          </TileContainer>
        )}

        <TransactionHistoryList
          id={Number(id)}
          handleBuyAgain={handleBuyAgain}
          handleShowOrderDetails={handleShowOrderDetails}
          refresh={checkoutId} // refresh after checkoutId change
        />
      </PageContainer>
    </>
  );
};
export default PurchasePage;
