import React from 'react';
import { Context } from 'functions/context';
import 'styles/mapModal.scss';
import lottie from 'lottie-web';
import {
  getMyMapPetitions,
  upVoteWorkRequest,
} from 'interface/map';

import {
  Button,
} from 'react-bootstrap';

export default class SignPetitionModal extends React.Component {
    static contextType = Context;

    constructor(props) {
      super(props);
      this.state = {
        loadingSignPetitionModal: false,
        loadingSignPetitionModalA: false,
      };
    }

    handleSubmit = async () => {
      console.log('SignPetitionModal: handleSubmit()');
      const {
        showSignPetitionWR,
        setState,
        getWorkRequests,
      } = this.props;

      // Set loading to true
      this.setState({
        loadingSignPetitionModal: true,
        loadingSignPetitionModalA: true,
      });

      await upVoteWorkRequest(showSignPetitionWR.id);
      await getWorkRequests(
        null,
        true,
        null,
        true,
      );
      const myWorkRequests = await getMyMapPetitions(1, 200);
      this.setState({
        loadingSignPetitionModal: false,
      });
      setState({
        myWorkRequests,
        showSignPetition: false,
        showMarkerView: true,
      });
    };

    componentDidMount = async () => {
      // Loading
      const animLogin1 = lottie.loadAnimation({
        name: 'loadingSignPetitionModal',
        container: this.animateLoading1,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'mscLogoGrey.json',
      });

      animLogin1.onLoopComplete = () => {
        const { loadingSignPetitionModal, loadingSignPetitionModalA } = this.state;
        console.log('onLoopComplete', loadingSignPetitionModal, loadingSignPetitionModalA);
        if (!loadingSignPetitionModal) {
          lottie.stop('loadingloadingSignPetitionModal');
          this.setState({ loadingSignPetitionModalA: false });
          console.log('onLoopComplete: loadingloadingSignPetitionModalSTOPPED');
        }
      };
    }

    componentWillUnmount = () => {
      lottie.destroy('loadingSignPetitionModal');
    };

    render() {
      const {
        loadingSignPetitionModal,
        loadingSignPetitionModalA,
      } = this.state;
      const { setState } = this.props;

      if (loadingSignPetitionModal) {
        lottie.play('loadingSignPetitionModal');
      }
      return (
        <div className="mapModal">
          <div id="signPetitionModal">
            <div
              id="LoadingLoginOverlayContainer"
              style={
              loadingSignPetitionModalA
                ? {
                  display: 'block',
                }
                : {
                  display: 'none',
                }
              }
            >
              <div
                id="LoadingLoginOverlay"
                className={loadingSignPetitionModal
                  ? 'fadeIn'
                  : 'fadeOut'}
              />
              <div
                ref={(ref) => { this.animateLoading1 = ref; }}
                id="LoadingLoginOverlayImage"
                style={
                loadingSignPetitionModalA
                  ? {
                    display: 'block',
                  }
                  : {
                    display: 'none',
                  }
              }
              />
            </div>
            <div>
              <div className="title textAccentBold">
                Sign
              </div>
              <div className="text">
                You are about to sign this petition,
                {' '}
                <div className="textAccentOrange">
                  thereby agreeing with the author&apos;s thoughts and concerns on the matter.
                </div>
                {' '}
                The petition will then be treated as a
                {' '}
                <div className="textAccentOrange">
                  public document
                </div>
                {' '}
                with which
                {' '}
                <div className="textAccentOrange">
                  your account/signature will be associated to.
                </div>
                <div className="textAccentBold m-4">
                  Do you wish to sign this petition?
                </div>
              </div>
              <div style={{ textAlign: 'left' }}>
                <Button
                  className="modalButton"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  YES
                </Button>
                <Button
                  className="modalButton float-right"
                  onClick={() => {
                    setState({
                      showSignPetition: false,
                      showMarkerView: true,
                    });
                  }}
                >
                  NO
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
