import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import 'styles/button.scss';

export default function Button(props) {
  const {
    label,
    to = '/',
    href = '',
    onClick,
    formSubmit = false,
    arrow = false,
    dark = false,
    outlined = false,
    fullWidth = false,
    thin = false,
    smoothTransition = false,
  } = props;

  if (href) {
    return (
      <a
        className={`home-button ${arrow ? 'arrow' : ''} ${dark ? 'dark' : ''} ${outlined ? 'outlined' : ''} ${fullWidth ? 'fullWidth' : ''} ${thin ? 'thin' : ''}`}
        href={href}
        // target & rel props to open in new tab
        target="_blank"
        rel="noreferrer noopener"
      >
        {label}
        {arrow
          ? (
            <img
              src="home/arrow.svg"
              alt=">"
              className="home-button-arrow"
            />
          )
          : null}
      </a>
    );
  }

  if (onClick) {
    return (
      <div
        className={`home-button ${arrow ? 'arrow' : ''} ${dark ? 'dark' : ''} ${outlined ? 'outlined' : ''} ${fullWidth ? 'fullWidth' : ''} ${thin ? 'thin' : ''}`}
        onClick={onClick}
        role="button"
        tabIndex="0"
      >
        {label}
        {arrow
          ? (
            <img
              src="home/arrow.svg"
              alt=">"
              className="home-button-arrow"
            />
          )
          : null}
      </div>
    );
  }

  if (formSubmit) {
    return (
      <button
        className={`home-button ${arrow ? 'arrow' : ''} ${dark ? 'dark' : ''} ${outlined ? 'outlined' : ''} ${fullWidth ? 'fullWidth' : ''} ${thin ? 'thin' : ''}`}
        type="submit"
      >
        {label}
        {arrow
          ? (
            <img
              src="home/arrow.svg"
              alt=">"
              className="home-button-arrow"
            />
          )
          : null}
      </button>
    );
  }

  return (
    <Link
      className={`home-button ${arrow ? 'arrow' : ''} ${dark ? 'dark' : ''} ${outlined ? 'outlined' : ''} ${fullWidth ? 'fullWidth' : ''} ${thin ? 'thin' : ''}`}
      to={to}
      smooth={smoothTransition}
    >
      {label}
      {arrow
        ? (
          <img
            src="home/arrow.svg"
            alt=">"
            className="home-button-arrow"
          />
        )
        : null}
    </Link>
  );
}
