import 'styles/map.scss';
import 'styles/mapMarker.scss';

import React from 'react';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import hasher from 'node-object-hash';
import {
  MapContainer,
  TileLayer,
  MapConsumer,
  Polygon,
  Popup,
  Marker,
  Polyline,
} from 'react-leaflet';

import CustomerPremiseMarker from 'components/map/mapMarkerCustomerPremise';
import SearchMarker from 'components/map/mapMarkerSearch';
import MapMarker from 'components/map/mapMarker';
import MapMarkerCluster from 'components/map/mapMarkerCluster';
import theme from 'theme';
import { Text } from 'components/typography';

const busRouteColors = ['#FF00FF', theme.colors.blue, '#00ff11', '#FFD600'];

export default class MapContainerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchMarker: null,
    };
  }

  componentDidMount = async () => {
    // eslint-disable-next-line
    this.props.thisOfMapContainerContainer(this);
  };

  render() {
    const {
      viewportLocation,
      authenticatedState,
      logAReportHere,
      logAPetitionHere,
      getWorkRequests,
      customerPremises,
      myLocation,
      serviceType,
      city,
      handleChangeCity,
      theme,
      cityBounds,
      toggleWards,
      wards,
      workRequests,
      polyLines,
      currentCity,
      renderMap,
      map,
      myWorkRequests,
      thisOfOverlay,
      parkingSites,
    } = this.props;
    const { searchMarker } = this.state;
    console.log(cityBounds && cityBounds);

    const polyLineData =
      polyLines &&
      polyLines
        .reduce(
          (acc, item, idx) => [
            ...acc,

            item.features &&
              item.features.map((f) => ({
                color: busRouteColors[idx],
                feature: f,
              })),
          ],
          []
        )
        .flat();

    const hashSortCoerce = hasher({ sort: true, coerce: true, alg: 'sha1' });
    console.warn(
      '====================================Container.jsx rendered===================================='
    );
    return (
      <MapContainer
        style={{
          height: 'calc(100vh - 60px)',
          zIndex: '1',
          width: '100vw',
          position: 'absolute',
          top: '0px',
        }}
        key={`AUTH-${authenticatedState}`}
        center={viewportLocation}
        zoom={11}
        minZoom={9}
        attributionControl={false}
        closePopupOnClick={false}
        contextmenu
        contextmenuItems={
          authenticatedState === true
            ? [
                {
                  text: 'Log a report here',
                  icon: 'map/rightclickmenu/orange/report.svg',
                  callback: logAReportHere,
                },
                {
                  text: 'Log a petition here',
                  icon: 'map/rightclickmenu/orange/petition.svg',
                  callback: logAPetitionHere,
                },
                '-',
                {
                  text: 'Refresh map',
                  icon: 'map/rightclickmenu/orange/refresh.svg',
                  callback: () => getWorkRequests(false, true),
                },
              ]
            : [
                {
                  text: 'Refresh map',
                  icon: 'map/rightclickmenu/orange/refresh.svg',
                  callback: () => getWorkRequests(false, true),
                },
              ]
        }
        zoomControl={false}
        doubleClickZoom
        scrollWheelZoom
        dragging
        animate
        easeLinearity={0.4}
        id="mapid"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="Copyright 2021 | MySmart.City"
        />

        {authenticatedState === true
          ? customerPremises.map((fav, i) => (
              <CustomerPremiseMarker
                premise={fav}
                theme={theme}
                key={`customerPremiseMarker-${i}`}
              />
            ))
          : null}

        {/* Current location */}
        <Marker
          position={myLocation}
          icon={L.divIcon({
            className: 'custom icon',
            iconAnchor: [40, 40],
            html: ReactDOMServer.renderToString(
              <div
                style={{
                  padding: '20px',
                  borderRadius: '50%',
                  height: '80px',
                  width: '80px',
                  backgroundColor: 'rgba(32, 150, 186, 0.5)',
                }}
              >
                <div
                  style={{
                    borderRadius: '50%',
                    height: '40px',
                    width: '40px',
                    backgroundColor: '#2096ba',
                  }}
                />
              </div>
            ),
          })}
        />

        {searchMarker ? (
          <SearchMarker
            serviceType={serviceType}
            searchMarker={searchMarker}
            city={city}
            handleChangeCity={(s) => handleChangeCity(s)}
            thisOfOverlay={thisOfOverlay}
          />
        ) : null}

        {cityBounds.map((bound) => (
          <Polygon
            positions={bound}
            fillColor="#df6e21"
            fillOpacity="0.1"
            weight="0.5"
            color="#693616"
            interactive={false}
            key={hashSortCoerce.hash(bound)}
          />
        ))}

        {polyLineData &&
          polyLineData.map((line) => (
            <Polyline
              color={line.color}
              positions={line.feature.geometry.coordinates.map((c) => [
                c[1],
                c[0],
              ])}
            />
          ))}

        {Array.isArray(parkingSites) &&
          parkingSites.map((site) => (
            <Polygon
              positions={site.geometry.coordinates[0].map((c) => [c[1], c[0]])}
              key={site.id}
              fillOpacity="0.8"
              fillColor={
                site.properties.Type.toLowerCase() === 'private'
                  ? '#df6e21'
                  : '#00ff11'
              }
              color={
                site.properties.Type.toLowerCase() === 'private'
                  ? '#df6e21'
                  : '#00ff11'
              }
              weight="0.5"
            >
              <Popup minWidth={200} closeOnClick closeButton={true}>
                <Text textAlign="center" fontWeight="bold">
                  {site.properties.Type} parking
                </Text>
              </Popup>
            </Polygon>
          ))}

        {toggleWards &&
          Array.isArray(wards) &&
          wards.map((ward) =>
            ward.attributes.geometry.map((geometry) => (
              <Polygon
                positions={geometry}
                fillColor="#df6e21"
                fillOpacity="0.2"
                weight="0.5"
                color="#693616"
                key={hashSortCoerce.hash(geometry)}
              >
                <Popup minWidth={300} closeOnClick closeButton={false}>
                  <div
                    style={{
                      width: '300px',
                      padding: '15px',
                      overflowX: 'auto',
                      marginRight: '-16px',
                      marginTop: '0px',
                    }}
                  >
                    <img
                      src="map/mapMarker/wardPin.svg"
                      alt="Report button"
                      style={{
                        position: 'absolute',
                        top: '-30px',
                        left: '-30px',
                        height: '5.5rem',
                        borderRadius: '50%',
                        boxShadow: 'rgb(100, 100, 100) 0px 0px 10px 1px',
                      }}
                    />

                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        fontFamily: 'GothamMedium',
                      }}
                    >
                      Ward: {ward.label}
                    </div>

                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        fontFamily: 'GothamMedium',
                      }}
                    >
                      {`${ward.attributes.councillorName} ${ward.attributes.councillorSurname}`}
                    </div>
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '14px',
                        padding: '10px 0px',
                        wordBreak: 'break-word',
                      }}
                    >
                      <img
                        src="map/emailWard.svg"
                        alt="Report button"
                        style={{
                          margin: '-8px 8px 0px 0px',
                          height: '16px',
                        }}
                      />
                      {ward.attributes.councillorEmail}
                    </div>
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '14px',
                      }}
                    >
                      <img
                        src="map/phoneWard.svg"
                        alt="Report button"
                        style={{
                          margin: '-5px 8px 0px 0px',
                          height: '16px',
                        }}
                      />
                      {ward.attributes.councillorPhone}
                    </div>
                  </div>
                </Popup>
              </Polygon>
            ))
          )}
        {/* Reports */}

        {workRequests.clusters.map((workRequest) => (
          <MapMarkerCluster
            workRequestT={workRequest}
            theme={theme}
            currentCityId={currentCity.value}
            authenticatedState={authenticatedState}
            thisOfOverlay={thisOfOverlay}
            map={map}
            serviceType={serviceType}
            key={workRequest.id}
          />
        ))}

        {workRequests.mapWorkRequests.map((workRequest) => (
          <MapMarker
            workRequestT={workRequest}
            theme={theme}
            currentCityId={currentCity.value}
            authenticatedState={authenticatedState}
            thisOfOverlay={thisOfOverlay}
            map={map}
            serviceType={serviceType}
            key={workRequest.id}
          />
        ))}

        {myWorkRequests.map((workRequest) => (
          <MapMarker
            workRequestT={workRequest}
            theme={theme}
            currentCityId={currentCity.value}
            authenticatedState={authenticatedState}
            thisOfOverlay={thisOfOverlay}
            map={map}
            serviceType={serviceType}
            key={workRequest.id}
          />
        ))}
        <MapConsumer>{renderMap}</MapConsumer>
      </MapContainer>
    );
  }
}
