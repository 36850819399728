import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Flex } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { useEffect } from 'react';
import { CustomerPremises, UtilityAccount } from 'types/types';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { Loader } from 'components/inthecity';
import UtilityAccountItem from 'components/my-municipality/utility-account-item';
import ExternalLink from 'components/elements/external-link';
import { useNavigate, useParams } from 'react-router-dom';
import useQuery from 'hooks/use-query';

const MunicipalAccountPage = () => {
  // Hooks
  const navigate = useNavigate();
  const { cityId } = useParams();
  const query = useQuery();
  const intent = query.get('query');
  console.log('Intent: ', intent === 'self-meter-readings');

  // API Calls
  const {
    loading: loadingPremises,
    callApi: getPremises,
    response: customerPremises,
  } = useSmartCityManager(SCManagerEndpoints.GetCustomerPremises);

  // State management and constants
  const premisesWithMunicipalAccounts = customerPremises?.data?.filter(
    (p: CustomerPremises) => p?.serviceProviderAccounts?.length
  );

  // Side effects
  useEffect(() => {
    getPremises();
  }, [cityId]);

  return (
    <MunicipalPageWrapper>
      {loadingPremises ? (
        <Loader />
      ) : (
        <Flex
          maxWidth="720px"
          mx="auto"
          width="100%"
          p="24px 28px 54px 28px"
          flexDirection="column"
          borderRadius="4px"
          boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
        >
          {premisesWithMunicipalAccounts &&
          premisesWithMunicipalAccounts.length > 0 ? (
            <>
              <Text
                fontWeight={600}
                mb="10px"
                fontSize="18px"
                textAlign="center"
              >
                Your accounts
              </Text>
              {premisesWithMunicipalAccounts.map(
                (premise: CustomerPremises) => (
                  <>
                    <Text fontWeight={600} mb="10px" textAlign="left">
                      {premise.description}
                    </Text>
                    {premise.serviceProviderAccounts &&
                      premise.serviceProviderAccounts.map(
                        (account: UtilityAccount) => (
                          <UtilityAccountItem
                            account={account}
                            onClick={() => {
                              console.log('account', account);
                              if (account.statusId === 2) {
                                navigate(
                                  intent === 'self-meter-readings'
                                    ? `/my-municipality/self-meter-readings/${cityId}?accountNumber=${account.accountNumber}&premiseId=${premise.id}`
                                    : `/my-municipality/municipal-accounts/${cityId}/view-account?accountNumber=${account.accountNumber}&premiseId=${premise.id}`
                                );
                              }
                            }}
                          />
                        )
                      )}
                  </>
                )
              )}
            </>
          ) : (
            <Flex
              flexDirection="column"
              width="100%"
              justifyContent="center"
              gap="24px"
            >
              <Image
                source="my-municipality/help-clinic.svg"
                alt="info"
                width="90px"
                mx="auto"
              />
              <Text textAlign="center">
                Link a municipal account to easily view & pay your monthly bill.
              </Text>
              <Button
                variant="cta"
                width="fit-content"
                mx="auto"
                onClick={() => navigate('/account/utility-accounts')}
              >
                Add Municipal Account
              </Button>
              <Text textAlign="center">
                For more information please contact George Municipality.
              </Text>
              <Flex
                justifyContent="space-evenly"
                mobileCss={css`
                  flex-direction: column;
                  align-items: center;
                  gap: 12px;
                `}
              >
                <ExternalLink href="tel:0448019111">
                  <Flex alignItems="center">
                    <Box
                      p="10px"
                      borderRadius="50%"
                      backgroundColor="#FCF1E9"
                      mr="12px"
                    >
                      <Image
                        source="my-municipality/phone.svg"
                        alt="phone"
                        width="20px"
                      />
                    </Box>
                    <Text fontSize="18px" fontWeight={600}>
                      044 801 9111
                    </Text>
                  </Flex>
                </ExternalLink>
                <ExternalLink href="mailto:accounts@george.gov.za">
                  <Flex alignItems="center">
                    <Box
                      p="10px"
                      borderRadius="50%"
                      backgroundColor="#FCF1E9"
                      mr="12px"
                    >
                      <Image
                        source="my-municipality/email.svg"
                        alt="mail"
                        width="20px"
                      />
                    </Box>
                    <Text fontSize="18px" fontWeight={600}>
                      accounts@george.gov.za
                    </Text>
                  </Flex>
                </ExternalLink>
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
    </MunicipalPageWrapper>
  );
};

export default MunicipalAccountPage;
