/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import {
  StyledCard,
  ThumbnailImage,
  InfoContainer,
  TypeLabel,
  Row,
  SmallIcon,
  Title,
  Secondary,
  Time,
  StarIcon,
  CostRatingContainer,
  LikeButton,
  Divider,
} from './card.styled';

/**
 * Quicket Event Card
 */
export const EventCard = ({ event }) => {
  const {
    id,
    name,
    description,
    url,
    imageUrl,
    startDate,
    endDate,
    categories,
    tickets,
    venue,
  } = event;

  // const [liked, setLiked] = useState(false);

  // useEffect(() => {
  //   setLiked(false);
  // }, []);

  // const handleLike = () => {
  //   setLiked(!liked);
  // };

  return (
    <StyledCard
      to="/in-the-city/whats-on-in-your-city/event"
      state={{ event }}
    >
      {/* Card Thumbnail */}
      <ThumbnailImage src={`https:${imageUrl}`}>
        <TypeLabel>{(categories && categories.length > 0) && categories[0]?.name}</TypeLabel>
      </ThumbnailImage>

      {/* Card Info */}
      <InfoContainer>
        <Row>
          <Title>{name}</Title>
          {(tickets && tickets?.length > 0) && (
            <CostRatingContainer>
              <p>{tickets[0].price === 0 ? 'Free' : `R ${tickets[0].price}`}</p>
            </CostRatingContainer>
          )}
        </Row>
        <Row>
          <SmallIcon src="inthecity/calender-icon.svg" alt="calendar" />
          <Secondary>{`From ${moment(startDate)} to ${moment(endDate)}`}</Secondary>
        </Row>
        <Row>
          <SmallIcon src="inthecity/pin-icon.svg" alt="pin" />
          <Secondary>{venue ? venue?.name : 'No location'}</Secondary>
          {/* <LikeButton active={liked} onClick={handleLike} /> */}
        </Row>
      </InfoContainer>
    </StyledCard>
  );
};

/**
 * Foursquare POI Card
 */
export const PlaceCard = (props) => {
  const {
    place,
  } = props;

  const {
    categories,
    distance,
    rating,
    hours,
    location,
    name,
    photos,
  } = place;

  return (
    <StyledCard
      to="/in-the-city/places-of-interest/place"
      state={{ place }}
    >
      {/* Card Thumbnail */}
      <ThumbnailImage
        style={photos[0]?.prefix ? null : { backgroundSize: 'inherit' }}
        src={photos[0]?.prefix ? `${photos[0]?.prefix}original${photos[0]?.suffix}` : '/mscdark.png'}
      >
        <TypeLabel>{categories[0]?.name}</TypeLabel>
      </ThumbnailImage>

      {/* Card Info */}
      <InfoContainer>
        <Row>
          <Title>{name}</Title>
          <CostRatingContainer>
            <StarIcon src="inthecity/star-icon.svg" alt="star" />
            <p>{Math.round((rating / 10) * 5) ? Math.round((rating / 10) * 5) : ''}</p>
          </CostRatingContainer>
        </Row>
        <Row>
          <SmallIcon src="inthecity/calender-icon.svg" alt="calendar" />
          <Time
            active={hours.open_now}
          >
            {hours.open_now ? 'Open' : 'Closed'}
          </Time>

          {hours.display
            ? (
              <>
                <Divider>·</Divider>
                <Secondary>{hours.display}</Secondary>
              </>
            )
            : (
              <>
                <Divider>·</Divider>
                <Secondary>Trading hours not available</Secondary>
              </>
            )}
        </Row>
        <Row>
          <SmallIcon src="inthecity/pin-icon.svg" alt="pin" />
          <Secondary>
            {location && location.address ? `${location.address} ${location.locality ? `, ${location.locality}` : ''}` : `${Math.round(Number(distance) / 1000)} km away`}
          </Secondary>
        </Row>
      </InfoContainer>
    </StyledCard>
  );
};
