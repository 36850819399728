import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'components/elements';
import {
  Tile,
  TileTitle,
  TileInfo,
  TileIcon,
  Grid,
} from './pageLinksGrid.styled';
import { useGlobalContext } from 'contexts/global';

const Link = ({ protectedRoute, children, to, isGuest }) => {
  const { setShowLoginModal } = useGlobalContext();
  return (
    <>
      {!(protectedRoute && isGuest) ? (
        <RouterLink to={to}>{children}</RouterLink>
      ) : (
        <Button onClick={() => setShowLoginModal(true)}>{children}</Button>
      )}
    </>
  );
};

const PageLinksGrid = () => {
  const {
    globalState: {
      user: { uuid },
    },
  } = useGlobalContext();
  const isGuest = uuid === 'guest';
  return (
    <Grid>
      <Link to="places-of-interest">
        <Tile src="inthecity/tilesNaming/banner_Group_3383.png">
          <TileTitle>Places of Interest</TileTitle>
          <TileInfo>Museums, Galleries, Parks, Landmarks and more.</TileInfo>
          <TileIcon src="inthecity/tilesIcons/icon_PlaceOfInterest.svg" />
        </Tile>
      </Link>
      <Link to="whats-on-in-your-city">
        <Tile src="inthecity/tilesNaming/banner_Group_3519.png">
          <TileTitle>What&apos;s on in your City</TileTitle>
          <TileInfo>Events, Golf Courses, Concerts and more.</TileInfo>
          <TileIcon src="inthecity/tilesIcons/icon_WhatOnInYourCity.svg" />
        </Tile>
      </Link>
      <Link to="insights" protectedRoute isGuest={isGuest}>
        <Tile src="inthecity/tilesNaming/banner_Group_3520.png">
          <TileTitle>Local Insights</TileTitle>
          <TileInfo>Cities, Wards and more.</TileInfo>
          <TileIcon src="inthecity/tilesIcons/icon_Information.svg" />
        </Tile>
      </Link>
      <Link to="purchase" protectedRoute isGuest={isGuest}>
        <Tile src="inthecity/tilesNaming/banner_Group_3518.png">
          <TileTitle>Purchase</TileTitle>
          <TileInfo>Airtime, Data, Lotto</TileInfo>
          <TileIcon src="inthecity/tilesIcons/icon_Purchase.svg" />
        </Tile>
      </Link>
    </Grid>
  );
};

export default PageLinksGrid;
