import { Modal } from 'components/elements';
import SchedulingAssistant, {
  SchedulingProps,
} from 'components/scheduling-assistant';

const SchedulingModal = ({
  isOpen,
  ...schedulingProps
}: {
  isOpen: boolean;
} & SchedulingProps) => (
  <Modal
    isOpen={isOpen}
    onClose={schedulingProps.confirmationCallback}
    slideFromBottomMobile
    title="Schedule service"
  >
    <SchedulingAssistant {...schedulingProps} />
  </Modal>
);

export default SchedulingModal;
