/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */

import { useState, useEffect } from 'react'; // , useCallback
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
// import ImageViewer from 'react-simple-image-viewer';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useGlobalContext } from 'contexts/global';
import {
  reverseGeocode,
  getChildWorkTypes,
  getTopLevelAnnouncementWorkRequestTypes,
  isInCityBounds,
} from 'interface/social';
import { wrTypeIconUrl } from 'functions/social';
import { Loader } from 'components/social/createpost';
import { logWorkRequest, addWorkRequestAttachment } from 'interface/map';
import { Modal, LocationPicker } from 'components/social';
import DatePicker from 'react-datepicker';
import { Text } from 'components/typography';
import { Grid, Box } from 'components/layout';
import { Button, Image, Modal as TheBetterModal } from 'components/elements';
import './date-picker-style.css';

import InteractiveAddressSelector from 'components/interactive-address-selector';
import {
  SmallLoader,
  FileInput,
  FileInputText,
  Form,
  SelectButton,
  Input,
  Textarea,
  LocationRow,
  LocationLabel,
  SubmitButton,
  ErrorLabel,
  LocationIcon,
  EditLocationButton,
  UploadContainer,
  CameraIcon,
  GrayImageText,
  WorkTypeListContainer,
  WorkTypeItem,
  WorkTypeRow,
  WorkTypeInfo,
  WorkTypeButton,
  PreviewImage,
  ImagesContainer,
  CancelImageIcon,
  LoaderContainer,
} from './createpost.styled';
import { Posted, Discard } from './createpostItems';

const ipLocation = require('iplocation');
const publicIp = require('public-ip');
const GM_DEFAULT_ADDRESS = {
  address: '71 York St, Dormehls Drift, George, 6529',
  location: {
    latitude: -33.961032,
    longitude: 22.454147,
  },
};

const defaultValues = {
  details: '',
  address: '',
  location: null,
  images: [],
  wrTypes: [],
  wrParentType: null,
  wrChildType: null,
  form: {
    // hoised state of form
    description: '',
    title: '',
  },
};

// Stage 0: Form, Stage 1: Type list, Stage 2: Posted, Stage 3: Discard, Stage 4: LocationPicker
const CreateAnnouncementPost = ({
  show,
  handleClose,
  handleRefreshNewsFeed,
}) => {
  const [stage, setStage] = useState(0);
  const [state, setState] = useState(defaultValues);
  const [tempLocation, setTempLocation] = useState(); // intermediate hoisted state for LocationPicker
  const { globalState } = useGlobalContext();

  const update = (updatedState) => {
    setState({ ...state, ...updatedState });
    console.log({ ...state, ...updatedState }); // test
  };

  useEffect(() => {
    async function fetchData() {
      // Get work types
      const parentTypes = await getTopLevelAnnouncementWorkRequestTypes();
      parentTypes.forEach(async (parent) => {
        const children = await getChildWorkTypes(parent.value);
        parent.children = children;
      });

      let location = {};
      // Find location using browser location
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          location = { latitude: coords.latitude, longitude: coords.longitude };
        },
        async () => {
          const loc = await ipLocation(await publicIp.v4());
          location = { latitude: loc.latitude, longitude: loc.longitude };
        }
      );

      const addressResult = await reverseGeocode(
        location.latitude,
        location.longitude
      );

      update({
        wrTypes: parentTypes,
        address: addressResult?.results[0]?.formatted_address,
        location,
      });
    }
    fetchData();
  }, [globalState.city]);

  const handleChangeLocation = () => {
    update(tempLocation);
    setStage(0);
  };

  const handleClickClose = () =>
    _.isEqual(state, defaultValues) ? handleClose() : setStage(4);

  switch (stage) {
    case 0:
      return (
        <Modal
          title="Create Announcements Post"
          show={show}
          handleClose={handleClickClose}
        >
          <AnnouncementForm
            state={state}
            update={update}
            goParentTypeList={() => setStage(1)}
            goChildTypeList={() => setStage(2)}
            goLocationPicker={() => setStage(5)}
            handlePosted={() => setStage(3)}
          />
        </Modal>
      );
    case 1:
      return (
        <Modal
          title="Choose Announcement category"
          show={show}
          closeButton={false}
          backButton
          handleBack={() => setStage(0)}
          handleClose={() => setStage(4)}
        >
          <ParentWorkTypeList
            state={state}
            update={update}
            goBack={() => setStage(0)}
          />
        </Modal>
      );
    case 2:
      return (
        <Modal
          title="Choose Announcement type"
          show={show}
          closeButton={false}
          backButton
          handleBack={() => setStage(0)}
          handleClose={() => setStage(4)}
        >
          <ChildWorkTypeList
            state={state}
            update={update}
            goBack={() => setStage(0)}
          />
        </Modal>
      );
    case 3:
      return (
        <Modal
          show={show}
          closeButton={false}
          handleClose={() => {
            setStage(0);
            setState({ ...defaultValues, ...{ wrTypes: state.wrTypes } }); // reset state, but keep wrTypes
            handleClose();
          }}
          handleBack={() => {}}
        >
          <Posted
            type="Announcement"
            handleClose={() => {
              setStage(0);
              setState({ ...defaultValues, ...{ wrTypes: state.wrTypes } }); // reset state, but keep wrTypes
              handleClose();
            }}
          />
        </Modal>
      );
    case 4:
      return (
        <Modal
          show={show}
          closeButton={false}
          handleClose={() => {}}
          noFullHeight
        >
          <Discard
            handleBack={() => setStage(0)}
            handleClose={() => {
              setStage(0);
              setState({ ...defaultValues, ...{ wrTypes: state.wrTypes } }); // reset state, but keep wrTypes
              handleClose();
              handleRefreshNewsFeed();
            }}
          />
        </Modal>
      );
    case 5:
      return (
        <Modal
          title="Choose Location"
          show={show}
          closeButton={false}
          backButton
          handleBack={() => setStage(0)}
        >
          <LocationPicker
            position={tempLocation?.location}
            handleChangeLocation={setTempLocation}
          />
          <SubmitButton onClick={handleChangeLocation}>
            Choose this location
          </SubmitButton>
        </Modal>
      );
    default:
      return null;
  }
};

export const ParentWorkTypeList = (props) => {
  const { state, update, goBack } = props;
  const handleSelect = (type) => {
    update({
      wrParentType: type,
      wrChildType: null,
    });
    goBack();
  };
  return (
    <WorkTypeListContainer>
      {state.wrTypes.length === 0 && <Loader />}
      {Array.isArray(state.wrTypes) &&
        state.wrTypes.map((type) => (
          <WorkTypeItem>
            <WorkTypeRow>
              <WorkTypeInfo>
                <h3>{type.label}</h3>
                {/* <p>Placeholder for the announcement type description.</p> */}
              </WorkTypeInfo>
              <WorkTypeButton
                selected={state.wrParentType?.value === type.value}
                onClick={() => handleSelect(type)}
              >
                {state.wrParentType?.value === type.value
                  ? 'Selected'
                  : 'Select'}
              </WorkTypeButton>
            </WorkTypeRow>
          </WorkTypeItem>
        ))}
    </WorkTypeListContainer>
  );
};

export const ChildWorkTypeList = (props) => {
  const { globalState } = useGlobalContext();
  const { state, update, goBack } = props;
  const childTypes = state.wrTypes.find(
    (t) => t.value === state.wrParentType.value
  )?.children;
  const handleSelect = (type) => {
    update({ wrChildType: type });
    goBack();
  };
  return (
    <WorkTypeListContainer>
      {Array.isArray(childTypes) &&
        childTypes.map((type) => (
          <WorkTypeItem>
            <img
              src={wrTypeIconUrl(
                type.attributes.customIcon,
                globalState.city?.value
              )}
              alt="work type icon"
            />
            <WorkTypeRow>
              <WorkTypeInfo>
                <h3>{type.label}</h3>
                {/* <p>Placeholder for the announcement type description.</p> */}
              </WorkTypeInfo>
              <WorkTypeButton
                selected={state.wrChildType?.value === type.value}
                onClick={() => handleSelect(type)}
              >
                {state.wrChildType?.value === type.value
                  ? 'Selected'
                  : 'Select'}
              </WorkTypeButton>
            </WorkTypeRow>
          </WorkTypeItem>
        ))}
    </WorkTypeListContainer>
  );
};

const AnnouncementForm = (props) => {
  const {
    state,
    update,
    goParentTypeList,
    goChildTypeList,
    goLocationPicker,
    handlePosted,
  } = props;

  const { globalState } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [locError, setLocError] = useState(false);
  const [topOfSocialFeed, setTopOfSocialFeed] = useState(null);
  const [postContent, setPostContent] = useState('');
  const [isLocationPickerOpen, setIsLocationPickerOpen] = useState(false);
  const [address, setAddress] = useState('');
  console.log({ address, state });

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    isValid,
  } = useForm();

  const onSubmit = async (data) => {
    const form = {
      custom1: data.title,
      description: postContent,
      typeId: state.wrChildType.value, // child worktype
      // orgUnitId: 1080, // default 1080
      contactName: globalState.user.fullName, // username
      address: state.address,
      latitude: state.location?.latitude,
      longitude: state.location?.longitude,
      topOfSocialFeed: topOfSocialFeed?.getTime() / 1000 || null,
    };

    update({ form: data });

    setLoading(true);
    // log workrequest
    const wrRes = await logWorkRequest(JSON.stringify(form));

    for (let i = 0; i < state.images.length; i++) {
      const formData = new FormData();
      formData.append('workRequestId', wrRes.id);
      formData.append('file', state.images[i].file);
      await addWorkRequestAttachment(formData);
    }
    setLoading(false);

    handlePosted();
  };

  const handleRemoveImage = (index) => {
    const imagesArr = state.images.filter((img, i) => i !== index && img);
    update({ images: imagesArr });
  };

  const getErrorMessage = (type) => {
    switch (type) {
      case 'required':
        return 'This is a required field';
      case 'maxLength':
        return 'Field exceeds the maximum length';
      case 'minLength':
        return 'Field is Less than the minimum length';
      default:
        return 'Error';
    }
  };

  useEffect(() => {
    async function checkLocation() {
      const res = await isInCityBounds(
        state.location?.latitude,
        state.location?.longitude
      );
      if (res?.foundInSmartCityId === globalState.city.value) {
        setLocError(false);
      } else {
        setLocError(true);
      }
    }
    checkLocation();
  }, [state.location]);

  useEffect(() => {
    // George has requested not to have to enter the same address every time, so we set a default
    if (globalState.city.value === 663) {
      update({ ...GM_DEFAULT_ADDRESS });
    }
    console.log(state.wrParentType);
    if (state.wrParentType === undefined || state.wrParentType === null) {
      goParentTypeList();
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TheBetterModal
        isOpen={isLocationPickerOpen}
        onClose={() => setIsLocationPickerOpen(false)}
      >
        <InteractiveAddressSelector
          setAddressCallback={(a) => {
            update({
              address: a.formatted_address,
              location: {
                latitude: a.geometry.location.lat,
                longitude: a.geometry.location.lng,
              },
            });
            setIsLocationPickerOpen(false);
          }}
        />
      </TheBetterModal>
      <SelectButton
        onClick={() => {
          update({ form: getValues() });
          goParentTypeList();
        }}
      >
        <p>{state.wrParentType?.label || 'Choose parent type'}</p>
      </SelectButton>

      {state.wrParentType && (
        <>
          <SelectButton
            onClick={() => {
              update({ form: getValues() });
              goChildTypeList();
            }}
          >
            <p>{state.wrChildType?.label || 'Choose type'}</p>
          </SelectButton>
        </>
      )}

      {state.wrParentType && state.wrChildType && (
        <>
          <Input
            error={errors.title}
            placeholder="What this alert is about"
            defaultValue={state.form.title}
            {...register('title', {
              required: true,
              minLength: 3,
              maxLength: 100,
            })}
          />
          {errors.title && (
            <ErrorLabel>{getErrorMessage(errors.title?.type)}</ErrorLabel>
          )}

          {/* <Textarea
            error={errors.description}
            placeholder="Give details about what is happening"
            defaultValue={state.form.description}
            {...register('description', {
              required: true,
              minLength: 3,
              maxLength: 500,
            })}
          /> */}
          <Box mb="20px">
            <ReactQuill
              value={postContent}
              onChange={(c) => setPostContent(c)}
              theme="snow"
            />
          </Box>
          {errors.description && (
            <ErrorLabel>{getErrorMessage(errors.description?.type)}</ErrorLabel>
          )}
          {globalState.city.value !== 663 && (
            <>
              <LocationRow>
                <LocationIcon src="social/location-icon.svg" alt="pin" />
                <LocationLabel>
                  {state.address || 'Choose your location'}
                </LocationLabel>
                <EditLocationButton
                  onClick={() => setIsLocationPickerOpen(true)}
                />
              </LocationRow>
              {locError && (
                <ErrorLabel>{`Please select a location inside of ${globalState.city?.label}`}</ErrorLabel>
              )}
            </>
          )}

          {state.images?.length > 0 && (
            <ImagesContainer>
              {state.images.map((src, index) => (
                <PreviewImage len={state.images.length} src={src.url}>
                  <Button onClick={() => handleRemoveImage(index)}>
                    <Image
                      source="social/clear-icon.svg"
                      alt="delete"
                      overrideCss={css`
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        height: 30px;
                        width: 30px;
                        z-index: 10;
                      `}
                    />
                  </Button>
                </PreviewImage>
              ))}
            </ImagesContainer>
          )}

          {state.images.length === 0 && (
            <UploadContainer>
              <CameraIcon src="social/photo-icon.svg" alt="camera" />
              <GrayImageText>Add photos relevant to your post</GrayImageText>
              <Upload state={state} update={update} />
            </UploadContainer>
          )}

          {state.images.length > 0 && state.images.length < 3 && (
            <Upload state={state} update={update} />
          )}
        </>
      )}

      <SubmitButton
        type="submit"
        disabled={
          isValid ||
          locError ||
          !state.wrChildType ||
          !postContent ||
          postContent === ''
        }
      >
        {loading && (
          <LoaderContainer>
            <SmallLoader />
          </LoaderContainer>
        )}
        {loading ? 'Publishing Post...' : 'Publish Post'}
      </SubmitButton>
    </Form>
  );
};

const Upload = ({ state, update }) => {
  const [error, setError] = useState();

  const handleChange = (e) => {
    const file = e.target.files[0];

    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      setError('Only PNG, JPEG and GIF');
      return;
    }

    if (file.size > 10000000) {
      setError('Max 10MB');
      return;
    }

    update({
      images: [
        ...state.images,
        {
          file,
          url: URL.createObjectURL(file),
        },
      ],
    });
  };

  if (state.images.length < 3) {
    return (
      <>
        <FileInputText>
          <FileInput
            type="file"
            accept="image/*"
            onChange={(e) => handleChange(e)}
          />
          {state.images.length === 0 ? 'Click to browse' : 'Add more photos'}
        </FileInputText>
        {error && <ErrorLabel>{error}</ErrorLabel>}
      </>
    );
  }
  return null;
};

export default CreateAnnouncementPost;
