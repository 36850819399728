export interface SmartCityUserCard {
  uuid: string;
  smartcityuser_uuid: string;
  bin: string;
  expiryMonth: string;
  expiryYear: string;
  holder: string;
  lastDigits: string;
  registrationId: string;
}

export interface SmartCityUserRole {
  id: number;
  description: string;
}

export interface SmartCityUser {
  followable: boolean;
  followed: boolean;
  reportsCount: number;
  petitionsCount: number;
  followDate: number;
  accepttc: boolean;
  acceptmarketing: boolean;
  cards: SmartCityUserCard[];
  uuid: string;
  fullName: string;
  mobileNumber: string;
  password: string | null;
  email: string;
  currentCitySubscriberId: number;
  firebaseUid: string;
  theme: string;
  issuesNearMeNotficationDistanceThreshold: any;
  hasProfilePic: boolean;
  schemaOptions: any;
  userOptions: any;
  verified: boolean;
  smartCityUserRoles: SmartCityUserRole[];
  twitterHandle: any;
  councillorForWard: any;
}

export interface SmartCity {
  label: string;
  value: number;
  attributes: {
    latitude: string;
    distanceKm: string;
    subscriberId: string;
    longitude: string;
  };
}
export interface MSCGlobalState {
  user: SmartCityUser;
  showLoginModal: boolean;
  location: {
    latitude: string;
    longitude: string;
  };
  city: SmartCity;
  availableCities: SmartCity[];
  FirebaseToken: string;
  authenticated?: boolean;
}

export interface PrivateService {
  label: string;
  value: number;
  attributes: { index: string; customIcon: string };
}

export interface SuggestedService {
  label: string;
  value: number;
  attributes: { Category: string; customIcon: string };
}

export interface ServiceSelection {
  label: string;
  value: number;
}

export interface LatLng {
  lat: number;
  lng: number;
}
export interface GoogleSuggestedAddress {
  business_status: string;
  formatted_address: string;
  geometry: {
    location: LatLng;
    viewport: {
      northeast: LatLng;
      southwest: LatLng;
    };
  };
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  name: string;
  opening_hours: {
    open_now: boolean;
  };
  place_id: string;
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  rating: number;
  types: string[];
  user_ratings_total: number;
}

export interface SmartCityService {
  serviceType: number;
  label: string;
  icon: string;
  index: number;
  min: number;
  max: number;
  price: number;
  currency: string;
  unit: string;
  current: any;
  shiftStart: string;
  shiftEnd: string;
  duration: number | null;
}

export interface SmartCityServiceGroup {
  heading: string;
  subHeading: string;
  information: any;
  smartCityServices: SmartCityService[];
}

export interface ServiceBookingInformation {
  workOrderId?: number;
  typeId?: number;
  service?: string;
  serviceIcon?: string;
  resourceId?: number;
  resourceName?: string;
  startDateTime?: number;
  endDateTime?: number;
  estimatedDuration?: number;
  latitude?: number;
  longitude?: number;
  serviceCost?: number;
  totalPrice?: number;
  insured?: any;
  insurance?: any;
  premiseTypeIcon?: string;
  premiseType?: string;
  address?: string;
  premiseId?: number;
  status?: string;
  recurring?: boolean;
  recurringString?: string;
  recurringDates?: any[];
  workOrderStatus?: string;
  ratingStatus?: string;
  productUUID?: string;
  resourceProfileUrl?: string;
  couponCode?: string;
}
export interface SmartCityServiceBooking {
  smartCityServiceGroups: SmartCityServiceGroup[];
  bookingInformation: ServiceBookingInformation | null;
}

export interface ResourceReview {
  fullName: string;
  role: string;
  starRating: number;
  comment: string;
  timeOfReview: number;
  profilePic?: string;
}
export interface SuggestedResource {
  id: number;
  profilePic: string;
  fullName: string;
  preferredSupplier?: boolean;
  jobsCompleted?: number;
  description?: string;
  reviewCount: number;
  rating: {
    starRating?: number;
    ratings?: any[];
  };
  reviews: ResourceReview[];
}

// IMPORTANT: Make sure these always match up with the API labels and headings
/* TODO: Rather use an ID system for each group and subgroup.
 * String matching might not be the best way of making sure that we keep our data in sync.
 */
export enum DomesticCleaningGroups {
  ROOMS = 'Rooms',
  EXTRAS = 'Extras',
}

export enum DomesticCleaningRooms {
  BED = 'Bedrooms',
  BATH = 'Bathrooms',
}

export enum DomesticCleaningExtras {
  LAUNDRY = 'Laundry',
  FRIDGE = 'Inside Fridge',
  WINDOWS = 'Windows',
  OUTSIDE = 'Outside Area/Patio',
  OVEN = 'Inside Oven',
  IRONING = 'Ironing',
  CABINETS = 'Cabinets',
}

export enum GardeningGroups {
  HOURS = 'Hours needed',
}

export enum LocalStorageIndex {
  LATEST_BOOKING = 'latestBooking',
  LATEST_BOOKING_ADDRESS = 'latestBookingAddress',
}

export interface PremiseAvailableService {
  label: string;
  value: number;
  attributes: {
    index: number;
    customIcon: string;
  };
  longAttributes: {};
}

export interface UtilityAccount {
  premiseId: number;
  orgUnitId: number;
  accountNumber: string;
  orgUnitDescription: string;
  active: boolean;
  meters: any[];
  externalId: null | string;
  status: string;
  statusId: number;
}
export interface CustomerPremises {
  id: number;
  code?: any;
  description?: string;
  unitNo?: any;
  buildingName?: any;
  streetNumber?: number | string;
  streetName?: string;
  surburb?: string; // nice
  suburb?: any;
  latitude: number;
  longitude: number;
  active: boolean;
  blockId?: number;
  premiseType: string;
  premiseTypeId: number;
  premiseTypeIcon?: string;
  addressString: string;
  serviceAvailable: boolean;
  availableServices: PremiseAvailableService[];
  serviceProviderAccounts?: UtilityAccount[];
}

export interface CustomerPremisesResponse {
  meta: {
    currentPage: number;
    total: number;
    lastPage: number;
    currentPageCount: number;
  };
  data: CustomerPremises[];
}

export interface CreateCustomerPremise {
  description: string;
  buildingName?: string;
  suburb?: string;
  latitude: number;
  longitude: number;
  premiseType: string;
  premiseTypeId: number;
  unitNo?: string;
}

export interface LabelValuePair {
  label: string;
  value: number;
  attributes?: Record<string, any>;
}

export type PremiseType = LabelValuePair;

export interface PeachPaymentStatusResponse {
  amount: string;
  paymentBrand: string;
  threeDSecure: {
    dsTransactionId: string;
    eci: string;
    authType: string;
    version: string;
    verificationId: string;
    flow: string;
    challengeMandatedIndicator: string;
    acsTransactionId: string;
  };
  descriptor: string;
  ndc: string;
  buildNumber: string;
  paymentType: string;
  result: {
    cvvResponse: string;
    code: string;
    description: string;
  };
  resultDetails: {
    ExtendedDescription: string;
    clearingInstituteName: string;
    AuthCode: string;
    ConnectorTxID1: string;
    ConnectorTxID3: string;
    ConnectorTxID2: string;
    AcquirerResponse: string;
    CardholderInitiatedTransactionID: string;
  };
  currency: string;
  customParameters: {
    SHOPPER_EndToEndIdentity: string;
    CTPE_DESCRIPTOR_TEMPLATE: string;
  };
  id: string;
  card: {
    bin: string;
    expiryMonth: string;
    holder: string;
    expiryYear: string;
    binCountry: string;
    last4Digits: string;
  };
  customer: {
    ip: string;
    ipCountry: string;
  };
  timestamp: string;
}

type MessageOrigin = 'Resource' | 'SmartCity' | 'BackOffice';

export interface ResourceChatMessage {
  messageId: number;
  resourceId: number;
  resourceName: string;
  resourceIcon: string;
  text: string;
  read: boolean;
  originator: MessageOrigin;
  bookingId: number;
  timestamp: number;
}

export interface CustomerBooking {
  workOrderId: number;
  service: string;
  serviceIcon: string;
  resourceProfilePic: string;
  resourceName: string;
  bookingStartDate: number;
  bookingEndDate: number;
  status: string;
  ratingStatus: string;
  resourceId: number;
}

export interface MyBookingsResponse {
  activeBookings: CustomerBooking[];
  completedBookings: CustomerBooking[];
  cancelledBookings: CustomerBooking[];
  recurringBookings: CustomerBooking[];
  pendingBookings: CustomerBooking[];
}

export interface EnRouteResource {
  id: number;
  profilePic: string;
  fullName: string;
  eta: number;
  bookingId: number;
  resourceId: number;
  serviceType?: string;
  serviceTypeId: number;
  workOrderStatus: string;
}

export interface ResourceTrackingInfo {
  fullName: string;
  imageUrl: string;
  latitude: number | null;
  longitude: number | null;
}

export interface ReviewPost {
  workOrderId: number;
  resourceId: number;
  starRating: number;
  comment: string;
  reviewer_uuid: any;
  reviewByUser: boolean;
}

export interface UserPaymentMethod {
  uuid: string;
  smartcityuser_uuid: string;
  bin: string;
  expiryMonth: string;
  expiryYear: string;
  holder: string;
  lastDigits: string;
  registrationId: string;
}

export interface EmergencyContact {
  contactName: string;
  contactNumber: string;
}

export interface EmergencyContactResponse {
  data: EmergencyContact[];
  meta: any;
}

export interface MeterReadingInterface {
  typeId: number;
  orgId: number;
  reading: bigint;
  readingDate: number;
  premiseId: number;
}

export interface MSCMapPoint {
  business_status: string;
  formatted_address: string;
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  opening_hours: Record<string, unknown>;
  place_id: string;
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  rating: number;
  reference: string;
  user_ratings_total: number;
  id: string;
  orgUnitId: number;
  customIcon: string;
  lat: number;
  lng: number;
  address: string;
  typeDescription: string;
  imageIds: string[];
  custom1: string;
  emergencyService: boolean;
}

export interface NivoGraphDataItem {
  id: string;
  label: string;
  value: number;
  color: string;
}

export interface NivoPieChartSlice {
  id: string;
  label: string;
  hidden: boolean;
  value: number;
  formattedValue: string;
  data: {
    id: string;
    value: number;
    label: string;
    color: string;
  };
  color: string;
  arc: {
    index: number;
    startAngle: number;
    endAngle: number;
    innerRadius: number;
    outerRadius: number;
    thickness: number;
    padAngle: number;
    angle: number;
    angleDeg: number;
  };
}

export interface MeterType {
  label: string;
  value: number;
  attributes: {
    colour: string;
    examplePic: string;
    icon: string;
  };
  longAttributes: Record<string, any>;
  arrayAttributes: Record<string, any>[];
}

export interface Meter {
  premiseId: number;
  orgUnitId: number;
  meterNumber: string;
  orgUnitDescription: string;
  meterTypeId: number;
  meterTypeDescription: string;
  description: string;
  meterId: number;
  colour: string;
  icon: string;
}

export interface MeterReading {
  reading: number;
  readingDate: number;
  premiseId: number;
  meterNumber: string;
  meterId: number;
}

export interface PastReading {
  id: number;
  typeId: number | null;
  orgId: number | null;
  reading: number;
  readingDate: number;
  premiseId: number;
  refNo: string;
  meterId: number;
  colour: string;
  icon: string;
}

// TYPES SPECIFIC TO GEORGE MUNICIPALITY

export interface GMSocialLink {
  id: string;
  url: string;
}

export interface GMCategory {
  id: number;
  name: string;
  slug: string;
  icon: string;
}

export interface GMLocation {
  id: number;
  name: string;
  slug: string;
}

export interface GMImage {
  id: number;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  src: string;
  name: string;
  alt: string;
  position: number;
}

export interface GMBusiness {
  id: number;
  name: string;
  slug: string;
  permalink: string;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  description: string;
  short_description: string;
  zip: string;
  phone: string;
  phone_2: string;
  fax: string;
  email: string;
  website: string;
  social_links: GMSocialLink[];
  views_count: number;
  map_hidden: boolean;
  address: string;
  latitude: string;
  longitude: string;
  pricing_type: string;
  price: number;
  price_range: string;
  owner_contact_hidden: boolean;
  video_url: string;
  tagline: string;
  directory: number;
  date_expired: string;
  never_expired: boolean;
  featured: boolean;
  new: boolean;
  popular: boolean;
  status: string;
  reviews_allowed: boolean;
  average_rating: number;
  rating_count: number;
  related_ids: number[];
  categories: GMCategory[];
  tags: string[];
  locations: GMLocation[];
  images: GMImage[];
  menu_order: number;
  author: number;
  yoast_head: string;
  yoast_head_json: {
    og_description: string;
  };
}
export interface GMCouncillor {
  id: number;
  name: string;
  email: string;
  ward: string;
  areasOfResponsibility: string;
  imagePath: string;
  party: string;
  type: 'ward councillor';
}

export interface GMBusinessCategory {
  id: number;
  count: number;
  name: string;
}

export interface GMSurveyQuestion {
  id: number;
  question: string;
  min: number;
  max: number;
  answer: number;
  requiresTextBox?: boolean;
  comment?: string | null;
}

export interface GMSurveyCategory {
  id: number;
  description: string;
  questions: GMSurveyQuestion[];
}

export interface GMSurvey {
  id: number;
  templateWorkOrderId: number;
  surveyRefNo: string;
  description: string;
  categorys: GMSurveyCategory[]; // Ahhhh, spelling, why doth thou elude me?
  notes: string;
}

export interface GMSurveySummary {
  id: number;
  availableTill: number;
  description: string;
}

export interface GMIDPSummary extends GMSurveySummary {}

export interface GMIDP {
  templateWorkOrderId: number;
  idpRefNo: string;
  comment: string;
  latitude: number;
  longitude: number;
  topic: null | string;
  locationString: null | string;
  wardNo: null | string;
}

export interface GMGISFeature {
  type: string;
  id: number;
  geometry: {
    type: any;
    coordinates: number[];
  };
  properties: {
    OBJECTID: number;
    Category: string;
    Name: string;
    Contact: string;
    Facility_category: string;
  };
}

export interface GMWordPressPost {
  id: number;
  date: string;
  date_gmt: string;
  guid: {
    rendered: string;
  };
  modified: string;
  modified_gmt: string;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: {
    rendered: string;
  };
  template: string;
}

export interface GMTender extends GMWordPressPost {
  'supply-chain-categories': number[];
  'toolset-meta': {
    'supply-chain-fields': {
      'type-document-supply': {
        type: string;
        raw: string;
      };
      'status-supply': {
        type: string;
        raw: string;
      };
      'bid-number-supply': {
        type: string;
        raw: string;
      };
      'institution-supply': {
        type: string;
        raw: string;
      };
      'date-open-supply': {
        type: string;
        raw: string;
        formatted: null;
      };
      'date-closed-supply': {
        type: string;
        raw: string;
        formatted: null;
      };
      'closing-date-supply': {
        type: string;
        raw: string;
        formatted: null;
      };
      'bid-description-supply': {
        type: string;
        raw: string;
      };
      'enquiries-supply': {
        type: string;
        raw: string;
      };
      'description-document-supply': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'tender-document-supply': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'quotation-document-supply': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'document-supply-chain': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document-supply': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'extra-document-2-supply': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'extra-document-3-supply': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'extra-document-4-supply': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'extra-document-5-supply': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'extra-document-6': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'extra-document-7': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'extra-document-8': {
        type: string;
        raw: string;
        attachment_id: null;
      };
      'extra-document-9': {
        type: string;
        raw: string;
        attachment_id: null;
      };
    };
  };
}

export interface GMVacancy extends GMWordPressPost {
  'toolset-meta': {
    'human-resources': {
      'type-document-hr': {
        type: string;
        raw: string;
      };
      'directorate-jobs': {
        type: string;
        raw: string;
      };
      level: {
        type: string;
        raw: string;
      };
      reference: {
        type: string;
        raw: string;
      };
      'salary-scale': {
        type: string;
        raw: string;
      };
      'enquiries-hr': {
        type: string;
        raw: string;
      };
      'closing-date-hr': {
        type: string;
        raw: string;
        formatted: string;
      };
      'job-description': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'application-form': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      document: {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
    };
  };
}

export interface GMApplication extends GMWordPressPost {
  'toolset-meta': {
    'george-documents': {
      'type-document': {
        type: string;
        raw: string;
      };
      status: {
        type: string;
        raw: string;
      };
      'financial-year': {
        type: string;
        raw: string;
      };
      level: {
        type: string;
        raw: string;
      };
      reference: {
        type: string;
        raw: string;
      };
      'salary-scale': {
        type: string;
        raw: string;
      };
      'bid-number': {
        type: string;
        raw: string;
      };
      'bid-description': {
        type: string;
        raw: string;
      };
      institution: {
        type: string;
        raw: string;
      };
      enquiries: {
        type: string;
        raw: string;
      };
      'date-open': {
        type: string;
        raw: string;
        formatted: string | null;
      };
      'date-closed': {
        type: string;
        raw: string;
        formatted: string | null;
      };
      'description-document': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'tender-document': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'quotation-document': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document-2': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document-3': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document-4': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document-5': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document-6': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document-7': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document-8': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'extra-document-9': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'job-description': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'application-form': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
      'document-upload': {
        type: string;
        raw: string;
        attachment_id: number | null;
      };
    };
  };
}

export interface GMAccountLineItem {
  label: string;
  icon: string;
  colour: string; // Why do we hate consistency? Why?
  amount: number;
}

export interface GMMunicipalAccount {
  premiseId: number;
  dueDate: number;
  total: number;
  productId: string;
  lineItems: GMAccountLineItem[];
  accountNumber: string;
}

export interface GMPastMunicipalPayment {
  date: number;
  amount: number;
  docurl: string;
  accountType: string;
  referenceCode: string;
  premiseDescription: string;
  premiseTypeIcon: string;
  accountNumber: string;
}
