/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ActionBar } from 'components';
import Loadshedding, { BlockSchedule } from 'components/social/loadshedding';
import {
  AlertPost,
  CreatePost,
  Breadcrumbs,
  Alerts,
  Search,
} from 'components/social';
import CreatePostMenu from 'components/social/createpostmenu';
import { SuggestionList } from 'components/social/connection';
import { Heading } from 'components/social/text';
import { Loader } from 'components/social/createpost';
import { getOpenWorkOrdersForType } from 'interface/social/alerts';
import {
  NotFoundContainer,
  ContentRow,
  TopRow,
  PageContainer,
  FilterColumn,
  FeedColumn,
  ConnectionsColumn,
  Feed,
} from './social.styled';
import { Title, TextLink } from './connections.styled';

const PAGE_SIZE = 10;

const AlertsPage = () => {
  const navigation = useLocation();
  const alert = navigation.state?.alert || 0;
  const scrollLeft = navigation.state?.scrollLeft || 0;

  const [showCreatePost, setShowCreatePost] = useState(false);
  const [createPostType, setCreatePostType] = useState(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    currentPage: 0,
    total: null,
    lastPage: null,
    currentPageCount: null,
  });

  const [showSchedule, setShowSchedule] = useState(false);
  const [scheduleState, setScheduleState] = useState({
    stage: 0,
    block: 0,
    suburb: '',
    city: '',
  });

  const handleNextPage = async () => {
    const nextPage = meta.currentPage + 1;
    const res = await getOpenWorkOrdersForType(alert, nextPage, PAGE_SIZE);
    setData([...data, ...res.data]);
    setMeta(res.meta);
  };

  useEffect(() => {
    async function fetchData() {
      // if not loadshedding, ie id !== 1
      if (alert !== 1) {
        setLoading(true);
        setData([]);
        const res = await getOpenWorkOrdersForType(alert);
        setData(res?.data);
        setLoading(false);
      }
    }
    fetchData();
  }, [alert]);

  const handleCreatePost = (type) => {
    if (type !== null) {
      setShowCreatePost(true);
      setCreatePostType(type);
    }
  };

  const handleOpenSchedule = (d) => {
    setScheduleState(d);
    setShowSchedule(true);
  };

  return (
    <>
      <CreatePost
        show={showCreatePost}
        type={createPostType}
        handleClose={() => setShowCreatePost(false)}
      />
      <BlockSchedule
        show={showSchedule}
        block={scheduleState.block}
        suburb={scheduleState.suburb}
        city={scheduleState.city}
        handleClose={() => setShowSchedule(false)}
      />
      <ActionBar>
        <CreatePostMenu handleCreatePost={handleCreatePost} />
        <Search />
      </ActionBar>

      <PageContainer>
        <TopRow>
          <Breadcrumbs />
        </TopRow>

        <ContentRow>
          <FilterColumn>{/* <Filter /> */}</FilterColumn>

          <FeedColumn>
            <Heading>Alerts</Heading>

            <Alerts alert={alert} scrollLeft={scrollLeft} />

            {alert === 1 ? ( // load shedding id = 1
              <Loadshedding handleOpenSchedule={(b) => handleOpenSchedule(b)} />
            ) : (
              <Feed>
                {loading && data !== [] && <Loader />}
                {data?.length === 0 && !loading && (
                  <NotFoundContainer>
                    <h2>No alerts</h2>
                  </NotFoundContainer>
                )}

                <InfiniteScroll
                  style={{ overflow: 'inherit' }}
                  dataLength={meta?.currentPage}
                  next={handleNextPage}
                  hasMore={meta ? meta?.currentPage < meta?.lastPage : true}
                  loader={!loading && <Loader />}
                  // endMessage={<Loadmore visible>No more alerts</Loadmore>}
                >
                  {Array.isArray(data) &&
                    data.map(
                      (
                        {
                          id,
                          title,
                          profileImg = 'chatbot/msc_profile.svg',
                          username = 'My Smart City',
                          address,
                          createdDate,
                          plannedStart,
                          plannedFinish,
                          typeDescription,
                          description,
                          imageIds,
                        },
                        index
                      ) => (
                        <AlertPost
                          id={id}
                          isFirst={index === 0}
                          profileImg={profileImg}
                          username={username}
                          address={address}
                          createdDate={createdDate}
                          plannedStart={plannedStart}
                          plannedFinish={plannedFinish}
                          type={typeDescription}
                          title={title}
                          text={description}
                          images={imageIds}
                          key={index}
                        />
                      )
                    )}
                </InfiniteScroll>
              </Feed>
            )}
          </FeedColumn>

          <ConnectionsColumn>
            <Heading style={{ marginBottom: '23px' }}>Connections</Heading>
            <Title>Suggested Follows</Title>
            <SuggestionList />
            <TextLink to="/social/connections">See more suggestions</TextLink>
          </ConnectionsColumn>
        </ContentRow>
      </PageContainer>
    </>
  );
};

export default AlertsPage;
