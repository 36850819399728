import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, serverTimestamp } from 'firebase/database';
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
} from 'firebase/auth';

import { firebaseConfig } from 'constants/app';

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getDatabase();
export const providerGoogle = new GoogleAuthProvider();
export const providerFacebook = new FacebookAuthProvider();

export const errorHandler = (e: any, setState: any) => {
  let message = '';
  switch (e.code) {
    case 'auth/invalid-email':
      message = 'The email entered is invalid';
      break;
    case 'auth/email-already-exists':
      message = 'The provided email is already in use by an existing user';
      break;
    case 'auth/user-not-found':
      message = 'No user with these credentials could be found';
      break;
    case 'auth/popup-closed-by-user':
      message =
        'Authentication window was closed prematurely, please try again';
      break;
    case 'auth/wrong-password':
      message =
        'Incorrect password or use a different sign in method, please try again';
      break;
    default:
      message = e.message;
  }
  if (e.code) {
    message = `Error ${e.code}: ${message}`;
  } else {
    message = `Error: ${message}`;
  }
  setState({ error: message });
};

export const submitForm = (form: any, formData: any) => {
  set(ref(db, `forms/${form}`), {
    ...formData,
    created: serverTimestamp,
  });
};
