const apiUrl = 'https://api.foursquare.com/v3/';

const FS_API_KEY = 'fsq3kk915tm/4K9CwdV02S2mLXKLJTaeW0+lzO9ZNDf9gGY=';

// https://developer.foursquare.com/reference/response-fields
const fields = [
  'fsq_id',
  'name',
  'geocodes',
  'location',
  'categories',
  'chains',
  'related_places',
  'distance',
  'description',
  'tel',
  'email',
  'website',
  'social_media',
  'verified',
  'hours',
  'hours_popular',
  'rating',
  'stats',
  'popularity',
  'price',
  'menu',
  'date_closed',
  'photos',
  'tips',
  'tastes',
];

/**
 * Get Place
 *
 * Search for places in the FSQ Places database using a location
 * and querying by name, category name, taste label, or chain name.
 * For example, search for "coffee" to get back a list of recommended coffee shops.
 * @param {string} ll latitude/longitude (e.g., ll=41.8781,-87.6298)
 * @param {string} radius
 * @returns {Promise} results and context
 */
export const getPlaces = (ll, radius = '15000') => fetch(
  `${apiUrl}places/search`
  + `?ll=${ll}`
  + `&radius=${radius}`
  + '&sort=DISTANCE'
  + '&limit=50'
  + `&fields=${fields.join(',')}`,
  {
    method: 'GET',
    async: true,
    headers: {
      Authorization: FS_API_KEY,
    },
  },
)
  .then((res) => res.json())
  .catch((e) => console.log(e));
