/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
/** @jsxImportSource @emotion/react */
import { useState, useCallback, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
import moment from 'moment-timezone';
import _ from 'lodash';

import { numberFormatter } from 'functions/app';
import { imageUrl, profileImageUrl, wrTypeIconUrl } from 'functions/social';
import { useGlobalContext } from 'contexts/global';
import {
  shareWorkRequest,
  upVoteWorkRequest,
  unUpVoteWorkRequest,
  rePostWorkRequest,
} from 'interface/social';
import { useOnClickOutside } from 'functions/home';
import { css } from '@emotion/react';
import { apiUrl } from 'constants/app';
import { Cookies } from 'react-cookie';
import { Button } from 'components/elements';
import { SmallLoader } from 'components/private-services/survey';
import { Box } from 'components/layout';
import CommentSection from './comment';
import ShareMenu from './sharemenu';
import {
  Container,
  ProfileRow,
  Reference,
  ReferenceNumber,
  ProfileImage,
  InfoContainer,
  Username,
  Dot,
  GrayText,
  InfoRow,
  TypeIcon,
  Type,
  Title,
  Text,
  ImagesContainer,
  PreviewImage,
  ActionRow,
  ActionButton,
  ActionText,
  ActionIcon,
  Line,
  City,
  VerifiedIcon,
  // StatusBarContainer,
  // StatusBar,
  // StatusContainer,
  Readmore,
} from './post.styled';

const cookies = new Cookies();

// function removeStartCommasSpaces(str) {
//   if (str.length > 0 && (str[0] === ',' || str[0] === ' ')) {
//     removeStartCommasSpaces(str.slice(1, str.length));
//   }
//   console.log(str);
//   return str;
// }

const detectHTML = (content) => {
  return (
    content.includes('<p>') ||
    content.includes('<br') ||
    content.includes('<h') ||
    content.includes('<ol>') ||
    content.includes('<ul>')
  );
};

export const Post = (props) => {
  const {
    id,
    isRepost,
    repostedUser,
    rePostDate,
    isFirst,
    uuid,
    verified,
    refNum,
    username,
    role,
    location,
    timestamp,
    status,
    wrIcon,
    type,
    title,
    text,
    images,
    upvotes = 0,
    comments = 0,
    reposts = 0,
    shares = 0,
    loggedByMe,
    canUpVote,
    canRepost,
    currentStatusProgress,
    totalNumberOfStatus,
    handleRefreshNewsFeed,
    petition,
    announcement,
    updateFeed,
  } = props;

  const isReport = !(petition || announcement);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [commentsCount, setCommentsCount] = useState(comments);
  const [upvoted, setUpvoted] = useState(!canUpVote);
  const [reposted, setReposted] = useState(!canRepost);
  const [tempReposted, setTempReposted] = useState(false);
  const [shareCount, setShareCount] = useState(shares);
  const { globalState } = useGlobalContext();
  const [limit, setLimit] = useState(256);
  const contentIsHTML = detectHTML(text);
  const [isHTMLExpanded, setIsHTMLExpanded] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => setTempReposted(false), [canRepost]);

  // share menu
  const [openShareMenu, setOpenShareMenu] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpenShareMenu(false));

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleReadMore = () => {
    setLimit(limit + 400);
  };

  const handleDeletePost = () => {
    setIsDeleteLoading(true);
    fetch(`${apiUrl}smartcitymanager/cancelWorkRequest?workRequestId=${id}`, {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }).then(() => {
      setIsDeleteLoading(false);
      updateFeed();
    });
  };

  const handleUpvote = () => {
    if (!loggedByMe && !(petition && upvoted)) {
      // cannot upvote if the post is the user's, and cannot sign a petition more than once
      if (upvoted) unUpVoteWorkRequest(id);
      else upVoteWorkRequest(id);
      setUpvoted(!upvoted);
    }
  };
  const debouncedHandleUpvote = _.debounce(handleUpvote, 200);

  const handleRepost = () => {
    if (!reposted) {
      rePostWorkRequest(id);
      setReposted(true);
      setTempReposted(true);
      handleRefreshNewsFeed();
    }
  };
  const debouncedHandleRepost = _.debounce(handleRepost, 1000);

  const handleShare = () => {
    shareWorkRequest(id);
    setShareCount(Number(shareCount) + 1);
  };
  const debouncedHandleShare = _.debounce(handleShare, 1500);

  return (
    <Container>
      <Line isFirst={isFirst} />

      {isRepost && (
        <ProfileRow repost>
          <Link to={`/social/profile/${repostedUser?.uuid}`}>
            <ProfileImage
              alt="profile"
              src={profileImageUrl(repostedUser?.uuid)}
            />
          </Link>
          <InfoContainer>
            <InfoRow>
              <Link to={`/social/profile/${repostedUser?.uuid}`}>
                <Username>{repostedUser?.fullName}</Username>
              </Link>
              {repostedUser?.verified && (
                <VerifiedIcon src="social/verified-icon.svg" alt="verified" />
              )}
              {repostedUser.smartCityUserRoles.length > 0 && (
                <>
                  <Dot>·</Dot>
                  <GrayText>
                    {_.startCase(
                      _.toLower(repostedUser?.smartCityUserRoles[0].description)
                    )}
                  </GrayText>
                </>
              )}
            </InfoRow>
            <InfoRow>
              <City>{globalState.city?.label}</City>
            </InfoRow>
            <InfoRow>
              <GrayText>
                {moment.unix(rePostDate).tz(moment.tz.guess()).fromNow()}
              </GrayText>
              <Dot>·</Dot>
              <Type>Repost</Type>
            </InfoRow>
          </InfoContainer>
        </ProfileRow>
      )}

      <Reference>
        Reference
        <ReferenceNumber>{refNum}</ReferenceNumber>
      </Reference>

      <ProfileRow>
        <Link to={`/social/profile/${uuid}`}>
          <ProfileImage alt="profile" src={profileImageUrl(uuid)} />
        </Link>
        <InfoContainer>
          <InfoRow>
            <Link to={`/social/profile/${uuid}`}>
              <Username>{username}</Username>
            </Link>
            {verified && (
              <VerifiedIcon src="social/verified-icon.svg" alt="verified" />
            )}
            <Dot>·</Dot>
            <GrayText>{_.startCase(_.toLower(role))}</GrayText>
          </InfoRow>
          <InfoRow>
            {/* removeStartCommasSpaces(location) */}
            <City>
              {location
                .replace(/^[,\s]+|[,\s]+$/g, '')
                .replace(/,[,\s]*,/g, ',')}
            </City>
          </InfoRow>
          <InfoRow>
            <GrayText>
              {moment.unix(timestamp).tz(moment.tz.guess()).fromNow()}
            </GrayText>
            <Dot>·</Dot>
            {wrIcon && !isReport && (
              <TypeIcon
                src={wrTypeIconUrl(wrIcon, globalState.city?.value)}
                alt="type"
              />
            )}
            {/* placeholder until Derek add parentWRtype on report WRs */}
            <Type>{isReport ? 'Report' : type}</Type>
          </InfoRow>
        </InfoContainer>
        {/* <StatusContainer>
          <StatusBarContainer>
            <StatusBar />
            <StatusBar percent={(currentStatusProgress * 100) / totalNumberOfStatus} />
          </StatusBarContainer>
          <p>{status}</p>
        </StatusContainer> */}
        {globalState.city.value === 663 &&
          globalState.user.smartCityUserRoles.map((r) => r.id).includes(6) && (
            <>
              {isDeleteLoading ? (
                <Box ml="auto" mr="0">
                  <SmallLoader />
                </Box>
              ) : (
                <Button
                  onClick={handleDeletePost}
                  variant="outlined"
                  height="fit-content"
                  fontSize="12px"
                  ml="auto"
                >
                  DELETE
                </Button>
              )}
            </>
          )}
      </ProfileRow>

      <Title>{title}</Title>
      {contentIsHTML ? (
        <>
          <div
            css={css`
              position: relative;
            `}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: text }}
              css={css`
                margin-top: 10px;
                height: fit-content;
                ${!isHTMLExpanded &&
                css`
                  max-height: 220px;
                `}
                transition: all 0.3s ease-in-out;
                overflow: hidden;
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p,
                li,
                strong {
                  margin: 0;
                }
                p,
                li,
                strong {
                  font-family: 'Roboto';
                  font-size: 14px;
                }
              `}
            />
            <div
              css={css`
                ${isHTMLExpanded &&
                css`
                  opacity: 0;
                  display: none;
                `}
                transition: all 0.3s ease-in-out;
                height: 50px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: linear-gradient(rgba(0, 0, 0, 0) 0%, white 100%);
              `}
            />
          </div>
          <div
            css={css`
              margin-top: 10px;
            `}
          >
            <Readmore
              onClick={() => setIsHTMLExpanded((expanded) => !expanded)}
            >
              {isHTMLExpanded ? 'Read less' : 'Read more'}
            </Readmore>
          </div>
        </>
      ) : (
        <>
          <Text>
            {text.slice(0, limit)}
            {text.length > limit && '...'}
          </Text>

          {text.length > limit && (
            <Readmore onClick={handleReadMore}>Read more</Readmore>
          )}
        </>
      )}

      {images?.length > 0 && (
        <ImagesContainer>
          {images.map((src, index) => (
            <PreviewImage
              len={images.length}
              src={imageUrl(src, globalState.city?.attributes?.subscriberId)}
              onClick={() => openImageViewer(index)}
            >
              {images.length > 3 && index === 2 && `+${images.length - 3}`}
            </PreviewImage>
          ))}
        </ImagesContainer>
      )}

      <ActionRow>
        <ActionButton onClick={debouncedHandleUpvote}>
          <ActionIcon
            active={upvoted}
            src={
              petition ? 'social/petition-icon.svg' : 'social/upvote-icon.svg'
            }
            alt="upvote"
          />
          <ActionText>
            {upvoted
              ? numberFormatter(Number(upvotes) + 1)
              : numberFormatter(upvotes)}
          </ActionText>
          <ActionText mobileHide>
            &nbsp;
            {petition ? 'signatures' : 'upvotes'}
          </ActionText>
        </ActionButton>
        <ActionButton onClick={() => setCommentsOpen(!commentsOpen)}>
          <ActionIcon src="social/comment-icon.svg" alt="comment" />
          <ActionText>{numberFormatter(commentsCount)}</ActionText>
          <ActionText mobileHide>&nbsp;comments</ActionText>
        </ActionButton>
        <ActionButton onClick={debouncedHandleRepost}>
          <ActionIcon
            active={reposted}
            src="social/repost-icon.svg"
            alt="repost"
          />
          <ActionText>
            {tempReposted
              ? numberFormatter(Number(reposts) + 1)
              : numberFormatter(Number(reposts))}
          </ActionText>
          <ActionText mobileHide>&nbsp;reposts</ActionText>
        </ActionButton>
        <div ref={ref} style={{ position: 'relative' }}>
          <ActionButton onClick={() => setOpenShareMenu(!openShareMenu)}>
            <ActionIcon src="social/share-icon.svg" alt="share" />
            <ActionText>{numberFormatter(shareCount)}</ActionText>
            <ActionText mobileHide>&nbsp;shares</ActionText>
          </ActionButton>
          <ShareMenu
            postType="post"
            postId={id}
            cityId={globalState.city?.value}
            open={openShareMenu}
            handleShare={debouncedHandleShare}
          />
        </div>
      </ActionRow>

      {commentsOpen && (
        <CommentSection
          workRequestId={id}
          handlePostComment={() => setCommentsCount(commentsCount + 1)}
        />
      )}

      {isViewerOpen && (
        <ImageViewer
          src={images.map((image) =>
            imageUrl(image, globalState.city?.attributes?.subscriberId)
          )}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
        />
      )}
    </Container>
  );
};

export const AlertPost = ({
  id,
  isFirst,
  profileImg,
  verified = true,
  username,
  address,
  createdDate,
  plannedStart,
  plannedFinish,
  type,
  title,
  text,
  images,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { globalState } = useGlobalContext();

  // share menu
  const [openShareMenu, setOpenShareMenu] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpenShareMenu(false));

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  /*
    Expected time

    If start and finish today: "Expected 14:00 to 17:00"
    If start today and no finish: "To happen through the day"
    If start is past and no finish: "Happened an hour ago"
    If start today and finish in future: "Expected to finish "
    If start is future: "To happen in two days"
  */
  const expectedTimeString = (start, finish) => {
    const startDate = moment.unix(start).tz(moment.tz.guess());
    const finishDate = moment.unix(finish).tz(moment.tz.guess());

    // if start is in the past
    if (startDate.isBefore(new Date(), 'day')) {
      return `Happened ${startDate.fromNow()}`;
    }

    // if start is in the past, and finish is in future
    if (
      startDate.isBefore(new Date(), 'day') &&
      finishDate.isAfter(new Date(), 'day')
    ) {
      return `Expected to finish ${finishDate.fromNow()}`;
    }

    // if start and finish are today
    if (
      startDate.isSame(new Date(), 'day') &&
      finishDate.isSame(new Date(), 'day')
    ) {
      return `Expected ${startDate.format('HH:mm')} to ${finishDate.format(
        'HH:mm'
      )}`;
    }

    // if start is today and no finish
    if (startDate.isSame(new Date(), 'day')) {
      return 'To happen through the day';
    }

    // if start and finish is in future
    if (startDate.isAfter(new Date(), 'day')) {
      return `To happen ${startDate.format('D MMM YYYY')}`;
    }

    return 'No expected time';
  };

  // // reverse geocode latlng to address
  // useEffect(() => {
  //   async function fetchAddress() {
  //     const res = await reverseGeocode(lat, lng);
  //     console.log(res);
  //     setAddress(res?.results[0]?.formatted_address || city?.label);
  //   }
  //   fetchAddress();
  // }, []);

  return (
    <Container>
      <Line isFirst={isFirst} />
      <Reference>{`${type} Alert`}</Reference>

      <ProfileRow>
        <Link to="/social/profile">
          <ProfileImage alt="profile" src={profileImg} />
        </Link>
        <InfoContainer>
          <InfoRow>
            <Link to="/social/profile">
              <Username>{username}</Username>
            </Link>
            {verified && (
              <VerifiedIcon src="social/verified-icon.svg" alt="verified" />
            )}
          </InfoRow>
          <InfoRow>
            <City>
              {address
                .replace(/^[,\s]+|[,\s]+$/g, '')
                .replace(/,[,\s]*,/g, ',')}
            </City>
          </InfoRow>
          <InfoRow>
            <GrayText>
              {moment.unix(createdDate).tz(moment.tz.guess()).fromNow()}
            </GrayText>
            <Dot>·</Dot>
            <Type>{expectedTimeString(plannedStart, plannedFinish)}</Type>
          </InfoRow>
        </InfoContainer>
      </ProfileRow>

      <Title>{title}</Title>
      <Text>{text}</Text>

      {images?.length > 0 && (
        <ImagesContainer>
          {images.map((src, index) => (
            <PreviewImage
              len={images.length}
              src={imageUrl(src, globalState.city?.attributes?.subscriberId)}
              onClick={() => openImageViewer(index)}
            >
              {images.length > 3 && index === 2 && `+${images.length - 3}`}
            </PreviewImage>
          ))}
        </ImagesContainer>
      )}

      <ActionRow oneItem>
        <div ref={ref} style={{ position: 'relative' }}>
          <ActionButton onClick={() => setOpenShareMenu(!openShareMenu)}>
            <ActionIcon src="social/share-icon.svg" alt="share" />
            <ActionText>Share alert</ActionText>
          </ActionButton>
          <ShareMenu
            postType="alert"
            postId={id}
            cityId={globalState.city?.value}
            open={openShareMenu}
            handleShare={() => {}}
          />
        </div>
      </ActionRow>

      {isViewerOpen && (
        <ImageViewer
          src={images.map((image) =>
            imageUrl(image, globalState.city?.attributes?.subscriberId)
          )}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
        />
      )}
    </Container>
  );
};
