import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { Image } from 'components/elements';
import theme from 'theme';
import { css } from '@emotion/react';
import { shortenWithEllipses } from 'functions/address-utils';
import { reviews, Review } from './reviews';

const MAX_REVIEW_LENGTH = 220;

const ReviewContent = ({ review }: { review: Review }) => (
  <Flex
    flexDirection="column"
    justifyContent="flex-start"
    p="20px"
    backgroundColor="white"
    borderRadius={theme.borderRadius.medium}
    mobileCss={css`
      min-width: 300px;
    `}
    tabletCss={css`
      min-width: 300px;
    `}
  >
    <Text fontWeight="bold">{review.reviewer}</Text>
    <Text color={theme.colors.lightGrey}>{review.date}</Text>
    <Text mt="15px">
      {shortenWithEllipses(review.review, MAX_REVIEW_LENGTH)}
    </Text>
    <Flex justifyContent="flex-end" mt="auto">
      <Image
        source="new-homepage/5-stars.svg"
        alt="rating"
        p="5px"
        backgroundColor={theme.colors.selectionPrimary}
        borderRadius="8px"
      />
    </Flex>
  </Flex>
);

const ReviewSection = () => (
  <Flex
    width="100%"
    py="3%"
    backgroundColor="#FCF0E7"
    justifyContent="center"
    flexDirection="column"
    mobileCss={css`
      padding: 8% 0;
    `}
  >
    <Text
      textAlign="center"
      fontSize="35px"
      fontWeight="bold"
      mb="20px"
      mobileCss={css`
        font-size: 25px;
      `}
    >
      What our community has to say
    </Text>
    <Grid
      gridTemplateColumns="repeat(4, 1fr)"
      gridColumnGap="14px"
      px="15%"
      hideScrollBar
      overrideCss={css`
        @media ${theme.mediaQueries.tabletDown} {
          padding: 0;
          overflow-x: scroll;
          display: flex;
        }
      `}
    >
      <Box hideForDesktop width="10px" />
      {reviews.map((review: Review) => (
        <ReviewContent review={review} />
      ))}
    </Grid>
  </Flex>
);

export default ReviewSection;
