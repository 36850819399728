import React from 'react';

import { Modal } from 'components/social';
import {
  TabRow,
  Tab,
  ScrollContainer,
} from 'components/inthecity/lottoModal/lottoModal.styled';
import { SelectButton } from 'components/social/createpost/createpost.styled';

const ProductSelectionModal = (props) => {
  // props
  const {
    show,
    handleClose,
    handleChangeSubcategory,
    handleChangeCategory,
    category,
    provider,
  } = props;

  return (
    <Modal
      title={provider}
      show={show}
      handleClose={handleClose}
    >
      <TabRow>
        <Tab
          active={category === 'Airtime'}
          onClick={() => handleChangeCategory('Airtime')}
        >
          Airtime
        </Tab>
        <Tab
          active={category === 'Data'}
          onClick={() => handleChangeCategory('Data')}
        >
          Data
        </Tab>
      </TabRow>

      <ScrollContainer>
        {category === 'Airtime' && (
          <>
            <SelectButton
              onClick={() => handleChangeSubcategory('Direct Topup')}
            >
              <p>Own Amount</p>
            </SelectButton>
            <SelectButton
              onClick={() => handleChangeSubcategory('PIN On Receipt')}
            >
              <p>PIN On Receipt Bundles</p>
            </SelectButton>
          </>
        )}
        {category === 'Data' && (
          <>
            <SelectButton
              onClick={() => handleChangeSubcategory('Direct Topup')}
            >
              <p>Direct Topup Bundles</p>
            </SelectButton>
            <SelectButton
              onClick={() => handleChangeSubcategory('PIN On Receipt')}
            >
              <p>PIN On Receipt Bundles</p>
            </SelectButton>
          </>
        )}
      </ScrollContainer>
    </Modal>
  );
};

export default ProductSelectionModal;
