import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { GenericDropdown } from './navbar.styled';

export const UnreadDot = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  background: #DF6E21 0% 0% no-repeat padding-box;
  opacity: ${({ show }) => (show ? '1' : '0')};
  border-radius: 50%;
  top: 22px;
  left: -10px;
  @media only screen and (min-width: 1150px) {
    width: 9px;
    height: 9px;
    top: 37px;
    left: -14px;
  }
`;

export const Button = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: 24px;
  @media only screen and (min-width: 1150px) {
    margin-left: 50px;
  }
`;

export const Dropdown = styled(GenericDropdown)`
  z-index: 10;
  position: absolute;
  border-radius: 5px;
  padding: 27px 22px;
  left: 14px;
  right: 14px;
  top: 51px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: 70vh;
  @media only screen and (min-width: 1150px) {
    left: unset;
    top: 65px;
    right: 13rem;
    border-radius: 9px;
    padding: 40px;
    width: 691px;
    min-height: 300px;
    max-height: 500px;
  }
`;

export const ItemContainer = styled(Link)`
  position: relative;
  width: 100%;
  display: flex;
  &:not(:last-child) {
    margin-bottom: 16px;
    @media only screen and (min-width: 1150px) {
      margin-bottom: 30px;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-left: 12px;
  height: calc(48px + 16px);
  padding-bottom: 16px;
  border-bottom: 2px solid hsla(210.0000000000001, 13.793103448275856%, 88.62745098039215%, 0.46);
  @media only screen and (min-width: 1150px) {
    margin-left: 24px;
    height: calc(80px + 24px);
    padding-bottom: 24px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Dot = styled.p`
  font: normal normal 500 10px/13px Roboto;
  padding: 0 5px;
  color: #B2BCC1;
  margin: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/19px Roboto;
    padding: 0 9px;
  }
`;

export const Category = styled.p`
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #B2BCC1;
  margin: 0;
  @media only screen and (min-width: 1150px) {
    font: normal normal normal 16px/19px Roboto;
  }
`;

export const Time = styled.p`
  font: normal normal normal 10px/12px Roboto;
  color: #B2BCC1;
  margin-left: auto;
  @media only screen and (min-width: 1150px) {
    font: normal normal normal 16px/19px Roboto;
  }
`;

export const Username = styled.p`
  font: normal normal normal 12px/14px Roboto;
  letter-spacing: 0px;
  color: #0A0918;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 14px;
  @media only screen and (min-width: 1150px) {
    font: normal normal normal 20px/24px Roboto;
    height: 24px;
  }
`;

export const Event = styled.p`
  font: normal normal normal 10px/12px Roboto;
  letter-spacing: 0px;
  color: #DF6E21;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media only screen and (min-width: 1150px) {
    font: normal normal normal 16px/19px Roboto;
  }
`;

export const Count = styled.div`
  user-select: none;
  position: absolute;
  top: -5px;
  left: -5px;
  border-radius: 50%;
  background: red;
  color: white;
  text-align: center;
  height: 14px;
  width: 14px;
  font: normal normal 600 8px/15px Roboto;
  @media only screen and (min-width: 800px) {
    height: 18px;
    width: 18px;
    font: normal normal 600 10px/18px Roboto;
  }
`;

export const NotFoundContainer = styled.div`
  text-align: center;
  color: #B2BCC1;
  margin: 25px 70px;
  h2 {
    font: normal normal 500 16px/19px Roboto;
  }
  p {
    font: normal normal normal 10px/12px Roboto;
  }
  @media only screen and (min-width: 800px) {
    margin: 50px 130px;
    h2 {
      font: normal normal 500 20px/26px Roboto;
    }
    p {
      font: normal normal normal 16px/19px Roboto;
    }
  }
`;
