import styled from 'styled-components';

export const PageContainer = styled.div`
  /* min-height: 100%; */
  /* max-height: 100%;  */
  width: 100%;
  overflow-x: hidden;
`;

export const TopRow = styled.div`
  margin-top: 15px;
  position: relative;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  align-items: flex-start;
  &::after {
    flex: 1;
    content: '';
  }
  @media only screen and (min-width: 800px) {
    margin-top: 30px;
    padding: 0 50px;
    flex-direction: row;
    align-items: center;
  }

  @media only screen and (min-width: 1400px) {
    padding: 0 100px;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media only screen and (min-width: 800px) {
    padding: 0 50px;
  }
  @media only screen and (min-width: 1400px) {
    padding: 0 100px;
  }
`;

export const FilterColumn = styled.div`
  display: none;
  @media only screen and (min-width: 800px) {
    display: block;
    margin-top: 30px;
    max-width: 350px;
    min-width: 260px;
    flex: 1;
  }
`;

export const FeedColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 10px;
  width: 100%;
  @media only screen and (min-width: 800px) {
    margin: 30px auto;
    padding: 0 60px;
    max-width: 750px;
    min-width: 700px;
    flex: 1;
  }
`;

export const ConnectionsColumn = styled.div`
  display: none;
  @media only screen and (min-width: 800px) {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    max-width: 450px;
    min-width: 380px;
    flex: 1;
  }
`;

export const FeedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  margin-bottom: 12px;
  h2 {
    font: normal normal 500 16px/21px Roboto;
    color: #333333;
    margin: 0;
    padding: 0;
  }
  img {
    cursor: pointer;
    width: 18px;
    margin: 0;
    padding: 0;
  }
  @media only screen and (min-width: 800px) {
    border-top: 2px solid #b2bcc13a;
    padding-top: 22px;
    margin-bottom: 22px;
    h2 {
      font: normal normal 500 16px/21px Roboto;
    }
    img {
      display: none;
    }
  }
`;

export const Feed = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageRow = styled.div`
  display: flex;
  flex-direction: row;
  /* @media only screen and (min-width: 700px) {
    display: none;
  } */
`;

export const PageCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2vw;
  /* @media only screen and (min-width: 700px) {
    display: none;
  } */
  width: ${({ width = '100%' }) => width};
  ${({ hideMobile = false }) => hideMobile && `
    @media only screen and (max-width: 700px) {
      display: none;
    }
  `}
`;

export const NotFoundContainer = styled.div`
  text-align: center;
  color: #B2BCC1;
  margin: 25px 70px;
  h2 {
    font: normal normal 500 16px/19px Roboto;
  }
  p {
    font: normal normal normal 10px/12px Roboto;
  }
  @media only screen and (min-width: 800px) {
    margin: 50px 130px;
    h2 {
      font: normal normal 500 20px/26px Roboto;
    }
    p {
      font: normal normal normal 16px/19px Roboto;
    }
  }
`;

export const Loadmore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 16px/21px Roboto;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  background: #F6F7F8;
  color: #B2BCC1;
  height: 45px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;
