import { Image } from 'components/elements';
import Modal, { ModalProps } from 'components/elements/modal';
import { Box, Flex, Grid } from 'components/layout';
import { getResourceProfilePic } from 'functions/private-services';
import { ResourceReview, SuggestedResource } from 'types/types';
import { Text } from 'components/typography';
import theme from 'theme';
import StarRating from 'components/star-rating';
import { css } from '@emotion/react';

const ReviewBlock = ({ review }: { review: ResourceReview }) => (
  <Box mt="40px">
    <Text
      textAlign="left"
      fontSize="14px"
      fontWeight={600}
      color={theme.colors.darkGrey}
    >
      {review.fullName}
    </Text>
    <StarRating rating={{ starRating: review.starRating }} />
    <Text mt="15px">{review.comment}</Text>
  </Box>
);

const ResourceDetailsModal = ({
  resource,
  ...modalProps
}: { resource: SuggestedResource } & ModalProps) => (
  <Modal {...modalProps} slideFromBottomMobile title="Service Provider Profile">
    <Flex
      mobileCss={css`
        overflow-y: scroll;
      `}
    >
      <Grid
        maxWidth="850px"
        gridRowGap="25px"
        pt="20px"
        desktopCss={css`
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 30px;
        `}
      >
        <Box
          desktopCss={css`
            border-right: 1px solid ${theme.colors.lightGrey};
            padding-right: 20px;
          `}
        >
          <Flex alignItems="center" width="100%" justifyContent="center">
            <Image
              source={getResourceProfilePic(resource.profilePic)}
              alt="Profile"
              height="100px"
              width="100px"
              borderRadius="50%"
              objectFit="cover"
              mr="40px"
            />
            <Box>
              <Text>{resource.fullName}</Text>
              <StarRating rating={resource.rating} />
            </Box>
          </Flex>
          <Grid gridTemplateColumns="1fr 1fr" gridColumnGap="20px" mt="10px">
            <Box width="100%">
              <Text
                mt="15px"
                textAlign="center"
                fontSize="20px"
                fontWeight={600}
              >
                {resource.jobsCompleted}
              </Text>
              <Text
                mt="5px"
                color={theme.colors.lightGrey}
                textAlign="center"
                fontSize="14px"
              >
                Jobs Completed
              </Text>
            </Box>
            <Box width="100%">
              <Text
                mt="15px"
                textAlign="center"
                fontSize="20px"
                fontWeight={600}
              >
                {resource.reviewCount}
              </Text>
              <Text
                mt="5px"
                color={theme.colors.lightGrey}
                textAlign="center"
                fontSize="14px"
              >
                Reviews received
              </Text>
            </Box>
          </Grid>
          <Text mt="30px" textAlign="left" fontSize="16px" fontWeight={600}>
            About me
          </Text>
          <Text
            mt="10px"
            textAlign="left"
            fontSize="14px"
            color={theme.colors.darkGrey}
          >
            {resource.description}
          </Text>
        </Box>
        <Box>
          <Text mt="30px" textAlign="left" fontSize="16px" fontWeight={600}>
            Reviews
          </Text>
          <Flex
            flexDirection="column"
            desktopCss={css`
              overflow-y: scroll;
              height: 250px;
            `}
          >
            {resource.reviews.map((review) => (
              <ReviewBlock review={review} />
            ))}
          </Flex>
        </Box>
      </Grid>
    </Flex>
  </Modal>
);

export default ResourceDetailsModal;
