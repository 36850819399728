import { GMSurvey, GMSurveyCategory } from 'types/types';

const CATEGORY_QUESTION_LIMIT = 2;

// Define a function to split a category into chunks of three questions each
export const splitCategory = (category: GMSurveyCategory) => {
  const chunks = [];
  for (let i = 0; i < category.questions.length; i += CATEGORY_QUESTION_LIMIT) {
    chunks.push({
      id: category.id,
      description: category.description,
      questions: category.questions.slice(i, i + CATEGORY_QUESTION_LIMIT),
    });
  }
  return chunks;
};

// Define a function to flatten an array of arrays into a single array
export const flattenArray = (arr: any[]) =>
  arr.reduce((acc, val) => acc.concat(val), []);

export const splitSurveyCategories = (cats: GMSurveyCategory[]) => {
  const categorys = [...cats];

  // Duplicate any categories that contain more than three questions
  const duplicatedCategories = flattenArray(
    categorys.map((category) => {
      if (category.questions.length > CATEGORY_QUESTION_LIMIT) {
        return splitCategory(category);
      }
      return [category];
    })
  );

  // Update the original categorys array with the duplicated categories
  categorys.length = 0;
  categorys.push(...duplicatedCategories);
  return categorys;
};

export const rebuildCategories = (categories: GMSurveyCategory[]) =>
  categories.reduce((acc: any, curr: any) => {
    const category = acc.find((c: any) => c.id === curr.id);
    if (!category) {
      acc.push(curr);
    } else {
      category.questions = category.questions.concat(curr.questions);
    }
    return acc;
  }, []);

export const checkAllQuestionsAnswered = (surveyDetails: GMSurvey) => {
  const allQuestionsAnswered = surveyDetails.categorys.every((category) =>
    category.questions.every((question) => question.answer > 0)
  );
  return allQuestionsAnswered;
};

export const updateSurveyAnswer = (
  currentCategoryIndex: number,
  questionId: number,
  answer: number,
  surveyDetails: GMSurvey
) => {
  const newSurveyDetails = { ...surveyDetails };
  const currentQuestionIndex = newSurveyDetails.categorys[
    currentCategoryIndex
  ].questions.findIndex((q) => q.id === questionId);
  newSurveyDetails.categorys[currentCategoryIndex].questions[
    currentQuestionIndex
  ].answer = answer;
  return newSurveyDetails;
};

export const updateSurveyComment = (
  currentCategoryIndex: number,
  questionId: number,
  comment: string,
  surveyDetails: GMSurvey
) => {
  const newSurveyDetails = { ...surveyDetails };
  const currentQuestionIndex = newSurveyDetails.categorys[
    currentCategoryIndex
  ].questions.findIndex((q) => q.id === questionId);
  newSurveyDetails.categorys[currentCategoryIndex].questions[
    currentQuestionIndex
  ].comment = comment;
  return newSurveyDetails;
};
