import 'styles/map.scss';
import 'styles/mapMarkerViewModal.scss';
import React, { useRef, useState } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { ICON_IMAGE_URL } from 'constants/app';
import ReactDOMServer from 'react-dom/server';
import _ from 'lodash';

export default ({
  workRequestT,
  theme,
  map,
  thisOfOverlay,
  serviceType,
  currentCityId,
}) => {
  const position = {
    lat: workRequestT.lat,
    lng: workRequestT.lng,
  };
  const [workRequest] = useState(_.cloneDeep(workRequestT));
  const ref = useRef(null);

  // New icon mode
  let iconTemp = '';
  if (serviceType === 'public-transport') {
    iconTemp = 'my-municipality/go-george.png';
  } else if (workRequest.customIcon) {
    iconTemp = `${ICON_IMAGE_URL}map_${workRequest.customIcon.split('.')[0]}_${
      workRequest.loggedByMe ? 'loggedbyme' : 'loggedbyother'
    }_${theme}.${workRequest.customIcon.split('.')[1]}?cityId=${currentCityId}`;
  } else {
    iconTemp = `${ICON_IMAGE_URL}map_personal_emergency_icon_light.png?cityId=${currentCityId}`;
  }
  let iconTempAd = '';
  if (workRequest.privateFlag) {
    iconTempAd = 'map/mapMarker/lock.svg';
  } else if (workRequest.upVotedByMe && `${serviceType}` === 'municipal') {
    iconTempAd = 'map/mapMarker/upvote.svg';
  } else if (workRequest.upVotedByMe && `${serviceType}` === 'petitions') {
    iconTempAd = 'map/mapMarker/signature.svg';
  }

  const icon = L.divIcon({
    iconAnchor: serviceType === 'public-transport' ? [10, 10] : [50, 60],
    popupAnchor: [-24, -70],
    html: ReactDOMServer.renderToString(
      <div>
        {iconTempAd !== '' ? (
          <div className="mapMarkerIconAdContainer">
            <img
              className="mapMarkerIconAd"
              src={iconTempAd}
              alt={iconTempAd}
            />
          </div>
        ) : null}
        <img
          className="mapMarkerIcon"
          src={iconTemp}
          alt={iconTemp}
          style={
            serviceType === 'public-transport'
              ? {
                  width: '18px',
                  height: '18px',
                }
              : null
          }
        />
      </div>
    ),
  });

  return (
    <Marker
      {...{
        position,
        ref,
        icon,
      }}
      eventHandlers={{
        click: () => {
          map.flyTo({ lat: workRequest.lat, lng: workRequest.lng }, 18, 2);
          console.log('showMarkerViewModalWR', workRequest);
          thisOfOverlay.setState({
            showMarkerViewModalWR: workRequest,
            showMarkerView: true,
          });
        },
      }}
      key={`${workRequest.id}-marker`}
      className="mapMarker"
    />
  );
};
