import { css } from '@emotion/react';
import theme from 'theme';
import { Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { Button, Image } from 'components/elements';

import { AwardBlock } from 'components/private-services/campaign/hero/hero';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLinksEnum } from 'types/routing';
import { useGlobalContext } from 'contexts/global';
import {
  HomePageEvents,
  PrivateServicesEvents,
  logFirebaseEvent,
} from 'functions/firebase';

const SCALE_ANIMATION_DURATION = 0.2;

const HeroHomePage = ({
  scrollToPrivateServices,
  showPsModal,
  isLoggedIn,
}: {
  scrollToPrivateServices: () => void;
  isLoggedIn: boolean;
  showPsModal: () => void;
}) => {
  const { setShowLoginModal } = useGlobalContext();
  const [bookAServiceHover, setBookAServiceHover] = useState(false);
  const [reportAConcernHover, setReportAConcernHover] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Flex
        height="100%"
        position="relative"
        background="transparent linear-gradient(120deg, #EE4036 33%, #E6632B 66%, #F16522 100%) 0% 0% no-repeat padding-box;"
        px="100px"
        pt="4%"
        overrideCss={css`
          @media ${theme.mediaQueries.tabletDown} {
            padding: 80px;
          }
          @media ${theme.mediaQueries.mobileOnly} {
            padding: 35px;
            padding-top: 10%;
            height: 650px;
            background: transparent
              linear-gradient(180deg, #ee4036 33%, #e6632b 66%, #f16522 100%) 0%
              0% no-repeat padding-box;
          }
        `}
        overflow="hidden"
      >
        <Grid
          gridTemplateColumns="1fr 1fr"
          overrideCss={css`
            @media ${theme.mediaQueries.tabletDown} {
              grid-template-columns: 1fr;
            }
          `}
        >
          <Flex
            zIndex="1"
            pb="70px"
            flexDirection="column"
            overrideCss={css`
              @media ${theme.mediaQueries.tabletDown} {
                max-width: 100%;
              }
              @media ${theme.mediaQueries.desktopOnly} {
                margin-left: 40px;
              }
            `}
            mobileCss={css`
              padding-bottom: 10px;
            `}
            tabletCss={css`
              padding-bottom: 0px;
            `}
          >
            <Text
              fontSize="60px"
              color="white"
              fontWeight="bold"
              p={0}
              m={0}
              mobileCss={css`
                font-size: 34px;
              `}
              tabletCss={css`
                font-size: 40px;
              `}
            >
              Improving your home & community has never been easier
            </Text>
            <Text
              color="white"
              p={0}
              m={0}
              mt="3%"
              fontSize="24px"
              overrideCss={css`
                @media ${theme.mediaQueries.mobileOnly} {
                  font-size: 16px;
                }
              `}
            >
              Report concerns to your local municipality OR book a Smart Service
              Provider for your home with My Smart City.
            </Text>
            <Flex
              backgroundColor="rgba(200, 200, 200, 0.9)"
              boxShadow="0 0 10px #0000001a;"
              width="fit-content"
              p="6px"
              px="12px"
              borderRadius="8px"
              alignItems="center"
              alignContent="center"
              mt="5%"
              overrideCss={css`
                @media ${theme.mediaQueries.mobileOnly} {
                  padding: 7px;
                }
              `}
            >
              <Text
                fontFamily="Roboto"
                p={0}
                m={0}
                fontWeight="bold"
                fontSize="13px"
                overrideCss={css`
                  @media ${theme.mediaQueries.mobileOnly} {
                    font-size: 10px;
                  }
                `}
              >
                Average app rating (4.5)
              </Text>
              <Image
                alt="app-rating"
                source="new-homepage/app-stars.svg"
                height="40px"
                ml="15px"
                overrideCss={css`
                  @media ${theme.mediaQueries.mobileOnly} {
                    height: 25px;
                  }
                `}
              />
            </Flex>
            <Flex>
              <Button
                onClick={() => {
                  if (isLoggedIn) {
                    navigate(PageLinksEnum.Map);
                    logFirebaseEvent(HomePageEvents.LogReport);
                  } else {
                    setShowLoginModal(true);
                  }
                }}
                boxShadow="0 0 10px #0000001a;"
                mobileCss={css`
                  padding: 10px;
                  width: 100%;
                  margin-right: 10px;
                `}
                mt="5%"
                width="fit-content"
                borderRadius="8px"
                mr="25px"
                id="report-a-concern-cta"
                p="20px"
                backgroundColor="black"
                onHover={(active) => setReportAConcernHover(active)}
                overrideCss={css`
                  transition: transform ${SCALE_ANIMATION_DURATION}s;
                  ${reportAConcernHover &&
                  css`
                    transform: scale(1.1);
                    background-color: white;
                  `}
                `}
              >
                <Text
                  fontSize="20px"
                  mobileCss={css`
                    font-size: 16px;
                  `}
                  color="white"
                  fontWeight="bold"
                  overrideCss={css`
                    ${reportAConcernHover &&
                    css`
                      color: #ee4036;
                    `}
                  `}
                >
                  Log a Report
                </Text>
              </Button>
              <Button
                hideForMobile
                onClick={() => {
                  if (!isLoggedIn) {
                    setShowLoginModal(true);
                  } else {
                    scrollToPrivateServices();
                    logFirebaseEvent(PrivateServicesEvents.BookAService);
                  }
                }}
                boxShadow="0 0 10px #0000001a;"
                mt="5%"
                width="fit-content"
                borderRadius="8px"
                id="book-a-service-cta"
                p="20px"
                backgroundColor="black"
                mobileCss={css`
                  width: 100%;
                  padding: 10px;
                `}
                onHover={(active) => setBookAServiceHover(active)}
                overrideCss={css`
                  transition: transform ${SCALE_ANIMATION_DURATION}s;
                  ${bookAServiceHover &&
                  css`
                    transform: scale(1.1);
                    background-color: white;
                  `}
                `}
              >
                <Text
                  fontSize="20px"
                  color="white"
                  fontWeight="bold"
                  mobileCss={css`
                    font-size: 16px;
                  `}
                  overrideCss={css`
                    ${bookAServiceHover &&
                    css`
                      color: #ee4036;
                    `}
                  `}
                >
                  Book a Service
                </Text>
              </Button>
              <Button
                hideForDesktop
                hideForTablet
                onClick={() => {
                  if (!isLoggedIn) {
                    setShowLoginModal(true);
                  } else {
                    showPsModal();
                    logFirebaseEvent(PrivateServicesEvents.BookAService);
                  }
                }}
                boxShadow="0 0 10px #0000001a;"
                mt="5%"
                width="fit-content"
                borderRadius="8px"
                id="book-a-service-cta"
                p="20px"
                backgroundColor="black"
                mobileCss={css`
                  width: 100%;
                  padding: 10px;
                `}
                onHover={(active) => setBookAServiceHover(active)}
                overrideCss={css`
                  transition: transform ${SCALE_ANIMATION_DURATION}s;
                  ${bookAServiceHover &&
                  css`
                    transform: scale(1.1);
                    background-color: white;
                  `}
                `}
              >
                <Text
                  fontSize="20px"
                  color="white"
                  fontWeight="bold"
                  mobileCss={css`
                    font-size: 16px;
                  `}
                  overrideCss={css`
                    ${bookAServiceHover &&
                    css`
                      color: #ee4036;
                    `}
                  `}
                >
                  Book a Service
                </Text>
              </Button>
            </Flex>
          </Flex>
          <Flex
            width="100%"
            height="100%"
            alignItems="flex-end"
            alignContent="flex-end"
          >
            <Image
              source="new-homepage/hero-background.png"
              alt="b"
              position="absolute"
              width="53%"
              bottom="-30%"
              right="1%"
              opacity="0.7"
              mobileCss={css`
                width: 100%;
                bottom: -20%;
                right: -3%;
              `}
              tabletCss={css`
                width: 100%;
                bottom: -20%;
                right: -3%;
                z-index: 0;
                opacity: 0.4;
              `}
            />
            <Button
              hideForTablet
              onClick={() =>
                isLoggedIn
                  ? navigate(PageLinksEnum.Map)
                  : setShowLoginModal(true)
              }
              onHover={(active) => setReportAConcernHover(active)}
              zIndex={0}
              overrideCss={css`
                transition: transform ${SCALE_ANIMATION_DURATION}s;
                ${reportAConcernHover &&
                css`
                  transform: scale(1.1);
                `}
              `}
            >
              <Image
                source="new-homepage/municipal.png"
                alt="municipal"
                objectFit="cover"
                width="100%"
                tabletCss={css`
                  width: 50%;
                `}
              />
            </Button>
            <Button
              hideForTablet
              onClick={scrollToPrivateServices}
              onHover={(active) => setBookAServiceHover(active)}
              ml="-33%"
              zIndex={1}
              overrideCss={css`
                transition: transform ${SCALE_ANIMATION_DURATION}s;
                ${bookAServiceHover &&
                css`
                  transform: scale(1.1);
                `}

                @media ${theme.mediaQueries.mobileOnly} {
                  margin-left: -20%;
                }
              `}
            >
              <Image
                width="110%"
                source="new-homepage/cleaner.png"
                alt="cleaner"
                objectFit="cover"
                tabletCss={css`
                  width: 50%;
                `}
              />
            </Button>
          </Flex>
        </Grid>
      </Flex>
      <Flex
        backgroundColor="#F6F6F9"
        flex="1"
        p="10px"
        flexDirection="column"
        justifyContent="center"
        boxShadow="0px 0px 10px #0000001A"
      >
        <Text
          textAlign="center"
          color="#707070"
          fontWeight="medium"
          fontSize="14"
          hideForTablet
          hideForDesktop
        >
          Award-winning app
        </Text>
        <Flex width="100%" justifyContent="space-around">
          <AwardBlock
            text="Finalist - BCX Digital Innovation Awards"
            imageUrl="privateServices/bcx.svg"
          />
          <AwardBlock
            text="Best Enterprise Solution 2022"
            imageUrl="privateServices/mtnAOTY.svg"
          />
          <AwardBlock
            text="Play Your Part Award Winner"
            imageUrl="privateServices/SaComp.svg"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default HeroHomePage;
