import { MunicipalPageWrapper } from 'components/my-municipality';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GMTender } from 'types/types';
import { Text } from 'components/typography';
import { Box, Flex, Grid } from 'components/layout';
import theme from 'theme';
import { Loader } from 'components/inthecity';
import { Button, TextField } from 'components/elements';
import { SmallLoader } from 'components/private-services/survey';
import ExternalLink from 'components/elements/external-link';
import { capitalize } from '@material-ui/core';
import useQuery from 'hooks/use-query';

const getDocumentLinks = (tender: GMTender) => {
  const documentLinks: { fieldName: string; documentLink: string }[] = [];
  Object.entries(tender['toolset-meta']['supply-chain-fields']).forEach(
    ([fieldName, fieldData]) => {
      if (fieldData.type === 'file' && fieldData.raw) {
        const documentLink = fieldData.raw;
        documentLinks.push({ fieldName, documentLink });
      }
    }
  );
  return documentLinks;
};

const TenderItem = ({ tender }: { tender: GMTender }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Flex boxShadow={theme.elevationShadow} p="16px" flexDirection="column">
      <Grid gridTemplateColumns="1fr 100px" width="100%" gridColumnGap="10px">
        <Text>
          {tender.title.rendered.replace('\\', '').replace('&#8211;', '-')}
        </Text>
        <Button
          color={theme.colors.primary}
          fontWeight={600}
          my="auto"
          onClick={() => setShowMore((s) => !s)}
        >
          {showMore ? 'Show less' : 'Show more'}
        </Button>
      </Grid>
      {showMore && (
        <Grid gridRowGap="10px" width="100%">
          <ExternalLink
            href={tender.link}
            target="__blank"
            color={theme.colors.primary}
            textDecoration="underline"
          >
            View on george.gov.za
          </ExternalLink>
          <Text fontWeight="bold">Document Downloads:</Text>
          {getDocumentLinks(tender).map(({ fieldName, documentLink }) => (
            <ExternalLink
              href={documentLink}
              target="__blank"
              color={theme.colors.primary}
              textDecoration="underline"
            >
              <Flex alignItems="center">
                <Box
                  mr="15px"
                  backgroundColor={theme.colors.primary}
                  borderRadius="50%"
                  p="2px"
                >
                  <img
                    className="profileNavDrop"
                    src="profile/download.svg"
                    alt="Logo"
                    style={{ height: '20px', width: '20px' }}
                  />
                </Box>
                <Text>{capitalize(fieldName.split('-')[0])} Document</Text>
              </Flex>
            </ExternalLink>
          ))}
        </Grid>
      )}
    </Flex>
  );
};

const TendersPage = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [tenders, setTenders] = useState<GMTender[]>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const getQueries = useQuery();
  const query = getQueries.get('query');

  const getTenders = useCallback(async (pageNumber: number) => {
    if (pageNumber === 1) {
      setLoading(true);
    } else {
      !loading && setLoadingMore(true);
    }
    setPage(pageNumber + 1);
    fetch(
      `https://www.george.gov.za/wp-json/wp/v2/supply-chain-categor?filter[category_name]=${query}&per_page=10&page=${pageNumber}&orderby=modified`
    )
      .then((res) => res.json())
      .then((data: GMTender[]) => {
        setTenders((t) => [...t, ...data]);
        if (pageNumber === 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
        setPage(pageNumber + 1);
      })
      .catch((err) => {
        console.log(err);
        if (pageNumber === 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
      });
  }, []);

  useEffect(() => {
    getTenders(page);
  }, []);

  const tendersToDisplay = useMemo(
    () =>
      search === ''
        ? tenders
        : tenders.filter((tender) =>
            tender.title.rendered.toLowerCase().includes(search.toLowerCase())
          ),
    [search, tenders]
  );

  return (
    <MunicipalPageWrapper title={query?.split('-').join(' ').toUpperCase()}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <TextField
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            mb="20px"
          />
          <Grid gridRowGap="20px">
            {tendersToDisplay.length > 0 ? (
              tendersToDisplay.map((tender) => (
                <TenderItem
                  key={`${tender.id}-${tender.date}`}
                  tender={tender}
                />
              ))
            ) : (
              <Text textAlign="center">
                No results on this page, please load more to view more results
              </Text>
            )}
          </Grid>
        </>
      )}
      <Flex width="100%" justifyContent="center" mt="20px">
        {loadingMore ? (
          <SmallLoader />
        ) : (
          <Button onClick={() => getTenders(page)} variant="cta">
            Load more
          </Button>
        )}
      </Flex>
    </MunicipalPageWrapper>
  );
};

export default TendersPage;
