/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSocialContext } from 'contexts/social';
import { useGlobalContext } from 'contexts/global';
import { Heading, Text } from 'components/social/text';
import LocationModal from './locationmodal';
import {
  Container,
  Menu,
  Title,
  Item,
  Icon,
  DropdownContainer,
  LocationButton,
  ClearButton,
  Button,
  DropdownItem,
  DropdownIcon,
  Dropdown,
  Radio,
} from './filter.styled';

export const LocationFilter = () => {
  const [show, setShow] = useState(false);
  const { globalState } = useGlobalContext();


  return (
    <>
      <LocationButton onClick={() => setShow(true)}>
        <Title weight="500">{globalState.city?.label}</Title>
        <DropdownIcon src="social/location-dropdown-icon.svg" alt="dropdown" />
      </LocationButton>
      <LocationModal show={show} handleClose={() => setShow(false)} />
    </>
  );
};

export const FilterMenu = () => {
  const { filters, addFilter } = useSocialContext();
  const [datesOpen, setDatesOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(true);
  const {
    globalState: { city },
  } = useGlobalContext();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date) {
      const epochTimeSeconds = Math.floor(date.getTime() / 1000).toString(); // Convert to seconds epoch
      console.log('Start Date Epoch:', epochTimeSeconds);
      addFilter({ date: '', startDate: epochTimeSeconds })
    };
  }
  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (date) {
      const epochTimeSeconds = Math.floor(date.getTime() / 1000).toString(); // Convert to seconds epoch
      console.log('End Date Epoch:', epochTimeSeconds);
      addFilter({ date: '', endDate: epochTimeSeconds })
    };
  }

  const clearDateRanges = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Menu>
      <Item>
        <Icon src="social/dates-icon.svg" />
        <DropdownContainer>
          <Button onClick={() => setDatesOpen(!datesOpen)}>
            <Title>Date Posted</Title>
            <DropdownIcon
              open={datesOpen}
              src="social/dropdown-icon.svg"
              alt="dropdown"
            />
          </Button>
          <Dropdown open={datesOpen}>
            <DropdownItem onClick={() => {
              addFilter({ date: '' , startDate: '', endDate: ''});
              clearDateRanges();
            }}>
              <Text>All</Text>
              <Radio type="radio" checked={filters.date === ''} />
            </DropdownItem>
            <DropdownItem onClick={() => {
              addFilter({ date: 'today' , startDate: '', endDate: ''});
              clearDateRanges();
            }}>
              <Text>Today</Text>
              <Radio type="radio" checked={filters.date === 'today'} />
            </DropdownItem>
            <DropdownItem onClick={() => {
              addFilter({ date: 'yesterday', startDate: '', endDate: '' });
              clearDateRanges();
            }}>
              <Text>Yesterday</Text>
              <Radio type="radio" checked={filters.date === 'yesterday'} />
            </DropdownItem>
            <DropdownItem onClick={() => {
              addFilter({ date: 'last week', startDate: '', endDate: '' });
              clearDateRanges();
            }}>
              <Text>Last Week</Text>
              <Radio type="radio" checked={filters.date === 'last week'} />
            </DropdownItem>
            <DropdownItem onClick={() => {
              addFilter({ date: 'last 2 weeks' , startDate: '', endDate: ''});
              clearDateRanges();
            }}>
              <Text>Last 2 Weeks</Text>
              <Radio type="radio" checked={filters.date === 'last 2 weeks'} />
            </DropdownItem>
            
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={2} sx={{ width: 250, margin: '10px' }}>
              <div>Date Range:</div>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '1px solid #df7531', }, // at focused state
                    '& .MuiInputLabel-root.Mui-focused': { // This targets the label when the TextField is focused
                      color: '#df7531', // Orange color for the focused label
                    }
                  }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '1px solid #df7531', }, // at focused state
                    '& .MuiInputLabel-root.Mui-focused': { // This targets the label when the TextField is focused
                      color: '#df7531', // Orange color for the focused label
                    }
                  }}
                />
                <ClearButton variant="outlined" onClick={() => {
                  addFilter({ date: '' });
                  clearDateRanges();
                }}>
                  Clear Date Range
                </ClearButton>
              </Stack>
            </LocalizationProvider>

          </Dropdown>

        </DropdownContainer>
      </Item>
      {city.value !== 663 && (
        <Item>
          <Icon src="social/categories-icon.svg" />
          <DropdownContainer>
            <Button onClick={() => setCategoryOpen(!categoryOpen)}>
              <Title>Category</Title>
              <DropdownIcon
                open={categoryOpen}
                src="social/dropdown-icon.svg"
                alt="dropdown"
              />
            </Button>
            <Dropdown open={categoryOpen}>
              <DropdownItem onClick={() => addFilter({ category: '' })}>
                <Text>All</Text>
                <Radio type="radio" checked={filters.category === ''} />
              </DropdownItem>
              <DropdownItem onClick={() => addFilter({ category: 'CITIZEN' })}>
                <Text>Citizens</Text>
                <Radio type="radio" checked={filters.category === 'CITIZEN'} />
              </DropdownItem>
              <DropdownItem onClick={() => addFilter({ category: 'MAYOR' })}>
                <Text>Mayors</Text>
                <Radio type="radio" checked={filters.category === 'MAYOR'} />
              </DropdownItem>
              <DropdownItem
                onClick={() => addFilter({ category: 'WARD_COUNSILLOR' })}
              >
                <Text>Ward Councillor</Text>
                <Radio
                  type="radio"
                  checked={filters.category === 'WARD_COUNSILLOR'}
                />
              </DropdownItem>
            </Dropdown>
          </DropdownContainer>
        </Item>
      )}
    </Menu>
  );
};

const Filter = () => {
  return (
    <Container>
      <Heading>Filter Feed</Heading>
      <FilterMenu />
    </Container>
  );
};

export default Filter;
