import React from 'react';

export const Context = React.createContext();
export const useContext = () => React.useContext(Context);
export class ContextManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.zeroState();
  }

  zeroState = () => ({
    authenticated: false,
    firebaseToken: '',
    firebaseUID: '8e5ee12f-7241-4412-9bcf-702de1740fcc',
    user: null,
  });

  // SETTERS
  setAuth = (authenticated) => this.setState({ authenticated });

  setUser = (user) => this.setState({ user });

  noAuthAndClear = () => this.setState(this.zeroState());

  // RENDER CONTEXT
  render() {
    const {
      setAuth,
      setCallTypes,
      setCustomerTypes,
      setUser,
      noAuthAndClear,
    } = this;
    const { authenticated, user } = this.state;
    const { children } = this.props;

    return (
      <Context.Provider
        value={{
          setAuth,
          setCallTypes,
          setCustomerTypes,
          setUser,
          noAuthAndClear,
          authenticated,
          user,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}
