import React, { useState } from 'react';

const useGoogleAddressFinder = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const findAddress = (query: string, setAddresses: React.Dispatch<any>) => {
    const config = {
      method: 'GET',
      headers,
    };
    setLoading(true);
    console.log('QUERY', query);
    fetch(
      `https://${process.env.REACT_APP_API_BASE}/api/gmaps/place/textsearch/json?region=za&language=en&query=${query}&key=AIzaSyAoMxu7f1QrVK9_xggoZGi4uWKZRuVIZmw`,
      config
    )
      .then((res) => res.json())
      .then((res) => {
        setResponse(res);
        console.log('QUERY RESULT', res.results);
        setAddresses(res.results);
        setLoading(false);
      })
      .catch((err) => setError(err));
  };

  return { loading, response, error, findAddress };
};

export default useGoogleAddressFinder;
