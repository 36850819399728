import styled from 'styled-components';

export const TabRow = styled.div`
  display: flex;
  width: calc(100% + 48px);
  border-bottom: 1px solid #e8ebec;
  margin-bottom: 30px;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  
  @media only screen and (min-width: 800px) {
    border-bottom: 2px solid #e8ebec;
    width: calc(100% + 90px);
    margin-bottom: 45px;
    margin-left: -45px;
    margin-right: -45px;
    padding-left: 45px;
  }
`;

export const Tab = styled.div`
  cursor: pointer;
  padding-bottom: 8px;
  padding-left: 5px;
  padding: right: 5px;
  color: ${(props) => (props.active ? '#DF6E21' : '#0A0918')};
  font: normal normal normal 12px/14px Roboto;
  border-bottom: 1px solid ${(props) => (props.active ? '#DF6E21' : '#e8ebec')};
  margin-bottom: -1px;
  margin-right: 20px;

  @media only screen and (min-width: 800px) {
    padding-bottom: 12px;
    padding-left: 5px;
    padding: right: 5px;
    font: normal normal normal 20px/24px Roboto;
    border-bottom: 2px solid ${(props) => (props.active ? '#DF6E21' : '#e8ebec')};
    margin-bottom: -2px;
    margin-right: 20px;
  }
`;

export const Heading = styled.h2`
  margin: 0;
  padding: 0;
  color: #0A0918;
  font: normal normal 500 12px/14px Roboto;
  margin-bottom: 12px;
  
  @media only screen and (min-width: 800px) {
    font: normal normal 500 16px/19px Roboto;  
    margin-bottom: 16px;
  }
`;

export const AddBoard = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;

  img {
    width: 12px;
    height: 12px;
    margin-right: 15px;
  }
  p {
    margin: 0;
    padding: 0;
    font: normal normal normal 16px/19px Roboto;
    color: #262A34;

    @media only screen and (min-width: 800px) {
      font: normal normal normal 16px/19px Roboto;
    }
  }
`;

export const SelectButton = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  background-color: #FFFFFF 0% 0% no-repeat padding-box;
  background: url('social/forward-icon.svg') 95% 50% no-repeat padding-box;
  background-size: 6px;
  border: 1px solid #DEE2E6;
  border-radius: 2px;
  height: 52px;
  width: 100%;
  color: #0A0918;
  margin-bottom: 15px;
  padding: 0 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0 0 0 3px #DF6E213E;
    border-color: transparent;
  }

  @media only screen and (min-width: 800px) {
    height: 67px;
    padding: 0 30px;
    margin-bottom: 30px;
    border: 2px solid #DEE2E6;
    border-radius: 5px;
    background-size: 12px;
  }
`;

export const BoardButton = styled(SelectButton)`
  background: ${(props) => (props.empty ? 'none' : 'url("social/forward-icon.svg") 95% 50% no-repeat padding-box')};
  margin-bottom: 36px;
  justify-content: space-between;
  
  @media only screen and (min-width: 800px) {
    margin-bottom: 45px;
  }
`;

export const BoardPreview = styled(SelectButton)`
  background: none;
  justify-content: space-between;

  cursor: auto;
  &:hover {
    box-shadow: none;
    border-color: #DEE2E6;
  }
`;

export const ButtonLabel = styled.p`
  color: #0A0918;
  font: normal normal normal 12px/52px Roboto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 10px 0 0;
  padding: 0;

  @media only screen and (min-width: 800px) {
    font: normal normal normal 20px/67px Roboto;
    margin: 0 20px 0 0; 
  }
`;

export const ButtonBall = styled.div`
  width: 30px;
  height: 30px;
  background: ${(props) => {
    if (props.empty) return '#262A340D';
    if (props.number > 3) return '#00A300';
    if (props.number > 1) return '#FAD02C';
    return '#DF6E21';
  }};
  color: #F4F4F4;
  font: normal normal normal 12px/30px Roboto;
  text-align: center;
  border-radius: 50%;

  /* @media only screen and (min-width: 800px) {
  } */
`;

export const NumberSelector = styled.input`
  background-color: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DEE2E6;
  border-radius: 2px;
  height: 52px;
  width: 100%;
  color: #0A0918;
  margin-bottom: 15px;
  padding: 0 20px;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;
  font: normal normal normal 12px/52px Roboto;

  &:hover {
    box-shadow: 0 0 0 3px #DF6E213E;
    border-color: transparent;
  }

  @media only screen and (min-width: 800px) {
    height: 67px;
    padding: 0 30px;
    margin-bottom: 30px;
    border: 2px solid #DEE2E6;
    border-radius: 5px;
    font: normal normal normal 20px/67px Roboto;
  }
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid #DEE2E6;
  border-radius: 2px;
  font: normal normal normal 12px/52px Roboto;
  height: 52px;
  color: #0A0918;
  padding: 0 20px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 15px;
  flex-shrink: 0;
  /* margin-top: 18px; */

  &::placeholder {
    color: #B2BCC1;
    opacity: 1;
  }
  &:hover {
    box-shadow: 0 0 0 3px #DF6E213E;
    border-color: transparent;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #DF6E213E, 0 0 0 1px #DF6E21;
    border-color: transparent;
  }

  ${(props) => (props.readonly && `
    color: #B2BCC1;
    &:hover {
      box-shadow: none;
      border-color: #DEE2E6;
    }
    &:focus {
      box-shadow: none;
      border-color: #DEE2E6;
    }
  `)}

  @media only screen and (min-width: 800px) {
    height: 67px;
    padding: 0 30px;
    margin-bottom: 30px;
    border: 2px solid #DEE2E6;
    border-radius: 5px;
    font: normal normal normal 20px/67px Roboto;
    margin-bottom: 30px;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  flex-shrink: 1;

  width: calc(100% + 8px);
  padding-top: 4px;
  margin-left: -4px;
  margin-right: -4px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 15px;
  
  @media only screen and (min-width: 800px) {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 30px;
  }
`;

export const BottomContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  width: calc(100% + 48px);
  height: 1px;
  background: #e8ebec;
  margin-bottom: 15px;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  
  @media only screen and (min-width: 800px) {
    width: calc(100% + 90px);
    height: 2px;
    background: #e8ebec;
    margin-bottom: 30px;
    margin-left: -45px;
    margin-right: -45px;
    padding-left: 45px;
  }
`;

export const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  margin-bottom: 15px;

  p {
    font: normal normal normal 16px/19px 'Roboto';
    padding: 0;
    margin: 0;
    color: #0A0918;

    &:last-child {
      color: #DF6E21;
      font-weight: 500;
    }
  }
`;

export const Button = styled.div`
  font: normal normal normal 16px/45px 'Roboto';
  text-align: center;
  border-style: none;
  border-radius: 5px;
  margin-top: auto;
  padding: 0;
  width: 100%;
  height: 45px;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;

  ${({ disabled = false }) => (disabled
    ? `
      cursor: not-allowed;
      background: rgba(178, 188, 193, 0.21) 0% 0% no-repeat padding-box;
      color: #B2BCC1;
      &:hover {
        background: rgba(178, 188, 193, 0.21) 0% 0% no-repeat padding-box;
      }`
    : `
      cursor: pointer;
      background: #DF6E21 0% 0% no-repeat padding-box;
      color: #FFFFFF;
      &:hover {
        background: #CB641E 0% 0% no-repeat padding-box;
      }`
  )}

  @media only screen and (min-width: 800px) {
    font: normal normal normal 20px/67px 'Roboto';
    height: 67px;
    margin: 0;
  }
`;

export const RemoveLink = styled.div`
  font: normal normal normal 10px/12px 'Roboto';
  position: absolute;
  right: 0;
  bottom: 16px;
  cursor: pointer;
  color: #DF6E21;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/14px 'Roboto';
    bottom: 20px;
  }
`;

export const BallRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Ball = styled.div`
  user-select: none;
  cursor: pointer;
  width: 43px;
  height: 43px;
  ${(props) => (props.active
    ? ` 
      background: #DF6E21;
      color: #F4F4F4;
    `
    : `
      background: #262A340D;
      color: #0A0918;
    `
  )}
  font: normal normal normal 16px/43px Roboto;
  text-align: center;
  border-radius: 50%;
`;

// Generic product item
export const ProductItem = styled.div`
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #DEE2E689;
  border-radius: 5px;
  padding: 0 12px;
  height: 68px;
  margin-bottom: 15px;

  img {
    object-fit: cover;
    border-radius: 3px;
    max-height: 45px;
    min-height: 0px;
    width: 52px;
  }

  @media only screen and (min-width: 800px) {
    height: 82px;
    border: 2px solid #DEE2E689;
    padding: 0 16px;
    margin-bottom: 30px;
  }
`;

export const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;

  p {
    font: normal normal normal 10px/12px Roboto;
    color: #B2BCC1;

    &:first-child {
      font: normal normal normal 12px/14px Roboto;
      color: #0A0918;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  @media only screen and (min-width: 800px) {

    p {
      font: normal normal normal 12px/14px Roboto;
      color: #B2BCC1;

      &:first-child {
        font: normal normal normal 16px/19px Roboto;
        color: #0A0918;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
`;

export const ProductTotal = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  color: #0A0918;
  font: normal normal 500 12px/14px Roboto;

  @media only screen and (min-width: 800px) {
    font: normal normal 500 14px/16px Roboto;
  }
`;

export const ProductButton = styled.div`
  margin-left: auto;
  cursor: pointer;
  background: rgba(223, 109, 33, 0.05);
  color: #DF6E21;
  padding: 0 12px;
  border-radius: 12px;
  height: 24px;
  font: normal normal normal 10px/24px Roboto;

  &:hover {
    background: rgba(223, 109, 33, 0.09);
  }

  @media only screen and (min-width: 800px) {
    padding: 0 22px;
    border-radius: 21px;
    height: 43px;
    font: normal normal normal 16px/43px Roboto;
  }
`;

export const CheckboxPlus = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 12px;
  height: 53px;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.active ? '#DF6E21B3' : '#DEE2E6')};
  background-color: ${(props) => (props.active ? '#DF6E210D' : '#FFFFFF')};
  
  img {
    width: 12px;
    height: 12px;
    margin-right: 15px;
  }
  
  p {
    font: normal normal 500 12px/14px Roboto;
    color: #0A0918;
  }
  
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 16px;
  }

  transition: all 0.1s ease-in-out;

  @media only screen and (min-width: 800px) {
    height: 75px;
    border: 2px solid #DEE2E6;
    padding: 0 16px;
    
    img {
      width: 25px;
      height: 25px;
      margin-right: 30px;
    }

    p {
      font: normal normal 500 20px/24px Roboto;
    }
    
    margin-bottom: 22px;
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
`;

export const Label = styled.p`
  font: normal normal normal 10px/14px Roboto;
  color: #B2BCC1;
  margin: 0;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 10px;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/16px Roboto;
    margin-top: -20px;
    margin-bottom: 20px;
  }
`;

export const ErrorLabel = styled.p`
  font: normal normal normal 10px/14px Roboto;
  color: red !important;
  margin: 0;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 10px;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/16px Roboto;
    margin-top: -20px;
    margin-bottom: 20px;
  }
`;

export const StatusAnimation = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto;

  @media only screen and (min-width: 800px) {
    width: 200px;
    height: 200px;
  }
`;

export const StatusMessage = styled.p`
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0px 0px 28px 0px;
  font: normal normal 500 16px/19px Roboto;
  letter-spacing: 0px;
  color: #0A0918;

  @media only screen and (min-width: 800px) {
    font: normal normal 500 16px/19px Roboto;
    margin: 0px 0px 40px 0px;
  }  
`;

export const GrayButton = styled.div`
  cursor: pointer;
  font: normal normal normal 16px/45px 'Roboto';
  text-align: center;
  border-style: none;
  border-radius: 5px;
  padding: 0;
  width: 100%;
  height: 45px;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;
  background: rgba(178, 188, 193, 0.21) 0% 0% no-repeat padding-box;
  color: #0A0918;
  
  &:hover {
    background: rgba(178, 188, 193, 0.21) 0% 0% no-repeat padding-box;
  }

  @media only screen and (min-width: 800px) {
    font: normal normal normal 20px/67px 'Roboto';
    height: 67px;
    margin: 0;
  }
`;

export const SlipLine = styled.p`
  text-align: center;
  font: normal normal normal 12px/24px Roboto;
  color: #0A0918;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/24px Roboto;
  }
`;

export const ConfirmLine = styled.div`
  width: 100%;
  height: 1px;
  background: #e8ebec;
  margin: 15px 0;
  
  @media only screen and (min-width: 800px) {
    height: 2px;
    background: #e8ebec;
    margin: 30px 0;
  }
`;

export const PeachDisclaimer = styled.div`
  display: flex;
  font: normal normal normal 12px/24px Roboto;
  p {
    margin-right: 10px;
  }
  img {
    flex-shrink: 0;
    height: 20px;
  }
`;
