import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  overflow-y: scroll;
  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar { display: none; } /* Chrome */
  
  @media only screen and (min-width: 800px) {
    width: min-content;    
  }
`;

export const Button = styled.div`
  cursor: pointer;
  padding: 0 10px;
  border-radius: 4px;
  background: ${(props) => (props.active ? props.theme.colors.primary : 'none')}; 
  font: normal normal normal ${(props) => (props.theme.mobileFontSizes[1])} ${(props) => (props.theme.font)};
  color: ${(props) => (props.active ? '#FFFFFF' : props.theme.colors.dark)};
  line-height: 25px;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 5px;
  }

  @media only screen and (min-width: 800px) {
    padding: 0 15px;
    font: normal normal normal ${(props) => (props.theme.fontSizes[1])} ${(props) => (props.theme.font)};
    line-height: 35px;
  }
`;
