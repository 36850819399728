import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInTheCityContext } from 'contexts/inthecity';
import {
  FilterColumn,
  SubTitle,
  ListItems,
  MoreList,
  MoreListIcon,
  Line,
  ListIcon,
  MobileMoreListIcon,
} from './categoryList.styled';

const CategoryList = (props) => {
  const { hideMobile = false, hideDesktop = false } = props;
  const { updateState, POICategories, WOIYCCategories } = useInTheCityContext();
  const [placeOfInterestListCounter, setPlaceOfInterestListCounter] = useState(hideDesktop ? 4 : 4);
  const [whatsOnInYourCityListCounter, setWhatsOnInYourCityListCounter] = useState(
    hideDesktop ? 4 : 4,
  );
  const navigate = useNavigate();
  const handleClickWOIYC = (x) => {
    updateState({ selectedWOIYCCategory: x });
    navigate('/in-the-city/whats-on-in-your-city');
  };
  const handleClickPOI = (x) => {
    updateState({ selectedPOICategory: x });
    navigate('/in-the-city/places-of-interest');
  };

  const placeListHandler = () => {
    if (placeOfInterestListCounter === POICategories.length) {
      setPlaceOfInterestListCounter(4);
    } else {
      setPlaceOfInterestListCounter(POICategories.length);
    }
  };

  const whatsOnInYourCityListHandler = () => {
    if (whatsOnInYourCityListCounter === WOIYCCategories.length) {
      setWhatsOnInYourCityListCounter(4);
    } else {
      setWhatsOnInYourCityListCounter(WOIYCCategories.length);
    }
  };

  return (
    <FilterColumn hideDesktop={hideDesktop} hideMobile={hideMobile}>
      <SubTitle>
        Places of Interest
        <MobileMoreListIcon
          src="inthecity/breadcrum_arrow_icon.svg"
          flip={(placeOfInterestListCounter >= POICategories.length)}
        />
      </SubTitle>
      <br />
      <div>
        {POICategories.slice(0, placeOfInterestListCounter).map((x, index) => (
          <div
            key={index}
            onClick={() => handleClickPOI(x)}
            role="button"
            tabIndex={0}
          >
            <ListItems
              key={index}
            >
              <ListIcon src="inthecity/orangeArrow_icon.svg" />
              {x.label}
              <br />
            </ListItems>
          </div>
        ))}
        <MoreList
          onClick={() => placeListHandler()}
          hideDesktop={hideDesktop}
          hideMobile={hideMobile}
        >
          <MoreListIcon src="inthecity/breadcrum_arrow_icon.svg" flip={(placeOfInterestListCounter >= POICategories.length)} />
          {(placeOfInterestListCounter >= POICategories.length) ? 'Less' : 'More'}
          <br />
        </MoreList>
        <Line />
      </div>

      <SubTitle>
        What&apos;s on in your City
        <MobileMoreListIcon src="inthecity/breadcrum_arrow_icon.svg" flip={(whatsOnInYourCityListCounter >= POICategories.length)} />
      </SubTitle>

      <br />
      <div>
        {WOIYCCategories.slice(0, whatsOnInYourCityListCounter).map((x, index) => (
          <div
            key={index}
            onClick={() => handleClickWOIYC(x)}
            role="button"
            tabIndex={0}
          >
            <ListItems>
              <ListIcon src="inthecity/orangeArrow_icon.svg" />
              {x.label}
              <br />
            </ListItems>
          </div>
        ))}
        <MoreList onClick={() => whatsOnInYourCityListHandler()}>
          <MoreListIcon src="inthecity/breadcrum_arrow_icon.svg" flip={(whatsOnInYourCityListCounter >= WOIYCCategories.length)} />
          {(whatsOnInYourCityListCounter >= WOIYCCategories.length) ? 'Less' : 'More'}
          <br />
        </MoreList>
        <Line />
      </div>
    </FilterColumn>
  );
};

export default CategoryList;
