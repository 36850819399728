import { css } from '@emotion/react';
import { Button, Image, Link } from 'components/elements';
import { Loader } from 'components/inthecity';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { useGlobalContext } from 'contexts/global';
import {
  HomePageEvents,
  PrivateServicesEvents,
  logFirebaseEvent,
} from 'functions/firebase';
import { getPrivateServiceImage } from 'functions/private-services';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { MutableRefObject, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';
import { PageLinksEnum } from 'types/routing';
import { PrivateService } from 'types/types';

const ServiceButton = ({
  service,
  onClickCallback,
}: {
  service: PrivateService;
  onClickCallback: () => void;
}) => (
  <Button
    p="20px"
    border={theme.borders.standard1px}
    borderRadius={theme.borderRadius.medium}
    backgroundColor="white"
    onClick={onClickCallback}
    overrideCss={css`
      transition: transform 0.2s ease-in-out;
      &:hover {
        border-color: ${theme.colors.primary};
        transform: scale(1.01);
      }
    `}
  >
    <Flex flexDirection="column" justifyContent="flex-start">
      <Box
        p="7px"
        border={theme.borders.standard1px}
        borderRadius="50%"
        width="fit-content"
        backgroundColor="white"
      >
        <Image
          width="25px"
          height="25px"
          source={getPrivateServiceImage(service.attributes.customIcon)}
          alt={service.label}
        />
      </Box>
      <Text textAlign="left" mt="10px" fontSize="18px" fontWeight="bold">
        {service.label}
      </Text>
      <Text
        mt="10px"
        color={theme.colors.primary}
        p="5px"
        backgroundColor={theme.colors.selectionPrimary}
        borderRadius={theme.borderRadius.small}
      >
        Book now
      </Text>
    </Flex>
  </Button>
);

const SuggestServiceButton = () => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(PageLinksEnum.SuggestAService);
    logFirebaseEvent(HomePageEvents.SuggestService);
  };
  return (
    <Button
      p="20px"
      border={theme.borders.standard1px}
      borderRadius={theme.borderRadius.medium}
      backgroundColor="white"
      onClick={clickHandler}
      overrideCss={css`
        transition: transform 0.2s ease-in-out;
        &:hover {
          border-color: ${theme.colors.primary};
          transform: scale(1.01);
        }
      `}
    >
      <Flex flexDirection="column" justifyContent="flex-start">
        <Box width="25px" height="25px" backgroundColor="white">
          <Text color={theme.colors.primary} fontSize="2rem" m={0} p={0}>
            +
          </Text>
        </Box>
        <Text textAlign="left" mt="10px" fontSize="18px" fontWeight="bold">
          Suggest a service
        </Text>
        <Text textAlign="left" mt="10px">
          Let us know which premium services you would like to be made available
          in your area.
        </Text>
      </Flex>
    </Button>
  );
};

const BookAServiceSection = ({
  privateServicesRef,
  isLoggedIn,
  showPsModal,
}: {
  privateServicesRef: MutableRefObject<any>;
  isLoggedIn: boolean;
  showPsModal: () => void;
}) => {
  const { setShowLoginModal } = useGlobalContext();
  const navigate = useNavigate();

  const {
    loading,
    response: availableServices,
    callApi,
  } = useSmartCityManager(SCManagerEndpoints.AllServices);

  useEffect(() => {
    if (isLoggedIn) {
      callApi();
    }
  }, [isLoggedIn]);

  return (
    <>
      <div ref={privateServicesRef} />
      <Flex
        flexDirection="column"
        justifyContent="center"
        width="100%"
        pt="70px"
        px="20%"
        mobileCss={css`
          padding-top: 20px;
        `}
        tabletCss={css`
          padding-left: 5%;
          padding-right: 5%;
        `}
      >
        <Text
          id="book-a-service"
          textAlign="center"
          fontSize="40px"
          mb="10px"
          fontWeight="bold"
          mobileCss={css`
            font-size: 30px;
          `}
        >
          Book a Service
        </Text>
        <Text
          textAlign="center"
          fontSize="24px"
          mobileCss={css`
            font-size: 18px;
          `}
        >
          Select from the services we currently offer or suggest a service that
          you would like to add.
        </Text>
        {isLoggedIn && (
          <Box hideForMobile>
            {loading || !availableServices ? (
              <Loader />
            ) : (
              <Grid
                gridTemplateColumns="repeat(3, 1fr)"
                width="100%"
                mt="20px"
                gridColumnGap="10px"
                gridRowGap="10px"
              >
                {availableServices
                  // Filter out tutors until implementation is complete
                  .filter(
                    (s: PrivateService) =>
                      !s.label.toLowerCase().includes('tutor') &&
                      !s.label.toLowerCase().includes('pet')
                  )
                  .map((s: PrivateService) => (
                    <ServiceButton
                      service={s}
                      key={s.value}
                      onClickCallback={() => {
                        navigate(
                          `${PageLinksEnum.BookAService}/${s.value}/${s.label}?persistBooking=false`
                        );
                        logFirebaseEvent(
                          s.label.toLowerCase().includes('domestic')
                            ? PrivateServicesEvents.BookDomestic
                            : PrivateServicesEvents.BookGardener
                        );
                      }}
                    />
                  ))}
                <SuggestServiceButton />
              </Grid>
            )}
          </Box>
        )}
      </Flex>
      <Grid
        gridTemplateColumns="2fr 3fr"
        px="10%"
        py="70px"
        mobileCss={css`
          margin-top: 20px;
          margin-bottom: 20px;
          padding: 10px;
          display: flex;
          flex-direction: column-reverse;
        `}
        tabletCss={css`
          margin-top: 20px;
          margin-bottom: 20px;
          padding: 10px 10%;
          display: flex;
          flex-direction: column-reverse;
        `}
      >
        <Box
          mt="20%"
          mobileCss={css`
            margin-top: 20px;
            padding: 0 20px;
          `}
          tabletCss={css`
            margin-top: 20px;
          `}
        >
          <Text
            fontSize="30px"
            mobileCss={css`
              font-size: 24px;
            `}
          >
            Reliable, Professional and Trusted Service Providers at your
            Convenience.
          </Text>
          <Text
            mt="20px"
            fontSize="18px"
            mobileCss={css`
              font-size: 14px;
              margin-top: 10px;
            `}
          >
            Home or office, book service providers with ease and peace of mind
            with My Smart City. Convenience and control at the touch of a
            button.
          </Text>
          <Button
            onClick={() => {
              if (isLoggedIn) {
                showPsModal();
                logFirebaseEvent(PrivateServicesEvents.BookAService);
              } else {
                setShowLoginModal(true);
              }
            }}
            mt="20px"
            variant="filled"
            color="white"
            fontWeight="bold"
            fontSize="20px"
            width="100%"
            overrideCss={css`
              ${isLoggedIn &&
              css`
                @media ${theme.mediaQueries.tabletUp} {
                  display: none;
                }
              `}
              transition: transform 0.2s ease-in-out;
              &:hover {
                transform: scale(1.04);
              }
            `}
          >
            Book now!
          </Button>
          {/* App download buttons */}
          <Flex justifyContent="center" flexDirection="column" mt="20px">
            <Text pb="20px" fontSize="18px">
              Booking services is even easier using our mobile app. Download
              now!
            </Text>
            <Flex alignContent="center">
              <Link
                href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
                target="_blank"
              >
                <Image
                  p="0px 10px 0px 10px"
                  source="/footer/playStore.svg"
                  alt="playstore"
                  width="100%"
                  hoverCss={css`
                    transform: scale(1.1);
                    transform-origin: center;
                  `}
                />
              </Link>
              <Link
                href="https://apps.apple.com/app/mysmart-city/id1555236570"
                target="_blank"
              >
                <Image
                  mobileCss={css`
                    margin-right: 10px;
                  `}
                  source="/footer/appStore.svg"
                  alt="appstore"
                  width="100%"
                  hoverCss={css`
                    transform: scale(1.1);
                    transform-origin: center;
                  `}
                />
              </Link>
            </Flex>
          </Flex>
        </Box>

        <Flex width="100%">
          <Image
            source="new-homepage/book-service-image.png"
            alt="book service"
            maxWidth="100%"
            ml="20px"
            objectFit="cover"
          />
        </Flex>
      </Grid>
    </>
  );
};

export default BookAServiceSection;
