import { fetchMSC } from 'interface/app';
import { apiUrl } from 'constants/app';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
export const updateProfile = async (user) =>
  fetchMSC(
    'updateProfile',
    'POST',
    (json) => json,
    (e) => console.log('Error when updating profile.', e),
    user
  );
export const updateMarketingFlag = async (flag) =>
  fetchMSC(
    `updateMarketingFlag?acceptMarketing=${flag}`,
    'POST',
    (json) => json,
    (e) => console.log('Error when updating updateMarketingFlag.', e)
  );

export const getEmergencyContacts = async () =>
  fetchMSC(
    'getEmergencyContacts',
    'GET',
    (json) => json.data,
    (e) => alert('Error getting contacts.', e)
  );

export const addEmergencyContact = async (name, number) =>
  fetchMSC(
    `addEmergencyContact?contactName=${name}&contactNumber=${number}`,
    'POST',
    (json) => console.log('adding emergency contacts:', json),
    (e) => alert('Error adding contacts.', e)
  );

export const deleteEmergencyContact = async (name, number) =>
  fetchMSC(
    `deleteEmergencyContact?contactName=${name}&contactNumber=${number}`,
    'POST',
    (json) => console.log('adding emergency contacts:', json),
    (e) => alert('Error adding contacts.', e)
  );

export const getFavouriteCities = async () =>
  fetchMSC(
    'getFavoriteCities', //* Yes only this one is the American spelling
    'GET',
    (json) => json,
    () => null
  );

export const addFavouriteCity = async (cityId) =>
  fetchMSC(
    'addFavouriteCity',
    'POST',
    (json) => json,
    (e) => alert('Error adding city.', e),
    { cityId }
  );

export const removeFavouriteCity = async (cityId) =>
  fetchMSC(
    'addFavouriteCity',
    'POST',
    (json) => json,
    (e) => alert('Error removing city.', e),
    { cityId }
  );

export const addProfilePic = async (formData) =>
  fetch(`${apiUrl}smartcitymanager/addProfilePic`, {
    method: 'POST',
    async: false,
    credentials: 'include',
    body: formData,
    processData: false,
    contentType: false,
    headers: {
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

export const getCustomerPremises = async () =>
  fetch(
    `${apiUrl}smartcitymanager/getCustomerPremises?pageLimit=1000&pageNo=1`,
    {
      method: 'GET',
      async: false,
      credentials: 'include',
      headers: {
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

export const createCustomerPremise = async (customerPremise) =>
  fetch(`${apiUrl}smartcitymanager/createCustomerPremise`, {
    method: 'POST',
    async: false,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
    body: JSON.stringify(customerPremise),
  })
    .then((res) => res.json())
    .catch((e) => e);

export const updateCustomerPremise = async (customerPremise) =>
  fetch(`${apiUrl}smartcitymanager/updateCustomerPremise`, {
    method: 'POST',
    async: false,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
    body: customerPremise,
  })
    .then((res) => res.json())
    .catch((e) => e);

export const deleteCustomerPremise = async (customerPremise) =>
  fetch(`${apiUrl}smartcitymanager/deleteCustomerPremise`, {
    method: 'POST',
    async: false,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
    body: customerPremise,
  })
    .then((res) => res.json())
    .catch((e) => e);

export const addAccountToPremise = async (premiseAccount) =>
  fetch(`${apiUrl}smartcitymanager/addAccountToPremise`, {
    method: 'POST',
    async: false,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
    body: premiseAccount,
  })
    .then((res) => res.json())
    .catch((e) => e);

export const removeAccountFromPremise = async (premiseAccount) =>
  fetch(`${apiUrl}smartcitymanager/removeAccountFromPremise`, {
    method: 'POST',
    async: false,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
    body: premiseAccount,
  })
    .then((res) => res.json())
    .catch((e) => e);

export const getServiceProviderOrgUnits = async () =>
  fetch(`${apiUrl}smartcitymanager/getServiceProviderOrgUnits`, {
    method: 'GET',
    async: false,
    credentials: 'include',
    headers: {
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

export const logSupportCall = async (queryString, callback) =>
  fetch(`${apiUrl}/smartcitymanager/logSupportCall?${queryString}`, {
    method: 'POST',
    headers: { firebase_token: cookies.get('firebaseToken') },
  })
    .then((res) => callback(res))
    .catch((e) => e);
