import React, { useRef, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default function CustomerPremiseMarker({ premise }) {
  // const [draggable, setDraggable] = useState(false);
  if (!premise.latitude) {
    return null;
  }
  const [position] = useState({
    lat: premise.latitude,
    lng: premise.longitude,
  });
  const markerRef = useRef(null);

  const icon = L.divIcon({
    className: 'custom icon',
    iconAnchor: [30, 60],
    popupAnchor: [-3, -70],
    html: ReactDOMServer.renderToString(
      <img
        style={{ height: '60px' }}
        alt="FavIcon"
        src="map/favouritePin.svg"
      />
    ),
  });

  return (
    <Marker position={position} ref={markerRef} icon={icon}>
      <Popup minWidth={300} closeOnClick closeButton={false}>
        <div
          style={{
            width: '300px',
            padding: '15px',
            overflowX: 'auto',
            marginTop: '25px',
            textAlign: 'center',
          }}
        >
          <img
            src="map/favouriteCircle.svg"
            alt="Report button"
            style={{
              position: 'absolute',
              top: '-30px',
              left: '-30px',
              height: '5.5rem',
              borderRadius: '50%',
              boxShadow: 'rgb(100, 100, 100) 0px 0px 10px 1px',
            }}
          />
          <div
            style={{
              textAlign: 'center',
              fontSize: '20px',
              fontFamily: 'GothamMedium',
              marginBottom: '15px',
            }}
          >
            {premise.description}
          </div>
          {premise.buildingName &&
            premise.buildingName !== '' &&
            premise.buildingName}
          {premise.streetNumber &&
            premise.streetNumber !== '' &&
            `, ${premise.streetNumber}`}
          {premise.streetName &&
            premise.streetName !== '' &&
            `, ${premise.streetName}`}
          {premise.surburb && premise.surburb !== '' && `, ${premise.surburb}`}
          <br />
          <Link
            to="/account/favourite-locations"
            style={{ display: 'contents' }}
          >
            <Button
              className="homeButtonNoFloat"
              style={{
                marginTop: '25px',
              }}
            >
              EDIT
            </Button>
          </Link>
        </div>
      </Popup>
    </Marker>
  );
}
