/** @jsxImportSource @emotion/react */
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { Button, Image, Modal } from 'components/elements';
import theme from 'theme';
import {
  PeachPaymentStatusResponse,
  SmartCityServiceBooking,
  SmartCityServiceGroup,
} from 'types/types';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import {
  getResourceProfilePic,
  setMinimumCurrentValues,
} from 'functions/private-services';
import StarRating from 'components/star-rating';
import { convertToReadableDate } from 'functions/date';
import { Loader } from 'components/inthecity';
import { shortenWithEllipses } from 'functions/address-utils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PolicyConfirmationModal from 'components/private-services/my-bookings/policy-confirmation-modal';
import { Cookies } from 'react-cookie';
import ReschedulingModal from 'components/private-services/my-bookings/rescheduling-modal';
import useQuery from 'hooks/use-query';
import { peachSuccessCode } from 'constants/peach';
import ResourceDetailsModal from 'components/private-services/resource-details-modal';
import { useAppDispatch } from 'redux/hooks';
import { setBooking as setBookingRedux, setBookingStep } from 'redux/booking';
import { PageLinksEnum } from 'types/routing';
import { PrivateServicesEvents, logFirebaseEvent } from 'functions/firebase';

const cookies = new Cookies();

const BookingSectionWrapper = ({
  children,
  title,
  isLast,
}: {
  children: React.ReactNode;
  title: string;
  isLast?: boolean;
}) => (
  <Box
    borderB={!isLast ? `1px solid ${theme.colors.borderGrey}` : ''}
    pb="20px"
    mt="10px"
    overrideCss={css`
      @media ${theme.mediaQueries.mobileOnly} {
        padding: 0 10px 20px 10px;
        ${!isLast &&
        css`
          border-bottom: 5px solid ${theme.colors.borderGrey};
        `}
      }
    `}
  >
    <Text
      fontSize="20px"
      fontWeight="bold"
      mb="10px"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          font-size: 14px;
          color: ${theme.colors.lightGrey};
          font-weight: normal;
        }
      `}
    >
      {title}
    </Text>
    {children}
  </Box>
);

const Pricing = ({
  price,
  insured,
  isActiveBooking,
  handleCancelBooking,
  cancelling,
  handleRescheduleBooking,
  handleBookAgain,
}: {
  price: number;
  insured?: boolean;
  isActiveBooking?: boolean;
  handleCancelBooking: () => void;
  cancelling: boolean;
  handleRescheduleBooking: () => void;
  handleBookAgain: () => void;
}) => (
  <Box
    overrideCss={css`
      @media ${theme.mediaQueries.mobileOnly} {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100vw;
        background-color: white;
        border-top: ${theme.borders.standard1px};
        border-radius: 4px 4px 0 0;
        box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1);
      }
    `}
  >
    <Flex
      width="100%"
      borderRadius={theme.borderRadius.small}
      p="12px"
      height="fit-content"
      flexDirection="column"
      backgroundColor="rgb(243, 245, 246)"
      mobileCss={css`
        background: transparent;
      `}
    >
      <Flex
        justifyContent="space-between"
        py="10px"
        borderB={theme.borders.standard1px}
      >
        <Text>Service (incl VAT)</Text>
        <Text>{`R${
          insured ? (price - 15).toFixed(2) : price.toFixed(2)
        }`}</Text>
      </Flex>
      {insured && (
        <Flex
          justifyContent="space-between"
          py="10px"
          borderB={theme.borders.standard1px}
        >
          <Text>Insurance</Text>
          <Text>R15.00</Text>
        </Flex>
      )}
      <Flex justifyContent="space-between" py="10px">
        <Text fontSize="16px" fontWeight="bold">
          Total
        </Text>
        <Text fontSize="16px" fontWeight="bold">{`R${price.toFixed(2)}`}</Text>
      </Flex>
    </Flex>
    {isActiveBooking && (
      <>
        <Flex
          px="10px"
          flexDirection="column"
          mb="10px"
          overrideCss={css`
            @media ${theme.mediaQueries.tabletUp} {
              margin-top: 10px;
            }
          `}
        >
          <Button
            onClick={handleBookAgain}
            backgroundColor={theme.colors.primary}
            borderRadius={theme.borderRadius.small}
            color="white"
            width="100%"
            fontWeight={600}
            p="14px"
            border={`1px solid ${theme.colors.primary}`}
          >
            Book Again
          </Button>
          <Grid
            mt="10px"
            // gridTemplateColumns="1fr 1fr"

            // gridColumnGap="10px"
            mb="10px"
          >
            {/** TODO: Uncomment this button to enable rescheduling bookings once the API is fixed */}
            {/* <Button
              onClick={handleRescheduleBooking}
              backgroundColor={theme.colors.selectionPrimary}
              borderRadius={theme.borderRadius.small}
              color={theme.colors.primary}
              width="100%"
              fontWeight={600}
              p="14px"
              border={`1px solid ${theme.colors.primary}`}
            >
              Reschedule Booking
            </Button> */}
            <Button
              onClick={handleCancelBooking}
              backgroundColor="white"
              disabled={cancelling}
              borderRadius={theme.borderRadius.small}
              fontWeight={600}
              color="rgba(247, 13, 25, 0.7)"
              width="100%"
              p="14px"
              border="1px solid rgba(247, 13, 25, 0.5)"
            >
              {cancelling ? 'Submitting...' : 'Cancel Booking'}
            </Button>
          </Grid>
        </Flex>
      </>
    )}
  </Box>
);

const ResourceSummary = ({
  resourceId,
  serviceId,
}: {
  resourceId: number;
  serviceId: number;
}) => {
  // TODO: Add resource details modal
  const { response, callApi } = useSmartCityManager(
    SCManagerEndpoints.GetPrivateServicesResource
  );
  const [resource, setResource] = useState<any>(null);
  const [resourceDetailViewOpen, setResourceDetailViewOpen] = useState(false);
  useEffect(() => {
    callApi({
      queryParams: `id=${resourceId}&serviceTypeId=${serviceId}`,
    });
  }, [resourceId]);

  useEffect(() => {
    if (response) {
      setResource(response);
    }
  }, [response]);

  if (!resource) {
    return null;
  }

  return (
    <Flex
      minWidth="100%"
      justifyContent="space-between"
      height="100%"
      border={theme.borders.standard1px}
      mr="20px"
      borderRadius={theme.borderRadius.small}
      p="10px"
    >
      <ResourceDetailsModal
        isOpen={resourceDetailViewOpen}
        onClose={() => setResourceDetailViewOpen(false)}
        resource={resource}
      />
      <Grid gridTemplateColumns="1fr 5fr" gridColumnGap="5px">
        <Image
          source={getResourceProfilePic(resource.profilePic)}
          alt="Profile"
          height="40px"
          width="40px"
          borderRadius="50%"
          objectFit="cover"
        />
        <Grid gridTemplateRows="auto auto" ml="10px">
          <Flex justifyContent="space-between" width="100%" height="auto">
            <Text m={0} p={0} textAlign="left">
              {shortenWithEllipses(resource.fullName, 20)}
            </Text>
            {resource.preferredSupplier && (
              <Flex
                p="2px"
                height="fit-content"
                borderRadius={theme.borderRadius.small}
                fontSize="10px"
                backgroundColor="#00A3001A"
                color="#00A300"
              >
                <Text m={0} p={0} textAlign="center">
                  Preferred
                </Text>
              </Flex>
            )}
          </Flex>
          {resource.rating && <StarRating rating={resource.rating} />}
        </Grid>
      </Grid>
      <Button
        color={theme.colors.primary}
        fontWeight={600}
        p="10px"
        onClick={() => setResourceDetailViewOpen(true)}
      >
        View info
      </Button>
    </Flex>
  );
};

const BookingDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const bookingId = id;
  const { loading, response, callApi } = useSmartCityManager(
    SCManagerEndpoints.GetBooking
  );
  const { loading: cancelling, callApi: cancelBooking } = useSmartCityManager(
    SCManagerEndpoints.CancelBooking
  );
  const {
    loading: paymentStatusLoading,
    error: paymentStatusError,
    callApi: getPaymentStatus,
    response: paymentStatusResponse,
  } = useSmartCityManager(SCManagerEndpoints.GetPeachPaymentStatus);
  const {
    // loading: gettingBooking,
    response: bookingResponse,
    callApi: getBookingInformation,
  } = useSmartCityManager(SCManagerEndpoints.GetServiceInfo);
  const query = useQuery();
  const checkoutId = query.get('id');
  const [booking, setBooking] = useState<SmartCityServiceBooking | null>(null);
  const isActiveBooking = booking?.bookingInformation?.status === 'Active';
  const [schedulingModalOpen, setSchedulingModalOpen] = useState(false);
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<
    boolean | null
  >(null);
  const [paymentStatus, setPaymentStatus] =
    useState<PeachPaymentStatusResponse | null>(null);

  const [whichPolicy, setWhichPolicy] = useState<
    'cancellation' | 'reschedule' | null
  >(null);

  const doNotShowPolicyModal = cookies.get('do-not-show-again') === 'true';
  const dispatch = useAppDispatch();

  const handleBookAgain = async () => {
    if (booking) {
      await getBookingInformation({
        queryParams: `serviceId=${booking.bookingInformation?.typeId}`,
      });
    }
  };

  useEffect(() => {
    if (bookingResponse && booking) {
      const servicesInBooking = booking?.smartCityServiceGroups
        ?.map((group) => group.smartCityServices)
        .flat()
        .map((s) => ({ current: s.current, label: s.label }));

      const newServiceGroups: SmartCityServiceGroup[] = setMinimumCurrentValues(
        bookingResponse.smartCityServiceGroups
      );

      for (let i = 0; i < newServiceGroups.length; i++) {
        const newServices = newServiceGroups[i].smartCityServices;
        for (let j = 0; j < newServices.length; j++) {
          const newService = newServices[j];
          const idx = servicesInBooking?.findIndex(
            (s) => s.label === newService.label
          );
          if (idx !== -1 && idx !== undefined && servicesInBooking) {
            newService.current = servicesInBooking[idx].current;
            newServiceGroups[i].smartCityServices.splice(j, 1, newService);
          }
        }
      }

      dispatch(
        setBookingRedux({
          ...bookingResponse,
          bookingInformation: {
            ...bookingResponse.bookingInformation,
            typeId: booking.bookingInformation?.typeId,
            insured: false,
          },
          smartCityServiceGroups: newServiceGroups,
        })
      );

      dispatch(setBookingStep('details'));

      navigate(
        `${PageLinksEnum.BookAService}/${booking.bookingInformation?.typeId}/${booking.bookingInformation?.service}`
      );
    }
  }, [bookingResponse]);

  const handleCancelBooking = () => {
    logFirebaseEvent(PrivateServicesEvents.CancelBooking);
    cancelBooking({
      queryParams: `bookingId=${bookingId}&cancelRecurring=false`,
    });
    setTimeout(() => {
      navigate('/my-bookings');
    }, 500);
  };

  useEffect(() => {
    callApi({
      queryParams: `bookingId=${bookingId}`,
    });
  }, [bookingId]);

  useEffect(() => {
    setBooking(response);
  }, [response]);

  useEffect(() => {
    if (checkoutId) {
      getPaymentStatus({
        queryParams: `checkoutId=${checkoutId}&createRegistration=false`,
      });
      setShowPaymentStatusModal(true);
    }
  }, [checkoutId]);

  useEffect(() => {
    setPaymentStatus(paymentStatusResponse);
  }, [paymentStatusResponse]);

  useEffect(() => {
    if (showPaymentStatusModal) {
      if (paymentStatus?.result.code === peachSuccessCode) {
        setIsPaymentSuccessful(true);
      } else {
        setIsPaymentSuccessful(false);
      }
    }
  }, [paymentStatus, showPaymentStatusModal]);

  return (
    <Flex flexDirection="column">
      <Modal
        isOpen={showPaymentStatusModal}
        onClose={
          isPaymentSuccessful
            ? () => navigate('/my-bookings')
            : () => setShowPaymentStatusModal(false)
        }
        title="Payment Status"
      >
        {typeof isPaymentSuccessful === 'boolean' &&
        (!paymentStatusLoading || !!paymentStatusError) ? (
          <>
            {isPaymentSuccessful ? (
              <Flex flexDirection="column">
                <Text
                  textAlign="center"
                  fontSize="20px"
                  color={theme.colors.green}
                >
                  Success
                </Text>
                <Image
                  source="icons/successicon.svg"
                  alt="success"
                  height="80px"
                  my="20px"
                />
                <Text textAlign="center" fontSize="18px" mb="20px">
                  Thank you for booking with us.
                </Text>
                <Button
                  variant="filled"
                  color="white"
                  onClick={() => navigate('/my-bookings')}
                >
                  Close
                </Button>
              </Flex>
            ) : (
              <>
                <Text textAlign="center" fontSize="20px" mb="20px" color="red">
                  Payment failed
                </Text>
                <Text textAlign="center" mb="20px" maxWidth="450px">
                  It seems that something has gone wrong during the payment
                  process. Please try again, use a different payment method or
                  contact us for further assistance.
                </Text>
                <Button
                  width="100%"
                  variant="filled"
                  color="white"
                  onClick={() => {
                    setShowPaymentStatusModal(false);
                  }}
                >
                  Close
                </Button>
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Modal>
      <PolicyConfirmationModal
        confirmationCallback={() =>
          whichPolicy === 'cancellation'
            ? handleCancelBooking()
            : setSchedulingModalOpen(true)
        }
        isOpen={typeof whichPolicy === 'string'}
        onClose={() => setWhichPolicy(null)}
        title="Just to confirm"
        whichPolicy={whichPolicy || 'cancellation'}
      />
      {booking?.bookingInformation && (
        <ReschedulingModal
          bookingInformation={booking?.bookingInformation}
          isOpen={schedulingModalOpen}
          onClose={() => {
            setSchedulingModalOpen(false);
          }}
        />
      )}
      <Box mt="1%" ml="3%" />
      <Flex mt="10px">
        <Image
          source="social/breadcrumb-icon.svg"
          alt=">"
          mx="10px"
          overrideCss={css`
            transform: rotate(180deg);
          `}
        />
        <Link to="/my-bookings">
          <Text color={theme.colors.darkGrey}>Back to bookings</Text>
        </Link>
      </Flex>
      <Flex
        pb="20px"
        pt="10px"
        width="100%"
        borderB={`2px solid ${theme.colors.borderGrey}`}
        justifyContent="center"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            border-bottom: 5px solid ${theme.colors.borderGrey};
          }
        `}
      >
        <Box
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              width: 100%;
            }
          `}
        >
          <Text
            textAlign="center"
            color={theme.colors.darkGrey}
            fontSize="25px"
            fontWeight="500"
          >
            Booking Details
          </Text>
        </Box>
      </Flex>
      {loading ? (
        <Loader />
      ) : (
        booking && (
          <Flex
            justifyContent="center"
            width="100%"
            py="50px"
            hideScrollBar
            overrideCss={css`
              @media ${theme.mediaQueries.mobileOnly} {
                padding: 10px 0;
                height: 100%;
                overflow-y: scroll;
              }
            `}
          >
            <Grid
              gridColumnGap="70px"
              gridTemplateColumns="1fr 1fr"
              width="100%"
              maxWidth="900px"
              mx="30px"
              overrideCss={css`
                @media ${theme.mediaQueries.desktopOnly} {
                  min-width: 900px;
                }
                @media ${theme.mediaQueries.mobileOnly} {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                  min-width: 0;
                }
              `}
            >
              <Box>
                <BookingSectionWrapper title="Service">
                  <Box
                    mb="10px"
                    border={theme.borders.standard1px}
                    borderRadius={theme.borderRadius.small}
                    p="10px"
                  >
                    <Text mb="5px" fontWeight="bold">
                      {booking.bookingInformation?.service}
                    </Text>
                    <Grid gridTemplateColumns="repeat(3, auto)">
                      {booking.smartCityServiceGroups.map((group) => (
                        <>
                          {group.smartCityServices.map((service, serviceIdx) =>
                            service.label && service.current > 0 ? (
                              <Text
                                key={serviceIdx}
                                color={theme.colors.lightGrey}
                                fontSize="12px"
                              >
                                {`\u2022 ${
                                  service.serviceType === 1
                                    ? `${service.current} `
                                    : ''
                                }${service.label}`}
                              </Text>
                            ) : null
                          )}
                        </>
                      ))}
                    </Grid>
                  </Box>
                  {booking.bookingInformation?.resourceId && (
                    <ResourceSummary
                      resourceId={booking.bookingInformation?.resourceId}
                      serviceId={booking.bookingInformation?.typeId || 0}
                    />
                  )}
                </BookingSectionWrapper>
                {booking.bookingInformation &&
                  booking.bookingInformation.address && (
                    <BookingSectionWrapper title="Address">
                      <Flex
                        justifyContent="flex-start"
                        width="100%"
                        alignItems="center"
                      >
                        <Image
                          source="privateServices/gray-pin.svg"
                          alt="pin"
                          backgroundColor="rgb(248, 248, 249)"
                          borderRadius="50%"
                          p="7px"
                          mr="10px"
                          height="23px"
                          width="29px"
                        />

                        <Box>
                          <Text fontWeight="bold" mb="5px">
                            {booking.bookingInformation.premiseType}
                          </Text>
                          <Text color={theme.colors.lightGrey}>
                            {booking.bookingInformation.address
                              .slice(1)
                              .replaceAll(',', ', ')}
                          </Text>
                        </Box>
                      </Flex>
                    </BookingSectionWrapper>
                  )}
                <BookingSectionWrapper title="Schedule">
                  {booking.bookingInformation?.startDateTime && (
                    <Flex
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                    >
                      <Flex>
                        <Image
                          source="privateServices/calendar.svg"
                          alt="calendar"
                          mr="10px"
                        />
                        <Box>
                          <Text fontWeight="bold" mb="5px">
                            {convertToReadableDate(
                              booking.bookingInformation?.startDateTime || 0
                            )}
                          </Text>
                          <Text color={theme.colors.green}>
                            {booking.bookingInformation.estimatedDuration} hours
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                  )}
                </BookingSectionWrapper>
                <Box height="225px" hideForTablet hideForDesktop />
              </Box>
              <Pricing
                isActiveBooking={isActiveBooking}
                handleCancelBooking={() =>
                  !doNotShowPolicyModal
                    ? setWhichPolicy('cancellation')
                    : handleCancelBooking()
                }
                cancelling={cancelling}
                handleRescheduleBooking={() =>
                  !doNotShowPolicyModal
                    ? setWhichPolicy('reschedule')
                    : setSchedulingModalOpen(true)
                }
                handleBookAgain={handleBookAgain}
                price={booking.bookingInformation?.totalPrice || 0}
                insured={booking.bookingInformation?.insured}
              />
            </Grid>
          </Flex>
        )
      )}
    </Flex>
  );
};

export default BookingDetailsPage;
