import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Section from 'components/section';
import 'styles/privacy.css';

import {
  TermsLinks,
  TermsPopupBox,
  TermsPopupContent,
  TermsPopupButton,
  TermsSideMenu,
  TermsSideMenuContent,
} from './terms.styled';

const Links = () => (
  <TermsLinks>
    <ol>
      <li>
        <Link to="/privacy-policy#interpretation-and-definitions" smooth>
          Interpretation and Definitions
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#interpretation" smooth>
          Interpretation
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#definitions" smooth>
          Definitions
        </Link>
      </li>
      <li>
        <Link
          to="/privacy-policy#collecting-and-using-your-personal-data"
          smooth
        >
          Collecting and Using Your Personal Data
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#tracking-technologies-and-cookies" smooth>
          Tracking Technologies and Cookies
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#use-of-your-personal-data" smooth>
          Use of Your Personal Data
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#transfer-of-your-personal-data" smooth>
          Transfer of Your Personal Data
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#disclosure-of-your-personal-data" smooth>
          Disclosure of Your Personal Data
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#business-transactions" smooth>
          Business Transaction
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#law-enforcement" smooth>
          Law enforcement
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#other-legal-requirements" smooth>
          Other legal requirements
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#security-of-your-personal-data" smooth>
          Security of Your Personal Data
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#childrens-privacy" smooth>
          Children’s Privacy
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#links-to-other-websites" smooth>
          Links to Other Websites
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#changes-to-this-privacy-policy" smooth>
          Changes to this Privacy Policy
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#contact-us" smooth>
          Contact Us
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy#security-of-your-personal-data" smooth>
          Security of Your Personal Data
        </Link>
      </li>
    </ol>
  </TermsLinks>
);

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && (
        <TermsPopupBox>
          <TermsPopupContent>
            <Links />
          </TermsPopupContent>
        </TermsPopupBox>
      )}
      <TermsPopupButton
        className={`${isOpen ? 'open' : ''}`}
        role="button"
        tabIndex={0}
        onClick={togglePopup}
      >
        <img src="terms/arrow.svg" alt="arrow" title={isOpen} />
      </TermsPopupButton>
    </>
  );
};

const SideMenu = () => (
  <TermsSideMenu>
    <TermsSideMenuContent>
      <Links />
    </TermsSideMenuContent>
  </TermsSideMenu>
);

export default function PrivacyPolicyPage() {
  const Content = styled.div`
    color: #333;
    padding: 0 4rem;
    p {
      margin: 0;
      padding: 0;
      margin-bottom: 3rem;
      font: normal normal normal 14px/19px Roboto;
      &.margin-top {
        margin-top: 3rem;
      }
    }
    h1 {
      margin: 0;
      padding: 0;
      font: normal normal normal 35px/46px Roboto;
      margin-bottom: 5rem;
      ${({ bold }) =>
        bold &&
        `
        font: normal normal 500 35px/46px Roboto;
      `}
    }
    h2 {
      margin: 0;
      padding: 0;
      padding-top: 3rem;
      margin-bottom: 1rem;
      font: normal normal 500 25px/33px Roboto;
    }
    h3 {
      margin: 0;
      padding: 0;
      padding-top: 3rem;
      font: normal normal normal 20px/24px Roboto;
    }
    ul {
      font: normal normal normal 14px/19px Roboto;
    }
    @media only screen and (max-width: 650px) {
      padding: 0 1rem;
      p {
        margin-bottom: 1rem;
        &.margin-top {
          margin-top: 1rem;
        }
      }
      h1 {
        margin-bottom: 0.5rem;
      }
      h2 {
        padding-top: 1rem;
      }
      h3 {
        padding-top: 1rem;
      }
    }
  `;

  const Title = styled.h1`
    margin: 0;
    padding: 0;
    font: normal normal normal 35px/46px Roboto;
  `;

  const Bold = styled.p`
    margin: 0;
    padding: 0;
    font: normal normal 500 35px/46px Roboto;
    display: contents;
  `;

  return (
    <>
      <SEO
        title="My Smart City | Privacy Policy"
        descriptions="This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your information when you use the service and tells you about your privacy rights and how the law protects you."
      />
      <Section color="white" thinPadding>
        <Row noGutters>
          <Col lg={3} sm={12}>
            <Popup />
            <SideMenu />
          </Col>
          <Col lg={9} sm={12}>
            <Content>
              <h1>My Smart City</h1>
              <Title>Privacy Policy</Title>
              <p>Last updated: 29 March 2023</p>
              <p>
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You.
              </p>

              <h2 id="interpretation-and-definitions">
                Interpretation and Definitions
              </h2>

              <h2 id="interpretation">Interpretation</h2>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>

              <h2 id="definitions">Definitions</h2>
              <p>For the purposes of this Privacy Policy:</p>
              <ul>
                <li>
                  <p>
                    <Bold>Account </Bold>
                    means a unique account created for You to access our Service
                    or parts of our Service.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Company </Bold>
                    (referred to as either &quot;the Company&quot;,
                    &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                    Agreement) refers to Acumen Software , 2 Payne Road,
                    Bryanston, Johannesburg
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Cookies </Bold>
                    are small files that are placed on Your computer, mobile
                    device or any other device by a website, containing the
                    details of Your browsing history on that website among its
                    many uses.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Country </Bold>
                    refers to: South Africa
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Device </Bold>
                    means any device that can access the Service such as a
                    computer, a cell phone or a digital tablet.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Personal data </Bold>
                    is any information that relates to an identified or
                    identifiable natural person.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Service </Bold>
                    refers to the Website.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Usage Data </Bold>
                    refers to data collected automatically, either generated by
                    the use of the Service or from the Service infrastructure
                    itself (for example, the duration of a page visit).
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Website </Bold>
                    refers to:
                    <br />
                    Acumen Software, accessible from www.acumensoft.net or;
                    <br />
                    Forcelink, accessible from www.forcelink.net or;
                    <br />
                    My Smart City accessible from www.mysmart.city.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>You </Bold>
                    means the individual accessing or using the Service, or the
                    company, or other legal entity on behalf of which such
                    individual is accessing or using the Service, as applicable.
                  </p>
                </li>
              </ul>

              <h2 id="collecting-and-using-your-personal-data">
                Collecting and Using Your Personal Data
              </h2>

            <div class="tableWrapper">
              <table>
                <tr>
                  <th>Purpose of collection</th>
                  <th>Information category</th>
                  <th>Data collected</th>
                  <th>Specific use</th>
                  <th>Lawful basis for processing</th>
                  <th>Data shared with?</th>
                  <th>Retention period</th>
                </tr>
                <tr>
                  <td>To provide you with information and services.</td>
                  <td>Contact and location information.</td>
                  <td>Name, geographic location, email address, profile picture, phone number, municipal
                     account data</td>
                  <td>To provide you with appropriate information regarding our 
                    services and to facilitate service delivery to you. Further details 
                    available in section (6.)</td>
                  <td>Contractual fulfillment.</td>
                  <td>Internally, to other users, municiplaities and card payment gateways.</td>
                  <td>As long as necessary to provide service or for municipal resolution to take place. 
                    This may be indefinitely until a user account is deleted/requested to be deleted.</td>
                </tr>
                <tr>
                  <td>To improve our application and services</td>
                  <td>Application usage data</td>
                  <td>Your IP address, browser type and version, pages visited, timestamp of visit, 
                    duration of visit, unique device identifiers.</td>
                  <td>To develop and improve our services to meet and the expectations and 
                    requirements of our clients and users. Further details available in section (6.)</td>
                  <td>Legitimate interest.</td>
                  <td>Internally, 3rd party analytics tools.</td>
                  <td>IP addresses and page visit data is retained for a maximum of 26 months.
                    Device/browser information that do not contain personal data may be held indefinitely.
                  </td>
                </tr>
                <tr>
                  <td>To facilite on-boarding of service providers and provide private services to our users</td>
                  <td>Private services applicant data</td>
                  <td>Name, contact number, location, ID number, bank info, passport/visa/work permit info.</td>
                  <td>To on-board service providers to our platform and do the necessary checks to ensure
                    the safety of our users and comply with labour laws/regulations. The service provider&lsquo;s 
                    name and contact details is also shared with users they are providing a service to, 
                    to enhance the service experience for both parties.
                  </td>
                  <td>Contractual fulfillment.</td>
                  <td>Internally, other users and 3rd parties for criminal checks and deliveries.</td>
                  <td>As long as necessary while the service provider may be active on our platform. Some 
                    of the information may be stored for a longer period to comply with legal obligations.
                  </td>
                </tr>
                  
              </table>
              </div>
              <h2 id="tracking-technologies-and-cookies">
                Tracking Technologies and Cookies
              </h2>
              <p>
                We use Cookies and similar tracking technologies to track the
                activity on Our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and
                track information and to improve and analyse Our Service. The
                technologies We use may include:
              </p>
              <ul>
                <li>
                  <p>
                    <Bold>Cookies or Browser Cookies. </Bold>A cookie is a small
                    file placed on Your Device. You can instruct Your browser to
                    refuse all Cookies or to indicate when a Cookie is being
                    sent. However, if You do not accept Cookies, You may not be
                    able to use some parts of our Service. Unless you have
                    adjusted Your browser setting so that it will refuse
                    Cookies, our Service may use Cookies.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Flash Cookies. </Bold>
                    Certain features of our Service may use local stored objects
                    (or Flash Cookies) to collect and store information about
                    Your preferences or Your activity on our Service. Flash
                    Cookies are not managed by the same browser settings as
                    those used for Browser Cookies. For more information on how
                    You can delete Flash Cookies, please read &quot;Where can I
                    change the settings for disabling, or deleting local shared
                    objects?&quot; available at
                    https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>Web Beacons. </Bold>
                    Certain sections of our Service and our emails may contain
                    small electronic files known as web beacons (also referred
                    to as clear gifs, pixel tags, and single-pixel gifs) that
                    permit the Company, for example, to count users who have
                    visited those pages or opened an email and for other related
                    website statistics (for example, recording the popularity of
                    a certain section and verifying system and server
                    integrity).
                  </p>
                </li>
              </ul>
              <p>
                Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
                Cookies. Persistent Cookies remain on Your personal computer or
                mobile device when You go offline, while Session Cookies are
                deleted as soon as You close Your web browser.
              </p>
              <p>
                We use both Session and Persistent Cookies for the purposes set
                out below:
              </p>
              <ul>
                <li>
                  <Bold>Necessary / Essential Cookies</Bold>
                  <br />
                  <p className="margin-top">Type: Session Cookies</p>
                  <br />
                  <p>Administered by: Us</p>
                  <br />
                  <p>
                    Purpose: These Cookies are essential to provide You with
                    services available through the Website and to enable You to
                    use some of its features. They help to authenticate users
                    and prevent fraudulent use of user accounts. Without these
                    Cookies, the services that You have asked for cannot be
                    provided, and We only use these Cookies to provide You with
                    those services.
                  </p>
                </li>
                <li>
                  <Bold>Cookies Policy / Notice Acceptance Cookies</Bold>
                  <br />
                  <p className="margin-top">Type: Persistent Cookies</p>
                  <br />
                  <p>Administered by: Us</p>
                  <br />
                  <p>
                    Purpose: These Cookies identify if users have accepted the
                    use of cookies on the Website.
                  </p>
                </li>
                <li>
                  <Bold>Functionality Cookies</Bold>
                  <br />
                  <p className="margin-top">Type: Persistent Cookies</p>
                  <br />
                  <p>Administered by: Us</p>
                  <br />
                  <p>
                    Purpose: These Cookies allow us to remember choices You make
                    when You use the Website, such as remembering your login
                    details or language preference. The purpose of these Cookies
                    is to provide You with a more personal experience and to
                    avoid You having to re-enter your preferences every time You
                    use the Website.
                  </p>
                </li>
              </ul>

              <h2 id="use-of-your-personal-data">Use of Your Personal Data</h2>
              <p>
                The Company may use Personal Data for the following purposes:
              </p>
              <ul>
                <li>
                  <p>
                    <Bold>To provide and maintain our Service, </Bold>
                    including to monitor the usage of our Service.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>To manage Your Account: </Bold>
                    to manage Your registration as a user of the Service. The
                    Personal Data You provide can give You access to different
                    functionalities of the Service that are available to You as
                    a registered user.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>For the performance of a contract: </Bold>
                    the development, compliance and undertaking of the purchase
                    contract for the products, items or services You have
                    purchased or of any other contract with Us through the
                    Service.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>To contact You: </Bold>
                    To contact You by email, telephone calls, SMS, or other
                    equivalent forms of electronic communication, such as a
                    mobile application’s push notifications regarding updates or
                    informative communications related to the functionalities,
                    products or contracted services, including the security
                    updates, when necessary or reasonable for their
                    implementation.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>To manage Your requests: </Bold>
                    To attend and manage Your requests to Us.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>For business transfers: </Bold>
                    We may use Your information to evaluate or conduct a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Our assets, whether
                    as a going concern or as part of bankruptcy, liquidation, or
                    similar proceeding, in which Personal Data held by Us about
                    our Service users is among the assets transferred.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>For other purposes: </Bold>
                    We may use Your information for other purposes, such as data
                    analysis, identifying usage trends, determining the
                    effectiveness of our promotional campaigns and to evaluate
                    and improve our Service, products, services, marketing and
                    your experience.
                  </p>
                </li>
              </ul>

              <p>
                We may share Your personal information in the following
                situations:
              </p>

              <ul>
                <li>
                  <p>
                    <Bold>For business transfers: </Bold>
                    We may share or transfer Your personal information in
                    connection with, or during negotiations of, any merger, sale
                    of Company assets, financing, or acquisition of all or a
                    portion of Our business to another company.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>With Affiliates: </Bold>
                    We may share Your information with Our affiliates, in which
                    case we will require those affiliates to honour this Privacy
                    Policy. Affiliates include Our parent company and any other
                    subsidiaries, joint venture partners or other companies that
                    We control or that are under common control with Us.
                  </p>
                </li>
                <li>
                  <p>
                    <Bold>With other users: </Bold>
                    when You share personal information or otherwise interact in
                    the public areas with other users, such information may be
                    viewed by all users and may be publicly distributed outside.
                  </p>
                </li>
              </ul>

              <h2 id="transfer-of-your-personal-data">
                Transfer of Your Personal Data
              </h2>
              <p>
                Your information, including Personal Data, is processed at the
                Company’s operating offices in South Africa and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
              </p>
              <p>
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with the UK/EU 
                GDPR and POPI act and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </p>

              <h2 id="disclosure-of-your-personal-data">
                Disclosure of Your Personal Data
              </h2>

              <h3 id="business-transactions">Business Transactions</h3>
              <p>
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>

              <h3 id="law-enforcement">Law enforcement</h3>
              <p>
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>

              <h3 id="other-legal-requirements">Other legal requirements</h3>
              <p>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </p>
              <ul>
                <li>
                  <p>Comply with a legal obligation</p>
                </li>
                <li>
                  <p>
                    Protect and defend the rights or property of the Company
                  </p>
                </li>
                <li>
                  <p>
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </p>
                </li>
                <li>
                  <p>
                    Protect the personal safety of Users of the Service or the
                    public
                  </p>
                </li>
                <li>
                  <p>Protect against legal liability</p>
                </li>
              </ul>

              <h2 id="security-of-your-personal-data">
                Security of Your Personal Data
              </h2>
              <p>
                The security of Your Personal Data is important to Us, as such 
                we utilize various technical controls to ensure that your personal
                data is protected. To this end our business is Cyber Essentials 
                Plus certified. You should be aware that many other information
                security threats exist and take precautions to safeguard your 
                personal information. We accept no liability for breaches that occur
                outside of our sphere of control.
              </p>

              <h2 id="data-subject-rights">
                Your Rights as a Data Subject
              </h2>

            
              
              <h3>The right to be informed</h3>
              <p>As a data controller, we are obliged to provide clear and transparent 
                information about our data processing activities. This is provided 
                by this privacy notice and any related communications we may send you.</p>

              <h3>The right of access</h3>
              <p>You may request a copy of the personal data
                we hold about you free of charge. Once we have verified your
                identity and, if relevant, the authority of any third-party
                requestor, we will provide access to the personal data we hold
                about you. If there are exceptional circumstances that mean we
                can refuse to provide the information, we will explain them. If
                requests are frivolous or vexatious, we reserve the right to
                refuse them. If answering requests is likely to require
                additional time or unreasonable expense (which you may have to
                meet), we will inform you.</p>

              <h3>The right of rectification</h3>
              <p>If you believe we hold inaccurate or
                incomplete personal information about you, you may exercise your
                right to correct or complete this data. This may be used with
                the right to restrict processing to make sure that
                incorrect/incomplete information is not processed until it is
                corrected.</p>
              
            
              <h3>The right to erasure (the ‘right to be forgotten’)</h3>
              <p>Where no overriding legal basis or legitimate reason continues to 
                exist for processing personal data, you may request that we delete 
                the personal data. This includes personal data that may have been 
                unlawfully processed. We will take all reasonable steps to 
                ensure erasure.</p>
           
             
              <h3>The right to restrict processing</h3>
              <p>You may ask us to stop processing your personal data. 
                We will still hold the data but will not process it any further. 
                This right is an alternative to the right to erasure. 
                If one of the following conditions applies, you may exercise the 
                right to restrict processing:
                <br />
                <br />
                <ul>a) The accuracy of the personal data is contested. </ul>
                <ul>b) Processing of the personal data is unlawful.</ul>
                <ul>c) We no longer need the personal data for processing, 
                  but the personal data is required for part of a legal process.</ul>
                <ul>d) The right to object has been exercised and processing 
                  is restricted pending a decision on the status of the processing.</ul></p>

              <h3>The right to data portability</h3>
              <p>You may request your set of personal data be transferred to another 
                controller or processor, provided in a commonly used and machine-readable
                format. This right is only available if the original processing was on
                the basis of consent, the processing is by automated means and if the
                processing is based on the fulfilment of a contractual obligation.</p>

              <h3>The right to object</h3>
              <p>You have the right to object to our processing of your data under one of the following conditions:
              <br />
              <br />
              <ul>a) Processing is based on legitimate interest.</ul>
              <ul>b) Processing is for the purpose of direct marketing.</ul>
              <ul>c) Processing is for the purposes of scientific or historic research.</ul>
              <ul>d) Processing involves automated decision-making and profiling.</ul></p>
              
            

              <h2 id="childrens-privacy">Children’s Privacy</h2>
              <p>
                Our Service does not address anyone under the age of 18. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 18. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal
                Data, please contact Us. If We become aware that We have
                collected Personal Data from anyone under the age of 18 without
                verification of parental consent, We take steps to remove that
                information from Our servers.
              </p>

              <h2 id="links-to-other-websites">Links to Other Websites</h2>
              <p>
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party’s site. We strongly advise You to
                review the Privacy Policy of every site You visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>

              <h2 id="changes-to-this-privacy-policy">
                Changes to this Privacy Policy
              </h2>
              <p>
                We may update Our Privacy Policy from time to time. and update
                the &quot;Last updated&quot; date at the top of this Privacy
                Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>

              <h2 id="contact-us">Contact Us</h2>

              <p>
                If you have any questions about this Privacy Policy, You can
                contact us:
              </p>
              <ul>
                <li>
                  <p>By email: privacy@acumensoft.net</p>
                </li>
              </ul>
            </Content>
          </Col>
        </Row>
      </Section>
      <Footer />
    </>
  );
}
