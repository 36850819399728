import React from 'react';
import { LocationFilter } from 'components/social/filter';
import {
  CardsViewer, Breadcrumbs, CategoryBar,
} from 'components/inthecity';
import {
  TopRow, Title, ContentContainer, Banner,
} from './placesOfInterest.styled';

/**
 * Favourites Page
 */
const FavouritesPage = () => {
  console.log('FavouritesPage()');
  return (
    <>
      <TopRow>
        <Breadcrumbs />
        <LocationFilter />
      </TopRow>
      <Title>Your Favourites</Title>
      <CategoryBar />
      <Banner src="city/cape_town.jpg" />
      <ContentContainer>
        <CardsViewer title="Near you" />
      </ContentContainer>
    </>
  );
};

export default FavouritesPage;
