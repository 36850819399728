import { Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { Image, Link } from 'components/elements';
import { List } from '@material-ui/core';

import { css } from '@emotion/react';
import theme from 'theme';
import { FooterEmail, FooterPhone } from './footer.styled';

const Footer = () => (
  <Flex
    flexDirection="column"
    backgroundColor="#0000000A"
    fontSize="16px"
    mobileCss={css`
      padding-bottom: 70px;
    `}
  >
    <Grid
      tabletCss={css`
        margin: 0px;
        display: flex;
        flex-direction: column;
        border: none;
      `}
      mobileCss={css`
        width: 100%;
        margin: 0px;
        margin-bottom: 10px;
        display: flex;
        padding: 10px;
        flex-direction: column;
        border: none;
      `}
      gridColumnGap="40px"
      pt="20px"
      px="5%"
      gridTemplateColumns="1fr 1fr 1fr"
      borderB="3px solid #0000001D"
    >
      <Flex
        tabletCss={css`
          margin: 0px;
          margin-left: 20px;
          display: flex;
        `}
        mobileCss={css`
          margin: 0px;
          display: flex;
        `}
        flexDirection="column"
      >
        <Image
          mobileCss={css`
            width: 100px;
            height: 100px;
            object-fit: contain;
            margin: 10px;
            display: flex;
          `}
          source="mscdark.png"
          alt="logo"
          height="69px"
          width="85px"
        />
        <Text
          tabletCss={css`
            margin: 50px 0px 0px 0px;
            padding: 0px;
            display: flex;
          `}
          mobileCss={css`
            font-size: 20px;
            margin-left: 10px;
            margin-right: 50px;
            display: flex;
            padding: 0px;
          `}
          fontSize="18px"
        >
          The citizen&#39;s platform that connects you to the city that you
          love. Own your city, connect to service providers and resource and
          drive change.
        </Text>
        <Flex
          mobileCss={css`
            margin: 0px;
            display: flex;
          `}
          mt="30px"
          flexDirection="row"
          alignItems="center"
        >
          <Link
            id="footer-linkedIn"
            href="https://www.linkedin.com/company/my-smart-city/"
          >
            <Image
              mr="10px"
              height="40px"
              width="40px"
              mobileCss={css`
                margin: 20px 10px 20px 20px;
                width: 20px;
                height: 20px;
                object-fit: contain;
              `}
              hoverCss={css`
                transform: scale(1.1);
                transform-origin: center;
              `}
              objectFit="contain"
              source="new-homepage/linkedin-blackicon.png"
              alt="LinkedIn"
            />
          </Link>
          <Link
            id="footer-facebook"
            href="https://www.facebook.com/My-Smart-City-115586417024931"
          >
            <Image
              mobileCss={css`
                margin: 20px 10px 20px 0px;
                width: 20px;
                height: 20px;
                object-fit: contain;
              `}
              mr="10px"
              height="40px"
              width="40px"
              objectFit="contain"
              source="new-homepage/facebook-blackicon.png"
              alt="FaceBook"
              hoverCss={css`
                transform: scale(1.1);
                transform-origin: center;
              `}
            />
          </Link>
          <Link
            id="footer-Instagram"
            href="https://www.instagram.com/mysmartcity_sa/"
          >
            <Image
              mobileCss={css`
                margin: 20px 10px 20px 0px;
                width: 20px;
                height: 20px;
              `}
              mr="10px"
              height="40px"
              width="40px"
              objectFit="contain"
              source="new-homepage/instagram-blackicon.png"
              alt="Instagram"
              hoverCss={css`
                transform: scale(1.1);
                transform-origin: center;
              `}
            />
          </Link>
          <Link id="footer-Twitter" href="https://twitter.com/MySmartCity_SA">
            <Image
              mobileCss={css`
                margin: 20px 10px 20px 0px;
                width: 20px;
                height: 20px;
              `}
              mr="10px"
              height="40px"
              width="40px"
              objectFit="contain"
              source="new-homepage/twitter-blackicon.png"
              alt="Twitter"
              hoverCss={css`
                transform: scale(1.1);
                transform-origin: center;
              `}
            />
          </Link>
          <Link id="footer-TikTok" href="https://www.tiktok.com/@mysmartcity">
            <Image
              mobileCss={css`
                margin: 20px 0px 20px 0px;
                width: 70px;
                height: 70px;
                object-fit: contain;
              `}
              height="110px"
              width="110px"
              objectFit="contain"
              source="new-homepage/tiktok-blackicon.png"
              alt="TikTok"
              hoverCss={css`
                transform: scale(1.1);
                transform-origin: center;
              `}
            />
          </Link>
        </Flex>
      </Flex>
      <Grid
        gridTemplateColumns="1fr 1fr"
        tabletCss={css`
          margin: 10px 10px 30px 10px;
          padding: 0px 20px 0px 20px;
          display: flex;
          justify-content: space-between;
        `}
        mobileCss={css`
          margin: 0px;
          margin-left: 10px;
          display: flex;
        `}
        m="0px 40px 20px 0px"
        width="100%"
      >
        <Flex flexDirection="column" width="100%">
          <Text pb="20px" fontWeight={700}>
            Quick Links
          </Text>
          <Link pb="15px" className="footer-link" href="/about/ourstory">
            <Text
              color="black"
              hoverCss={css`
                font-weight: 500;
                color: ${theme.colors.primary};
              `}
            >
              About us
            </Text>
          </Link>
          <Link pb="15px" className="footer-link" href="/blog">
            <Text
              hoverCss={css`
                font-weight: 500;
                color: ${theme.colors.primary};
              `}
              color="black"
            >
              Blog
            </Text>
          </Link>
          <Link pb="15px" className="footer-link" href="/press">
            <Text
              hoverCss={css`
                font-weight: 500;
                color: ${theme.colors.primary};
              `}
              color="black"
            >
              Press
            </Text>
          </Link>
          <Link pb="15px" className="footer-link" href="/about/careers">
            <Text
              hoverCss={css`
                font-weight: 500;
                color: ${theme.colors.primary};
              `}
              color="black"
            >
              Careers
            </Text>
          </Link>
          <Link pb="15px" className="footer-links" href="/about/theteam">
            <Text
              hoverCss={css`
                font-weight: 500;
                color: ${theme.colors.primary};
              `}
              color="black"
            >
              The Team
            </Text>
          </Link>
        </Flex>

        <Flex
          flexDirection="column"
          tabletCss={css`
            margin-left: 100px;
          `}
          mobileCss={css`
            margin-right: 15px;
          `}
          width="100%"
        >
          <Text pb="20px" fontWeight="700">
            Contact Us
          </Text>
          <Flex>
            <FooterEmail />
            <Link pb="15px" href="mailto:support@mysmart.city">
              <Text
                hoverCss={css`
                  font-weight: 500;
                  color: ${theme.colors.primary};
                `}
                color="black"
              >
                support@mysmart.city
              </Text>
            </Link>
          </Flex>
          <Flex>
            <FooterPhone />
            <Link pb="15px" href="tel:0861-MYSMARTCITY">
              <Text
                hoverCss={css`
                  font-weight: 500;
                  color: ${theme.colors.primary};
                `}
                color="black"
              >
                0861-MYSMARTCITY
              </Text>
            </Link>
          </Flex>
        </Flex>
      </Grid>
      <Flex
        textAlign="center"
        tabletCss={css`
          display: flex;
          justify-content: center;
        `}
      >
        <List>
          <Text pb="20px" fontWeight={700}>
            Download the My Smart City App
          </Text>
          <Flex alignContent="center">
            <Link
              id="footer-playstore"
              href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
              target="_blank"
            >
              <Image
                p="0px 10px 0px 10px"
                source="/footer/playStore.svg"
                alt="playstore"
                width="100%"
                hoverCss={css`
                  transform: scale(1.1);
                  transform-origin: center;
                `}
              />
            </Link>
            <Link
              id="footer-appstore"
              href="https://apps.apple.com/app/mysmart-city/id1555236570"
              target="_blank"
            >
              <Image
                mobileCss={css`
                  margin-right: 10px;
                `}
                source="/footer/appStore.svg"
                alt="appstore"
                width="100%"
                hoverCss={css`
                  transform: scale(1.1);
                  transform-origin: center;
                `}
              />
            </Link>
          </Flex>
        </List>
      </Flex>
    </Grid>
    <Grid
      tabletCss={css`
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
      `}
      mobileCss={css`
        margin: 10px;
        padding: 0px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        font-size: 16px;
      `}
      fontSize="12px"
      gridTemplateColumns="1fr 2fr"
      m="0px 100px 100px 100px"
      p="20px 0px 20px 0px"
    >
      <Flex
        tabletCss={css`
          display: flex;
          justify-content: center;
          margin: 10px 0px;
        `}
        mobileCss={css`
          margin: 0px;
          display: flex;
          justify-content: center;
          margin: 15px 0px;
        `}
        ml="90px"
      >
        <Text
          mobileCss={css`
            font-size: 12px;
          `}
        >
          {' '}
          &#169; 2023 Copyright My Smart City. All rights Reserved.
        </Text>
      </Flex>
      <Flex m="10px 0 0 0">
        <Flex>
          <Image
            hideForTablet
            hideForMobile
            mr="10px"
            width="15px"
            height="15px"
            objectFit="contain"
            source="footer/disclaimer1.png"
            alt="disclaimer icon"
          />
        </Flex>
        <Flex
          tabletCss={css`
            margin: 10px auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            font-size: 16px;
          `}
          mobileCss={css`
            max-width: 80%;
            margin: 0 auto;
            margin-bottom: 5px;
            flex-wrap: wrap;
            border: none;
            text-align: center;
            justify-content: center;
          `}
          borderB="2px solid #0000001D"
          justifyText="center"
          justifyContent="center"
          pb="10px"
        >
          <Link
            mr="5px"
            pr="5px"
            mobileCss={css`
              border: none;
            `}
            overrideCss={css`
              border-right: 1px solid black;
            `}
            href="/terms-and-conditions#introduction"
          >
            <Text
              mobileCss={css`
                text-decoration: underline;
              `}
              hoverCss={css`
                color: ${theme.colors.primary};
              `}
              color="black"
            >
              Disclaimer
            </Text>
          </Link>
          <Link
            mr="5px"
            pr="5px"
            mobileCss={css`
              border: none;
            `}
            overrideCss={css`
              border-right: 1px solid black;
            `}
            href="/privacy-policy"
          >
            <Text
              mobileCss={css`
                text-decoration: underline;
              `}
              hoverCss={css`
                color: ${theme.colors.primary};
              `}
              color="black"
            >
              Privacy Policy
            </Text>
          </Link>
          <Link
            mr="5px"
            pr="5px"
            mobileCss={css`
              border: none;
            `}
            overrideCss={css`
              border-right: 1px solid black;
            `}
            href="cancellation-policy"
          >
            <Text
              mobileCss={css`
                text-decoration: underline;
              `}
              hoverCss={css`
                color: ${theme.colors.primary};
              `}
              color="black"
            >
              Cancellation Policy
            </Text>
          </Link>
          <Link
            mobileCss={css`
              border: none;
            `}
            mr="5px"
            pr="5px"
            overrideCss={css`
              border-right: 1px solid black;
            `}
            href="/reschedule-policy"
          >
            <Text
              mobileCss={css`
                text-decoration: underline;
              `}
              hoverCss={css`
                color: ${theme.colors.primary};
              `}
              color="black"
            >
              Reschedule Policy
            </Text>
          </Link>
          <Link href="/terms-and-conditions#introduction">
            <Text
              mobileCss={css`
                text-decoration: underline;
              `}
              hoverCss={css`
                color: ${theme.colors.primary};
              `}
              color="black"
            >
              Ts & Cs
            </Text>
          </Link>
        </Flex>
      </Flex>
    </Grid>
  </Flex>
);

export default Footer;
