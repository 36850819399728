import { css } from '@emotion/react';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { getResourceProfilePic } from 'functions/private-services';
import { Button, Image } from 'components/elements';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { ServiceBookingInformation, SuggestedResource } from 'types/types';
import StarRating from 'components/star-rating';
import PrivateServicesModal from 'components/private-services/private-services-modal';
import { useMakeABookingContext } from 'contexts/booking';
import { shortenWithEllipses } from 'functions/address-utils';
import ResourceDetailsModal from 'components/private-services/resource-details-modal';
import { useGlobalContext } from 'contexts/global';

export const ResourceSelectorButton = ({
  resource,
  isSelected,
  onClick,
}: {
  resource: SuggestedResource;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const [resourceDetailViewOpen, setResourceDetailViewOpen] = useState(false);
  return (
    <Flex flexDirection="column">
      <ResourceDetailsModal
        isOpen={resourceDetailViewOpen}
        onClose={() => setResourceDetailViewOpen(false)}
        resource={resource}
      />
      <Button onClick={onClick}>
        <Flex
          flexDirection="column"
          minWidth="240px"
          height="100%"
          border={
            isSelected
              ? `1px solid ${theme.colors.primary}`
              : theme.borders.standard1px
          }
          mr="20px"
          borderRadius={theme.borderRadius.small}
          p="10px"
          backgroundColor={isSelected ? 'rgba(223, 117, 49, 0.26)' : 'white'}
        >
          <Grid gridTemplateColumns="1fr 5fr" gridColumnGap="5px">
            <Image
              source={getResourceProfilePic(resource.profilePic)}
              alt="Profile"
              height="40px"
              width="40px"
              borderRadius="50%"
              objectFit="cover"
            />
            <Grid gridTemplateRows="auto auto" ml="10px">
              <Flex justifyContent="space-between" width="100%" height="auto">
                <Text m={0} p={0} textAlign="left">
                  {shortenWithEllipses(resource.fullName, 20)}
                </Text>
                {resource.preferredSupplier && (
                  <Flex
                    p="2px"
                    height="fit-content"
                    borderRadius={theme.borderRadius.small}
                    fontSize="10px"
                    backgroundColor="#00A3001A"
                    color="#00A300"
                  >
                    <Text m={0} p={0} textAlign="center">
                      Preferred
                    </Text>
                  </Flex>
                )}
              </Flex>
              {resource.rating && <StarRating rating={resource.rating} />}
            </Grid>
          </Grid>
        </Flex>
      </Button>
      <Button
        color={theme.colors.primary}
        fontWeight={600}
        p="10px"
        onClick={() => setResourceDetailViewOpen(true)}
      >
        View info
      </Button>
    </Flex>
  );
};

const ResourceSelectorWidget = ({
  bookingInformation,
  setResource,
}: {
  bookingInformation?: ServiceBookingInformation | null;
  setResource: (id: number, name: string, profileUrl: string) => void;
}) => {
  const { globalState } = useGlobalContext();
  const [suggestedResources, setSuggestedResources] = useState<
    SuggestedResource[] | null
  >(null);
  const [noResourceModalOpen, setNoResourceModalOpen] = useState(false);

  const {
    callApi: getSuggestedResources,
    response,
    loading,
  } = useSmartCityManager(SCManagerEndpoints.GetSuggestedResources);

  const {
    callApi: getSuggestedResourcesForGuest,
    response: responseForGuest,
    loading: gettingSuggestedResourcesForGuest,
  } = useSmartCityManager(SCManagerEndpoints.GetSuggestedResourcesForGuest);

  const { bookingStep, clearResource } = useMakeABookingContext();

  useEffect(() => {
    if (bookingInformation?.latitude && bookingInformation?.longitude) {
      if (bookingStep === 'details') {
        clearResource();
      }
      if (globalState.user.uuid === 'guest') {
        getSuggestedResourcesForGuest({
          queryParams:
            `appointmentStartDate=${bookingInformation.startDateTime}` +
            `&appointmentEndDate=${bookingInformation.endDateTime}` +
            `&serviceTypeId=${bookingInformation.typeId}` +
            `&latitude=${bookingInformation.latitude}` +
            `&longitude=${bookingInformation.longitude}`,
        });
      } else {
        getSuggestedResources({
          queryParams:
            `appointmentStartDate=${bookingInformation.startDateTime}` +
            `&appointmentEndDate=${bookingInformation.endDateTime}` +
            `&serviceTypeId=${bookingInformation.typeId}` +
            `&latitude=${bookingInformation.latitude}` +
            `&longitude=${bookingInformation.longitude}`,
        });
      }
    }
  }, [bookingInformation?.longitude, bookingInformation?.startDateTime]);

  useEffect(() => {
    if (response) {
      setSuggestedResources(response);
    }
    if (!loading && response && response.length === 0) {
      setNoResourceModalOpen(true);
    }
  }, [response]);

  useEffect(() => {
    if (responseForGuest) {
      setSuggestedResources(responseForGuest);
    }
    if (
      !gettingSuggestedResourcesForGuest &&
      responseForGuest &&
      responseForGuest.length === 0
    ) {
      setNoResourceModalOpen(true);
    }
  }, [responseForGuest]);

  return (
    <Flex
      flexDirection="column"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          padding: 10px 10px 20px 10px;
        }
      `}
    >
      <PrivateServicesModal
        psModalOpen={noResourceModalOpen}
        closeModal={() => setNoResourceModalOpen(false)}
        isServiceUnavailable
      />
      <Text fontSize="20px" mt="10px !important" mb="10px">
        Choose a service provider
      </Text>
      {bookingInformation?.latitude && bookingInformation?.longitude ? (
        <>
          {suggestedResources && suggestedResources.length > 0 ? (
            <Flex
              overflowX="scroll"
              mx="-10px"
              overrideCss={css`
                ::-webkit-scrollbar {
                  display: none;
                }
                @media ${theme.mediaQueries.tabletUp} {
                  display: grid;
                  grid-template-columns: 1fr;
                  grid-row-gap: 10px;
                  margin: 0;
                  overflow: unset;
                }
                @media ${theme.mediaQueries.desktopOnly} {
                  grid-template-columns: 1fr 1fr;
                }
              `}
            >
              <Box minWidth="15px" hideForDesktop hideForTablet />
              {suggestedResources?.map(
                (resource: SuggestedResource, idx: number) => (
                  <ResourceSelectorButton
                    resource={resource}
                    key={idx}
                    isSelected={bookingInformation?.resourceId === resource.id}
                    onClick={() =>
                      setResource(
                        resource.id,
                        resource.fullName,
                        resource.profilePic
                      )
                    }
                  />
                )
              )}
            </Flex>
          ) : (
            <Text>No service providers available for this address</Text>
          )}
        </>
      ) : (
        <Text m="0 !important" p={0}>
          Please select an address to view available service providers
        </Text>
      )}
    </Flex>
  );
};

export default ResourceSelectorWidget;
