import { css } from '@emotion/react';
import { Image, Button } from 'components/elements';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import theme from 'theme';
import { CustomerBooking } from 'types/types';

const RatingConfirm = ({
  booking,
  close,
}: {
  booking: CustomerBooking;
  close: () => void;
}) => {
  const [isPreferred, setPreferred] = useState(false);
  const { callApi } = useSmartCityManager(
    SCManagerEndpoints.SetPreferredSupplier
  );

  useEffect(() => {
    callApi({
      queryParams: `prefferedSupplier=${isPreferred}&workOrderId=${booking.workOrderId}`,
    });
  }, [isPreferred]);

  return (
    <>
      <Flex justifyContent="center">
        <Image
          width="100px"
          height="100px"
          source="privateServices/confirm-tick.svg"
          alt="confirm tick"
        />
      </Flex>
      <Flex py="20px" justifyContent="center">
        <Text fontSize="22px" fontWeight="500">
          Thank you for your feedback
        </Text>
      </Flex>
      <Button
        border={`2px solid ${theme.colors.borderGrey}`}
        borderRadius="5px"
        width="100%"
        onClick={() => setPreferred(!isPreferred)}
        overrideCss={css`
          transition: 0.2s ease-in-out;
          &:hover {
            background-color: ${theme.colors.selectionPrimary};
            box-shadow: ${theme.elevationShadow};
            .heart {
              transition: 0.3s ease;
              transform: translateX(-25%) translateY(-25%);
              transform: scale(1.3, 1.3);
            }
          }
        `}
      >
        <Flex py="10px" width="100%" alignItems="center" borderRadius="5px">
          <Image
            ml="10px"
            mr="25px"
            width="30px"
            height="30px"
            source={booking.resourceProfilePic}
            borderRadius="50%"
            objectFit="cover"
            alt="profile"
            overrideCss={css`
              @media ${theme.mediaQueries.mobileOnly} {
                margin-right: 10px;
                margin-left: 10px;
                width: 25px;
                height: 25px;
              }
            `}
          />
          <Box
            alignContent="center"
            px="2px"
            mr="25px"
            width="24px"
            height="21px"
            overrideCss={css`
              path,
              rect,
              circle,
              ellipse {
                fill: ${isPreferred && theme.colors.primary} !important;
              }
              @media ${theme.mediaQueries.mobileOnly} {
                margin-right: 25px;

                padding: 5px;
                padding-bottom: 25px;
                padding-right: 5px;
              }
            `}
          >
            <ReactSVG className="heart" src="privateServices/heart-icon.svg" />
          </Box>

          <Text
            fontSize="22px"
            color={theme.colors.primary}
            textAlign="center"
            overrideCss={css`
              @media ${theme.mediaQueries.mobileOnly} {
                font-size: 16px;
                padding-right: 15px;
              }
            `}
          >
            Mark as preferred provider
          </Text>
        </Flex>
      </Button>
      <Flex py="10px" borderB={`2px solid ${theme.colors.borderGrey}`} />
      <Button
        onClick={close}
        textAlign="center"
        py="20px"
        mt="40px"
        mb="10px"
        borderRadius={theme.borderRadius.small}
        width="100%"
        backgroundColor={theme.colors.backgroundGrey}
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 5px;
          }
        `}
      >
        <Text fontSize="20px" textAlign="center">
          Close
        </Text>
      </Button>
      {/* {openPsModal && (
        <Button
          onClick={openPsModal}
          py="20px"
          width="100%"
          textAlign="center"
          borderRadius={theme.borderRadius.small}
          backgroundColor={theme.colors.primary}
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              padding-top: 10px;
              padding-bottom: 10px;
            }
          `}
        >
          <Text fontSize="20px" color="white" textAlign="center">
            Book Another Service
          </Text>
        </Button>
      )} */}
    </>
  );
};
export default RatingConfirm;
