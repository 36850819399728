/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGlobalContext } from 'contexts/global';
import {
  ProductSelectionModal,
  ProductListModal,
  AmountModal,
  DetailsModal,
  PaymentModal,
  ConfirmationModal,
} from 'components/inthecity/airtimeDataModal';

/**
 * The Airtime/Data Modal for choosing and purchasing airtime/data products
 *
 * Props:
 * redirectState - {
 *  Object: lookup,
 *  Object: product,
 *  String: checkout
 *  String: mobileNumber,
 * }
 * buy - {
 *  Object: product,
 *  String: mobileNumber
 * }
 */
const AirtimeDataModal = (props) => {
  // props
  const {
    show,
    handleClose,
    products,
    buy,
    provider, // 'Vodacom', 'MTN', 'Cell C', 'Telkom Mobile'
    redirectState,
    handleShowOrderDetails,
  } = props;

  console.log('airtime:props', props);

  // hooks
  const {
    globalState: { user },
  } = useGlobalContext();
  const navigate = useNavigate();

  // state
  const [stage, setStage] = useState(0);
  const [category, setCategory] = useState('Airtime');
  const [subcategory, setSubcategory] = useState();
  const [product, setProduct] = useState();
  const [productList, setProductList] = useState(); // list of products that are 'bundles'
  const [ownAmount, setOwnAmount] = useState();
  const [mobileNumber, setMobileNumber] = useState();

  // Handle modal close, and reset state
  const handleResetAndClose = () => {
    setCategory('Airtime');
    setSubcategory(null);
    setProduct(null);
    setMobileNumber(user?.mobileNumber);
    setProductList(null);
    setStage(0);
    handleClose();
  };

  // Change Airtime/Data handler
  const handleChangeCategory = (c) => setCategory(c);

  // Change 'Pin on Receipt'/'Direct Topup' handler
  const handleChangeSubcategory = (s) => {
    if (s === 'Direct Topup' && category === 'Airtime') {
      setStage(2); // own amount, so go to Amount Modal
    } else {
      setStage(1); // go to Product List to select product
    }
    setSubcategory(s);
  };

  const handleSelectProduct = (p) => {
    setProduct(p);
    setStage(2); // go to Amount Modal (view of the chosen product)
    console.log('airtime:product', product);
  };

  const handleChangeOwnAmount = (a) => setOwnAmount(a);

  // Change phone number if the logged in user has changed
  useEffect(() => setMobileNumber(user?.mobileNumber), [user]);

  // go to purchase page if buy is not null
  useEffect(() => {
    if (buy) {
      setProduct(buy?.product);
      setOwnAmount(buy?.ownAmount);
      setMobileNumber(buy?.mobileNumber);
      setStage(4);
    }
  }, [buy]);

  // go to confirmation page if redirectState object is not null, i.e. a purchase was made
  useEffect(() => {
    if (redirectState) setStage(5);
  }, [redirectState]);

  // Get product list from provider -> category -> subcategory, or set "Own Amount" product for provider if Airtime -> Direct Topup
  useEffect(() => {
    if (category && subcategory && provider && products) {
      const { subCatalogues: catalogues } = products.find(
        ({ name }) => name === category
      );
      if (catalogues) {
        const { subCatalogues: providers } = catalogues.find(
          ({ name }) => name === subcategory
        );
        if (providers) {
          const { products: productsFromProvider } = providers.find(
            ({ name }) => name === provider
          );
          if (
            productsFromProvider &&
            category === 'Airtime' &&
            subcategory === 'Direct Topup'
          )
            setProduct(productsFromProvider[0]);
          if (productsFromProvider) setProductList(productsFromProvider);
        }
      }
    }
  }, [category, subcategory, provider, products]);

  switch (stage) {
    case 0:
      return (
        <ProductSelectionModal
          show={show}
          handleClose={handleResetAndClose}
          category={category}
          subcategory={subcategory}
          provider={provider}
          handleChangeCategory={handleChangeCategory}
          handleChangeSubcategory={handleChangeSubcategory}
        />
      );

    case 1:
      return (
        <ProductListModal
          show={show}
          handleClose={handleResetAndClose}
          handleBack={() => setStage(0)}
          products={products}
          category={category}
          subcategory={subcategory}
          provider={provider}
          productList={productList}
          handleSelectProduct={handleSelectProduct}
        />
      );

    case 2:
      return (
        <AmountModal
          show={show}
          handleClose={handleResetAndClose}
          product={product}
          ownAmount={ownAmount}
          category={category}
          subcategory={subcategory}
          handleChangeOwnAmount={handleChangeOwnAmount}
          handleBack={() => setStage(0)}
          handleNext={() => setStage(3)}
        />
      );

    case 3:
      return (
        <DetailsModal
          setStage={setStage}
          show={show}
          product={product}
          ownAmount={ownAmount}
          mobileNumber={mobileNumber}
          handleClose={handleResetAndClose}
          handleBack={(n) => {
            setMobileNumber(n);
            setStage(2);
          }}
          handleNext={(n) => {
            setMobileNumber(n);
            setStage(4);
          }}
        />
      );

    case 4:
      return (
        <PaymentModal
          show={show}
          product={product}
          ownAmount={ownAmount}
          mobileNumber={mobileNumber}
          category={category}
          subcategory={subcategory}
          handleClose={buy ? handleResetAndClose : () => {}}
          handleBack={buy ? () => {} : () => setStage(3)}
          buy={buy}
        />
      );

    case 5:
      return (
        <ConfirmationModal
          show={show}
          handleClose={() => {
            handleResetAndClose();
            navigate('/in-the-city/purchase/airtime');
          }}
          redirectState={redirectState}
          handleShowOrderDetails={() => {
            handleResetAndClose();
            handleShowOrderDetails();
          }}
        />
      );

    default:
      return null;
  }
};

export default AirtimeDataModal;
