import { css } from '@emotion/react';
import { Image } from 'components/elements';
import ExternalLink from 'components/elements/external-link';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import theme from 'theme';

const emailLink = `mailto:support@mysmart.city?subject=${encodeURIComponent(
  'I want to delete my account'
)}&body=${encodeURIComponent(
  'I want to delete my account. My details are: \n\nFull name:\nEmail address:\nCellphone number:'
)}`;

const whatsappLink = `https://wa.me/27729966875?text=${encodeURIComponent(
  'I want to delete my account. My details are: \n\nFull name:\nEmail address:\nCellphone number:'
)}`;

const DeleteAccountPage = () => (
  <Flex p="50px" flexDirection="column" gap="42px">
    <Text fontWeight={600} fontSize="40px">
      Account Deletion
    </Text>
    <Text>
      We&apos;re sorry to see you go. If you wish to delete your account, you
      can do so through either WhatsApp or email. Please choose the method that
      is most convenient for you:
    </Text>
    <Box width="100%" backgroundColor="#C5D2DB" height="2px" />
    <Flex
      alignItems="flex-start"
      gap="42px"
      mobileCss={css`
        flex-direction: column;
      `}
    >
      <Flex flexDirection="column" alignItems="flex-start" gap="30px">
        <Text fontWeight={600} fontSize="24px">
          Delete Account via WhatsApp
        </Text>
        <Text fontSize="16px">
          To delete your account using WhatsApp, please follow these steps:
        </Text>
        <ol>
          <li>
            <Text fontSize="16px">
              Click the &quot;WhatsApp&quot; button below.
            </Text>
          </li>
          <li>
            <Text fontSize="16px">
              You will be redirected to our WhatsApp support chat.
            </Text>
          </li>
          <li>
            <Text fontSize="16px">Send the message to our support team.</Text>
          </li>
          <li>
            <Text fontSize="16px">
              Our team will process your request and delete your account
              accordingly.
            </Text>
          </li>
        </ol>
        <ExternalLink href={whatsappLink} target="_blank">
          <Flex
            px="20px"
            py="10px"
            gap="16px"
            backgroundColor="#1BD741"
            borderRadius="4px"
            alignItems="center"
            justifyContent="center"
            fontWeight={600}
            fontSize="16px"
          >
            <Image
              source="icons/whatsapp.svg"
              alt="whatsapp"
              width="36px"
              height="36px"
            />
            <Text color="white">Whatsapp us</Text>
          </Flex>
        </ExternalLink>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-start" gap="30px">
        <Text fontWeight={600} fontSize="24px">
          Delete Account via Email
        </Text>
        <Text fontSize="16px">
          To delete your account using email, please follow these steps:
        </Text>
        <ol>
          <li>
            <Text fontSize="16px">
              Click the &quot;Email&quot; button below.
            </Text>
          </li>
          <li>
            <Text fontSize="16px">
              Your default email client will open with a pre-filled email.
              Please fill in your details.
            </Text>
          </li>
          <li>
            <Text fontSize="16px">
              In the email, state your request to delete your account and send
              it to our support team.
            </Text>
          </li>
          <li>
            <Text fontSize="16px">
              Our team will process your request and delete your account
              accordingly.
            </Text>
          </li>
        </ol>
        <ExternalLink href={emailLink} target="_blank">
          <Flex
            px="20px"
            py="10px"
            gap="16px"
            backgroundColor="#2096BA"
            borderRadius="4px"
            alignItems="center"
            justifyContent="center"
            fontWeight={600}
            fontSize="16px"
          >
            <Image
              source="icons/mail-white.svg"
              alt="whatsapp"
              width="36px"
              height="36px"
            />
            <Text color="white">Email us</Text>
          </Flex>
        </ExternalLink>
      </Flex>
    </Flex>
    <Box width="100%" backgroundColor="#C5D2DB" height="2px" />
    <Text fontWeight={600} fontSize="24px">
      Important notice
    </Text>
    <Text fontSize="16px">
      Please note that deleting your account is permanent and cannot be undone.
      Once your account is deleted, all your data and information associated
      with it will be permanently removed. If you have any concerns or need
      further assistance, please don&apos;t hesitate to reach out to our support
      team.
    </Text>
    <ExternalLink
      href="https://wa.me/27729966875"
      px="20px"
      backgroundColor={theme.colors.primary}
      target="_blank"
      borderRadius="4px"
      alignItems="center"
      fontWeight={600}
      fontSize="16px"
      width="fit-content"
    >
      <Text color="white" py="20px">
        Contact support
      </Text>
    </ExternalLink>
  </Flex>
);

export default DeleteAccountPage;
