import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
  Outlet,
  useNavigate,
} from 'react-router-dom';

import 'App.scss';
import 'styles/app.scss';
import InTheCityTheme from 'styles/inthecity';
import Map from 'pages/map';
import HomePage from 'pages/home';
import AboutPage from 'pages/about';
import { BlogPage, BlogPostPage } from 'pages/blog';
import {
  TermsAndConditionsPage,
  CompetitionTermsPage,
  SurveyTermsPage,
  PrivacyPolicyPage,
} from 'pages/terms';
import { BadRequestPage } from 'pages/error';
import {
  InTheCityPage,
  PlacesOfInterestPage,
  WhatsOnInYourCityPage,
  FavouritesPage,
  PlacePage,
  EventPage,
  PurchasePage,
  LocalInsightsPage,
} from 'pages/inthecity';
import PressPage from 'pages/press';
import {
  FaultsPage, // CompetitionPage,
  WinnersPage,
} from 'pages/marketing';
import {
  PrivateServicesCampaignPage,
  PrivateServicesSurveyPage,
} from 'pages/private-services';
import {
  SocialPage,
  ConnectionsPage,
  ProfilePage,
  AlertsPage,
  AlertPage,
  PostPage,
} from 'pages/social';
import LoginPage from 'pages/login';
import { logOut } from 'interface/login';
import Notifications from 'pages/notifications';
import { SocialProvider } from 'contexts/social';
import { InTheCityProvider } from 'contexts/inthecity';
import { Navbar } from 'components/navbar';
import { useGlobalContext } from 'contexts/global';
import { BookingInsurance } from 'pages/bookingInsurance';
import { ReschedulePolicy } from 'pages/reschedulePolicy';
import { CancellationPolicy } from 'pages/cancellationPolicy';
import SuggestAServicePage from 'pages/suggest-a-service';
import BookAServicePage from 'pages/book-a-service';
import MakeABookingProvider from 'contexts/booking';
import MyBookingsPage from 'pages/my-bookings';
import ResourceBanner from 'components/resource-banner';
import usePollApi from 'hooks/use-poll-api';
import { SCManagerEndpoints } from 'hooks/use-smart-city-manager';
import { EnRouteResource } from 'types/types';
import ResourceChat from 'components/chat-container';
import ResourceBannerMobile from 'components/resource-banner-mobile';
import ResourceTrackingModal from 'components/resource-tracking-modal';
import { Cookies } from 'react-cookie';
import BookingDetailsPage from 'pages/booking-details';
import EditProfilePage from 'pages/my-account/edit-profile';
import FavouriteLocationsPage from 'pages/my-account/favourite-locations';
import EmergencyContactsPage from 'pages/my-account/emergency-contacts';
import NotificationSettingsPage from 'pages/my-account/notification-settings';
import SupportPage from 'pages/my-account/support';
import TermsAndConditionsModal from 'components/terms-and-conditions-modal';
import ServicesPage from 'pages/my-municipality/services';
import WardCouncillorsPage from 'pages/my-municipality/ward-councillors';
import SelfMeterReadingsPage from 'pages/my-municipality/self-meter-readings/add-reading';
import BusinessDirectoryPage from 'pages/my-municipality/business-listings/business-directory';
import SingleListing from 'pages/my-municipality/business-listings/single-listing';
import SurveysPage from 'pages/my-municipality/surveys/surveys';
import CompleteSurvey from 'pages/my-municipality/surveys/complete-survey';
import MySurveysPage from 'pages/my-municipality/surveys/my-surveys';
import IDPContributionPage from 'pages/my-municipality/idps/idp-contribution';
import MyIDPContributionsPage from 'pages/my-municipality/idps/my-idp-contributions';
import GoGeorgePage from 'pages/my-municipality/go-george';
import UtilityAccountsPage from 'pages/my-account/utility-accounts';
import TendersPage from 'pages/my-municipality/documents/tenders';
import VacanciesPage from 'pages/my-municipality/documents/vacancies';
import ApplicationsPage from 'pages/my-municipality/documents/applications';
import MunicipalAccountPage from 'pages/my-municipality/municipal-account';
import ViewAccountPage from 'pages/my-municipality/municipal-account/view-account-page';
import PayAccountPage from 'pages/my-municipality/municipal-account/pay-account';
import MunicipalAccountPaymentStatusPage from 'pages/my-municipality/municipal-account/payment-status';
import PastMunicipalPaymentsPage from 'pages/my-municipality/municipal-account/past-payments';
import MetersPage from 'pages/my-municipality/self-meter-readings/meters';
import SelfMeterReadingStatusPage from 'pages/my-municipality/self-meter-readings/reading-status';
import PastReadingsPage from 'pages/my-municipality/self-meter-readings/past-readings';
import ChatPage from 'pages/my-municipality/chat';
import DeleteAccountPage from 'pages/delete-account';
import { PageLinksEnum } from './types/routing';

const cookies = new Cookies();

const ApiPoller = ({
  setResponse,
}: {
  setResponse: (response: any) => void;
}) => {
  const { response } = usePollApi(
    SCManagerEndpoints.GetEnRouteResources,
    30000
  );

  useEffect(() => {
    if (response) {
      setResponse(response);
    }
  }, [response]);

  return null;
};

export default function App() {
  console.log('App()');

  const { pathname } = useLocation();
  const { init, globalState, logout } = useGlobalContext();
  const navigate = useNavigate();
  const userAcceptTtc =
    typeof globalState?.user.accepttc === 'undefined'
      ? true
      : globalState?.user.accepttc;

  console.log('App rerender - Global state: ', init, globalState);

  const [enRouteResources, setEnRouteResources] = useState<EnRouteResource[]>(
    []
  );
  const [trackedResource, setTrackedResource] =
    useState<EnRouteResource | null>(null);

  const [showChat, setShowChat] = useState(false);
  const [showTracking, setShowTracking] = useState(false);

  useEffect(() => {
    const asyncInit = async () => {
      await init();
    };
    asyncInit();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isLoggedIn = !!cookies.get('firebaseToken');
  console.log(globalState.city);

  return (
    <div id="MySmartCityApp">
      <div id="App">
        <Container fluid id="AppContainer">
          <TermsAndConditionsModal
            isOpen={
              !userAcceptTtc &&
              !pathname.includes('terms-and-conditions') &&
              !pathname.includes('privacy-policy')
            }
            onClose={() => {
              if (!userAcceptTtc) {
                logout();
                navigate('/');
              }
            }}
            user={globalState.user}
          />
          {isLoggedIn && <ApiPoller setResponse={setEnRouteResources} />}
          <Navbar />
          {enRouteResources.length > 0 &&
            enRouteResources.map((resource) => (
              <ResourceBanner
                key={resource.bookingId}
                resource={resource}
                setTrackedResource={() => setTrackedResource(resource)}
                showChat={() => setShowChat(true)}
                showTracking={() => setShowTracking(true)}
              />
            ))}
          {enRouteResources.length > 0 &&
            isLoggedIn &&
            enRouteResources.map((resource) => (
              <ResourceBannerMobile
                key={resource.bookingId}
                resource={resource}
                setTrackedResource={() => setTrackedResource(resource)}
                showChat={() => setShowChat(true)}
              />
            ))}
          {trackedResource && (
            <>
              {showTracking && (
                <ResourceTrackingModal
                  resourceId={trackedResource.id}
                  onClose={() => setShowTracking(false)}
                  isOpen={showTracking}
                />
              )}
              {showChat && (
                <ResourceChat
                  resourceId={trackedResource.id}
                  bookingId={trackedResource.bookingId}
                  onClose={() => setShowChat(false)}
                  isOpen={showChat}
                />
              )}
            </>
          )}
          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="/about" element={<Outlet />}>
              <Route index element={<AboutPage />} />
              <Route path=":tab" element={<AboutPage />} />
            </Route>

            <Route path="delete-my-account" element={<DeleteAccountPage />} />

            <Route path="/blog" element={<Outlet />}>
              <Route index element={<BlogPage />} />
              <Route path="post" element={<Outlet />}>
                <Route index element={<BlogPage />} />
                <Route path=":num" element={<BlogPostPage />} />
              </Route>
            </Route>

            <Route path="/winners" element={<WinnersPage />} />
            {/* <Route path="/competition" element={<CompetitionPage />} /> */}
            <Route
              path="/report-faults-in-your-city"
              element={<FaultsPage />}
            />
            <Route path="/press" element={<PressPage />} />
            {/* <Route path="/download" element={<LandingPage />} /> */}
            <Route path="/login" element={<LoginPage />} />

            {/* Legal */}
            <Route
              path="/cancellation-policy"
              element={<CancellationPolicy />}
            />
            <Route path="/reschedule-policy" element={<ReschedulePolicy />} />
            <Route path="/booking-insurance" element={<BookingInsurance />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route
              path="/competition-terms-and-conditions"
              element={<CompetitionTermsPage />}
            />

            <Route path="/my-municipality" element={<Outlet />}>
              <Route path="services/:cityId" element={<ServicesPage />} />
              <Route path="supply-chain/:cityId" element={<TendersPage />} />
              <Route path="vacancies/:cityId" element={<VacanciesPage />} />
              <Route
                path="applications/:cityId"
                element={<ApplicationsPage />}
              />
              <Route path="self-meter-readings/:cityId" element={<Outlet />}>
                <Route index element={<MetersPage />} />
                <Route path="add-reading" element={<SelfMeterReadingsPage />} />
                <Route
                  path="reading-status"
                  element={<SelfMeterReadingStatusPage />}
                />
                <Route path="past-readings" element={<PastReadingsPage />} />
              </Route>
              <Route path="chat/:cityId" element={<ChatPage />} />
              <Route path="municipal-accounts/:cityId" element={<Outlet />}>
                <Route index element={<MunicipalAccountPage />} />
                <Route path="view-account" element={<ViewAccountPage />} />
                <Route path="pay-account" element={<PayAccountPage />} />
                <Route
                  path="payment-status"
                  element={<MunicipalAccountPaymentStatusPage />}
                />
                <Route
                  path="payment-history"
                  element={<PastMunicipalPaymentsPage />}
                />
              </Route>
              <Route
                path="ward-councillors/:cityId"
                element={<WardCouncillorsPage />}
              />
              <Route
                path="idp-contribution/:cityId"
                element={<IDPContributionPage />}
              />
              <Route
                path="my-idp-contributions/:cityId"
                element={<MyIDPContributionsPage />}
              />
              <Route path="go-george/:cityId" element={<GoGeorgePage />} />
              <Route
                path="business-directory/:cityId"
                element={<BusinessDirectoryPage />}
              />
              <Route path="surveys/:cityId" element={<Outlet />}>
                <Route index element={<SurveysPage />} />
                <Route path="my-surveys" element={<MySurveysPage />} />
                <Route
                  path="complete-survey/:surveyId"
                  element={<CompleteSurvey />}
                />
              </Route>
              <Route
                path="business-directory/:cityId/single-listing/:businessId"
                element={<SingleListing />}
              />
            </Route>

            {/* Error pages */}
            <Route path="/400" element={<BadRequestPage />} />

            {/* Universal Links Router */}
            <Route path="/link" element={<Outlet />}>
              <Route path=":city" element={<Outlet />}>
                <Route path=":service" element={<Outlet />}>
                  <Route path=":type" element={<Outlet />}>
                    <Route path=":id" element={<UniversalLinkRouter />} />
                  </Route>
                </Route>
              </Route>
            </Route>

            {/* Private Services */}
            <Route
              path={PageLinksEnum.SuggestAService}
              element={<SuggestAServicePage />}
            />

            <Route
              path={PageLinksEnum.MyBookings}
              element={<MyBookingsPage />}
            />
            <Route
              path="/booking-details/:id"
              element={<BookingDetailsPage />}
            />

            <Route
              path={`${PageLinksEnum.BookAService}/:serviceId/:heading`}
              element={
                <MakeABookingProvider>
                  <BookAServicePage />
                </MakeABookingProvider>
              }
            />

            <Route
              path="link/app/home"
              element={<PrivateServicesCampaignPage />}
            />
            <Route
              path="book-cleaning-and-maintenance-services"
              element={<PrivateServicesCampaignPage />}
            />
            <Route path="private-services-survey" element={<Outlet />}>
              <Route index element={<Navigate to="/" />} />
              <Route path=":uuid" element={<PrivateServicesSurveyPage />} />
              <Route
                path="terms-and-conditions"
                element={<SurveyTermsPage />}
              />
            </Route>

            {/* Social Feed pages */}
            <Route path="social" element={<SocialFeedWrapper />}>
              <Route index element={<SocialPage />} />
              <Route path="connections" element={<ConnectionsPage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="profile/:uuid" element={<ProfilePage />} />
              <Route path="alerts" element={<AlertsPage />} />
              <Route path="user/:uuid" element={<SocialPage />} />
              <Route path="post" element={<PostPage />}>
                <Route path=":id" element={<PostPage />}>
                  <Route path=":city" element={<PostPage />} />
                </Route>
              </Route>
              <Route path="alert" element={<AlertPage />}>
                <Route path=":id" element={<AlertPage />}>
                  <Route path=":city" element={<AlertPage />} />
                </Route>
              </Route>
            </Route>

            {/* In-the-City pages */}
            <Route path="/in-the-city" element={<InTheCityWrapper />}>
              <Route index element={<InTheCityPage />} />
              <Route path="places-of-interest" element={<Outlet />}>
                <Route index element={<PlacesOfInterestPage />} />
                <Route path="place" element={<PlacePage />} />
              </Route>
              <Route path="whats-on-in-your-city" element={<Outlet />}>
                <Route index element={<WhatsOnInYourCityPage />} />
                <Route path="event" element={<EventPage />} />
              </Route>
              <Route path="favourites" element={<FavouritesPage />} />
              <Route path="purchase" element={<PurchasesWrapper />}>
                <Route index element={<PurchasePage />} />
                <Route path=":type" element={<Outlet />}>
                  <Route index element={<PurchasePage />} />
                  <Route path=":redirectState" element={<PurchasePage />} />
                </Route>
              </Route>
              <Route path="insights" element={<InsightsWrapper />}>
                <Route index element={<LocalInsightsPage />} />
              </Route>
            </Route>

            {/* Map pages */}
            <Route path="/map" element={<MapFunctional />}>
              <Route path=":city" element={<MapFunctional />}>
                <Route path=":serviceType" element={<MapFunctional />}>
                  <Route
                    path=":currentWorkRequest"
                    element={<MapFunctional />}
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path="/notifications/:tab"
              element={<NotificationsFunctional />}
            />
            <Route path="notifications" element={<NotificationsFunctional />}>
              <Route path=":tab" element={<NotificationsFunctional />} />
            </Route>
            <Route path="/account" element={<Outlet />}>
              <Route path="profile" element={<EditProfilePage />} />
              <Route
                path="favourite-locations"
                element={<FavouriteLocationsPage />}
              />
              <Route
                path="utility-accounts"
                element={<UtilityAccountsPage />}
              />
              <Route
                path="emergency-contacts"
                element={<EmergencyContactsPage />}
              />
              <Route
                path="notification-settings"
                element={<NotificationSettingsPage />}
              />
              <Route path="support" element={<SupportPage />} />
            </Route>
            <Route path="/logout" element={<LogoutWapper />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Container>
      </div>
    </div>
  );
}

const UniversalLinkRouter = () => {
  const { city, service, type, id } = useParams();
  if (service === 'social') {
    return <Navigate to={`/${service}/${type}/${id}/${city}`} />;
  }
  return <Navigate to="/" />;
};

const RequireAuth = (guestnotallowed?: boolean) => {
  const { globalState, setShowLoginModal } = useGlobalContext();
  const location = useLocation();

  useEffect(() => {
    if (globalState.user.uuid.localeCompare('init') === 0) {
      console.log('Requiring auth');
    }
    if (
      globalState.user.uuid.localeCompare('false') === 0 ||
      (guestnotallowed && globalState.user.uuid === 'guest')
    ) {
      setShowLoginModal(true);
    }
    console.log('User authenticated: Go to page');
  }, [location.pathname]);
  // const location = useLocation();

  return <Outlet />;
};

const SocialFeedWrapper = () => (
  <SocialProvider>{RequireAuth(true)}</SocialProvider>
);

const InTheCityWrapper = () => (
  <InTheCityProvider>
    <InTheCityTheme>{RequireAuth()}</InTheCityTheme>
  </InTheCityProvider>
);

const PurchasesWrapper = () => (
  <InTheCityProvider>
    <InTheCityTheme>{RequireAuth(true)}</InTheCityTheme>
  </InTheCityProvider>
);

const InsightsWrapper = () => (
  <InTheCityProvider>
    <InTheCityTheme>{RequireAuth(true)}</InTheCityTheme>
  </InTheCityProvider>
);

const LogoutWapper = () => {
  console.log('Logging Out');
  logOut();
  return <Map />;
};

function NotificationsFunctional() {
  const params = useParams();
  const { tab } = params;
  return (
    <div id="Notifications">
      <Notifications tab={tab} />
    </div>
  );
}

function MapFunctional() {
  // If the user uses a link with a valid city send them there.

  const params = useParams();
  const { serviceType, currentWorkRequest, city } = params;
  const {
    globalState: { user, availableCities },
  } = useGlobalContext();

  const allCitiesSubscriberIds =
    availableCities &&
    availableCities.map((c: any) => c.attributes.subscriberId);

  console.log('useParams', serviceType, currentWorkRequest, city);
  if (
    allCitiesSubscriberIds &&
    (`${city}` === 'undefined' ||
      allCitiesSubscriberIds.includes(city) ||
      city === 'login' ||
      city === 'signup') &&
    (`${serviceType}` === 'undefined' ||
      serviceType === 'municipal' ||
      serviceType === 'petitions' ||
      serviceType === 'emergency' ||
      serviceType === 'public-transport' ||
      serviceType === 'parking')
  ) {
    return (
      <Map
        city={city}
        serviceType={serviceType}
        currentWorkRequest={currentWorkRequest}
        user={user}
      />
    );
  }
  return <Navigate to="/map" />;
}
