/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';

import { searchPlaces } from 'interface/social';
import { useOnClickOutside } from 'functions/home';
import {
  getSmartCityInsights,
  getSmartCityInsightsForCity,
  getInsightsResolutionTimeGraph,
  getInsightsResolutionTimeGraphForCity,
  getInsightsOpenTimeGraph,
  getInsightsOpenTimeGraphForCity,
} from 'interface/inthecity/inthecity';
import { useGlobalContext } from 'contexts/global';
import {
  Container,
  PinIcon,
  Input,
  LegendContainer,
  Divider,
  Square,
  Label,
  TypeContainer,
  TypeButton,
  TypeDropdown,
  Option,
  SearchDropdown,
  SearchResult,
} from './locationDataSearch.styled';

const debouncedFetchSearchData = debounce(async (query, callback) => {
  const res = await searchPlaces(query);
  callback(res.results);
}, 500);

/**
 * Search and get data for locations (cities or wards)
 */
const LocationDataSearch = (props) => {
  // props
  const { setData, period = 12, primary = false } = props;

  // States
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(0);
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [cityResults, setCityResults] = useState([]);
  const [addressResults, setAddressResults] = useState([]);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);

  // Hooks
  const { globalState } = useGlobalContext();
  const ref = useRef();
  useOnClickOutside(ref, () => setShowLocationDropdown(false));
  // Event handlers
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    setShowLocationDropdown(true);
  };

  const handleAddressClick = async (loc) => {
    if (loc.formatted_address !== 'No results') {
      console.log('handleAddressClick');
      setQuery(loc.formatted_address);
      setShowLocationDropdown(false);

      const latitude = loc.geometry.location.lat;
      const longitude = loc.geometry.location.lng;
      setLoading(true);
      const response = await getSmartCityInsights(
        period,
        null,
        latitude,
        longitude
      );
      const response1 = await getInsightsResolutionTimeGraph(
        response?.wardNo,
        globalState.city?.value
      );
      const response2 = await getInsightsOpenTimeGraph(
        response?.wardNo,
        globalState.city?.value
      );
      setData([response, response1, response2, type]);
      setLoading(false);
    }
  };

  const handleCityClick = async (c) => {
    console.log('HandleCityClick');
    if (c.label !== 'No results') {
      setQuery(c.label);
      setShowLocationDropdown(false);
      setLoading(true);
      const response = await getSmartCityInsightsForCity(
        period,
        c.value,
        c.attributes.subscriberId
      );
      const response1 = await getInsightsResolutionTimeGraphForCity(c.value);
      const response2 = await getInsightsOpenTimeGraphForCity(c.value);
      setData([response, response1, response2, type]);
      setLoading(false);
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      console.log('enter');
      if (type === 0 && addressResults.length > 0) {
        handleAddressClick(addressResults[0]);
      }
      if (type === 1 && cityResults.length > 0) {
        handleCityClick(cityResults[0]);
      }
    }
  };

  const handleTypeChange = (t) => {
    setType(t);
    setQuery('');
    setAddress(null);
    setCity(null);
    setCityResults([]);
    setAddressResults([]);
    setShowLocationDropdown(false);
    setShowTypeDropdown(false);
  };

  useEffect(() => {
    if (type === 0) {
      debouncedFetchSearchData(query, (res) => {
        if (res.length === 0) {
          setAddressResults([{ formatted_address: 'No results' }]);
        } else {
          setAddressResults(res);
        }
      });
    } else {
      const res = globalState.availableCities.filter(({ label }) =>
        label.toLowerCase().includes(query.toLowerCase())
      );
      console.log(res);
      if (res.length === 0) {
        setCityResults([{ label: 'No results' }]);
      } else {
        setCityResults(res);
      }
    }
    // setShowLocationDropdown(true);
  }, [query]);

  useEffect(() => {
    async function fetchInitialData() {
      setLoading(true);
      console.log('Default city', globalState.city);
      // Set the init city
      const response = await getSmartCityInsightsForCity(
        period,
        globalState.city?.value,
        globalState.city?.attributes?.subscriberId
      );
      console.log('getting response', response);
      const response1 = await getInsightsResolutionTimeGraphForCity(
        globalState.city?.value
      );
      const response2 = await getInsightsOpenTimeGraphForCity(
        globalState.city?.value
      );
      setQuery(globalState.city?.label);
      setData([response, response1, response2, 1]);
      setType(1);
      setLoading(false);
    }
    fetchInitialData();
    setShowLocationDropdown(false);
  }, []);

  return (
    <Container ref={ref}>
      <PinIcon src="icons/Asset 7.svg" alt="icon" />

      {/* Ward address search */}
      {type === 0 && (
        <Input
          value={query}
          placeholder="Search address"
          onChange={handleQueryChange}
          onKeyDown={handleEnterPress}
        />
      )}
      {showLocationDropdown &&
        query?.length > 0 &&
        addressResults?.length > 0 &&
        type === 0 && (
          <SearchDropdown>
            {addressResults.map((res, index) => (
              <SearchResult key={index} onClick={() => handleAddressClick(res)}>
                {res.formatted_address}
              </SearchResult>
            ))}
          </SearchDropdown>
        )}

      {/* City search */}
      {showLocationDropdown &&
        query?.length > 0 &&
        cityResults?.length > 0 &&
        type === 1 && (
          <SearchDropdown>
            {cityResults.map((res, index) => (
              <SearchResult key={index} onClick={() => handleCityClick(res)}>
                {res.label}
              </SearchResult>
            ))}
          </SearchDropdown>
        )}
      {type === 1 && (
        <Input
          value={query}
          placeholder="Search city"
          onChange={handleQueryChange}
          onKeyDown={handleEnterPress}
        />
      )}

      {/* City/Ward dropdown */}
      <TypeContainer>
        <TypeButton onClick={() => setShowTypeDropdown(!showTypeDropdown)}>
          <p>{type === 0 ? 'Ward' : 'City'}</p>
          <img src="social/location-dropdown-icon.svg" alt="dropdown" />
        </TypeButton>
        {showTypeDropdown && (
          <TypeDropdown>
            <Option onClick={() => handleTypeChange(0)}>Ward</Option>
            <Option onClick={() => handleTypeChange(1)}>City</Option>
          </TypeDropdown>
        )}
      </TypeContainer>

      <LegendContainer>
        <Divider />
        <Square primary={primary} />
      </LegendContainer>
    </Container>
  );
};

export default LocationDataSearch;
