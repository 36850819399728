/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { currentLoadSheddingStage } from 'interface/social/loadshedding';
import { populateSocialAlertsInfo } from 'interface/social/alerts';
import { useSocialContext } from 'contexts/social';
import { useGlobalContext } from 'contexts/global';
import { Heading } from '../text';
import {
  Row,
  StyledAlert,
  Title,
  Description,
  Icon,
  ScrollButton,
  AlertContainer,
  Count,
  New,
} from './alerts.styled';

const shouldShowAlerts = (alerts) => {
  return alerts.some(
    (alert) => alert.attributes.count && alert.attributes.count > 0
  );
};

const Alerts = ({ alert, scrollLeft = 0 }) => {
  const { alerts, setAlerts } = useSocialContext();
  if (!shouldShowAlerts(alerts)) {
    return null;
  }
  const ref = useRef();
  const len = 190; // scroll length of the width of one alert button

  const [loadsheddingLabel, setLoadsheddingLabel] = useState('');
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const { globalState } = useGlobalContext();

  const handleScroll = (e) => {
    e.preventDefault();
    setShowLeft(ref.current.scrollLeft > 0);
    setShowRight(
      ref.current.scrollLeft + ref.current.clientWidth < ref.current.scrollWidth
    );
  };

  const handleScrollRight = () =>
    ref.current.scroll({
      top: 0,
      left: ref.current.scrollLeft + len,
      behavior: 'smooth',
    });
  const handleScrollLeft = () =>
    ref.current.scroll({
      top: 0,
      left: ref.current.scrollLeft - len,
      behavior: 'smooth',
    });

  useEffect(() => {
    ref.current.scroll({ top: 0, left: scrollLeft });

    async function fetchData() {
      const res = await populateSocialAlertsInfo();
      const resLoad = await currentLoadSheddingStage();
      console.log('load:', resLoad);
      setAlerts(res);
      setLoadsheddingLabel(resLoad.label);
      console.log('alerts', res);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await populateSocialAlertsInfo();
      const resLoad = await currentLoadSheddingStage();
      setAlerts(res);
      setLoadsheddingLabel(resLoad.label);
    }
    fetchData();
  }, [globalState.city]);

  useEffect(() => {
    setShowRight(
      ref.current.scrollLeft + ref.current.clientWidth < ref.current.scrollWidth
    );
  }, [alerts]);

  return (
    <>
      <Heading>Alerts</Heading>
      <AlertContainer>
        <Row ref={ref} onScroll={(e) => handleScroll(e)}>
          {Array.isArray(alerts) &&
            alerts.map((i, index) => (
              <Alert
                value={i.value}
                title={i.label}
                icon={`https://mysmart.city/api/msc/images/custom/${i.attributes.customIcon}?cityId=${globalState.city?.value}`}
                description={
                  i.label === 'Load Shedding'
                    ? loadsheddingLabel
                    : i.attributes.plannedStart
                }
                active={alert === i.value}
                num={i.attributes.count}
                index={index}
                key={index}
                ref={ref}
              />
            ))}
        </Row>
        {showLeft && <ScrollButton left onClick={handleScrollLeft} />}
        {showRight && <ScrollButton right onClick={handleScrollRight} />}
      </AlertContainer>
    </>
  );
};

const Alert = forwardRef(
  (
    {
      value,
      index,
      active = false,
      icon,
      title,
      description,
      num = 0,
      isNew = false,
    },
    ref
  ) => {
    const navigate = useNavigate();

    const handleClick = () => {
      const state = {
        alert: value,
        scrollLeft: ref?.current.scrollLeft,
      };
      navigate('/social/alerts', { state });
    };

    return (
      <StyledAlert active={active} key={index} onClick={handleClick}>
        <Icon src={icon} alt={icon} active={active} />
        <Title active={active}>{title}</Title>
        <Description active={active}>{description}</Description>
        <Count active={active}>{`${num} Alerts`}</Count>

        {isNew && <New>new</New>}
      </StyledAlert>
    );
  }
);

export default Alerts;
