/* Modules */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Bootstrap from 'react-bootstrap';

// import { useGlobalContext } from 'contexts/global';
import 'styles/map.scss';

export default function CityPicker(props) {
  const {
    serviceType,
    city,
    availableCities,
    changeCityDropDown,
    setState,
    handleChangeCity,
    currentCity,
  } = props;

  console.log('citypicker props:', props);
  // const { globalState, setCurrentCity } = useGlobalContext();
  const navigate = useNavigate();
  let cityName = '';

  for (let i = 0; i < availableCities.length; i++) {
    if (availableCities[i].attributes.subscriberId === city) {
      cityName = availableCities[i].label;
      break;
    }
  }

  return (
    <div id="cityPicker">
      {availableCities.length >= 1
        ? (
          <Bootstrap.DropdownButton
            variant="outline-dark"
            show={changeCityDropDown}
            onClick={() => {
              if (changeCityDropDown) {
                setState({ changeCityDropDown: false });
              } else {
                setState({ changeCityDropDown: true });
              }
            }}
            title={(
              <div
                style={{
                  color: 'white',
                  fontSize: '14px',
                  margin: 'auto',
                  display: 'inline-block',
                  textTransform: 'capitalize',
                }}
              >
                <img
                  src="icons/Asset 7.svg"
                  alt="Logo"
                  style={{
                    color: 'white',
                    height: '1.3rem',
                    marginRight: '10px',
                    filter: 'invert(100%) sepia(2%) saturate(1%) hue-rotate(341deg) brightness(106%) contrast(100%)',
                  }}
                />
                {cityName}
              </div>
                      )}
            value={currentCity.value}
          >
            {availableCities.map((cityt, i) => {
              if (cityt.attributes.subscriberId !== city) {
                return (
                  <div
                    key={`dropdownitem${i}`}
                    value={cityt.label}
                    role="button"
                    tabIndex={0}
                    className="cityDropDown"
                    eventKey="1"
                    onClick={() => {
                      setState({ changeCityDropDown: false });
                      handleChangeCity(cityt.attributes.subscriberId).then(() => {
                        navigate(`/map/${cityt.attributes.subscriberId}/${serviceType}`);
                      });
                    }}
                  >

                    <div
                      style={{
                        color: 'white',
                        fontSize: '14px',
                        margin: 'auto',
                        display: 'inline-block',
                      }}
                    >
                      <img
                        src="icons/Asset 7.svg"
                        alt="Logo"
                        style={{
                          color: 'white',
                          height: '1.3rem',
                          marginRight: '10px',
                          textTransform: 'capitalize',
                          filter: 'invert(100%) sepia(2%) saturate(1%) hue-rotate(341deg) brightness(106%) contrast(100%)',
                        }}
                      />
                      {cityt.label}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </Bootstrap.DropdownButton>
        )
        : null}
    </div>
  );
}
