import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  margin: 40px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #DF6E21;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spinAnimation} 1s linear infinite;
  flex-shrink: 0;
`;

export const SmallLoader = styled.div`
  position: absolute;
  margin-left: 20px;
  border: 2px solid hsl(23.999999999999993, 74.80314960629921%, 75%);
  border-top: 2px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spinAnimation} 0.5s linear infinite;
  @media only screen and (min-width: 800px) {
    margin-left: 40px;
    border: 3px solid hsl(23.999999999999993, 74.80314960629921%, 75%);
    border-top: 3px solid white;
    width: 30px;
    height: 30px;
  }
`;
