import React from 'react';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import Toggle from 'react-bootstrap-toggle';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment-timezone';
import 'leaflet-contextmenu';
import { ICON_IMAGE_URL } from 'constants/app';
import { Context } from 'functions/context';
// SCSS
import 'styles/map.scss';
import 'styles/app.scss';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
import {
  sendEmergencyMessage,
  getMyMapWorkRequests,
  getMyMapPetitions,
} from 'interface/map';
import ReportModal from 'components/map/reportModal';
import PetitionModal from 'components/map/petitionModal';
import EditReportModal from 'components/map/editReportModal';
import SignPetitionModal from 'components/map/signPetitionModal';
import UpVoteReportModal from 'components/map/upVoteReportModal';
import MarkerViewModal from 'components/map/mapMarkerViewModal';
import FloatingNavbar from 'components/map/floatingNavbar';
import lottie from 'lottie-web';

export default class Overlay extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertBody: {},
      reportModal: false,
      petitionModal: false,
      showEditReport: false,
      showMarkerView: false,
      showUpVoteReport: false,
      showSignPetition: false,
      showNotificationModal: false,
      showEmergencyModal: false,
      showEmergencyModalStage: 0,
      loginModal: false,
      popOverProfilePicture: false,
      showNotificationsModalMenu: false,
      unreadNotifications: [],
      searchReports: [],
      loadingMap: true,
      loadingMapA: true,
      loadingWorkRequests: false,
      sidebarOpen: false,
      useThisLocationForLog: {},
      showEditReportWR: { imageIds: [], notes: [] },
      showUpVoteReportWR: {},
      showSignPetitionWR: {},
      showMarkerViewModalWR: { imageIds: [], notes: [] },
      changeCityDropDown: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(
      ([key, val]) =>
        prevProps[key] !== val && console.log(`OVERLAY: Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(
        ([key, val]) =>
          prevState[key] !== val &&
          console.log(`OVERLAY: State '${key}' changed`)
      );
    }
  }

  componentDidMount = async () => {
    // eslint-disable-next-line
    this.props.thisOfOverlay(this);
    // Loading animation
    const anim = lottie.loadAnimation({
      name: 'loadingMap',
      container: this.animate2,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'map/mscMapLoad.json',
    });

    anim.onLoopComplete = () => {
      const { loadingMap } = this.state;
      if (!loadingMap) {
        lottie.stop('loadingMap');
        this.setState({ loadingMapA: false });
      }
    };

    // Loading animation
    const anim1 = lottie.loadAnimation({
      name: 'loadingWorkRequests',
      container: this.animate3,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'map/mscMapLoad.json',
    });

    anim1.onLoopComplete = () => {
      const { loadingWorkRequests } = this.state;
      if (!loadingWorkRequests) {
        lottie.stop('loadingWorkRequests');
      }
    };
  };

  loadingMap = () => {
    const { loadingMap, loadingMapA } = this.state;

    if (loadingMap) {
      lottie.play('loadingMap');
    }

    return (
      <div
        id="LoadingMapOverlayContainer"
        style={!loadingMapA ? { display: 'none' } : null}
      >
        <div className={loadingMap ? 'fadeIn' : 'fadeOut'}>
          <div
            ref={(ref) => {
              this.animate2 = ref;
            }}
            className="loadingMap"
          />
        </div>
      </div>
    );
  };

  loadingWorkRequests = () => {
    const { loadingWorkRequests } = this.state;

    if (loadingWorkRequests) {
      lottie.play('loadingWorkRequests');
    }

    return (
      <div
        id="loadingWorkRequestsOverlayContainer"
        style={!loadingWorkRequests ? { display: 'none' } : null}
      >
        <div className={loadingWorkRequests ? 'fadeIn' : 'fadeOut'}>
          <div
            ref={(ref) => {
              this.animate3 = ref;
            }}
            className="loadingWorkRequests"
          />
        </div>
      </div>
    );
  };

  filters = () => {
    const {
      currentServices,
      authenticatedState,
      serviceType,
      city,
      currentCity,
      setState,
      reloadFilters,
      getWorkRequests,
    } = this.props;
    console.log(currentCity);
    return (
      <section className="Cardt">
        <div className="CardTitle">
          <img className="sidebarIcon" src="map/filter.svg" alt="filter" />
          Filter
        </div>
        <Row style={{ margin: '0px' }}>
          <Link to={`/map/${city}/municipal`} style={{ display: 'contents' }}>
            <Col
              className="filterButton m-0"
              xs={6}
              sm={12}
              lg={12}
              xl={6}
              style={
                serviceType === 'municipal'
                  ? { backgroundColor: '#f97b26' }
                  : { backgroundColor: '#4f4f4f' }
              }
              onClick={() => {
                this.setState({
                  loadingWorkRequests: true,
                  sidebarOpen: false,
                });
                setState({ myWorkRequests: [] });
                reloadFilters(true, 'municipal').then((re) => {
                  if (authenticatedState) {
                    getMyMapWorkRequests(1, 200).then((res) => {
                      setState({
                        myWorkRequests: res,
                      });
                    });
                  }
                  getWorkRequests('municipal', false, re);
                });
              }}
            >
              Municipal
            </Col>
          </Link>
          <Link to={`/map/${city}/emergency`} style={{ display: 'contents' }}>
            <Col
              xs={6}
              sm={12}
              lg={12}
              xl={6}
              className="filterButton m-0"
              style={
                serviceType === 'emergency'
                  ? { backgroundColor: '#f97b26' }
                  : { backgroundColor: '#4f4f4f' }
              }
              onClick={() => {
                // Since the getWorkRequests for Emergency requires the
                // filters to load the google maps. We need them upfront
                setState({ myWorkRequests: [] });
                this.setState({
                  loadingWorkRequests: true,
                  sidebarOpen: false,
                });
                reloadFilters(true, 'emergency').then((res) =>
                  getWorkRequests('emergency', false, res)
                );
              }}
            >
              Emergency
            </Col>
          </Link>
          <Link to={`/map/${city}/petitions`} style={{ display: 'contents' }}>
            <Col
              xs={6}
              sm={12}
              lg={12}
              xl={6}
              className="filterButton m-0"
              style={
                serviceType === 'petitions'
                  ? { backgroundColor: '#f97b26' }
                  : { backgroundColor: '#4f4f4f' }
              }
              onClick={() => {
                setState({ myWorkRequests: [] });
                this.setState({
                  loadingWorkRequests: true,
                  sidebarOpen: false,
                });
                reloadFilters(true, 'petitions').then((re) => {
                  if (authenticatedState) {
                    getMyMapPetitions(1, 200).then((res) => {
                      setState({
                        myWorkRequests: res,
                      });
                    });
                  }
                  console.log('HELEL', re);
                  getWorkRequests('petitions', false, re);
                });
              }}
            >
              Petitions
            </Col>
          </Link>
          {currentCity.value === 663 && (
            <>
              <Link
                to={`/map/${city}/public-transport`}
                style={{ display: 'contents' }}
              >
                <Col
                  xs={6}
                  sm={12}
                  lg={12}
                  xl={6}
                  className="filterButton m-0"
                  style={
                    serviceType === 'public-transport'
                      ? { backgroundColor: '#f97b26' }
                      : { backgroundColor: '#4f4f4f' }
                  }
                  onClick={() => {
                    // Since the getWorkRequests for Emergency requires the
                    // filters to load the google maps. We need them upfront
                    setState({ myWorkRequests: [] });
                    this.setState({
                      loadingWorkRequests: true,
                      sidebarOpen: false,
                    });
                    reloadFilters(true, 'public-transport').then((res) =>
                      getWorkRequests('public-transport', false, res)
                    );
                  }}
                >
                  Transport
                </Col>
              </Link>
              <Link to={`/map/${city}/parking`} style={{ display: 'contents' }}>
                <Col
                  xs={6}
                  sm={12}
                  lg={12}
                  xl={6}
                  className="filterButton m-0"
                  style={
                    serviceType === 'parking'
                      ? { backgroundColor: '#f97b26' }
                      : { backgroundColor: '#4f4f4f' }
                  }
                  onClick={() => {
                    // Since the getWorkRequests for Emergency requires the
                    // filters to load the google maps. We need them upfront
                    setState({ myWorkRequests: [] });
                    this.setState({
                      loadingWorkRequests: true,
                      sidebarOpen: false,
                    });
                    reloadFilters(true, 'parking').then((res) =>
                      getWorkRequests('parking', false, res)
                    );
                  }}
                >
                  Parking
                </Col>
              </Link>
            </>
          )}
          <Col xs={6} sm={12} lg={12} xl={6} />
        </Row>
        <Row
          className="FiltersGrid"
          style={{ margin: '0px', padding: '0px 15px' }}
        >
          {currentServices.map((filter, filterIndex) => {
            const isSelected = filter.selected;
            const selectedStyle =
              'invert(51%) sepia(100%) saturate(1121%) hue-rotate(343deg) brightness(99%) contrast(98%)';
            const toBlack =
              'invert(53%) sepia(0%) saturate(0%) hue-rotate(275deg) brightness(95%) contrast(85%)';
            let icon;
            if (filter.attributes && !filter.attributes.customIcon) {
              icon = 'personal_emergency.png'.split('.');
            } else if (filter.attributes) {
              icon = filter.attributes.customIcon.split('.');
            } else {
              icon = 'Not loaded';
            }
            return (
              <Col
                className="Filter"
                role="button"
                tabIndex={0}
                xs={4}
                sm={6}
                lg={6}
                xl={4}
                key={`filterCurrServ-${filterIndex}`}
                onClick={() => {
                  for (let i = 0; i < currentServices.length; i++) {
                    if (currentServices[i].value === filter.value) {
                      currentServices[i].selected = !isSelected;
                    }
                  }
                  setState({ currentServices });
                  getWorkRequests(serviceType);
                }}
              >
                <img
                  className="FilterIcon"
                  alt="FilterIcon"
                  src={`${ICON_IMAGE_URL}${icon[0]}.${icon[1]}?cityId=${currentCity.value}`}
                  style={{
                    filter: isSelected ? selectedStyle : toBlack,
                  }}
                />

                <p className="FilterTitle">{filter.label}</p>
              </Col>
            );
          })}
        </Row>
      </section>
    );
  };

  favoriteLocation = () => {
    const { customerPremises, authenticatedState, changeLocation } = this.props;
    if (authenticatedState) {
      return (
        <section
          className="Cardt"
          style={{ maxHeight: '30vh', overflowX: 'auto' }}
        >
          <div className="CardTitle">
            <img
              className="sidebarIcon"
              src="icons/favLocation.svg"
              alt="filter"
            />
            Favourite locations
          </div>

          {customerPremises.map((fav, i) => (
            <div
              role="button"
              tabIndex={0}
              onClick={() =>
                changeLocation({ lng: fav.longitude, lat: fav.latitude })
              }
              className="Favorite"
              key={`favLocs-${i}`}
            >
              <div className="FavInfo">
                <p className="FavTitle">{fav.description}</p>
                <p className="FavLocation">
                  {fav.buildingName}, {fav.streetNumber} {fav.streetName},{' '}
                  {fav.surburb}
                </p>
              </div>
            </div>
          ))}
          {customerPremises.length === 0 ? (
            <div role="button" tabIndex={0} className="Favorite">
              <div className="FavInfo">
                <p className="FavTitle">You have no saved locations</p>
                <p className="FavLocation">
                  Add one in the profile settings page.
                </p>
              </div>
            </div>
          ) : null}
        </section>
      );
    }
    return null;
  };

  myReports = () => {
    const {
      currentWorkRequest,
      serviceType,
      setState,
      authenticatedState,
      myWorkRequests,
      globalMap,
    } = this.props;
    const { searchReports } = this.state;
    if (!authenticatedState) {
      return null;
    }
    if (`${serviceType}` === 'emergency') {
      return null;
    }
    return (
      <section className="Cardt">
        <div className="CardTitle">
          <img className="sidebarIcon" src="map/reports.svg" alt="filter" />
          {}
          {`${serviceType}` === 'municipal' ? 'My reports' : null}
          {`${serviceType}` === 'petitions' ? 'My petitions' : null}
          <div
            className="reportsCounter"
            style={
              myWorkRequests.length < 10
                ? { fontSize: '13px' }
                : { fontSize: '10px' }
            }
          >
            {myWorkRequests.length < 10 ? myWorkRequests.length : '9+'}
          </div>
        </div>
        <Form.Control
          size="sm"
          type="text"
          placeholder="Search your reports"
          style={{ margin: '0px 20px 0px 20px', width: 'initial' }}
          value={searchReports}
          onChange={(event) =>
            this.setState({ searchReports: event.target.value })
          }
        />
        {myWorkRequests.map((workRequest, i) => {
          if (
            workRequest.description.toLowerCase().includes(searchReports) ||
            workRequest.code.toLowerCase().includes(searchReports)
          ) {
            return (
              <div
                className="Report"
                role="menuitem"
                tabIndex={0}
                key={`workRequestReports-${i}`}
                style={
                  workRequest.id === Number(currentWorkRequest)
                    ? {
                        backgroundColor: 'rgb(255, 217, 176)',
                        cursor: 'pointer',
                      }
                    : {
                        cursor: 'pointer',
                      }
                }
                onClick={() => {
                  globalMap.flyTo([workRequest.lat, workRequest.lng], 16, 2);
                  setState({ showMarkerViewModalWR: workRequest });
                  this.setState({
                    showMarkerView: true,
                    sidebarOpen: false,
                  });
                }}
              >
                <div className="ReportInfo">
                  <p className="ItemText">{workRequest.code}</p>
                  <p className="ItemText">{workRequest.address}</p>
                  <p className="ItemText">{workRequest.description}</p>
                  <p className="ItemText">
                    <Moment
                      tz={moment.tz.guess()}
                      format="D MMMM YYYY"
                      date={moment.unix(workRequest.createdDate)}
                    />{' '}
                  </p>
                </div>
                <div className="ReportStatus">
                  <img
                    className="ReportStatusIcon"
                    alt="ReportStatusIcon"
                    src="icons/Asset 11.svg"
                  />
                  <br />
                  <p className="ReportStatusText">
                    {workRequest.statusDescription}
                  </p>
                </div>
              </div>
            );
          }
          return null;
        })}
        {myWorkRequests.length === 0 ? (
          <div className="Report" role="menuitem" tabIndex={0}>
            <div className="ReportInfo" style={{ width: '100%' }}>
              <p className="ItemText">You have nothing logged.</p>
            </div>
          </div>
        ) : null}
      </section>
    );
  };

  componentWillUnmount = () => {
    // eslint-disable-next-line
    //this.props.onRef(undefined);
    lottie.destroy('loadingMap');
    lottie.destroy('loadingWorkRequests');
  };

  render() {
    const {
      showAlert,
      alertBody,
      reportModal,
      petitionModal,
      showEditReport,
      showMarkerView,
      showUpVoteReport,
      showSignPetition,
      showEmergencyModal,
      showEmergencyModalStage,
      sidebarOpen,
      useThisLocationForLog,
      showEditReportWR,
      showUpVoteReportWR,
      showSignPetitionWR,
      showMarkerViewModalWR,
      loadingMap,
      loadingPetition,
      changeCityDropDown,
    } = this.state;
    const {
      myLocation,
      currentUser,
      globalMap,
      allLabels,
      availableCities,
      emergencyContacts,
      currentCity,
      customerPremises,
      cityBounds,
      serviceType,
      authenticatedState,
      city,
      toggleWards,
      setState,
      handleChangeCity,
      changeLocation,
      thisOfMapContainerContainer,
      getWorkRequests,
      firebaseUID,
    } = this.props;
    console.warn(
      '====================================Overlay.jsx rendered===================================='
    );
    console.log('loadingMap', loadingMap, firebaseUID);
    return (
      <>
        {/* Alert popup */}
        <Modal
          show={showAlert}
          backdrop="static"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ borderRadius: '10px' }}
          onHide={() => {
            this.setState({
              showAlert: false,
            });
          }}
        >
          <img
            src="map/problemIcon.svg"
            className="modalIcon"
            alt="Modal icon"
          />
          <Modal.Header closeButton style={{ borderBottom: '0px' }} />
          <Modal.Body className="alertBody">{alertBody}</Modal.Body>
        </Modal>

        <LoggingModal
          show={reportModal}
          size="md"
          onHide={() => this.setState({ reportModal: false })}
          icon="map/mapMarker/reportPin.svg"
          body={
            <ReportModal
              {...{
                currentUser,
                globalMap,
                allLabels,
                center: myLocation,
                currentCity,
                customerPremises,
                cityBounds,
                useThisLocationForLog,
                serviceType,
              }}
              setState={(s) => setState(s)}
              thisOfOverlay={this}
              getWorkRequests={(
                forwardServiceType,
                force,
                currentServices,
                authenticatedStateT
              ) =>
                getWorkRequests(
                  forwardServiceType,
                  force,
                  currentServices,
                  authenticatedStateT
                )
              }
            />
          }
        />
        <LoggingModal
          show={petitionModal}
          size="md"
          onHide={() => this.setState({ petitionModal: false })}
          icon="map/mapMarker/petitionPin.svg"
          body={
            <PetitionModal
              {...{
                currentUser,
                globalMap,
                allLabels,
                center: myLocation,
                currentCity,
                customerPremises,
                cityBounds,
                useThisLocationForLog,
                serviceType,
              }}
              setState={(s) => setState(s)}
              thisOfOverlay={this}
              getWorkRequests={(
                forwardServiceType,
                force,
                currentServices,
                authenticatedStateT
              ) =>
                getWorkRequests(
                  forwardServiceType,
                  force,
                  currentServices,
                  authenticatedStateT
                )
              }
            />
          }
        />

        <LoggingModal
          show={showEditReport}
          onHide={() => {
            this.setState({
              showEditReport: false,
              showMarkerView: true,
            });
          }}
          size="md"
          body={
            <EditReportModal
              showEditReportWR={showEditReportWR}
              setState={(s) => this.setState(s)}
              currentUser={currentUser}
              currentLocation={myLocation}
              currentCity={currentCity}
              thisOfOverlay={this}
              getWorkRequests={(
                forwardServiceType,
                force,
                currentServices,
                authenticatedStateT
              ) =>
                getWorkRequests(
                  forwardServiceType,
                  force,
                  currentServices,
                  authenticatedStateT
                )
              }
            />
          }
        />

        <LoggingModal
          show={showUpVoteReport}
          onHide={() =>
            this.setState({
              showUpVoteReport: false,
              showMarkerView: true,
            })
          }
          size="md"
          body={
            <UpVoteReportModal
              showUpVoteReportWR={showUpVoteReportWR}
              setState={(s) => setState(s)}
              currentUser={currentUser}
              currentLocation={myLocation}
              currentCity={currentCity}
              thisOfOverlay={this}
              getWorkRequests={(
                forwardServiceType,
                force,
                currentServices,
                authenticatedStateT
              ) =>
                getWorkRequests(
                  forwardServiceType,
                  force,
                  currentServices,
                  authenticatedStateT
                )
              }
            />
          }
        />

        <LoggingModal
          show={showSignPetition}
          onHide={() =>
            this.setState({
              showSignPetition: false,
              showMarkerView: true,
            })
          }
          size="md"
          body={
            <SignPetitionModal
              showSignPetitionWR={showSignPetitionWR}
              setState={(s) => this.setState(s)}
              currentUser={currentUser}
              currentLocation={myLocation}
              currentCity={currentCity}
              thisOfOverlay={this}
              getWorkRequests={(
                forwardServiceType,
                force,
                currentServices,
                authenticatedStateT
              ) =>
                getWorkRequests(
                  forwardServiceType,
                  force,
                  currentServices,
                  authenticatedStateT
                )
              }
            />
          }
        />

        <LoggingModal
          show={showMarkerView}
          onHide={() => this.setState({ showMarkerView: false })}
          size="md"
          body={
            <MarkerViewModal
              showMarkerViewModalWR={showMarkerViewModalWR}
              serviceType={serviceType}
              setState={(s) => this.setState(s)}
              currentUser={currentUser}
              currentLocation={myLocation}
              currentCity={currentCity}
              authenticatedState={authenticatedState}
              subscriberId={currentCity.attributes.subscriberId}
              getWorkRequests={(
                forwardServiceType,
                force,
                currentServices,
                authenticatedStateT
              ) =>
                getWorkRequests(
                  forwardServiceType,
                  force,
                  currentServices,
                  authenticatedStateT
                )
              }
            />
          }
        />

        <LoggingModal
          show={showEmergencyModal}
          onHide={() =>
            this.setState({
              showEmergencyModal: false,
              showEmergencyModalStage: 0,
            })
          }
          icon="map/emergencyButton.svg"
          size="md"
          body={
            <div className="mapModal">
              {showEmergencyModalStage === 0 ? (
                <div className="emergencyModal">
                  {emergencyContacts.length === 0 ? (
                    <>
                      You have no emergency contacts!
                      <div style={{ marginTop: '12px' }}>
                        Add some by going{' '}
                        <div className="textAccentOrange textAccentBold">
                          {' '}
                          to your profile.{' '}
                        </div>
                      </div>
                      <Link
                        to="/profile/emergencyContact"
                        style={{ display: 'contents', color: 'black' }}
                      >
                        <div
                          className="emergencyButton emergencyButtonOrange"
                          role="button"
                          tabIndex={0}
                          style={{ marginTop: '12px' }}
                        >
                          Yes
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      Do you wish to{' '}
                      <div className="textAccentOrange textAccentBold">
                        {' '}
                        continue{' '}
                      </div>{' '}
                      and alert all your
                      <div className="textAccentOrange textAccentBold">
                        {' '}
                        emergency contacts?{' '}
                      </div>
                      {emergencyContacts.map((contact) => (
                        <div
                          key={contact.contactName}
                          style={{
                            fontFamily: 'GothamMedium',
                            marginTop: '10px',
                          }}
                        >
                          {contact.contactName}: {contact.contactNumber}
                        </div>
                      ))}
                      <div style={{ display: 'flex', marginTop: '20px' }}>
                        <div
                          role="button"
                          className="emergencyButton"
                          tabIndex={0}
                          onClick={() =>
                            this.setState({ showEmergencyModal: false })
                          }
                        >
                          No
                        </div>
                        <div
                          className="emergencyButton emergencyButtonOrange"
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            sendEmergencyMessage(
                              myLocation.lat,
                              myLocation.lng
                            ).then(() => {
                              this.setState({ showEmergencyModalStage: 1 });
                            });
                          }}
                        >
                          Yes
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="emergencyModal">
                  Your emergency contacts have been{' '}
                  <div className="textAccentOrange textAccentBold">
                    {' '}
                    alerted and notified.{' '}
                  </div>
                  <div style={{ display: 'flex', marginTop: '20px' }}>
                    <div
                      role="button"
                      className="emergencyButton"
                      tabIndex={0}
                      onClick={() =>
                        this.setState({ showEmergencyModal: false })
                      }
                    >
                      Close
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        />
        <div className={sidebarOpen ? 'sidebar' : 'sidebar sidebarClosed'}>
          <FloatingNavbar
            changeCityDropDown={changeCityDropDown}
            availableCities={availableCities}
            currentCity={currentCity}
            sidebarOpen={sidebarOpen}
            setState={(s) => this.setState(s)}
            changeLocation={(s) => changeLocation(s)}
            handleChangeCity={(s) => handleChangeCity(s)}
            thisOfMapContainerContainer={thisOfMapContainerContainer}
            city={city}
            serviceType={serviceType}
          />
          {this.loadingWorkRequests()}
          {/* Sidebar body */}
          <div style={{ height: '100%', position: 'relative' }}>
            <Scrollbars
              className="sidebarBody"
              autoHide
              autoHeight
              autoHeightMax={
                window.innerWidth < 768 ? 'calc(100vh - 125px)' : '100vh'
              }
            >
              {/* Search with favs */}
              {this.favoriteLocation()}
              {/* Filters */}
              {this.filters()}
              {/* My reports */}
              {this.myReports()}
              {!authenticatedState ? (
                <div className="unAnuthedFiller">
                  <img
                    className="unAnuthedFillerImg"
                    src="map/signInIcon.svg"
                    alt="signInIcon"
                  />
                  <br />
                  Please sign up or sign in to use the <br />
                  other features of this service.
                </div>
              ) : null}
            </Scrollbars>
          </div>
        </div>
        <div className="sidebarC">
          {/* RIGHT ICONS */}
          <div className="rightSidebar">
            <div className="rightSidebarItemContainer">
              <div className="rightSidebarContainer">
                <div
                  className="rightSidebarItemContainer"
                  style={{
                    flexGrow: '1',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div className="rightSidebarItem">
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (!authenticatedState || loadingMap) {
                          return;
                        }
                        this.setState({
                          showEmergencyModal: !showEmergencyModal,
                        });
                      }}
                    >
                      <img
                        src="map/emergencyButton.svg"
                        alt="emergency button"
                        className={
                          !authenticatedState || loadingMap
                            ? 'emergencyButtonIcon greyImage'
                            : 'emergencyButtonIcon '
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="rightSidebarLine" />

                <div
                  className="rightSidebarItemContainer"
                  style={{
                    alignContent: 'end',
                    display: 'grid',
                  }}
                >
                  <div className="rightSidebarItem">
                    <div
                      className="recenterContainer"
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        globalMap.flyTo([myLocation.lat, myLocation.lng], 15, 2)
                      }
                    >
                      <img
                        className="recentreImg"
                        src="map/recentre.svg"
                        alt="recentre"
                      />
                    </div>
                  </div>
                  <div className="rightSidebarItem displayNoneOnMobile">
                    <div className="mapControls">
                      <div
                        className="mapControlsItem mapControlsItemPlus"
                        tabIndex={0}
                        role="button"
                        onClick={() => {
                          globalMap.zoomIn();
                        }}
                      >
                        +
                      </div>
                      <div
                        className="mapControlsItem mapControlsItemMinus"
                        tabIndex={0}
                        role="button"
                        onClick={() => {
                          globalMap.zoomOut();
                        }}
                      >
                        -
                      </div>
                    </div>
                  </div>
                  <div className="rightSidebarItem">
                    <Toggle
                      className="toggleWards"
                      onClick={() => {
                        setState({ toggleWards: !toggleWards });
                      }}
                      width={50}
                      height={35}
                      on=""
                      off=""
                      active={toggleWards}
                    />
                    <div
                      style={{ fontSize: '1.2rem', fontFamily: 'GothamMedium' }}
                    >
                      Wards
                    </div>
                  </div>

                  <div className="rightSidebarLine" />
                  {/* REPORT BUTTON */}
                  <div className="rightSidebarItem">
                    <div
                      className="reportButton"
                      role="button"
                      tabIndex="0"
                      onClick={() => {
                        if (!authenticatedState || loadingMap) {
                          return;
                        }
                        this.setState({
                          reportModal: true,
                          useThisLocationForLog: {},
                        });
                      }}
                    >
                      <img
                        className={
                          !authenticatedState || loadingMap
                            ? 'reportPin greyImage'
                            : 'reportPin '
                        }
                        src="map/mapMarker/reportPin.svg"
                        alt="Report pin"
                      />

                      <div className="reportPinText pinText">Create Report</div>
                    </div>
                  </div>
                  {/* PETITION BUTTON */}
                  <div className="rightSidebarItem">
                    <div
                      className="petitionButton"
                      role="button"
                      tabIndex="0"
                      onClick={() => {
                        if (!authenticatedState || loadingMap) {
                          return;
                        }
                        this.setState({
                          petitionModal: true,
                          useThisLocationForLog: {},
                        });
                      }}
                    >
                      {(!loadingPetition && (
                        <img
                          className={
                            !authenticatedState || loadingMap
                              ? 'petitionPin greyImage'
                              : 'petitionPin '
                          }
                          src="map/mapMarker/petitionPin.svg"
                          alt="Petition pin"
                        />
                      )) ||
                        null}
                      <div className="petitionPinText pinText">
                        Create Petition
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.loadingMap()}
      </>
    );
  }
}

const LoggingModal = ({
  show,
  onEntered,
  onHide,
  icon,
  body,
  size,
  header,
}) => (
  // Popup for report
  <Modal
    {...{ show, onEntered, onHide }}
    size={size}
    backdrop="static"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    style={{ borderRadius: '10px' }}
  >
    {icon ? <img src={icon} className="modalIcon" alt="Modal icon" /> : null}
    <Modal.Header closeButton style={{ borderBottom: '0px' }}>
      {header}
    </Modal.Header>
    {body}
  </Modal>
);
