/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { ForwardedRef, forwardRef, ReactNode } from 'react';
import StandardCSS from '../../types/standard-css';
import StandardProps from '../../types/standard-props';

type FlexProps = {
  flexDirection?: 'row' | 'column' | 'row-reverse';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-evenly'
    | 'space-between'
    | 'space-around';
  flexWrap?: 'wrap' | 'nowrap' | 'unset';
  gap?: string;
  overrideCss?: SerializedStyles;
  children?: ReactNode;
} & StandardProps;

const Flex = forwardRef(
  (props: FlexProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      flexDirection,
      justifyContent,
      flexWrap,
      gap,
      overrideCss,
      children,
      ...standardProps
    } = props;

    return (
      <div
        onMouseEnter={() =>
          standardProps.onHover && standardProps.onHover(true)
        }
        onMouseLeave={() =>
          standardProps.onHover && standardProps.onHover(false)
        }
        ref={ref}
        css={css`
          ${StandardCSS(standardProps)}
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-direction: ${flexDirection};
          flex-wrap: ${flexWrap};
          justify-content: ${justifyContent};
          gap: ${gap};
          ${overrideCss}
        `}
      >
        {children}
      </div>
    );
  }
);

export default Flex;
