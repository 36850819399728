/* eslint-disable max-len */
import React, { useState, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import SEO from 'components/seo';
import Footer from 'components/footer';
import Section, { PageWrapper } from 'components/section';
import Hero, { DownloadBar, HeroCarousel } from 'components/home/hero';
import { Card, Filter } from 'components/blog';
// import Chatbot from 'components/chatbot';
import debounce from 'lodash.debounce';

const blogposts = [
  {
    link: '/blog/post/7',
    image: 'blog/post7/preview.jpg',
    title: 'Celebrating Youth Month: The Passionate Young Team Behind My Smart City',
    date: '17 June 2024',
    tags: [{ name: 'Press Releases', value: 'press-releases' }],
    text: "Hey everyone! As we celebrate Youth Month, we at Acumen Software want to give a huge shoutout to the amazing young people who make My Smart City...",
    imageHeight: '450px',
  },
  {
    link: '/blog/post/5',
    image: 'blog/post5/preview.jpg',
    title: 'MY SMART CITY LAUNCHES IN ETHEKWINI',
    date: '1 March 2022',
    tags: [{ name: 'Press Releases', value: 'press-releases' }],
    text: 'After our initial launch over 6 months ago, we’ve connected the residents of Cape Town, Joburg, Ekurhuleni and Tshwane to My Smart City...',
    imageHeight: '450px',
  },
  {
    link: '/blog/post/6',
    image: 'blog/post6/preview.jpg',
    title: 'My Smart City’s Social Feed is here!',
    date: '15 February 2022',
    tags: [{ name: 'Press Releases', value: 'press-releases' }],
    text: 'Today Acumen Software officially launched the My Smart City Social Feed . The newly integrated function on an already robust My Smart City...',
    imageHeight: '450px',
  },
  {
    link: '/blog/post/4',
    image: 'blog/post4/preview.jpg',
    title: 'WE HAVE A WINNER TO OUR MAURITIUS TRIP',
    date: '31 January 2022',
    tags: [{ name: 'Events', value: 'events' }],
    text: 'In October 2021, we launched our Mauritius Holiday giveaway which was met with loads of excitement. Thousands of excited My Smart City fans...',
    imageHeight: '450px',
  },
  {
    link: '/blog/post/1',
    image: 'blog/post1/preview.jpg',
    title: 'What is My Smart City',
    date: '21 July 2021',
    tags: [{ name: 'How-to guides', value: 'how-to-guides' }],
    text: 'Simply put My Smart City is a community platform that empowers citizens with a means of engaging with service providers in their city...',
    imageHeight: '450px',
  },
  {
    link: '/blog/post/3',
    image: 'blog/post3/preview.jpg',
    title: 'Officially launched in Johannesburg and Cape Town',
    date: '15 July 2021',
    tags: [{ name: 'Press Releases', value: 'press-releases' }],
    text: 'Today Acumen Software officially launched the MySmart.City citizen platform in Johannesburg and Cape Town...',
    imageHeight: '450px',
  },
  {
    link: '/blog/post/2',
    image: 'blog/post2/preview.jpg',
    title: 'What Is The PoPI Act?',
    date: '8 July 2021',
    tags: [{ name: 'By-laws', value: 'by-laws' }],
    text: "You may have noticed a number of emails finding their way into your inbox recently informing you of your service providers' compliance with the PoPI Act...",
    imageHeight: '450px',
  },
];

export default function BlogPage() {
  const [posts, setPosts] = useState(blogposts);

  const onCategoryChange = (category) => {
    if (category === '') {
      setPosts(blogposts);
    } else {
      // eslint-disable-next-line max-len
      const filtered = blogposts.filter(
        (post) => post.tags.filter((tag) => tag.value === category).length > 0
      );
      setPosts(filtered);
    }
  };

  const onQueryChange = (query) => {
    if (query === '') {
      setPosts(blogposts);
    } else {
      // eslint-disable-next-line max-len
      const filtered = posts.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      console.log('query results: ', filtered);
      setPosts(filtered);
    }
  };

  const debouncedQuerySearch = useMemo(() => debounce(onQueryChange, 300), []);

  return (
    <PageWrapper>
      <SEO
        title="My Smart City | Blog | All About Your City"
        description="Follow the My Smart City Blog for news, insights, tips and tricks and more. Everything that you need to know in order to make your city a Smart City."
      />
      <HeroCarousel excludeExtraSlides>
        <Hero
          image="blog/hero.jpg"
          title="My Smart City Blog"
          desktopSubtitle="Everything you need to know about My Smart City,
            common issues experienced by citizens and what is
            going on in your city."
          mobileWidth="90%"
        />
      </HeroCarousel>
      <DownloadBar dark />
      <Section color="white">
        <Filter
          onCategoryChange={onCategoryChange}
          onQueryChange={debouncedQuerySearch}
        />
        <Row noGutter>
          {posts?.map((post) => (
            <Col xl={4} lg={5} md={6} sm={6} xs={12}>
              <Link to={post.link}>
                <Card
                  image={post.image}
                  title={post.title}
                  date={post.date}
                  tags={post.tags}
                  text={post.text}
                  imageHeight={post.imageHeight}
                />
              </Link>
            </Col>
          ))}
        </Row>
      </Section>
      <Footer />
      {/* <Chatbot /> */}
    </PageWrapper>
  );
}
