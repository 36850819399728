import { css } from '@emotion/react';
import Form from 'components/form';
import { Checkbox, InputField } from 'components/form/input';
import SubmitButton from 'components/form/submit-button';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { Button } from 'components/elements';
import { useCallback, useEffect, useState } from 'react';
import { apiUrl } from 'constants/app';

import theme from 'theme';
import ExternalLink from 'components/elements/external-link';
import { Loader } from 'components/inthecity';
import { useGlobalContext } from 'contexts/global';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { auth } from 'interface/firebase';
import Lottie from 'react-lottie';
import { validateEmail, validateMobileNumber } from 'functions/form';
import { UserEvents, logFirebaseEvent } from 'functions/firebase';
import animationData from './email.json';

const SignUp = ({ returnToLogin }: { returnToLogin: () => void }) => {
  const [createdPassword, setCreatedPassword] = useState('');
  const [signUpError, setSignUpError] = useState<any>(null);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [sendVerificationEmail, setSendVerificationEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const { globalState } = useGlobalContext();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleEmailSignup = useCallback((data: any) => {
    // Create user on Firebase using email\
    logFirebaseEvent(UserEvents.Register, { ...data });
    setLoading(true);
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        const { user } = userCredential;
        // @ts-ignore
        const { uid } = user;
        const smartCityUser = {
          fullName: data.fullName,
          email: data.email,
          password: data.password,
          firebaseUid: uid,
          mobileNumber: data.mobileNumber,
          accepttc: true,
          acceptMarketing: data.acceptmarketing === 'on',
          currentCitySubscriberId: globalState.city.value || 431,
        };
        // Create user on Forcelink using email
        fetch(`${apiUrl}smartcitymanager/signUp2`, {
          method: 'POST',
          body: JSON.stringify(smartCityUser),
          headers: {
            Accept: 'application/json, text/javascript; q=0.01',
            'Content-Type': 'application/problem+json',
          },
        })
          .then((res) => {
            setSendVerificationEmail(true);
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .catch((error) =>
            error.text().then((errorAsText: string) => {
              setSignUpError({ message: errorAsText });
              setLoading(false);
            })
          );
      })
      .catch((error) => {
        setSignUpError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (sendVerificationEmail) {
      // Send verification email
      if (auth.currentUser) {
        sendEmailVerification(auth.currentUser)
          .then(() => {
            setLoading(false);
            setSignUpSuccess(true);
          })
          .catch((e) => {
            setSignUpError(e);
          });
      }
    }
  }, [sendVerificationEmail]);

  if (loading) return <Loader />;

  return (
    <>
      {signUpSuccess ? (
        <Flex
          width="100%"
          justifyContent="center"
          fontSize="18px"
          flexDirection="column"
        >
          <Text textAlign="center" fontWeight="bold" fontSize="18px">
            Thank you for creating a My Smart City account!
          </Text>
          <Box my="30px">
            <Lottie options={defaultOptions} height={250} width={250} />
          </Box>
          <Text textAlign="center" pb="20px">
            We have sent you a verification email. After verifying your email,
            please&nbsp;
            <Button
              color={theme.colors.primary}
              fontWeight="bold"
              onClick={returnToLogin}
            >
              sign in.
            </Button>
          </Text>
        </Flex>
      ) : (
        <Flex
          maxHeight="500px"
          overflowY="scroll"
          flexDirection="column"
          maxWidth="470px"
        >
          <Text textAlign="center" mt="10px" mb="10px">
            Welcome to My Smart City! Please use the form below to register your
            account, or sign up using your social media profile.
          </Text>
          <Flex width="100%" justifyContent="center" mb="20px">
            <Text>Already have an account?&nbsp;</Text>
            <Button
              color={theme.colors.primary}
              fontWeight="bold"
              onClick={returnToLogin}
            >
              Sign in.
            </Button>
          </Flex>
          <Form onSubmit={(d) => handleEmailSignup(d)} dataTestId="signup-form">
            <Grid
              gridRowGap="20px"
              px="30px"
              mobileCss={css`
                padding: 0 10px;
              `}
            >
              {signUpError && <Text color="red">{signUpError.message}</Text>}
              <InputField
                instructions="Make sure this matches your ID"
                label="Name"
                dataTestId="signup-name"
                name="fullName"
                placeholder="Name and surname"
                required
                validate={(e) => {
                  const v = e.target.value;
                  if (v.length === 0) {
                    return {
                      field: 'fullName',
                      valid: false,
                      message: 'Please enter your name',
                    };
                  }
                  return { field: 'fullName', valid: true, message: '' };
                }}
              />
              <InputField
                dataTestId="signup-cellphone"
                instructions="This number will be used for account recovery."
                label="Cellphone"
                name="mobileNumber"
                type="mobileNumber"
                placeholder="Mobile number"
                required
                validate={(e) => {
                  const v = e.target.value;
                  if (!validateMobileNumber(v)) {
                    return {
                      field: 'mobileNumber',
                      valid: false,
                      message: 'Please enter a valid cellphone number',
                    };
                  }
                  return {
                    field: 'mobileNumber',
                    valid: true,
                    message: '',
                  };
                }}
              />
              <InputField
                dataTestId="signup-email"
                label="Email"
                name="email"
                placeholder="Email"
                required
                validate={(e) => {
                  const v = e.target.value;
                  if (v.length > 0 && !validateEmail(v)) {
                    return {
                      field: 'email',
                      valid: false,
                      message: 'Invalid email address',
                    };
                  }
                  if (v.length === 0) {
                    return {
                      field: 'email',
                      valid: false,
                      message: 'Please enter your email',
                    };
                  }
                  return { field: 'email', valid: true, message: '' };
                }}
              />
              <InputField
                dataTestId="signup-password"
                type="password"
                instructions="Your password must contain more than 6 characters"
                label="Password"
                name="password"
                placeholder="Password"
                required
                validate={(e) => {
                  const v = e.target.value;
                  setCreatedPassword(v);
                  if (v.length < 6) {
                    return {
                      field: 'password',
                      valid: false,
                      message:
                        'Your password must contain more than 6 characters',
                    };
                  }
                  return { field: 'password', valid: true, message: '' };
                }}
              />
              <InputField
                dataTestId="signup-password-confirm"
                type="password"
                label="Confirm Password"
                name="password-confirm"
                placeholder="Confirm Password"
                validate={(e) => {
                  const v = e.target.value;
                  if (v.length < 6 || v !== createdPassword) {
                    return {
                      field: 'password',
                      valid: false,
                      message: 'Passwords do not match',
                    };
                  }
                  return { field: 'password', valid: true, message: '' };
                }}
                required
              />
              <Checkbox
                dataTestId="signup-accept-terms"
                name="accepttc"
                required
                label={
                  <Text>
                    <span>
                      I am over the age of 18 and I agree to My Smart
                      City&apos;s&nbsp;
                      <ExternalLink
                        href="/terms-and-conditions#introduction"
                        color={theme.colors.blue}
                      >
                        Terms and Conditions
                      </ExternalLink>
                      &nbsp;and&nbsp;
                      <ExternalLink
                        href="/terms-and-conditions#privacy-policy"
                        color={theme.colors.blue}
                      >
                        Privacy Policy
                      </ExternalLink>
                      &nbsp;terms.
                    </span>
                  </Text>
                }
              />
              <Checkbox
                name="acceptmarketing"
                label={
                  <Text>
                    I want to be an active citizen and agree to receive
                    marketing communications from My Smart City
                  </Text>
                }
              />
              <SubmitButton label="Sign up" dataTestId="signup-submit" />
            </Grid>
          </Form>
        </Flex>
      )}
    </>
  );
};

export default SignUp;
