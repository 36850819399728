import { Modal } from 'components/elements';
import { useState } from 'react';
import { CustomerBooking, ReviewPost } from 'types/types';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useGlobalContext } from 'contexts/global';
import ServiceRating from './service-rating';
import RatingComment from './service-rating-comment';
import RatingConfirm from './service-rating-confirm';

/** Rating steps
 * 0 - Star rating
 * 1 - Comment
 * 2 - Confirm
 */

const RateResourceModal = ({
  booking,
  onClose,
  isOpen,
}: {
  booking: CustomerBooking;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const {
    globalState: { user },
  } = useGlobalContext();
  const { callApi: postReview } = useSmartCityManager(
    SCManagerEndpoints.AddStarRating
  );
  const [ratingStep, setRatingStep] = useState(0);
  const [review, setReview] = useState<ReviewPost>({
    workOrderId: booking.workOrderId,
    resourceId: booking.resourceId,
    starRating: 0,
    comment: '',
    reviewByUser: true,
    reviewer_uuid: user.uuid,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onBack={ratingStep === 1 ? () => setRatingStep(0) : undefined}
      title="Rate Booking"
    >
      {ratingStep === 0 && (
        <ServiceRating
          booking={booking}
          setStarRating={(starRating: number) =>
            setReview({ ...review, starRating })
          }
          onNext={() => setRatingStep(1)}
        />
      )}
      {ratingStep === 1 && (
        <RatingComment
          booking={booking}
          starRating={review.starRating}
          setReviewComment={(comment: string) =>
            setReview({ ...review, comment })
          }
          onNext={() => {
            postReview({
              body: review,
            });
            setRatingStep(2);
          }}
        />
      )}
      {ratingStep === 2 && (
        <RatingConfirm
          booking={booking}
          close={() => {
            onClose();
            window.location.reload();
          }}
        />
      )}
    </Modal>
  );
};

export default RateResourceModal;
