import { useState } from 'react';

const useGetWardcouncillors = () => {
  const [wardCouncillors, setWardCouncillors] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getWardCouncillors = async () => {
    setLoading(true);
    setError(null);
    fetch('https://www.george.gov.za/wp-json/wp/v2/pages/898')
      .then((res) => res.json())
      .then((res) => {
        const htmlString: string = res.content.rendered
          .replaceAll('\\"', '"')
          .replaceAll('<\\', '<')
          .replaceAll('<ul class="cut-member-list">', '')
          .replaceAll('</ul>', '')
          .replaceAll('\\n', '')
          .replaceAll('</li>', '</li>~');

        setWardCouncillors(htmlString);
        setLoading(false);
      })
      .catch((e) => setError(e));
  };

  return { wardCouncillors, loading, error, getWardCouncillors };
};

export default useGetWardcouncillors;
