import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import PROTECTED_ROUTES from 'constants/protected-routes';
import { useGlobalContext } from 'contexts/global';
import { useCallback } from 'react';
import { HomePageEvents, logFirebaseEvent } from 'functions/firebase';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';

type LinkPropsType = {
  title: string;
  description: string;
  icon: string;
  image: string;
};
type MoreFeaturesLinkProps = {
  linkProps: LinkPropsType;
  to: string;
  event: HomePageEvents;
};

const moreFeaturesLinks: MoreFeaturesLinkProps[] = [
  {
    linkProps: {
      title: 'Emergency Services',

      description: 'Police, security and health emergency services.',
      icon: 'home/icons/emergency.svg',
      image: 'new-homepage/emergency-image.png',
    },
    to: '/map/cape_town/emergency',
    event: HomePageEvents.EmergencyServices,
  },
  {
    linkProps: {
      title: 'Airtime and Data',
      image: 'inthecity/tilesNaming/banner_Group_3518.png',
      description:
        'Purchase airtime and data for your favourite mobile provider.',
      icon: 'new-homepage/purchase.svg',
    },

    to: '/in-the-city/purchase/airtime',
    event: HomePageEvents.AirtimeAndData,
  },
  {
    linkProps: {
      title: 'Lotto',
      description: 'Purchase lotto tickets from the palm of your hand.',
      icon: 'new-homepage/purchase.svg',
      image: 'new-homepage/lotto.png',
    },
    to: '/in-the-city/purchase/lotto',
    event: HomePageEvents.Lotto,
  },
  {
    linkProps: {
      title: 'Places of Interest',
      description: 'Explore your city.',
      icon: 'new-homepage/location-icon.svg',
      image: 'new-homepage/places-of-interest.png',
    },
    to: '/in-the-city/places-of-interest',
    event: HomePageEvents.PlacesOfInterest,
  },
  {
    linkProps: {
      title: 'Events',
      description: 'See what\u2019s on around you.',
      icon: 'new-homepage/calendar.svg',
      image: 'new-homepage/events.png',
    },
    to: '/in-the-city/whats-on-in-your-city',
    event: HomePageEvents.Events,
  },
];

const MoreFeaturesLink = ({
  title,
  description,
  icon,
  image,
  onClick,
}: { onClick: () => void } & LinkPropsType) => (
  <Button
    onClick={onClick}
    borderRadius="12px"
    overrideCss={css`
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: scale(1.04);
        box-shadow: ${theme.elevationShadow};
      }
      @media ${theme.mediaQueries.tabletDown} {
        min-width: 200px;
      }
    `}
  >
    <Flex
      flexDirection="column"
      border={theme.borders.standard1px}
      borderRadius="12px"
      height="100%"
    >
      <Image
        source={image}
        alt={description}
        width="100%"
        mb="10px"
        height="60%"
        objectFit="cover"
        borderRadius="12px 12px 0 0"
      />
      <Box p="10px">
        <Flex alignItems="center">
          <Image source={icon} alt={title} width="20px" mr="8px" />
          <Text fontWeight="bold">{title}</Text>
        </Flex>
        <Text mt="15px" textAlign="left">
          {description}
        </Text>
      </Box>
    </Flex>
  </Button>
);

const MoreFeaturesSection = () => {
  const navigate = useNavigate();
  const {
    globalState: {
      user: { uuid },
    },
    setShowLoginModal,
  } = useGlobalContext();
  console.log('HERE', uuid);

  const clickHandler = useCallback(
    (to: string) => {
      if (
        !uuid ||
        uuid === 'init' ||
        uuid === '' ||
        (uuid === 'guest' &&
          PROTECTED_ROUTES.some((route) => to.includes(route)))
      ) {
        setShowLoginModal(true);
      } else {
        navigate(to);
      }
    },
    [uuid]
  );

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      width="100%"
      py="70px"
      px="10%"
      mobileCss={css`
        padding: 10% 0;
      `}
      tabletCss={css`
        padding: 10% 0;
      `}
    >
      <Text
        textAlign="center"
        fontSize="35px"
        mb="10px"
        fontWeight="bold"
        mobileCss={css`
          font-size: 30px;
        `}
      >
        More Features
      </Text>
      <Text
        textAlign="center"
        fontSize="20px"
        px="20%"
        mb="20px"
        mobileCss={css`
          padding: 0 4%;
          font-size: 18px;
        `}
      >
        Contact emergency services, purchase airtime, and more
      </Text>
      <Grid
        gridTemplateColumns="repeat(5, 1fr)"
        gridColumnGap="15px"
        hideScrollBar
        overrideCss={css`
          @media ${theme.mediaQueries.tabletDown} {
            padding: 0;
            padding-bottom: 10px;
            overflow-x: scroll;
            overflow-y: unset;
            display: flex;
          }
        `}
      >
        <Box hideForDesktop width="10px" />
        {moreFeaturesLinks.map((link) => (
          <MoreFeaturesLink
            {...link.linkProps}
            onClick={() => {
              navigate(link.to);
              logFirebaseEvent(link.event);
            }}
          />
        ))}
        <Box hideForDesktop width="10px" />
      </Grid>
    </Flex>
  );
};

export default MoreFeaturesSection;
