import React, { useState } from 'react';
import styled from 'styled-components';

import {
  StyledFilterBar, SearchInput, Select, Option,
} from './blog.styled';

export function Card(props) {
  const {
    image, title, date, text, imageHeight, cardHeight, tags,
  } = props;

  const StyledCard = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    margin: 0 2vw 8rem;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    height: ${cardHeight || 'auto'};
    @media only screen and (max-width: 650px) {
      margin: 0 0 2rem;
    }
    &:hover {
      transform: scale(1.015);
      background: #e1753110;
    }
  `;

  const Image = styled.div`
    background-image: url(${image});
    background-size: cover;
    background-position: center;
    height: ${imageHeight || '500px'};
  `;

  const Content = styled.div`
    padding: 2rem;
  `;

  const Title = styled.div`
    color: #e17531;
    font-size: 2rem;
    line-height: 2rem;
    font-family: GothamMedium;
    margin-bottom: 1.5rem;
    @media only screen and (max-width: 650px) {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
  `;

  const Date = styled.div`
    color: #e17531;
    font-size: 1.5rem;
    font-family: GothamMedium;
    font-style: italic;
    margin-bottom: 1.5rem;
    @media only screen and (max-width: 650px) {
      font-size: 1.25rem;
    }
  `;

  const Text = styled.div`
    color: #333333;
    font-size: 1.5rem;
    line-height: 1.75rem;
    @media only screen and (max-width: 650px) {
      font-size: 1rem;
      line-height: 1rem;
    }
  `;

  const TagsRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1.5rem;
  `;

  const Tag = styled.div`
    background: #e17531;
    color: white;
    font-family: GothamMedium;
    font-size: 0.9rem;
    line-height: 1rem;
    padding: 0.4rem 0.8rem;
    border-radius: 14px;
    min-width: fit-content;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    transition: all 0.15s ease-in-out;
  `;

  return (
    <StyledCard>
      <Image />
      <Content>
        <Title>{title}</Title>
        <Date>{date}</Date>
        <TagsRow>
          {tags?.map((tag) => <Tag>{tag.name}</Tag>)}
        </TagsRow>
        <Text>{text}</Text>
      </Content>
    </StyledCard>
  );
}

export const Filter = ({ onCategoryChange, onQueryChange }) => {
  const [category, setCategory] = useState('');
  const [query, setQuery] = useState('');

  const categoriesOptions = [
    { name: 'All Categories', value: '' },
    { name: 'New Functionality', value: 'new-functionality' },
    { name: 'Events', value: 'events' },
    { name: 'Press Releases', value: 'press-releases' },
    { name: 'City News', value: 'city-news' },
    { name: 'How-to guides', value: 'how-to-guides' },
    { name: 'Accolades', value: 'accolades' },
    { name: 'Water', value: 'water' },
    { name: 'Roads', value: 'roads' },
    { name: 'Electricity', value: 'electricity' },
    { name: 'By-laws', value: 'by-laws' },
    { name: 'Transport', value: 'transport' },
    { name: 'Social Development', value: 'social-development' },
    { name: 'Building Works', value: 'building-works' },
    { name: 'Emergency', value: 'emergency' },
    { name: 'Parks', value: 'parks' },
    { name: 'Health & Safety', value: 'health-safety' },
    { name: 'Refuse', value: 'refuse' },
  ];

  const onSelect = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setCategory(val);
    onCategoryChange(val);
  };

  const onSearch = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setQuery(val);
    onQueryChange(val);
  };

  return (
    <StyledFilterBar>
      <Select
        onChange={onSelect}
        value={category}
      >
        <Option disabled value="">Categories</Option>
        {categoriesOptions.map(({ name, value }) => (
          <Option value={value}>{name}</Option>
        ))}
      </Select>
      <SearchInput
        placeholder="Search"
        onChange={onSearch}
        value={query}
        icon="blog/search.svg"
      />
    </StyledFilterBar>
  );
};
