import styled from 'styled-components';

import { Subtitle } from 'components';
import { GenericDropdown } from '../navbar/navbar.styled';

export const Container = styled.div`
  position: absolute;
  top: 8px;
  left: calc(50% - 162px);
`;

export const MobileContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: #FFFFFF;
  height: 57px;
`;

export const SearchInput = styled.input`
  width: 324px;
  height: 41px;
  background: url('social/search-icon.svg') 5% 50% no-repeat padding-box;
  background-color: #f0f2f3;
  padding-left: 55px;
  border-radius: 2px;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 21px;
  font-style: normal;
  font-variant: normal;
  color: #333333;
  border: 1px solid #f0f2f3;
  transition: 0.2s ease-in-out;
  &::placeholder {
    color: #B2BCC1;
    opacity: 1;
    border: none;
  }
  &:hover {
    box-shadow: 0 0 0 3px #DF6E213E;
  }
  &:focus {
    outline: none;
    border: 1px solid #DF6E21;
    box-shadow: 0 0 0 3px #DF6E213E;
  }
  &::selection {
    background: #DF6E21;
    color: white;
  }
`;

export const MobileSearchInput = styled.input`
  height: 36px;  
  background: url('social/search-icon.svg') 14px 50% no-repeat padding-box;
  background-color: rgba(178, 188, 193, 0.2);

  padding-left: 50px;
  font: normal normal normal 12px/14px SF Pro, Roboto;
  color: #333333;
  outline: none;
  border: none;
  border-radius: 2px;
  flex: 1;
  margin-right: 20px;
  &::placeholder {
    color: #B2BCC1;
    opacity: 1;
  }
`;
export const Search = styled.input`
  background: #b2bcc133 url('social/search-dark-icon.svg') 19px 50% no-repeat padding-box;
  background-size: 26px;
  height: 54px;
  width: 100%;
  border: none;
  border-radius: 2px;
  padding-left: 63px;
  margin-bottom: 38px;
  font: normal normal normal 14px/21px Roboto;
  color: #333333;
  transition: all 0.2s ease-in-out;
  border: 1px solid #b2bcc133;
  &::placeholder {
    color: #B2BCC1;
    opacity: 1;
  }
  &:hover {
    box-shadow: 0 0 0 3px #DF6E213E;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #DF6E213E, 0 0 0 1px #DF6E21;
  }
`;
export const BackButton = styled.div`
  background: url('social/back-icon.svg') 50% 50% no-repeat padding-box;
  background-size: 10px;
  height: 57px;
  width: 42px;
  cursor: pointer;
`;
export const SearchIcon = styled.div`
  background: url('social/search-icon.svg') 50% 50% no-repeat padding-box;
  height: 57px;
  width: 57px;
  margin-left: 5px;
`;
export const SearchButton = styled.div`
  background: url('social/search-icon.svg') 50% 50% no-repeat padding-box;
  height: 57px;
  width: 30px;
  margin-left: auto;
  cursor: pointer;
  @media only screen and (min-width: 1150px) {
    display: none;
  }
`;
export const MobileDropdown = styled.div`
  z-index: 1;
  background: white;
  top: 57px;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  height: 70vh;
  padding: 20px 24px;
  width: 100%;
  min-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  position: fixed;
  box-shadow: 0 30vh 0 0 #00000021;
  @media only screen and (min-width: 1150px) {
    display: none;
  }
`;

export const ResetButton = styled.div`
  display: ${({ show = true }) => (show ? 'block' : 'none')};
  /* z-index: 3; */
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 5%;
  width: 19px;
  height: 19px;
  background: url('social/clear-icon.svg') 50% 50% no-repeat padding-box;
`;

export const Dropdown = styled(GenericDropdown)`
  top: 65px;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  position: absolute;
  border-radius: 9px;
  padding: 40px;
  width: 691px;
  min-height: 200px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  @media only screen and (max-width: 1150px) {
    display: none;
  }
`;

export const Title = styled(Subtitle)`
  color: #0A0918;
  margin-bottom: 23px;
  font-weight: 500;
`;

export const NotFoundContainer = styled.div`
  text-align: center;
  color: #B2BCC1;
  margin: 25px 70px;
  h2 {
    font: normal normal 500 16px/19px Roboto;
  }
  p {
    font: normal normal normal 10px/12px Roboto;
  }
  @media only screen and (min-width: 800px) {
    margin: 50px 130px;
    h2 {
      font: normal normal 500 20px/26px Roboto;
    }
    p {
      font: normal normal normal 16px/19px Roboto;
    }
  }
`;
