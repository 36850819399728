/* eslint-disable arrow-body-style */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Container, Button } from './tabs.styled';

/**
 * Purchase Tabs Bar
 */
const PurchaseTabs = (props) => {
  const {
    links = [
      {
        id: 0,
        label: 'All',
        link: '/in-the-city/places-of-interest',
      },
    ],
  } = props;
  const location = useLocation();
  const id = location.state?.tab?.id; // defaults to all/featured id
  return (
    <Container>
      {links?.slice(0, 5).map((link, idx) => (
        <Link
          to={link.link}
          state={{ tab: link }}
          key={idx}
        >
          <Button
            active={id ? link.id === id : link.id === links[0].id}
          >
            {link.label === 'Featured' ? 'All' : link.label}
          </Button>
        </Link>
      ))}
    </Container>
  );
};

export default PurchaseTabs;
