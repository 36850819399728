import { MunicipalPageWrapper } from 'components/my-municipality';
import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { useEffect } from 'react';
import {
  CustomerPremises,
  GMPastMunicipalPayment,
  UtilityAccount,
} from 'types/types';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { Loader } from 'components/inthecity';
import UtilityAccountItem from 'components/my-municipality/utility-account-item';
import ExternalLink from 'components/elements/external-link';
import { useNavigate, useParams } from 'react-router-dom';
import theme from 'theme';
import useQuery from 'hooks/use-query';
import { convertToReadableDate } from 'functions/date';

const PastMunicipalPaymentsPage = () => {
  // Hooks
  const navigate = useNavigate();
  const { cityId } = useParams();
  const query = useQuery();

  // API Calls
  const {
    loading: loadingPayments,
    error: paymentsError,
    callApi: getPayments,
    response: payments,
  } = useSmartCityManager(SCManagerEndpoints.GetPaymentHistoryForAccount);

  const {
    loading: loadingPremises,
    callApi: getPremises,
    response: customerPremises,
  } = useSmartCityManager(SCManagerEndpoints.GetCustomerPremises);

  // State management and constants
  const accountNumber = query.get('accountNumber');
  const premiseId = query.get('premiseId');
  const premise: CustomerPremises =
    premiseId &&
    customerPremises?.data?.find(
      (items: CustomerPremises) => items.id === parseInt(premiseId, 10)
    );
  const municipalAccount = premise?.serviceProviderAccounts?.find(
    (items: UtilityAccount) => items.accountNumber === accountNumber
  );
  // Side effects
  useEffect(() => {
    if (accountNumber && premise && municipalAccount) {
      getPayments({
        queryParams: `orgUnitId=${municipalAccount?.orgUnitId}&premiseId=${premiseId}`,
      });
    }
  }, [accountNumber, premise, municipalAccount]);

  useEffect(() => {
    getPremises();
  }, []);

  console.log('payments', payments);

  return (
    <MunicipalPageWrapper>
      {loadingPayments || loadingPremises ? (
        <Loader />
      ) : (
        <Flex
          maxWidth="720px"
          mx="auto"
          width="100%"
          p="24px 28px 54px 28px"
          flexDirection="column"
          borderRadius="4px"
          boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
        >
          {premiseId && accountNumber && (
            <UtilityAccountItem
              account={{
                premiseId: parseInt(premiseId, 10),
                accountNumber,
                orgUnitId: 0,
                orgUnitDescription: 'Municipal Account',
                active: true,
                meters: [],
                externalId: '0',
                statusId: 2,
                status: 'Verified',
              }}
            />
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="2"
            viewBox="0 0 658 2"
            fill="none"
          >
            <path
              d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H658V0H1V2Z"
              fill="#F0F0F0"
            />
          </svg>
          {payments && payments?.length && (
            <Grid mt="36px" gridRowGap="14px">
              {payments?.map((payment: GMPastMunicipalPayment) => (
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  p="16px"
                  boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
                  borderRadius="4px"
                  alignItems="center"
                >
                  <Text>{convertToReadableDate(payment.date)}</Text>
                  <Flex alignItems="center">
                    <Text>R {payment.amount}</Text>
                    <ExternalLink
                      href={payment.docurl}
                      target="__blank"
                      p="6px"
                      borderRadius="4px"
                      backgroundColor={theme.colors.primary}
                      ml="16px"
                    >
                      <Image
                        source="my-municipality/download.svg"
                        alt="download"
                      />
                    </ExternalLink>
                  </Flex>
                </Flex>
              ))}
            </Grid>
          )}
          {/* <Button
            p="14px"
            mt="36px"
            border={`1px solid ${theme.colors.primary}`}
            fontSize="16px"
            color={theme.colors.primary}

          >
            Municipal Accounts
          </Button> */}
        </Flex>
      )}
    </MunicipalPageWrapper>
  );
};

export default PastMunicipalPaymentsPage;
