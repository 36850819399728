import React, {
// useState,
} from 'react';
import {
  Form, Row, Col, Modal, Button,
} from 'react-bootstrap';
import { ICON_IMAGE_URL } from 'constants/app';
import _ from 'lodash';
import {
  isMarkerInsidePolygon,
} from 'functions/map';
import 'styles/mapModal.scss';
import { Context } from 'functions/context';
import {
  MapContainer, TileLayer, Polygon,
} from 'react-leaflet';
import DraggableMarker from 'components/map/mapMarkerModal';
import {
  logWorkRequest,
  addWorkRequestAttachment,
  getMyMapWorkRequests,
  getTopLevelOrgUnits,
  getChildOrgUnits,
  getTopLevelWorkTypes,
  getChildWorkTypes,
  getCustomerPremiseAccountNo,
} from 'interface/map';
import lottie from 'lottie-web';
import { Scrollbars } from 'react-custom-scrollbars';

export default class ReportModal extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      stage: 1,
      selectedPremise: {},
      reSelectedPremise: { premiseId: 0 },
      currentFile1: [],
      currentImage1: {},
      currentFileError1: '',
      currentFile2: [],
      currentImage2: {},
      currentFileError2: '',
      currentFile3: [],
      currentImage3: {},
      currentFileError3: '',
      currentFile4: [],
      currentImage4: {},
      currentFileError4: '',
      wr: { Message: '' },
      loadingReportModal: true,
      loadingReportModalA: true,
      currentLocation: {},
      currentLocationInBounds: false,
      privateFlag: false,
      rootOrgUnits: [],
      cat0: [],
      cat1: [],
      cat1Selected: { value: -1 },
      cat2: [],
      cat2Selected: { value: -1 },
      cat3: [],
      cat3Selected: { value: -1 },
      cat4: [],
      cat4Selected: { value: -1 },

    };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeCurrentLocation = this.handleChangeCurrentLocation.bind(this);
    this.mape = React.createRef();
    this.imageFile1 = React.createRef();
    this.imageFile2 = React.createRef();
    this.imageFile3 = React.createRef();
    this.imageFile4 = React.createRef();
  }

  handleChangeCurrentLocation(currentLocation) {
    const { cityBounds } = this.props;
    let temp = false;
    for (let i = 0; i < cityBounds.length; i++) {
      if (isMarkerInsidePolygon(currentLocation, cityBounds[i])) {
        temp = true;
        break;
      }
    }
    this.setState({
      currentLocationInBounds: temp,
      currentLocation,
    });
  }

  handleChangeName(event) {
    console.log(event.target.value);
    this.setState({ name: event.target.value });
  }

  categoryIcon = (key, filter) => {
    const { cat0 } = this.state;
    let icon;
    if (!filter.attributes.customIcon) {
      icon = 'personal_emergency.png'.split('.');
    } else {
      icon = filter.attributes.customIcon.split('.');
    }
    const selectedStyle = 'invert(51%) sepia(100%) saturate(1121%) hue-rotate(343deg) brightness(99%) contrast(98%)';
    const { currentUser } = this.props;
    console.log('categoryIcon', filter.attributes.customIcon);
    return (
      <Col
        sm={3}
        xs={4}
        style={{
          textAlign: 'center',
          paddingBottom: '20px',
          cursor: 'pointer',
        }}
        onClick={() => this.setState({
          cat0: _.cloneDeep(filter),
        })}
      >
        <img
          src={`${ICON_IMAGE_URL}${icon[0]}.${icon[1]}?cityId=${currentUser.currentCitySubscriberId}`}
          alt="Category icon"
          style={{
            height: '60px',
            paddingBottom: '10px',
            filter: filter.value === cat0.value ? selectedStyle : null,
          }}
        />
        <div style={{ wordBreak: 'break-word' }}>
          {filter.label}
        </div>
      </Col>
    );
  };

  handleSubmit = () => {
    const {
      currentUser,
      // globalMap,
      currentCity,
      useThisLocationForLog,
      getWorkRequests,
      serviceType,
      setState,
    } = this.props;
    const {
      name,
      currentFile1,
      currentFile2,
      currentFile3,
      currentFile4,
      privateFlag,
      selectedPremise,
      cat0,
      cat1Selected,
      cat2Selected,
      cat3Selected,
      cat4Selected,
    } = this.state;

    let { currentLocation } = this.state;

    if (useThisLocationForLog.lat) {
      currentLocation = useThisLocationForLog;
    }

    let orgUnitId = -1;
    // Select the correct org unit ID
    if (cat3Selected.value !== -1 && cat3Selected.attributes && 'drillDownOrgUnits' in cat3Selected.attributes) {
      orgUnitId = cat3Selected.value;
    } else if (cat2Selected !== -1 && cat2Selected.attributes && 'drillDownOrgUnits' in cat2Selected.attributes) {
      orgUnitId = cat2Selected.value;
    } else if (cat1Selected !== -1 && cat1Selected.attributes && 'drillDownOrgUnits' in cat1Selected.attributes) {
      orgUnitId = cat1Selected.value;
    } else {
      orgUnitId = cat0.value;
    }

    let temp = {};
    let se = {};
    if (cat4Selected.value !== -1 && !('drillDownOrgUnits' in cat4Selected.attributes)) {
      console.log('cat4Selected', cat4Selected);
      se = cat4Selected;
    } else if (cat3Selected.value !== -1 && !('drillDownOrgUnits' in cat3Selected.attributes)) {
      console.log('cat3Selected', cat3Selected);
      se = cat3Selected;
    } else if (cat2Selected.value !== -1 && !('drillDownOrgUnits' in cat2Selected.attributes)) {
      console.log('cat2Selected', cat2Selected);
      se = cat2Selected;
    } else {
      console.log('cat1Selected', cat1Selected);
      se = cat1Selected;
    }
    const {
      reSelectedPremise,
    } = this.state;
    if (this.requiresAccountNumber()) {
      if (typeof reSelectedPremise.premiseId === 'undefined') {
        // Create a new favorite location and account number
        console.log('Create a new favorite location and account number');
        temp = {
          description: name,
          premiseId: 0,
          premiseDescription: reSelectedPremise.premiseDescription,
          accountNumber: reSelectedPremise.accountNumber,
          typeId: se.value,
          orgUnitId,
          contactName: currentUser.fullName,
          contactNumber: currentUser.mobileNumber,
          contactEmail: currentUser.username,
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
          privateFlag,
        };
      } else {
        // Create a new account number on the premise
        console.log('Create a new account number on the premise');
        temp = {
          description: name,
          premiseId: selectedPremise.id,
          accountNumber: reSelectedPremise.accountNumber,
          typeId: se.value,
          orgUnitId,
          contactName: currentUser.fullName,
          contactNumber: currentUser.mobileNumber,
          contactEmail: currentUser.username,
          latitude: selectedPremise.latitude,
          longitude: selectedPremise.longitude,
          privateFlag,
        };
      }
    } else if (selectedPremise.id) {
      // Logging a issue on premise location
      console.log('Logging a issue on premise location');
      temp = {
        description: name, // required
        premiseId: selectedPremise.id, // required
        typeId: se.value,
        orgUnitId,
        contactName: currentUser.fullName,
        contactNumber: currentUser.mobileNumber,
        contactEmail: currentUser.username,
        latitude: selectedPremise.latitude,
        longitude: selectedPremise.longitude,
        privateFlag,
      };
    } else {
      // Log at current location
      console.log('Log at current location');
      temp = {
        description: name,
        premiseId: 0,
        typeId: se.value,
        orgUnitId,
        contactName: currentUser.fullName,
        contactNumber: currentUser.mobileNumber,
        contactEmail: currentUser.username,
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
        privateFlag,
      };
    }

    // Set loading to true
    this.setState({
      loadingReportModal: true,
      loadingReportModalA: true,
    });

    console.log('tempWorkRequest:', temp);
    logWorkRequest(JSON.stringify(temp)).then((wr) => {
      if ('id' in wr) {
        const mylittlepromises = [];
        if (currentFile1.length === 1) {
          const formData = new FormData();
          console.log('Uploaded file to', currentFile1[0]);
          formData.append('workRequestId', wr.id);
          formData.append('file', currentFile1[0]);
          mylittlepromises.push(addWorkRequestAttachment(formData));
        }
        if (currentFile2.length === 1) {
          const formData = new FormData();
          console.log('Uploaded file to', currentFile2[0]);
          formData.append('workRequestId', wr.id);
          formData.append('file', currentFile2[0]);
          mylittlepromises.push(addWorkRequestAttachment(formData));
        }
        if (currentFile3.length === 1) {
          const formData = new FormData();
          console.log('Uploaded file to', currentFile3[0]);
          formData.append('workRequestId', wr.id);
          formData.append('file', currentFile3[0]);
          mylittlepromises.push(addWorkRequestAttachment(formData));
        }
        if (currentFile4.length === 1) {
          const formData = new FormData();
          console.log('Uploaded file to', currentFile4[0]);
          formData.append('workRequestId', wr.id);
          formData.append('file', currentFile4[0]);
          mylittlepromises.push(addWorkRequestAttachment(formData));
        }

        Promise.all(mylittlepromises).then((values) => {
          console.log('Uploaded file to', wr, values);
          // Set loading to false
          if (`${serviceType}` === 'municipal') {
            getWorkRequests(
              setState,
              currentLocation,
              null,
              currentCity.attributes.subscriberId,
              true,
            ).then(() => {
              getMyMapWorkRequests(1, 200).then((myWorkRequests) => {
                setState({ myWorkRequests });
                this.setState({ wr, loadingReportModal: false, stage: 7 });
              });
            });
          } else {
            this.setState({ wr, loadingReportModal: false, stage: 7 });
          }
        });
      } else {
        console.log(wr);
        this.setState({ wr, loadingReportModal: false, stage: 8 });
      }
    });
  };

  componentDidMount = async () => {
    const {
      // allLabels,
      center,
      currentCity,
      useThisLocationForLog,
    } = this.props;

    // Loading
    const animLogin1 = lottie.loadAnimation({
      name: 'loginLoadingReportModal',
      container: this.animateLoading1,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'mscLogoGrey.json',
    });

    animLogin1.onLoopComplete = () => {
      const { loadingReportModal, loadingReportModalA } = this.state;
      console.log('onLoopComplete', loadingReportModal, loadingReportModalA);
      if (!loadingReportModal) {
        lottie.stop('loginLoadingReportModal');
        this.setState({ loadingReportModalA: false });
        console.log('onLoopComplete: loginLoadingReportModal STOPPED');
      }
    };

    const rootOrgUnitsTemp = await getTopLevelOrgUnits('MS', currentCity.attributes.subscriberId);
    console.log('getTopLevelOrgUnits', rootOrgUnitsTemp.data);
    if (useThisLocationForLog.lat) {
      this.setState({ stage: 2 });
    } else {
      this.setState({ stage: 1 });
    }
    this.setState({
      currentLocation: center,
      rootOrgUnits: rootOrgUnitsTemp.data,
      loadingReportModal: false,
    });
    this.handleChangeCurrentLocation(center);
  }

  componentWillUnmount = () => {
    lottie.destroy('loginLoadingReportModal');
  };

  createAReport = () => {
    const {
      rootOrgUnits,
      cat0,
      selectedPremise,
    } = this.state;
    const {
      useThisLocationForLog,
    } = this.props;
    return (
      <div className="stage1">
        <div className="title">
          Report category
        </div>
        <div className="subtitle">
          What would you like to report?
        </div>

        <Row className="madalImageRow">
          {rootOrgUnits.map((filter, i) => this.categoryIcon(`caticon${i}`, filter))}
          {/* // console.log('{selectedPremise}', selectedPremise,
          //  JSON.stringify(selectedPremise).localeCompare('{}') !== 0,
          // filter, filter.attributes.requiresAccountNumber === 'true');
          // IF there is a customer premise selected
          // if (JSON.stringify(selectedPremise).localeCompare('{}') !== 0
          // && filter.attributes.requiresAccountNumber === 'true') {
          //   return this.categoryIcon(`caticon${i}`, filter);
          // }
          // // If there is no customer premise selected
          // if (JSON.stringify(selectedPremise).localeCompare('{}') === 0) {
          //   return this.categoryIcon(`caticon${i}`, filter);
          // }
          // return <></>; */}

        </Row>
        <Row className="buttonBar">
          {!('lng' in useThisLocationForLog)
            ? (
              <Button
                className="modalButton"
                onClick={() => {
                  this.setState({ stage: 1 });
                }}
              >
                BACK
              </Button>
            )
            : null }
          {cat0.attributes
            ? (
              <Button
                className="modalButton fr"
                onClick={() => {
                  if (('lng' in useThisLocationForLog) || ('id' in selectedPremise)) {
                    this.setState({
                      stage: 4,
                    });
                    this.getTheNextDropDown(cat0).then((res) => this.setState({
                      cat1: res,
                      cat2: [],
                      cat2Selected: { value: -1 },
                      cat3: [],
                      cat3Selected: { value: -1 },
                      cat4: [],
                      cat4Selected: { value: -1 },
                    }));
                  } else {
                    this.setState({ stage: 3 });
                  }
                }}
              >
                NEXT
              </Button>
            )
            : (
              <Button
                className="buttonGreyed modalButton fr "
              >
                NEXT
              </Button>
            )}
        </Row>
      </div>
    );
  }

  selectLocation = () => {
    const {
      selectedPremise,
    } = this.state;
    const { customerPremises } = this.props;
    console.log('customerPremises', customerPremises);
    return (
      <div className="stage2">
        <div className="title">
          Your locations
        </div>
        <div className="subtitle">
          Which location are you logging this issue at?
        </div>
        <div
          className="subtitle"
          style={{
            fontSize: ' 0.8rem',
            color: ' gray',
          }}
        >
          *Different categories will be available if you select a favorite location.
        </div>
        <Row style={{
          backgroundColor: '#f5d4bd',
          margin: '0px -40px 30px',
          padding: '0px 50px 20px',
        }}
        >
          {customerPremises.map((element) => (
            <Col
              style={{
                padding: '20px 0px 0px',
              }}
              sm={12}
            >
              <Row>
                <Form.Check
                  type="radio"
                  onClick={() => this.setState({ selectedPremise: element })}
                  checked={JSON.stringify(selectedPremise) === JSON.stringify(element)}
                />
                <div className="radioContainer">
                  <div className="radioHeading">
                    {element.description}
                  </div>
                  <div className="radioSubHeading">
                    {element.buildingName !== '' && element.buildingName !== null ? (
                      `${element.buildingName},`) : null}
                    {element.streetNumber !== '' && element.streetNumber !== null ? (
                      `${element.streetNumber},`) : null}
                    {element.streetName !== '' && element.streetName !== null ? (
                      `${element.streetName},`) : null}
                    {element.surburb !== '' && element.surburb !== null ? (
                      `${element.surburb}`) : null}
                  </div>
                </div>
              </Row>
            </Col>
          ))}
          <Col
            style={{
              padding: '20px 0px 0px',
            }}
            sm={12}
          >
            <Row>
              <Form.Check
                type="radio"
                id="default-radio"
                onClick={() => this.setState({ selectedPremise: {} })}
                checked={JSON.stringify(selectedPremise) === JSON.stringify({})}
              />
              <div className="radioContainer">
                <div className="radioHeading">
                  Current location
                </div>
                <div className="radioSubHeading">
                  Placed at your current location
                </div>
              </div>
            </Row>
          </Col>

        </Row>
        <Row
          className="buttonBar"
          style={{
            marginTop: '20px',
          }}
        >
          <Button
            className="modalButton fr"
            onClick={() => {
              this.setState({
                stage: 2,
              });
            }}
          >
            NEXT
          </Button>
        </Row>
      </div>
    );
  }

  selectANewLocation = () => {
    const {
      currentLocation, // current location of marker
      currentLocationInBounds, // current location of marker in bounds
      cat0,
    } = this.state;
    const {
      cityBounds,
      center,
    } = this.props;
    return (
      <div className="stage3">
        <div className="title">
          Location
        </div>
        <div className="subtitle">
          Pinpoint the exact location of the report
        </div>
        <Row>

          {currentLocationInBounds ? null : (
            <div className="mapNotSupport">
              Please choose a location within the orange part of the map.
            </div>
          )}
          <MapContainer
            ref={this.mape}
            center={currentLocation.lat ? currentLocation : center}
            key="test"
            zoom={13}
            style={{
              height: '400px',
              width: '100%',
              borderRadius: '10px',
              margin: '0px 15px 25px',
            }}
          >
            {cityBounds.map((bound) => (
              <Polygon
                positions={bound}
                fillColor="#df6e21"
                fillOpacity="0.1"
                weight="0.5"
                color="#693616"
                interactive={false}
              />
            ))}
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="Copyright 2021 | MySmart.City"
            />
            <DraggableMarker
              center={currentLocation.lat ? currentLocation : center}
              handleChangeCurrentLocation={(s) => this.handleChangeCurrentLocation(s)}
            />
          </MapContainer>
        </Row>
        <Row
          className="buttonBar"
        >
          <Button
            className="modalButton"
            onClick={() => this.setState({ stage: 2 })}
          >
            BACK
          </Button>
          {currentLocationInBounds
            ? (
              <Button
                className="modalButton fr"
                onClick={() => {
                  this.setState({
                    stage: 4,
                  });
                  this.getTheNextDropDown(cat0).then((res) => this.setState({
                    cat1: res,
                    cat2: [],
                    cat2Selected: { value: -1 },
                    cat3: [],
                    cat3Selected: { value: -1 },
                    cat4: [],
                    cat4Selected: { value: -1 },
                  }));
                }}
              >
                NEXT
              </Button>
            )
            : (
              <Button
                className="buttonGreyed modalButton fr"
              >
                NEXT
              </Button>
            )}
        </Row>
      </div>
    );
  }

  getTheNextDropDown = async (catamaran) => {
    let val = [];
    if ('attributes' in catamaran && 'drillDownOrgUnits' in catamaran.attributes) { // Then is it of type org unit
      this.setState({
        loadingReportModal: true,
        loadingReportModalA: true,
      });
      // Use the orgunit call to get more of them
      // getChildOrgUnits
      if (catamaran.attributes.drillDownOrgUnits === 'true') {
        val = await getChildOrgUnits(catamaran.value);
      } else {
        val = await getTopLevelWorkTypes(catamaran.value);
      }
      this.setState({
        loadingReportModal: false,
      });
      return val;
    }
    if (catamaran.attributes.hasChildren === 'true') {
      this.setState({
        loadingReportModal: true,
        loadingReportModalA: true,
      });
      val = await getChildWorkTypes(catamaran.value);
      this.setState({
        loadingReportModal: false,
      });
    }
    return val;
  }

  requiresAccountNumber = () => {
    const {
      cat1,
      cat1Selected,
      cat2,
      cat2Selected,
      cat3,
      cat3Selected,
      cat4,
      cat4Selected,
    } = this.state;
    if (cat4.length === 0 || (cat4.length > 0 && cat4Selected.value !== -1)) {
      if (cat4Selected.attributes && cat4Selected.attributes.requiresAccountNumber.localeCompare('true') === 0) {
        // Requires account number
        return true;
      }
      if (cat3.length === 0 || (cat3.length > 0 && cat3Selected.value !== -1)) {
        if (cat3Selected.attributes && cat3Selected.attributes.requiresAccountNumber.localeCompare('true') === 0) {
          return true;
        }
        if (cat2.length === 0 || (cat2.length > 0 && cat2Selected.value !== -1)) {
          if (cat2Selected.attributes && cat2Selected.attributes.requiresAccountNumber.localeCompare('true') === 0) {
            return true;
          }
          if (cat1.length > 0 && cat1Selected.value !== -1) {
            if (cat1Selected.attributes && cat1Selected.attributes.requiresAccountNumber.localeCompare('true') === 0) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  customerPremiseAccountNo = () => {
    const {
      selectedPremise,
      reSelectedPremise,
      cat0,
      cat1Selected,
      cat2Selected,
      cat3Selected,
      cat4Selected,
    } = this.state;
    if (this.requiresAccountNumber()) {
      // Check if we have this accout number
      let se = {};
      if (cat4Selected.value !== -1 && !('drillDownOrgUnits' in cat4Selected.attributes)) {
        se = cat4Selected;
      } else if (cat3Selected.value !== -1 && !('drillDownOrgUnits' in cat3Selected.attributes)) {
        se = cat3Selected;
      } else if (cat2Selected.value !== -1 && !('drillDownOrgUnits' in cat2Selected.attributes)) {
        se = cat2Selected;
      } else {
        se = cat1Selected;
      }
      getCustomerPremiseAccountNo(selectedPremise.id, cat0.value, se.value).then((res) => {
        if (res.premiseDescription) {
          if (JSON.stringify(res).localeCompare(JSON.stringify(reSelectedPremise)) !== 0) {
            if (res.accountNumber === null) {
              res.requiresAccountNumber = true;
            } else {
              res.requiresAccountNumber = false;
            }
            this.setState({ reSelectedPremise: res });
          }
        } else {
          this.setState({
            reSelectedPremise: {
              accountNumber: null,
              requiresAccountNumber: true,
            },
          });
        }
      });
    }
  }

  validateSelectACategory = () => {
    const {
      reSelectedPremise,
      cat1,
      cat1Selected,
      cat2,
      cat2Selected,
      cat3,
      cat3Selected,
      cat4,
      cat4Selected,
    } = this.state;
    if ((cat1.length > 0 && cat1Selected.value !== -1)
    && (cat2.length === 0 || (cat2.length > 0 && cat2Selected.value !== -1))
    && (cat3.length === 0 || (cat3.length > 0 && cat3Selected.value !== -1))
    && (cat4.length === 0 || (cat4.length > 0 && cat4Selected.value !== -1))) {
      // Category is valid
      if (this.requiresAccountNumber()) {
        if (reSelectedPremise.premiseDescription && reSelectedPremise.premiseDescription.length > 2
          && reSelectedPremise.accountNumber && reSelectedPremise.accountNumber.length > 2) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  }

  selectACategory = () => {
    const {
      selectedPremise,
      reSelectedPremise,
      privateFlag,
      cat0,
      cat1,
      cat1Selected,
      cat2,
      cat2Selected,
      cat3,
      cat3Selected,
      cat4,
      cat4Selected,
    } = this.state;
    const {
      useThisLocationForLog,
    } = this.props;
    console.log('cats',
      cat0,
      cat1,
      cat1Selected,
      cat2,
      cat2Selected,
      cat3,
      cat3Selected,
      cat4,
      cat4Selected);
    console.log('cats valid:',
      cat1.length > 0 && cat1Selected.value !== -1,
      cat2.length === 0 || (cat2.length > 0 && cat2Selected.value !== -1),
      cat3.length === 0 || (cat3.length > 0 && cat3Selected.value !== -1),
      cat4.length === 0 || (cat4.length > 0 && cat4Selected.value !== -1));
    // Check if this catagory needs an account number this is really not a great an attempt.
    return (
      <div className="stage4">
        <div className="title">
          Category
        </div>
        <div className="subtitle">
          Choose a category that best suits your report.
        </div>
        <div className="reportSubCategory">
          {cat1 && cat1.length > 0
            ? (
              <Row style={{ paddingTop: '15px' }}>
                <Col md={12} style={{ padding: '0px' }}>
                  <Form.Label>Sub-category</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      console.log('e.target.value', e.target.value, e.target.value.localeCompare('-1') !== 0);
                      this.setState({
                        cat2: [],
                        cat2Selected: { value: -1 },
                        cat3: [],
                        cat3Selected: { value: -1 },
                        cat4: [],
                        cat4Selected: { value: -1 },
                      });
                      if (e.target.value.localeCompare('-1') !== 0) {
                        const cat22 = await this.getTheNextDropDown(JSON.parse(e.target.value));
                        this.setState({
                          cat2: cat22,
                          cat1Selected: JSON.parse(e.target.value),
                        });
                      } else {
                        this.setState({
                          cat1Selected: { value: -1 },
                        });
                      }
                      this.customerPremiseAccountNo();
                    }}
                  >
                    <option value={-1} id={-1} key="subcat-base" selected>
                      Select one of the following
                    </option>
                    {cat1.map((item, j) => (
                      <option value={JSON.stringify(item)} id={j + 1} key={`subcat${item}${j}`}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            )
            : null}
          {cat2 && cat2.length > 0
            ? (
              <Row style={{ paddingTop: '15px' }}>
                <Col md={12} style={{ padding: '0px' }}>
                  <Form.Label>Sub-category</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      console.log('e.target.value', e.target.value);
                      this.setState({
                        cat3: [],
                      });
                      if (e.target.value !== '-1') {
                        const cat33 = await this.getTheNextDropDown(JSON.parse(e.target.value));
                        this.setState({
                          cat3: cat33,
                          cat2Selected: JSON.parse(e.target.value),
                        });
                      } else {
                        this.setState({
                          cat2Selected: { value: -1 },
                        });
                      }
                      this.customerPremiseAccountNo();
                    }}
                  >
                    <option value={-1} id={-1} key="subcat-base" selected>
                      Select one of the following
                    </option>
                    {cat2.map((item, j) => (
                      <option value={JSON.stringify(item)} id={j + 1} key={`subcat${item}${j}`}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            )
            : null}
          {cat3 && cat3.length > 0
            ? (
              <Row style={{ paddingTop: '15px' }}>
                <Col md={12} style={{ padding: '0px' }}>
                  <Form.Label>Sub-category</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      console.log('e.target.value', e.target.value);
                      if (e.target.value !== '-1') {
                        const cat44 = await this.getTheNextDropDown(JSON.parse(e.target.value));
                        this.setState({
                          cat4: cat44,
                          cat3Selected: JSON.parse(e.target.value),
                        });
                      } else {
                        this.setState({
                          cat3Selected: { value: -1 },
                        });
                      }
                      this.customerPremiseAccountNo();
                    }}
                  >
                    <option value={-1} id={-1} key="subcat-base" selected>
                      Select one of the following
                    </option>
                    {cat3.map((item, j) => (
                      <option value={JSON.stringify(item)} id={j + 1} key={`subcat${item}${j}`}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            )
            : null}

          {cat4 && cat4.length > 0
            ? (
              <Row style={{ paddingTop: '15px' }}>
                <Col md={12} style={{ padding: '0px' }}>
                  <Form.Label>Sub-category</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      console.log('e.target.value', e.target.value);
                      if (e.target.value !== '-1') {
                        this.setState({
                          cat4Selected: JSON.parse(e.target.value),
                        });
                      } else {
                        this.setState({
                          cat4Selected: { value: -1 },
                        });
                      }
                      this.customerPremiseAccountNo();
                    }}
                  >
                    <option value={-1} id={-1} key="subcat-base" selected>
                      Select one of the following
                    </option>
                    {cat3.map((item, j) => (
                      <option value={JSON.stringify(item)} id={j + 1} key={`subcat${item}${j}`}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            )
            : null}

        </div>
        <div style={{ textAlign: 'center' }}>
          <div className="privateFlag">
            <div
              className={privateFlag ? 'privateFlagCheckBox privateFlagCheckBoxChecked' : 'privateFlagCheckBox'}
              role="button"
              tabIndex={0}
              aria-label="Save"
              onClick={() => {
                this.setState({ privateFlag: !privateFlag });
              }}
            >
              {privateFlag ? <img src="map/tick.svg" alt="tick" /> : null}
            </div>
            Keep this report private?
          </div>
        </div>
        {this.requiresAccountNumber()
          ? (
            <div>
              {!reSelectedPremise.requiresAccountNumber
              // Requires account number and has a utility account
                ? (
                  <Col md={12} style={{ padding: '5px' }}>
                    <Form.Label>
                      Using your
                      {' '}
                      <div style={{ fontFamily: 'GothamMedium', display: 'contents' }}>
                        {reSelectedPremise.orgUnitDescription}
                      </div>
                      {' '}
                      account number for
                      {' '}
                      <div style={{ fontFamily: 'GothamMedium', display: 'contents' }}>
                        {reSelectedPremise.premiseDescription}
                        .
                      </div>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={reSelectedPremise.accountNumber}
                      readOnly
                    />
                  </Col>
                )
                : (
                  <div>
                    {selectedPremise.description
                    // Requires account number but has no utility account on favorite location
                      ? (
                        <div>
                          <Col md={12} style={{ padding: '5px' }}>
                            This sub-category requires an account number, please
                            add it below to your favorite location.
                          </Col>
                          <Col md={12} style={{ padding: '5px' }}>
                            <Form.Label>
                              Add your
                              {' '}
                              <div style={{ fontFamily: 'GothamMedium', display: 'contents' }}>
                                {reSelectedPremise.orgUnitDescription}
                              </div>

                              {' '}
                              account number to
                              {' '}
                              <div style={{ fontFamily: 'GothamMedium', display: 'contents' }}>
                                {reSelectedPremise.premiseDescription}
                                .
                              </div>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={reSelectedPremise.accountNumber}
                              onChange={(event) => this.setState({
                                reSelectedPremise: {
                                  premiseId: reSelectedPremise.premiseId,
                                  premiseDescription: reSelectedPremise.premiseDescription,
                                  orgUnitId: reSelectedPremise.orgUnitId,
                                  orgUnitDescription: reSelectedPremise.orgUnitDescription,
                                  accountNumber: event.target.value,
                                  requiresAccountNumber: reSelectedPremise.requiresAccountNumber,
                                },
                              })}
                            />
                          </Col>
                        </div>
                      )
                      : (
                        // Requires account number but has no utility account on favorite location
                        <div>
                          <Col md={12} style={{ padding: '5px' }}>
                            This sub-category requires an account number please
                            add it below by creating a favorite location.
                          </Col>

                          <Col md={12} style={{ padding: '5px' }}>
                            <Form.Label>
                              Favorite location name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={reSelectedPremise.premiseDescription}
                              onChange={(event) => this.setState({
                                reSelectedPremise: {
                                  premiseId: reSelectedPremise.premiseId,
                                  premiseDescription: event.target.value,
                                  orgUnitId: reSelectedPremise.orgUnitId,
                                  orgUnitDescription: reSelectedPremise.orgUnitDescription,
                                  accountNumber: reSelectedPremise.accountNumber,
                                  requiresAccountNumber: reSelectedPremise.requiresAccountNumber,
                                },
                              })}
                            />
                          </Col>
                          <Col md={12} style={{ padding: '5px' }}>
                            <Form.Label>
                              Account number
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={reSelectedPremise.accountNumber}
                              onChange={(event) => this.setState({
                                reSelectedPremise: {
                                  premiseId: reSelectedPremise.premiseId,
                                  premiseDescription: reSelectedPremise.premiseDescription,
                                  orgUnitId: reSelectedPremise.orgUnitId,
                                  orgUnitDescription: reSelectedPremise.orgUnitDescription,
                                  accountNumber: event.target.value,
                                  requiresAccountNumber: reSelectedPremise.requiresAccountNumber,
                                },
                              })}
                            />
                          </Col>
                        </div>
                      )}
                  </div>
                )}
            </div>
          )
          : null}
        <Row className="buttonBar" style={{ marginTop: '40px' }}>
          <Button
            className="modalButton"
            onClick={() => {
              if (useThisLocationForLog.lat) {
                this.setState({ stage: 1 });
              } else if (JSON.stringify(selectedPremise) === JSON.stringify({})) {
                this.setState({ stage: 3 });
              } else {
                this.setState({ stage: 2 });
              }
            }}
          >
            BACK
          </Button>
          { this.validateSelectACategory()
            ? (
              <Button
                className="modalButton fr "
                onClick={() => this.setState({ stage: 5 })}
              >
                NEXT
              </Button>
            )
            : (
              <Button
                className="buttonGreyed modalButton fr "
              >
                NEXT
              </Button>
            )}
        </Row>
      </div>
    );
  }

  setDescription = () => {
    const { name } = this.state;
    return (
      <div className="stage5">
        <div className="title">
          Description
        </div>
        <div className="subtitle">
          Think of a description that best fits your report.
        </div>
        <Row>
          <Form.Group style={{
            margin: '0px 10px 20px',
            width: '100%',
          }}
          >
            <Form.Control
              as="textarea"
              rows={4}
              value={name}
              onChange={this.handleChangeName}
              style={{
                marginBottom: '5px', resize: 'none', width: '100%', borderRadius: '10px',
              }}
              placeholder="Description"
            />

            <Form.Text className="text-muted" style={{ margin: '0px 0px 0px' }}>
              Your description should be between
              {' '}
              <div className="textAccentOrange">3 and 200 characters long.</div>
            </Form.Text>
          </Form.Group>
        </Row>
        <Row className="buttonBar">
          <Button
            className="modalButton"
            onClick={() => this.setState({ stage: 4 })}
          >
            BACK
          </Button>
          {name.length > 3 && name.length < 200 ? (
            <Button
              className="modalButton fr"
              onClick={() => this.setState({ stage: 6 })}
            >
              NEXT
            </Button>
          )
            : (
              <Button
                className="buttonGreyed modalButton fr "
              >
                NEXT
              </Button>
            )}
        </Row>
      </div>
    );
  }

  setImages = () => {
    const {
      currentFile1,
      currentImage1,
      currentFileError1,
      currentFile2,
      currentImage2,
      currentFileError2,
      currentFile3,
      currentImage3,
      currentFileError3,
      currentFile4,
      currentImage4,
      currentFileError4,
    } = this.state;

    const { setState } = this.props;
    return (
      <div className="stage6">
        <div className="title">
          Add a Photo
        </div>
        <div className="subtitle">
          Please add a photo for visual confirmation.
        </div>
        <Scrollbars autoHeight>
          <div style={{ display: 'flex', padding: '0px 0px 20px' }}>
            {currentFile1.length === 1
              ? (
                <div
                  className={currentFileError1 === '' ? 'uploadImageBlock' : 'uploadImageBlock fileError'}
                  style={{ background: `url('${currentImage1}')` }}
                >
                  <div
                    className="imageUploadCrossIconContainer"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.setState({
                        currentFile1: [],
                        currentImage1: {},
                        currentFileError1: '',
                      });
                    }}
                  >
                    <img
                      className="imageUploadCrossIcon"
                      src="map/mapMarker/imageCross.svg"
                      alt="map/mapMarker/imageCross.svg"
                    />

                  </div>
                  <div style={{ height: '135px', width: '135px' }} />
                  <div className="fileErrorText">
                    {currentFileError1}
                  </div>
                </div>
              )
              : (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => (this.imageFile1.current.click())}
                >
                  <img
                    className="imageUploadContainerIcon"
                    src="map/Profile_Picture_Holder.svg"
                    alt="icons/Profile_Picture_Holder.svg"
                  />
                </div>
              )}
            {currentFile2.length === 1
              ? (

                <div
                  className={currentFileError2 === '' ? 'uploadImageBlock' : 'uploadImageBlock fileError'}
                  style={{
                    background: `url('${currentImage2}')`,
                  }}
                >
                  <div
                    className="imageUploadCrossIconContainer"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.setState({
                        currentFile2: [],
                        currentImage2: {},
                        currentFileError2: '',
                      });
                    }}
                  >
                    <img
                      className="imageUploadCrossIcon"
                      src="map/mapMarker/imageCross.svg"
                      alt="map/mapMarker/imageCross.svg"
                    />
                  </div>
                  <div style={{ height: '135px', width: '135px' }} />
                  <div className="fileErrorText">
                    {currentFileError2}
                  </div>
                </div>

              )
              : (

                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => (this.imageFile2.current.click())}
                >

                  <img
                    className="imageUploadContainerIcon"
                    src="map/Profile_Picture_Holder.svg"
                    alt="icons/Profile_Picture_Holder.svg"
                  />
                </div>

              )}
            {currentFile3.length === 1
              ? (

                <div
                  className={currentFileError3 === '' ? 'uploadImageBlock' : 'uploadImageBlock fileError'}
                  style={{
                    background: `url('${currentImage3}')`,
                  }}
                >
                  <div
                    className="imageUploadCrossIconContainer"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.setState({
                        currentFile3: [],
                        currentImage3: {},
                        currentFileError3: '',
                      });
                    }}
                  >
                    <img
                      className="imageUploadCrossIcon"
                      src="map/mapMarker/imageCross.svg"
                      alt="map/mapMarker/imageCross.svg"
                    />
                  </div>
                  <div style={{ height: '135px', width: '135px' }} />
                  <div className="fileErrorText">
                    {currentFileError3}
                  </div>
                </div>

              )
              : (

                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => (this.imageFile3.current.click())}
                >

                  <img
                    className="imageUploadContainerIcon"
                    src="map/Profile_Picture_Holder.svg"
                    alt="icons/Profile_Picture_Holder.svg"
                  />
                </div>

              )}
            {currentFile4.length === 1
              ? (

                <div
                  className={currentFileError4 === '' ? 'uploadImageBlock' : 'uploadImageBlock fileError'}
                  style={{
                    background: `url('${currentImage4}')`,
                  }}
                >
                  <div
                    className="imageUploadCrossIconContainer"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.setState({
                        currentFile4: [],
                        currentImage4: {},
                        currentFileError4: '',
                      });
                    }}
                  >
                    <img
                      className="imageUploadCrossIcon"
                      src="map/mapMarker/imageCross.svg"
                      alt="map/mapMarker/imageCross.svg"
                    />
                  </div>
                  <div style={{ height: '135px', width: '135px' }} />
                  <div className="fileErrorText">
                    {currentFileError4}
                  </div>
                </div>
              )
              : (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => (this.imageFile4.current.click())}
                >
                  <img
                    className="imageUploadContainerIcon"
                    src="map/Profile_Picture_Holder.svg"
                    alt="icons/Profile_Picture_Holder.svg"
                  />
                </div>
              )}
          </div>
        </Scrollbars>
        <Form.Text className="text-muted">
          Click on the
          {' '}
          <div className="textAccentOrange"> camera </div>
          {' '}
          to upload a photo.
        </Form.Text>
        <Row className="buttonBar">
          <Button
            className="modalButton"
            onClick={() => this.setState({ stage: 5 })}
          >
            BACK
          </Button>
          { currentFileError1 === ''
            && currentFileError2 === ''
            && currentFileError3 === ''
            && currentFileError4 === ''
            ? (
              <Button
                className="modalButton fr "
                onClick={() => this.handleSubmit(setState)}
              >
                SEND
              </Button>
            )
            : (
              <Button
                className="modalButton fr buttonGreyed"
              >
                SEND
              </Button>
            )}
        </Row>
      </div>
    );
  }

  complete = () => {
    const { wr } = this.state;
    const { thisOfOverlay } = this.props;
    return (
      <div className="stage7">
        <div className="title">
          Thank You!
        </div>
        <div className="subtitle">
          We appreciate you sending in this report. Your request has
          been sent to the appropriate service providers.
        </div>
        <div
          className="subtitle"
          style={{
            marginTop: '0px',
            marginBottom: '40px',
          }}
        >
          Issue number:
          {' '}
          <div className="textAccentOrange">
            {' '}
            {wr.code}
          </div>
        </div>
        <Row style={{
          marginBottom: '40px',
        }}
        >
          <Col
            sm={12}
            style={{
              display: 'contents',
            }}
          >
            <img
              className="imageContainer"
              src="map/tick.svg"
              alt="file missing"
            />
          </Col>
        </Row>
        <Row>
          <Button
            className="homeButton"
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              fontSize: '1.2rem',
              padding: '10px 32px',
            }}
            onClick={() => thisOfOverlay.setState({ reportModal: false })}
          >
            CLOSE
          </Button>

          <Button
            className="homeButton"
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              fontSize: '1.2rem',
              padding: '10px 32px',
            }}
            onClick={() => thisOfOverlay.setState({
              reportModal: false,
              showMarkerViewModalWR: wr,
              showMarkerView: true,
            })}
          >
            VIEW
          </Button>
        </Row>
      </div>
    );
  }

  completeError = () => {
    const { wr } = this.state;
    const { thisOfOverlay } = this.props;
    return (
      <div className="stage7">
        <div className="title">
          Error
        </div>
        <div className="subtitle">
          The following error has occured:
        </div>
        <div
          className="subtitle"
          style={{
            marginTop: '0px',
            marginBottom: '20px',
            fontSize: '25px',
            fontWeight: 'bold',
            lineHeight: '20px',
          }}
        >
          <div className="textAccentOrange">
            {wr.Message}
          </div>
        </div>
        <Row style={{
          marginBottom: '40px',
        }}
        >
          <Col
            sm={12}
            style={{
              display: 'contents',
            }}
          >
            <img
              className="imageContainer"
              src="map/problemIcon.svg"
              alt="file missing"
            />
          </Col>
        </Row>
        <Row>
          <Button
            className="homeButton"
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              fontSize: '1.2rem',
              padding: '10px 32px',
            }}
            onClick={() => thisOfOverlay.setState({ reportModal: false })}
          >
            CLOSE
          </Button>
        </Row>
      </div>
    );
  }

  render() {
    const {
      stage,
      loadingReportModal,
      loadingReportModalA,
    } = this.state;
    if (loadingReportModal) {
      lottie.play('loginLoadingReportModal');
    }
    return (
      <div id="reportModal" className="mapModal">
        <div
          id="LoadingLoginOverlayContainer"
          style={
                loadingReportModalA
                  ? {
                    display: 'block',
                  }
                  : {
                    display: 'none',
                  }
              }
        >
          <div
            id="LoadingLoginOverlay"
            className={loadingReportModal
              ? 'fadeIn'
              : 'fadeOut'}
          />
          <div
            ref={(ref) => { this.animateLoading1 = ref; }}
            id="LoadingLoginOverlayImage"
            style={
                loadingReportModalA
                  ? {
                    display: 'block',
                  }
                  : {
                    display: 'none',
                  }
              }
          />
        </div>
        <Modal.Body
          style={{ padding: '0px 40px 30px' }}
        >
          {stage === 1 ? this.selectLocation() : null }
          {stage === 2 ? this.createAReport() : null }
          {stage === 3 ? this.selectANewLocation() : null}
          {stage === 4 ? this.selectACategory() : null}
          {stage === 5 ? this.setDescription() : null}
          {stage === 6 ? this.setImages() : null}
          {stage === 7 ? this.complete() : null}
          {stage === 8 ? this.completeError() : null}
          {stage < 7
            ? (
              <Row>
                <div style={{
                  margin: '25px auto 0px auto',
                }}
                >
                  <div className={stage > 0 ? 'progressBall' : 'progressBall progressBallDisabled'} />
                  <div className={stage > 1 ? 'progressBall' : 'progressBall progressBallDisabled'} />
                  <div className={stage > 2 ? 'progressBall' : 'progressBall progressBallDisabled'} />
                  <div className={stage > 3 ? 'progressBall' : 'progressBall progressBallDisabled'} />
                  <div className={stage > 4 ? 'progressBall' : 'progressBall progressBallDisabled'} />
                  <div className={stage > 5 ? 'progressBall' : 'progressBall progressBallDisabled'} />
                  <div className={stage > 6 ? 'progressBall' : 'progressBall progressBallDisabled'} />
                </div>
              </Row>
            )
            : null }
        </Modal.Body>
        <input
          type="file"
          id="file1"
          ref={this.imageFile1}
          style={{ display: 'none' }}
          onChange={(e) => {
            const allowedExtensions = /(jpg|jpeg|png)$/i;
            if (!allowedExtensions.exec(e.target.files[0].type)) {
              console.log('incorrect file type', e.target.files[0]);
              this.setState({ currentFileError1: 'Incorrect file type' });
            } else if (e.target.files[0].size > 10000000) {
              console.log('File too large', e.target.files[0].size);
              this.setState({ currentFileError1: 'File too large' });
            } else {
              this.setState({ currentFileError1: '' });
            }
            this.setState({
              currentFile1: e.target.files,
              currentImage1: URL.createObjectURL(e.target.files[0]),
            });
          }}
        />
        <input
          type="file"
          id="file2"
          ref={this.imageFile2}
          style={{ display: 'none' }}
          onChange={(e) => {
            const allowedExtensions = /(jpg|jpeg|png)$/i;
            if (!allowedExtensions.exec(e.target.files[0].type)) {
              console.log('incorrect file type', e.target.files[0]);
              this.setState({ currentFileError2: 'Incorrect file type' });
            } else if (e.target.files[0].size > 10000000) {
              console.log('File too large', e.target.files[0].size);
              this.setState({ currentFileError2: 'File too large' });
            } else {
              this.setState({ currentFileError2: '' });
            }
            this.setState({
              currentFile2: e.target.files,
              currentImage2: URL.createObjectURL(e.target.files[0]),
            });
          }}
        />
        <input
          type="file"
          id="file3"
          ref={this.imageFile3}
          style={{ display: 'none' }}
          onChange={(e) => {
            const allowedExtensions = /(jpg|jpeg|png)$/i;
            if (!allowedExtensions.exec(e.target.files[0].type)) {
              console.log('incorrect file type', e.target.files[0]);
              this.setState({ currentFileError3: 'Incorrect file type' });
            } else if (e.target.files[0].size > 10000000) {
              console.log('File too large', e.target.files[0].size);
              this.setState({ currentFileError3: 'File too large' });
            } else {
              this.setState({ currentFileError3: '' });
            }
            this.setState({
              currentFile3: e.target.files,
              currentImage3: URL.createObjectURL(e.target.files[0]),
            });
          }}
        />
        <input
          type="file"
          id="file4"
          ref={this.imageFile4}
          style={{ display: 'none' }}
          onChange={(e) => {
            const allowedExtensions = /(jpg|jpeg|png)$/i;
            if (!allowedExtensions.exec(e.target.files[0].type)) {
              console.log('incorrect file type', e.target.files[0]);
              this.setState({ currentFileError4: 'Incorrect file type' });
            } else if (e.target.files[0].size > 10000000) {
              console.log('File too large', e.target.files[0].size);
              this.setState({ currentFileError4: 'File too large' });
            } else {
              this.setState({ currentFileError4: '' });
            }
            this.setState({
              currentFile4: e.target.files,
              currentImage4: URL.createObjectURL(e.target.files[0]),
            });
          }}
        />
      </div>
    );
  }
}
