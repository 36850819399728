import usePollApi from 'hooks/use-poll-api';
import { SCManagerEndpoints } from 'hooks/use-smart-city-manager';
import { useEffect, useState } from 'react';
import { ResourceTrackingInfo } from 'types/types';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
import { Flex } from 'components/layout';
import theme from 'theme';
import { css } from '@emotion/react';
import { Modal } from 'components/elements';
import { Text } from 'components/typography';
import { convertToReadableDate } from 'functions/date';

const MSCMarker = (iconUrl: string) =>
  new L.Icon({
    iconUrl,
    iconRetinaUrl: iconUrl,
    iconAnchor: new L.Point(20, 20),
    iconSize: [40, 40],
  });

const ResourceTrackingModal = ({
  resourceId,
  onClose,
  isOpen,
}: {
  resourceId: number;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [resourcePosition, setResourcePosition] =
    useState<ResourceTrackingInfo | null>(null);
  const { response } = usePollApi(
    SCManagerEndpoints.GetResourceTrackingPosition,

    10000,
    {
      queryParams: `resourceId=${resourceId}`,
    }
  );
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState<any>(null);
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime());

  useEffect(() => {
    setResourcePosition(response);
    setLastUpdate(new Date().getTime());
  }, [response]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      slideFromBottomMobile
      title={`${resourcePosition?.fullName} is en route`}
    >
      <Text
        color={theme.colors.lightGrey}
      >{`Last updated: ${convertToReadableDate(lastUpdate / 1000)}`}</Text>
      {resourcePosition && (
        <Flex
          width="100%"
          height="450px"
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              width: 100%;
              height: 400px;
              padding: 3px;
            }
          `}
        >
          <MapContainer
            center={[
              resourcePosition?.latitude || 0,
              resourcePosition?.longitude || 0,
            ]}
            zoom={16}
            scrollWheelZoom={false}
            style={{
              height: '100%',
              width: '100%',
              borderRadius: '5px',
            }}
            whenCreated={setMap}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              position={{
                lat: resourcePosition.latitude || 0,
                lng: resourcePosition.longitude || 0,
              }}
              icon={MSCMarker(resourcePosition.imageUrl)}
            >
              <Popup>{resourcePosition.fullName}</Popup>
            </Marker>
          </MapContainer>
        </Flex>
      )}
    </Modal>
  );
};

export default ResourceTrackingModal;
