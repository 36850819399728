import 'styles/map.scss';
import 'styles/mapMarkerViewModal.scss';
import React, {
  useRef,
} from 'react';
import {
  Marker,
} from 'react-leaflet';
import L from 'leaflet';

export default
({
  workRequestT,
  // theme,
  map,
  // thisOfOverlay,
  // serviceType,
  // currentCityId,
}) => {
  const position = {
    lat: workRequestT.latitude,
    lng: workRequestT.longitude,
  };
  const ref = useRef(null);
  const icon = L.divIcon({
    html: `
    <div class='square-box'>
      <div class='square-content'>
          ${workRequestT.count}
      </div>
    </div>`,
    className: 'marker-cluster-custom',
    iconSize: L.point(40, 40, true),
  });

  return (
    <Marker
      {...{
        position,
        ref,
        icon,
      }}
      eventHandlers={{
        click: () => {
          if (map.getZoom() + 2 < 18) {
            map.flyTo(
              { lat: workRequestT.latitude, lng: workRequestT.longitude },
              map.getZoom() + 2,
              2,
            );
          } else {
            map.flyTo(
              { lat: workRequestT.latitude, lng: workRequestT.longitude },
              18,
              2,
            );
          }
        },
      }}
      key={`${workRequestT.id}-marker`}
      className="mapMarker"
    />
  );
};
