import { SmartCityServiceGroup } from 'types/types';

export const getPrivateServiceImage = (customIcon: string) =>
  `https://mysmart.city/api/msc/images/custom/${customIcon}?subscriberId=msc_private`;

export const getResourceProfilePic = (picId: string) =>
  `https://za2.forcelink.net/forcelink/modal/viewimagefile.html?id=${picId}&subscriberId=msc_private`;

export const setMinimumCurrentValues = (groups: SmartCityServiceGroup[]) => {
  const newGroups: SmartCityServiceGroup[] = [];
  groups.forEach((group) =>
    newGroups.push({
      ...group,
      smartCityServices: group.smartCityServices.map((service) => ({
        ...service,
        current: service.min,
      })),
    })
  );
  return newGroups;
};
