import styled from 'styled-components';

export const Title = styled.h2`
  text-align: center;
  /* margin: 0;
  margin-top: -30px;
  margin-bottom: 3px; */
  margin: -30px 40px 3px 40px;
  font: normal normal 500 15px/20px Roboto;
  /* padding: 0 30px; */
  color: #333333;
  @media only screen and (min-width: 800px) {
    /* margin-top: -70px;
    margin-bottom: 3px; */
    font: normal normal 500 30px/39px Roboto;
    margin: -70px 40px 3px 40px;
    /* padding: 0 40px; */
  }
`;

export const Subtitle = styled.h3`
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  font: normal normal 500 10px/15px Roboto;
  padding: 0 30px;
  color: rgba(51, 51, 51, 0.5);
  @media only screen and (min-width: 800px) {
    margin-bottom: 40px;
    font: normal normal 500 20px/26px Roboto;
    padding: 0 40px;
  }
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  overflow-y: auto;
  margin-top: 12px;
  @media only screen and (min-width: 800px) {
    margin-top: 24px;
  }
`;

export const Date = styled.p`
  font: normal normal 500 16px/19px Roboto;
  margin-bottom: 14px;
  color: #0A0918;
  @media only screen and (min-width: 800px) {
    font: normal normal 500 20px/26px Roboto;
  }
`;

export const TimeRange = styled.p`
  font: normal normal normal 12px/14px Roboto;
  border-bottom: 1px solid #dee2e675;
  color: #333333;
  padding-bottom: 14px;
  margin-bottom: 14px;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/21px Roboto;
    borde-bottom: 2px solid #dee2e675;
  }
`;

export const StageList = styled.div`
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  overflow-y: auto;
  height: 100%;
  margin-right: -24px;
  padding-right: 24px;
  @media only screen and (min-width: 800px) {
    margin-right: -45px;
    padding-right: 45px;
  }
`;

export const Stage = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 48px;
  padding-bottom: 20px;
  margin-bottom: 20px; 
  border-bottom: 1px solid rgba(222, 226, 230, 0.46);

  p {
    font: normal normal normal 12px/14px Roboto;
    margin: 0;
    padding: 0;
  }

  div {
    margin-left: auto;
  }
  
  @media only screen and (min-width: 800px) {
    height: 68px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(222, 226, 230, 0.46);

    p {
      font: normal normal normal 20px/26px Roboto;
    }
  }
`;
