import { Button, Modal } from 'components/elements';
import { Loader } from 'components/inthecity';
import { Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';
import { PageLinksEnum } from 'types/routing';
import { PrivateService } from 'types/types';
import { PrivateServicesEvents, logFirebaseEvent } from 'functions/firebase';
import { PrivateServiceButton } from '../service-button';

const PrivateServicesModal = ({
  psModalOpen,
  closeModal,
  isServiceUnavailable,
}: {
  psModalOpen: boolean;
  closeModal: () => void;
  isServiceUnavailable?: boolean;
}) => {
  const navigate = useNavigate();
  const {
    loading,
    response: services,
    callApi,
  } = useSmartCityManager(SCManagerEndpoints.AllServices);

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Modal
      isOpen={psModalOpen}
      onClose={closeModal}
      title={isServiceUnavailable ? 'Service not available' : 'Book a service'}
    >
      {loading ? (
        <Loader />
      ) : (
        <Flex
          flexDirection="column"
          width="100%"
          justifyContent="center"
          maxWidth="450px"
        >
          {isServiceUnavailable ? (
            <Text
              p="10px"
              borderRadius={theme.borderRadius.small}
              backgroundColor={theme.colors.backgroundGrey}
              mb="20px"
            >
              Unfortunately, the service you are trying to book is not yet
              available at this address. You can either book another service,
              change your address, or suggest this service in your area.
            </Text>
          ) : (
            <Text textAlign="center" mb="20px">
              Which service would you like to book?
            </Text>
          )}

          <Grid
            gridTemplateColumns="repeat(2, 1fr)"
            gridColumnGap="20px"
            mb="20px"
          >
            {!loading &&
              services &&
              services.length > 0 &&
              services
                .filter(
                  (s: PrivateService) =>
                    !s.label.toLowerCase().includes('tutor') &&
                    !s.label.toLowerCase().includes('pet')
                )
                .map((service: PrivateService) => (
                  <PrivateServiceButton
                    key={service.value}
                    service={service}
                    onClick={() => {
                      logFirebaseEvent(
                        service.label.toLowerCase().includes('domestic')
                          ? PrivateServicesEvents.BookDomestic
                          : PrivateServicesEvents.BookGardener
                      );
                      navigate(
                        `${PageLinksEnum.BookAService}/${service.value}/${service.label}`
                      );
                    }}
                  />
                ))}
          </Grid>
          <Button
            border={`1px solid ${theme.colors.borderGrey}`}
            borderRadius={theme.borderRadius.medium}
            onClick={() => navigate(PageLinksEnum.SuggestAService)}
          >
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              pb="10px"
            >
              <Text color={theme.colors.lightGrey} fontSize="2rem" m={0} p={0}>
                +
              </Text>
              <Text
                color={theme.colors.lightGrey}
                fontWeight="bold"
                mb="20px"
                mt="-5px"
              >
                Suggest a service
              </Text>
            </Flex>
          </Button>
          {/* 
    //NOTE: Uncomment and set functionality once we have more services.
    <Button
      border={`1px solid ${theme.colors.primary}`}
      borderRadius={theme.borderRadius.medium}
      mt="20px"
    >
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        p="10px"
      >
        <Text color={theme.colors.primary} fontWeight="700" fontSize="1.1rem">
          View all Private Services
        </Text>
      </Flex>
    </Button> */}
        </Flex>
      )}
    </Modal>
  );
};

export default PrivateServicesModal;
