import { Modal } from 'components/elements';
import SignIn from 'pages/auth/signin';
import SignUp from 'pages/auth/signup';
import { useState } from 'react';

export type AuthState = 'signin' | 'signup';

const LoginModal = ({
  show,
  hide,
  onSignIn,
}: {
  show: boolean;
  hide: () => void;
  onSignIn: any;
}) => {
  const [authState, setAuthState] = useState<AuthState>('signin');
  return (
    <Modal
      isOpen={show}
      onClose={hide}
      slideFromBottomMobile
      title={authState === 'signin' ? 'Sign in' : 'Sign up'}
    >
      {authState === 'signin' ? (
        <SignIn setAuthState={setAuthState} onSignIn={onSignIn} />
      ) : (
        <SignUp returnToLogin={() => setAuthState('signin')} />
      )}
    </Modal>
  );
};

export default LoginModal;
