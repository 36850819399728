import { Flex } from 'components/layout';
import { Image } from 'components/elements';
import { Text } from 'components/typography';
import { useState, useEffect } from 'react';
import theme from 'theme';

const StarRating = ({
  rating,
  hideNumbers,
  size,
}: {
  rating: {
    starRating?: number;
    ratings?: any[];
  };
  hideNumbers?: boolean;
  size?: string;
}) => {
  const [stars, setStars] = useState<string[]>([]);

  useEffect(() => {
    if (rating.starRating) {
      const whole = Math.floor(rating.starRating);
      const decimal = rating.starRating - whole;
      const temp: string[] = [];
      for (let i = 0; i < whole; i++) {
        temp.push('inthecity/star-icon.svg');
      }
      if (decimal > 0) {
        temp.push('icons/half_star.svg');
      }
      setStars(temp);
    }
  }, [rating]);

  return (
    <Flex alignItems="center">
      {stars.map((star: string, idx: number) => (
        <Image
          source={star}
          alt="star"
          key={idx}
          width={size || '14px'}
          mr="5px"
        />
      ))}
      {!hideNumbers && (
        <Text color={theme.colors.primary} ml="5px" mt="2px" fontWeight="bold">
          {rating?.starRating?.toFixed(2).toString() || 'No ratings yet'}
        </Text>
      )}
    </Flex>
  );
};

export default StarRating;
