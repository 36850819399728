import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const AboutIconsRow = styled.div`
  height: 8vw;
  width: 100%;
  @media only screen and (min-width: 700px) {
    height: 4vw;
  }
`;

export const AboutIcons = styled.img`
  display: block;
  height: 100%;
  margin: 0 auto;
`;

export const OurStoryContainer = styled(Col)`
  z-index: 1;
  height: 25rem;
  @media only screen and (min-width: 600px) {
    height: 30rem;
  }
  @media only screen and (min-width: 800px) {
    height: 35rem;
  }
  @media only screen and (min-width: 900px) {
    height: 40rem;
  }
  @media only screen and (min-width: 1400px) {
    height: 50rem;
  }
`;

export const AboutSectionContainer = styled.div`
  height: 30rem;
  @media only screen and (min-width: 600px) {
    height: 35rem;
  }
  @media only screen and (min-width: 800px) {
    height: 25rem;
  }
  @media only screen and (min-width: 900px) {
    height: 40rem;
  }
  @media only screen and (min-width: 1200px) {
    height: 38rem;
  }
  @media only screen and (min-width: 1400px) {
    height: 30rem;
  }
`;

export const AboutOurstoryImage1 = styled.img`
  position: absolute;
  z-index: 1;
  right: 5%;
  bottom: 10%;
  width: 35%;
  @media only screen and (min-width: 770px) {
    right: -11%;
    width: 40%;
    top: -50%;
  }
  @media only screen and (min-width: 1000px) {
    top: -80%;
  }
  @media only screen and (min-width: 1400px) {
    top: -112%;
  }
`;

export const AboutCareersImage1 = styled.img`
  position: absolute;
  right: 15%;
  bottom: 4%;
  width: 18%;
`;

export const AboutCareersContainer = styled.div`
  height: 12rem;
  @media only screen and (min-width: 770px) {
    height: 0;
  }
`;

export const AboutCareersImage2 = styled.img`
  display: block;
  width: 75%;
  margin: 0 auto;
`;

export const AboutNavbarContainer = styled.div`
  display: flex;
  background-color: #333333;
  align-items: center;
  height: 7rem;
  padding: 0 5rem 0 5rem;

  @media only screen and (max-width: 1000px) {
    padding: 0;
    justify-content: space-around;
    height: 5rem;
    background-color: #2096ba;
  }
`;

export const AboutNavbarItem = styled.div`
  font-size: 2rem;
  color: white;
  font-family: GothamMedium;
  padding-right: 2rem;
  margin-right: 4.25rem;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s;
  border-bottom: 2px solid transparent;

  &:hover {
    color: white;
    border-bottom: 2px solid white;
  }

  ${({ selected }) => (selected && `
    border-bottom: 2px solid white;
  `)}

  @media only screen and (max-width: 1000px) {
    font-size: 1.5rem;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
`;
