import { Image, Button } from 'components/elements';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { useState } from 'react';
import theme from 'theme';
import { CustomerBooking } from 'types/types';
import { getPrivateServiceImage } from 'functions/private-services';
import Star from '../rating-elements/star';

const ServiceRating = ({
  booking,
  onNext,
  setStarRating,
}: {
  booking: CustomerBooking;
  onNext: () => void;
  setStarRating: (rating: number) => void;
}) => {
  const [starsActive, setStarsActive] = useState<number | null>(null);
  return (
    <>
      <Flex width="100%" justifyContent="center">
        <Image
          objectFit="cover"
          width="96px"
          height="96px"
          source={booking.resourceProfilePic}
          alt="profile"
          borderRadius="50%"
        />
      </Flex>

      <Flex alignItems="center" width="100%" pt="20px" justifyContent="center">
        <Image
          objectFit="contain"
          mr="5px"
          width="15px"
          height="15px"
          source={getPrivateServiceImage(booking.serviceIcon)}
          alt="service image"
        />

        <Text fontSize="15px" color={theme.colors.primary}>
          {booking.service}
        </Text>
      </Flex>

      <Flex pt="20px" justifyContent="center">
        <Text fontWeight="500" fontSize="25px">
          {`Rate ${booking.resourceName}'s Service`}
        </Text>
      </Flex>

      <Flex mx="auto" textAlign="center" justifyContent="center">
        <Text width="65%" fontSize="15px" color="#B2BCC1">
          Let us know about your experience working with {booking.resourceName}
        </Text>
      </Flex>

      <Flex justifyContent="center">
        {[...Array(5)].map((_, index) => (
          <Button
            m="20px"
            onClick={() => {
              setStarRating(index + 1);
              onNext();
            }}
            onHover={(active) =>
              active ? setStarsActive(index) : setStarsActive(null)
            }
          >
            <Star
              isActive={starsActive ? starsActive >= index : false}
              key={index}
            />
          </Button>
        ))}
      </Flex>
    </>
  );
};
export default ServiceRating;
