/** @jsxImportSource @emotion/react */
import React, { ForwardedRef, forwardRef } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import StandardCSS from '../../types/standard-css';
import StandardProps from '../../types/standard-props';

type GridProps = {
  gridAreas?: string;
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  gridColumnGap?: string;
  gridRowGap?: string;
  overrideCss?: SerializedStyles;
  children?: React.ReactNode;
} & StandardProps;

const Grid = forwardRef(
  (props: GridProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      gridAreas,
      gridTemplateColumns,
      gridTemplateRows,
      gridColumnGap,
      gridRowGap,
      overrideCss,
      children,
      ...standardProps
    } = props;
    return (
      <div
        ref={ref}
        css={css`
          -webkit-display: grid;
          display: grid;
          grid-template-areas: ${gridAreas};
          grid-template-columns: ${gridTemplateColumns};
          grid-template-rows: ${gridTemplateRows};
          grid-column-gap: ${gridColumnGap};
          grid-row-gap: ${gridRowGap};
          ${StandardCSS(standardProps)}
          ${overrideCss}
        `}
      >
        {children}
      </div>
    );
  }
);

export default Grid;
