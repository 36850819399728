import { css } from '@emotion/react';
import { Button } from 'components/elements';
import { Text } from 'components/typography';
import theme from 'theme';

const NavItem = ({
  label,
  active,
  navigate,
}: {
  label: string;
  active: boolean;
  navigate: () => void;
}) => (
  <Button onClick={navigate}>
    <Text
      fontWeight={active ? 'bold' : 'normal'}
      color={active ? theme.colors.primary : theme.colors.darkGrey}
      fontSize="16px"
      hoverCss={css`
        color: ${theme.colors.primary};
      `}
      textAlign="left"
    >
      {label}
    </Text>
  </Button>
);

export default NavItem;
