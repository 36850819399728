/* eslint-disable max-len */
import { apiUrl } from 'constants/app';
import { Cookies } from 'react-cookie';
// import { errorHandler } from 'interface/social';

const cookies = new Cookies();

export const populateSocialAlertsInfo = async () => fetch(
  `${apiUrl}smartcitymanager/populateSocialAlertsInfo`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const getLoadSheddingStage = async () => fetch(
  `${apiUrl}smartcitymanager/currentLoadSheddingStage`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

/**
 * Get alert posts of an alert type
 * @param {string} workType - Alert type
 * @param {number} limitPerPage - Limit per page
 * @param {number} pageNo - Page number
 */
export const getOpenWorkOrdersForType = async (workType = '', limitPerPage = '', pageNo = '') => fetch(
  `${apiUrl}smartcitymanager/getOpenWorkOrdersForType?`
  + `workType=${workType}`
  + `&limitPerPage=${limitPerPage}`
  + `&pageNo=${pageNo}`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => {
    console.log(e);
    return [];
  });

/**
 * Get Load Shedding Blocks
 * Lookup list of blocks/areas based on user's favorite locations
 * Lookup stage from loadshedding data (store in global setting - refresh every 5 mins)
 * @returns {[{ block, stage }]}
 */
export const getLoadSheddingBlocks = async () => fetch(
  `${apiUrl}smartcitymanager/getLoadSheddingBlocks`,
  {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
      processData: false,
    },
  },
)
  .then((res) => res.json())
  .catch((e) => {
    console.log(e);
    return [];
  });

export const getWorkOrder = async (id) => fetch(
  `${apiUrl}smartcitymanager/getWorkOrder?`
  + `workOrderId=${id}`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => {
    console.log(e);
    return [];
  });
