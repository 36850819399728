/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GMBusiness, GMBusinessCategory } from 'types/types';

export interface ReduxGMBusinessDirectoryState {
  businessSearchTerm: string;
  businessCategories: GMBusinessCategory[];
  selectedCategoryId: number;
  currentBusinesses: GMBusiness[];
}

const initialState: ReduxGMBusinessDirectoryState = {
  businessSearchTerm: '',
  businessCategories: [],
  selectedCategoryId: 0,
  currentBusinesses: [],
};

export const gmBusinessesSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.businessSearchTerm = action.payload;
    },
    setCurrentBusinesses: (state, action: PayloadAction<GMBusiness[]>) => {
      state.currentBusinesses = action.payload;
    },
    setBusinessCategories: (
      state,
      action: PayloadAction<GMBusinessCategory[]>
    ) => {
      state.businessCategories = action.payload;
    },
    setSelectedCategoryId: (state, action: PayloadAction<number>) => {
      state.selectedCategoryId = action.payload;
    },
  },
});

export const gmBusinessesSelector = (state: ReduxGMBusinessDirectoryState) =>
  state;

export const {
  setSearchTerm,
  setCurrentBusinesses,
  setBusinessCategories,
  setSelectedCategoryId,
} = gmBusinessesSlice.actions;
export default gmBusinessesSlice.reducer;
