import { useEffect, useState } from 'react';
import useSmartCityManager, {
  SCManagerParams,
  endpointType,
} from './use-smart-city-manager';

const usePollApi = (
  endpoint: endpointType,
  interval: number,
  params?: SCManagerParams
) => {
  const [pollCountDown, setPollCountDown] = useState(0);
  const { loading, response, error, callApi } = useSmartCityManager(endpoint);

  useEffect(() => {
    if (pollCountDown > 0) {
      setTimeout(() => {
        setPollCountDown(pollCountDown - 1000);
      }, 1000);
    } else {
      callApi(params);
      setPollCountDown(interval);
    }
  }, [pollCountDown]);

  return { loading, response, error };
};

export default usePollApi;
