/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Image, Modal } from 'components/elements';
import { Loader } from 'components/inthecity';
import { Flex, Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { GMSurvey, GoogleSuggestedAddress, LabelValuePair } from 'types/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from 'contexts/global';
import SurveyQuestion from 'components/my-municipality/survey-question';
import AnimatedCarousel from 'components/animated-carousel';
import InteractiveAddressSelector from 'components/interactive-address-selector';
import Dropdown from 'components/dropdown';
import {
  splitSurveyCategories,
  updateSurveyComment,
  updateSurveyAnswer,
  rebuildCategories,
  checkAllQuestionsAnswered,
} from 'functions/surveys';

const RACE_DROPDOWN_OPTIONS = [
  {
    label: 'African',
    value: 0,
  },
  {
    label: 'Asian',
    value: 1,
  },
  {
    label: 'Coloured',
    value: 2,
  },
  {
    label: 'Indian',
    value: 3,
  },
  {
    label: 'White',
    value: 4,
  },
  {
    label: 'Prefer not to say',
    value: 5,
  },
];

const GENDER_DROPDOWN_OPTIONS = [
  {
    label: 'Male',
    value: 0,
  },
  {
    label: 'Female',
    value: 1,
  },
  {
    label: 'Prefer not to say',
    value: 2,
  },
];

const CompleteSurvey = () => {
  const { surveyId, cityId } = useParams();
  const {
    globalState: { user },
  } = useGlobalContext();
  const [demographicPosted, setDemographicPosted] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(true);
  const [infoModalAnswer, setInfoModalAnswer] = useState(0);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [address, setAddress] = useState<GoogleSuggestedAddress | null>(null);
  const [race, setRace] = useState<LabelValuePair>({
    label: 'Racial Group',
    value: -1,
  });
  const [gender, setGender] = useState<LabelValuePair>({
    label: 'Gender',
    value: -1,
  });
  const [surveyDetails, setSurveyDetails] = useState<GMSurvey | null>(null);
  const navigate = useNavigate();

  const {
    loading: postingDemographic,
    response: surveyResponse,
    callApi: postDemographic,
  } = useSmartCityManager(SCManagerEndpoints.PostDemographic);

  const {
    loading: postingSurvey,
    callApi: postSurvey,
    response: postSurveyResponse,
  } = useSmartCityManager(SCManagerEndpoints.PostSurvey);

  useEffect(() => {
    if (surveyResponse) {
      setSurveyDetails({
        ...surveyResponse,
        categorys: splitSurveyCategories(surveyResponse.categorys),
      });
    }
  }, [surveyResponse]);

  return (
    <MunicipalPageWrapper
      title={`Survey #${surveyId}`}
      backLink={`/my-municipality/surveys/${cityId}`}
    >
      {postingDemographic ? (
        <Loader />
      ) : (
        <>
          <Modal
            isOpen={showInfoModal}
            onClose={() => setShowInfoModal(false)}
            slideFromBottomMobile
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              mobileCss={css`
                padding-bottom: 30px; // To account for the bottom nav
              `}
            >
              <Image
                source="my-municipality/megaphone.png"
                alt="megaphone"
                width="100px"
                mx="auto"
                mb="40px"
              />
              <Text textAlign="center" fontWeight="bold">
                {user.fullName}, please could you take a moment to answers some
                questions to help your community.
              </Text>
              <Text textAlign="center" my="20px">
                For the survey we will need you to rank how you feel to the
                question asked on a scale from 1 to 5. With 1 being{' '}
                <strong>strongly disagree</strong> and 5 being{' '}
                <strong>strongly agree</strong>.
              </Text>
              <Text textAlign="center" mb="20px">
                Please see below an example of the question and how they can be
                answered.
              </Text>
              <SurveyQuestion
                category={{
                  description: 'Example:',
                  id: 0,
                  questions: [
                    {
                      id: 0,
                      question:
                        'The infrastructure of the GM is well maintained',
                      min: 5,
                      max: 1,
                      answer: infoModalAnswer || 0,
                    },
                  ],
                }}
                answer={infoModalAnswer}
                onChange={(a) => setInfoModalAnswer(a)}
              />
              <Button
                onClick={() => setShowInfoModal(false)}
                variant="cta"
                maxWidth="500px"
                mx="auto"
                mobileCss={css`
                  margin: 0;
                  width: 100%;
                `}
              >
                Got it, start the survey
              </Button>
            </Flex>
          </Modal>
          <Modal
            isOpen={locationModalOpen}
            onClose={() => setLocationModalOpen(false)}
          >
            <InteractiveAddressSelector
              setAddressCallback={(a) => {
                setAddress(a);
                setLocationModalOpen(false);
              }}
            />
          </Modal>
          {!demographicPosted && (
            <Grid
              maxWidth="520px"
              mx="auto"
              borderRadius="4px"
              p="24px"
              width="100%"
              boxShadow={theme.cardShadow}
              gridRowGap="10px"
            >
              <Text fontSize="16px" fontWeight={600}>
                Demographic
              </Text>
              <Button
                width="100%"
                p="16px"
                textAlign="left"
                borderRadius="4px"
                border={theme.borders.standard1px}
                onClick={() => setLocationModalOpen(true)}
              >
                {address?.formatted_address || 'Select your address'}
              </Button>
              <Grid gridTemplateColumns="1fr 1fr" gridColumnGap="10px">
                <Dropdown
                  value={race}
                  options={RACE_DROPDOWN_OPTIONS}
                  onChange={(o) => setRace(o)}
                />
                <Dropdown
                  value={gender}
                  options={GENDER_DROPDOWN_OPTIONS}
                  onChange={(o) => setGender(o)}
                />
              </Grid>
              <Button
                variant="cta"
                disabled={!address || race.value === -1 || gender.value === -1}
                onClick={() => {
                  postDemographic({
                    body: {
                      workOrderId: surveyId,
                      latitiude: address?.geometry.location.lat || 0, // Ahh, spelling, the bane of my existence
                      longitude: address?.geometry.location.lng || 0,
                      race: race.label,
                      gender: gender.label,
                      locationType: 'suburb',
                    },
                  });
                  setDemographicPosted(true);
                }}
              >
                Submit
              </Button>
            </Grid>
          )}
          {surveyDetails && demographicPosted && !postSurveyResponse && (
            <>
              {postingDemographic || postingSurvey ? (
                <Loader />
              ) : (
                <AnimatedCarousel
                  lastSlideCallback={
                    checkAllQuestionsAnswered(surveyDetails)
                      ? () =>
                          postSurvey({
                            body: {
                              ...surveyDetails,
                              categorys: rebuildCategories(
                                surveyDetails.categorys
                              ),
                            },
                          })
                      : undefined
                  }
                  carouselItems={surveyDetails.categorys.map(
                    (c, idx: number) => (
                      <SurveyQuestion
                        category={c}
                        updateComment={(questionId: number, comment: string) =>
                          setSurveyDetails(
                            updateSurveyComment(
                              idx,
                              questionId,
                              comment,
                              surveyDetails
                            )
                          )
                        }
                        onChange={(questionId: number, answer: number) =>
                          setSurveyDetails(
                            updateSurveyAnswer(
                              idx,
                              questionId,
                              answer,
                              surveyDetails
                            )
                          )
                        }
                      />
                    )
                  )}
                />
              )}
            </>
          )}
          {postSurveyResponse && (
            <Flex
              borderRadius="4px"
              boxShadow={theme.cardShadow}
              p="16px"
              mx="auto"
              justifyContent="center"
              width="100%"
              maxWidth="540px"
              flexDirection="column"
              flex="1"
              height="100%"
            >
              <Image
                mx="auto"
                source="my-municipality/smile.svg"
                alt="smile"
                width="85px"
                mb="10px"
              />
              <Text
                textAlign="center"
                color="#C0C0C0
"
                mb="10px"
              >
                Thank you for your feedback! Reference number below
              </Text>
              <Text textAlign="center" fontSize="20px" fontWeight={600}>
                {surveyDetails?.surveyRefNo}
              </Text>
              <Flex width="100%" mt="30px">
                <Button
                  variant="cta"
                  width="100%"
                  mr="10px"
                  onClick={() =>
                    navigate(`/my-municipality/surveys/${cityId}/my-surveys`)
                  }
                >
                  View my surveys
                </Button>
                <Button
                  variant="outlined"
                  width="100%"
                  mr="10px"
                  onClick={() => navigate(`/my-municipality/surveys/${cityId}`)}
                >
                  Back to home
                </Button>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </MunicipalPageWrapper>
  );
};

export default CompleteSurvey;
