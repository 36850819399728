import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, Col } from 'react-bootstrap';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Section from 'components/section';

import {
  TermsLinks,
  Tcheader,
  TermsPopupBox,
  TermsPopupContent,
  TermsPopupButton,
  TermsSideMenu,
  TermsSideMenuContent,
  TermsContainer,
  TermsTitle,
  TermsTitleAlt,
} from './cancellationPolicy.styled'; // TermsParagraph,

const Links = () => (
  <TermsLinks>
    <Tcheader className="text-bold">My Smart City</Tcheader>
    <ol>
      <li>
        <Link to="/cancellation-policy#introduction" smooth>
          Introduction
        </Link>
      </li>
      <li>
        <Link to="/cancellation-policy#cancellation-of-booking" smooth>
          Cancellation of Booking
        </Link>
      </li>
      <li>
        <Link to="/cancellation-policy#failure-to-provide-access" smooth>
          Failure to Provide Access
        </Link>
      </li>
      <li>
        <Link to="/cancellation-policy#cancellation-by-my-smart-city" smooth>
          Cancellation by My Smart City
        </Link>
      </li>
    </ol>
  </TermsLinks>
);

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && (
        <TermsPopupBox>
          <TermsPopupContent>
            <Links />
          </TermsPopupContent>
        </TermsPopupBox>
      )}
      <TermsPopupButton
        className={`${isOpen ? 'open' : ''}`}
        role="button"
        tabIndex={0}
        onClick={togglePopup}
      >
        <img src="terms/arrow.svg" alt="arrow" title={isOpen} />
      </TermsPopupButton>
    </>
  );
};

const SideMenu = () => (
  <TermsSideMenu>
    <TermsSideMenuContent>
      <Links />
    </TermsSideMenuContent>
  </TermsSideMenu>
);

export default function CancellationPolicy() {
  return (
    <>
      <SEO
        title="My Smart City | Cancellation Policy"
        description="Cancellation policy"
      />
      <Section color="white" thinPadding>
        <Row noGutters>
          <Col lg={3} sm={12}>
            <Popup />
            <SideMenu />
          </Col>
          <Col lg={9} sm={12}>
            <TermsContainer>
              <TermsTitle>My Smart City</TermsTitle>
              <TermsTitleAlt className="bold">
                Cancellation Policy
              </TermsTitleAlt>
              <ol>
                <li id="introduction">
                  Introduction
                  <ol>
                    <li>
                      This Cancellation Policy (“the Policy”) seeks to regulate
                      the situations that arises when a client of My Smart City
                      (“Client”) cancels a booking.
                    </li>
                    <li>
                      This policy shall apply to all My Smart City Contractors
                      and Clients.
                    </li>
                  </ol>
                </li>
                <li id="cancellation-of-booking">
                  Cancellation of Booking
                  <ol>
                    <li>
                      The Client may, at any time prior to the scheduled time
                      for a booking, cancel any individual or recurring booking
                      via the My Smart City platform (“the Platform”).
                    </li>
                    <li>
                      Should the Client cancel the booking within 12 (twelve)
                      hours prior to the scheduled start of their booked
                      appointment, My Smart City shall be entitled to charge an
                      amount equal to the total cost of the booking in order to
                      account for the Contractor’s loss of potential earnings,
                      transport costs, and/or other associated fees where
                      applicable, as stated in the Terms and Conditions and
                      booking confirmation email received by the Client upon
                      making the booking.
                    </li>
                    <li>
                      Should the Client cancel the booking between 12 (twelve)
                      and 24 (twenty-four) hours prior to the scheduled start of
                      their booked appointment, My Smart City will be entitled
                      to charge an amount equal to 50% (fifty percent) of the
                      booking in order to account for the Contractor’s loss in
                      order to account for the factors listed in clause 2.2
                      above.
                    </li>
                    <li>
                      Should the Client reschedule or edit the booking 24
                      (twenty-four) or more hours prior to the scheduled start
                      of their booked appointment, the Client shall be entitled
                      to a full refund.
                    </li>
                    <li>
                      Should the Client seek to cancel their booking, the Client
                      may:
                    </li>
                    <ol>
                      <li>
                        access their account, and navigate to “my bookings”.
                        Once the relevant booking has been opened; the cancel
                        button is at the bottom of the screen.
                      </li>
                    </ol>
                    <li>
                      As an alternative to cancellation, the Client may elect to
                      reschedule their booking, or place it on hold as set forth
                      in the Rescheduling Policy found here.
                    </li>
                    <li>
                      Once the booking has been cancelled, a confirmation popup
                      will allow the Client to confirm the cancellation, with
                      specific reference to any cancellation penalties that may
                      need to be paid by the Client.
                    </li>
                    <li>
                      The Client shall receive a cancellation email, recording
                      any penalty notifications as per the popup, should such
                      penalties be applicable.
                    </li>
                  </ol>
                </li>
                <li id="failure-to-provide-access">
                  Failure to provide Access
                  <ol>
                    <li>
                      Should the Client fail to arrive at the booking, or
                      alternatively fail to provide the service provider with
                      appropriate access information in order to allow them to
                      begin providing their services within 30 (thirty) minutes
                      after the scheduled appointment time, this shall be
                      considered to be a cancellation in terms of clause 2.2
                      above. However, in these circumstances the service
                      provider will have incurred travel costs and time in
                      addition to their loss of income, and accordingly My Smart
                      City shall not be required to issue a refund.
                    </li>
                    <li>The Client is advised that:</li>
                    <ol>
                      <li>
                        if they will be late to the start of the booking, they
                        can notify the service provider. If the service provider
                        is left waiting 30 minutes past the new agreed time, it
                        shall be considered to be a cancellation in terms of
                        clause 3.1 above;
                      </li>
                      <li>
                        if they provide the incorrect address to My Smart City
                        and/or the service provider, and the service provider is
                        not within 2 (two) kilometers of the correct address,
                        the booking will be considered to be a cancellation in
                        terms of clause 3.1 above; and/or
                      </li>
                      <li>
                        if they fail to provide any necessary/substitute tools
                        and/or equipment as per the service requirements
                        resulting in the service provider not being able to
                        provide the agreed-upon services, this shall be deemed
                        to be a cancellation in terms of clause 3.1 above.
                      </li>
                    </ol>
                  </ol>
                </li>
                <li id="cancellation-by-my-smart-city">
                  Cancellation by My Smart City
                  <ol>
                    <li>
                      My Smart City may at any time, without cause, cancel any
                      particular booking or this agreement as a whole including
                      the Client’s right to use the Website, giving notice by
                      email to the extent reasonably practicable. If so, My
                      Smart City will refund any amounts due to the Client for
                      any outstanding, paid private services appointments.
                    </li>
                    <li>
                      The service provider may occasionally cancel an accepted
                      booking due to illness, or family emergency etc.
                    </li>
                    <li>
                      Should this be the case, My Smart City will offer another
                      service provider in their place for the same day & time
                      (availability dependent); or offer to reschedule the
                      booking, in order to use the same service provider.
                    </li>
                    <li>
                      The Client will again have the option to place their
                      booking ‘on-hold’, which can then be moved / rescheduled
                      within 3 months of the original booking (as per
                      Rescheduling Policy).
                    </li>
                    <li>
                      If the Client then chooses to cancel the booking as a
                      result of the service provider’s cancellations, the Client
                      will be refunded in full.
                    </li>
                    <li>
                      Following cancellation of this agreement for any reason:
                    </li>
                    <ol>
                      <li>Accrued rights and liabilities are unaffected.</li>
                      <li>
                        All clauses in any agreement or policy between the
                        Client and My Smart City which are stated or intended to
                        continue after cancellation will continue to apply. The
                        Client must not attempt to re-register for or continue
                        to use the Website if My Smart City has given the Client
                        notice of cancellation.
                      </li>
                    </ol>
                  </ol>
                </li>
              </ol>
            </TermsContainer>
          </Col>
        </Row>
      </Section>
      <Footer />
    </>
  );
}
