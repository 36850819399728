import { css } from '@emotion/react';
import { Loader } from 'components/inthecity';
import { Box, Flex } from 'components/layout';
import PrivateServicesBreadcrumbs from 'components/private-services/breadcrumbs';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from 'theme';
import { CustomerBooking, MyBookingsResponse } from 'types/types';
import ActiveBooking from 'components/private-services/my-bookings/active-booking';
import ResourceChat from 'components/chat-container';
import InactiveBooking from 'components/private-services/my-bookings/inactive-booking';
import RateResourceModal from 'components/rate-resource-modal';
import { Button } from 'components/elements';

const MyBookingsPage = () => {
  const location = useLocation();
  const {
    loading,
    response: allBookings,
    callApi,
  } = useSmartCityManager(SCManagerEndpoints.GetMyBookings);
  const [myBookings, setMyBookings] = useState<MyBookingsResponse | null>(null);
  const [activeChat, setActiveChat] = useState({
    resourceId: 0,
    bookingId: 0,
    resourceName: '',
  });
  const [bookingToRate, setBookingToRate] = useState<CustomerBooking | null>(
    null
  );
  const [pastBookingsView, setPastBookingsView] = useState<
    'completed' | 'cancelled'
  >('completed');
  const navigate = useNavigate();

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    setMyBookings(allBookings);
  }, [allBookings]);

  if (loading) {
    return <Loader />;
  }
  return (
    <Flex justifyContent="center" flexDirection="column">
      {!!bookingToRate && (
        <RateResourceModal
          isOpen={!!bookingToRate}
          onClose={() => setBookingToRate(null)}
          booking={bookingToRate}
        />
      )}
      {activeChat.bookingId !== 0 && activeChat.resourceId !== 0 && (
        <ResourceChat
          resourceId={activeChat.resourceId}
          bookingId={activeChat.bookingId}
          resourceName={activeChat.resourceName}
        />
      )}
      <Box mt="1%" ml="3%">
        <PrivateServicesBreadcrumbs
          crumbs={[{ label: 'My Bookings', value: location.pathname }]}
        />
      </Box>

      <Flex
        pb="20px"
        width="100%"
        borderB={`2px solid ${theme.colors.borderGrey}`}
        justifyContent="center"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            border-bottom: 5px solid ${theme.colors.borderGrey};
          }
        `}
      >
        <Box
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              width: 100%;
            }
          `}
        >
          <Text
            textAlign="center"
            color={theme.colors.darkGrey}
            fontSize="25px"
            fontWeight="500"
          >
            My Bookings
          </Text>
        </Box>
      </Flex>
      <Flex
        px="20%"
        flexDirection="column"
        minWidth="100%"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            padding: 0;
            border-bottom: 5px solid ${theme.colors.borderGrey};
          }
        `}
      >
        {myBookings &&
          myBookings?.activeBookings.length === 0 &&
          myBookings.cancelledBookings.length === 0 &&
          myBookings.completedBookings.length === 0 &&
          myBookings.pendingBookings.length === 0 && (
            <Text>You have not made any bookings with us yet.</Text>
          )}
        {myBookings && myBookings?.activeBookings.length > 0 && (
          <Box p="10px">
            <Text
              fontSize="22px"
              fontWeight="bold"
              mb="20px"
              overrideCss={css`
                @media ${theme.mediaQueries.mobileOnly} {
                  font-size: 18px;
                }
              `}
            >
              Active Bookings
            </Text>
            {myBookings?.activeBookings.map((booking) => (
              <ActiveBooking
                booking={booking}
                key={booking.workOrderId}
                setViewBooking={() =>
                  navigate(`/booking-details/${booking.workOrderId}`)
                }
                chatButtonCallback={() => {
                  setActiveChat({
                    resourceId: booking.resourceId,
                    bookingId: booking.workOrderId,
                    resourceName: booking.resourceName,
                  });
                }}
              />
            ))}
          </Box>
        )}
      </Flex>
      <Flex
        px="20%"
        flexDirection="column"
        minWidth="100%"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            padding: 0;
            border-bottom: 5px solid ${theme.colors.borderGrey};
          }
        `}
      >
        {myBookings &&
          (myBookings.cancelledBookings.length > 0 ||
            myBookings.completedBookings.length > 0) && (
            <Box p="10px">
              <Text
                fontSize="22px"
                fontWeight="bold"
                overrideCss={css`
                  @media ${theme.mediaQueries.mobileOnly} {
                    font-size: 18px;
                  }
                `}
              >
                Past Bookings
              </Text>
              <Flex mb="20px" mt="10px">
                <Button
                  fontWeight="500"
                  variant={
                    pastBookingsView === 'completed' ? 'filled' : 'outlined'
                  }
                  onClick={() => setPastBookingsView('completed')}
                  borderRadius="10px 0 0 10px"
                >
                  Completed
                </Button>
                <Button
                  fontWeight="500"
                  variant={
                    pastBookingsView === 'cancelled' ? 'filled' : 'outlined'
                  }
                  onClick={() => setPastBookingsView('cancelled')}
                  borderRadius="0 10px 10px 0"
                >
                  Cancelled
                </Button>
              </Flex>
              {pastBookingsView === 'completed' ? (
                myBookings.completedBookings.length > 0 ? (
                  myBookings.completedBookings.map((booking) => (
                    <InactiveBooking
                      setViewBooking={() =>
                        navigate(`/booking-details/${booking.workOrderId}`)
                      }
                      booking={booking}
                      onRateClick={() => setBookingToRate(booking)}
                    />
                  ))
                ) : (
                  <Text>You have no completed bookings.</Text>
                )
              ) : myBookings.cancelledBookings.length > 0 ? (
                myBookings.cancelledBookings.map((booking) => (
                  <InactiveBooking
                    setViewBooking={() =>
                      navigate(`/booking-details/${booking.workOrderId}`)
                    }
                    booking={booking}
                    onRateClick={() => setBookingToRate(booking)}
                  />
                ))
              ) : (
                <Text>You have no cancelled bookings.</Text>
              )}
            </Box>
          )}
      </Flex>
      <Flex
        px="20%"
        flexDirection="column"
        minWidth="100%"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            padding: 0;
            border-bottom: 5px solid ${theme.colors.borderGrey};
          }
        `}
      />
    </Flex>
  );
};

export default MyBookingsPage;
