import styled from 'styled-components';

const getColor = (color) => {
  const lookup = {
    orange: '#de6e21',
    blue: '#0098b5',
    dark: '#333333',
    light: '#dfeaef',
  };
  return lookup[color] ? lookup[color] : color;
};

// Magic Number Alert:
// the 0.043744332 is calculated by this formula:
// tan(5°) / 2, where 5° is the current skewY value.
const magicNumber = '0.043744332';
const skewPadding = `calc(100vw * ${magicNumber})`;

export const StyledSection = styled.div`
  background: ${({ color }) => getColor(color)};
  position: relative;
  overflow-x: clip;
  padding: 6vw 6vw calc(3 * ${skewPadding});
  
  ${({ diagonal, inverted }) => diagonal && `
    &:before {
      height: calc(2 * ${skewPadding});
      background: inherit;
      content: "";
      position: absolute;
      left: 0;
      top: calc(-1* ${skewPadding});
      right: 0;
      bottom: 0;
      transform: skewy(${inverted ? '5' : '-5'}deg);
      transform-origin: 50% 0;
      backface-visibility: hidden;
    }`}
`;

export const PageWrapper = styled.div`
  overflow-x: hidden;
`;
