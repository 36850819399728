import { Flex } from 'components/layout';
import { css } from '@emotion/react';
import theme from 'theme';

import { CaroucelImg, Title } from './mediaCaroucel.styled';

const MediaCarousel = () => (
  <Flex width="100%" justifyContent="center" flexDirection="column">
    <Title>As mentioned on these reputable media sources:</Title>
    <Flex
      width="100%"
      justifyContent="space-evenly"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          width: fit-content;
          justify-content: center;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          margin: 20px auto;
          grid-gap: 20px;
        }
      `}
    >
      <CaroucelImg src="/marketing/news/0.jpg" alt="media" />
      <CaroucelImg src="/marketing/news/1.jpg" alt="media" />
      <CaroucelImg src="/marketing/news/3.jpg" alt="media" />
      <CaroucelImg src="/marketing/news/4.jpg" alt="media" />
      <CaroucelImg src="/marketing/news/5.png" alt="media" />
      <CaroucelImg src="/marketing/news/6.png" alt="media" />
    </Flex>
  </Flex>
);

export default MediaCarousel;
