import { css } from '@emotion/react';
import { Button } from 'components/elements';
import theme from 'theme';

const CommentButton = ({
  text,
  onClick,
  active,
}: {
  text: string;
  onClick: () => void;
  active: boolean;
}) => (
  <Button
    onClick={onClick}
    mx="10px"
    backgroundColor={theme.colors.backgroundGrey}
    p="10px"
    borderRadius={theme.borderRadius.small}
    overrideCss={css`
      transition: 0.1s ease-in-out;
      color: black;
      &:hover {
        transform: translateX(-25%) translateY(-25%);
        transform: scale(1.05, 1.05);
        background-color: ${theme.colors.selectionPrimary};
        box-shadow: ${theme.elevationShadow};
      }
      ${active &&
      css`
        background-color: ${theme.colors.primary};
        color: white;
      `}
    `}
  >
    {text}
  </Button>
);
export default CommentButton;
