import 'styles/map.scss';
import 'styles/mapMarker.scss';

import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import Moment from 'react-moment';
import { apiBaseUrlBase } from 'constants/app';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment-timezone';
import _ from 'lodash';

import 'styles/mapModal.scss';
import { Context } from 'functions/context';
import lottie from 'lottie-web';
import { getGoogleMapsEntryID, getWorkRequest } from 'interface/map';
import theme from 'theme';
export default class MarkerViewModal extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      loadingMarkerViewModal: true,
      loadingMarkerViewModalA: true,
      timesDropDown: false,
      viewProgress: false,
      textCopied: false,
      workRequest: { imageIds: [], notes: [] },
    };
  }

  componentDidMount = async () => {
    const {
      showMarkerViewModalWR,
      authenticatedState,
      subscriberId,
      serviceType,
    } = this.props;
    // Loading
    const animLogin12 = lottie.loadAnimation({
      name: 'loadingShowMarkerViewModalWR',
      container: this.animateLoadingVM,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'mscLogoGrey.json',
    });
    animLogin12.setSpeed(1.5);
    animLogin12.onLoopComplete = () => {
      const { loadingMarkerViewModal, loadingMarkerViewModalA } = this.state;
      console.log(
        'onLoopComplete',
        loadingMarkerViewModal,
        loadingMarkerViewModalA
      );
      if (!loadingMarkerViewModal) {
        lottie.stop('loadingShowMarkerViewModalWR');
        this.setState({ loadingMarkerViewModalA: false });
        console.log('onLoopComplete: loadingShowMarkerViewModalWR');
      }
    };
    console.log('showMarkerViewModalWR', showMarkerViewModalWR);

    if (`${serviceType}` === 'municipal' || `${serviceType}` === 'petitions') {
      console.log('authenticatedStateauthenticatedState', authenticatedState);
      const workRequest = await getWorkRequest(
        showMarkerViewModalWR.id,
        subscriberId,
        authenticatedState
      );
      console.log('workRequestworkRequest', workRequest);
      this.setState({ workRequest, loadingMarkerViewModal: false });
    }
    // get the work order
    if (
      `${serviceType}` === 'emergency' ||
      serviceType === 'public-transport'
    ) {
      let temp;
      if (subscriberId !== 'george') {
        const res = await getGoogleMapsEntryID(showMarkerViewModalWR.place_id);
        temp = _.cloneDeep(res.result);
      } else {
        const queryId = serviceType === 'public-transport' ? 74 : 48;
        // Custom non-Google implementation for George Municipality.
        const res = await fetch(
          `https://gis.george.gov.za/server/rest/services/Public_App_Data/MapServer/${queryId}/query?where=OBJECTID=${showMarkerViewModalWR.id}&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Kilometer&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`
        );
        const json = await res.json();
        const feature = json.features[0];
        temp = {
          business_status: 'OPERATIONAL',
          customIcon: null,
          formatted_phone_number: feature.properties.RouteName,
          name: feature.properties.StopName,
          formatted_address: feature.properties.TimeTableW,
        };
      }
      this.setState({ workRequest: temp, loadingMarkerViewModal: false });
    }
    if (
      `${serviceType}` === 'emergency' &&
      `${serviceType}` === 'municipal' &&
      `${serviceType}` === 'petitions'
    ) {
      console.warn('serviceType not correct', `${serviceType}`);
      this.setState({
        workRequest: { typeDescription: 'ERROR' },
        loadingMarkerViewModal: false,
      });
    }
  };

  componentWillUnmount = () => {
    lottie.destroy('loadingShowMarkerViewModalWR');
  };

  render() {
    const {
      loadingMarkerViewModal,
      loadingMarkerViewModalA,
      workRequest,
      viewProgress,
      timesDropDown,
      textCopied,
    } = this.state;
    const { setState, authenticatedState, subscriberId, serviceType } =
      this.props;

    let votesToGoalPercent = 0;
    if (workRequest.custom1 !== null) {
      if (workRequest.customNumber1) {
        if ((workRequest.upVoteCount / workRequest.customNumber1) * 100 < 100) {
          votesToGoalPercent =
            (workRequest.upVoteCount / workRequest.customNumber1) * 100;
        }
        votesToGoalPercent = 100;
      }
      if ((workRequest.upVoteCount / 10) * 100 > 100) {
        votesToGoalPercent = 100;
      }
      votesToGoalPercent = (workRequest.upVoteCount / 10) * 100;
    }
    if (loadingMarkerViewModal) {
      lottie.play('loadingShowMarkerViewModalWR');
    }

    return (
      <div className="mapMarkerPopup">
        <div
          id="LoadingLoginOverlayContainer"
          style={
            loadingMarkerViewModalA ? { display: 'block' } : { display: 'none' }
          }
        >
          <div
            id="LoadingLoginOverlay"
            className={loadingMarkerViewModal ? 'fadeIn' : 'fadeOut'}
          />
          <div
            ref={(ref) => {
              this.animateLoadingVM = ref;
            }}
            id="LoadingLoginOverlayImage"
            style={
              loadingMarkerViewModalA
                ? { display: 'block' }
                : { display: 'none' }
            }
          />
        </div>
        {serviceType === 'municipal' ? (
          /* REPORT POPUP */
          <div className="reportPopup">
            <img
              className="popupPin"
              src="map/mapMarker/reportPin.svg"
              alt="Report button"
            />
            <Scrollbars
              autoHide
              autoHeight
              autoHeightmin="20vh"
              autoHeightMax="70vh"
              className="popUp"
            >
              <div className="mapMarkerContent">
                <div className="popupHeading">
                  {workRequest.typeDescription}
                </div>
                <div className="popupSubHeading">
                  Issue number:{' '}
                  <div className="textAccentOrange">{workRequest.code}</div>
                </div>
                <div className="popupSubHeading">
                  {workRequest.address &&
                    workRequest.address.slice(1).replaceAll(',', ', ')}
                </div>
                <div className="popupSubHeading">
                  Reported on{' '}
                  <Moment
                    tz={moment.tz.guess()}
                    format="D MMMM YYYY"
                    date={workRequest.createdDate * 1000}
                  />
                </div>

                {/* REPORT POPUP - VOTE/SIGN CONTAINER */}
                <div className="voteSignContainer">
                  {workRequest.upVoteCount}
                  <div
                    role="button"
                    tabIndex={0}
                    className="voteSignButton"
                    onClick={
                      authenticatedState
                        ? () => {
                            if (workRequest.canUpVote) {
                              return setState({
                                showUpVoteReportWR: workRequest,
                                showUpVoteReport: true,
                                showMarkerView: false,
                              });
                            }
                            return null;
                          }
                        : () =>
                            setState({
                              loginModal: true,
                              showMarkerView: false,
                            })
                    }
                  >
                    <img
                      className={
                        workRequest.canUpVote && authenticatedState
                          ? 'upvoteArrowImage filterOrange'
                          : 'upvoteArrowImage'
                      }
                      src="map/mapMarker/upArrow.svg"
                      alt="Up arrow"
                    />
                  </div>
                </div>
                {/* REPORT POPUP - PROGRESS CONTAINER */}
                <div className="popupContainer">
                  <div className="popupCategoryHeader">Progress status</div>

                  <div
                    style={{
                      fontSize: '1.5rem',
                    }}
                  >
                    {workRequest.statusDescription}
                  </div>
                </div>

                {/* REPORT POPUP - PHOTOS */}
                <div className="popupContainer">
                  <div className="popupCategoryHeader">Photos</div>
                  <Scrollbars autoHide autoHeight>
                    <div style={{ display: 'flex', padding: '0px 0px 20px' }}>
                      {workRequest.imageIds.map((val, i) => (
                        <a
                          rel="noopener noreferrer"
                          href={`${apiBaseUrlBase}modal/viewimagefile.html?id=${val}&subscriberId=${subscriberId}`}
                          target="_blank"
                        >
                          <div
                            className="imageBlock"
                            role="button"
                            tabIndex={0}
                            alt="test"
                            key={`imageIds-${i}`}
                            style={{
                              background: `url('${apiBaseUrlBase}modal/viewimagefile.html?id=${val}&subscriberId=${subscriberId}')`,
                            }}
                          />
                        </a>
                      ))}
                      {workRequest.imageIds.length === 0 ? (
                        <div style={{ fontSize: '1.4rem' }}>
                          No photos have been added.
                          {workRequest.loggedByMe
                            ? ' Click edit to add some.'
                            : null}
                        </div>
                      ) : null}
                    </div>
                  </Scrollbars>
                </div>

                {/* REPORT POPUP - DESCRIPTION */}
                <div className="popupContainer">
                  <div className="popupCategoryHeader">Description</div>
                  <div className="popupDescriptionContainer">
                    {workRequest.description}
                  </div>
                </div>

                {/* REPORT POPUP - NOTES */}
                <div className="popupContainer">
                  <div className="popupCategoryHeader">Notes</div>
                  <div className="popupNotesContainer">
                    {workRequest.notes.length === 0 ? (
                      <div style={{ fontSize: '1.4rem' }}>
                        There are currently no notes on this issue.
                        {workRequest.loggedByMe
                          ? ' Click edit to add some.'
                          : null}
                      </div>
                    ) : (
                      workRequest.notes.map((note) => (
                        <>
                          <div className="reportsNoteContainer">
                            <img
                              // src={`${apiBaseUrlBase}images/profilepicture
                              /// ?username=${note.username}`}
                              src={`${apiBaseUrlBase}images/profilepicture/?username=${note.username}`}
                              alt="profileimage"
                              className="notesProfileImage"
                            />

                            <div className="reportsNoteTextContainer">
                              <div className="reportsNoteText">
                                {note.noteText}
                              </div>
                              <div className="reportsSubNoteText">
                                {note.fullName} on the{' '}
                                <Moment
                                  tz={moment.tz.guess()}
                                  format="DD/MM/YYYY"
                                  date={note.timestamp * 1000}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    )}
                  </div>
                </div>

                <div className="shareThis">
                  <div className="textAccentBold textAccentOrange">
                    Share this report
                  </div>
                  <div className="iconRow">
                    <div
                      role="button"
                      className="iconButton"
                      tabIndex={0}
                      onClick={() => {
                        window.open(
                          `https://www.facebook.com/sharer/sharer.php?u=mysmart.city/map/${subscriberId}/municipal/${workRequest.id}&t=${workRequest.description}`,
                          '_blank'
                        );
                      }}
                    >
                      <img
                        src="map/socialMedia/facebook.svg"
                        alt="Category icon"
                        className="shareIcons"
                      />
                    </div>
                    <div
                      role="button"
                      className="iconButton"
                      tabIndex={0}
                      onClick={() => {
                        window.open(
                          `http://twitter.com/share?text=${workRequest.description}&url=https://mysmart.city/map/${subscriberId}/municipal/${workRequest.id}&hashtags=mysmartcity`,
                          '_blank'
                        );
                      }}
                    >
                      <img
                        src="map/socialMedia/twitter.svg"
                        alt="Category icon"
                        className="shareIcons"
                      />
                    </div>
                    <div
                      role="button"
                      className="iconButton"
                      tabIndex={0}
                      onClick={() => {
                        this.setState({ textCopied: true });
                        navigator.clipboard.writeText(
                          `https://mysmart.city/map/${subscriberId}/municipal/${workRequest.id}`
                        );
                      }}
                    >
                      <img
                        src="map/socialMedia/magnetlink.svg"
                        alt="Category icon"
                        className="shareIcons"
                      />
                    </div>
                    {textCopied ? (
                      <div style={{ fontSize: '15px' }}>
                        Link copied to clipboard
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>

                {workRequest.loggedByMe ? (
                  <div className="buttonContainer">
                    <Button
                      className="homeButtonPopup"
                      onClick={() => {
                        setState({
                          showEditReport: true,
                          showEditReportWR: workRequest,
                          showMarkerView: false,
                        });
                      }}
                    >
                      EDIT
                    </Button>
                  </div>
                ) : null}
              </div>
            </Scrollbars>
          </div>
        ) : null}
        {serviceType === 'petitions' ? (
          /* PETITION POPUP */
          <div className="petitionPopup">
            <img
              className="popupPin"
              src="map/mapMarker/petitionPin.svg"
              alt="Report button"
            />
            <Scrollbars
              autoHide
              autoHeight
              autoHeightmin="20vh"
              autoHeightMax="70vh"
              className="popUp"
            >
              <div className="mapMarkerContent">
                <div className="popupHeading">{workRequest.custom1}</div>
                <div className="popupSubHeading">{workRequest.address}</div>
                <div className="popupSubHeading">
                  <Moment
                    tz={moment.tz.guess()}
                    format="D MMMM YYYY"
                    date={workRequest.createdDate * 1000}
                  />
                </div>

                {/* PETITION POPUP - VOTE/SIGN CONTAINER */}
                <div className="voteSignContainer">
                  {workRequest.upVoteCount}
                  <div
                    role="button"
                    tabIndex={0}
                    className="voteSignButton"
                    onClick={
                      authenticatedState
                        ? () => {
                            if (workRequest.canUpVote) {
                              return setState({
                                showSignPetitionWR: workRequest,
                                showSignPetition: true,
                                showMarkerView: false,
                              });
                            }
                            return null;
                          }
                        : () =>
                            setState({
                              loginModal: true,
                              showMarkerView: false,
                            })
                    }
                  >
                    <img
                      className={
                        workRequest.canUpVote && authenticatedState
                          ? 'upvoteArrowImage filterOrange'
                          : 'upvoteArrowImage'
                      }
                      src="map/mapMarker/signPetition.svg"
                      alt="Up arrow"
                    />
                  </div>
                </div>
                {/* PETITION POPUP - PROGRESS CONTAINER */}
                <div className="progressContainer">
                  <div className="popupCategoryHeader">
                    Progress status
                    <div
                      className="textAccentOrange"
                      role="button"
                      tabIndex={0}
                      style={{
                        display: 'block',
                        float: 'right',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (viewProgress) {
                          this.setState({ viewProgress: false });
                        } else {
                          this.setState({ viewProgress: true });
                        }
                      }}
                    >
                      {viewProgress ? 'View' : 'Hide'}
                    </div>
                  </div>
                  {viewProgress ? (
                    <ProgressBar now={votesToGoalPercent} />
                  ) : (
                    <div>
                      <div className="popupStatusInfo">
                        {workRequest.upVoteCount}{' '}
                        <div className="textAccentOrange"> | </div>
                        {workRequest.customNumber1}
                        <div style={{ float: 'right' }}> Signatures </div>
                      </div>
                      <div className="popupStatusInfo">
                        {workRequest.statusDescription}
                        <div style={{ float: 'right' }}> Status </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* PETITION POPUP - PHOTOS */}
                <div className="popupContainer">
                  <div className="popupCategoryHeader">Photos</div>
                  <Scrollbars autoHide autoHeight>
                    <div style={{ display: 'flex', padding: '0px 0px 20px' }}>
                      {workRequest.imageIds.map((val, i) => (
                        <a
                          rel="noopener noreferrer"
                          href={`${apiBaseUrlBase}modal/viewimagefile.html?id=${val}&subscriberId=${subscriberId}`}
                          target="_blank"
                        >
                          <div
                            className="imageBlock"
                            role="button"
                            tabIndex={0}
                            alt="test"
                            key={`imageIds-${i}`}
                            style={{
                              background: `url('${apiBaseUrlBase}modal/viewimagefile.html?id=${val}&subscriberId=${subscriberId}')`,
                            }}
                          />
                        </a>
                      ))}
                      {workRequest.imageIds.length === 0 ? (
                        <div style={{ fontSize: '1.4rem' }}>
                          No photos have been added.
                          {workRequest.loggedByMe
                            ? ' Click edit to add some.'
                            : null}
                        </div>
                      ) : null}
                    </div>
                  </Scrollbars>
                </div>

                {/* PETITION POPUP - DESCRIPTION */}
                <div className="reportPopupDescription">
                  <div className="popupCategoryHeader">Description</div>
                  <div className="popupDescriptionContainer">
                    {workRequest.description}
                  </div>
                </div>

                <div className="shareThis">
                  <div className="textAccentBold textAccentOrange">
                    Share this petition
                  </div>
                  <div className="iconRow">
                    <div
                      role="button"
                      className="iconButton"
                      tabIndex={0}
                      onClick={() => {
                        window.open(
                          `https://www.facebook.com/sharer/sharer.php?u=mysmart.city/map/${subscriberId}/petitions/${workRequest.id}&t=${workRequest.description}`,
                          '_blank'
                        );
                      }}
                    >
                      <img
                        src="map/socialMedia/facebook.svg"
                        alt="Category icon"
                        className="shareIcons"
                      />
                    </div>
                    <div
                      role="button"
                      className="iconButton"
                      tabIndex={0}
                      onClick={() => {
                        window.open(
                          `http://twitter.com/share?text=${workRequest.description}&url=https://mysmart.city/map/${subscriberId}/petitions/${workRequest.id}&hashtags=mysmartcity`,
                          '_blank'
                        );
                      }}
                    >
                      <img
                        src="map/socialMedia/twitter.svg"
                        alt="Category icon"
                        className="shareIcons"
                      />
                    </div>
                    <div
                      role="button"
                      className="iconButton"
                      tabIndex={0}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://mysmart.city/map/${subscriberId}/petitions/${workRequest.id}`
                        );
                        this.setState({ textCopied: true });
                      }}
                    >
                      <img
                        src="map/socialMedia/magnetlink.svg"
                        alt="Category icon"
                        className="shareIcons"
                      />
                    </div>
                    {textCopied ? (
                      <div style={{ fontSize: '15px' }}>
                        Link copied to clipboard
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
        ) : null}
        {serviceType === 'emergency' ? (
          /* EMERGENCY POPUP */
          <div className="emergencyPopup">
            <img
              className="popupPin"
              src="map/mapMarker/petitionPin.svg"
              alt="Report button"
            />
            <Scrollbars
              autoHeight
              autoHeightmin="20vh"
              autoHeightMax="70vh"
              className="popUp"
            >
              <div className="mapMarkerContent">
                <div className="popupHeading">{workRequest.name}</div>
                <div className="popupSubHeading">
                  {workRequest.formatted_address}
                </div>
                {workRequest.formatted_phone_number && (
                  <div className="popupCellNumber">
                    <img
                      src="map/phoneWard.svg"
                      alt="phone"
                      style={{
                        height: '20px',
                        marginLeft: '-30px',
                        marginRight: '15px',
                      }}
                    />
                    <a
                      href={`tel:${workRequest.formatted_phone_number}`}
                      style={{
                        color: theme.colors.primary,
                      }}
                    >
                      {workRequest.formatted_phone_number}
                    </a>
                  </div>
                )}
                <div className="popupOpeningTimes">
                  {workRequest.opening_hours && (
                    <div>
                      <div className="openNow">
                        {workRequest.opening_hours.open_now ? (
                          <div
                            tabIndex={0}
                            role="button"
                            onClick={() =>
                              this.setState({ timesDropDown: !timesDropDown })
                            }
                          >
                            {' '}
                            Open now
                            <img
                              className={
                                timesDropDown
                                  ? 'arrowDropDown'
                                  : 'arrowDropDown arrowDropDownRotate'
                              }
                              src="map/mapMarker/roundArrowRight.svg"
                              alt="phone"
                            />
                          </div>
                        ) : (
                          <div
                            tabIndex={0}
                            role="button"
                            onClick={() =>
                              this.setState({ timesDropDown: !timesDropDown })
                            }
                          >
                            Closed
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          timesDropDown
                            ? 'openTimesDropDown'
                            : 'openTimesDropDown openTimesDropDownShow'
                        }
                      >
                        {workRequest.opening_hours.weekday_text &&
                          workRequest.opening_hours.weekday_text.map((data) => (
                            <div className="openingTime">
                              <div
                                style={
                                  moment().format('dddd') === data.split(':')[0]
                                    ? {
                                        float: 'left',
                                        fontFamily: 'GothamMedium',
                                      }
                                    : { float: 'left' }
                                }
                              >
                                {data.split(':')[0]}
                              </div>

                              <div
                                style={
                                  moment().format('dddd') === data.split(':')[0]
                                    ? {
                                        float: 'right',
                                        fontFamily: 'GothamMedium',
                                      }
                                    : { float: 'right' }
                                }
                              >
                                {data.split(':')[1]}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="popupRatings">
                  <div className="popupRatingsHeading">
                    {workRequest.rating}
                  </div>
                  <div>
                    {workRequest.rating &&
                      workRequest.rating > 0 &&
                      [...Array(Math.ceil(workRequest.rating + 0.01))].map(
                        (x) => (
                          <img
                            src="map/mapMarker/starReview.svg"
                            alt="star"
                            key={x}
                            style={{
                              height: '30px',
                              margin: '0px 15px',
                              filter:
                                'invert(51%) sepia(100%) saturate(1121%) hue-rotate(343deg) brightness(99%) contrast(98%)',
                            }}
                          />
                        )
                      )}
                    {workRequest.rating &&
                      [...Array(Math.floor(5 - workRequest.rating))].map(
                        (x) => (
                          <img
                            src="map/mapMarker/starReview.svg"
                            alt="star"
                            key={x}
                            style={{ height: '30px', margin: '0px 15px' }}
                          />
                        )
                      )}
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
        ) : null}
        {serviceType === 'public-transport' ? (
          <div className="emergencyPopup">
            <img
              className="popupPin"
              src="map/mapMarker/petitionPin.svg"
              alt="Report button"
            />
            <Scrollbars
              autoHeight
              autoHeightmin="20vh"
              autoHeightMax="70vh"
              className="popUp"
            >
              <div className="mapMarkerContent">
                <div className="popupHeading">{workRequest.name} bus stop</div>
                <div className="popupSubHeading">
                  This stop is on Route {workRequest.formatted_phone_number},
                  operating {workRequest.formatted_address}
                </div>
              </div>
            </Scrollbars>
          </div>
        ) : null}
      </div>
    );
  }
}
