import { Flex, Grid, Box } from 'components/layout';
import { Text } from 'components/typography';
import { Image, Button } from 'components/elements';
import theme from 'theme';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { EnRouteResource } from 'types/types';

const mySound = new Audio('resource-banner-images/ping.mp3');

const ResourceBannerMobile = ({
  resource,
  setTrackedResource,
  showChat,
}: {
  resource: EnRouteResource;
  setTrackedResource: () => void;
  showChat: () => void;
}) => {
  const [bottom, setBottom] = useState('-100px');
  const [closed, setClosed] = useState(false);
  const eta = Math.floor((resource.eta - new Date().getTime() / 1000) / 60);

  useEffect(() => {
    setBottom('40px');
    mySound.play();
  }, []);

  if (closed) {
    return null;
  }

  return (
    <Grid
      position="fixed"
      bottom={bottom}
      right="10px"
      left="10px"
      zIndex={10001}
      py="10px"
      alignItems="center"
      gridTemplateColumns="0.5fr 3.5fr 1fr"
      hideForDesktop
      background="white"
      boxShadow={theme.elevationShadow}
      borderRadius={theme.borderRadius.small}
      overrideCss={css`
        transition: all 0.8s ease-in;
      `}
    >
      <Flex>
        <Box
          mx="10px"
          borderRadius="50%"
          width="25px"
          height="25px"
          background="radial-gradient(circle, rgba(0,163,0,1) 2%, rgba(255,255,255,1) 38%)"
        />
      </Flex>
      <Grid alignItems="center" gridTemplateColumns="0.4fr 2fr">
        <Image
          width="29px"
          height="29px"
          objectFit="cover"
          borderRadius="50%"
          source={resource.profilePic}
          alt="profile"
        />
        <Grid gridTemplateRows="1fr 1fr">
          <Text>{resource.fullName}</Text>
          <Text fontSize="12px" color="#00A300">
            {eta > 0 ? ` Arriving in ${eta} minutes ` : 'Service in progress '}
          </Text>
        </Grid>
      </Grid>
      <Flex justifyContent="space-around">
        <Button
          mx="20px"
          onClick={() => {
            setTrackedResource();
            showChat();
          }}
        >
          <Image
            source="resource-banner-assets/chaticonmobile.png"
            alt="chat icon"
          />
        </Button>
        <Button onClick={() => setClosed(true)}>
          <Image
            mx="20px"
            ml="20px"
            source="resource-banner-assets/exiticonmobile.png"
            alt="exit"
          />
        </Button>
      </Flex>
    </Grid>
  );
};
export default ResourceBannerMobile;
