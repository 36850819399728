/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ActionBar } from 'components';
import CreatePostMenu from 'components/social/createpostmenu';
import { getWorkOrder } from 'interface/social/alerts';
import { SocialProvider } from 'contexts/social';
import { useGlobalContext } from 'contexts/global';
import { CreatePost, Breadcrumbs, Alerts, Search } from 'components/social';
import { AlertPost } from 'components/social/post';
import { SuggestionList } from 'components/social/connection';
import { Heading } from 'components/social/text';
import {
  NotFoundContainer,
  ContentRow,
  TopRow,
  PageContainer,
  FilterColumn,
  FeedColumn,
  FeedHeader,
  ConnectionsColumn,
  Feed,
} from './social.styled';
import { Title, TextLink } from './connections.styled';

const AlertPage = () => {
  const { id, city } = useParams();
  const navigate = useNavigate();

  if (!id || !city) {
    navigate('/social');
  }

  const [showCreatePost, setShowCreatePost] = useState(false);
  const [createPostType, setCreatePostType] = useState(null);
  const [alert, setAlert] = useState(null);
  const { globalState } = useGlobalContext();

  const handleCreatePost = (type) => {
    if (type !== null) {
      setShowCreatePost(true);
      setCreatePostType(type);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const resWr = await getWorkOrder(id);
      console.log('workorder', resWr);
      if (resWr.id) {
        setAlert(resWr);
      } else {
        navigate('/social');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // if user change city, redirect to /social
    if (
      globalState.city?.value &&
      Number(city) !== Number(globalState.city.value)
    ) {
      navigate('/social');
    }
  }, [globalState.city]);

  return (
    <SocialProvider>
      <CreatePost
        show={showCreatePost}
        type={createPostType}
        handleClose={() => setShowCreatePost(false)}
      />
      <ActionBar>
        <CreatePostMenu handleCreatePost={handleCreatePost} />
        <Search />
      </ActionBar>

      <PageContainer>
        <TopRow>
          <Breadcrumbs />
        </TopRow>

        <ContentRow>
          <FilterColumn>{/* <Filter /> */}</FilterColumn>

          <FeedColumn>
            <Heading>Alerts</Heading>

            <Alerts />

            <FeedHeader>
              <h2>Alert</h2>
            </FeedHeader>
            <Feed>
              {!alert?.id && (
                <NotFoundContainer>
                  <h2>No alert found</h2>
                </NotFoundContainer>
              )}
              {alert?.id && (
                <AlertPost
                  id={id}
                  isFirst={false}
                  profileImg="chatbot/msc_profile.svg"
                  username="My Smart City"
                  address={alert.address}
                  createdDate={alert.createdDate}
                  plannedStart={alert.plannedStart}
                  plannedFinish={alert.plannedFinish}
                  type={alert.typeDescription}
                  title={alert.title}
                  text={alert.description}
                  images={alert.imageIds}
                />
              )}
            </Feed>
          </FeedColumn>

          <ConnectionsColumn>
            <Heading style={{ marginBottom: '23px' }}>Connections</Heading>
            <Title>Suggested Follows</Title>
            <SuggestionList />
            <TextLink to="/social/connections">See more suggestions</TextLink>
          </ConnectionsColumn>
        </ContentRow>
      </PageContainer>
    </SocialProvider>
  );
};

export default AlertPage;
