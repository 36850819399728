import { useState } from 'react';
import {
  Routes,
  Route,
  Link,
  useLocation,
  Outlet,
  useNavigate,
} from 'react-router-dom';

import { LoginModal } from 'components';
import { useGlobalContext } from 'contexts/global';
import { MSCProMunicipalities } from 'constants/msc-pro-municipalities';
import { Flex, Grid } from 'components/layout';
import theme from 'theme';
import { css } from '@emotion/react';
import { Text } from 'components/typography';
import { UserEvents, logFirebaseEvent } from 'functions/firebase';
import { Logo, StyledNavbar } from './navbar.styled';
import Sidebar from './sidebar';
import { UserNavigation } from './profile';
import CitySelect from './citySelect';

// navbar will not be rendered on these paths
const EXCLUDE_PATHS = ['/download', '/400'];

const NavItem = ({
  to,
  children,
  isActive,
}: {
  to: string;
  children: any;
  isActive: boolean;
}) => (
  <Link
    to={to}
    onClick={() => logFirebaseEvent(UserEvents.NavigateToPage, { page: to })}
  >
    <Flex
      width="100%"
      height="100%"
      px="15px"
      backgroundColor={isActive ? theme.colors.primary : 'transparent'}
      color="white"
      alignItems="center"
      justifyContent="center"
      fontSize="14px"
      fontWeight={600}
    >
      {children}
    </Flex>
  </Link>
);

const NavbarComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { globalState, setShowLoginModal } = useGlobalContext();
  const [showSocialDropdown, setShowSocialDropdown] = useState(false);

  return (
    <StyledNavbar>
      <Sidebar setShowLoginModal={(s: boolean) => setShowLoginModal(s)} />
      <LoginModal
        show={globalState.showLoginModal}
        hide={() => {
          setShowLoginModal(false);
          if (location.pathname !== '/') navigate('/');
        }}
        onSignIn={() => {
          setShowLoginModal(false);
        }}
      />

      <Link to="/">
        <Logo alt="logo" src="logo.svg" />
      </Link>

      <Grid
        gridTemplateColumns="repeat(4, 1fr)"
        height="100%"
        mr="35%"
        hideForMobile
        hideForTablet
      >
        <NavItem to="/map" isActive={location.pathname.includes('/map')}>
          Map
        </NavItem>
        <NavItem to="/" isActive={location.pathname.includes('book-a-service')}>
          Book a Service
        </NavItem>
        {globalState?.city?.label !== 'init' &&
          globalState?.city?.value &&
          MSCProMunicipalities.includes(globalState.city.value) && (
            <NavItem
              to={`/my-municipality/services/${globalState.city.value}`}
              isActive={location.pathname.includes('/my-municipality')}
            >
              Municipality
            </NavItem>
          )}
        <Link to="/social">
          <Flex
            position="relative"
            width="100%"
            height="100%"
            px="10px"
            backgroundColor={
              location.pathname.includes('social') ||
              location.pathname.includes('press') ||
              location.pathname.includes('in-the-city') ||
              location.pathname.includes('blog')
                ? theme.colors.primary
                : 'transparent'
            }
            color="white"
            alignItems="center"
            justifyContent="center"
            fontSize="14px"
            fontWeight={600}
            hoverCss={css`
              cursor: pointer;
            `}
            onHover={(active) => setShowSocialDropdown(active)}
          >
            <Text>Social</Text>
            {showSocialDropdown && (
              <Grid
                boxShadow={theme.elevationShadow}
                position="absolute"
                top="100%"
                left="0"
                backgroundColor="white"
                width="250px"
                zIndex={1000}
              >
                <Link to="/in-the-city" style={{ width: '100%' }}>
                  <Flex
                    py="16px"
                    color={theme.colors.primary}
                    width="100%"
                    justifyContent="center"
                    borderB={`1px solid ${theme.colors.selectionPrimary}`}
                  >
                    <Text textAlign="center" fontSize="14px">
                      In the City
                    </Text>
                  </Flex>
                </Link>
                <Link to="/press" style={{ width: '100%' }}>
                  <Flex
                    py="16px"
                    color={theme.colors.primary}
                    width="100%"
                    justifyContent="center"
                    borderB={`1px solid ${theme.colors.selectionPrimary}`}
                  >
                    <Text textAlign="center" fontSize="14px">
                      In the Press
                    </Text>
                  </Flex>
                </Link>
                <Link to="/blog" style={{ width: '100%' }}>
                  <Flex
                    py="16px"
                    color={theme.colors.primary}
                    width="100%"
                    justifyContent="center"
                  >
                    <Text textAlign="center" fontSize="14px">
                      Blog
                    </Text>
                  </Flex>
                </Link>
              </Grid>
            )}
          </Flex>
        </Link>
      </Grid>
      <Routes>
        <Route path="/map" element={<Outlet />}>
          <Route path="*" element={null} />
        </Route>
        <Route path="*" element={<CitySelect />} />
      </Routes>

      <UserNavigation setShowLoginModal={(s: any) => setShowLoginModal(s)} />
    </StyledNavbar>
  );
};

/**
 * Navigation bar component.
 */
const Navbar = () => (
  <Routes>
    {EXCLUDE_PATHS.map((path, index) => (
      <Route path={path} element={null} key={index} />
    ))}

    <Route path="*" element={<NavbarComponent />} />
  </Routes>
);

export default Navbar;
