import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 35px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid ${(props) => (props.variation === 'dark' ? '#f0f2f3' : '#FFFFFF')};
  box-shadow: 0px 0px 30px ${(props) => (props.variation === 'dark' ? 'hsla(0, 0%, 0%, 0.0118)' : '#00000014')};
  background-color: ${(props) => (props.variation === 'dark' ? '#f0f2f3' : '#FFFFFF')}; 
  min-width: 0;
  
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 780px;
    height: 62px;
    border-radius: 5px;

    &:not(:last-child) {
      margin-right: 50px;
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    /* width: 600px;
    margin-right: 50px; */
  }

  @media only screen and (min-width: 1400px) {
    /* width: 780px; */
    /* margin-right: 100px; */
  }
`;

export const PinIcon = styled.img`
  flex-shrink: 0;
  width: 12px;
  height: 16px;
  margin-left: 18px;
  margin-right: 18px;

  @media only screen and (min-width: 1400px) {
    margin-left: 35px;
    margin-right: 35px;
    width: 18px;
    height: 30px;
  }
`;

export const Input = styled.input`
  flex: 1;
  color: #3A3945;
  background-color: ${(props) => (props.variation === 'dark' ? '#f0f2f3' : '#FFFFFF')}; 
  border-radius: 2px;
  border: 1px solid ${(props) => (props.variation === 'dark' ? '#f0f2f3' : '#FFFFFF')};
  transition: 0.2s ease-in-out;
  font: normal normal normal 12px/14px ${(props) => (props.theme.font)};
  
  @media only screen and (min-width: 1200px) {
    background-position: 28px 50%;
    background-size: 35px;
    height: 62px;
    border-radius: 5px;
    font: normal normal normal 20px/24px ${(props) => (props.theme.font)};
  }

  &::placeholder {
    color: #B2BCC1;
    opacity: 1;
    border: none;
  }
`;

export const TypeContainer = styled.div`
  flex-shrink: 0;
  position: relative;
`;

export const TypeButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  
  p {
    font: normal normal normal 12px/14px Roboto;
    color: #B2BCC1;
    margin: 0;
    padding: 0;
  }

  img {
    margin-left: 8px;
    height: 6px;
  }
`;

export const TypeDropdown = styled.div`
  z-index: 2;
  position: absolute;
  right: 0;
  top: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000017;
  border-radius: 10px;
  padding: 12px;

  @media only screen and (min-width: 1200px) {
    border-radius: 16px;
    padding: 25px;
  }
`;

export const Option = styled.div`
  font: normal normal normal 12px/14px Roboto;
  color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding: 0 15px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;

export const Divider = styled.div`
  width: 1px;
  height: 24px;
  background: #D9DEE2;
  
  @media only screen and (min-width: 1200px) {
    height: 40px;
  }
`;

export const Square = styled.div`
  background: ${(props) => (props.primary ? '#DF6E21' : '#2096BA')};
  border-radius: 2px;
  width: 10px;
  height: 10px;
  margin: 0 16px;

  @media only screen and (min-width: 1200px) {
    width: 16px;
    height: 16px;
  }
`;

export const Label = styled.p`
  display: none;
  margin: 0;
  padding: 0;

  @media only screen and (min-width: 1200px) {
    margin-right: 16px;
    display: block;
    color: #B2BCC1;
    font: normal normal normal ${(props) => (props.theme.fontSizes[0])} ${(props) => (props.theme.font)};
  }
`;

export const SearchDropdown = styled.div`
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 35px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  padding: 6px 0;
  background: #FFFFFF;
  z-index: 1;
  @media only screen and (min-width: 1200px) {
    top: 62px;
  }
`;

export const SearchResult = styled.p`
  cursor: pointer;
  font: normal normal normal 10px/21px Roboto;
  color: #333333;
  padding: 4px 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  margin-bottom: 4px;
  &:hover {
    background: #b2bcc12e;
  }
  @media only screen and (min-width: 1200px) {
    font: normal normal normal 14px/21px Roboto;
    padding: 4px 60px;
  }
`;
