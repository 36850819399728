/** @jsxImportSource @emotion/react */
import { Box, Flex, Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { convertToReadableDate, toUnix } from 'functions/date';
import usePollApi from 'hooks/use-poll-api';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect, useRef, useState } from 'react';
import { ResourceChatMessage } from 'types/types';
import { Text } from 'components/typography';
import { css } from '@emotion/react';
import theme from 'theme';
import { Image } from 'components/elements';
import { SmallLoader } from 'components/private-services/survey';

const calculateOneYearAgo = (now: Date) => {
  const oneYearAgo = new Date(now);
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  return oneYearAgo;
};

const chatDate = toUnix(calculateOneYearAgo(new Date()));

const ChatPage = () => {
  // API calls
  const { loading: gettingChats, response: chats } = usePollApi(
    SCManagerEndpoints.GetChats,
    30000,
    {
      queryParams: `timestamp=${chatDate}`,
    }
  );
  const {
    loading: gettingMessages,
    callApi: getMessages,
    response: messages,
  } = useSmartCityManager(SCManagerEndpoints.GetChats);
  const {
    loading: sendingMessage,
    callApi: sendMessage,
    response: sentMessage,
  } = useSmartCityManager(SCManagerEndpoints.SendMessageToBackOffice);

  // State management and constants
  const [chatMessages, setChatMessages] = useState<ResourceChatMessage[]>([]);
  const formRef = useRef<HTMLFormElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  // Functions
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // Side Effects
  useEffect(() => {
    if (!sendingMessage && sentMessage) {
      getMessages({ queryParams: `timestamp=${chatDate}` });
    }
  }, [sendingMessage, sentMessage]);

  useEffect(() => {
    if (!gettingMessages && messages) {
      setChatMessages(messages);
    }
  }, [gettingMessages, messages]);

  useEffect(() => {
    if (!gettingChats && chats) {
      setChatMessages(chats);
    }
  }, [gettingChats, chats]);

  useEffect(() => {
    // Scroll to the bottom whenever chatMessages are updated
    scrollToBottom();
  }, [chatMessages]);

  // Handlers
  const handleSendMessage = (data: any) => {
    const theForm = formRef.current;
    sendMessage({
      body: {
        text: data.text,
      },
    });
    if (theForm) {
      theForm.reset();
    }
  };

  return (
    <MunicipalPageWrapper hideFooter>
      {!!chatMessages && (
        <Flex
          ref={chatContainerRef}
          height="400px"
          overflowY="scroll"
          flexDirection="column"
          gap="24px"
          p="24px"
          position="relative"
          mobileCss={css`
            height: 70vh;
            padding-bottom: 15vh;
          `}
          tabletCss={css`
            height: 75vh;
            padding-bottom: 10vh;
          `}
        >
          {chatMessages.map((message: ResourceChatMessage) => (
            <Flex
              justifyContent={
                message.originator === 'BackOffice' ? 'flex-start' : 'flex-end'
              }
              height="fit-content"
              alignItems="flex-start"
            >
              <Box maxWidth="80%">
                <Text
                  px="14px"
                  py="5px"
                  color={
                    message.originator !== 'BackOffice' ? 'white' : 'black'
                  }
                  overrideCss={css`
                    border-radius: ${message.originator !== 'BackOffice'
                      ? '6px 6px 0 6px'
                      : '6px 6px 6px 0'};
                    background-color: ${message.originator !== 'BackOffice'
                      ? '#2096BA'
                      : theme.colors.backgroundGrey};
                  `}
                >
                  {message.text}
                </Text>
                <Flex
                  mt="5px"
                  width="100%"
                  justifyContent="flex-end"
                  alignItems="center"
                  px="5px"
                >
                  <Text
                    fontSize="12px"
                    textAlign="right"
                    mr="6px"
                    color={theme.colors.lightGrey}
                  >
                    {convertToReadableDate(message.timestamp)}
                  </Text>
                  {!!message.read && (
                    <Image
                      source="my-municipality/read.svg"
                      alt="read"
                      width="16px"
                    />
                  )}
                </Flex>
              </Box>
            </Flex>
          ))}
        </Flex>
      )}
      <form
        ref={formRef}
        autoComplete="off"
        onSubmit={(e: any) => {
          e.preventDefault();
          const form = e.target;
          const formData = new FormData(form);
          const formJson = Object.fromEntries(formData.entries());
          handleSendMessage(formJson);
        }}
      >
        <Flex
          height="78px"
          p="10px 16px"
          width="100%"
          borderT={theme.borders.standard1px}
          mt="20px"
          mobileCss={css`
            position: fixed;
            bottom: 0;
            left: 0;
          `}
          tabletCss={css`
            position: fixed;
            bottom: 0;
            left: 0;
          `}
          backgroundColor="white"
        >
          <input
            placeholder="Type message..."
            type="text"
            name="text"
            required
            multiple
            css={css`
              border: none;
              width: 100%;
            `}
          />
          {sendingMessage ? (
            <Box mt="10px">
              <SmallLoader />
            </Box>
          ) : (
            <button
              type="submit"
              css={css`
                border: none;
                background-color: transparent;
              `}
            >
              <Image
                source="my-municipality/send.svg"
                alt="send"
                height="30px"
              />
            </button>
          )}
        </Flex>
      </form>
    </MunicipalPageWrapper>
  );
};

export default ChatPage;
