/* Modules */
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
/* Local */
import { GlobalProvider } from 'contexts/global';
import { AuthProvider } from 'contexts/auth';
import Theme from 'styles/theme';
import { gtmId } from 'constants/app';
import { getEnvironment } from 'functions/app';
import { ContextManager as MySmartCityManager } from './functions/context';
import './index.css';
import App from './App';
import store from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from 'redux/store';

// initialize Google Tag Manager (only on production)
if (getEnvironment() === 'prod') {
  const gtmArgs = { gtmId };
  TagManager.initialize(gtmArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <MySmartCityManager>
            <GlobalProvider>
              <AuthProvider>
                <Theme>
                  <App />
                </Theme>
              </AuthProvider>
            </GlobalProvider>
          </MySmartCityManager>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
