/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment-timezone';

import { Loader } from 'components/social/createpost';
import {
  getUnreadNotificationsCount,
  getNotifications,
} from 'interface/social/notifications';
import {
  markNotificationRead,
  markAllNotificationsAsRead,
} from 'interface/notifications';
import { useOnClickOutside } from 'functions/home';
import { profileImageUrl, wrTypeIconUrl } from 'functions/social';
import { Icon } from './navbar.styled';
import {
  // Container,
  NotFoundContainer,
  Count,
  UnreadDot,
  Dropdown,
  Button,
  ItemContainer,
  InfoContainer,
  Time,
  // Category,
  Username,
  Event,
  InfoRow,
  // Dot,
  Header,
  LinkButton,
  NotificationImage,
} from './notifications.styled';

const Notifications = () => {
  const ref = useRef();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [open, setOpen] = useState(false);

  const fetchNotifications = async () => {
    setLoading(true);
    const notifRes = await getNotifications();
    const countRes = await getUnreadNotificationsCount();
    setData(notifRes);
    setUnreadCount(countRes);
    setLoading(false);
  };

  const setOpenAndRefresh = () => {
    setOpen(!open);
    if (!open) {
      fetchNotifications();
    }
  };

  const handleReadAll = async () => {
    await markAllNotificationsAsRead();
    fetchNotifications();
  };

  const handleRead = async (uuid) => markNotificationRead(uuid);

  useOnClickOutside(ref, () => setOpen(false));

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div style={{ margin: '0px 30px' }} ref={ref}>
      <Button open={open} onClick={setOpenAndRefresh}>
        <Icon src="social/notifications.svg" alt="" />
        {unreadCount > 0 && (
          <Count>{unreadCount > 9 ? '+9' : unreadCount}</Count>
        )}
      </Button>
      <Dropdown open={open}>
        {loading && <Loader />}
        {data.length === 0 && !loading && (
          <NotFoundContainer>
            <h2>No notifications</h2>
            <p>When important things happen we&apos;ll notify you here.</p>
          </NotFoundContainer>
        )}
        {data.length > 0 && (
          <>
            <Header>
              <LinkButton onClick={handleReadAll}>Mark all as read</LinkButton>
            </Header>

            {data.map(
              (
                {
                  cityId,
                  customIcon,
                  read,
                  uuid,
                  title,
                  message,
                  createdDate,
                  socialMediaNotification,
                },
                index
              ) => (
                <Item
                  cityId={cityId}
                  customIcon={customIcon}
                  isSocial={socialMediaNotification}
                  isUnread={!read}
                  uuid={uuid}
                  username={title}
                  event={message}
                  createdDate={createdDate}
                  // handleClick={() => {
                  //   setOpen(false);
                  //   if (!read) {
                  //     handleRead(uuid);
                  //     fetchNotifications();
                  //   }
                  // }}
                  key={index}
                />
              )
            )}
          </>
        )}
      </Dropdown>
    </div>
  );
};

const Item = (props) => {
  const {
    isUnread,
    username,
    event,
    // category,
    createdDate,
    handleClick,
    isSocial,
    customIcon,
    cityId,
  } = props;

  return (
    <ItemContainer>
      <UnreadDot show={isUnread} />
      <NotificationImage
        alt="notification"
        src={isSocial ? profileImageUrl(0) : wrTypeIconUrl(customIcon, cityId)}
      />
      <InfoContainer>
        <InfoRow>
          <Username>{username}</Username>
          {/* <Dot>·</Dot>
          <Category>{category}</Category> */}
          <Time>
            {moment
              .unix(createdDate.toString())
              .tz(moment.tz.guess())
              .fromNow()}
          </Time>
        </InfoRow>
        <Event>{event}</Event>
      </InfoContainer>
    </ItemContainer>
  );
};

export default Notifications;
