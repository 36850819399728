/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Link } from 'react-router-dom';

import { profileImageUrl } from 'functions/social';
import { useAuthContext } from 'contexts/auth';
import { useGlobalContext } from 'contexts/global';
import { Search } from 'components/social';
import {
  Logo,
  StyledNavbar,
  ProfileIcon,
  Icon,
  StyledLink,
} from './navbar.styled';
import CreatePostMenu from './createpostmenu';
import Notifications from './notifications';

const Navbar = ({ handleCreatePost }) => {
  const {
    globalState: { user },
  } = useGlobalContext();
  return (
    <StyledNavbar>
      <Link to="/">
        <Logo alt="logo" src="logo.svg" />
      </Link>
      <Search />
      <CreatePostMenu handleCreatePost={handleCreatePost} />
      <Notifications />
      <StyledLink to="/social/connections">
        <Icon src="social/connections.svg" alt="icon" />
      </StyledLink>
      <StyledLink to="/profile/editProfile">
        <ProfileIcon
          src={user ? profileImageUrl(user.uuid) : 'chatbot/msc_profile.svg'}
          alt="icon"
        />
      </StyledLink>
    </StyledNavbar>
  );
};

export default Navbar;
