/* eslint-disable no-unused-vars */
import { Flex, Grid, Box } from 'components/layout';
import { Text } from 'components/typography';
import { Image, Button } from 'components/elements';
import { EnRouteResource } from 'types/types';
import { useState } from 'react';

// NOTE: Tracking disabled until Forcelink endpoint fixed
const ResourceBanner = ({
  resource,
  setTrackedResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showTracking,
  showChat,
}: {
  resource: EnRouteResource;
  setTrackedResource: () => void;
  showTracking: () => void;
  showChat: () => void;
}) => {
  const [closed, setClosed] = useState(false);
  if (closed) {
    return null;
  }

  const eta = Math.floor((resource.eta - new Date().getTime() / 1000) / 60);
  return (
    <Grid
      alignItems="center"
      gridTemplateColumns="1.5fr 1fr 1fr"
      width="100%"
      px="30px"
      hideForMobile
      hideForTablet
      background=" linear-gradient(90deg, rgba(4,152,4,1) 0%, rgba(0,156,0,1) 50%, rgba(0,163,0,1) 100%)"
      py="20px"
    >
      <Flex alignItems="center" justifyContent="flex-start">
        <Box
          alignItems="flex-start"
          borderRadius="50%"
          width="25px"
          height="25px"
          background=" radial-gradient(circle, rgba(255,255,255,1) 20%, rgba(255,255,255,0.4696079115239846) 32%, rgba(255,255,255,0) 92%);"
        />
        <Image
          mx="15px"
          objectFit="cover"
          width="48px"
          height="48px"
          borderRadius="50%"
          source={resource.profilePic}
          alt="profile"
        />
        <Text fontWeight="bold" fontSize="18px" color="white" px="3px">
          {resource.fullName}
        </Text>
        <Text mx="5px" fontSize="18px" color="white" px="3px">
          &#x2022;
        </Text>
        <Text fontSize="18px" color="white">
          {resource.serviceType || 'Private Services'}
        </Text>
      </Flex>

      <Flex alignItems="center" justifyContent="center">
        <Text fontSize="18px" color="white" px="5px">
          {eta > 0 ? ` Arriving in ${eta} minutes ` : 'Service in progress '}
        </Text>
        {/* Removed until we fix tracking */}
        {/* <Button
          mx="20px"
          onClick={() => {
            setTrackedResource();
            showTracking();
          }}
        >
          <Image source="resource-banner-assets/pindrop.png" alt="Pindrop" />
        </Button> */}
        <Button
          onClick={() => {
            setTrackedResource();
            showChat();
          }}
        >
          <Image source="resource-banner-assets/chaticon.png" alt="chatIcon" />
        </Button>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button onClick={() => setClosed(true)}>
          <Image source="resource-banner-assets/exiticon.png" alt="exit icon" />
        </Button>
      </Flex>
    </Grid>
  );
};

export default ResourceBanner;
