/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Loader } from 'components/inthecity';
import { Box, Flex, Grid } from 'components/layout';
import { Image } from 'components/elements';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { useEffect } from 'react';
import theme from 'theme';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { GMSurvey } from 'types/types';
import { useParams } from 'react-router-dom';

const calculateNumberOfQuestions = (survey: GMSurvey) =>
  survey.categorys.reduce((acc, curr) => acc + curr.questions.length, 0);

const MySurveysPage = () => {
  const {
    loading: gettingSurveys,
    response: availableSurveys,
    callApi: getSurveys,
  } = useSmartCityManager(SCManagerEndpoints.GetMySurveys);
  const { cityId } = useParams<{ cityId: string }>();

  useEffect(() => {
    getSurveys();
  }, []);

  return (
    <MunicipalPageWrapper
      title="My Surveys"
      backLink={`/my-municipality/surveys/${cityId}`}
    >
      {gettingSurveys ? (
        <Loader />
      ) : (
        <>
          <Grid
            gridTemplateColumns="repeat(4, 1fr)"
            gridRowGap="10px"
            gridColumnGap="10px"
            mx="auto"
            mobileCss={css`
              grid-template-columns: 1fr;
              margin: 0;
            `}
          >
            {availableSurveys?.map((survey: GMSurvey) => (
              <Box p="16px" borderRadius="4px" boxShadow={theme.cardShadow}>
                <Grid gridRowGap="5px">
                  <Text
                    fontSize="18px"
                    fontWeight="bold"
                    color={theme.colors.primary}
                  >
                    {survey.surveyRefNo}
                  </Text>
                  <Text fontSize="16px" fontWeight={500}>
                    {survey.description}
                  </Text>
                  <Flex alignItems="center">
                    <Image
                      source="my-municipality/question-icon.svg"
                      alt="question"
                      mr="7px"
                    />
                    <Text color="#2096BA" fontSize="16px" fontWeight={600}>
                      {calculateNumberOfQuestions(survey)}
                    </Text>
                  </Flex>
                </Grid>
              </Box>
            ))}
          </Grid>
        </>
      )}
    </MunicipalPageWrapper>
  );
};

export default MySurveysPage;
