import React from 'react';

import { StyledSection } from './section.styled';

const Section = (props) => {
  const {
    children,
    color = 'white',
    diagonal = false,
    inverted = false,
  } = props;

  return (
    <StyledSection
      color={color}
      diagonal={diagonal}
      inverted={inverted}
    >
      {children}
    </StyledSection>
  );
};

export default Section;
