export const apiBaseUrl = `https://${process.env.REACT_APP_API_BASE}/api/msc/rest/`; // See .evn.* files for deets
export const apiBaseUrlBase = `https://${process.env.REACT_APP_API_BASE}/api/msc/`;
export const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
export const isDevelopment = appEnvironment === 'dev';
export const isProduction = appEnvironment === 'prod';
export const apiUrl = `${apiBaseUrl}`;
export const apiUrlGMAPS = `https://${process.env.REACT_APP_API_BASE}/api/gmaps/`; // https://maps.googleapis.com/maps/api/
export const apiUrlMSC = apiBaseUrl;
export const ICON_IMAGE_URL = `${apiBaseUrlBase}images/custom/`;
export const apiExternalUrl = `https://${process.env.REACT_APP_API_BASE}/api/`;

// configuration for firebase auth
const firebaseConfigProd = {
  apiKey: 'AIzaSyDIr5L9W1OAc7kS3G3i6V2F7YuRISoHz4w',
  authDomain: 'auth.mysmart.city',
  databaseURL: 'https://smartcity-1491995786543.firebaseio.com',
  projectId: 'smartcity-1491995786543',
  storageBucket: 'smartcity-1491995786543.appspot.com',
  messagingSenderId: '11375145447',
  appId: '1:11375145447:web:4ea394a965cc606ac48d42',
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyCGbC7uliEvwV51ibUgfESCEE_UwPXr4u8',
  authDomain: 'mysmartcitydev.firebaseapp.com',
  projectId: 'mysmartcitydev',
  storageBucket: 'mysmartcitydev.appspot.com',
  messagingSenderId: '585066203086',
  appId: '1:585066203086:web:f78563321844c3b36acd9f',
  measurementId: 'G-R4HJE6MVY3',
};

export const firebaseConfig = isProduction
  ? firebaseConfigProd
  : firebaseConfigDev;

export const recaptchaSiteKey = '6LdnXE8bAAAAANIdiuOBwYWuik7-ByCtdD9jZKqi';

// ID for Google Tag Manager
export const gtmId = 'GTM-PPWGJPV';
