const ipLocation = require('iplocation');
const publicIp = require('public-ip');

/**
 * Get Directions URL (Google Maps)
 */
export function getDirectionsURL(address, latitude, longitude) {
  const z = 5;
  if (address) {
    const a = address.replace(' ', '+');
    return `https://www.google.com/maps/dir/?api=1&origin=&destination=${a}&travelmode=driving`;
  }
  if (latitude && longitude) {
    return `https://www.google.com/maps/dir/?api=1&origin=&destination=${latitude},${longitude},${z}&travelmode=driving`;
  }
  return null;
}

/**
 * Get Location
 * @returns {Promise} will return a latitude and longitude object
 */
export async function getLocation() {
  // Find location using browser location if not allowed to use IP
  if (navigator.geolocation) {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    }).catch((e) => {
      console.log(e);
      return false;
    });
    if (position) {
      return position.coords;
    }
  }

  // Find location based on IP
  const loc = await ipLocation(await publicIp.v4());
  return loc;
}
