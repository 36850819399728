import AccountNavigator from 'components/account-navigator';
import { Flex, Grid } from 'components/layout';
import { Button, Modal, Image } from 'components/elements';
import theme from 'theme';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect, useState } from 'react';
import { EmergencyContact } from 'types/types';
import Form from 'components/form';
import { InputField } from 'components/form/input';
import { validateMobileNumber } from 'functions/form';
import SubmitButton from 'components/form/submit-button';
import { css } from '@emotion/react';
import { shortenWithEllipses } from 'functions/address-utils';

const EmergencyContactsPage = () => {
  const {
    loading: gettingEmergencyContacts,
    response: emergencyContacts,
    callApi: getEmergencyContacts,
  } = useSmartCityManager(SCManagerEndpoints.GetEmergencyContacts);
  const { loading: addEmergencyContactLoading, callApi: addEmergencyContact } =
    useSmartCityManager(SCManagerEndpoints.AddEmergencyContact);
  const {
    loading: deleteEmergencyContactLoading,
    callApi: deleteEmergencyContact,
  } = useSmartCityManager(SCManagerEndpoints.DeleteEmergencyContact);
  const [showAddEmergencyContactModal, setShowAddEmergencyContactModal] =
    useState(false);

  useEffect(() => {
    getEmergencyContacts();
  }, [deleteEmergencyContactLoading, addEmergencyContactLoading]);

  return (
    <AccountNavigator
      loading={
        gettingEmergencyContacts ||
        addEmergencyContactLoading ||
        deleteEmergencyContactLoading
      }
    >
      <Flex
        mobileCss={css`
          margin-top: 20px;
        `}
        width="100%"
        justifyContent="space-between"
        borderB={theme.borders.standard1px}
        pb="10px"
        alignItems="center"
        mb="10px"
      >
        <Modal
          isOpen={showAddEmergencyContactModal}
          onClose={() => setShowAddEmergencyContactModal(false)}
          title="Add Emergency Contact"
        >
          <Form
            onSubmit={(data) => {
              addEmergencyContact({
                queryParams: `contactNumber=${data.contactNumber}&contactName=${data.contactName}`,
              });
              setShowAddEmergencyContactModal(false);
            }}
          >
            <InputField
              name="contactName"
              label="Contact Name"
              required
              placeholder="Emergency contact name"
              mb="20px"
            />
            <InputField
              name="contactNumber"
              label="Contact Number"
              placeholder="Emergency contact number"
              required
              validate={(e) => {
                if (!validateMobileNumber(e.target.value)) {
                  return {
                    valid: false,
                    message: 'Please enter a valid mobile number',
                    field: 'contactNumber',
                  };
                }
                return {
                  valid: true,
                  message: '',
                  field: 'contactNumber',
                };
              }}
            />
            <SubmitButton label="Add Contact" />
          </Form>
        </Modal>
        <Text fontSize="17px">Emergency Contacts</Text>
        <Button
          onClick={() => setShowAddEmergencyContactModal(true)}
          p="10px"
          py="5px"
          borderRadius={theme.borderRadius.small}
          border={theme.borders.standard1px}
          color={theme.colors.primary}
        >
          Add new
        </Button>
      </Flex>
      {emergencyContacts?.data && emergencyContacts.data.length > 0 ? (
        <Flex flexDirection="column" width="100%" mt="10px">
          {emergencyContacts.data
            .filter((c: EmergencyContact) => !!c.contactName)
            .map((contact: EmergencyContact, idx: number) => (
              <Grid
                key={idx}
                width="100%"
                gridTemplateColumns="2fr 2fr 1fr"
                py="10px"
                alignItems="center"
              >
                <Text fontSize="16px" fontWeight="bold" hideForDesktop>
                  {shortenWithEllipses(contact.contactName, 15)}
                </Text>
                <Text
                  fontSize="16px"
                  fontWeight="bold"
                  hideForMobile
                  hideForTablet
                >
                  {shortenWithEllipses(contact.contactName, 35)}
                </Text>
                <Text
                  fontSize="16px"
                  color={theme.colors.lightGrey}
                  textAlign="center"
                >
                  {contact.contactNumber}
                </Text>
                <Button
                  onClick={() =>
                    deleteEmergencyContact({
                      queryParams: `contactName=${contact.contactName}&contactNumber=${contact.contactNumber}`,
                    })
                  }
                  p="10px"
                  ml="auto"
                  py="5px"
                  color={theme.colors.primary}
                >
                  <Image
                    source="profile/binIcon.svg"
                    alt="delete"
                    height="20px"
                    mt="5px"
                  />
                </Button>
              </Grid>
            ))}
        </Flex>
      ) : (
        <Text fontSize="16px" color={theme.colors.lightGrey} mt="10px">
          No emergency contacts added
        </Text>
      )}
    </AccountNavigator>
  );
};

export default EmergencyContactsPage;
