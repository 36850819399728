import { Cookies } from 'react-cookie';
import _ from 'lodash';
import { getCurrentSmartCityUser } from 'interface/app';
import { isAuthenticated } from 'interface/login';
import { guestUser } from 'functions/auth';
import { isAnonymousToken } from 'interface/login';

export const camelToSentenceCase = (camelCase) => {
  const result = camelCase.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const checkIfUserUpdate = async (user, setUser) => {
  const cookies = new Cookies();
  const firebaseUID = await cookies.get('currentUser');
  try {
    const userToSet = await getCurrentSmartCityUser(firebaseUID);
    userToSet.userOptions = JSON.parse(userToSet.userOptions);
    userToSet.userOptions.theme = 'light';
    if (!_.isEqual(user, userToSet)) setUser(userToSet);
  } catch {
    const userToSet = { userOptions: { theme: 'light' } };
    if (!_.isEqual(user, userToSet)) setUser(userToSet);
  }
};

export const authenticate = async () => {
  const cookies = new Cookies();
  const firebaseToken = await cookies.get('firebaseToken');
  const firebaseUID = await cookies.get('currentUser');
  let authenticatedState = false;
  let currentUser = {};
  if (firebaseToken && firebaseToken.length > 10) {
    const isAnonymous = await isAnonymousToken();
    if (isAnonymous) {
      currentUser = guestUser;
    } else {
      authenticatedState = await isAuthenticated(firebaseToken);
      if (authenticatedState) {
        // Get the user model
        currentUser = await getCurrentSmartCityUser(firebaseUID);
      } else {
        // Clear cookies
        cookies.remove('currentUser');
        cookies.remove('firebaseToken');
      }
    }
  } else {
    // Clear cookies
    cookies.remove('currentUser');
    cookies.remove('firebaseToken');
  }
  console.log('authenticate', authenticatedState, firebaseUID, currentUser);
  return {
    authenticatedState,
    firebaseUID,
    currentUser,
  };
};

export const objectArrayComparer = (otherArray) => (current) => (
  otherArray.filter((other) => other.contactName === current.contactName
  && other.contactNumber === current.contactNumber).length === 0
);

export const isTouchDevice = () => (('ontouchstart' in window)
     || (navigator.maxTouchPoints > 0)
     || (navigator.msMaxTouchPoints > 0));

export const numberFormatter = (num, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((i) => num >= i.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const getEnvironment = () => {
  const url = process.env?.REACT_APP_API_BASE;
  const env = url ? url.split('.')[0] : 'dev';
  if (env === 'mysmart') return 'prod';
  return env;
};

/**
 * Determine if on a mobile device
 *
 * @returns {Boolean}
 */
export function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (/android/i.test(userAgent) || (/android|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream));
}
