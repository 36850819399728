import { Box, Flex, Grid } from 'components/layout';
import theme from 'theme';
import { useEffect, useState } from 'react';
import { Button, Image } from 'components/elements';

import { Text } from 'components/typography';
import { Calendar, CalendarMultiSelect } from 'components/calendar';
import { cloneDeep } from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { css } from '@emotion/react';
import { dayLookup } from 'functions/date';
import {
  firstDateToSchedule,
  TWENTY_FOUR_HOURS,
  LATEST_WORKING_HOUR,
  LATEST_BOOKING_HOUR,
  THREE_MONTHS,
} from './utils';

export type SchedulingProps = {
  includeLunch: boolean;
  duration: number;
  confirmationCallback: () => void;
  setAppointmentStart: (date: Date) => void;
  currentDate?: Date;
  reschedule?: boolean;
};

const HourButton = ({
  hour,
  selected,
  onClick,
}: {
  hour: number;
  selected: boolean;
  onClick: () => void;
}) => (
  <Button
    p="7px"
    onClick={onClick}
    border={`1px solid ${
      selected ? theme.colors.primary : theme.colors.borderGrey
    }`}
    backgroundColor={selected ? theme.colors.primary : 'white'}
    borderRadius={theme.borderRadius.small}
    color={selected ? 'white' : 'black'}
  >
    <Text fontSize="14px" fontWeight="medium">{`${
      hour < 10 ? '0' : ''
    }${hour}:00`}</Text>
  </Button>
);

const DayButton = ({
  day,
  selected,
  onClick,
}: {
  day: string;
  selected: boolean;
  onClick: () => void;
}) => (
  <Button
    p="7px"
    px="12px"
    mr="7px"
    onClick={onClick}
    border={`1px solid ${
      selected ? theme.colors.primary : theme.colors.borderGrey
    }`}
    backgroundColor={selected ? theme.colors.primary : 'white'}
    borderRadius={theme.borderRadius.small}
    color={selected ? 'white' : 'black'}
  >
    <Text fontSize="14px" fontWeight="medium">
      {day.slice(0, 1)}
    </Text>
  </Button>
);

const currentDateToDay = (date?: Date, reschedule?: boolean) => {
  if (!date) return firstDateToSchedule(reschedule);
  const temp = cloneDeep(date);
  temp.setHours(0, 0, 0, 0);
  return temp;
};

const SchedulingAssistant = ({
  includeLunch,
  duration,
  confirmationCallback,
  setAppointmentStart,
  currentDate,
  reschedule,
}: SchedulingProps) => {
  const [day, setDay] = useState<Date>(
    currentDateToDay(currentDate, reschedule)
  );

  const [hour, setHour] = useState(
    reschedule
      ? 8
      : currentDate && currentDate.getHours() > 0
      ? currentDate.getHours()
      : new Date().getHours() + 1 <= LATEST_BOOKING_HOUR
      ? new Date().getHours() + 1
      : 8
  );
  const [selectableHours, setSelectableHours] = useState<number[]>([]);
  const [multiSelectedDates, setMultiSelectedDates] = useState<Date[]>([]);
  const [recurrenceWeekCount, setRecurrenceWeekCount] = useState(1);
  // Will need this for recurring services in future
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [showRecurring, setShowRecurring] = useState(false);
  const [usingCustomDates, setUsingCustomDates] = useState(false);

  const tomorrowThisTime = new Date(new Date().getTime() + TWENTY_FOUR_HOURS);
  const isBookingOnNextDay = day.getTime() > tomorrowThisTime.getTime();

  const hoursLeft = isBookingOnNextDay
    ? 10
    : LATEST_WORKING_HOUR - (tomorrowThisTime.getHours() + 1);

  useEffect(() => {
    const temp = cloneDeep(day);
    temp.setHours(hour, 0, 0, 0);
    setAppointmentStart(temp);
  }, [hour, day]);

  console.log(hour);

  useEffect(() => {
    let hours = [];
    const startingHour =
      isBookingOnNextDay || reschedule || showRecurring
        ? 8
        : tomorrowThisTime.getHours() + 1;
    const durationTrim = duration - 3 + (includeLunch ? 1 : 0);

    for (let i = 0; i < hoursLeft; i++) {
      if (i + startingHour <= LATEST_BOOKING_HOUR) hours.push(i + startingHour);
    }
    hours = hours.slice(0, hours.length - durationTrim);
    setSelectableHours(hours);
  }, [duration, day, showRecurring]);

  return (
    <Flex
      overflowY="scroll"
      flexDirection="column"
      maxHeight="580px"
      hideScrollBar
      px="10px"
      maxWidth="450px"
      mobileCss={css`
        height: 80vh;
        padding-bottom: 50px;
      `}
    >
      <Flex justifyContent="center" flexDirection="column">
        <Text fontSize="18px" fontWeight="medium" my="20px">
          Choose a date
        </Text>
        {!showRecurring ? (
          <Calendar
            date={day}
            setDate={(date) => setDay(date)}
            maxDate={new Date(Date.now() + THREE_MONTHS)} // Three months (more or less) from now
            minDate={firstDateToSchedule(reschedule)}
          />
        ) : (
          <CalendarMultiSelect
            date={multiSelectedDates}
            setDate={(dates) => {
              setMultiSelectedDates(dates);
              setUsingCustomDates(true);
            }}
            maxDate={new Date(Date.now() + THREE_MONTHS)} // Three months (more or less) from now
            minDate={
              new Date(
                firstDateToSchedule(reschedule).getTime() + 24 * 3600 * 1000
              )
            }
          />
        )}
      </Flex>
      <Box borderT={theme.borders.standard1px} mt="10px" py="10px">
        <Text fontSize="18px" fontWeight="medium" my="20px">
          Choose Time
        </Text>
        <Grid
          gridTemplateColumns="repeat(4, 1fr)"
          gridColumnGap="10px"
          gridRowGap="10px"
        >
          {selectableHours.map((h: number, idx: number) => (
            <HourButton
              key={idx}
              hour={h}
              selected={hour === h}
              onClick={() => setHour(h)}
            />
          ))}
        </Grid>
      </Box>
      {/* For recurring services */}
      {/* <Text fontSize="18px" fontWeight="medium" my="10px">
          Make this booking recurring?
        </Text>
        <Flex>
          <Button
            onClick={() => {
              setShowRecurring(false);
              setUsingCustomDates(false);
              setMultiSelectedDates([]);
            }}
            p="7px"
            width="100%"
            mr="2px"
            border={`1px solid ${
              !showRecurring ? theme.colors.primary : theme.colors.borderGrey
            }`}
            backgroundColor={
              !showRecurring ? 'rgba(223, 117, 49, 0.26)' : 'white'
            }
            borderRadius={theme.borderRadius.small}
          >
            No
          </Button>
          <Button
            onClick={() => setShowRecurring(true)}
            p="7px"
            ml="2px"
            width="100%"
            border={`1px solid ${
              showRecurring ? theme.colors.primary : theme.colors.borderGrey
            }`}
            backgroundColor={
              showRecurring ? 'rgba(223, 117, 49, 0.26)' : 'white'
            }
            borderRadius={theme.borderRadius.small}
          >
            Yes
          </Button>
        </Flex> */}
      {showRecurring && (
        <>
          <Flex
            alignContent="center"
            alignItems="center"
            mt="10px"
            backgroundColor={theme.colors.backgroundGrey}
            p="10px"
            borderRadius={theme.borderRadius.small}
          >
            <Image source="icons/info.svg" alt="info" mr="10px" />
            <Text>
              Use the scheduler below or click on specific dates on the calendar
              above to schedule your recurring booking.
            </Text>
          </Flex>
          {usingCustomDates ? (
            <>
              <Text mt="10px">
                You have selected custom dates in the calendar above.
              </Text>
              <Button
                onClick={() => {
                  setUsingCustomDates(false);
                  setMultiSelectedDates([firstDateToSchedule(reschedule)]);
                }}
                color={theme.colors.primary}
                border={`1px solid ${theme.colors.primary}`}
                borderRadius={theme.borderRadius.small}
                p="10px"
                mt="10px"
              >
                I&apos;d rather use the scheduler
              </Button>
            </>
          ) : (
            <>
              <Grid
                gridTemplateColumns="1fr 1fr"
                my="10px"
                overrideCss={css`
                  input {
                    border: ${theme.borders.standard1px};
                    border-radius: ${theme.borderRadius.small};
                    padding: 2px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                  }
                `}
              >
                <Box>
                  <Text fontSize="15px" my="10px">
                    Start date
                  </Text>
                  <DatePicker
                    value={day.toLocaleDateString()}
                    onChange={(date) => date && setDay(date)}
                    maxDate={new Date(Date.now() + THREE_MONTHS)} // Three months (more or less) from now
                    minDate={firstDateToSchedule(reschedule)}
                  />
                </Box>
                <Box>
                  <Text fontSize="15px" my="10px">
                    End date
                  </Text>
                  <DatePicker
                    value={new Date(
                      Date.now() + THREE_MONTHS
                    ).toLocaleDateString()}
                    onChange={() => null}
                    maxDate={new Date(Date.now() + THREE_MONTHS)} // Three months (more or less) from now
                    minDate={firstDateToSchedule(reschedule)}
                  />
                </Box>
              </Grid>
              <Text fontSize="15px">Occurs every</Text>
              <Grid
                gridTemplateColumns="1fr 5fr"
                my="10px"
                gridColumnGap="15px"
                alignItems="center"
              >
                <Grid gridTemplateColumns="1fr 1fr 1fr">
                  <Button
                    disabled={recurrenceWeekCount <= 1}
                    onClick={() =>
                      setRecurrenceWeekCount(recurrenceWeekCount - 1)
                    }
                    m={0}
                    p="6px"
                    px="10px"
                    border={`1px solid ${theme.colors.borderGrey}`}
                    borderRadius={theme.borderRadius.small}
                    fontSize="18px"
                    color={
                      recurrenceWeekCount <= 1
                        ? theme.colors.borderGrey
                        : 'black'
                    }
                  >
                    -
                  </Button>
                  <Text
                    m={0}
                    textAlign="center"
                    my="auto"
                    color={theme.colors.primary}
                    fontWeight="500"
                    px="10px"
                  >
                    {recurrenceWeekCount.toString()}
                  </Text>
                  <Button
                    disabled={recurrenceWeekCount >= 4}
                    onClick={() =>
                      setRecurrenceWeekCount(recurrenceWeekCount + 1)
                    }
                    m={0}
                    p="8px"
                    px="10px"
                    border={`1px solid ${theme.colors.borderGrey}`}
                    borderRadius={theme.borderRadius.small}
                    color={
                      recurrenceWeekCount >= 4
                        ? theme.colors.borderGrey
                        : 'black'
                    }
                  >
                    +
                  </Button>
                </Grid>
                <Text fontSize="17px">weeks</Text>
              </Grid>
              <Text fontSize="15px" mb="10px">
                On these days:
              </Text>
              <Flex
                justifyContent="flex-start"
                overrideCss={css`
                  @media ${theme.mediaQueries.mobileOnly} {
                    justify-content: center;
                  }
                `}
              >
                {dayLookup.map((d, idx) => (
                  <DayButton
                    day={d}
                    key={idx}
                    onClick={() => null}
                    selected={false}
                  />
                ))}
              </Flex>
            </>
          )}
        </>
      )}

      <Button
        onClick={confirmationCallback}
        disabled={hour === 0}
        backgroundColor={theme.colors.primary}
        color="white"
        borderRadius={theme.borderRadius.small}
        width="100%"
        p="15px"
        mt="20px"
        mobileCss={
          !reschedule
            ? css`
                position: absolute;
                bottom: 10px;
                left: auto;
                width: 80%;
                right: auto;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
              `
            : undefined
        }
      >
        Confirm
      </Button>
      {reschedule && <Box minHeight="60px" hideForDesktop hideForTablet />}
    </Flex>
  );
};

export default SchedulingAssistant;
