import AccountNavigator from 'components/account-navigator';
import { Flex, Grid } from 'components/layout';
import { Button, Modal } from 'components/elements';
import theme from 'theme';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomerPremises, LabelValuePair, UtilityAccount } from 'types/types';
import Form from 'components/form';
import { InputField } from 'components/form/input';
import SubmitButton from 'components/form/submit-button';
import { css } from '@emotion/react';
import Dropdown from 'components/dropdown';
import { useNavigate } from 'react-router-dom';
import InfoBox from 'components/info-box';
import { SmallLoader } from 'components/private-services/survey';
import useDidMountEffect from 'hooks/use-did-mount-effect';
import { useGlobalContext } from 'contexts/global';
import UtilityAccountItem from 'components/my-municipality/utility-account-item';
import { ModalProps } from 'components/elements/modal';

const ErrorModal = (props: ModalProps) => {
  const { onClose } = props;
  return (
    <Modal {...props}>
      <Flex
        flexDirection="column"
        maxWidth="540px"
        height="100%"
        justifyContent="center"
      >
        <Text fontSize="16px">
          It seems that something went wrong when trying to add your account.
          Please ensure that your account number, account type and ID number
          have been entered correctly.
        </Text>
        <Button variant="cta" onClick={onClose} mt="40px">
          Close
        </Button>
      </Flex>
    </Modal>
  );
};

const UtilityAccountsPage = () => {
  const navigate = useNavigate();
  const {
    globalState: { city },
  } = useGlobalContext();

  // API Calls
  const {
    loading: loadingPremises,
    callApi: getPremises,
    response: customerPremises,
  } = useSmartCityManager(SCManagerEndpoints.GetCustomerPremises);
  const {
    loading: loadingTopLevelOrgUnits,
    callApi: getTopLevelOrgUnits,
    response: topLevelOrgUnits,
  } = useSmartCityManager(SCManagerEndpoints.GetTopLevelOrgUnits);
  const {
    loading: loadingChildOrgUnits,
    callApi: getChildOrgUnits,
    response: childOrgUnits,
  } = useSmartCityManager(SCManagerEndpoints.GetChildOrgUnits);
  const {
    loading: loadingAddAccount,
    callApi: addAccount,
    response: addAccountResponse,
    error: addAccountError,
  } = useSmartCityManager(SCManagerEndpoints.AddAccountToPremise);
  const { callApi: removeAccount, response: removeAccountResponse } =
    useSmartCityManager(SCManagerEndpoints.RemoveAccountFromPremise);

  // State management for dropdowns and submit button validation
  const [selectedPremiseId, setSelectedPremiseId] = useState<LabelValuePair>({
    label: 'Select a favourite location',
    value: -1,
  });
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [showAddUtilityAccountModal, setShowAddUtilityAccountModal] =
    useState(false);
  const [selectedTopAccountType, setSelectedTopAccountType] =
    useState<LabelValuePair>({
      label: 'Select an Account Type',
      value: -1,
    });
  const [selectedChildAccountType, setSelectedChildAccountType] =
    useState<LabelValuePair>({
      label: 'Please select...',
      value: -1,
    });

  // Logic to determine which orgUnitId to use
  const selectedOrgUnit =
    selectedTopAccountType?.attributes?.hasChildren === 'false' ||
    selectedTopAccountType?.attributes?.utilityAccountOnly === 'true'
      ? selectedTopAccountType
      : selectedChildAccountType;

  const topAccountTypes = useMemo(
    () =>
      topLevelOrgUnits &&
      (topLevelOrgUnits.data.find(
        (orgUnit: LabelValuePair) =>
          orgUnit?.attributes?.utilityAccountOnly === 'true'
      ) ??
        topLevelOrgUnits.data.filter(
          (orgUnit: LabelValuePair) =>
            orgUnit.attributes?.requiresAccountNumber === 'true' ||
            orgUnit.attributes?.hasChildren === 'true'
        )),
    [topLevelOrgUnits]
  );

  const premisesWithMunicipalAccounts = customerPremises?.data?.filter(
    (p: CustomerPremises) => p?.serviceProviderAccounts?.length
  );

  // Side Effects
  useEffect(() => {
    if (!!topAccountTypes && typeof topAccountTypes === 'object') {
      setSelectedTopAccountType(topAccountTypes);
    }
  }, [topAccountTypes]);

  useEffect(() => {
    getPremises();
    setSelectedPremiseId({
      label: 'Select a favourite location',
      value: -1,
    });
    if (showAddUtilityAccountModal) {
      getTopLevelOrgUnits({
        queryParams: 'orgUnitTypeCode=MS',
      });
    }
  }, [
    showAddUtilityAccountModal,
    city.value,
    addAccountResponse,
    removeAccountResponse,
  ]);

  useEffect(() => {
    if (!loadingAddAccount && !!addAccountError) {
      setShowErrorModal(true);
    }
  }, [loadingAddAccount, addAccountError]);

  useEffect(() => {
    if (
      selectedTopAccountType &&
      selectedTopAccountType.value !== -1 &&
      showAddUtilityAccountModal
    ) {
      getChildOrgUnits({
        queryParams: `parentOrgUnitId=${selectedTopAccountType.value}`,
      });
    }

    setSelectedChildAccountType({
      label: 'Please select...',
      value: -1,
      attributes: undefined,
    });
  }, [selectedTopAccountType]);

  // Handlers
  const handleSubmit = useCallback(
    (data: { accountNumber: string; externalId: string }) => {
      addAccount({
        body: {
          accountNumber: data.accountNumber,
          orgUnitId: selectedOrgUnit.value,
          premiseId: selectedPremiseId.value,
          orgUnitDescription: selectedOrgUnit.label,
          ...(city.value === 663 && {
            externalId: data.externalId,
          }),
        },
      });
      setShowAddUtilityAccountModal(false);
      setSelectedChildAccountType({
        label: 'Please select...',
        value: -1,
        attributes: undefined,
      });
      setSelectedTopAccountType({
        label: 'Select an Account Type',
        value: -1,
      });
    },
    [selectedOrgUnit, selectedPremiseId]
  );

  const handleRemoveAccount = useCallback((account: UtilityAccount) => {
    removeAccount({
      body: {
        accountNumber: account.accountNumber,
        orgUnitId: account.orgUnitId,
        premiseId: account.premiseId,
        orgUnitDescription: account.orgUnitDescription,
      },
    });
  }, []);

  console.log(selectedOrgUnit);

  // JSX
  return (
    <AccountNavigator
      loading={loadingPremises || loadingTopLevelOrgUnits || loadingAddAccount}
    >
      <Flex
        mobileCss={css`
          margin-top: 20px;
        `}
        width="100%"
        justifyContent="space-between"
        borderB={theme.borders.standard1px}
        pb="10px"
        alignItems="center"
        mb="10px"
      >
        <ErrorModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          title="Something went wrong"
        />
        {customerPremises && (
          <Modal
            isOpen={showAddUtilityAccountModal}
            onClose={() => setShowAddUtilityAccountModal(false)}
            title="Add Utility Account"
            slideFromBottomMobile
          >
            <Flex
              height="100%"
              maxWidth="540px"
              overflowY="scroll"
              hideScrollBar
              mobileCss={css`
                max-height: 550px;
              `}
            >
              <Form onSubmit={handleSubmit}>
                <Grid width="100%" gridRowGap="20px" mt="10px">
                  {customerPremises?.data?.length > 0 && (
                    <Dropdown
                      label="Select a favourite location"
                      value={selectedPremiseId}
                      options={customerPremises.data.map(
                        (c: CustomerPremises) => ({
                          label: c.description,
                          value: c.id,
                        })
                      )}
                      onChange={(premise) => setSelectedPremiseId(premise)}
                    />
                  )}
                  <Text mt="-5px">
                    Select one of your saved addresses to save an account to, or
                    add a new favourite location
                  </Text>
                  <Button
                    fontSize="16px"
                    fontWeight={600}
                    onClick={() => navigate('/account/favourite-locations')}
                    width="fit-content"
                    color={theme.colors.primary}
                  >
                    Add favourite location
                  </Button>
                  <InfoBox>
                    After confirming your account number, you will be able to
                    enter a meter number, allowing you to utilize the Self-meter
                    readings tab.
                  </InfoBox>
                  <InputField
                    label="Verification Type"
                    alwaysShowLabel
                    name="externalId"
                    required
                    placeholder="ID Number/Business Registration Number"
                    instructions="This field will verify your account details according to your ID number or Business Registration Number"
                  />
                  {selectedTopAccountType?.attributes?.utilityAccountOnly !==
                    'true' &&
                    topAccountTypes?.length > 0 && (
                      <Dropdown
                        label="Select an Account Type"
                        value={selectedTopAccountType}
                        options={topAccountTypes}
                        onChange={(accType) =>
                          setSelectedTopAccountType(accType)
                        }
                      />
                    )}
                  {loadingChildOrgUnits &&
                  selectedTopAccountType?.attributes?.hasChildren === 'true' ? (
                    <SmallLoader />
                  ) : (
                    <>
                      {!!childOrgUnits?.data?.length && (
                        <Dropdown
                          value={selectedChildAccountType}
                          options={childOrgUnits.data}
                          onChange={(childAcc) =>
                            setSelectedChildAccountType(childAcc)
                          }
                        />
                      )}
                    </>
                  )}
                  <InputField
                    name="accountNumber"
                    label="Account Number"
                    placeholder="Account Number"
                    required
                  />
                  <SubmitButton
                    label="Add Account"
                    disabled={
                      selectedOrgUnit.value === -1 ||
                      selectedPremiseId.value === -1
                    }
                  />
                </Grid>
              </Form>
            </Flex>
          </Modal>
        )}
        <Text fontSize="17px">Utility Accounts</Text>
        <Button
          onClick={() => setShowAddUtilityAccountModal(true)}
          p="10px"
          py="5px"
          borderRadius={theme.borderRadius.small}
          border={theme.borders.standard1px}
          color={theme.colors.primary}
        >
          Add new
        </Button>
      </Flex>
      {premisesWithMunicipalAccounts ? (
        <>
          {premisesWithMunicipalAccounts.map((premise: CustomerPremises) => (
            <>
              <Text fontWeight={600} mb="10px">
                {premise.description}
              </Text>
              {premise.serviceProviderAccounts &&
                premise.serviceProviderAccounts.map(
                  (account: UtilityAccount) => (
                    <UtilityAccountItem
                      account={account}
                      handleRemoveAccount={handleRemoveAccount}
                    />
                  )
                )}
            </>
          ))}
        </>
      ) : (
        <Text fontSize="16px" color={theme.colors.lightGrey} mt="10px">
          {/* TODO: This is only text for now, should be updated to match the designs, and include contact details */}
          No utility accounts added
        </Text>
      )}
    </AccountNavigator>
  );
};

export default UtilityAccountsPage;
