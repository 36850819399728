import React from 'react';

import { Modal } from 'components/social';
import { Loader } from 'components/inthecity';
import { ScrollContainer } from 'components/inthecity/lottoModal/lottoModal.styled';
import { SelectButton } from 'components/social/createpost/createpost.styled';

const ProductListModal = (props) => {
  // props
  const {
    show,
    handleClose,
    products,
    category,
    provider,
    productList,
    handleSelectProduct,
    handleBack,
  } = props;

  return (
    <Modal
      title={`${provider} ${category} Bundles`}
      show={show}
      handleClose={handleClose}
      backButton
      handleBack={handleBack}
    >
      <ScrollContainer>
        {!products && <Loader />}
        {(productList && productList.length > 0) && productList.map((product, i) => (
          <SelectButton
            key={i}
            onClick={() => handleSelectProduct(product)}
          >
            <p>{product.name}</p>
          </SelectButton>
        ))}
      </ScrollContainer>
    </Modal>
  );
};

export default ProductListModal;
