/**
 * Creates a user object to send to the MSC REST service
 * @param {string} fullName User's Full name
 * @param {string} mobileNumber User's mobile number
 * @param {string} password Password is not used anymore
 * @param {string} username Email of user
 * @param {long} currentCitySubscriberId ID of subscribed city
 * @param {string} firebaseUid UID from firebaseAuth
 *
 * @returns {JSON} MSC REST user object
 */
export const userMapper = (
  fullName,
  mobileNumber,
  password,
  username,
  currentCitySubscriberId,
  firebaseUid,
  accepttc,
  acceptmarketing,
  email
) => ({
  fullName,
  mobileNumber,
  password,
  username,
  currentCitySubscriberId,
  firebaseUid,
  accepttc,
  acceptmarketing,
  email,
});

/**
 * validateUserModel checks if the model as the required fields
 * @param {JSON} userModel MSC REST object returned from forcelink
 * @returns {boolean} True if it is compatible
 */
export const validateUserModel = (userModel) => {
  const required = [
    'fullName',
    'email',
    'currentCitySubscriberId',
    'firebaseUid',
  ];
  return !required.some((key) => !(key in userModel));
};
