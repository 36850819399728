import styled from 'styled-components';

import { Body } from 'components';

export const LocationButton = styled.div`
  cursor: pointer;
  align-self: center;
  display: flex;
  align-items: center;
  padding: 0 10px;

  position: absolute;
  left: 40%;

  @media only screen and (min-width: 800px) {
    left: 46%;
  }
`;

export const Label = styled(Body)`
  font: normal normal 500 12px/16px Roboto;
  color: #FFFFFF;
  text-align: left;
  padding: 0;
  margin: 0;
/* 
  @media only screen and (min-width: 800px) {
    font: normal normal 500 18px/24px Roboto;
  } */
`;

export const DropdownIcon = styled.img`
  margin-left: 12px;
  width: 10px;
  transition: transform 0.15s ease-in-out;
  transform: rotate( ${({ open }) => (open ? '180deg' : '0')} );
  filter: invert(100%);

  @media only screen and (min-width: 800px) {    
    margin-left: 24px;
    width: 14px;
  }
`;
