import { css } from '@emotion/react';
import { Image, Link } from 'components/elements';
import { Flex, Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import ServiceListingRow from 'components/my-municipality/service-listing';
import {
  MunicipalServices,
  groupMunicipalServicesByCategory,
} from 'constants/msc-pro-municipalities';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  setCurrentBusinesses,
  setSearchTerm,
  setSelectedCategoryId,
} from 'redux/gm-business-directory';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import theme from 'theme';

const ServicesPage = () => {
  const { cityId } = useParams();
  const dispatch = useAppDispatch();
  const {
    gmBusinesses: { selectedCategoryId },
  } = useAppSelector((state) => state);

  // Prefetch and store popular businesses in the redux store, also reset the search term and selected category
  useEffect(() => {
    dispatch(setSearchTerm(''));
    if (selectedCategoryId !== 0) {
      dispatch(setSelectedCategoryId(0));
      dispatch(setCurrentBusinesses([]));
      fetch(
        'https://visitgeorge.co.za/wp-json/directorist/v1/listings?per_page=12&orderby=popular&_fields=id,name,address,phone,images,categories,email'
      )
        .then((result) => result.json())
        .then((data) => {
          dispatch(setCurrentBusinesses(data));
        });
    }
  }, []);

  const groupedServices = groupMunicipalServicesByCategory(MunicipalServices);

  return (
    <MunicipalPageWrapper>
      <Grid mt="10px" gridColumnGap="10px" gridRowGap="10px">
        {cityId &&
          Object.entries(groupedServices).map(([category, services]) => (
            <ServiceListingRow
              services={services}
              category={category}
              cityId={cityId}
            />
          ))}
        <Link href="/map">
          <Flex
            height="100%"
            width="100%"
            borderRadius="4px"
            justifyContent="center"
            alignItems="center"
            backgroundColor={theme.colors.primary}
            color="white"
            py="16px"
            fontSize="16px"
            fontWeight="bold"
            mobileCss={css`
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
            hoverCss={css`
              color: white;
              scale: 1.01;
            `}
          >
            <Image
              source="my-municipality/report-an-issue.svg"
              alt="icon"
              mr="10px"
              width="35px"
              height="35px"
            />
            Report an issue
          </Flex>
        </Link>
      </Grid>
    </MunicipalPageWrapper>
  );
};

export default ServicesPage;
