import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';

import { Body } from 'components';

export const Container = styled.div`
  display: none;

  @media only screen and (min-width: 1000px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 5px;
    width: 100%;
    background: white;
    padding: 30px;
    background: rgba(222, 226, 230, 0.3);
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 5px;
  width: 100%;
  background: white;
  margin-bottom: 12px;
  @media only screen and (min-width: 1000px) {
    margin-bottom: 20px;
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  @media only screen and (min-width: 1000px) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  display: block;  
  font: normal normal 500 16px/21px Roboto;
  color: #0A0918;
  padding: 0;
  margin-top: 40px;
  // text overflow
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  
  @media only screen and (min-width: 800px) {
    margin-top: 10px;
  }
  
`;

export const Text = styled(Body)`
  color: ${(props) => props.theme.colors.dark};

  // text overflow
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const OrangeText = styled(Body)`
  color: ${(props) => props.theme.colors.primary};

  // text overflow
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const GreenText = styled(Body)`
  color: ${(props) => props.theme.colors.green};

  // text overflow
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.tertiary};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Button = styled.a`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  font: normal normal normal 14px/19px Roboto;
  line-height: 45px;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover { color: white; }
`;

export const LocationIcon = styled.div`
  background: url(inthecity/pin-icon.svg) no-repeat padding-box;
  background-size: 17px;
  height: 20px;
  width: 20px;
  margin-right: 16px;
`;

export const DateIcon = styled.div`
  background: url(inthecity/calender-icon.svg) no-repeat padding-box;
  background-size: 20px;
  height: 20px;
  width: 20px;
  margin-right: 16px;
`;

export const PriceIcon = styled.div`
  background: url(inthecity/price.svg) no-repeat padding-box;
  background-size: 20px;
  height: 20px;
  width: 20px;
  margin-right: 16px;
`;

export const Divider = styled(Text)`
  margin: 0 10px;
`;

export const StyledMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 30px;
  height: 165px;
  @media only screen and (min-width: 800px) {
    border-radius: 5px;
    height: 250px;
  }

  @media only screen and (min-width: 1000px) {
    border-radius: 5px;
    height: 400px;
  }
`;

export const StarIcon = styled.img`
  height: 12px;
  margin-right: 10px;
  @media only screen and (min-width: 1000px) {
    height: 16px;
    margin-right: 12px;
  }
`;
