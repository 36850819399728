import { css } from '@emotion/react';
import { Button, TextField } from 'components/elements';
import { Flex } from 'components/layout';
import StarRating from 'components/star-rating';
import { Text } from 'components/typography';
import { useState } from 'react';
import theme from 'theme';
import { CustomerBooking } from 'types/types';
import CommentButton from '../rating-elements/comment-button';

const CommentsArray = [
  'Good Communication',
  'Friendly',
  'Quick & Efficient',
  'Professional',
];

const buildResourceComment = (fullComment: string[], customComment: string) => {
  const comment = fullComment.join('. ');
  if (customComment) {
    return `${comment}. ${customComment}`;
  }
  return comment;
};

const RatingComment = ({
  booking,
  starRating,
  setReviewComment,
  onNext,
}: {
  booking: CustomerBooking;
  starRating: number;
  setReviewComment: (comment: string) => void;
  onNext: () => void;
}) => {
  const [comment, setComment] = useState('');
  const [fullComment, setFullComment] = useState<string[]>([]);
  const [buildingComment, setBuildingComment] = useState(false);
  return (
    <Flex
      flexDirection="column"
      mx="auto"
      textAlign="center"
      justifyContent="center"
    >
      <Text textAlign="center" width="100%" fontSize="15px" color="#B2BCC1">
        Let us know about your experience working with {booking.resourceName}
      </Text>

      <Flex justifyContent="center" py="30px">
        <StarRating size="40px" hideNumbers rating={{ starRating }} />
      </Flex>
      <Flex
        my="40px"
        flexWrap="wrap"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 10px;
            margin: 10px 0 20px 0;
          }
        `}
      >
        {CommentsArray.map((c, index) => (
          <CommentButton
            key={index}
            text={c}
            active={fullComment.includes(c)}
            onClick={() =>
              fullComment.includes(c)
                ? setFullComment(fullComment.filter((x) => x !== c))
                : setFullComment([...fullComment, c])
            }
          />
        ))}
      </Flex>
      <Flex>
        <TextField
          multiline
          height="100px"
          width="100%"
          placeholder="Add Review"
          onChange={(event) => setComment(event.target.value)}
          value={comment}
        />
      </Flex>

      <Flex my="20px" justifyContent="center">
        <Button
          borderRadius="5px"
          height="67px"
          width="100%"
          backgroundColor={theme.colors.primary}
          onClick={() => {
            setBuildingComment(true);
            setReviewComment(buildResourceComment(fullComment, comment));
            setTimeout(() => {
              onNext();
              setBuildingComment(false);
            }, 1000);
          }}
        >
          <Text fontSize="20px" color="white">
            {buildingComment ? 'Submitting...' : 'Done'}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};
export default RatingComment;
