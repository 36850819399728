/* eslint-disable max-len */
const url = 'https://api.quicket.co.za/api/';

/**
 * Quicket APIs
 */

/**
 * Search Quicket events
 * Populate search and suggested searches comes from titles of 'featured'
 * @param {string} query search query
 * @returns {Promise}
 */
export const searchEvents = async (query) => fetch(
  'https://qauipdmsel-dsn.algolia.net/1/indexes/*/queries?x-algolia-application-id=QAUIPDMSEL&x-algolia-api-key=be52999d67a149e55cf02ee1b60fb356',
  {
    method: 'POST',
    async: true,
    body: JSON.stringify({
      requests: [
        {
          indexName: 'products',
          params: `query=${query}`,
        },
      ],
    }),
  },
)
  .then((res) => res.json())
  .then((res) => res.results[0].hits)
  .catch((e) => console.log(e));

/**
 * Get some events
 * @param {number} id
 * @returns {Promise}
 */
export const getEvent = async (id) => fetch(
  `${url}events`
  + `?id=${id}`
  + '&api_key=634df098350a56b69e6be8edf203457a',
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.json())
  .catch((e) => console.log(e));

/**
 * Get list of events
 * @param {number} categories a comma separated list of event category ID
 * @param {number} pageSize the amount of events in a list
 * @returns {Promise}
 */
export const getEvents = async (categories, pageSize = 100) => fetch(
  `${url}events`
  + `?pageSize=${pageSize}`
  + `${categories ? `&categories=${categories}` : ''}`
  + '&api_key=634df098350a56b69e6be8edf203457a',
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.json())
  .then((res) => res.results)
  .catch((e) => console.log(e));
