import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BeachImage = styled.img`
  z-index: 1;
  position: absolute;
  display: none;
  @media only screen and (min-width: 977px) {
    display: block;
    width: 40%;
    top: -35%;
    right: -2%;
  }
`;

export const PhoneImage = styled.img`
  position: absolute;
  width: 32%;
  bottom: 17%;
  right: -9%;
  @media only screen and (max-width: 977px) {
    display: none;
  }
`;

export const FirstStepImage = styled.img`
  margin-left: -3vw;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 18vw;
  @media only screen and (max-width: 992px) {
    margin: 0 auto;
    height: auto;
    width: 50%;
    margin: 1rem 4rem;
  }
`;

export const StepImage = styled.img`
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 18vw;
  @media only screen and (max-width: 992px) {
    margin: 0 auto;
    height: auto;
    width: 50%;
    margin: 1rem 4rem;
  }
`;

// Hero
export const Background = styled.div`
  color: white;
  background-image: url(/${({ image }) => image});
  background-size: cover;
  background-color: #333;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 30vh;
  padding: 1rem;

  @media only screen and (min-width: 650px) {
    min-height: 60vh;
  }

  @media only screen and (min-width: 900px) {
    min-height: calc(100vh - 10rem);
    padding: 2rem;
  }

  @media only screen and (min-width: 1000px) {
    min-height: calc(100vh - 12rem);
    padding: 4rem;

    @media only screen and (max-height: 750px) {
      padding: 2rem;
    }
  }

  @media only screen and (min-width: 1400px) {
    padding: 5rem;
    min-height: calc(100vh - 15rem);     

    @media only screen and (max-height: 750px) {
      padding: 2rem;
    }
  }
`;
export const Container = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  text-align: left;
  height: 100%;
  width: 70%;
  @media only screen and (min-width: 700px) {
    width: 50%;
  }
  @media only screen and (min-width: 900px) {
    width: 50%;
  }
  @media only screen and (min-width: 1400px) {
    width: 50%;
  }
`;
export const Title = styled.h1`
  text-transform: uppercase;
  font-family: GothamMedium;
  transition: all 0.5s;

  margin: 0;
  font-weight: bolder;
  padding: 0;

  font-size: 1.75rem;
  line-height: 1.75rem;
  @media only screen and (min-width: 600px) {
    font-size: 4rem;
    line-height: 4rem;
  }
  @media only screen and (min-width: 900px) {
    font-size: 6rem;
    line-height: 6rem;

    @media only screen and (max-height: 750px) {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  @media only screen and (min-width: 1400px) {
    font-size: 9rem;
    line-height: 9rem;

    @media only screen and (max-height: 750px) {
      font-size: 6rem;
      line-height: 6rem;
    }
  }
`;
export const TitleHoliday = styled.h1`
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: white;
  display: contents;
  text-transform: uppercase;
  font-family: GothamMedium;
  transition: all 0.5s;
  margin: 0;
  font-weight: bolder;
  padding: 0;

  font-size: 1.75rem;
  line-height: 1.75rem;
  -webkit-text-stroke-width: 1px;
  @media only screen and (min-width: 600px) {
    font-size: 4rem;
    line-height: 4rem;
    -webkit-text-stroke-width: 2px;
  }
  @media only screen and (min-width: 900px) {
    font-size: 6rem;
    line-height: 6rem;
    -webkit-text-stroke-width: 3px;
    
    @media only screen and (max-height: 750px) {
      font-size: 4rem;
      line-height: 4rem;
      -webkit-text-stroke-width: 2px;
    }
  }
  @media only screen and (min-width: 1400px) {
    font-size: 9rem;
    line-height: 9rem;
    -webkit-text-stroke-width: 4px;

    @media only screen and (max-height: 750px) {
      font-size: 6rem;
      line-height: 6rem;
      -webkit-text-stroke-width: 3px;
    }
  }
`;
export const Subtitle = styled.p`
  width: 100%;
  font-family: Gotham;
  font-weight: 500;
  padding: 2rem 0;
  font-size: 1.25rem;
  line-height: 1.5rem;
  @media only screen and (min-width: 650px) {
    font-size: 2rem;
    line-height: 2rem;

    @media only screen and (max-height: 750px) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
  @media only screen and (min-width: 1400px) {
    font-size: 2rem;
    line-height: 2rem;

    @media only screen and (max-height: 750px) {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }
`;

export const CenterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 50px 0;
`;

export const OrangeLink = styled(Link)`
  text-decoration: underline;
  color: #DA7531;
  &:hover {
    color: #DA7531;
  }
`;

export const LogoTextRow = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 6rem;
`;

export const LogoRow = styled.div`
  display: flex;
  height: 2rem;
  justify-content: center;
  @media only screen and (min-width: 700px) {
    height: 4rem;
    margin-top: 2rem;
  }
`;

export const LogoImage = styled.img`
  height: 100%;
  // &:first-child {
  //   margin-right: 3rem;
  // }
`;

export const ButtonColumn = styled.div`
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 1000px) {
    width: 190px;
  }
`;

export const Button = styled.a`
  > img {
    width: 100%;
  }
  &:first-of-type {
    margin-bottom: 35px;
    @media only screen and (max-width: 1000px) {
      margin-bottom: 35px;
    }
  }
`;

export const QRCode = styled.div`
  display: none;
  @media only screen and (min-width: 800px) {
    display: block;
    width: 200px;
    height: 200px;
    margin-left: 70px;
  }
  @media only screen and (min-width: 1000px) {
    margin-left: 140px;  
  }
`;

export const QRCodeImage = styled.img`
  height: 100%;
`;

export const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  --border-style: 2px solid #DA7531;
  --border-space: 10px;
  padding: 10px;

  div {
    &:nth-child(1) {
      position: absolute;
      width: 50px;
      height: 50px;
      border-left: var(--border-style);
      border-top: var(--border-style);
      top: 0;
      left: 0;
    }
    &:nth-child(2) {
      position: absolute;
      width: 50px;
      height: 50px;
      border-right: var(--border-style);
      border-top: var(--border-style);
      top: 0;
      right: 0;
    }
    &:nth-child(3) {
      position: absolute;
      width: 50px;
      height: 50px;
      border-left: var(--border-style);
      border-bottom: var(--border-style);
      left: 0;
      bottom: 0;
    }
    &:nth-child(4) {
      position: absolute;
      width: 50px;
      height: 50px;
      border-right: var(--border-style);
      border-bottom: var(--border-style);
      bottom: 0;
      right: 0;
    }
  }
`;

export const QRText = styled.p`
  font: normal normal normal 25px/33px Roboto;
  color: #000000;
  position: absolute;
  margin-left: -25px;
  margin-top: 15px;
`;
