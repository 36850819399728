import styled from 'styled-components';

export const TabsContainer = styled.div`
  position: relative;
  margin: 0 0;
  margin-bottom: 12px;
  @media only screen and (min-width: 800px) {
    margin: 0 100px;
    margin-bottom: 24px;
  }
`;

export const Container = styled.div`
  padding: 0px 15px;
  width: 100%;
  display: flex;
  overflow-x: auto !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar { width: 0; height: 0; background: transparent; }
`;

export const Button = styled.div`
  cursor: pointer;
  padding: 0 10px;
  border-radius: 4px;
  background: ${(props) => (props.active ? props.theme.colors.primary : 'none')}; 
  font: normal normal normal 14px/19px ${(props) => (props.theme.font)};
  color: ${(props) => (props.active ? '#FFFFFF' : props.theme.colors.dark)};
  line-height: 25px;
  white-space: nowrap;
  transition: all 0.15s;

  &:hover {
    background: ${(props) => (props.active ? props.theme.colors.primary : '#00000010')};
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  @media only screen and (min-width: 800px) {
    padding: 0 15px;
    line-height: 35px;
  }
`;

export const ScrollButton = styled.div`
  display: none;
  position: absolute;
  border-radius: 50%;
  ${({ left = false }) => (left ? 'left: -40px;' : 'right: -40px;')}
  top: 0;
  height: 21px;
  width: 21px;
  cursor: pointer;
  background: url('inthecity/tab-icon.svg') 55% 50% no-repeat padding-box;
  background-size: 6px;
  transform: ${({ left = false }) => (left ? 'rotate(180deg)' : 'none')};
  padding: 0 0;

  &:hover {
    transform: ;
    transform: ${({ left = false }) => (left ? 'scale(1.04) rotate(180deg)' : 'scale(1.04)')};
  }

  @media only screen and (min-width: 800px) {
    display: block;
    top: 0;
    height: 35px;
    width: 35px;
    background-position: 55% 50%;
    background-size: auto;
  }
`;
