import { css } from '@emotion/react';
import Form from 'components/form';
import { InputField } from 'components/form/input';
import SubmitButton from 'components/form/submit-button';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { Button, Image } from 'components/elements';
import React, { useCallback, useEffect, useState } from 'react';
import { auth, providerFacebook, providerGoogle } from 'interface/firebase';
import theme from 'theme';
import { Loader } from 'components/inthecity';
import { useGlobalContext } from 'contexts/global';
import {
  forcelinkSignInEmail,
  forcelinkSignInSocial,
  signInAsGuest,
} from 'functions/auth';
import { validateEmail } from 'functions/form';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  signInAnonymously,
} from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import PROTECTED_ROUTES from 'constants/protected-routes';
import { UserEvents, logFirebaseEvent } from 'functions/firebase';
import { AuthState } from '.';

const SignIn = ({
  setAuthState,
  onSignIn,
}: {
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  onSignIn: any;
}) => {
  const [signInError, setSignInError] = useState<any>(null);
  const { setUserAndCityFromUser } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { globalState } = useGlobalContext();
  const [isForgotPasswordActive, setIsForgotPasswordActive] = useState(false);
  const [confirmResetEmail, setConfirmResetEmail] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname;

  const showGuestLogin = !PROTECTED_ROUTES.some((route) =>
    currentRoute.includes(route)
  );

  const handleSignInWithGoogle = useCallback(() => {
    setLoading(true);
    logFirebaseEvent(UserEvents.Login, { type: 'google' });
    signInWithPopup(auth, providerGoogle)
      .then(
        (userCredential) =>
          userCredential?.user &&
          userCredential.user.getIdToken().then((idToken) => {
            forcelinkSignInSocial(
              idToken,
              (e) => setSignInError(e),
              setUserAndCityFromUser,
              userCredential.user,
              onSignIn,
              globalState
            );
          })
      )
      .catch((error: any) => {
        setSignInError(error);
        setLoading(false);
      });
  }, []);

  const handleResetPassword = useCallback((data: any) => {
    setLoading(true);
    logFirebaseEvent(UserEvents.ResetPassword, { email: data.email });
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        setLoading(false);
        setIsForgotPasswordActive(false);
        setConfirmResetEmail(true);
      })
      .catch((error: any) => {
        setSignInError(error);
        setLoading(false);
      });
  }, []);

  const handleSignInWithFacebook = useCallback(() => {
    setLoading(true);
    logFirebaseEvent(UserEvents.Login, { type: 'facebook' });
    signInWithPopup(auth, providerFacebook)
      .then((result) => {
        const fbUser = result.user;
        fbUser?.getIdToken().then((token: string) => {
          forcelinkSignInSocial(
            token,
            (e) => setSignInError(e),
            setUserAndCityFromUser,
            fbUser,
            onSignIn,
            globalState
          );
        });
      })
      .catch((error: any) => {
        setSignInError(error);
        setLoading(false);
      });
  }, []);

  const handleSignInWithEmail = useCallback((data: any) => {
    setLoading(true);
    logFirebaseEvent(UserEvents.Login, { type: 'email', email: data.email });
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        if (userCredential?.user && !userCredential.user.emailVerified) {
          setSignInError({
            message: 'Please verify your email address before signing in.',
          });
        } else {
          userCredential?.user &&
            userCredential.user.getIdToken().then((idToken) => {
              forcelinkSignInEmail(
                idToken,
                (e) => setSignInError(e),
                setUserAndCityFromUser,
                onSignIn
              );
            });
        }
      })
      .catch((error) => {
        setSignInError(error);
        setLoading(false);
      });
  }, []);

  const handleSignAsGuest = useCallback(() => {
    setLoading(true);
    logFirebaseEvent(UserEvents.Login, { type: 'guest' });
    signInAnonymously(auth)
      .then((userCredential) => {
        userCredential?.user &&
          userCredential.user.getIdToken().then((idToken) => {
            signInAsGuest(idToken, setUserAndCityFromUser, onSignIn);
          });
      })
      .catch((error) => {
        setSignInError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (signInError) {
      setLoading(false);
    }
  }, [signInError]);

  if (loading) return <Loader />;

  return (
    <Form
      onSubmit={(d) =>
        isForgotPasswordActive
          ? handleResetPassword(d)
          : handleSignInWithEmail(d)
      }
      dataTestId="signin-form"
    >
      <Text textAlign="center" mt="10px" mb="20px">
        Sign in to your My Smart City account.
      </Text>
      <Grid
        gridRowGap="20px"
        px="30px"
        pb="20px"
        mobileCss={css`
          padding: 0 10px;
        `}
      >
        {signInError && (
          <Text color="red" dataTestId="signin-error-text">
            {signInError.message}
          </Text>
        )}
        {confirmResetEmail && (
          <Text
            p="16px"
            borderRadius="4px"
            fontSize="16px"
            fontWeight="bold"
            backgroundColor="#DEEFF5"
            dataTestId="signin-confirm-reset-email"
          >
            Please check your mailbox for instructions on how to reset your
            password, then sign in again below.
          </Text>
        )}
        <InputField
          dataTestId="signin-email"
          instructions={
            isForgotPasswordActive
              ? 'Enter your email address and we will send you a link to reset your password.'
              : ''
          }
          name="email"
          label="Email"
          placeholder="Email"
          required
          validate={(e) => {
            const v = e.target.value;
            if (v.length > 0 && !validateEmail(v)) {
              return {
                field: 'email',
                valid: false,
                message: 'Invalid email address',
              };
            }
            if (v.length === 0) {
              return {
                field: 'email',
                valid: false,
                message: 'Please enter your email',
              };
            }
            return { field: 'email', valid: true, message: '' };
          }}
        />
        {!isForgotPasswordActive && (
          <>
            <InputField
              dataTestId="signin-password"
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              required
              validate={(e) => {
                const v = e.target.value;
                if (v.length === 0) {
                  return {
                    field: 'password',
                    valid: false,
                    message: 'Please enter your password',
                  };
                }
                return { field: 'password', valid: true, message: '' };
              }}
            />
          </>
        )}
        <div>
          <SubmitButton
            label={isForgotPasswordActive ? 'Send reset email' : 'Sign in'}
            dataTestId="signin-button"
          />
          {globalState.user.uuid !== 'guest' && (
            <Button
              width="100%"
              fontSize="18px"
              dataTestId="guest-signin-button"
              variant="outlined"
              color="white"
              type="submit"
              py="20px"
              mt="25px"
              onClick={() => {
                handleSignAsGuest();
              }}
            >
              Sign in as guest
            </Button>
          )}
        </div>
        <Button
          onClick={() => setIsForgotPasswordActive((active) => !active)}
          fontSize="16px"
          fontWeight="bold"
          color={theme.colors.primary}
        >
          {isForgotPasswordActive ? 'Sign in with password' : 'Forgot password'}
        </Button>
        <Flex mt="-2px">
          <Box
            mt="8px"
            backgroundColor={theme.colors.borderGrey}
            height="1px"
            width="100%"
          />
          <Text textAlign="center" px="10px" color={theme.colors.lightGrey}>
            OR
          </Text>
          <Box
            mt="8px"
            backgroundColor={theme.colors.borderGrey}
            height="1px"
            width="100%"
          />
        </Flex>
        <Button
          onClick={handleSignInWithGoogle}
          variant="outlined"
          border={`1px solid ${theme.colors.lightGrey}`}
          dataTestId="signin-google-button"
        >
          <Flex width="100%">
            <Image
              source="map/gmail.png"
              objectFit="cover"
              width="25px"
              alt="gmail"
              position="absolute"
            />
            <Text mt="2px" width="100%" fontWeight="bold" color="black">
              Sign in with Google
            </Text>
          </Flex>
        </Button>
        <Button
          onClick={handleSignInWithFacebook}
          variant="outlined"
          border={`1px solid ${theme.colors.lightGrey}`}
          dataTestId="signin-google-button"
        >
          <Flex width="100%">
            <Image
              source="map/facebook.png"
              objectFit="cover"
              width="25px"
              alt="facebook"
              position="absolute"
            />
            <Text mt="2px" width="100%" fontWeight="bold" color="black">
              Sign in with Facebook
            </Text>
          </Flex>
        </Button>
        <Button
          dataTestId="signin-signup-button"
          onClick={() => {
            logFirebaseEvent(UserEvents.CreateAccount);
            setAuthState('signup');
          }}
          variant="outlined"
          fontWeight="bold"
          py="12px"
          color={theme.colors.primary}
        >
          Create Account
        </Button>
      </Grid>
    </Form>
  );
};

export default SignIn;
