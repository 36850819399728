/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { profileImageUrl } from 'functions/social';
import { Loader } from 'components/social/createpost';
import { useGlobalContext } from 'contexts/global';
import {
  getSmartCityUsersFollowed,
  getSmartCityUserFollowers,
  getSuggestedConnections,
  followUser,
  unFollowUser,
} from 'interface/social';
import { Button } from 'components/social';
import { NotFoundContainer } from 'pages/social/social.styled';
import {
  Row,
  ItemContainer,
  ProfileImage,
  InfoContainer,
  Username,
  City,
  Category,
  ButtonContainer,
  VerifiedIcon,
} from './connection.styled';

const PAGE_SIZE = 10;

export const SuggestionList = () => {
  const { globalState } = useGlobalContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getSuggestedConnections(1, 5);
      setData(res?.data);
      setLoading(false);
    };
    fetchData();
  }, [globalState.city]);
  return <ConnectionList data={data} loading={loading} />;
};

export const FollowersList = ({ updateCount }) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    currentPage: 0,
    total: null,
    lastPage: null,
    currentPageCount: null,
  });
  const [loading, setLoading] = useState(false);

  const handleNextPage = async () => {
    const nextPage = meta.currentPage + 1;
    const res = await getSmartCityUserFollowers(nextPage, PAGE_SIZE);
    setData([...data, ...res.data]);
    setMeta(res.meta);
    console.log(res);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await getSmartCityUserFollowers(1, PAGE_SIZE);
      setData(res.data);
      setMeta(res.meta);
      setLoading(false);
      updateCount(res.meta.total);
    }
    fetchData();
  }, []);

  return (
    <InfiniteScroll
      dataLength={meta?.currentPage}
      next={handleNextPage}
      hasMore={meta ? meta?.currentPage < meta?.lastPage : true}
      loader={<Loader />}
    >
      {loading && <Loader />}
      {data.length === 0 && !loading && (
        <NotFoundContainer>
          <h2>No followers</h2>
          {/* <p>Do this.</p> */}
        </NotFoundContainer>
      )}

      {Array.isArray(data) &&
        data.map(
          (
            {
              uuid,
              imgUrl,
              fullName,
              currentCitySubscriberId,
              smartCityUserRoles,
              verified,
              followable,
              followed,
            },
            index
          ) => (
            <Connection
              uuid={uuid}
              imgUrl={imgUrl}
              fullName={fullName}
              subscriberId={currentCitySubscriberId}
              role={smartCityUserRoles}
              isFollowing={followed}
              isFollowingMe
              followable={followable}
              verified={verified}
              key={index}
            />
          )
        )}
    </InfiniteScroll>
  );
};

export const FollowedList = ({ updateCount }) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    currentPage: 0,
    total: null,
    lastPage: null,
    currentPageCount: null,
  });
  const [loading, setLoading] = useState(false);

  const handleNextPage = async () => {
    const nextPage = meta.currentPage + 1;
    const res = await getSmartCityUsersFollowed(nextPage, PAGE_SIZE);
    setData([...data, ...res.data]);
    setMeta(res.meta);
    console.log(res);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await getSmartCityUsersFollowed(1, PAGE_SIZE);
      setData(res.data);
      setMeta(res.meta);
      setLoading(false);
      updateCount(res.meta.total);
    }
    fetchData();
  }, []);

  return (
    <InfiniteScroll
      dataLength={meta?.currentPage}
      next={handleNextPage}
      hasMore={meta ? meta?.currentPage < meta?.lastPage : true}
      loader={<Loader />}
    >
      {loading && <Loader />}
      {data.length === 0 && !loading && (
        <NotFoundContainer>
          <h2>You&apos;re not following anyone</h2>
          {/* <p>Do this.</p> */}
        </NotFoundContainer>
      )}
      {Array.isArray(data) &&
        data.map(
          (
            {
              uuid,
              imgUrl,
              fullName,
              currentCitySubscriberId,
              smartCityUserRoles,
              verified,
              followable,
              followed,
            },
            index
          ) => (
            <Connection
              uuid={uuid}
              imgUrl={imgUrl}
              fullName={fullName}
              subscriberId={currentCitySubscriberId}
              role={smartCityUserRoles}
              isFollowing={followed}
              followable={followable}
              verified={verified}
              key={index}
            />
          )
        )}
    </InfiniteScroll>
  );
};

const ConnectionList = ({ data = [], loading = false }) => {
  return (
    <>
      {loading && <Loader />}
      {Array.isArray(data) && data.length !== 0
        ? data.map(
            (
              {
                uuid,
                imgUrl,
                fullName,
                currentCitySubscriberId,
                smartCityUserRoles,
                verified,
                followable,
                followed,
              },
              index
            ) => (
              <Connection
                uuid={uuid}
                imgUrl={imgUrl}
                fullName={fullName}
                subscriberId={currentCitySubscriberId}
                role={smartCityUserRoles}
                isFollowing={followed}
                followable={followable}
                verified={verified}
                key={index}
              />
            )
          )
        : null}
    </>
  );
};

function getCityLabel(id, cities) {
  if (Array.isArray(cities) && cities.length > 0) {
    return cities.find((obj) => obj.value === id)?.label;
  }
  return 'No city found';
}

export const Connection = (props) => {
  const {
    uuid,
    fullName,
    subscriberId,
    role,
    verified,
    isFollowing,
    isFollowingMe,
    time,
    followable,
  } = props;

  const followingLabel = () => (isFollowingMe ? 'Following Back' : 'Following'); // change follow label if user is following me
  const followLabel = () => (isFollowingMe ? 'Follow Back' : 'Follow');

  const { globalState } = useGlobalContext();

  const [followState, setFollowState] = useState(isFollowing);

  const handleFollow = () => {
    console.log(`followState: ${followState}`);
    if (followState) {
      setFollowState(false);
      unFollowUser(uuid);
    } else {
      setFollowState(true);
      followUser(uuid);
    }
  };

  return (
    <ItemContainer>
      <Link to={`/social/profile/${uuid}`}>
        <ProfileImage src={profileImageUrl(uuid)} alt="profile" />
      </Link>
      <InfoContainer>
        <Row>
          <Link to={`/social/profile/${uuid}`}>
            <Username>{fullName}</Username>
          </Link>
          {verified && (
            <VerifiedIcon src="social/verified-icon.svg" alt="verified" />
          )}
        </Row>
        <City>{getCityLabel(subscriberId, globalState.availableCities)}</City>
        {role && (
          <Category>{_.startCase(_.toLower(role[0]?.description))}</Category>
        )}
        {time && <City>{time}</City>}
        {followable && ( // TODO: change to followable
          <ButtonContainer onClick={handleFollow}>
            <Button
              label={followState ? followingLabel() : followLabel()}
              active={followState}
            />
          </ButtonContainer>
        )}
      </InfoContainer>
    </ItemContainer>
  );
};

export default ConnectionList;
