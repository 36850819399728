import React from 'react';
import { Form, Row, Col, Modal, Button, ProgressBar } from 'react-bootstrap';
import { ICON_IMAGE_URL } from 'constants/app';
import _ from 'lodash';
import { isMarkerInsidePolygon } from 'functions/map';
import {
  getTopLevelPetitionWorkRequestTypes,
  getOrgUnitsForWorkType,
  addWorkRequestAttachment,
  logWorkRequest,
  getMyMapPetitions,
} from 'interface/map';
import 'styles/mapModal.scss';
import { Context } from 'functions/context';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import DraggableMarker from 'components/map/mapMarkerModal';
import lottie from 'lottie-web';
import { Scrollbars } from 'react-custom-scrollbars';
import { Flex } from 'components/layout';

export default class PetitionModal extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      reportObject: {},
      name: '',
      step: 1,
      goal: 500,
      WorkRequestTypes: [],
      OrgUnitsForWorkType: [],
      OrgUnitsForWorkTypeCurrentID: -1,
      SelectedOrgUnit: {},
      description: '',
      currentLocation: {},
      currentLocationInBounds: false,
      loadingPetitionModal: false,
      loadingPetitionModalA: false,
      savedWorkRequest: { description: 'test', id: -1 },
      currentFile1: [],
      currentImage1: {},
      currentFileError1: '',
      currentFile2: [],
      currentImage2: {},
      currentFileError2: '',
      currentFile3: [],
      currentImage3: {},
      currentFileError3: '',
      currentFile4: [],
      currentImage4: {},
      currentFileError4: '',
    };
    this.globalMap = null;
    this.handleChangeCurrentLocation =
      this.handleChangeCurrentLocation.bind(this);
    this.mape = React.createRef();
    this.imageFile1 = React.createRef();
    this.imageFile2 = React.createRef();
    this.imageFile3 = React.createRef();
    this.imageFile4 = React.createRef();
  }

  handleChangeCurrentLocation(currentLocation) {
    const { cityBounds } = this.props;
    let temp = false;
    for (let i = 0; i < cityBounds.length; i++) {
      if (isMarkerInsidePolygon(currentLocation, cityBounds[i])) {
        temp = true;
        break;
      }
    }
    this.setState({
      currentLocationInBounds: temp,
      currentLocation,
    });
  }

  categoryClicked = (filter) => {
    // Since a new category is chosen we can reset the ReportObject
    console.log('filter categoryClicked', filter);
    this.setState({ reportObject: _.cloneDeep(filter) });
  };

  categoryIcon = (key, filter) => {
    const { reportObject } = this.state;
    // console.log('reportObject', reportObject);
    // console.log(filter);
    let icon;
    if (!filter.attributes.customIcon) {
      icon = 'personal_emergency.png'.split('.');
    } else {
      icon = filter.attributes.customIcon.split('.');
    }

    const selectedStyle =
      'invert(51%) sepia(100%) saturate(1121%) hue-rotate(343deg) brightness(99%) contrast(98%)';
    const { currentUser } = this.props;
    return (
      <Col
        xs={4}
        md={3}
        style={{
          textAlign: 'center',
          paddingBottom: '20px',
          cursor: 'pointer',
        }}
        onClick={() => this.categoryClicked(filter)}
      >
        <img
          src={`${ICON_IMAGE_URL}${icon[0]}.${icon[1]}?cityId=${currentUser.currentCitySubscriberId}`}
          alt="Category icon"
          className="categoryImg"
          style={{
            filter: filter.value === reportObject.value ? selectedStyle : null,
          }}
        />
        <div style={{ wordBreak: 'break-word' }}>{filter.label}</div>
      </Col>
    );
  };

  createAPetition = () => {
    const { reportObject, WorkRequestTypes } = this.state;
    return (
      <div>
        <div className="title">Start a Petition</div>
        <div className="subtitle">What would you like to petition?</div>
        <Flex
          overflowY="scroll"
          maxHeight="calc(100vh - 400px)"
          width="fit-content"
          overflowX="hidden"
          mb="10px"
          pr="20px"
        >
          <Form.Group>
            <Row style={{ marginTop: '15px' }}>
              {WorkRequestTypes.map((filter, i) =>
                this.categoryIcon(`caticon${i}`, filter)
              )}
            </Row>
          </Form.Group>
        </Flex>
        {/* Buttons  */}
        <Row className="buttonBar">
          {reportObject.attributes ? (
            <Button
              className="modalButton fr"
              onClick={() => this.setState({ step: 2 })}
            >
              NEXT
            </Button>
          ) : (
            <Button className="modalButton buttonGreyed fr">NEXT</Button>
          )}
        </Row>
      </div>
    );
  };

  setTitle = () => {
    const { reportObject, name } = this.state;
    this.loadGetOrgUnitsForWorkType(reportObject.value);
    return (
      <div>
        <div className="title">Give your cause a title.</div>
        <div className="subtitle">
          Try to capture the interest of the public.
        </div>
        <Form.Group style={{ margin: '30px 0px' }}>
          <Form.Control
            value={name}
            onChange={(event) => this.setState({ name: event.target.value })}
            placeholder="Title"
            className="textField"
          />
          <Form.Text className="text-muted" style={{ margin: '0px 0px 0px' }}>
            Your description should be between{' '}
            <div className="textAccentOrange"> 5 and 35 characters.</div>
          </Form.Text>
        </Form.Group>
        <Row className="buttonBar">
          {name.length > 5 && name.length < 35 ? (
            <Button
              className="modalButton fr "
              onClick={() => this.setState({ step: 3 })}
            >
              NEXT
            </Button>
          ) : (
            <Button className="buttonGreyed modalButton fr ">NEXT</Button>
          )}
          <Button
            className="modalButton"
            onClick={() => {
              this.setState({ step: 1 });
            }}
          >
            BACK
          </Button>
        </Row>
      </div>
    );
  };

  setDescription = () => {
    const { OrgUnitsForWorkType, description } = this.state;
    return (
      <div>
        <div className="title">Tell the World More</div>
        <div className="subtitle">
          Think of a description that best fits your cause.
        </div>
        <Form.Group>
          <Row style={{ marginTop: '15px', padding: '10px' }}>
            <Form.Control
              as="textarea"
              rows={4}
              value={description}
              onChange={(event) => {
                this.setState({ description: event.target.value });
              }}
              style={{
                marginBottom: '5px',
                resize: 'none',
                width: '100%',
                borderRadius: '10px',
              }}
              placeholder="Description"
            />
            <Form.Text className="text-muted" style={{ margin: '0px 0px 0px' }}>
              Your description should be between{' '}
              <div className="textAccentOrange"> 50 and 2000 characters.</div>
            </Form.Text>
          </Row>
        </Form.Group>
        <Row className="buttonBar">
          {description.length > 50 && description.length < 2000 ? (
            <Button
              className="modalButton fr"
              onClick={() => {
                if (OrgUnitsForWorkType.length === 0) {
                  this.setState({ step: 5 });
                } else {
                  this.setState({ step: 4 });
                }
              }}
            >
              NEXT
            </Button>
          ) : (
            <Button className="modalButton fr buttonGreyed">NEXT</Button>
          )}
          <Button
            className="modalButton"
            onClick={() => {
              this.setState({ step: 2 });
            }}
          >
            BACK
          </Button>
        </Row>
      </div>
    );
  };

  setTarget = () => {
    const { SelectedOrgUnit, OrgUnitsForWorkType } = this.state;
    console.log(OrgUnitsForWorkType);
    console.log(SelectedOrgUnit);
    if (
      OrgUnitsForWorkType.length === 1 &&
      SelectedOrgUnit !== OrgUnitsForWorkType[0]
    ) {
      this.setState({ SelectedOrgUnit: OrgUnitsForWorkType[0] });
    }
    return (
      <div>
        <div className="title">Who can help make this happen?</div>

        <div className="subtitle">Choose the most relevant party.</div>

        <Form.Group>
          <Row style={{ paddingTop: '10px', marginBottom: '40px' }}>
            <Col md={12}>
              <Form.Control
                as="select"
                onChange={(event) =>
                  this.setState({ SelectedOrgUnit: event.target.value })
                }
              >
                {OrgUnitsForWorkType.map((item, j) => (
                  <option value={item} key={`subcat${item}${j}`}>
                    {item.label}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
        <Row className="buttonBar">
          <Button
            className="modalButton fr"
            onClick={() => this.setState({ step: 5 })}
          >
            NEXT
          </Button>

          <Button
            className="modalButton"
            onClick={() => {
              this.setState({ step: 3 });
            }}
          >
            BACK
          </Button>
        </Row>
      </div>
    );
  };

  // Goal
  setGoal = () => {
    // Set the number of signatures.
    const { goal, OrgUnitsForWorkType } = this.state;
    return (
      <div>
        <div className="title">Signatures</div>
        <div className="subtitle">
          Set a goal for the minimum number of signatures this may require.
        </div>
        <Form.Group style={{ margin: '30px 0px' }}>
          <Form.Control
            value={goal}
            onChange={(event) => {
              if (`${Number(event.target.value)}` !== 'NaN') {
                this.setState({ goal: Number(event.target.value) });
              }
            }}
            placeholder="Goal"
            className="textField"
          />
          <Form.Text className="text-muted" style={{ margin: '0px 0px 0px' }}>
            Think about the{' '}
            <div className="textAccentOrange">
              size of the cause (between 500 and 10 000 000).
            </div>
          </Form.Text>
        </Form.Group>
        <Row className="buttonBar">
          {!Number.isNaN(goal) && goal < 10000000 && goal >= 500 ? (
            <Button
              className="modalButton fr "
              onClick={() => this.setState({ step: 6 })}
            >
              NEXT
            </Button>
          ) : (
            <Button className="buttonGreyed modalButton fr ">NEXT</Button>
          )}
          <Button
            className="modalButton"
            onClick={() => {
              if (OrgUnitsForWorkType.length === 0) {
                this.setState({ step: 3 });
              } else {
                this.setState({ step: 4 });
              }
            }}
          >
            BACK
          </Button>
        </Row>
      </div>
    );
  };

  // Upload an image
  setImages = () => {
    const {
      currentFile1,
      currentImage1,
      currentFileError1,
      currentFile2,
      currentImage2,
      currentFileError2,
      currentFile3,
      currentImage3,
      currentFileError3,
      currentFile4,
      currentImage4,
      currentFileError4,
    } = this.state;

    const { useThisLocationForLog } = this.props;

    return (
      <div className="stage6">
        <div className="title">Add a Photo</div>
        <div className="subtitle">
          Photo&apos;s help convince people of your cause.
        </div>
        <Scrollbars autoHeight>
          <div style={{ display: 'flex', padding: '0px 0px 20px' }}>
            {currentFile1.length === 1 ? (
              <div
                className={
                  currentFileError1 === ''
                    ? 'uploadImageBlock'
                    : 'uploadImageBlock fileError'
                }
                style={{ background: `url('${currentImage1}')` }}
              >
                <div
                  className="imageUploadCrossIconContainer"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({
                      currentFile1: [],
                      currentImage1: {},
                      currentFileError1: '',
                    });
                  }}
                >
                  <img
                    className="imageUploadCrossIcon"
                    src="map/mapMarker/imageCross.svg"
                    alt="map/mapMarker/imageCross.svg"
                  />
                </div>
                <div style={{ height: '135px', width: '135px' }} />
                <div className="fileErrorText">{currentFileError1}</div>
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.imageFile1.current.click()}
              >
                <img
                  className="imageUploadContainerIcon"
                  src="map/Profile_Picture_Holder.svg"
                  alt="icons/Profile_Picture_Holder.svg"
                />
              </div>
            )}
            {currentFile2.length === 1 ? (
              <div
                className={
                  currentFileError2 === ''
                    ? 'uploadImageBlock'
                    : 'uploadImageBlock fileError'
                }
                style={{
                  background: `url('${currentImage2}')`,
                }}
              >
                <div
                  className="imageUploadCrossIconContainer"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({
                      currentFile2: [],
                      currentImage2: {},
                      currentFileError2: '',
                    });
                  }}
                >
                  <img
                    className="imageUploadCrossIcon"
                    src="map/mapMarker/imageCross.svg"
                    alt="map/mapMarker/imageCross.svg"
                  />
                </div>
                <div style={{ height: '135px', width: '135px' }} />
                <div className="fileErrorText">{currentFileError2}</div>
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.imageFile2.current.click()}
              >
                <img
                  className="imageUploadContainerIcon"
                  src="map/Profile_Picture_Holder.svg"
                  alt="icons/Profile_Picture_Holder.svg"
                />
              </div>
            )}
            {currentFile3.length === 1 ? (
              <div
                className={
                  currentFileError3 === ''
                    ? 'uploadImageBlock'
                    : 'uploadImageBlock fileError'
                }
                style={{
                  background: `url('${currentImage3}')`,
                }}
              >
                <div
                  className="imageUploadCrossIconContainer"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({
                      currentFile3: [],
                      currentImage3: {},
                      currentFileError3: '',
                    });
                  }}
                >
                  <img
                    className="imageUploadCrossIcon"
                    src="map/mapMarker/imageCross.svg"
                    alt="map/mapMarker/imageCross.svg"
                  />
                </div>
                <div style={{ height: '135px', width: '135px' }} />
                <div className="fileErrorText">{currentFileError3}</div>
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.imageFile3.current.click()}
              >
                <img
                  className="imageUploadContainerIcon"
                  src="map/Profile_Picture_Holder.svg"
                  alt="icons/Profile_Picture_Holder.svg"
                />
              </div>
            )}
            {currentFile4.length === 1 ? (
              <div
                className={
                  currentFileError4 === ''
                    ? 'uploadImageBlock'
                    : 'uploadImageBlock fileError'
                }
                style={{
                  background: `url('${currentImage4}')`,
                }}
              >
                <div
                  className="imageUploadCrossIconContainer"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({
                      currentFile4: [],
                      currentImage4: {},
                      currentFileError4: '',
                    });
                  }}
                >
                  <img
                    className="imageUploadCrossIcon"
                    src="map/mapMarker/imageCross.svg"
                    alt="map/mapMarker/imageCross.svg"
                  />
                </div>
                <div style={{ height: '135px', width: '135px' }} />
                <div className="fileErrorText">{currentFileError4}</div>
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.imageFile4.current.click()}
              >
                <img
                  className="imageUploadContainerIcon"
                  src="map/Profile_Picture_Holder.svg"
                  alt="icons/Profile_Picture_Holder.svg"
                />
              </div>
            )}
          </div>
        </Scrollbars>
        <Form.Text className="text-muted">
          Click on the <div className="textAccentOrange"> camera </div> to
          upload a photo.
        </Form.Text>
        <Row className="buttonBar">
          <Button
            className="modalButton"
            onClick={() => this.setState({ step: 5 })}
          >
            BACK
          </Button>
          {currentFileError1 === '' &&
          currentFileError2 === '' &&
          currentFileError3 === '' &&
          currentFileError4 === '' ? (
            <Button
              className="modalButton fr "
              onClick={() => {
                if (useThisLocationForLog.lat) {
                  this.setState({ step: 8 });
                } else {
                  this.setState({ step: 7 });
                }
              }}
            >
              NEXT
            </Button>
          ) : (
            <Button className="modalButton fr buttonGreyed">NEXT</Button>
          )}
        </Row>
      </div>
    );
  };

  renderMap = (map) => {
    const { position } = this.state;
    if (!this.globalMap) {
      // console.log('map from MapConsumer:', map);
      // console.log('map center:', map.getCenter());
      this.globalMap = map;
      // map && this.globalMap.on("move", this.onMove);
      // map && this.globalMap.on("zoomend", this.onZoomEnd);
      console.log('this.globalMap=', this.globalMap);
      console.log('position=', position);
      map && this.globalMap.on('moveend', this.onMoveEnd);
    }
    return null;
  };

  // Choose location
  selectANewLocation = () => {
    const { currentLocation, currentLocationInBounds } = this.state;
    const { cityBounds, center } = this.props;
    if (!currentLocation.lng) {
      this.setState({ currentLocation: _.cloneDeep(center) });
    }
    return (
      <div>
        <div className="title">Location</div>
        <div className="subtitle">
          Pinpoint the exact location of the petition
        </div>
        <Row>
          {currentLocationInBounds ? null : (
            <div className="mapNotSupport">
              Please choose a location within the orange part of the map.
            </div>
          )}

          <MapContainer
            ref={this.mape}
            center={center}
            zoom={13}
            style={{
              height: '400px',
              width: '100%',
              borderRadius: '10px',
              margin: '0px 15px 25px',
            }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="Copyright 2021 | MySmart.City"
            />
            {cityBounds.map((bound) => (
              <Polygon
                positions={bound}
                fillColor="#df6e21"
                fillOpacity="0.1"
                weight="0.5"
                color="#693616"
                interactive={false}
              />
            ))}

            <DraggableMarker
              center={center}
              handleChangeCurrentLocation={(s) =>
                this.handleChangeCurrentLocation(s)
              }
            />
          </MapContainer>
        </Row>
        <Row className="buttonBar">
          {currentLocationInBounds ? (
            <Button
              className="modalButton fr"
              onClick={() => this.setState({ step: 8 })}
            >
              OVERVIEW
            </Button>
          ) : (
            <Button className="modalButton buttonGreyed fr">OVERVIEW</Button>
          )}
          <Button
            className="modalButton"
            onClick={() => {
              this.setState({ step: 6 });
            }}
          >
            BACK
          </Button>
        </Row>
      </div>
    );
  };

  overView = () => {
    const {
      name,
      description,
      goal,
      currentFile1,
      currentImage1,
      currentFileError1,
      currentFile2,
      currentImage2,
      currentFileError2,
      currentFile3,
      currentImage3,
      currentFileError3,
      currentFile4,
      currentImage4,
      currentFileError4,
    } = this.state;

    const { useThisLocationForLog } = this.props;
    return (
      <div>
        <div className="title">Overview</div>
        <div className="subtitle">
          Take a quick look at your petition to make any{' '}
          <div className="textAccentOrange"> final adjustments. </div>
        </div>
        <div style={{ margin: '0px -40px 20px' }}>
          <Scrollbars autoHeight autoHeightMin={350}>
            <div className="overviewContainer">
              <div
                className="title textAccentBold"
                style={{
                  textAlign: 'left',
                  padding: '0px',
                }}
              >
                {name}
              </div>
              <div className="progressContainer">
                <div className="textAccentBold">Progress status</div>
                <ProgressBar now={0} />
                <div
                  className="popupStatusInfo"
                  style={{
                    display: 'inline-flex',
                    width: '100%',
                  }}
                >
                  <div
                    className="textAccentBold"
                    style={{
                      width: '50%',
                    }}
                  >
                    0 Shares
                  </div>
                  <div
                    className="textAccentBold"
                    style={{
                      width: '50%',
                      textAlign: 'right',
                    }}
                  >
                    {goal} Shares
                  </div>
                </div>
              </div>
              <Scrollbars autoHeight>
                <div style={{ display: 'flex', padding: '0px' }}>
                  {currentFile1.length === 1 ? (
                    <div
                      className={
                        currentFileError1 === ''
                          ? 'uploadImageBlock'
                          : 'uploadImageBlock fileError'
                      }
                      style={{ background: `url('${currentImage1}')` }}
                    >
                      <div className="imageUploadCrossIconContainer" />
                      <div style={{ height: '135px', width: '135px' }} />
                      <div className="fileErrorText">{currentFileError1}</div>
                    </div>
                  ) : null}
                  {currentFile2.length === 1 ? (
                    <div
                      className={
                        currentFileError2 === ''
                          ? 'uploadImageBlock'
                          : 'uploadImageBlock fileError'
                      }
                      style={{
                        background: `url('${currentImage2}')`,
                      }}
                    >
                      <div className="imageUploadCrossIconContainer" />
                      <div style={{ height: '135px', width: '135px' }} />
                      <div className="fileErrorText">{currentFileError2}</div>
                    </div>
                  ) : null}
                  {currentFile3.length === 1 ? (
                    <div
                      className={
                        currentFileError3 === ''
                          ? 'uploadImageBlock'
                          : 'uploadImageBlock fileError'
                      }
                      style={{
                        background: `url('${currentImage3}')`,
                      }}
                    >
                      <div className="imageUploadCrossIconContainer" />
                      <div style={{ height: '135px', width: '135px' }} />
                      <div className="fileErrorText">{currentFileError3}</div>
                    </div>
                  ) : null}
                  {currentFile4.length === 1 ? (
                    <div
                      className={
                        currentFileError4 === ''
                          ? 'uploadImageBlock'
                          : 'uploadImageBlock fileError'
                      }
                      style={{
                        background: `url('${currentImage4}')`,
                      }}
                    >
                      <div className="imageUploadCrossIconContainer" />
                      <div style={{ height: '135px', width: '135px' }} />
                      <div className="fileErrorText">{currentFileError4}</div>
                    </div>
                  ) : null}
                </div>
              </Scrollbars>
              <div className="petitionDescriptionOverview">{description}</div>
            </div>
          </Scrollbars>
        </div>
        <Row className="buttonBar">
          <Button
            className="modalButton "
            onClick={() => {
              if (useThisLocationForLog.lat) {
                this.setState({ step: 6 });
              } else {
                this.setState({ step: 7 });
              }
            }}
          >
            BACK
          </Button>
          <Button
            className="modalButton fr"
            onClick={() => this.setState({ step: 9 })}
          >
            SHARE
          </Button>
        </Row>
      </div>
    );
  };

  confirm = () => {
    console.log('over');
    return (
      <div>
        <div className="title">Sharing</div>
        <div className="subtitle" style={{ margin: '40px 10px' }}>
          By clicking share you are{' '}
          <div className="textAccentOrange"> hereby adhering</div> to the
          <div className="textAccentOrange">
            {' '}
            terms and conditions of the My Smart City platform and service{' '}
          </div>
          which{' '}
          <div
            className="textAccentOrange textAccentBold"
            onClick={() => window.open('/terms-and-conditions')}
            role="button"
            tabIndex={0}
          >
            can be read here.
          </div>{' '}
          Once you have shared this petition you can no longer make any changes
          to this form as it will then be considered a public document for other
          users to sign and share.
          <br />
          <br />
          <div className="textAccentBold">
            Do you wish to share your petition?
          </div>
        </div>
        <Row className="buttonBar">
          <Button
            className="modalButton fr"
            onClick={() => this.handleSubmit()}
          >
            SHARE
          </Button>
          <Button
            className="modalButton"
            onClick={() => this.setState({ step: 7 })}
          >
            BACK
          </Button>
        </Row>
      </div>
    );
  };

  // Done
  done = () => {
    const { savedWorkRequest } = this.state;
    const { thisOfOverlay } = this.props;
    return (
      <div>
        <div className="title">Thank You!</div>
        <div className="subtitle">
          Your petition has been created. Other users can now view and sign it.
        </div>

        <div
          className="subtitle"
          style={{
            marginTop: '0px',
            marginBottom: '40px',
          }}
        >
          Issue number:{' '}
          <div className="textAccentOrange"> {savedWorkRequest.code}</div>
        </div>

        <Row
          style={{
            marginBottom: '40px',
          }}
        >
          <Col
            sm={12}
            style={{
              display: 'contents',
            }}
          >
            <img
              className="imageContainer"
              src="map/tick.svg"
              alt="file missing"
            />
          </Col>
        </Row>

        <div className="shareThis">
          <div className="textAccentBold textAccentOrange">
            Share this petition
          </div>
          <div className="iconRow">
            <div
              role="button"
              className="iconButton"
              tabIndex={0}
              onClick={() => {
                window.open(
                  `https://www.facebook.com/sharer/sharer.php?u=mysmart.city/map/petitions/${savedWorkRequest.id}&t=${savedWorkRequest.description}`,
                  '_blank'
                );
              }}
            >
              <img
                src="map/socialMedia/facebook.svg"
                alt="Category icon"
                className="shareIcons"
              />
            </div>
            <div
              role="button"
              className="iconButton"
              tabIndex={0}
              onClick={() => {
                window.open(
                  `http://twitter.com/share?text=${savedWorkRequest.description}&url=https://mysmart.city/map/petitions/${savedWorkRequest.id}&hashtags=mysmartcity`,
                  '_blank'
                );
              }}
            >
              <img
                src="map/socialMedia/twitter.svg"
                alt="Category icon"
                className="shareIcons"
              />
            </div>
            <div
              role="button"
              className="iconButton"
              tabIndex={0}
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://mysmart.city/map/petitions/${savedWorkRequest.id}`
                );
              }}
            >
              <img
                src="map/socialMedia/magnetlink.svg"
                alt="Category icon"
                className="shareIcons"
              />
            </div>
          </div>
        </div>
        <Row className="buttonBar">
          <Button
            className="modalButton"
            onClick={() => {
              thisOfOverlay.setState({ petitionModal: false });
            }}
          >
            CLOSE
          </Button>
          <Button
            className="modalButton fr"
            onClick={() => {
              thisOfOverlay.setState({
                reportModal: false,
                showMarkerViewModalWR: savedWorkRequest,
                showMarkerView: true,
              });
            }}
          >
            VIEW
          </Button>
        </Row>
      </div>
    );
  };

  handleSubmit = () => {
    console.log('Submitting report');
    const {
      currentUser,
      currentCity,
      useThisLocationForLog,
      getWorkRequests,
      serviceType,
      setState,
    } = this.props;
    const {
      name,
      description,
      goal,
      reportObject,
      SelectedOrgUnit,
      currentFile1,
      currentFile2,
      currentFile3,
      currentFile4,
      loadingPetitionModal,
    } = this.state;

    let { currentLocation } = this.state;
    if (useThisLocationForLog.lat) {
      currentLocation = useThisLocationForLog;
    }
    const temp = {
      description: name, // required
      custom1: description,
      premiseId: 0, // required
      typeId: reportObject.value,
      orgUnitId: SelectedOrgUnit.value,
      contactName: currentUser.fullName,
      contactNumber: currentUser.mobileNumber,
      contactEmail: currentUser.username,
      latitude: currentLocation.lat,
      longitude: currentLocation.lng,
      customNumber1: goal,
      privateFlag: false,
    };

    this.setState({
      loadingPetitionModal: true,
      loadingPetitionModalA: true,
    });

    console.log('tempWorkRequest:', temp);
    logWorkRequest(JSON.stringify(temp)).then((savedWorkRequest) => {
      const mylittlepromises = [];
      if (currentFile1.length === 1) {
        const formData = new FormData();
        console.log('Uploaded file to', currentFile1[0]);
        formData.append('workRequestId', savedWorkRequest.id);
        formData.append('file', currentFile1[0]);
        mylittlepromises.push(addWorkRequestAttachment(formData));
      }
      if (currentFile2.length === 1) {
        const formData = new FormData();
        console.log('Uploaded file to', currentFile2[0]);
        formData.append('workRequestId', savedWorkRequest.id);
        formData.append('file', currentFile2[0]);
        mylittlepromises.push(addWorkRequestAttachment(formData));
      }
      if (currentFile3.length === 1) {
        const formData = new FormData();
        console.log('Uploaded file to', currentFile3[0]);
        formData.append('workRequestId', savedWorkRequest.id);
        formData.append('file', currentFile3[0]);
        mylittlepromises.push(addWorkRequestAttachment(formData));
      }
      if (currentFile4.length === 1) {
        const formData = new FormData();
        console.log('Uploaded file to', currentFile4[0]);
        formData.append('workRequestId', savedWorkRequest.id);
        formData.append('file', currentFile4[0]);
        mylittlepromises.push(addWorkRequestAttachment(formData));
      }

      Promise.all(mylittlepromises).then((values) => {
        console.log('Uploaded file to', savedWorkRequest, values);
        // Set loading to false
        if (`${serviceType}` === 'petitions') {
          getWorkRequests(
            setState,
            currentLocation,
            null,
            currentCity.attributes.subscriberId,
            true
          ).then(() => {
            console.log('loadingPetitionModal', loadingPetitionModal);
            getMyMapPetitions(1, 200).then((res) => {
              setState({ myWorkRequests: res });
              this.setState({
                savedWorkRequest,
                loadingPetitionModal: false,
                step: 10,
              });
            });
          });
        } else {
          this.setState({
            savedWorkRequest,
            loadingPetitionModal: false,
            step: 10,
          });
        }
      });
      console.log(savedWorkRequest);
    });
  };

  stepIcon = () => {
    const { step } = this.state;
    return (
      <Row>
        <div
          style={{
            margin: '25px auto 0px auto',
          }}
        >
          <div
            className={
              step > 0 ? 'progressBall' : 'progressBall progressBallDisabled'
            }
          />
          <div
            className={
              step > 1 ? 'progressBall' : 'progressBall progressBallDisabled'
            }
          />
          <div
            className={
              step > 2 ? 'progressBall' : 'progressBall progressBallDisabled'
            }
          />
          <div
            className={
              step > 3 ? 'progressBall' : 'progressBall progressBallDisabled'
            }
          />
          <div
            className={
              step > 4 ? 'progressBall' : 'progressBall progressBallDisabled'
            }
          />
          <div
            className={
              step > 5 ? 'progressBall' : 'progressBall progressBallDisabled'
            }
          />
          <div
            className={
              step > 6 ? 'progressBall' : 'progressBall progressBallDisabled'
            }
          />
          <div
            className={
              step > 7 ? 'progressBall' : 'progressBall progressBallDisabled'
            }
          />
          <div
            className={
              step > 8 ? 'progressBall' : 'progressBall progressBallDisabled'
            }
          />
        </div>
      </Row>
    );
  };

  componentDidMount = async () => {
    console.log('PetitionModal: componentDidMount');
    const { currentCity } = this.props;
    // Loading
    lottie.destroy('loginLoadingPetitionModal');
    const animLoginPetition = lottie.loadAnimation({
      name: 'loginLoadingPetitionModal',
      container: this.animateLoadingP,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'mscLogoGrey.json',
    });

    animLoginPetition.onLoopComplete = () => {
      const { loadingPetitionModal, loadingPetitionModalA } = this.state;
      console.log(
        'onLoopComplete',
        loadingPetitionModal,
        loadingPetitionModalA
      );
      if (!loadingPetitionModal) {
        lottie.stop('loginLoadingPetitionModal');
        this.setState({ loadingPetitionModalA: false });
        console.log('onLoopComplete: loginLoadingPetitionModal STOPPED');
      }
    };
    this.setState({ loadingPetitionModal: true, loadingPetitionModalA: true });
    const WorkRequestTypesT = await getTopLevelPetitionWorkRequestTypes(
      currentCity.attributes.subscriberId
    );
    console.log('WorkRequestTypes', WorkRequestTypesT);
    this.setState({
      WorkRequestTypes: WorkRequestTypesT,
      loadingPetitionModal: false,
    });
  };

  componentWillUnmount = () => {
    lottie.destroy('loginLoadingPetitionModal');
  };

  loadGetOrgUnitsForWorkType = async (type) => {
    const { OrgUnitsForWorkTypeCurrentID } = this.state;
    if (OrgUnitsForWorkTypeCurrentID !== type) {
      const OrgUnitsForWorkTypeT = await getOrgUnitsForWorkType(type);
      console.log('WorkRequestTypes', OrgUnitsForWorkTypeT);
      this.setState({
        OrgUnitsForWorkType: OrgUnitsForWorkTypeT,
        OrgUnitsForWorkTypeCurrentID: type,
      });
    }
  };

  render() {
    const { step, loadingPetitionModal, loadingPetitionModalA } = this.state;
    if (loadingPetitionModal) {
      lottie.play('loginLoadingPetitionModal');
    }

    console.log(
      'onLoopComplete RE',
      loadingPetitionModal,
      loadingPetitionModalA
    );
    return (
      <div id="petitionModal" className="mapModal">
        <div
          id="LoadingLoginOverlayContainer"
          style={
            loadingPetitionModalA ? { display: 'block' } : { display: 'none' }
          }
        >
          <div
            id="LoadingLoginOverlay"
            className={loadingPetitionModal ? 'fadeIn' : 'fadeOut'}
          />
          <div
            ref={(ref) => {
              this.animateLoadingP = ref;
            }}
            id="LoadingLoginOverlayImage"
            style={
              loadingPetitionModalA ? { display: 'block' } : { display: 'none' }
            }
          />
        </div>

        <Modal.Body style={{ padding: '0px 40px 30px' }}>
          {step === 1 ? this.createAPetition() : null}
          {step === 2 ? this.setTitle() : null}
          {step === 3 ? this.setDescription() : null}
          {step === 4 ? this.setTarget() : null}
          {step === 5 ? this.setGoal() : null}
          {step === 6 ? this.setImages() : null}
          {step === 7 ? this.selectANewLocation() : null}
          {step === 8 ? this.overView() : null}
          {step === 9 ? this.confirm() : null}
          {step === 10 ? this.done() : null}
          {this.stepIcon()}
          <input
            type="file"
            id="file1"
            ref={this.imageFile1}
            style={{ display: 'none' }}
            onChange={(e) => {
              const allowedExtensions = /(jpg|jpeg|png)$/i;
              if (!allowedExtensions.exec(e.target.files[0].type)) {
                console.log('incorrect file type', e.target.files[0]);
                this.setState({ currentFileError1: 'Incorrect file type' });
              } else if (e.target.files[0].size > 10000000) {
                console.log('File too large', e.target.files[0].size);
                this.setState({ currentFileError1: 'File too large' });
              } else {
                this.setState({ currentFileError1: '' });
              }
              this.setState({
                currentFile1: e.target.files,
                currentImage1: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />
          <input
            type="file"
            id="file2"
            ref={this.imageFile2}
            style={{ display: 'none' }}
            onChange={(e) => {
              const allowedExtensions = /(jpg|jpeg|png)$/i;
              if (!allowedExtensions.exec(e.target.files[0].type)) {
                console.log('incorrect file type', e.target.files[0]);
                this.setState({ currentFileError2: 'Incorrect file type' });
              } else if (e.target.files[0].size > 10000000) {
                console.log('File too large', e.target.files[0].size);
                this.setState({ currentFileError2: 'File too large' });
              } else {
                this.setState({ currentFileError2: '' });
              }
              this.setState({
                currentFile2: e.target.files,
                currentImage2: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />
          <input
            type="file"
            id="file3"
            ref={this.imageFile3}
            style={{ display: 'none' }}
            onChange={(e) => {
              const allowedExtensions = /(jpg|jpeg|png)$/i;
              if (!allowedExtensions.exec(e.target.files[0].type)) {
                console.log('incorrect file type', e.target.files[0]);
                this.setState({ currentFileError3: 'Incorrect file type' });
              } else if (e.target.files[0].size > 10000000) {
                console.log('File too large', e.target.files[0].size);
                this.setState({ currentFileError3: 'File too large' });
              } else {
                this.setState({ currentFileError3: '' });
              }
              this.setState({
                currentFile3: e.target.files,
                currentImage3: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />
          <input
            type="file"
            id="file4"
            ref={this.imageFile4}
            style={{ display: 'none' }}
            onChange={(e) => {
              const allowedExtensions = /(jpg|jpeg|png)$/i;
              if (!allowedExtensions.exec(e.target.files[0].type)) {
                console.log('incorrect file type', e.target.files[0]);
                this.setState({ currentFileError4: 'Incorrect file type' });
              } else if (e.target.files[0].size > 10000000) {
                console.log('File too large', e.target.files[0].size);
                this.setState({ currentFileError4: 'File too large' });
              } else {
                this.setState({ currentFileError4: '' });
              }
              this.setState({
                currentFile4: e.target.files,
                currentImage4: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />
        </Modal.Body>
      </div>
    );
  }
}
