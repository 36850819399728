import styled from 'styled-components';

export const StyledNavbar = styled.div`
  z-index: 1;
  width: 100%;
  height: 60px;
  padding: 0 25px;
  background: #333333;
  align-items: center;
  display: flex;
  flex-direction: row;
  z-index: 10000000000000000;
`;

export const Logo = styled.img`
  width: 45px;
  height: 40px;
  margin: 0px 25px 0px 0px;
`;

export const GenericDropdown = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 40px #00000029;
  z-index: 1;
  cursor: default;
  opacity: 0;
  visibility: hidden;
  ${({ open }) =>
    open
      ? `
    // transform: translateY(0);
    transition-timing-function: ease-out;
    transition: 0.2s;
    opacity: 1;
    visibility: visible;`
      : `
    // transform: translateY(5%);
    transition-timing-function: ease-in;
    transition: 0.15s;
    z-index: -1;`}
`;

export const Icon = styled.img`
  height: 20px;
  @media only screen and (min-width: 800px) {
    height: 25px;
  }
`;

export const NavBarText = styled.div`
  font: normal normal normal 14px/21px Roboto;
  color: #ffffff;
`;

export const NavBarTextContainer = styled.div`
  margin: 0px 15px;
  padding: 5px 6px;
  ${(props) =>
    props.underlined
      ? 'border-bottom-style: solid; border-bottom-width: 1px; border-color: white;'
      : ''}
`;

export const Nav = styled.div`
  display: none;
  @media only screen and (min-width: 1500px) {
    display: contents;
  }
`;
