import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Body } from 'components';

export const Title = styled.p`
  font: normal normal 500 16px/19px Roboto;
  color: #0A0918;
  margin: 0;
  padding: 0;

  // text overflow
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const StyledCard = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-out;
  user-select: none; // prevent text highlight and image drag

  &:hover {
    box-shadow: 0px 3px 15px #00000019;
  }
`;

// Card Thumbnail Container

export const ThumbnailImage = styled.div`
  position: relative;
  background: #00000019 url(${({ src }) => src}) no-repeat padding-box;
  background-position: center;
  background-size: cover;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  height: 100%;
`;

export const TypeLabel = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0 15px;
  font: ${(props) => `normal normal normal 10px/22px ${props.theme.font}`};
  color: white;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 2px;

  @media only screen and (min-width: 800px) {
    top: 15px;
    right: 15px;
    font: ${(props) => `normal normal normal 10px/28px ${props.theme.font}`};
  }
`;

// Card Info Container

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #DEE2E6;
  border-right: 1px solid #DEE2E6; 
  border-bottom: 1px solid #DEE2E6;
  border-radius: 0 0 10px 10px;
  padding: 12px 10px;
  height: 95px;
  
  @media only screen and (min-width: 800px) {
    padding: 12px 16px 33px 16px;
    height: 150px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @media only screen and (min-width: 800px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const SmallIcon = styled.img`
  height: 10px;
  margin-right: 10px;

  @media only screen and (min-width: 800px) {
    height: 16px;
    margin-right: 20px;
  }
`;

export const Secondary = styled(Body)`
  color: ${(props) => props.theme.colors.secondary};
  margin: 0;
  padding: 0;

  // text overflow
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const Divider = styled(Secondary)`
  margin: 0 10px;
`;

export const Time = styled(Body)`
  color: ${(props) => (props.active ? props.theme.colors.green : props.theme.colors.primary)};
`;

// Cost/Rating Container for the star rating or the cost

export const CostRatingContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: auto;
  color: ${(props) => props.theme.colors.primary};
  p {
    font: ${(props) => `normal normal normal ${props.theme.mobileFontSizes[0]} ${props.theme.font}`};
    margin: 0;
    padding: 0;
  }

  @media only screen and (min-width: 800px) {
    p {
      font: ${(props) => `normal normal normal ${props.theme.fontSizes[0]} ${props.theme.font}`};
    }
  }
`;

export const StarIcon = styled.img`
  height: 14px;
  margin-right: 8px;
`;

// Like (heart) button

export const LikeButton = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  background: url(${(props) => (props.active ? 'inthecity/heart-active-icon.svg' : 'inthecity/heart-icon.svg')}) no-repeat padding-box;
  background-size: 13px;
  height: 12px;
  width: 13px;
  cursor: pointer;
  @media only screen and (min-width: 800px) {
    background-size: 24px;
    height: 22px;
    width: 24px;
  }
`;
