/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Image, TextField } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { shortenWithEllipses } from 'functions/address-utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import theme from 'theme';
import { GMBusiness, GMBusinessCategory } from 'types/types';
import { Text } from 'components/typography';
import { Loader } from 'components/inthecity';
import { decodeHtml, fixWordPressString } from 'functions/george-municipality';
import { SmallLoader } from 'components/private-services/survey';
import { Link } from 'react-router-dom';
import ExternalLink from 'components/elements/external-link';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setBusinessCategories,
  setCurrentBusinesses,
  setSearchTerm,
  setSelectedCategoryId,
} from 'redux/gm-business-directory';
import useDidMountEffect from 'hooks/use-did-mount-effect';

const BusinessListing = ({
  business,
  currentCategoryId,
}: {
  business: GMBusiness;
  currentCategoryId: number;
}) => {
  const imageUrl =
    business.images && business.images.length > 0
      ? fixWordPressString(business.images[0].src)
      : 'my-municipality/placeholder.webp';
  return (
    <Flex
      flexDirection="column"
      position="relative"
      mobileCss={css`
        padding: 10px;
        box-shadow: ${theme.cardShadow};
        border-radius: 4px;
      `}
    >
      <Link
        to={`/my-municipality/business-directory/663/single-listing/${business.id}`}
      >
        {currentCategoryId === 0 && (
          <Text
            backgroundColor="white"
            borderRadius="10px"
            p="5px"
            position="absolute"
            right="10px"
            top="10px"
            fontSize="8px"
            mobileCss={css`
              right: 20px;
              top: 20px;
            `}
          >
            {business?.categories[0]?.name || 'Uncategorized'}
          </Text>
        )}
        <Image
          source={imageUrl}
          alt="image"
          borderRadius="4px"
          width="100%"
          objectFit="cover"
          height="180px"
        />
      </Link>

      <Text my="10px" fontSize="16px">
        {decodeHtml(business.name)}
      </Text>
      {business.address && (
        <Flex>
          <Image
            source="my-municipality/location-pin.png"
            alt="phone-icon"
            mr="10px"
            width="15px"
          />{' '}
          <Text>{shortenWithEllipses(business.address, 27)}</Text>
        </Flex>
      )}
      {business.phone && (
        <Flex my="10px">
          <Image
            source="my-municipality/phone-filled.svg"
            alt="phone-icon"
            mr="10px"
            width="15px"
          />{' '}
          <ExternalLink
            href={`tel:${business.phone}`}
            hoverCss={css`
              color: ${theme.colors.primary};
            `}
          >
            {business.phone}
          </ExternalLink>
        </Flex>
      )}
      {business.email && (
        <Flex mb="10px">
          <Image
            source="my-municipality/email-blue.svg"
            alt="phone-icon"
            mr="10px"
            width="15px"
          />{' '}
          <ExternalLink
            href={`mailto:${business.email}`}
            hoverCss={css`
              color: ${theme.colors.primary};
            `}
          >
            {business.email}
          </ExternalLink>
        </Flex>
      )}
    </Flex>
  );
};

const BusinessDirectoryPage = () => {
  const {
    gmBusinesses: {
      businessCategories,
      selectedCategoryId,
      currentBusinesses,
      businessSearchTerm,
    },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState<GMBusiness[]>(currentBusinesses);
  const [categories, setCategories] =
    useState<GMBusinessCategory[]>(businessCategories);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useAppDispatch();

  const currentCategoryCount = categories.find(
    (c) => c.id === selectedCategoryId
  )?.count;
  const showLoadMoreButton =
    currentCategoryCount && currentCategoryCount > businesses.length;

  const categoryScrollerRef = useRef<HTMLDivElement>(null);

  const avgItemWidth = categoryScrollerRef.current
    ? categoryScrollerRef.current?.clientWidth / (categories.length + 1)
    : 0;

  const handleCategoryScroll = useCallback(
    (direction: 'left' | 'right') => {
      if (categoryScrollerRef.current && avgItemWidth > 0) {
        const scrollAmount =
          direction === 'left'
            ? -categoryScrollerRef.current.clientWidth
            : categoryScrollerRef.current.clientWidth;
        categoryScrollerRef.current.scrollTo({
          left: categoryScrollerRef.current.scrollLeft + scrollAmount * 0.8,
          behavior: 'smooth',
        });
      }
    },
    [categoryScrollerRef.current]
  );

  useEffect(() => {
    setLoadingCategories(true);
    if (currentBusinesses.length === 0) {
      setLoading(true);
      fetch(
        'https://visitgeorge.co.za/wp-json/directorist/v1/listings?per_page=12&orderby=popular&_fields=id,name,address,phone,images,categories,email'
      )
        .then((result) => result.json())
        .then((data) => {
          dispatch(setCurrentBusinesses(data));
          setBusinesses(data);
          setLoading(false);
        });
    }
    fetch('https://www.visitgeorge.co.za/wp-json/wp/v2/at_biz_dir-category')
      .then((result) => result.json())
      .then((cats) => {
        setCategories(cats);
        dispatch(setBusinessCategories(cats));
        setLoadingCategories(false);
      });
  }, []);

  useDidMountEffect(() => {
    setLoading(true);
    setBusinesses([]);
    selectedCategoryId !== -1 && dispatch(setSearchTerm(''));
    fetch(
      `https://visitgeorge.co.za/wp-json/directorist/v1/listings?per_page=12${
        selectedCategoryId !== 0
          ? `&categories=${selectedCategoryId}`
          : '&orderby=popular'
      }&_fields=id,name,address,phone,images,categories,email`
    )
      .then((result) => result.json())
      .then((data) => {
        dispatch(setCurrentBusinesses(data));
        setBusinesses(data);
        setLoading(false);
      });
  }, [selectedCategoryId]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    fetch(
      `https://visitgeorge.co.za/wp-json/directorist/v1/listings?per_page=12&offset=${
        businesses.length
      }${
        selectedCategoryId !== 0 ? `&categories=${selectedCategoryId}` : ''
      }&_fields=id,name,address,phone,images,categories,email`
    )
      .then((result) => result.json())
      .then((data) => {
        setBusinesses([...businesses, ...data]);
        dispatch(setCurrentBusinesses([...businesses, ...data]));
        setLoadingMore(false);
      });
  };

  const handleSearchAllListings = () => {
    setLoading(true);
    setBusinesses([]);
    fetch(
      `https://visitgeorge.co.za/wp-json/directorist/v1/listings?per_page=100&search=${businessSearchTerm}&_fields=id,name,address,phone,images,categories,email`
    )
      .then((result) => result.json())
      .then((data) => {
        setBusinesses(data);
        dispatch(setCurrentBusinesses(data));
        setLoading(false);
      });
  };

  return (
    <MunicipalPageWrapper title="Business Directory">
      <Flex width="100%" justifyContent="center" flexDirection="column">
        <Flex
          justifyContent="space-between"
          mobileCss={css`
            flex-direction: column;
          `}
        >
          <Flex width="100%" minWidth="30%">
            <Image
              source="blog/search.svg"
              width="20px"
              height="20px"
              alt="search"
              mr="10px"
              my="auto"
            />
            <TextField
              mr="16px"
              value={businessSearchTerm}
              onChange={(e) => dispatch(setSearchTerm(e.target.value))}
              placeholder="Quick search..."
              width="100%"
              mobileCss={css`
                width: 100%;
                margin-right: 0;
              `}
            />
            {businessSearchTerm && (
              <Button
                variant="outlined"
                mr="16px"
                onClick={() => {
                  handleSearchAllListings();
                  dispatch(setSelectedCategoryId(-1));
                }}
              >
                Search all
              </Button>
            )}
          </Flex>
          <Flex
            overflow="hidden"
            width="100%"
            borderB={`1px solid ${theme.colors.primary}`}
            mobileCss={css`
              width: 100%;
              margin: 10px 0;
            `}
          >
            <Button hideForMobile onClick={() => handleCategoryScroll('left')}>
              <Image
                source="icons/arrow-right-black.svg"
                opacity="0.7"
                width="30px"
                height="30px"
                alt="arrow-left"
                overrideCss={css`
                  transform: rotate(180deg);
                `}
              />
            </Button>
            <div
              ref={categoryScrollerRef}
              css={css`
                ::-webkit-scrollbar {
                  display: none;
                }
                overflow-x: scroll;
                display: flex;
              `}
            >
              {loadingCategories && categories.length === 0 ? (
                <Box my="10px" mx="auto">
                  <SmallLoader />
                </Box>
              ) : (
                <>
                  {selectedCategoryId === -1 && (
                    <Flex
                      mr="10px"
                      p="10px"
                      borderRadius="2px"
                      backgroundColor={theme.colors.selectionPrimary}
                      width="fit-content"
                      overrideCss={css`
                        white-space: nowrap;
                      `}
                      color={theme.colors.primary}
                    >
                      Search Results
                    </Flex>
                  )}
                  <Button
                    mr="10px"
                    p="10px"
                    borderRadius="2px"
                    backgroundColor={
                      selectedCategoryId === 0
                        ? theme.colors.selectionPrimary
                        : 'transparent'
                    }
                    width="fit-content"
                    overrideCss={css`
                      white-space: nowrap;
                    `}
                    color={theme.colors.primary}
                    onClick={() => dispatch(setSelectedCategoryId(0))}
                  >
                    Popular
                  </Button>
                  {categories.map((category, idx) => (
                    <Button
                      key={idx}
                      mr={idx === categories.length - 1 ? '0' : '10px'}
                      p="10px"
                      borderRadius="2px"
                      backgroundColor={
                        selectedCategoryId === category.id
                          ? theme.colors.selectionPrimary
                          : 'transparent'
                      }
                      width="fit-content"
                      overrideCss={css`
                        white-space: nowrap;
                      `}
                      color={theme.colors.primary}
                      onClick={() =>
                        dispatch(setSelectedCategoryId(category.id))
                      }
                    >
                      {category.name}&nbsp;({category.count})
                    </Button>
                  ))}
                </>
              )}
            </div>
            <Button hideForMobile onClick={() => handleCategoryScroll('right')}>
              <Image
                source="icons/arrow-right-black.svg"
                width="30px"
                opacity="0.7"
                height="30px"
                alt="arrow-left"
              />
            </Button>
          </Flex>
        </Flex>
        {loading && businesses.length === 0 ? (
          <Loader />
        ) : (
          <Flex flexDirection="column" width="100%" justifyContent="center">
            {businessSearchTerm !== '' &&
              businesses.filter((b) =>
                decodeHtml(b.name)
                  .toLowerCase()
                  .includes(businessSearchTerm.toLowerCase())
              ).length === 0 && (
                <Text
                  textAlign="center"
                  fontSize="16px"
                  overrideCss={css`
                    &:first-child {
                      margin-top: 50px;
                    }
                  `}
                >
                  No quick search results. To view more results,&nbsp;
                  <Button
                    color={theme.colors.primary}
                    fontWeight="bold"
                    onClick={() => {
                      handleSearchAllListings();
                      dispatch(setSelectedCategoryId(-1));
                    }}
                  >
                    search all businesses
                  </Button>
                  .
                </Text>
              )}
            <Grid
              mt="20px"
              gridTemplateColumns="repeat(4, 1fr)"
              gridColumnGap="15px"
              gridRowGap="15px"
              tabletCss={css`
                grid-template-columns: repeat(3, 1fr);
              `}
              mobileCss={css`
                grid-template-columns: 1fr;
              `}
            >
              {businessSearchTerm === ''
                ? businesses.map((business) => (
                    <BusinessListing
                      business={business}
                      key={business.id}
                      currentCategoryId={selectedCategoryId}
                    />
                  ))
                : businesses
                    .filter((b) =>
                      decodeHtml(b.name)
                        .toLowerCase()
                        .includes(businessSearchTerm.toLowerCase())
                    )
                    .map((business) => (
                      <BusinessListing
                        business={business}
                        key={business.id}
                        currentCategoryId={selectedCategoryId}
                      />
                    ))}
            </Grid>
            {showLoadMoreButton && (
              <>
                {loadingMore ? (
                  <Box mx="auto" mt="20px">
                    <SmallLoader />
                  </Box>
                ) : (
                  <Button
                    variant="cta"
                    mt="20px"
                    width="fit-content"
                    mx="auto"
                    onClick={() => handleLoadMore()}
                  >
                    Load More...
                  </Button>
                )}
              </>
            )}
          </Flex>
        )}
      </Flex>
    </MunicipalPageWrapper>
  );
};

export default BusinessDirectoryPage;
