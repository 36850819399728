import {
  getNotes,
  addWorkRequestNote,
  getChildOrgUnits,
  getAllWorkTypesForOrgUnit,
} from 'interface/map';

export const updateWorkRequestNotes = async (
  workRequestId,
  setState,
  subscriberId
) => {
  const temp = await getNotes(workRequestId, subscriberId);
  setState({ currentNotes: temp });
};

export const updateAndAddWorkRequestNote = async (workRequestId, value) => {
  console.log('updateAndAddWorkRequestNote', value);
  await addWorkRequestNote(workRequestId, value);
};

export const getChildOrgUnitsAndUpdate = async (
  setMSOUChildList,
  setMSOUChildWorkTypesList,
  setMSOUChildWorkTypesChanged,
  value
) => {
  setMSOUChildList([]);
  setMSOUChildWorkTypesList([]);
  console.log('getChildOrgUnitsAndUpdate', value);
  const temp = await getChildOrgUnits(value);
  setMSOUChildList(temp.data);
  setMSOUChildWorkTypesChanged(true);
};

export const getAllWorkTypesForOrgUnitAndUpdate = async (
  setMSOUChildWorkTypesList,
  value
) => {
  console.log('getChildOrgUnitsAndUpdate', value);
  const temp = await getAllWorkTypesForOrgUnit(value);
  console.log('getAllWorkTypesForOrgUnit:', getAllWorkTypesForOrgUnit);
  setMSOUChildWorkTypesList(temp);
};

export function getRootOrgUnit(orgUnits, id) {
  let counter = 0;
  if (!orgUnits) {
    return {};
  }
  while (counter < orgUnits.length) {
    if (
      orgUnits[counter].value === id &&
      orgUnits[counter].attributes.parentId.localeCompare('0') === 0
    ) {
      return orgUnits[counter];
    }
    if (orgUnits[counter].value === id) {
      return getRootOrgUnit(
        orgUnits,
        parseInt(orgUnits[counter].attributes.parentId, 10)
      );
    }
    counter += 1;
  }
  return {};
}

export function isMarkerInsidePolygon(pos, poly) {
  const polyPoints = poly;
  const x = pos.lat;
  const y = pos.lng;
  let inside = false;
  for (
    let i = 0, j = polyPoints.length - 1;
    i < polyPoints.length;
    j = i, i += 1
  ) {
    const xi = polyPoints[i][0];
    const yi = polyPoints[i][1];
    const xj = polyPoints[j][0];
    const yj = polyPoints[j][1];

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export function findObject(object, value, pass) {
  if (pass) {
    return object;
  }
  console.log(`Looking for ${value} in`, object);
  let temp = '';
  if (!object.length) {
    if (
      object.value === value ||
      `${object.value}`.localeCompare(value) === 0
    ) {
      console.log('FOUND!1', object);
      return object;
    }
  } else {
    for (let i = 0; i < object.length; i++) {
      if (
        object[i].value === value ||
        `${object[i].value}`.localeCompare(value) === 0
      ) {
        console.log('FOUND!2', object[i]);
        return object[i];
      }
    }
  }
  // Go lower ...
  if (!object.length) {
    if ('children' in object) {
      temp = findObject(object.children, value);
    } else if ('workTypes' in object) {
      temp = findObject(object.workTypes, value);
    }
  } else {
    for (let i = 0; i < object.length; i++) {
      if ('children' in object[i]) {
        temp = findObject(object[i].children, value);
        if (temp !== '') {
          break;
        }
      } else if ('workTypes' in object[i]) {
        temp = findObject(object[i].workTypes, value);
        if (temp !== '') {
          break;
        }
      }
    }
  }
  return temp;
}

export const getMapBounds = (allLabelsTemp) => {
  // Get the wards
  let wardOrgUnit = {};
  for (let i = 0; i < allLabelsTemp.orgUnitTypes.length; i++) {
    if (allLabelsTemp.orgUnitTypes[i].label === 'Ward') {
      wardOrgUnit = allLabelsTemp.orgUnitTypes[i];
    }
  }

  const wards = [];
  for (let i = 0; i < allLabelsTemp.orgUnits.length; i++) {
    if (
      allLabelsTemp.orgUnits[i].attributes.orgUnitTypeId ===
      `${wardOrgUnit.value}`
    ) {
      wards.push(allLabelsTemp.orgUnits[i]);
    }
  }

  // Get the cities
  let cityOrgUnit = {};
  for (let i = 0; i < allLabelsTemp.orgUnitTypes.length; i++) {
    if (allLabelsTemp.orgUnitTypes[i].label === 'City') {
      cityOrgUnit = allLabelsTemp.orgUnitTypes[i];
    }
  }

  const city = [];
  for (let i = 0; i < allLabelsTemp.orgUnits.length; i++) {
    if (
      allLabelsTemp.orgUnits[i].attributes.orgUnitTypeId ===
      `${cityOrgUnit.value}`
    ) {
      city.push(allLabelsTemp.orgUnits[i]);
    }
  }

  console.log('city:', city);
  // There should only be one city, which is the current city.
  // format the cities
  let cityBounds = [{}];
  if (city[0]) {
    cityBounds = JSON.parse(city[0].attributes.geometry).geometries;
    // console.log('city bounds:', cityBounds);
  } else {
    cityBounds = [{}];
  }

  // format the wards
  for (let i = 0; i < wards.length; i++) {
    setTimeout(() => {
      wards[i].attributes.geometry = JSON.parse(
        wards[i].attributes.geometry
      ).geometries;
    }, 100);
  }
  console.log('wards Length', wards.length);
  return { wards, cityBounds };
};
