/* Modules */
import React from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
/* Local */
import 'styles/notifications.scss';
import { authenticate } from 'functions/app';
import Footer from 'components/footer';
import {
  getNotificationSettings,
  updateNotificationSettings,
  markNotificationRead,
  getNotifications,
  markAllNotificationsAsRead,
  getWorkRequest,
} from 'interface/notifications';
import Moment from 'react-moment';
import { Scrollbars } from 'react-custom-scrollbars';
import Toggle from 'react-bootstrap-toggle';
import _ from 'lodash';
import { apiBaseUrlBase } from 'constants/app';
import HistoryPush from 'components/historyPush';

import { getAvailableCities } from 'interface/map';
import moment from 'moment-timezone';

export default class Notifications extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.hiddenFileInput = React.createRef();
    this.editorRef = React.createRef();
    this.state = {
      readyToRedirect: false,
      redirectTo: '',
      search: '',
      notificationsAll: [],
      notifications: [],
      settingsSection: 'Municipal',
      notificationSettings: [],
      currentNotification: {},
      currentNotificationSubscriberId: '',
      selectedNotification: {},
      pushTo: '',
      showGlobalNotifications: false,
      globalNotificationSetting: {
        email: false,
        escalatedByMe: false,
        loggedByMe: false,
        nearMe: false,
        upvotedByMe: false,
      },
      globalNotificationSettingToggle: false,
      showMenu: false,
      showNotification: false,
      notificationSettingsActive: {},
    };
  }

  getWorkRequestForNotification(n, availableCities, notifications) {
    // Find the subscriber ID for thecity
    let subscriberId = '';
    for (let i = 0; i < availableCities.length; i++) {
      if (n.cityId === availableCities[i].value) {
        subscriberId = availableCities[i].attributes.subscriberId;
        break;
      }
    }
    getWorkRequest(n.workRequestId, subscriberId).then((res) => {
      this.setState({
        currentNotification: res,
        currentNotificationSubscriberId: subscriberId,
      });
      if (!n.read) {
        // Mark the notifcation as read
        markNotificationRead(n.uuid);
        // Set the current notification to read.
        console.log('notifications', notifications);
        const notificationsTemp = _.cloneDeep(notifications);
        for (let i = 0; i < notificationsTemp.length; i++) {
          if (notificationsTemp[i].uuid === n.uuid) {
            notificationsTemp[i].read = true;
            break;
          }
        }
        this.setState({ notifications: notificationsTemp });
      }
    });
    this.setState({ selectedNotification: n });
  }

  componentDidMount = async () => {
    const { tab } = this.props;
    // Auth routing
    // authenticate
    const { authenticatedState, currentUser } = await authenticate();

    // Available Locations
    const availableCities = await getAvailableCities();

    console.log('authenticatedState', authenticatedState);
    if (!authenticatedState) {
      this.setState({ readyToRedirect: true, redirectTo: 'map' });
    }

    const notifications = await getNotifications();
    notifications.sort((a, b) => (b.createdDate > a.createdDate && 1) || -1);
    // if (notifications.length > 0) {
    //   this.getWorkRequestForNotification(notifications[0],
    //     availableCities, notifications);
    // }
    const notificationSettings = (await getNotificationSettings()).data;
    console.log('notificationSettings: ', notificationSettings);
    console.log('currentUser', currentUser);
    if (!tab) {
      this.setState({
        pushTo: '/notifications/recent',
        notificationSettings,
        availableCities,
        notifications,
        notificationsAll: notifications,
      });
    } else {
      this.setState({
        notificationSettings,
        availableCities,
        notifications,
        notificationsAll: notifications,
      });
    }
  };

  setNotificationSetting = async (setting, key) => {
    const { notificationSettings } = this.state;
    for (let i = 0; i < notificationSettings.length; i++) {
      if (notificationSettings[i].referenceCode === setting.referenceCode) {
        const temp = _.cloneDeep(notificationSettings);
        temp[i][key] = !temp[i][key];
        this.setState({ notificationSettings: temp });
        updateNotificationSettings(JSON.stringify(temp));
        break;
      }
    }
  };

  setGlobalNotificationSetting = async (key, value) => {
    const { notificationSettings } = this.state;
    const temp = _.cloneDeep(notificationSettings);
    for (let i = 0; i < notificationSettings.length; i++) {
      temp[i][key] = value;
    }
    this.setState({ notificationSettings: temp });
    updateNotificationSettings(JSON.stringify(temp));
  };

  render() {
    const { tab } = this.props;
    const {
      readyToRedirect,
      redirectTo,
      search,
      notifications,
      settingsSection,
      notificationSettings,
      notificationsAll,
      currentNotification,
      currentNotificationSubscriberId,
      availableCities,
      selectedNotification,
      pushTo,
      showGlobalNotifications,
      globalNotificationSetting,
      globalNotificationSettingToggle,
      showMenu,
      notificationSettingsActive,
      showNotification,
    } = this.state;
    if (readyToRedirect && redirectTo !== '') {
      return <Navigate to={`/${redirectTo}`} />;
    }

    let tempReports = 0;
    let tempPetitions = 0;
    for (let i = 0; i < notifications.length; i++) {
      if (`${notifications[i].custom1}` === 'null') {
        tempReports += 1;
      } else {
        tempPetitions += 1;
      }
    }
    console.log(showMenu, showNotification);
    return (
      <>
        <HistoryPush pushTo={pushTo} setState={(s) => this.setState(s)} />
        <Row className="notificationsHeader hideOnDesktop ">
          Notifications inbox
          {showMenu ? (
            <div
              style={{ display: 'contents' }}
              role="button"
              tabIndex={0}
              onClick={() => this.setState({ showMenu: false })}
            >
              <img
                src="notifications/crossShadow.svg"
                alt="close menu icon"
                style={{
                  height: '25px',
                  float: 'right',
                  cursor: 'pointer',
                }}
              />
            </div>
          ) : (
            <div
              style={{ display: 'contents' }}
              role="button"
              tabIndex={0}
              onClick={() => this.setState({ showMenu: true })}
            >
              <img
                src="notifications/hamburgerShadow.svg"
                alt="hamburger menu icon"
                style={{
                  height: '25px',
                  float: 'right',
                  cursor: 'pointer',
                }}
              />
            </div>
          )}
        </Row>

        <Container fluid className="p-0 m-0">
          <Row className="p-0 m-0">
            <Col md={2} className="p-0">
              <div className={showMenu ? 'menu menuShow' : 'menu'}>
                <div className="menuSearch">
                  <img
                    className="menuSearchIconMag"
                    alt="FavIcon"
                    src="map/magnifyingGlassIcon.svg"
                  />
                  <Form.Control
                    className="menuSearchInput"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        this.setState({
                          search: e.target.value,
                          pushTo: '/notifications/recent',
                          notifications: notificationsAll,
                        });
                      } else {
                        const temp = [];
                        for (let i = 0; i < notifications.length; i++) {
                          if (
                            notifications[i].typeDescription.includes(
                              e.target.value
                            )
                          ) {
                            temp.push(notifications[i]);
                          }
                        }
                        this.setState({
                          search: e.target.value,
                          pushTo: 'notifications/search',
                          notifications: temp,
                        });
                      }
                    }}
                  />
                  {search !== '' ? (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        this.setState({
                          search: '',
                          pushTo: '/notifications/recent',
                          notifications: notificationsAll,
                          showMenu: false,
                        })
                      }
                    >
                      <img
                        className="searchIconCross"
                        alt="FavIcon"
                        src="map/cross.svg"
                      />
                    </div>
                  ) : null}
                </div>
                <div
                  className="menuItem"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({
                      pushTo: '/notifications/recent',
                      showMenu: false,
                    });
                  }}
                  style={
                    tab === 'recent' ? { backgroundColor: '#474747' } : null
                  }
                >
                  <img
                    src="/notifications/sidebar/recent.svg"
                    className="sidebarImage"
                    alt="recent"
                  />
                  Recent
                  {notifications.length !== 0 ? (
                    <div className="notificationCount">
                      {notifications.length}
                    </div>
                  ) : null}
                </div>
                <div
                  className="menuItem"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({
                      pushTo: '/notifications/reports',
                      showMenu: false,
                    });
                  }}
                  style={
                    tab === 'reports' ? { backgroundColor: '#474747' } : null
                  }
                >
                  <img
                    src="/notifications/sidebar/reports.svg"
                    className="sidebarImage"
                    alt="recent"
                  />
                  Reports
                  {tempReports !== 0 ? (
                    <div className="notificationCount">{tempReports}</div>
                  ) : null}
                </div>
                <div
                  className="menuItem"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({
                      pushTo: '/notifications/petitions',
                      showMenu: false,
                    });
                  }}
                  style={
                    tab === 'petitions' ? { backgroundColor: '#474747' } : null
                  }
                >
                  <img
                    src="/notifications/sidebar/petitions.svg"
                    className="sidebarImage"
                    alt="recent"
                  />
                  Petitions
                  {tempPetitions ? (
                    <div className="notificationCount">{tempPetitions}</div>
                  ) : null}
                </div>
                <div
                  className="menuItem"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({
                      pushTo: '/notifications/settings',
                      showMenu: false,
                    });
                  }}
                  style={
                    tab === 'Settings'
                      ? { backgroundColor: '#474747', marginTop: '50px' }
                      : { marginTop: '50px' }
                  }
                >
                  <img
                    src="/notifications/sidebar/settings.svg"
                    className="sidebarImage"
                    alt="recent"
                  />
                  Settings
                </div>
              </div>
            </Col>
            <Col className="formContainers p-0" md={10} sm={12}>
              <Row className="notificationsHeader hideOnMobile m-0">
                Notifications inbox
              </Row>
              <Row className="notificationsBody m-0">
                {tab === 'recent' ||
                tab === 'reports' ||
                tab === 'petitions' ||
                tab === 'search' ? (
                  <div style={{ display: 'contents' }}>
                    <Col lg={6} className=" p-0">
                      <div className="notificationsSubHeaderList">
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </div>
                      <Scrollbars style={{ height: '80vh' }}>
                        {notifications.map((n) => {
                          if (tab === 'petitions' && !n.custom1) {
                            return null;
                          }
                          if (tab === 'reports' && n.custom1) {
                            return null;
                          }
                          return (
                            <Row
                              className="notificationItem"
                              role="button"
                              tabIndex={0}
                              style={
                                n === selectedNotification
                                  ? { backgroundColor: ' #d2e9f1' }
                                  : null
                              }
                              onClick={() => {
                                this.getWorkRequestForNotification(
                                  n,
                                  availableCities,
                                  notifications
                                );
                              }}
                            >
                              <Col xs={2} className="pl-0">
                                <div
                                  className="notificationImageContainer"
                                  style={
                                    n.read ? { backgroundColor: 'grey' } : null
                                  }
                                >
                                  <img
                                    src="/notifications/sidebar/settings.svg"
                                    alt="recent"
                                  />
                                </div>
                              </Col>
                              <Col xs={9} sm={9} className="pr-0">
                                <div className="notificationInfo">
                                  Your report on the
                                  <div
                                    style={{
                                      fontFamily: 'GothamMedium',
                                      display: 'contents',
                                    }}
                                  >
                                    {n.custom1
                                      ? ` ${n.description} `
                                      : ` ${n.typeDescription} `}
                                  </div>
                                  on
                                  <div
                                    style={{
                                      fontFamily: 'GothamMedium',
                                      display: 'contents',
                                    }}
                                  >
                                    {` ${n.address.split(',')[0]},  ${
                                      n.address.split(',')[1]
                                    } `}
                                  </div>
                                  has been updated:{' '}
                                  <div
                                    style={{
                                      fontFamily: 'GothamMedium',
                                      display: 'contents',
                                    }}
                                  >
                                    {n.reason}
                                  </div>
                                  <div
                                    style={
                                      !n.read
                                        ? {
                                            fontFamily: 'GothamMedium',
                                            color: '#df6e21',
                                            fontStyle: 'italic',
                                            fontWeight: 'bold',
                                            marginTop: '10px',
                                          }
                                        : {
                                            fontFamily: 'GothamMedium',
                                            color: 'grey',
                                            fontStyle: 'italic',
                                            fontWeight: 'bold',
                                            marginTop: '10px',
                                          }
                                    }
                                  >
                                    about{' '}
                                    <Moment
                                      tz={moment.tz.guess()}
                                      date={moment.unix(n.createdDate / 1000)}
                                      format="D MMMM YYYY"
                                      interval={100}
                                      fromNow
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col xs={1} className="pr-0">
                                <div className="notificationRead">
                                  <img
                                    src="notifications/roundArrowRight.svg"
                                    alt="Logo"
                                    style={
                                      n.read
                                        ? {
                                            height: '24px',
                                          }
                                        : {
                                            height: '24px',
                                            filter:
                                              'invert(67%) sepia(38%) saturate(7175%) hue-rotate(349deg) brightness(92%) contrast(89%)',
                                          }
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      </Scrollbars>
                    </Col>
                    <Col
                      md={6}
                      className={
                        currentNotification.typeDescription
                          ? 'menuRight menuRightShow p-0 '
                          : 'menuRight p-0 '
                      }
                      style={{
                        backgroundColor: '#ededed',
                        minHeight: '100%',
                      }}
                    >
                      <Row className="m-0">
                        <Col xs={12} className="p-0">
                          <div className="currentNotificationHeading">
                            <Row className="m-0">
                              {currentNotification.typeDescription ? (
                                <Col
                                  className="pl-0"
                                  xs={1}
                                  sm={1}
                                  onClick={() => {
                                    this.setState({ currentNotification: {} });
                                  }}
                                >
                                  <img
                                    src="notifications/roundArrowLeft.svg"
                                    alt="Logo"
                                    style={{
                                      height: '24px',
                                      paddingRight: '10px',
                                      top: 'calc(50% - 12px)',
                                      position: 'relative',
                                    }}
                                  />
                                </Col>
                              ) : null}
                              <Col xs={7} sm={7} style={{ margin: 'auto' }}>
                                {currentNotification.typeDescription
                                  ? currentNotification.typeDescription
                                  : 'No notification selected'}
                              </Col>
                              <Col xs={4} sm={4} className="p-0">
                                {currentNotification.typeDescription ? (
                                  <Link to="/map">
                                    <Button className="homeButtonNoFloat viewButton">
                                      VIEW
                                    </Button>
                                  </Link>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md={8} className="p-0">
                          {currentNotification.typeDescription ? (
                            <div>
                              <div className="currentNotificationContainer">
                                <div className="currentNotificationInfo">
                                  <div className="currentNotificationInfoBlock">
                                    Issue number:{' '}
                                    <div className="textAccentOrange">
                                      {currentNotification.code}
                                    </div>
                                    <div
                                      className="float-right"
                                      style={{ fontFamily: 'GothamMedium' }}
                                    >
                                      {currentNotification.upVoteCount}
                                      <img
                                        src="notifications/upArrow.svg"
                                        alt="Up arrow"
                                        style={{
                                          filter:
                                            'invert(45%) sepia(12%) saturate(2531%) hue-rotate(148deg) brightness(105%) contrast(100%)',
                                          height: '20px',
                                          top: '-2px',
                                          position: 'relative',
                                          marginLeft: '5px',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="currentNotificationInfoBlock">
                                    {currentNotification.address}
                                  </div>
                                  <div className="currentNotificationInfoBlock">
                                    <Moment
                                      tz={moment.tz.guess()}
                                      format="D MMMM YYYY"
                                      date={moment.unix(
                                        currentNotification.createdDate / 1000
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="currentNotificationContainer">
                                <div className="currentNotificationTitle">
                                  Progress status
                                </div>
                                <div className="currentNotificationInfo">
                                  {currentNotification.statusDescription}
                                </div>
                              </div>
                              <div className="currentNotificationContainer">
                                <div className="currentNotificationTitle">
                                  Photos
                                </div>
                                <Row
                                  style={{
                                    margin: '0px 0px 10px',
                                    overflowX: 'auto',
                                    overflowY: 'hidden',
                                    padding: '10px 0px',
                                    flexWrap: 'initial',
                                  }}
                                >
                                  {currentNotification.imageIds &&
                                    currentNotification.imageIds.map(
                                      (val, i) => (
                                        <a
                                          rel="noopener noreferrer"
                                          href={`${apiBaseUrlBase}modal/viewimagefile.html?id=${val}&subscriberId=${currentNotificationSubscriberId}`}
                                          target="_blank"
                                        >
                                          <div
                                            className="imageBlock"
                                            role="button"
                                            tabIndex={0}
                                            alt="test"
                                            key={`imageIds-${i}`}
                                            style={{
                                              background: `url('${apiBaseUrlBase}modal/viewimagefile.html?id=${val}&subscriberId=${currentNotificationSubscriberId}')`,
                                            }}
                                          />
                                        </a>
                                      )
                                    )}
                                </Row>
                              </div>
                            </div>
                          ) : null}
                        </Col>
                        <Col md={4} />
                      </Row>
                    </Col>
                  </div>
                ) : null}

                {tab === 'settings' ? (
                  <Col md={7} className="p-0">
                    <div className="notificationsSubHeaderList">
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </div>
                    <div className="notificationsSettings">
                      <div className="notificationsSubSubHeader">Filters</div>
                      <div className="notificationText">
                        Set which notifications you would like to receive based
                        on both the municipal and petitions categories. Click on
                        edit preferences in one of the smaller tiles to toggle
                        the notifications on or off.
                      </div>

                      <div className="settingsContainer">
                        <div className="settingsHeader">
                          <div
                            className={
                              settingsSection === 'Municipal'
                                ? 'SelectedSettingText SettingText'
                                : 'SettingText'
                            }
                            onClick={() =>
                              this.setState({ settingsSection: 'Municipal' })
                            }
                            role="button"
                            tabIndex={0}
                          >
                            Municipal
                          </div>
                          <div className="textAccentOrange SettingText">
                            {' '}
                            |{' '}
                          </div>
                          <div
                            className={
                              settingsSection === 'Petition'
                                ? 'SelectedSettingText SettingText'
                                : 'SettingText'
                            }
                            onClick={() =>
                              this.setState({ settingsSection: 'Petition' })
                            }
                            role="button"
                            tabIndex={0}
                          >
                            Petition
                          </div>
                          <div className="notificationGlobalContainer ">
                            <div
                              className={
                                showGlobalNotifications
                                  ? 'notificationGlobal notificationGlobalSelected'
                                  : 'notificationGlobal'
                              }
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                this.setState({
                                  showGlobalNotifications:
                                    !showGlobalNotifications,
                                });
                              }}
                            >
                              <img
                                src="/notifications/sidebar/settings.svg"
                                className="notificationGlobalIcon"
                                alt="recent"
                              />
                            </div>
                            {showGlobalNotifications ? (
                              <div className="globalNotificationPopupContainer">
                                <div className="globalNotificationPopup">
                                  <div style={{ fontFamily: 'GothamMedium' }}>
                                    Global notification settings
                                  </div>
                                  <div className="globalToggleContainer">
                                    <div className="globalToggleSettingsGlobal">
                                      <Toggle
                                        className="toggleSettings"
                                        onClick={() => {
                                          if (
                                            !globalNotificationSettingToggle
                                          ) {
                                            // Set all of the settings to off
                                          }
                                          this.setState({
                                            globalNotificationSettingToggle:
                                              !globalNotificationSettingToggle,
                                          });
                                        }}
                                        width={15}
                                        height={15}
                                        on=""
                                        off=""
                                        active={globalNotificationSettingToggle}
                                      />
                                      Enable global settings
                                      <hr />
                                    </div>
                                    <div className="globalToggleSettings">
                                      <Toggle
                                        className="toggleSettings"
                                        onClick={() =>
                                          this.setGlobalNotificationSetting(
                                            globalNotificationSetting,
                                            'email'
                                          )
                                        }
                                        width={15}
                                        height={15}
                                        on=""
                                        off=""
                                        active={globalNotificationSetting.email}
                                      />
                                      Email notifications
                                    </div>
                                    <div className="globalToggleSettings">
                                      <Toggle
                                        className="toggleSettings"
                                        onClick={() =>
                                          this.setGlobalNotificationSetting(
                                            globalNotificationSetting,
                                            'escalatedByMe'
                                          )
                                        }
                                        width={15}
                                        height={15}
                                        on=""
                                        off=""
                                        active={
                                          globalNotificationSetting.escalatedByMe
                                        }
                                      />
                                      Self-escalated
                                    </div>
                                    <div className="globalToggleSettings">
                                      <Toggle
                                        className="toggleSettings"
                                        onClick={() =>
                                          this.setGlobalNotificationSetting(
                                            globalNotificationSetting,
                                            'loggedByMe'
                                          )
                                        }
                                        width={15}
                                        height={15}
                                        on=""
                                        off=""
                                        active={
                                          globalNotificationSetting.loggedByMe
                                        }
                                      />
                                      Logged by me
                                    </div>
                                    <div className="globalToggleSettings">
                                      <Toggle
                                        className="toggleSettings"
                                        onClick={() =>
                                          this.setGlobalNotificationSetting(
                                            globalNotificationSetting,
                                            'nearMe'
                                          )
                                        }
                                        width={15}
                                        height={15}
                                        on=""
                                        off=""
                                        active={
                                          globalNotificationSetting.nearMe
                                        }
                                      />
                                      Near me
                                    </div>
                                    <div className="globalToggleSettings">
                                      <Toggle
                                        className="toggleSettings"
                                        onClick={() =>
                                          this.setGlobalNotificationSetting(
                                            globalNotificationSetting,
                                            'upvotedByMe'
                                          )
                                        }
                                        width={15}
                                        height={15}
                                        on=""
                                        off=""
                                        active={
                                          globalNotificationSetting.upvotedByMe
                                        }
                                      />
                                      Upvoted by me
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="settingsBody">
                          <Row
                            style={{
                              marginRight: '-10px',
                              marginLeft: '-10px',
                              marginBottom: '-20px',
                            }}
                          >
                            {notificationSettings.map((ns) => {
                              let currentNotificationSetting;
                              if (
                                settingsSection === 'Municipal' &&
                                ns.referenceType === 'OrgUnit'
                              ) {
                                currentNotificationSetting = ns;
                              } else if (
                                settingsSection === 'Petition' &&
                                ns.referenceType === 'Petition'
                              ) {
                                currentNotificationSetting = ns;
                              } else {
                                return null;
                              }
                              return (
                                <Col
                                  sm={6}
                                  md={6}
                                  lg={3}
                                  style={{
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                  }}
                                >
                                  <div className="flip-card">
                                    <div
                                      className={
                                        notificationSettingsActive ===
                                        ns.referenceDescription
                                          ? 'flip-card-inner flippityFlop'
                                          : 'flip-card-inner'
                                      }
                                    >
                                      <div
                                        className="flip-card-front"
                                        tabIndex={0}
                                        role="button"
                                        onClick={() => {
                                          if (
                                            ns.referenceDescription ===
                                            notificationSettingsActive
                                          ) {
                                            this.setState({
                                              notificationSettingsActive: '',
                                            });
                                          } else {
                                            this.setState({
                                              notificationSettingsActive:
                                                ns.referenceDescription,
                                            });
                                          }
                                        }}
                                      >
                                        <img
                                          src={`${apiBaseUrlBase}/images/custom/${
                                            currentNotificationSetting.customIcon.split(
                                              '.'
                                            )[0]
                                          }.${
                                            currentNotificationSetting.customIcon.split(
                                              '.'
                                            )[1]
                                          }?cityId=${
                                            currentNotificationSetting.cityId
                                          }`}
                                          alt="Avatar"
                                          className="notificationSettingImage"
                                          style={{
                                            filter:
                                              'invert(47%) sepia(85%) saturate(1261%) hue-rotate(350deg) brightness(93%) contrast(87%)',
                                          }}
                                        />
                                        <div className="notificationSettingTitle">
                                          {
                                            currentNotificationSetting.referenceDescription
                                          }
                                        </div>
                                        <div className="notificationSettingEdit">
                                          Edit preferences
                                        </div>
                                      </div>
                                      <div className="flip-card-back">
                                        <div
                                          className="closeSettings showOnMobile"
                                          tabIndex={0}
                                          aria-label="close"
                                          role="button"
                                          onClick={() => {
                                            this.setState({
                                              notificationSettingsActive: '',
                                            });
                                          }}
                                        >
                                          <img
                                            src="notifications/crossShadowBlack.svg"
                                            alt="close menu icon"
                                            style={{
                                              height: '15px',
                                              float: 'right',
                                              cursor: 'pointer',
                                            }}
                                          />
                                        </div>

                                        <div>
                                          <Toggle
                                            className="toggleSettings"
                                            onClick={() =>
                                              this.setNotificationSetting(
                                                currentNotificationSetting,
                                                'email'
                                              )
                                            }
                                            width={15}
                                            height={8}
                                            on=""
                                            off=""
                                            active={
                                              currentNotificationSetting.email
                                            }
                                          />
                                          Email notifications
                                        </div>
                                        <div>
                                          <Toggle
                                            className="toggleSettings"
                                            onClick={() =>
                                              this.setNotificationSetting(
                                                currentNotificationSetting,
                                                'escalatedByMe'
                                              )
                                            }
                                            width={15}
                                            height={8}
                                            on=""
                                            off=""
                                            active={
                                              currentNotificationSetting.escalatedByMe
                                            }
                                          />
                                          Self-escalated
                                        </div>
                                        <div>
                                          <Toggle
                                            className="toggleSettings"
                                            onClick={() =>
                                              this.setNotificationSetting(
                                                currentNotificationSetting,
                                                'loggedByMe'
                                              )
                                            }
                                            width={15}
                                            height={8}
                                            on=""
                                            off=""
                                            active={
                                              currentNotificationSetting.loggedByMe
                                            }
                                          />
                                          Logged by me
                                        </div>
                                        <div>
                                          <Toggle
                                            className="toggleSettings"
                                            onClick={() =>
                                              this.setNotificationSetting(
                                                currentNotificationSetting,
                                                'nearMe'
                                              )
                                            }
                                            width={15}
                                            height={8}
                                            on=""
                                            off=""
                                            active={
                                              currentNotificationSetting.nearMe
                                            }
                                          />
                                          Near me
                                        </div>
                                        <div>
                                          <Toggle
                                            className="toggleSettings"
                                            onClick={() =>
                                              this.setNotificationSetting(
                                                currentNotificationSetting,
                                                'upvotedByMe'
                                              )
                                            }
                                            width={15}
                                            height={8}
                                            on=""
                                            off=""
                                            active={
                                              currentNotificationSetting.upvotedByMe
                                            }
                                          />
                                          Upvoted by me
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </div>

                      <div className="notificationsSubSubHeader">
                        Unsubscribe from CRM
                      </div>
                      <div className="notificationText">
                        <div className="textAccentOrange">Unsubscribe</div>
                      </div>
                      <div className="notificationsSubSubHeader">
                        Clear all notifications
                      </div>
                      <div className="notificationText">
                        <div
                          className="textAccentOrange"
                          style={{ cursor: 'pointer' }}
                          role="button"
                          tabIndex={0}
                          onClick={() => markAllNotificationsAsRead()}
                        >
                          Clear all
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : null}
              </Row>
              {/* {tab === 'recent' ? <Recent state={this.state} /> : null } */}
            </Col>
          </Row>
          <Footer />
        </Container>
      </>
    );
  }
}
