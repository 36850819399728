import { css } from '@emotion/react';
import { AddressSelectionModal } from 'components/address-manager';
import BookingSummary from 'components/private-services/bookings/booking-summary';
import ServiceDetails from 'components/private-services/bookings/service-details';
import { Button, Image, Modal } from 'components/elements';
import { Loader } from 'components/inthecity';
import { Box, Flex } from 'components/layout';
import PrivateServicesBreadcrumbs from 'components/private-services/breadcrumbs';
import { Text } from 'components/typography';
import { useMakeABookingContext } from 'contexts/booking';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import theme from 'theme';
import { CustomerPremises, PeachPaymentStatusResponse } from 'types/types';
import ProgressBar from 'components/progress-bar';
import { peachSuccessCode } from 'constants/peach';
import { firstDateToSchedule } from 'components/scheduling-assistant/utils';
import { toUnix } from 'functions/date';
import {
  formatForcelinkAddress,
  shortenWithEllipses,
} from 'functions/address-utils';
import useQuery from 'hooks/use-query';
import { PrivateServicesEvents, logFirebaseEvent } from 'functions/firebase';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { clearUserBooking, setAddress } from 'redux/booking';
import { setMinimumCurrentValues } from 'functions/private-services';
import { useGlobalContext } from 'contexts/global';

const progressBarSteps = ['Pick service', 'Details', 'Checkout', 'Payment'];

const BookAServicePage = () => {
  const {
    booking,
    setServiceOptionCurrent,
    setBooking,
    price,
    totalDuration,
    setBookingDuration,
    setResource,
    setBookingTimes,
    setBookingStep,
    bookingStep,
  } = useMakeABookingContext();
  const { globalState, setShowLoginModal } = useGlobalContext();
  const { serviceId, heading } = useParams();
  const { loading, callApi, response } = useSmartCityManager(
    SCManagerEndpoints.GetServiceInfo
  );
  const {
    loading: paymentStatusLoading,
    error: paymentStatusError,
    callApi: getPaymentStatus,
    response: paymentStatusResponse,
  } = useSmartCityManager(SCManagerEndpoints.GetPeachPaymentStatus);

  const location = useLocation();
  const navigate = useNavigate();

  const [paymentStatus, setPaymentStatus] =
    useState<PeachPaymentStatusResponse | null>(null);
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
  const query = useQuery();
  const checkoutId = query.get('id');

  const [schedulingModalOpen, setSchedulingModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  /* The rest of the booking is contained within the Context. 
  Address is stored in Redux, where it was previously stored in local storage. 
  Note that address is still on the booking state in redux to avoid 
  breaking the existing booking flow. */
  const {
    booking: { address },
  } = useAppSelector((state) => state);
  const [selectedAddress, setSelectedAddress] =
    useState<CustomerPremises | null>(address);
  const [addressModalOpen, setAddressModalOpen] = useState(!selectedAddress);

  const displayAddress =
    selectedAddress && shortenWithEllipses(selectedAddress.addressString, 40);

  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [bookingStep]);

  useEffect(() => {
    if (showPaymentStatusModal) {
      if (paymentStatus?.result.code === peachSuccessCode) {
        setIsPaymentSuccessful(true);
        logFirebaseEvent(PrivateServicesEvents.PaymentSuccess);
      } else {
        setIsPaymentSuccessful(false);
      }
    }
  }, [paymentStatus, showPaymentStatusModal]);

  // Get fresh booking data from API
  useEffect(() => {
    if (serviceId)
      callApi({
        queryParams: `serviceId=${serviceId}`,
      });
  }, [serviceId]);

  // Set booking data from API
  useEffect(() => {
    const now = new Date();
    if (
      response &&
      (booking.bookingInformation?.typeId !== parseInt(serviceId || '', 10) ||
        !booking.bookingInformation ||
        isPaymentSuccessful)
    ) {
      dispatch(clearUserBooking());
      setBooking({
        smartCityServiceGroups: setMinimumCurrentValues(
          response.smartCityServiceGroups
        ),
        bookingInformation: {
          ...response.bookingInformation,
          insured: false,
          typeId: parseInt(serviceId || '', 10),
          startDateTime: toUnix(firstDateToSchedule()),
          endDateTime: toUnix(new Date(now.getTime() + 6 * 1000 * 3600)),
        },
      });
    }
  }, [response, isPaymentSuccessful]);

  // Keep the address data in sync, persist in redux store
  useEffect(() => {
    if (selectedAddress) {
      setBooking({
        ...booking,
        bookingInformation: {
          ...booking.bookingInformation,
          latitude: selectedAddress.latitude,
          longitude: selectedAddress.longitude,
          premiseType: selectedAddress.premiseType,
          premiseId: selectedAddress.id,
        },
      });
      dispatch(setAddress(selectedAddress));
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (checkoutId) {
      getPaymentStatus({
        queryParams: `checkoutId=${checkoutId}&createRegistration=false`,
      });
      setShowPaymentStatusModal(true);
    }
  }, [checkoutId]);

  useEffect(() => {
    setPaymentStatus(paymentStatusResponse);
  }, [paymentStatusResponse]);

  if (loading || booking.smartCityServiceGroups.length === 0 || globalState.user.uuid === "init") {
    return <Loader />;
  }

  return (
    <Flex justifyContent="center" flexDirection="column">
      <AddressSelectionModal
        setAddress={(a) => setSelectedAddress(a)}
        isOpen={addressModalOpen}
        onClose={() => setAddressModalOpen(false)}
      />
      <Modal
        isOpen={showPaymentStatusModal}
        onClose={
          isPaymentSuccessful
            ? () => navigate('/')
            : () => setShowPaymentStatusModal(false)
        }
        title="Payment Status"
      >
        {typeof isPaymentSuccessful === 'boolean' &&
        (!paymentStatusLoading || !!paymentStatusError) ? (
          <>
            {isPaymentSuccessful ? (
              <Flex flexDirection="column">
                <Text
                  id="GTM-paymentSuccess"
                  textAlign="center"
                  fontSize="20px"
                  color={theme.colors.green}
                >
                  Success
                </Text>
                <Image
                  source="icons/successicon.svg"
                  alt="success"
                  height="80px"
                  my="20px"
                />
                <Text textAlign="center" fontSize="18px" mb="20px">
                  Thank you for booking with us.
                </Text>
                <Button
                  variant="filled"
                  color="white"
                  onClick={() => {
                    dispatch(clearUserBooking());
                    navigate('/');
                  }}
                >
                  Close
                </Button>
              </Flex>
            ) : (
              <>
                <Text
                  id="GTM-paymentFailure"
                  textAlign="center"
                  fontSize="20px"
                  mb="20px"
                  color="red"
                >
                  Payment failed
                </Text>
                <Text textAlign="center" mb="20px" maxWidth="450px">
                  It seems that something has gone wrong during the payment
                  process. Please try again, use a different payment method or
                  contact us for further assistance.
                </Text>
                <Button
                  width="100%"
                  variant="filled"
                  color="white"
                  onClick={() => {
                    setShowPaymentStatusModal(false);
                  }}
                >
                  Close
                </Button>
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Modal>
      <Box mt="1%" ml="3%">
        <PrivateServicesBreadcrumbs
          crumbs={[{ label: 'Service Details', value: location.pathname }]}
        />
      </Box>

      <Flex
        width="100%"
        borderB={`2px solid ${theme.colors.borderGrey}`}
        justifyContent="center"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            border-bottom: 5px solid ${theme.colors.borderGrey};
          }
        `}
      >
        <Box
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              width: 100%;
            }
          `}
        >
          <Text
            m={0}
            textAlign="center"
            color={theme.colors.darkGrey}
            fontSize="25px"
            fontWeight="500"
          >
            {bookingStep === 'details' ? heading : 'Booking Summary'}
          </Text>
          {bookingStep === 'details' && (
            <Button
              width="100%"
              onClick={() => setAddressModalOpen(true)}
              my="3%"
            >
              <Flex alignItems="center" justifyContent="center">
                <Image
                  source="privateServices/gray-pin.svg"
                  alt="pin"
                  mr="10px"
                  height="20px"
                />
                <Text color={theme.colors.primary} fontWeight="bold">
                  {formatForcelinkAddress(displayAddress) ||
                    'Please select an address'}
                </Text>
                <Image
                  source="icons/edit.svg"
                  alt="pin"
                  ml="10px"
                  height="16px"
                />
              </Flex>
            </Button>
          )}
          <ProgressBar
            items={progressBarSteps}
            step={bookingStep === 'details' ? 1 : 2}
          />
        </Box>
      </Flex>
      {bookingStep === 'details' ? (
        <ServiceDetails
          setBookingTimes={setBookingTimes}
          duration={totalDuration}
          setBookingDuration={setBookingDuration}
          price={price}
          booking={booking}
          setServiceOptionCurrent={setServiceOptionCurrent}
          setResource={setResource}
          scheduleModalOpen={schedulingModalOpen}
          setScheduleModalOpen={setSchedulingModalOpen}
          continueBooking={() => {
            if(globalState.user.uuid === "guest") {
              setShowLoginModal(true);
            } else {
              setBookingStep('summary');
            }
          }}
        />
      ) : (
        <BookingSummary
          openAddressModal={() => setAddressModalOpen(true)}
          openScheduleModal={() => setSchedulingModalOpen(true)}
          booking={booking}
          duration={totalDuration}
          selectedAddress={selectedAddress}
          backToEdit={() => setBookingStep('details')}
        />
      )}
    </Flex>
  );
};

export default BookAServicePage;
