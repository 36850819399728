import styled from 'styled-components';

export const TopRow = styled.div`
  position: relative;
  display: flex;
  margin-top: 15px;
  padding: 0 20px;
  flex-direction: row;
  align-items: flex-start;
  @media only screen and (min-width: 800px) {
    margin-top: 30px;
    padding: 0 50px;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 22px;
  margin-top: 24px;
  @media only screen and (min-width: 800px) {
    margin: 0 auto;
    margin-top: 60px;
    width: 800px;
  }
  @media only screen and (min-width: 1400px) {
    width: 950px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LocationImageBanner = styled.div`
  background: linear-gradient(#00000087, #00000087), url(${({ src }) => src}) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 13px;
  }
  p {
    margin: 0;
    margin-left: 15px;
    color: white;
    font: normal normal bold 12px/16px Roboto;
  }
  @media only screen and (min-width: 800px) {
    height: 255px;
    img {
      height: 22px;
    }
    p {
      margin-left: 15px;
      font: normal normal 500 25px/33px Roboto;
    }
  }
`;

export const ProfileImage = styled.div`
  position: absolute;
  background: #FFFFFF url(${({ src }) => src}) 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  bottom: -18px;
  left: 25px;
  @media only screen and (min-width: 800px) {
    left: 50px;
    bottom: -45px;
    height: 184px;
    width: 184px;
    border: 5px solid #FFFFFF;
  }
`;

export const InfoColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 9px 20px 27px;
  border: 2px solid #B2BCC14D;
  border-top: none;
  border-radius: 2px;
  @media only screen and (min-width: 800px) {
    padding: 82px 70px 52px 70px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin-bottom: 8px;
  &:last-child() {
    margin: 0;
  }
`;

export const HeaderText = styled.p`
  font: normal normal ${({ bold = false }) => (bold ? 'bold' : 'normal')} 12px/16px Roboto;
  color: ${({ color = '#333333' }) => color};
  padding: 0;
  margin: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal ${({ bold = false }) => (bold ? 'bold' : 'normal')} 20px/24px Roboto;
  }
`;

export const Divider = styled(HeaderText)`
  margin: 0 10px;
  color: #B2BCC1;
  @media only screen and (min-width: 800px) {
    margin: 0 24px;
  }
`;

export const TabRow = styled.div`
  display: flex;
  margin: 20px 0;
  @media only screen and (min-width: 800px) {
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;

export const TabButton = styled.p`
  cursor: pointer;
  margin: 0;
  margin-right: 37px;
  font: normal normal normal 12px/16px Roboto;
  color: ${({ active = false }) => (active ? '#DF6E21' : '#333333')};
  padding: 0 1px 4px 1px;
  border-bottom: 1px solid ${({ active = false }) => (active ? '#DF6E21' : '#FFFFFF')};
  &:hover {
    color: #DF6E21;
    border-bottom: 1px solid #DF6E21;
  }
  @media only screen and (min-width: 800px) {
    margin-right: 60px;
    font: normal normal normal 14px/26px Roboto;
    padding: 0 1px 4px 1px;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 38%;
  right: 10px;
  @media only screen and (min-width: 800px) {
    right: 25px;
  }
`;
