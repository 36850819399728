import styled from 'styled-components';

export const SelectContainer = styled.div`
  position: relative;
`;

export const SelectButton = styled.div`
  background-color: #ffffff 0% 0% no-repeat padding-box;
  background: url('social/dropdown-icon.svg') 95% 50% no-repeat padding-box;
  background-size: 12px;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  height: 52px;
  width: 100%;
  font: normal normal normal 12px/52px Roboto;
  letter-spacing: 0px;
  color: #0a0918;
  margin-bottom: 13px;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 0 3px #df6e213e;
    border-color: transparent;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #df6e213e, 0 0 0 1px #df6e21;
    border-color: transparent;
  }
  @media only screen and (min-width: 800px) {
    border: 2px solid #dee2e6;
    border-radius: 5px;
    height: 67px;
    font: normal normal normal 14px/67px Roboto;
    padding: 0 30px;
    margin-bottom: 30px;
  }
`;

export const CitySelect = styled(SelectButton)`
  background: url('social/location-icon.svg') 16px 50% no-repeat padding-box,
    url('social/location-dropdown-icon.svg') 95% 50% no-repeat padding-box;
  cursor: pointer;
  background-size: 10px;
  padding: 0 16px 0 42px;
  @media only screen and (min-width: 800px) {
    background-size: 14px;
    background: url('social/location-icon.svg') 30px 50% no-repeat padding-box,
      url('social/location-dropdown-icon.svg') 95% 50% no-repeat padding-box;
    padding: 0 30px 0 67px;
  }
`;

export const SuburbSelect = styled(SelectButton)`
  background: url('social/forward-icon.svg') 95% 50% no-repeat padding-box;
  background-size: 6px;
  margin-bottom: 60px;
`;

export const SubmitButton = styled.button`
  background: #df6e21 0% 0% no-repeat padding-box;
  border-style: none;
  border-radius: 5px;
  margin: 0;
  margin-top: auto;
  padding: 0;
  width: 100%;
  height: 45px;
  font: normal normal normal 14px/19px Roboto;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #cb641e 0% 0% no-repeat padding-box;
  }
  &:disabled {
    background: rgba(178, 188, 193, 0.21) 0% 0% no-repeat padding-box;
    color: #b2bcc1;
  }
  @media only screen and (min-width: 800px) {
    height: 67px;
    margin: 0;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding: 0 15px;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const Search = styled.input`
  background: #b2bcc133 url('social/search-dark-icon.svg') 19px 50% no-repeat
    padding-box;
  background-size: 26px;
  height: 54px;
  width: 100%;
  border: none;
  border-radius: 2px;
  padding-left: 63px;
  margin-bottom: 38px;
  font: normal normal normal 14px/21px Roboto;
  color: #333333;
  transition: all 0.2s ease-in-out;
  border: 1px solid #b2bcc133;
  &::placeholder {
    color: #b2bcc1;
    opacity: 1;
  }
  &:hover {
    box-shadow: 0 0 0 3px #df6e213e;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #df6e213e, 0 0 0 1px #df6e21;
  }
`;

export const ScrollableListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const ParentSuburb = styled.p`
  font: normal normal normal 16px/21px Roboto;
  color: #b2bcc1;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const ChildSuburbContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

export const ChildSuburbText = styled.p`
  width: 100%;
  font: normal normal normal 20px/47px Roboto;
  color: #333333;
  padding-bottom: 18px;
  border-bottom: 2px solid #dee2e675;
  margin-left: 23px;
  cursor: pointer;
`;

export const Icon = styled.div`
  background: url('social/location-icon.svg') 50% 50% no-repeat padding-box;
  background-color: #b2bcc119;
  background-size: 13px;
  border-radius: 50%;
  width: 47px;
  height: 47px;
`;
