/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import moment from 'moment';

import { Modal } from 'components/social';
import {
  getImageURL,
  postPurchaseOrder,
  pingKinektekPurchase,
  confirmKinektekPurchase,
} from 'interface/inthecity/inthecity';
import { useGlobalContext } from 'contexts/global';
import {
  ScrollContainer,
  BottomContainer,
  Line,
  Button,
  ProductItem,
  ProductContent,
  ProductTotal,
  StatusMessage,
  StatusAnimation,
  GrayButton,
  ConfirmLine,
} from 'components/inthecity/lottoModal/lottoModal.styled';
import {
  ProgressLabel,
  StageCountCircle,
  ProgressHolder,
  ProgressLine,
  ConfirmationLabel,
} from './amountModal.styled';

/**
 * Lotto Confirmation Modal
 */
const ConfirmationModal = (props) => {
  // props
  const { show, handleClose, redirectState, handleShowOrderDetails } = props;

  const { ownAmount, product, checkout, lookup, mobileNumber } =
    redirectState || {};

  console.log('redirectState', {
    ownAmount,
    product,
    checkout,
    lookup,
    mobileNumber,
  });

  // hooks
  const {
    globalState: { user },
  } = useGlobalContext();

  // refs
  const ref = useRef();

  // state
  const [status, setStatus] = useState();
  const [orderDetails, setOrderDetails] = useState();
  console.log(status);

  // animation
  useEffect(() => {
    const animation = {
      container: ref.current,
      renderer: 'svg',
      loop: status === 'loading',
      autoplay: true,
      path: `animations/status/${status}.json`,
    };
    lottie.loadAnimation(animation);
    return () => {
      lottie.destroy();
    };
  }, [status]);

  // Kinektek payment
  async function processPurchase() {
    setStatus('loading');

    const purchaseOrder = {
      productId: product.productID,
      mobileNumber,
      smartcityuser_uuid: user?.uuid,
      dualStep: product.dualStep,
      timeStamp: moment().unix(),
      amount: ownAmount || product.price / 100,
      currency: 'ZAR',
      paymentType: 'DB',
      checkoutId: checkout,
      customerDetail: `name:${product.name},issuer:${product.issuer},mobileNumber:${mobileNumber},imageName:${product.imageName}`,
      ...(product.dualStep && { sessionId: lookup.sessionID }),
    };

    const purchaseOrderResponse = await postPurchaseOrder(purchaseOrder);

    console.log('lotto:purchaseOrder:request', purchaseOrder);
    console.log('lotto:purchaseOrder:response', purchaseOrderResponse);

    // Check Kinektek purchase status and slip
    const pingResponse = await pingKinektekPurchase(
      purchaseOrderResponse?.sessionID
    );
    setOrderDetails(pingResponse);

    if (pingResponse.status === 'SUCCESS') {
      // client received the slip
      const confirmResponse = await confirmKinektekPurchase(
        purchaseOrderResponse?.sessionID
      );
      if (confirmResponse) setStatus('success');
    } else setStatus('error');
  }

  // initiate payment process on mount
  useEffect(() => {
    if (redirectState && checkout) processPurchase();
  }, []);

  return (
    <Modal
      title="Confirmation"
      show={show}
      backButton={false}
      closeButton={false}
      handleClose={status === 'loading' ? () => {} : handleClose}
    >
      <ProgressHolder>
        <StageCountCircle done active>
          1<ProgressLabel>Amount</ProgressLabel>
        </StageCountCircle>
        <ProgressLine active />
        <StageCountCircle done active>
          2<ProgressLabel>Phone</ProgressLabel>
        </StageCountCircle>
        <ProgressLine active />
        <StageCountCircle done active>
          3<ProgressLabel>Payment</ProgressLabel>
        </StageCountCircle>
        <ProgressLine active />
        <StageCountCircle active>
          4<ConfirmationLabel>Confirmation</ConfirmationLabel>
        </StageCountCircle>
      </ProgressHolder>

      <ScrollContainer>
        <StatusAnimation ref={ref} />

        <StatusMessage>
          {status === 'loading' && 'Processing payment'}
          {status === 'success' && 'Thank you for your purchase'}
          {status === 'error' && 'Payment Rejected'}
        </StatusMessage>

        <ProductItem>
          <img src={getImageURL(product?.imageName)} alt={product?.name} />
          <ProductContent>
            <p>{product?.name}</p>
            <p>{mobileNumber}</p>
            <p>{moment().format('DD MMM YYYY')}</p>
          </ProductContent>
          <ProductTotal>
            {product
              ? `R ${Number(product?.price / 100 || ownAmount).toFixed(2)}`
              : 'Getting price'}
          </ProductTotal>
        </ProductItem>

        <ConfirmLine />

        {orderDetails && (
          <GrayButton onClick={() => handleShowOrderDetails(orderDetails)}>
            Order Details
          </GrayButton>
        )}
      </ScrollContainer>

      <BottomContainer>
        <Line />
        <Button
          disabled={status === 'loading'}
          onClick={status === 'loading' ? () => {} : handleClose}
        >
          Done
        </Button>
      </BottomContainer>
    </Modal>
  );
};

export default ConfirmationModal;
