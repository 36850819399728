import React from 'react';
import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
`;

export const InfographicContainer = styled.div`
  height: 100%;
  width: 90%;
  margin: 0 auto;
  margin-top: 10%;
  @media only screen and (min-width: 992px) {
    opacity: 100%;
    position: relative;
    right: -10%;
    bottom: -10%;
  }  
`;

export const CitiesContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 20%;
  @media only screen and (min-width: 600px) {
    width: 80%;
    margin-top: 10%;
  }
  @media only screen and (min-width: 992px) {
    position: relative;
    right: -10%;
    bottom: 0;
    margin-top: 0;
  }  
`;

export const CitiesRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CitiesColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &:first-of-type {
    margin-right: 1rem;
  }
  &:last-of-type {
    margin-top: -4.3744332vw;
  }
  @media only screen and (min-width: 992px) {
    &::first-of-type {
      margin-right: 2rem;
    }
  }  
`;

export const CityBubble = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5rem;
  img {
    width: 100%;
    height: 100%;
    transition: 0.2s;
  }
  p {
    position: absolute;
    color: transparent;
    font-size: 1rem;
    font-family: GothamMedium;
    transition: 0.2s;
    @media only screen and (min-width: 992px) {
      font-size: 1.5rem;
    }
  }
  &:hover {
    img {
      filter: brightness(80%) grayscale(50%);
    }
    p {
      color: white;
    }
  }
`;

export function Hero(
  {
    image,
    phone,
    title,
    desktopSubtitle,
    mobileSubtitle,
    mobileWidth = '100%',
    mediumWidth = '70%',
    desktopWidth = '50%',
  },
) {
  const Background = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(/${phone}), url(/${image});
    background-size: 35%, cover;
    background-color: #333;
    background-position: right, center;
    background-repeat: no-repeat, no-repeat;
    min-height: 30vh;
    padding: 1rem 0;
    @media only screen and (min-width: 650px) {
      min-height: 60vh;
    }
    @media only screen and (min-width: 900px) {
      min-height: calc(100vh - 10rem);
      padding: 1.5rem;
    }
    @media only screen and (min-width: 1000px) {
      min-height: calc(100vh - 12rem);
      padding: 1.5rem;
    }
    @media only screen and (min-width: 1400px) {
      padding: 2.5rem;
      min-height: calc(100vh - 15rem);      
    }
  `;
  const Container = styled.div`
    text-align: left;
    margin-top: auto;
    color: white;
    width: ${mobileWidth};
    @media only screen and (min-width: 900px) {
      width: ${mediumWidth};
    }
    @media only screen and (min-width: 1400px) {
      width: ${desktopWidth};
    }
  `;
  const TitleContainer = styled.div`
    width: 100%;
    background: #df7531;
    padding-right: 5rem;
    padding-left: 2rem;
    @media only screen and (max-width: 650px) {
      padding-right: 2rem;
      padding-left: 1rem;
    }
  `;
  const TitleText = styled.h1`
    position: relative;
    font-family: GothamMedium;
    transition: all 0.5s;

    margin: 0;
    font-weight: normal;
    padding: 0;

    top: -0.8rem; // half the font-size
    font-size: 1.75rem;
    line-height: 1.75rem;
    @media only screen and (min-width: 600px) {
      top: -2rem; // half the font-size
      font-size: 4rem;
      line-height: 4rem;
    }
    @media only screen and (min-width: 900px) {
      top: -3rem;// half the font-size
      font-size: 6rem;
      line-height: 6rem;
    }
    @media only screen and (min-width: 1400px) {
      top: -4rem;// half the font-size
      font-size: 8rem;
      line-height: 8rem;
    }
  `;
  const Subtitle = styled.div`
    width: 100%;
    background-color: rgba(255, 117, 49, 0.5);
    font-family: Gotham !important;
  `;
  const DesktopSubtitle = styled(Subtitle)`
    display: none;
    font-family: GothamMedium;
    font-weight: bolder;
    padding: 2rem;
    @media only screen and (min-width: 650px) {
      display: block;
      font-size: 1.25rem;
      line-height: 2rem;
    }
    @media only screen and (min-width: 1400px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  `;
  const MobileSubtitle = styled(Subtitle)`
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1rem;
    @media only screen and (min-width: 650px) {
      display: none;
    }
  `;
  return (
    <Background
      image={image}
    >
      <Container>
        <TitleContainer>
          <TitleText>
            {title}
          </TitleText>
        </TitleContainer>
        <DesktopSubtitle>{desktopSubtitle}</DesktopSubtitle>
        <MobileSubtitle>{mobileSubtitle || desktopSubtitle}</MobileSubtitle>
      </Container>
    </Background>
  );
}
