// TODO: Add all primary paths here
export enum PageLinksEnum {
  Home = '/',
  Map = '/map',
  InTheCity = '/in-the-city',
  SuggestAService = '/suggest-a-service',
  BookAService = '/book-a-service',
  MyBookings = '/my-bookings',
  Auth = '/auth',
}
