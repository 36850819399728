/* eslint-disable arrow-body-style */
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Title } from 'components/social/text';
import { Container, Divider, PageLink } from './breadcrumbs.styled';

const CRUMB_NAMES = {
  profile: 'Profile',
  purchase: 'Purchase',
  insights: 'Local Insights',
  place: 'Place',
  event: 'Event',
  'in-the-city': 'In-the-City',
  'places-of-interest': 'Places of Interest',
  'whats-on-in-your-city': 'What\'s on in your City',
};

const getPathStr = (arr, index) => `/${arr.slice(0, index + 1).join('/')}`;

const getCrumbStr = (crumb) => CRUMB_NAMES[crumb] || crumb;

const Breadcrumbs = () => {
  const path = useLocation().pathname.replace(/^\/?|\/?$/g, '');
  const crumbs = path.split('/');

  // Remove lotto and airtime from from breadcrumbs
  if (crumbs.includes('airtime') || crumbs.includes('lotto')) crumbs.pop();

  return (
    <Container>
      <div style={{ fontFamily: 'Roboto', display: 'flex' }}>
        &nbsp;
        <Divider src="social/breadcrumb-icon.svg" alt="arrow" />
      </div>
      {crumbs.map((crumb, index) => (
        index !== (crumbs.length - 1)
          ? (
            <div style={{ fontFamily: 'Roboto', display: 'flex' }} key={index}>
              <PageLink
                to={getPathStr(crumbs, index)}
              >
                {getCrumbStr(crumb)}
              </PageLink>
              <Divider src="social/breadcrumb-icon.svg" alt="arrow" />
            </div>
          )
          : (
            <div style={{ fontFamily: 'Roboto', display: 'flex' }}>
              <Title key={index}>
                {getCrumbStr(crumb)}
              </Title>
            </div>
          )
      ))}
    </Container>
  );
};

export default Breadcrumbs;
