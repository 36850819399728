import { Flex } from 'components/layout';
import { Link } from 'react-router-dom';
import { Image } from 'components/elements';
import theme from 'theme';
import { Text } from 'components/typography';

export interface BreadCrumbInterface {
  label: string;
  value: string;
}

const PrivateServicesBreadcrumbs = ({
  crumbs,
}: {
  crumbs: BreadCrumbInterface[];
}) => (
  <Flex>
    <Link to="/">
      <Text color={theme.colors.darkGrey}>Home</Text>
    </Link>
    {crumbs.map((crumb: BreadCrumbInterface, idx: number) => (
      <Flex key={idx}>
        <Image source="social/breadcrumb-icon.svg" alt=">" mx="10px" />
        <Link to={crumb.value}>
          <Text color={theme.colors.darkGrey}>{crumb.label}</Text>
        </Link>
      </Flex>
    ))}
  </Flex>
);

export default PrivateServicesBreadcrumbs;
