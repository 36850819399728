import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    primary: '#DF6E21',
    secondary: '#B2BCC1',
    tertiary: '#DEE2E6',
    line: '#F6F6F9',
    green: '#00A300',
    dark: '#333333',
  },
  font: 'Roboto',
  fontSizes: [
    '16px/21px',
    '20px/24px',
    '25px/33px',
    '35px/46px',
  ],
  mobileFontSizes: [
    '10px/14px',
    '12px/16px',
    '16px/21px',
    '20px/26px',
  ],
  breakpoints: {
    sm: '700px',
    md: '900px',
    lg: '1100px',
    xl: '1400px',
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
