import React from 'react';
import styled from 'styled-components';

export function Card(props) {
  const { image, subtitle, title, date, text } = props;

  const StyledCard = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    margin: 0 2vw 8rem;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    height: auto;

    @media only screen and (max-width: 650px) {
      margin: 0 0 2rem;
    }
    &:hover {
      transform: scale(1.015);
      background: #e1753110;
    }
  `;

  const Image = styled.div`
    background-image: url(${image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    @media only screen and (max-width: 650px) {
      height: 100px;
    }
  `;

  const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    @media only screen and (min-width: 576px) {
      padding: 2rem;
      min-height: 260px;
    }
  `;

  const Subtitle = styled.div`
    word-wrap: break-word;
    color: hsl(0, 0%, 20%);
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-family: GothamMedium;
    margin-bottom: 1.5rem;
    @media only screen and (max-width: 650px) {
      font-size: 1rem;
      line-height: 1rem;
    }
  `;

  const Title = styled.div`
    word-wrap: break-word;
    color: #e17531;
    font-size: 2rem;
    line-height: 2rem;
    font-family: GothamMedium;
    margin-bottom: 1.5rem;
    margin-top: auto;
    @media only screen and (max-width: 650px) {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
  `;

  const Date = styled.div`
    color: #e17531;
    font-size: 1.5rem;
    font-family: GothamMedium;
    font-style: italic;
    @media only screen and (max-width: 650px) {
      font-size: 1rem;
    }
  `;

  const Text = styled.div`
    color: #333333;
    font-size: 1.5rem;
    line-height: 1.75rem;
    @media only screen and (max-width: 650px) {
      font-size: 1rem;
      line-height: 1rem;
    }
  `;

  return (
    <StyledCard>
      <Image />
      <Content>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
        <Date>{date}</Date>
        <Text>{text}</Text>
      </Content>
    </StyledCard>
  );
}
