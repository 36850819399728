import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  @media only screen and (min-width: 800px) {
    margin-bottom: 0;
    margin-top: 28px;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const Icon = styled.div`
  background: url(${({ src }) => src}) 50% 50% no-repeat padding-box;
  background-color: #f7f8f9;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-size: 15px;
  @media only screen and (min-width: 800px) {
    width: 50px;
    height: 50px;
    background-size: auto;
  }
`;

export const Title = styled.p`
  font: normal normal 500 14px/21px Roboto;
  color: #333333;
  text-align: left;
  padding: 0;
  margin: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal 500 16px/21px Roboto;
  }
`;

export const DropdownContainer = styled.div`
  flex: 1;
  margin-left: 0;
  width: 100%;
  transition: all 0.2s ease-in-out;
  @media only screen and (min-width: 800px) {
    margin-left: 12px;
  }
`;

export const LocationButton = styled.div`
  cursor: pointer;
  align-self: center;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-top: 17px;
  @media only screen and (min-width: 800px) {
    margin: 0;
  }
`;

export const Button = styled.div`
  cursor: pointer;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 10px;
  @media only screen and (min-width: 800px) {    
    padding: 0 10px;
    height: 50px;
  }
`;

export const Dropdown = styled.div`
  max-height: 0;
  max-height: ${({ open }) => (open ? 'fit-content' : '0')};
  transition: all 0.2s ease-in-out;
  overflow: hidden;
`;

export const DropdownItem = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 10px;
  cursor: pointer;
  @media only screen and (min-width: 800px) {    
    padding: 0 10px;
    height: 36px;
  }
`;

export const DropdownIcon = styled.img`
  margin-left: 12px;
  width: 10px;
  transition: transform 0.15s ease-in-out;
  transform: rotate( ${({ open }) => (open ? '180deg' : '0')} );
  @media only screen and (min-width: 800px) {    
    margin-left: 24px;
    width: 14px;
  }
`;

export const Radio = styled.input`
  &:checked {
    filter: hue-rotate(189deg) brightness(1.25);
  }
`;

export const ClearButton = styled.div`
  cursor: pointer;
  background-color: #e0e0e0; // Example background color
  color: #333; // Text color
  border: none;
  border-radius: 4px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #bdbdbd; // Darken on hover
  }

  @media only screen and (min-width: 800px) {
    padding: 0 10px;
    height: 50px;
  }
`;