import styled from 'styled-components';

export const StyledButton = styled.div`
  cursor: pointer;
  padding: 0 20px;
  border-radius: 2px;
  background: ${({ active }) => (active ? '#FFFFFF' : '#DF6E21')} 0% 0% no-repeat padding-box;
  font: normal normal normal 10px/20px Roboto;
  color: ${({ active }) => (active ? '#DF6E21' : '#FFFFFF')};
  border: 1px solid ${({ active }) => (active ? '#DF6E21' : '#FFFFFF')};
  &:hover {
    background: #DF6E21 0% 0% no-repeat padding-box;
    color: #FFFFFF;
  }
  @media only screen and (min-width: 800px) {
    padding: 0 30px;
    border-radius: 3px;
    font: normal normal normal 14px/40px Roboto;
  }
`;
