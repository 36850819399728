import { ModalProps } from 'components/elements/modal';
import { Cookies } from 'react-cookie';
import { Checkbox } from 'components/form/input';
import { Modal, Link, Button } from 'components/elements';
import { Text } from 'components/typography';
import { Flex, Grid } from 'components/layout';
import theme from 'theme';

const cookies = new Cookies();

const PolicyConfirmationModal = ({
  confirmationCallback,
  whichPolicy,
  ...modalProps
}: {
  confirmationCallback: () => void;
  whichPolicy: 'cancellation' | 'reschedule';
} & ModalProps) => (
  <Modal {...modalProps}>
    <Flex flexDirection="column" justifyContent="center" flex="1">
      <Text fontSize="16px" mb="10px">
        Have you read our{' '}
        {whichPolicy === 'cancellation' ? (
          <Link
            href="/cancellation-policy"
            target="_blank"
            color={theme.colors.primary}
            fontWeight="bold"
          >
            Cancellation Policy
          </Link>
        ) : (
          <Link
            target="_blank"
            href="/reschedule-policy"
            color={theme.colors.primary}
            fontWeight="bold"
          >
            Reschedule Policy
          </Link>
        )}
        ?
      </Text>
      <Checkbox
        label="Do not ask again"
        name="do-not-show-again"
        onChange={(e) => {
          e.target.checked &&
            cookies.set('do-not-show-again', true, { path: '/' });
          !e.target.checked &&
            cookies.remove('do-not-show-again', { path: '/' });
        }}
      />
    </Flex>
    <Grid gridTemplateColumns="1fr" gridRowGap="12px" mt="20px">
      <Button
        onClick={() => {
          confirmationCallback();
          modalProps.onClose();
        }}
        p="15px"
        fontSize="16px"
        fontWeight="bold"
        color="white"
        backgroundColor={theme.colors.primary}
        border={`1px solid ${theme.colors.primary}`}
        borderRadius="4px"
      >
        Yes
      </Button>
      <Button
        onClick={() => {
          modalProps.onClose();
        }}
        variant="outlined"
        p="15px"
        fontSize="16px"
        fontWeight="bold"
        color={theme.colors.primary}
        borderRadius="4px"
      >
        No
      </Button>
    </Grid>
  </Modal>
);

export default PolicyConfirmationModal;
