import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
  light: {
    id: 0,
    name: 'Light',
    color: {
      headline: '#333333',
      title: '#333333',
      subtitle: '#333333',
      body: '#333333',
      button: {
        text: '#000000',
        background: '#000000',
        hover: '#000000',
      },
      link: {
        text: '#000000',
        opacity: 1,
      },
      background: '#FFFFFF',
    },
    font: 'Roboto',
    fontSize: {
      headline: '20px/26px',
      title: '16px/21px',
      subtitle: '16px/21px',
      body: '14px/19px',
    },
  },
  dark: {},
};

const Theme = ({ children }) => {
  // default to user's system preference
  let colorScheme = 'light';
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    colorScheme = 'light'; // 'dark'
  }

  return (
    <ThemeProvider
      theme={theme[colorScheme]}
    >
      {children}
    </ThemeProvider>
  );
};

export default Theme;
