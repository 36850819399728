import { apiUrl } from 'constants/app';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const getUnreadNotificationsCount = async () => fetch(
  `${apiUrl}smartcitymanager/getUnreadNotificationsCount`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const getNotifications = async () => fetch(
  `${apiUrl}smartcitymanager/getNotifications`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);
