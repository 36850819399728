import { Image } from 'components/elements';
import { Grid } from 'components/layout';

const InfoBox = ({ children }: { children: any }) => (
  <Grid
    gridTemplateColumns="1fr 5fr"
    gridColumnGap="10px"
    p="16px"
    borderRadius="4px"
    backgroundColor="#BADEEA"
    fontSize="14px"
  >
    <Image
      source="icons/Info.png"
      width="35px"
      height="35px"
      my="auto"
      alt="info"
    />
    {children}
  </Grid>
);

export default InfoBox;
