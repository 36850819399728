/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, createContext } from 'react';

const InTheCityContext = createContext();

const initialState = {
  POICategories: [
    { label: 'Arts and Entertainment', to: 'places-of-interest', id: 10 },
    { label: 'Business and Professional Services', to: 'places-of-interest', id: 11 },
    { label: 'Community and Government', to: 'places-of-interest', id: 12 },
    { label: 'Dining and Drinking', to: 'places-of-interest', id: 13 },
    { label: 'Event', to: 'places-of-interest', id: 14 },
    { label: 'Health and Medicine', to: 'places-of-interest', id: 15 },
    { label: 'Landmarks and Outdoors', to: 'places-of-interest', id: 16 },
    { label: 'Retail', to: 'places-of-interest', id: 17 },
    { label: 'Sports and Recreation', to: 'places-of-interest', id: 18 },
    { label: 'Travel and Transportation', to: 'places-of-interest', id: 19 },
  ],
  WOIYCCategories: [
    { label: 'Music', link: 'whats-on-in-your-city', id: 1 },
    { label: 'Sports & Fitness', link: 'whats-on-in-your-city', id: 5 },
    { label: 'Arts & Culture', link: 'whats-on-in-your-city', id: 9 },
    { label: 'Food & Drink', link: 'whats-on-in-your-city', id: 12 },
    { label: 'Business & Industry', link: 'whats-on-in-your-city', id: 13 },
    { label: 'Family & Education', link: 'whats-on-in-your-city', id: 30 },
    { label: 'Health & Wellness', link: 'whats-on-in-your-city', id: 38 },
    { label: 'Faith & Spirituality', link: 'whats-on-in-your-city', id: 46 },
    { label: 'Charity & Causes', link: 'whats-on-in-your-city', id: 50 },
    { label: 'Other', link: 'whats-on-in-your-city', id: 64 },
  ],
  // events
  eventCategories: null,
  featuredEvents: null,
  // places
  places: null,
  selectedWOIYCCategory: -1,
  selectedPOICategory: -1,
};

const InTheCityProvider = (props) => {
  const [state, setState] = useState(initialState);

  function updateState(item) {
    setState({ ...state, ...item });
  }

  function resetState() {
    setState(initialState);
  }

  const data = {
    POICategories: state.POICategories,
    WOIYCCategories: state.WOIYCCategories,
    eventCategories: state.eventCategories,
    featuredEvents: state.featuredEvents,
    selectedWOIYCCategory: state.selectedWOIYCCategory,
    selectedPOICategory: state.selectedPOICategory,
    places: state.places,
    updateState,
    resetState,
  };

  return <InTheCityContext.Provider value={data} {...props} />;
};

const useInTheCityContext = () => useContext(InTheCityContext);

export {
  InTheCityProvider,
  useInTheCityContext,
};
