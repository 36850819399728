export const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;
export const THREE_MONTHS = 7776000000;
export const LATEST_WORKING_HOUR = 18;
export const LATEST_BOOKING_HOUR = 15;

export const firstDateToSchedule = (reschedule?: boolean) => {
  const tomorrow = new Date(new Date().getTime() + TWENTY_FOUR_HOURS);
  let returnDate = tomorrow;
  if (tomorrow.getHours() >= LATEST_BOOKING_HOUR || reschedule) {
    returnDate = new Date(new Date().getTime() + TWENTY_FOUR_HOURS * 2);
  }
  returnDate.setHours(0, 0, 0, 0);
  return returnDate;
};

export const earliestSchedule = () => {
  const tomorrow = new Date(new Date().getTime() + TWENTY_FOUR_HOURS);
  let returnDate = tomorrow;
  if (tomorrow.getHours() > LATEST_BOOKING_HOUR - 1) {
    returnDate = new Date(new Date().getTime() + TWENTY_FOUR_HOURS * 2);
    returnDate.setHours(8, 0, 0, 0);
  } else {
    returnDate = new Date(
      new Date().getTime() + TWENTY_FOUR_HOURS + 60 * 60 * 1000
    );
  }
  return returnDate;
};
