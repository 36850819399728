import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInTheCityContext } from 'contexts/inthecity';
import {
  FilterColumn,
  SubTitle,
  ListItems,
  MoreList,
  MoreListIcon,
  Line,
  ListIcon,
  MobileMoreListIcon,
} from './mobileCategoryList.styled';

/**
 * Mobile Category Links
 */
const MobileCategoryList = (props) => {
  const { hideMobile = false, hideDesktop = false } = props;

  const [placeOfInterestListCounter, setPlaceOfInterestListCounter] = useState(0);
  const [whatsOnInYourCityListCounter, setWhatsOnInYourCityListCounter] = useState(0);
  const { updateState, POICategories, WOIYCCategories } = useInTheCityContext();

  const navigate = useNavigate();
  const handleClickWOIYC = (x) => {
    updateState({ selectedWOIYCCategory: x });
    navigate('/in-the-city/whats-on-in-your-city');
  };
  const handleClickPOI = (x) => {
    updateState({ selectedPOICategory: x });
    navigate('/in-the-city/places-of-interest');
  };

  const MobilePlaceListHandler = () => {
    if (placeOfInterestListCounter === POICategories.length) {
      setPlaceOfInterestListCounter(0);
    } else {
      setPlaceOfInterestListCounter(POICategories.length);
    }
  };

  const MobileWhatsOnInYourCityListHandler = () => {
    if (whatsOnInYourCityListCounter === WOIYCCategories.length) {
      setWhatsOnInYourCityListCounter(0);
    } else {
      setWhatsOnInYourCityListCounter(WOIYCCategories.length);
    }
  };

  return (
    <FilterColumn hideDesktop={hideDesktop} hideMobile={hideMobile}>
      <SubTitle onClick={() => MobilePlaceListHandler(POICategories,
        placeOfInterestListCounter)}
      >
        Places of Interest
        <MobileMoreListIcon
          src="inthecity/breadcrum_arrow_icon.svg"
          flip={(placeOfInterestListCounter >= POICategories.length)}
        />
      </SubTitle>
      <br />
      <div>
        {POICategories.slice(0, placeOfInterestListCounter).map((x, index) => (
          <div
            key={index}
            onClick={() => handleClickPOI(x)}
            role="button"
            tabIndex={0}
          >
            <ListItems>
              <ListIcon src="inthecity/orangeArrow_icon.svg" />
              {x.label}
              <br />
            </ListItems>
          </div>
        ))}
        <MoreList
          onClick={() => MobilePlaceListHandler(POICategories,
            placeOfInterestListCounter)}
          hideDesktop={hideDesktop}
          hideMobile={hideMobile}
        >
          <MobileMoreListIcon src="inthecity/breadcrum_arrow_icon.svg" flip={(placeOfInterestListCounter >= POICategories.length)} />
          <br />
        </MoreList>
        <Line />
      </div>
      <SubTitle onClick={() => MobileWhatsOnInYourCityListHandler(WOIYCCategories,
        whatsOnInYourCityListCounter)}
      >
        What&apos;s on in your City
        <MobileMoreListIcon src="inthecity/breadcrum_arrow_icon.svg" flip={(whatsOnInYourCityListCounter >= WOIYCCategories.length)} />
      </SubTitle>

      <br />
      <div>
        {WOIYCCategories.slice(0, whatsOnInYourCityListCounter).map((x, index) => (
          <div
            key={index}
            onClick={() => handleClickWOIYC(x)}
            role="button"
            tabIndex={0}
          >
            <ListItems>
              <ListIcon src="inthecity/orangeArrow_icon.svg" />
              {x.label}
              <br />
            </ListItems>
          </div>
        ))}
        <MoreList onClick={() => MobileWhatsOnInYourCityListHandler(WOIYCCategories,
          whatsOnInYourCityListCounter)}
        >
          <MoreListIcon src="inthecity/breadcrum_arrow_icon.svg" flip={(whatsOnInYourCityListCounter >= WOIYCCategories.length)} />
          <br />
        </MoreList>
        <Line />
      </div>
    </FilterColumn>
  );
};

export default MobileCategoryList;
