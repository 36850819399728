export interface NavigationItemInterface {
  label: string;
  link: string;
}

export interface NavigationItemGroupInterface {
  title: string;
  items: NavigationItemInterface[];
}

export const NAVIGATION_ITEMS: NavigationItemGroupInterface[] = [
  {
    title: 'Profile',
    items: [
      {
        label: 'My Profile',
        link: '/account/profile',
      },
      {
        label: 'Favourite Locations',
        link: '/account/favourite-locations',
      },
      {
        label: 'Utility Accounts',
        link: '/account/utility-accounts',
      },
      {
        label: 'Emergency Contacts',
        link: '/account/emergency-contacts',
      },
    ],
  },
  {
    title: 'My Activities',
    items: [
      {
        label: 'My Bookings',
        link: '/my-bookings',
      },
    ],
  },
  {
    title: 'Settings',
    items: [
      {
        label: 'Notification Settings',
        link: '/account/notification-settings',
      },
    ],
  },
  {
    title: 'Support',
    items: [
      {
        label: 'Contact us',
        link: '/account/support',
      },
    ],
  },
];
