/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';

import { Dropdown, Item, Icon, Label } from './sharemenu.styled';

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const ShareMenu = ({ postType, postId, cityId, open, text = '', handleShare }) => {
  const [copied, setCopied] = useState(false);

  const url = `https://${process.env.REACT_APP_API_BASE}/social/${postType}/${postId}/${cityId}`;

  useEffect(() => {
    setCopied(false);
  }, [open]);

  const handleTwitter = () => {
    openInNewTab(`https://twitter.com/intent/tweet?url=${url}&text=${text}`);
    handleShare();
  };

  const handleFacebook = () => {
    openInNewTab(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
    handleShare();
  };

  const handleWhatsapp = () => {
    openInNewTab(`https://wa.me/?text=${url}`);
    handleShare();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
    }, (err) => {
      console.log(err);
    });
    handleShare();
  };

  return (
    <Dropdown open={open}>
      <Item onClick={handleTwitter}>
        <Icon src="social/twitter-icon.svg" />
        <Label>Twitter</Label>
      </Item>
      <Item onClick={handleFacebook}>
        <Icon src="social/facebook-icon.svg" />
        <Label>Facebook</Label>
      </Item>
      <Item onClick={handleWhatsapp}>
        <Icon src="social/whatsapp-icon.svg" />
        <Label>WhatsApp</Label>
      </Item>
      <Item onClick={handleCopyLink}>
        <Icon src="social/copylink-icon.svg" />
        <Label>{copied ? 'Link copied' : 'Copy link'}</Label>
      </Item>
    </Dropdown>
  );
};

export default ShareMenu;
