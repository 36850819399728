import React, { useEffect, useRef, useState } from 'react';
import { Marker, TileLayer } from 'react-leaflet';
import debounce from 'lodash.debounce';
import L from 'leaflet';

import { useGlobalContext } from 'contexts/global';
import { useOnClickOutside } from 'functions/home';
import { searchPlaces } from 'interface/social';
import { StyledMapContainer } from './locationpicker.styled';
import {
  SearchContainer,
  SearchDropdown,
  SearchResult,
  StyledSearch,
} from '../createpost/createpost.styled';

const LocationPicker = ({ position, handleChangeLocation }) => {
  const { globalState } = useGlobalContext();
  const [map, setMap] = useState();
  const currentCityPosition = [
    globalState.city?.attributes?.latitude || 0,
    globalState.city?.attributes?.longitude || 0,
  ];
  const posArr = position
    ? [position.latitude, position.longitude]
    : currentCityPosition;
  const icon = new L.Icon({
    iconUrl: '/social/location-icon.svg',
    iconSize: [30, 44],
  });

  useEffect(() => map && map.flyTo(posArr), [position]);

  return (
    <StyledMapContainer
      center={posArr}
      zoom={11}
      minZoom={9}
      scrollWheelZoom
      easeLinearity={0.4}
      zoomControl={false}
      doubleClickZoom
      dragging
      animate
      attributionControl={false}
      closePopupOnClick={false}
      whenCreated={(m) => setMap(m)}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker icon={icon} position={posArr} />
      <LocationSearch handleChangeLocation={handleChangeLocation} />
    </StyledMapContainer>
  );
};

const debouncedFetchSearchData = debounce(async (query, callback) => {
  const res = await searchPlaces(query);
  callback(res.results);
}, 500);

const LocationSearch = ({ handleChangeLocation }) => {
  const [query, setQuery] = useState(null);
  const [results, setResults] = useState('');
  const [show, setShow] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setShow(false));

  const handleSearchClick = async (loc) => {
    setQuery(loc.formatted_address);
    setShow(false);

    handleChangeLocation({
      address: loc.formatted_address,
      location: {
        latitude: loc.geometry.location.lat,
        longitude: loc.geometry.location.lng,
      },
    });
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    debouncedFetchSearchData(query, (res) => {
      setResults(res);
      setShow(true);
    });
  }, [query]);

  return (
    <SearchContainer ref={ref}>
      <StyledSearch
        type="text"
        placeholder="Search Address"
        value={query}
        onChange={handleQueryChange}
        show={show}
      />
      {show && query?.length > 0 && results?.length > 0 && (
        <SearchDropdown>
          {results.slice(0, 8).map((res, index) => (
            <SearchResult key={index} onClick={() => handleSearchClick(res)}>
              {res.formatted_address}
            </SearchResult>
          ))}
        </SearchDropdown>
      )}
    </SearchContainer>
  );
};

export default LocationPicker;
