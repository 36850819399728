/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSocialContext } from 'contexts/social';
import { useGlobalContext } from 'contexts/global';
import { getCustomerPremises } from 'interface/map';
import { getLoadSheddingStage } from 'interface/social/alerts';
import {
  Line,
  StyledStageStatus,
  PowerStatus,
  StageStatusText,
  // StageStatusTimeText,
  StyledFavoriteLocationRow,
  StyledLocationItem,
  Icon,
  LocationText,
  CityText,
  LocationStageStatus,
  TextContainer,
} from './loadshedding.styled';

const Loadshedding = ({ handleOpenSchedule }) => {
  const { loadsheddingStage } = useSocialContext();
  const { globalState } = useGlobalContext();
  const [favLocations, setFavLocations] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getCustomerPremises();
      setFavLocations(data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = await getCustomerPremises();
      setFavLocations(data);
    }
    fetchData();
  }, [globalState.city]);

  return (
    <>
      <StageStatus />
      <FavoriteLocationRow />
      {favLocations.map((item) => (
        <LocationItem
          handleClick={() => {
            const d = {
              stage: loadsheddingStage,
              block: item.blockId,
              suburb: item.suburb || item.description,
              city: 'City of Cape Town',
            };
            handleOpenSchedule(d);
          }}
          location={item.suburb || item.description}
          city="City of Cape Town"
          stage={loadsheddingStage}
        />
      ))}
    </>
  );
};

const StageStatus = () => {
  const { loadsheddingStage, setLoadsheddingStage } = useSocialContext();

  useEffect(() => {
    async function fetchStage() {
      const res = await getLoadSheddingStage();
      setLoadsheddingStage(res.value);
    }
    if (!loadsheddingStage) {
      fetchStage();
    }
  }, []);
  return (
    <>
      <Line />
      <StyledStageStatus active={loadsheddingStage > 0}>
        <PowerStatus isPowerOn={loadsheddingStage > 0} />
        <div>
          <StageStatusText>
            {loadsheddingStage > 0
              ? `Stage ${loadsheddingStage} Loadshedding`
              : 'No Loadshedding Scheduled'}
          </StageStatusText>
          {/* <StageStatusTimeText>
            Since 6 days ago
          </StageStatusTimeText> */}
        </div>
      </StyledStageStatus>
    </>
  );
};

const FavoriteLocationRow = () => {
  return (
    <StyledFavoriteLocationRow>
      <p>Favourite Locations</p>
      <Link to="/profile/favouriteLocations">Add New</Link>
    </StyledFavoriteLocationRow>
  );
};

const LocationItem = (props) => {
  const { location, city, stage = 0, handleClick } = props;

  return (
    <StyledLocationItem onClick={handleClick}>
      <Icon />
      <TextContainer>
        <LocationText>{location}</LocationText>
        <CityText>{city}</CityText>
      </TextContainer>
      <LocationStageStatus active={stage > 0}>
        {stage > 0 ? `Stage ${stage}` : 'No Loadshedding'}
      </LocationStageStatus>
    </StyledLocationItem>
  );
};

export default Loadshedding;
