import useOutsideAlerter from 'hooks/use-outside-alerter';
import React, { useRef } from 'react';

const OutsideAlerter = ({
  callback,
  children,
}: {
  callback: () => void;
  children: React.ReactNode;
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, callback);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
