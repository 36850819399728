/* eslint-disable max-len */
import React, { useRef } from 'react';

import { useOnClickOutside } from 'functions/home';
import {
  Background, StyledModal, Title, CloseButton, BackButton,
} from './modal.styled';

const Modal = (props) => {
  const {
    children, title, show, closeButton = true, handleClose = () => {}, backButton = false, handleBack,
  } = props;
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());

  if (show) {
    return (
      <Background>
        <StyledModal ref={ref}>
          {title && <Title>{title}</Title>}
          {children}
          {closeButton ? <CloseButton onClick={handleClose} /> : null}
          {backButton ? <BackButton onClick={handleBack} /> : null}
        </StyledModal>
      </Background>
    );
  }
  return null;
};

export default Modal;
