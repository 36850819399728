import { apiUrl } from 'constants/app';
import { Cookies } from 'react-cookie';
import { validateUserModel } from './login';

const cookies = new Cookies();

export const guestUser = {
  "followable":true,
  "followed":false,
  "reportsCount":0,
  "petitionsCount":0,
  "followDate":0,
  "accepttc":true,
  "acceptmarketing":false,
  "cards":[],
  "uuid":"guest",
  "fullName":"Guest",
  "mobileNumber":"",
  "password":null,
  "email":"",
  "currentCitySubscriberId":0,
  "firebaseUid":"B7zpJXCnY0gZ8RhOT6dh6jNzKE62",
  "theme":"light",
  "issuesNearMeNotficationDistanceThreshold":null,
  "hasProfilePic":false,
  "schemaOptions":"{\n\"availableServices\":\"28575,27140,27147\"\n}",
  "userOptions":null,
  "verified":false,
  "smartCityUserRoles":[{"id":1,"description":"CITIZEN"}],
  "twitterHandle":null,
  "councillorForWard":null
};

export const signInAsGuest = (
  idToken: string,
  setUserAndCityFromGuest: (currentUser: any) => void,
  onSignIn: any
) => {
    cookies.set('firebaseToken', idToken, { path: '/' });
    cookies.set('currentUser', "guest", { path: '/' });
    setUserAndCityFromGuest(guestUser);
    onSignIn();
};

export const forcelinkSignInEmail = (
  idToken: string,
  setError: (e: any) => void,
  setUserAndCityFromUser: (currentUser: any) => void,
  onSignIn: any
) => {
  fetch(`${apiUrl}smartcitymanager/signIn`, {
    method: 'POST',
    body: idToken,
    headers: { firebase_token: idToken },
  })
    .then((res) => res.json())
    .then((result) => {
      if (validateUserModel(result)) {
        cookies.set('firebaseToken', idToken, { path: '/' });
        cookies.set('currentUser', result.uuid, { path: '/' });
        console.log('User result', result);
        setUserAndCityFromUser(result);
        onSignIn();
      }
    })
    .catch((err) => {
      setError({ message: err });
    });
};

const checkIfUserExists = async (idToken: string) => {
  console.log(idToken);
  const res = await fetch(`${apiUrl}smartcitymanager/getCurrentSmartCityUser`, {
    method: 'GET',
    headers: { firebase_token: idToken },
  });
  const code = res.status;
  return code;
};

export const forcelinkSignInSocial = async (
  idToken: string,
  setError: (e: any) => void,
  setUserAndCityFromUser: (currentUser: any) => void,
  user: any,
  onSignIn: any,
  globalState: any
) => {
  // Check if user exists
  const doesUserExist = await checkIfUserExists(idToken);
  // Sign in if user exists
  if (doesUserExist === 200) {
    fetch(`${apiUrl}smartcitymanager/signIn`, {
      method: 'POST',
      body: idToken,
      headers: { firebase_token: idToken },
    })
      .then((res) => res.json())
      .then((result) => {
        if (validateUserModel(result)) {
          cookies.set('firebaseToken', idToken, { path: '/' });
          cookies.set('currentUser', result.uuid, { path: '/' });
          setUserAndCityFromUser(result);
          onSignIn();
        }
      })
      .catch((err) => {
        setError({ message: err });
      });
  } else {
    // Create user on Forcelink and sign in
    fetch(`${apiUrl}smartcitymanager/signUp2`, {
      method: 'POST',
      body: JSON.stringify({
        email: user.email,
        fullName: user.displayName,
        currentCitySubscriberId: globalState.city.value || 431,
        mobileNumber: '',
        firebaseUid: user.uid,
      }),
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
      },
    }).then((res) => {
      if (res.status === 200) {
        onSignIn();
        res.json().then((r) => {
          cookies.set('firebaseToken', idToken, { path: '/' });
          cookies.set('currentUser', r.uuid, { path: '/' });
          setUserAndCityFromUser(r);
        });
      }
      throw res;
    });
  }
};
