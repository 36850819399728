import { Modal, Image, Button, TextField } from 'components/elements';
import InteractiveAddressSelector from 'components/interactive-address-selector';
import { Loader } from 'components/inthecity';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import React, { useEffect, useState } from 'react';
import theme from 'theme';
import {
  CreateCustomerPremise,
  CustomerPremises,
  GoogleSuggestedAddress,
  PremiseType,
} from 'types/types';
import Dropdown from 'components/dropdown';
import { css } from '@emotion/react';
import {
  formatForcelinkAddress,
  shortenWithEllipses,
} from 'functions/address-utils';
import { useGlobalContext } from 'contexts/global';

type AddressManagerState = 'favourites' | 'addNew' | 'custom' | 'edit';

const FavouriteLocationItem = ({
  location,
  setAddressToUpdate,
  deleteAddress,
  setAddress,
}: {
  location: CustomerPremises;
  setAddressToUpdate: () => void;
  deleteAddress: () => void;
  setAddress: () => void;
}) => {
  const displayAddress = shortenWithEllipses(location.addressString, 50);
  return (
    <Grid
      alignItems="center"
      py="10px"
      pr="10px"
      gridTemplateColumns="10fr 1fr"
      mobileCss={css`
        grid-template-columns: 4fr 1fr;
      `}
    >
      <Button onClick={setAddress}>
        <Flex alignItems="center">
          {/* TODO: Uncomment when we get premise icons */}
          {/* <Flex
            p="5px"
            backgroundColor={theme.colors.borderGrey}
            borderRadius="50%"
            height="30px"
            width="30px"
            mr="15px"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              source={location.premiseTypeIcon || 'map/favouritePin.svg'}
              alt="icon"
              width="15px"
            />
          </Flex> */}
          <Box>
            <Text textAlign="left" fontWeight="bold">
              {location.description}
            </Text>
            <Text textAlign="left" color={theme.colors.lightGrey}>
              {formatForcelinkAddress(displayAddress)}
            </Text>
          </Box>
        </Flex>
      </Button>
      <Flex width="100%" justifyContent="space-between">
        <Button height="100%" onClick={setAddressToUpdate} mt="5px">
          <Image source="icons/edit.svg" alt="edit" height="20px" />
        </Button>
        <Button height="100%" onClick={deleteAddress} ml="auto">
          <Image
            source="profile/binIcon.svg"
            alt="delete"
            height="20px"
            mt="5px"
          />
        </Button>
      </Flex>
    </Grid>
  );
};

const newAddressInitialState: CreateCustomerPremise = {
  description: '',
  premiseType: 'Home',
  latitude: 0,
  longitude: 0,
  premiseTypeId: 0,
  unitNo: '',
  buildingName: '',
};

export const AddressSelectionModal = ({
  setAddress,
  isOpen,
  onClose,
}: {
  setAddress: (address: CustomerPremises) => void;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [modalTitle, setModalTitle] = useState('Select your address');
  const {
    globalState: { user },
  } = useGlobalContext();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={modalTitle}
      slideFromBottomMobile
    >
      {user.uuid !== 'guest' ? (
        <AddressManager
          setAddress={(address: CustomerPremises) => {
            setAddress(address);
            onClose();
          }}
          setModalTitle={setModalTitle}
        />
      ) : (
        <InteractiveAddressSelector
          setAddressCallback={(a: GoogleSuggestedAddress) => {
            setAddress({
              addressString: a.formatted_address,
              latitude: a.geometry.location.lat,
              longitude: a.geometry.location.lng,
              description: a.formatted_address,
              buildingName: '',
              id: 0,
              premiseType: 'Guest',
              premiseTypeId: 0,
              active: true,
              serviceAvailable: true,
              availableServices: [],
            });
            onClose();
          }}
        />
      )}
    </Modal>
  );
};

const AddressManager = ({
  setAddress,
  setModalTitle,
  showCloseButton = false,
}: {
  setAddress?: (address: CustomerPremises) => void;
  setModalTitle?: React.Dispatch<React.SetStateAction<string>>;
  showCloseButton?: boolean;
}) => {
  const { globalState } = useGlobalContext();
  const [customerPremises, setCustomerPremises] = useState<
    CustomerPremises[] | null
  >(null);
  const [addressManagerState, setAddressManagerState] =
    useState<AddressManagerState>(
      globalState.user.uuid === 'guest' ? 'custom' : 'favourites'
    );
  const [searchedAddress, setSearchedAddress] =
    useState<GoogleSuggestedAddress | null>(null);
  const [newAddress, setNewAddress] = useState<CreateCustomerPremise>(
    newAddressInitialState
  );
  const [premiseTypes, setPremiseTypes] = useState<PremiseType[]>([]);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [addressToUpdate, setAddressToUpdate] =
    useState<CustomerPremises | null>();
  const [prevModalState, setPrevModalState] = useState<AddressManagerState>(
    globalState.user.uuid === 'guest' ? 'addNew' : 'favourites'
  );

  const reset = () => {
    setSearchedAddress(null);
    setNewAddress(newAddressInitialState);
    setValidationError(null);
    setAddressToUpdate(null);
  };

  const {
    loading,
    // error,
    response: addresses,
    callApi: getCustomerPremises,
  } = useSmartCityManager(SCManagerEndpoints.GetCustomerPremises);

  const {
    // loading: loadingPremises,
    response: premises,
    callApi: getPremiseTypes,
  } = useSmartCityManager(SCManagerEndpoints.GetPremiseTypes);

  const {
    loading: submittingNewAddress,
    response: newAddressResponse,
    callApi: submitNewAddress,
  } = useSmartCityManager(SCManagerEndpoints.CreateCustomerPremise);

  const {
    loading: gettingServicesForWard,
    response: servicesForWard,
    callApi: getServicesForWard,
  } = useSmartCityManager(SCManagerEndpoints.GetServicesForWard);

  const {
    loading: submittingUpdatedAddress,
    response: updateAddressResponse,
    callApi: updateAddress,
  } = useSmartCityManager(SCManagerEndpoints.UpdateCustomerPremise);

  useEffect(() => {
    setNewAddress({
      ...newAddress,
      latitude: searchedAddress?.geometry.location.lat || 0,
      longitude: searchedAddress?.geometry.location.lng || 0,
    });
    if (addressToUpdate) {
      setNewAddress({
        ...newAddress,
        latitude: searchedAddress?.geometry.location.lat || 0,
        longitude: searchedAddress?.geometry.location.lng || 0,
        description: addressToUpdate?.description || '',
        premiseTypeId: addressToUpdate?.premiseTypeId || 0,
        unitNo: addressToUpdate?.unitNo || '',
        buildingName: addressToUpdate?.buildingName || '',
      });
    }
  }, [searchedAddress]);

  useEffect(() => {
    if (addressManagerState === 'favourites') {
      getCustomerPremises();
      setModalTitle && setModalTitle('Select your address');
      reset();
    }
    if (addressManagerState === 'addNew') {
      setModalTitle && setModalTitle('Add new address');
      getPremiseTypes();
    }
    if (addressManagerState === 'edit') {
      setModalTitle && setModalTitle('Edit address');
      getPremiseTypes();
      setNewAddress({
        ...newAddress,
        latitude: addressToUpdate?.latitude || 0,
        longitude: addressToUpdate?.longitude || 0,
        description: addressToUpdate?.description || '',
        premiseTypeId: addressToUpdate?.premiseTypeId || 0,
        unitNo: addressToUpdate?.unitNo || '',
        buildingName: addressToUpdate?.buildingName || '',
        premiseType: addressToUpdate?.premiseType || '',
      });
    }
    if (addressManagerState === 'custom') {
      setModalTitle && setModalTitle('Select your address');
    }
  }, [addressManagerState, updateAddressResponse]);

  useEffect(() => {
    if (premises && premises.length > 0) {
      setPremiseTypes(premises);
      console.log(premises);
      if (addressManagerState === 'addNew') {
        setNewAddress({
          ...newAddress,
          premiseType: 'Please select',
        });
      }
    }
  }, [premises]);

  useEffect(() => {
    if (addresses) {
      setCustomerPremises(addresses.data);
    }
  }, [addresses, loading]);

  useEffect(() => {
    if (newAddressResponse || updateAddressResponse) {
      setAddressManagerState('favourites');
    }
  }, [newAddressResponse, updateAddressResponse]);

  const onSubmitNewAddress = (mode: 'udpate' | 'create') => {
    if (!newAddress.description) {
      setValidationError('Please enter a description');
      return;
    }
    if (!newAddress.premiseTypeId) {
      setValidationError('Please select a location type');
      return;
    }
    if (mode === 'create') {      
      if (globalState.user.uuid === "guest") {
        getServicesForWard({queryParams: `latitude=${newAddress.latitude}&longitude=${newAddress.longitude}`});
        while (gettingServicesForWard) {
          console.log(servicesForWard);
        }
        const guestAddress = {
          id: 0, description: newAddress.description,
          buildingName: newAddress.buildingName,
          suburb: newAddress.suburb,
          latitude: newAddress.latitude,
          longitude: newAddress.longitude,
          active: true,
          premiseType: newAddress.premiseType,
          premiseTypeId: newAddress.premiseTypeId,
          addressString: newAddress.description,
          unitNo: newAddress.unitNo,
          serviceAvailable: true,
          availableServices: servicesForWard,
        }
        console.log(newAddress, guestAddress);
        setAddress && setAddress(guestAddress);
      } else {
        submitNewAddress({
          body: newAddress,
        });
      }
    } else {
      console.log({
        ...newAddress,
        id: addressToUpdate?.id,
        active: true,
        addressString:
          searchedAddress?.formatted_address || addressToUpdate?.addressString,
      });
      updateAddress({
        body: {
          ...newAddress,
          id: addressToUpdate?.id,
          latitude:
            searchedAddress?.geometry.location.lat || addressToUpdate?.latitude,
          longitude:
            searchedAddress?.geometry.location.lng ||
            addressToUpdate?.longitude,
          active: true,
          addressString:
            searchedAddress?.formatted_address ||
            addressToUpdate?.addressString,
        },
      });
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="100%"
      position="relative"
      pt={
        showCloseButton && addressManagerState !== 'favourites'
          ? '60px'
          : undefined
      }
    >
      {showCloseButton && addressManagerState !== 'favourites' && (
        <Button
          p="10px"
          position="absolute"
          top="10px"
          right="10px"
          onClick={() => setAddressManagerState('favourites')}
        >
          <Image
            source="assets/icons/close.svg"
            alt="close"
            overrideCss={css`
              @media ${theme.mediaQueries.mobileOnly} {
                width: 15px;
              }
            `}
          />
        </Button>
      )}
      {addressManagerState === 'favourites' && (
        <>
          {loading ? (
            <Loader />
          ) : (
            <Flex flexDirection="column">
              <Flex
                width="100%"
                justifyContent="space-between"
                borderB={theme.borders.standard1px}
                pb="10px"
                alignItems="center"
                mb="10px"
              >
                <Text fontSize="17px">Favourite Locations</Text>
                <Button
                  p="10px"
                  py="5px"
                  borderRadius={theme.borderRadius.small}
                  border={theme.borders.standard1px}
                  color={theme.colors.primary}
                  onClick={() => {
                    setAddressManagerState('custom');
                    setPrevModalState('addNew');
                  }}
                >
                  Add new
                </Button>
              </Flex>

              {customerPremises?.length && customerPremises?.length > 0 ? (
                <Flex
                  flexDirection="column"
                  overflowY="scroll"
                  hideScrollBar
                  maxHeight="500px"
                >
                  {customerPremises?.map(
                    (address: CustomerPremises, idx: number) => (
                      <FavouriteLocationItem
                        setAddress={() => {
                          setAddress && setAddress(address);
                        }}
                        key={idx}
                        location={address}
                        setAddressToUpdate={() => {
                          setAddressToUpdate(address);
                          setAddressManagerState('edit');
                          setPrevModalState('edit');
                        }}
                        deleteAddress={() => {
                          setAddressManagerState('favourites');
                          updateAddress({
                            body: {
                              id: address.id,
                              description: address.description,
                              active: false,
                            },
                          });
                        }}
                      />
                    )
                  )}
                </Flex>
              ) : (
                <Text>
                  You have not added any favorite locations for this city yet.
                </Text>
              )}
            </Flex>
          )}
        </>
      )}
      {addressManagerState === 'custom' && (
        <>
          <InteractiveAddressSelector
            setAddressCallback={(address) => {
              setSearchedAddress(address);
              setAddressManagerState(prevModalState);
            }}
          />
        </>
      )}
      {addressManagerState === 'addNew' && (
        <>
          {submittingNewAddress || gettingServicesForWard? (
            <Loader />
          ) : (
            <Grid gridRowGap="12px">
              <Flex alignItems="center">
                <Text color={theme.colors.lightGrey} fontWeight="bold">
                  {searchedAddress?.formatted_address}
                </Text>
                <Button onClick={() => setAddressManagerState('custom')}>
                  <Image
                    source="icons/edit.svg"
                    alt="edit"
                    height="15px"
                    ml="10px"
                    mb="4px"
                  />
                </Button>
              </Flex>
              <Text>Location Type *</Text>
              <Dropdown
                value={{
                  label: newAddress.premiseType,
                  value: newAddress.premiseTypeId,
                }}
                options={premiseTypes.map((p) => ({
                  label: p.label,
                  value: p.value,
                }))}
                onChange={(option) =>
                  setNewAddress({
                    ...newAddress,
                    premiseType: option.label,
                    premiseTypeId: option.value,
                  })
                }
              />
              <Text>Address Description *</Text>
              <TextField
                value={newAddress?.description}
                onChange={(event) =>
                  setNewAddress({
                    ...newAddress,
                    description: event.target.value,
                  })
                }
                placeholder="Enter a description for this address"
              />
              <Text>Building name</Text>
              <TextField
                value={newAddress?.buildingName}
                onChange={(event) =>
                  setNewAddress({
                    ...newAddress,
                    buildingName: event.target.value,
                  })
                }
                placeholder="Building name"
              />
              <Text>Unit Number</Text>
              <TextField
                value={newAddress?.unitNo}
                onChange={(event) =>
                  setNewAddress({
                    ...newAddress,
                    unitNo: event.target.value,
                  })
                }
                placeholder="Unit number"
              />
              {validationError && <Text color="red">{validationError}</Text>}
              <Button
                p="10px"
                backgroundColor={theme.colors.primary}
                borderRadius={theme.borderRadius.small}
                color="white"
                onClick={() => onSubmitNewAddress('create')}
              >
                Confirm
              </Button>
            </Grid>
          )}
        </>
      )}
      {addressManagerState === 'edit' && (
        <>
          {submittingUpdatedAddress ? (
            <Loader />
          ) : (
            <Grid gridRowGap="12px">
              <Flex alignItems="center">
                <Text color={theme.colors.lightGrey} fontWeight="bold">
                  {searchedAddress?.formatted_address ||
                    addressToUpdate?.addressString.slice(1)}
                </Text>
                <Button onClick={() => setAddressManagerState('custom')}>
                  <Image
                    source="icons/edit.svg"
                    alt="edit"
                    height="15px"
                    ml="10px"
                    mb="4px"
                  />
                </Button>
              </Flex>
              <Text>Location Type *</Text>
              <Dropdown
                value={{
                  label: newAddress.premiseType || 'Please select',
                  value: newAddress.premiseTypeId || 0,
                }}
                options={premiseTypes.map((p) => ({
                  label: p.label,
                  value: p.value,
                }))}
                onChange={(option) =>
                  setNewAddress({
                    ...newAddress,
                    premiseType: option.label,
                    premiseTypeId: option.value,
                  })
                }
              />
              <Text>Address Description *</Text>
              <TextField
                value={newAddress?.description}
                onChange={(event) =>
                  setNewAddress({
                    ...newAddress,
                    description: event.target.value,
                  })
                }
                placeholder="Enter a description for this address"
              />
              <Text>Building name</Text>
              <TextField
                value={newAddress?.buildingName}
                onChange={(event) =>
                  setNewAddress({
                    ...newAddress,
                    buildingName: event.target.value,
                  })
                }
                placeholder="Building name"
              />
              <Text>Unit Number</Text>
              <TextField
                value={newAddress?.unitNo}
                onChange={(event) =>
                  setNewAddress({
                    ...newAddress,
                    unitNo: event.target.value,
                  })
                }
                placeholder="Unit number"
              />
              {validationError && <Text color="red">{validationError}</Text>}
              <Button
                p="10px"
                backgroundColor={theme.colors.primary}
                borderRadius={theme.borderRadius.small}
                color="white"
                onClick={() => onSubmitNewAddress('udpate')}
              >
                Confirm
              </Button>
            </Grid>
          )}
        </>
      )}
    </Flex>
  );
};

export default AddressManager;
