import styled from 'styled-components';

export const Line = styled.div`
  height: 0;
  width: calc(100% + 100px);
  margin-left: -50px;
  margin-bottom: 12px;
  overflow: hidden;
  border-bottom: 7px solid rgba(178, 188, 193, 0.19);
  @media only screen and (min-width: 800px) {
    ${({ isFirst = false }) => (isFirst && 'display: none;')}
    width: 100%;
    margin: 0;
    margin-bottom: 27px;
    border-bottom: 2px solid rgba(178, 188, 193, 0.19);
  }
`;

export const StyledStageStatus = styled.div`
  display: flex;
  padding: 0 20px;
  /* justify-content: center; */
  align-items: center;
  border-radius: 6px;
  background: ${({ active = true }) => (active ? '#FD5363' : '#04BD6C')} 0% 0% no-repeat padding-box;
  height: 102px;
  margin-bottom: 20px;
  /* img {
    margin-right: 17px;
  } */
  @media only screen and (min-width: 800px) {
    padding: 0 35px;
    margin-bottom: 27px;
    img {
      margin-right: 30px;
    }
  }
`;

export const PowerStatus = styled.div`
  margin-right: 17px;
  width: 60px;
  height: 60px;
  background: url(${({ isPowerOn }) => (isPowerOn ? 'social/loadshedding-off-icon.svg' : 'social/loadshedding-on-icon.svg')}) 50% 50% no-repeat;
`;

export const StageStatusText = styled.p`
  font: normal normal 500 16px/21px Roboto;
  color: #FFFFFF;
  margin-bottom: 10px;
`;

export const StageStatusTimeText = styled.p`
  font: normal normal normal 12px/16px Roboto;
  color: #FFFFFF;
`;

export const StyledFavoriteLocationRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  a, p {
    font: normal normal normal 12px/14px Roboto;
    padding: 0;
    margin: 0;
    &:first-child {
      color: #B2BCC1;
    }
    &:last-child {
      color: #0A0918;
      cursor: pointer;
      user-select: none;
    }
  }
  a:hover {
    color: #DF6E21;
  }
  @media only screen and (min-width: 800px) {
    p {
      font: normal normal normal 16px/21px Roboto;
    }
  }
`;

export const StyledLocationItem = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  flex: 1;
`;

export const Icon = styled.div`
  background: url('social/location-icon.svg') 50% 50% no-repeat padding-box;
  background-color: #b2bcc119;
  background-size: 8px;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  margin-right: 13px;
  @media only screen and (min-width: 800px) {
    background-size: 13px;
    width: 47px;
    height: 47px;
    margin-right: 20px;
  }
`;

export const LocationText = styled.p`
  width: 100%;
  font: normal normal 500 12px/14px Roboto;
  color: #333333;
  margin-bottom: 2px;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 20px/26px Roboto;
    margin-bottom: 4px;
  }
`;

export const CityText = styled.p`
  width: 100%;
  font: normal normal normal 10px/12px Roboto;
  color: #3333337f;
  padding-bottom: 20px;
  border-bottom: 1px solid #dee2e675;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/21px Roboto;
    border-bottom: 2px solid #dee2e675;
  }
`;

export const LocationStageStatus = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  width: 111px;
  height: min-content;
  text-align: center;
  font: normal normal normal 10px/27px Roboto;
  border-radius: 1px;
  ${({ active }) => (active ? `
    color: #FD5363;
    background: #fd536419 0% 0% no-repeat padding-box;
    ` : `
    color: #04BD6C;
    background: #04bd6d19 0% 0% no-repeat padding-box;
  `)}
  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/45px Roboto;
    width: 188px;
    border-radius: 2px;
  }
`;
