import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import theme from 'theme';

const SummaryWidget = ({
  includeLunch,
  price,
  duration,
  openScheduleModal,
  error,
  appointmentStart,
  continueBooking,
}: {
  includeLunch: boolean;
  price: number;
  duration: number;
  openScheduleModal: () => void;
  error: string | null;
  appointmentStart: Date;
  continueBooking: () => void;
}) => (
  <Box
    width="100%"
    backgroundColor="rgb(243, 245, 246)"
    borderRadius={theme.borderRadius.small}
    p="12px"
    height="fit-content"
    overrideCss={css`
      @media ${theme.mediaQueries.mobileOnly} {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100vw;
        background-color: white;
        border-top: ${theme.borders.standard1px};
        border-radius: 0;
        box-shadow: 0px -4px 10px -1px rgba(0, 0, 0, 0.2);
      }
    `}
  >
    <Flex
      justifyContent="space-between"
      borderB={theme.borders.standard1px}
      py="14px"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          padding: 0;
          padding-bottom: 10px;
        }
      `}
    >
      <Text m={0} p={0}>
        When
      </Text>

      <Button
        m={0}
        p={0}
        onClick={openScheduleModal}
        color={theme.colors.primary}
      >
        <Flex alignItems="center">
          <Text>
            {`${appointmentStart.toLocaleDateString('en-GB', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })} at ${
              appointmentStart.getHours() > 9
                ? appointmentStart.getHours()
                : `0${appointmentStart.getHours()}`
            }:00`}
          </Text>
          <Image
            source="icons/edit.svg"
            alt="edit"
            height="12px"
            ml="10px"
            mb="5px"
          />
        </Flex>
      </Button>
    </Flex>
    <Flex
      justifyContent="space-between"
      borderB={theme.borders.standard1px}
      py="14px"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          padding: 10px 0;
        }
      `}
    >
      <Text m={0} p={0}>
        Duration
      </Text>

      <Text m={0} p={0}>
        {`${includeLunch ? duration + 1 : duration} hours`}
      </Text>
    </Flex>
    <Flex
      justifyContent="space-between"
      py="14px"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          padding: 10px 0;
        }
      `}
    >
      <Text fontWeight="bold" fontSize="15px" m={0} p={0}>
        Total Cost
      </Text>
      <Text fontWeight="bold" fontSize="15px" m={0} p={0}>
        {`R ${price.toFixed(0).toString()}`}
      </Text>
    </Flex>
    <Button
      backgroundColor={
        !error ? theme.colors.primary : theme.colors.backgroundGrey
      }
      borderRadius={theme.borderRadius.small}
      color={error ? theme.colors.darkGrey : 'white'}
      p="10px"
      width="100%"
      disabled={!!error}
      onClick={continueBooking}
      fontWeight={error ? undefined : 'bold'}
      mobileCss={css`
        padding: 15px 10px;
        font-size: 16px;
      `}
    >
      <Text textAlign="center">{error || 'Continue to checkout'}</Text>
    </Button>
  </Box>
);

export default SummaryWidget;
