import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, Col } from 'react-bootstrap';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Section from 'components/section';

import {
  TermsLinks,
  Tcheader,
  TermsPopupBox,
  TermsPopupContent,
  TermsPopupButton,
  TermsSideMenu,
  TermsSideMenuContent,
  TermsContainer,
  TermsTitle,
  TermsTitleAlt,
  TermsParagraph,
} from './terms.styled';

const Links = () => (
  <TermsLinks>
    <Tcheader className="text-bold">My Smart City</Tcheader>
    <ul>
      <li>
        <Link to="/terms-and-conditions#disclaimer" smooth>
          Use of Data Disclaimer
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#warrant" smooth>
          Warrant Identity and Authority
        </Link>
      </li>
    </ul>
    <Tcheader className="text-bold">Terms and Conditions</Tcheader>
    <ol>
      <li>
        <Link to="/terms-and-conditions#introduction" smooth>
          Introduction
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#definition" smooth>
          Definition
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#interpretation" smooth>
          Interpretation
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#acceptance-of-terms" smooth>
          Acceptance of Terms
        </Link>
      </li>
      <li>
        <Link
          to="/terms-and-conditions#use-of-my-smart-city-platform-services"
          smooth
        >
          Use of My Smart City Platform &amp; Services
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#third-party" smooth>
          Third Party
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#intellectual-property-rights" smooth>
          Intellectual Property Rights
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#user-account-information" smooth>
          User Account Information
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#no-unlawful-or-prohibited-use" smooth>
          No Unlawful or Prohibited use
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#user-conduct" smooth>
          User Conduct
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#no-warranties" smooth>
          No Warranties
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#service-suspension-interruption" smooth>
          Service Suspension/Interruption
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#devices" smooth>
          Devices
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#limitation-of-liability" smooth>
          Limitation of Liability
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#time-indulgence" smooth>
          Time &amp; Indulgence
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#applicable-law" smooth>
          Applicable Law
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#fair-usage-policy" smooth>
          Fair Usage Policy
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#fair-usage-policy-limit" smooth>
          Fair Uage Policy Limit
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#privacy-policy">Privacy Policy</Link>
      </li>
      <li>
        <Link
          to="/terms-and-conditions#reporting-my-smart-city-platform-privacy-policy-concerns"
          smooth
        >
          Reporting My Smart City Platform Privacy Policy Concerns
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#user-accounts" smooth>
          User Accounts
        </Link>
      </li>
      <li>
        <Link
          to="/terms-and-conditions#information-retained-by-acumen-software"
          smooth
        >
          Information Retained by Acumen Software
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#use-of-retained-information" smooth>
          Use of Retained Information
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#third-party-sites" smooth>
          Third Party Sites
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#cookies" smooth>
          Cookies
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#data-security-plan" smooth>
          Data Security Plan
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#transfer-of-data" smooth>
          Transfer of Data
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#data-subject-rights" smooth>
          Data subject rights
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#compaints-disputes" smooth>
          Complaints &amp; Disputes
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#severity" smooth>
          Severity
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#entire-agreement" smooth>
          Entire Agreement
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#petitions" smooth>
          Petitions
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#booking-a-service" smooth>
          Booking a Service
        </Link>
      </li>
      <li>
        <Link to="/terms-and-conditions#contact-us" smooth>
          Contact us
        </Link>
      </li>
    </ol>
  </TermsLinks>
);

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && (
        <TermsPopupBox>
          <TermsPopupContent>
            <Links />
          </TermsPopupContent>
        </TermsPopupBox>
      )}
      <TermsPopupButton
        className={`${isOpen ? 'open' : ''}`}
        role="button"
        tabIndex={0}
        onClick={togglePopup}
      >
        <img src="terms/arrow.svg" alt="arrow" title={isOpen} />
      </TermsPopupButton>
    </>
  );
};

const SideMenu = () => (
  <TermsSideMenu>
    <TermsSideMenuContent>
      <Links />
    </TermsSideMenuContent>
  </TermsSideMenu>
);

export default function TermsAndConditionsPage() {
  return (
    <>
      <SEO
        title="My Smart City | Terms and Conditions"
        description="These Terms and Conditions constitute an agreement between you as user and Acumen Software as it relates to the use of the My Smart City Platform and related Services."
      />
      <Section color="white" thinPadding>
        <Row noGutters>
          <Col lg={3} sm={12}>
            <Popup />
            <SideMenu />
          </Col>
          <Col lg={9} sm={12}>
            <TermsContainer>
              <TermsTitle>My Smart City</TermsTitle>
              <TermsTitleAlt className="bold" id="disclaimer">
                Use of Data Disclaimer
              </TermsTitleAlt>
              <TermsParagraph>
                As User you may use the My Smart City Platform, and the
                information, writings, images and/or other works that you see,
                hear or otherwise experience on the Platform (singly or
                collectively, the &quot;Data&quot;) solely in compliance with
                the Terms and Conditions available on the My Smart City
                Platform.
              </TermsParagraph>
              <TermsParagraph>
                My Smart City Platform collects, processes and retains Data as
                part of operating the My Smart City Platform. This data is
                collected through the day-to-day Operations of the Platform, and
                from voluntary submissions and updates by Users, and excludes
                any additional and/or supplementary data collected by, and/or
                provided directly to, other similar platforms and/or government
                bodies. Therefore, while the Data is provided in good faith and
                to the best of My Smart Citys knowledge, we do not make any
                warranty, express or implied, including warranties of fitness
                for a particular purpose, or assume any legal liability or
                responsibility for the accuracy, completeness, or usefulness of
                any information provided (either isolated or in the aggregate),
                or represents that its use would not infringe privately owned
                rights. While every effort is made to ensure the data quality,
                the data is provided &quot;as is&quot;. Users should not act (or
                refrain from acting) based upon information in the Data without
                independently verifying the information and obtaining any
                necessary additional, external, or professional advice. The
                burden of assessing the fitness of the data lies completely with
                the User. The accuracy of any User&#39;s statistical analysis
                and any reported findings are not the responsibility of My Smart
                City.
              </TermsParagraph>
              <TermsParagraph>
                It should further be noted that any Data downloaded or otherwise
                obtained through the use of the My Smart City Platform is
                accessed at the User’s own risk and discretion, and the User
                will be solely responsible for any damage to its Device or loss
                of data that results from the download.
              </TermsParagraph>

              <TermsTitleAlt className="bold" id="warrant">
                Warrant Identity and Authority
              </TermsTitleAlt>
              <TermsParagraph>
                You represent that you have all right, title, interest and
                authorisation to attend to the User activities you are
                requesting access to. You further represent and warrant that you
                are who you say you are, that you agree to provide true,
                accurate, current and complete information about yourself as
                prompted by the User Account Registration process or other
                related forms (the &quot;Registration Data&quot;) and to
                maintain and promptly update the Registration Data to keep it
                true, accurate, current and complete. You warrant that you have
                not submitted fictitious, false or inaccurate information about
                yourself, or the position you hold, and that all Data provided
                during your User activity is true. You agree that you will not
                knowingly and with intent to defraud use, and/or abuse, your
                User Account and related access, permissions and rights assigned
                to your User Account and that you will manage your User Account
                and related User rights in line with the Terms and Conditions of
                My Smart City.
              </TermsParagraph>
              <TermsParagraph>
                If you provide any information that is untrue, inaccurate, not
                current or incomplete, or if My Smart City has reasonable
                grounds to suspect that such information is untrue, inaccurate,
                not current or incomplete, My Smart City has the right to
                suspend or terminate your account and refuse any and all current
                or future use of the My Smart City Platform (or any portion
                thereof). Furthermore, Should your actions warrant a criminal
                offence and/or is a reportable incident under South African law,
                My Smart City will take the necessary legal steps to report your
                actions to the relevant regulatory authority.
              </TermsParagraph>
              <TermsTitleAlt className="bold">
                Terms &amp; Conditions
              </TermsTitleAlt>
              <ol>
                <li id="introduction">Introduction</li>
                <TermsParagraph className="first">
                  My Smart City is a platform operated and owned by Acumen
                  Software (Pty) Ltd, a South African registered company, with
                  its physical address being:
                </TermsParagraph>
                <TermsParagraph>
                  Corner Main Office Buildings
                  <br />
                  Block A, Unit 7
                  <br />
                  2 Payne Road
                  <br />
                  Bryanston, Sandton, 2191
                </TermsParagraph>
                <TermsParagraph>
                  All details related to contact information is available on our
                  website www.mysmart.city. The My Smart City Platform provides
                  its Services (described below) to you as User through its
                  website located at www.mysmart.city and its mobile
                  applications and related services, subject to the following
                  Terms and Conditions as amended from time to time.
                </TermsParagraph>
                <TermsParagraph>
                  These Terms and Conditions therefore constitute an agreement
                  between you as User and Acumen Software as it relates to the
                  use of the My Smart City Platform and related Services,
                  irrespective of having a registered a User Account.
                </TermsParagraph>
                <TermsParagraph>
                  Acumen Software reserves the right, in its sole discretion, to
                  amend these Terms and Conditions from time to time. Acumen
                  Software will notify Users 1 (one) month in advance of any
                  amendments to these Terms and Conditions on the My Smart City
                  website (www.mysmart.city) or through other reasonable means,
                  which may include the Services user interface or in an email
                  notification to the registered email address for the User’s
                  Account. Emergency changes may be implemented on shorter
                  notice.
                </TermsParagraph>
                <TermsParagraph>
                  Except for urgent changes addressing new functions of the
                  Services, or changes made for legal reasons which will be
                  effective as required by legislation and/or good practice, all
                  changes to these Terms and Conditions will become effective
                  within the referenced 1 (one) month notice. Your continued use
                  of the Service after any such changes constitutes your
                  acceptance of the new Terms and Conditions. If you do not
                  agree to abide by these or any future Terms and Conditions, do
                  not use or access (or continue to use or access) the My Smart
                  City Platform and related Service.
                </TermsParagraph>
                <TermsParagraph>
                  In addition, when using certain Services, you shall be subject
                  to any additional terms applicable to such services that may
                  be posted by any Service Provider from time to time. All such
                  terms are hereby incorporated by reference into these Terms
                  and Conditions.
                </TermsParagraph>
                <TermsParagraph>
                  It is the User’s responsibility to regularly check the website
                  to determine if there have been changes to these Terms and
                  Conditions and to review such changes.
                </TermsParagraph>

                <li id="definition">Definition</li>
                <TermsParagraph className="first">
                  <div className="terms-bold">Agreement </div>
                  means this Agreement which sets out the conditions of use for
                  the My Smart City Platform and includes any additional terms
                  and conditions provided for on the My Smart City website;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">Acumen Software </div>
                  means Acumen Software (Pty) Ltd, an entity duly registered
                  entity in terms of the laws of South Africa, with registration
                  number 2006/008627/07, also referred to as “we”, “us” and
                  “our”;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">Account </div>
                  means the registered account held by a User on the My Smart
                  City Platform;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">Content </div>
                  means any data provided by the User including personal
                  information, issues logged and images that are stored on the
                  My Smart City Platform;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">Device </div>
                  means a mobile phone, terminal, tablet, laptop or desktop
                  computer or any other electronic, battery or solar operated
                  device used by the User to gain access to the My Smart City
                  Platform;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">Facebook </div>
                  means a social networking site located at facebook.com, the
                  Terms of Service which is defined on their website:
                  https://www.facebook.com/terms.php;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">Google </div>
                  means an internet search engine located at google.com, the
                  Terms of Service which is defined on their website:
                  https://policies.google.com/terms?hl=en-US;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">Google Maps </div>
                  means the mapping and location service as provided through
                  Google Maps/Google Earth, allowing a User to view and use a
                  variety of content, including map and terrain data, imagery,
                  business listings, traffic, reviews and other related
                  information provided by Google, its licensors, and users, the
                  Terms of Service which is defined on their website:
                  https://cloud.google.com/maps-platform/terms;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">My Smart City Platform </div>
                  means all the components, Services, websites, portals, the My
                  Smart City mobile app and all other functionality provided
                  through and related to the Acumen Software brand;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">Services </div>
                  means any service available through the My Smart City
                  Platform; Service Provider means a third-party service
                  provider and its relevant network and infrastructure that
                  provides the User with Services or a connection to a mobile
                  network;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">Smart Device </div>
                  means any device integrated with the My Smart City Platform
                  that can connect, share data and interact with the User;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">User </div>
                  means any authorised individual over the age of 18 (eighteen)
                  that uses the My Smart City Platform, also referred to as
                  “you”;
                </TermsParagraph>
                <TermsParagraph>
                  <div className="terms-bold">User Account </div>
                  means a registered My Smart City account for a registered User
                  stored on the My Smart City Platform database;
                </TermsParagraph>

                <li id="interpretation">
                  Interpretation
                  <ol>
                    <li>
                      The headings of the clauses in this Agreement are for the
                      purpose of convenience and reference only and shall not be
                      used in the interpretation of, nor modify nor amplify, the
                      terms of this Agreement nor any clause thereof.
                    </li>
                    <li>
                      In this Agreement, unless a contrary intention clearly
                      appears:
                      <ol>
                        <li>
                          words importing:
                          <ol>
                            <li>the male gender includes the female gender;</li>
                            <li>
                              the singular includes the plural and vice versa;
                              and
                            </li>
                            <li>
                              natural persons include created entities
                              (corporate or non-corporate) and vice versa;
                            </li>
                          </ol>
                        </li>
                        <li>
                          expressions defined in this Agreement shall bear the
                          same meanings in the Annexures and/or supplementary
                          policies (if and or where applicable) to this
                          Agreement unless specifically defined in the
                          Annexures;
                        </li>
                        <li>
                          where figures are referred to in numerals and in
                          words, if there is any conflict between the two, the
                          words shall prevail;
                        </li>
                        <li>
                          any reference to a Party includes a reference to the
                          Party’s authorised representatives, employees, agents
                          or contractors;
                        </li>
                        <li>
                          when any number of days is prescribed, same shall,
                          unless otherwise specifically stated, be reckoned
                          exclusively of the first and inclusively of the last
                          day, unless the last day falls on a day which is not a
                          Business Day, in which case the last day shall be the
                          next succeeding Business Day being Monday to Friday
                          excluding weekends and public holidays;
                        </li>
                        <li>
                          where words have been defined in the body of this
                          Agreement, such words shall, unless otherwise required
                          by the context, have the meanings so assigned to them
                          throughout this Agreement;
                        </li>
                        <li>
                          where any provision contemplates a notice to be given
                          or agreement to be reached between the Parties, such
                          notice or agreement shall, unless expressly provided
                          otherwise, be made in writing.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li id="acceptance-of-terms">
                  Acceptance of Terms
                  <ol>
                    <li>
                      The User agrees to be bound by these Terms and Conditions
                      and by agreeing to such the User declares that he or she
                      is legally competent by law applicable to the User to
                      accept, agrese to and or enter into this Agreement.
                    </li>
                    <li>
                      If a User is under 18 (eighteen) years old, you may use
                      the Service, with or without registering, only with the
                      approval of your parent or legal guardian which you
                      confirm you have obtained by continued use of the Service.
                    </li>
                    <li>
                      The User’s continued use of My Smart City Platform, in the
                      event of an amendment of these Terms and Conditions, will
                      constitute the Users acceptance of and agreement to such
                      amendment. The User will be entitled, if not in agreement
                      with an amendment, to terminate their User Account with
                      immediate effect.
                    </li>
                  </ol>
                </li>

                <li id="use-of-my-smart-city-platform-services">
                  Use of My Smart City Platform & Services
                  <ol>
                    <li>
                      Acumen Software grants the User a non-exclusive,
                      non-transferable right to use the My Smart City Platform
                      via a Smart Device.
                    </li>
                    <li>
                      The User may not use the My Smart City Platform in
                      contravention of any applicable Law and or Regulation
                      which is not limited by the Applicable Law governing this
                      Agreement.
                    </li>
                  </ol>
                </li>

                <li id="third-party">
                  Third Party
                  <ol>
                    <li>
                      The use of the My Smart City Platform by a third party
                      using a User’s Device will despite any conditions under
                      which the service provider is providing such services to
                      the User, be subject to the Terms and Conditions for the
                      purposes of this Agreement.
                    </li>
                  </ol>
                </li>

                <li id="intellectual-property-rights">
                  Intellectual Property Rights
                  <ol>
                    <li>
                      All copyright, trademarks, patents and other intellectual
                      property rights in any material or content (including
                      without limitation software, data, applications,
                      information, text, photographs, music, sound, videos,
                      graphics, logos, symbols, artwork and any other material
                      or moving images) contained in, or accessible via the My
                      Smart City Platform, is either owned by Acumen Software or
                      has been licensed to Acumen Software by the rights
                      owner(s) for use as part of the My Smart City Platform and
                      Services.
                    </li>
                  </ol>
                </li>

                <li id="user-account-information">
                  User Account Information
                  <ol>
                    <li>
                      A User may be required to register a User Account in order
                      to access and use certain features of the My City
                      Platform. The User agrees, and is obligated to provide and
                      maintain true, accurate, current and complete information
                      when registering for an Account on the My Smart City
                      Platform or Services, as well as any additional
                      information provided or any amendments made by the User.
                    </li>
                    <li>
                      The User is responsible for maintaining the
                      confidentiality of the User Account password, if any. It
                      is the responsibility of the User to ensure that User
                      Account is exited at the end of each session when
                      accessing the Service.
                    </li>
                    <li>
                      If the User’s Device is stolen or if the User becomes
                      aware of any unauthorised use of the My Smart City
                      Platform, the User remains responsible for all charges to
                      your Account, unless there is gross negligence on behalf
                      of Acumen Software.
                    </li>
                    <li>
                      The User agree to immediately notify Acumen Software of
                      any unauthorised use of your password or User Account or
                      any other breach of security. Acumen Software will not be
                      liable for any loss or damage arising from failure to
                      comply with this Section.
                    </li>
                    <li>
                      The User is and will remain at all times responsible for
                      all actions, direct, indirect and or incidental to and or
                      as a result of access to or use of the My Smart City
                      Platform via the User Account or independently or whether
                      the access was made by yourself or by a third party.
                    </li>
                    <li>
                      If a User becomes aware of, or suspects Unauthorised use
                      they should contact Acumen Software at
                      support@acumensoft.net for support which may include
                      suspending the User Account, changing Account details
                      and/or password or assisting with providing transaction
                      details as retained on the Acumen Software database.
                    </li>
                    <li>
                      The User acknowledges that Acumen Software reserves the
                      right to terminate User Accounts that are inactive for
                      periods in excess of 6 months.
                    </li>
                  </ol>
                </li>

                <li id="no-unlawful-or-prohibited-use">
                  No Unlawful or Prohibited Use
                  <ol>
                    <li>
                      The User expressly acknowledges and agrees to use the My
                      Smart City Platform solely for lawful purposes.
                    </li>
                    <li>
                      The User may not, without limitation:
                      <ol>
                        <li>
                          use and or perform any action on or through the My
                          Smart City Platform for any unlawful purpose;
                        </li>
                        <li>
                          intercept or monitor, damage or modify any
                          communication which is not intended for use by the
                          User;
                        </li>
                        <li>
                          send any unsolicited commercial communication not
                          permitted by applicable law;
                        </li>
                        <li>
                          expose any third party to material which is offensive,
                          harmful to minors, indecent or otherwise
                          objectionable;
                        </li>
                        <li>
                          log issues that are not real, do not exist, or that
                          are fraudulent.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The User, further to the above, may not use any part of
                      the My Smart City Platform in any manner that could
                      damage, disable, overburden, or impair any My Smart City
                      platform server, or the network(s) connected to any My
                      Smart City server, or interfere with any other
                      party&apos;s use.
                    </li>
                    <li>
                      Acumen Software has the sole and exclusive right at any
                      time to immediately terminate any service and/or account
                      if it interprets the use of the My Smart City Platform as
                      in any way fraudulent, criminal, unethical, offensive to
                      others, misuse and/or in any other way deemed by Acumen
                      Software as prohibited.
                    </li>
                  </ol>
                </li>

                <li id="user-conduct">
                  User Conduct
                  <ol>
                    <li>
                      The User is solely responsible for all information, data,
                      text, software, music, sound, photographs, graphics,
                      video, messages or other materials that you upload, post,
                      publish or display or otherwise transmit or use via the My
                      Smart City Platform and related Services.
                    </li>
                    <li>
                      You agree to not use the Service to Post, or otherwise
                      transmit any Content that:
                      <ol>
                        <li>
                          is unlawful, harmful, threatening, abusive, harassing,
                          tortious, excessively violent, defamatory, vulgar,
                          obscene, pornographic, libellous, invasive of
                          another’s privacy, hateful racially, ethnically or
                          otherwise objectionable;
                        </li>
                        <li>
                          you do not have a right to transmit under any law or
                          under contractual or fiduciary relationships;
                        </li>
                        <li>
                          infringes any intellectual property or other
                          proprietary rights of any party;
                        </li>
                        <li>
                          constitutes unsolicited or unauthorised advertising,
                          promotional materials, commercial activities and/or
                          sales, or any other form of solicitation;
                        </li>
                        <li>
                          contains software viruses or any other computer code,
                          files or programs designed to interrupt, destroy or
                          limit the functionality of any computer software or
                          hardware or telecommunications equipment;
                        </li>
                        <li>
                          in the sole judgment of Acumen Software, is
                          objectionable or which restricts or inhibits any other
                          person from using or enjoying the Service, or which
                          may expose Acumen Software or its Users to any harm or
                          liability of any type;
                        </li>
                        <li>
                          impersonate any person or entity, or falsely state or
                          otherwise misrepresent your affiliation with a person
                          or entity;
                        </li>
                        <li>
                          solicit personal information from anyone under the age
                          of 18;
                        </li>
                        <li>
                          harvest or collect contact information of other Users
                          from the Service;
                        </li>
                        <li>
                          advertise or offer to sell or buy any goods or
                          services for any business purpose that is not
                          specifically authorised;
                        </li>
                        <li>
                          interfere with or disrupt the Service or servers or
                          networks connected to the Service, or disobey any
                          requirements, procedures, policies or regulations of
                          networks connected to the Service;
                        </li>
                        <li>
                          or violate any applicable local, national or
                          international law, or any regulations having the force
                          of law;
                        </li>
                        <li>
                          further or promote any criminal activity or enterprise
                          or provide instructional information about illegal
                          activities;
                        </li>
                        <li>
                          or obtain or attempt to access or otherwise obtain any
                          materials or information through any means not
                          intentionally made available or provided for through
                          the Service.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Acumen Software reserves the right to investigate and take
                      appropriate administrative or legal action against anyone
                      who, in s sole discretion, violates this provision,
                      including without limitation, removing the offending
                      content from the Service, suspending or terminating the
                      User Account of such violators and reporting you to the
                      law enforcement authorities if applicable.
                    </li>
                    <li>
                      Acumen Software also have the right to remove a User’s
                      profile picture if it is deemed by our administrators to
                      be offensive, harmful to minors, indecent or otherwise
                      objectionable and/or not aligned with the My Smart City
                      public image and values.
                    </li>
                  </ol>
                </li>

                <li id="no-warranties">
                  No Warranties
                  <ol>
                    <li>
                      The My Smart City Platform is provided on an “as is” and
                      “as available” basis. The use of the My Smart City
                      Platform and Services is at the User’s sole risk.
                    </li>
                    <li>
                      Acumen Software does not make any express warranty
                      regarding the My Smart City Platform and disclaim any
                      implied warranty, including but not limited to, any
                      warranty of merchantability, satisfactory quality, fitness
                      for a particular purpose, uninterrupted use, or that the
                      service will meet your requirements.
                    </li>
                    <li>
                      Acumen Software cannot and does not guarantee that the My
                      Smart City Platform will always function without
                      disruption, delay or other imperfections and Acumen
                      Software may choose not to provide service to certain
                      countries or calling areas at its sole discretion.
                    </li>
                    <li>
                      However, Acumen Software shall endeavour to provide the My
                      Smart City Platform with minimum disruption.
                    </li>
                    <li>
                      The User agree to release, indemnify and hold Acumen
                      Software and its affiliates and their officers, employees,
                      directors and agent harmless from any from any and all
                      losses, damages, expenses, including reasonable attorneys’
                      fees, rights, claims, actions of any kind and injury
                      (including death) arising out of or relating to your use
                      of the Service, any user content, your connection to the
                      Service, your violation of these Terms of Condition or
                      your violation of any rights of another.
                    </li>
                  </ol>
                </li>

                <li id="service-suspension-interruption">
                  Service Suspension/Interruption
                  <ol>
                    <li>
                      Acumen Software is entitled, without any liability, to
                      refuse, restrict, limit, suspend and/or interfere or
                      interrupt the My Smart City Platform or any part thereof,
                      without any notice to the User for the repair,
                      improvement, modify and/or upgrade of the service at any
                      time.
                    </li>
                    <li>
                      Users may not use the My Smart City Platform for
                      commercial use other than the commercial Services offered
                      by the platform. All and any commercial use not deemed as
                      consumer usage will lead to immediate termination of
                      Service.
                    </li>
                    <li>
                      The User agrees that Acumen Software shall not be liable
                      to you or to any third party for any modification,
                      suspension or discontinuance of the Service.
                    </li>
                  </ol>
                </li>

                <li id="devices">
                  Devices
                  <ol>
                    <li>
                      The My Smart City platform is accessible via Smart Devices
                      with the ability to browse the Services on the internet.
                      To the extent you access the Service through a Smart
                      Device, your wireless service carrier’s standard charges,
                      data rates and other fees may apply.
                    </li>
                    <li>
                      In addition, downloading, installing, or using certain
                      Services may be prohibited or restricted by your Smart
                      Device’s operating system, carrier, mobile and/or data
                      providers, accordingly not all Services may work with all
                      carriers or Smart Devices.
                    </li>
                    <li>
                      Users should take this in consideration when the same User
                      Account is loaded on more than one Smart Device and has an
                      obligation to confirm all synchronisation settings is
                      activated between Smart Devices used and that all Services
                      is available on each Smart Device used.
                    </li>
                    <li>
                      Should a User migrate between Smart Devices while a
                      Service is in progress, Acumen Software cannot be held
                      responsible for any interrupted services, loss of data,
                      and/or any other direct or indirect damages caused to the
                      User or any third party depended on the Service.
                    </li>
                  </ol>
                </li>

                <li id="limitation-of-liability">
                  Limitation of Liability
                  <ol>
                    <li>
                      The User expressly understands and agrees that Acumen
                      Software is not and cannot be held liable for:
                      <ol>
                        <li>any equipment failure or modifications;</li>
                        <li>
                          service outages or for service limitations or
                          interruptions;
                        </li>
                        <li>
                          any accidents or incidents which result from the use
                          of the My Smart City Platform or any of its Services
                          by the User or any other person;
                        </li>
                        <li>
                          any direct, indirect, incidental, punitive or
                          consequential damages including inter alia lost
                          profits, other economic loss, death, injuries to
                          persons or property, any losses due to fraudulent
                          behaviour, misuse of information and other criminal
                          activities that are directly, indirectly or
                          coincidently associated with any My Smart City
                          Platform, representation, offer, product or device,
                          provided directly or indirectly, as part of the
                          package (the My Smart City Platform) offered to the
                          User so arising from the use of the My Smart City
                          Platform by the User and the User hereby specifically
                          indemnifies Acumen Software against any claims and or
                          legal actions flowing or resulting from such damages;
                        </li>
                        <li>
                          the My Smart City Platform utilises Google Maps for
                          its location services, including estimated time of
                          arrivals and route optimisations. Acumen Software
                          therefor does not guarantee that location service
                          information supplied via the My Smart City Platform to
                          the User in any of its features and Services are
                          accurate and cannot be held liable and the User hereby
                          indemnifies Acumen Software against any such liability
                          for any direct, indirect and or co-incidental damages
                          suffered by the User or another as a result of usage
                          of the addresses or in the event an incorrect address
                          is provided herein. For further details on the Terms
                          of Service of the Google Maps Platform please refer to
                          their website which should be read in conjunction with
                          this Agreement;
                        </li>
                        <li>
                          Acumen Software offers a next of kin panic button on
                          the My Smart City Platform but does not guarantee that
                          the next of kin will respond to the panic message or
                          that the alert will be delivered to the next of kin;
                        </li>
                        <li>
                          Acumen Software take security and reliability very
                          seriously and do our utmost to make sure our platforms
                          are failsafe, by using high standard products and
                          adhering to the highest industry practices. However,
                          no system is perfectly secure or reliable and in
                          considering that the internet is inherently an
                          insecure medium when you take into account all the
                          third parties involved, as well as limitations
                          associated with your chosen internet provider,
                          wireless carrier or devices used. When you use the My
                          Smart City Platform, you accept these risks and the
                          responsibility for choosing to use a technology that
                          cannot outperform third parties that are used in
                          providing the service.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li id="time-indulgence">
                  Time &amp; Indulgence
                  <ol>
                    <li>
                      No latitude, extension of time or other indulgence which
                      may be given or allowed by either Party to any other party
                      in respect of the performance of any obligation here under
                      or the enforcement of any right arising from this
                      Agreement is binding unless confirmed in writing, and no
                      single or partial exercise of any right by any Party shall
                      under any circumstances be construed to be an implied
                      consent by such Party or operate as a waiver or a novation
                      of, or otherwise affect any of that Party’s rights in
                      terms of or arising from this Agreement or estop such
                      Party from enforcing, at any time and without notice,
                      strict and punctual compliance with each and every
                      provision or terms hereof.
                    </li>
                  </ol>
                </li>

                <li id="applicable-law">
                  Applicable Law
                  <ol>
                    <li>
                      This Agreement shall be governed by and be construed in
                      accordance with the laws of the Republic of South Africa,
                      and the Parties hereby agree and submit to the
                      jurisdiction of the South African courts.
                    </li>
                  </ol>
                </li>

                <li id="fair-usage-policy">
                  Fair Usage Policy
                  <ol>
                    <li>
                      The My Smart City Platform is subject to a fair usage
                      limit that gives Acumen Software the right to limit or
                      cancel Services if usage indicates any misuse of the
                      Service, or if the usage differs from average usage and/or
                      reaches the fair usage limits as defined in this document
                      or otherwise communicated by Acumen Software.
                    </li>
                    <li>
                      If not otherwise stated, all Acumen Software’s Services
                      are subject to Acumen Software ́s fair usage policy as
                      described below. Any use of the My Smart City Platform is
                      subject to the Fair Usage Policy. Acumen Software reserves
                      the right to change the Fair Usage Policy at any time.
                      Changes shall become effective immediately and if you
                      continue to use the My Smart City Platform after such
                      notification has been given, your acceptance will be
                      deemed. All such changes will be published on the My Smart
                      City website (www.mysmart.city) and in force no later than
                      thirty (30) days after such notification. If you have not
                      deactivated the My Smart City Platform during the thirty
                      (30) day period, you will be deemed to have accepted the
                      revised fair usage policy and shall be bound by these
                      terms and conditions. By accepting the Acumen Software
                      Fair Usage Policy you also accept these Terms and
                      Conditions and the Acumen Software End User Software
                      License and Privacy Policy available on our Website
                      www.acumensoft.net.
                    </li>
                    <li>
                      In the event that there is, or Acumen Software suspects
                      that there is, any fraudulent, criminal activities or
                      misuse of the My Smart City Platform in any way, Acumen
                      Software reserves the right to terminate your access with
                      immediate effect without any prior notice, and without any
                      refund. Acumen Software disclaims any and all
                      responsibilities or liability in relation to content
                      available through the My Smart City Platform or any third
                      part supplier or partner. As a User of the My Smart City
                      Platform, you are solely responsible for all activities
                      while using the My Smart City Platform.
                    </li>
                  </ol>
                </li>

                <li id="fair-usage-policy-limit">
                  Fair Usage Policy Limit
                  <ol>
                    <li>
                      The User acknowledge that Acumen Software may establish
                      general practices and limits concerning use of the My
                      Smart City Platform and related Services, including
                      without limitation the maximum period of time that data or
                      other Content will be retained by Acumen Software’s
                      servers, and the maximum storage space that will be
                      allotted on Acumen Software’s servers on your behalf. You
                      agree that Acumen Software has no responsibility or
                      liability for the deletion or failure to store any data or
                      other content maintained or transmitted by the My Smart
                      City Platform.
                    </li>
                    <li>
                      Acumen Software reserve the right to limit usage of the My
                      Smart City Platform for any User to 10 000 minutes over a
                      12 month period.
                    </li>
                    <li>
                      Acumen Software reserves the right to adjust these
                      allowances based on average user behaviour.
                    </li>
                  </ol>
                </li>

                <li id="privacy-policy">
                  Privacy Policy
                  <ol>
                    <li>
                      The My Smart City Platform collects personal information
                      from the User through creating User Accounts and tracking
                      User Account activity. We also use GPS coordinates to
                      provide users with community based services.
                    </li>
                    <li>
                      Acumen Software treats the protection of personal data and
                      privacy seriously. When using the My Smart City Platform,
                      you are entrusting Acumen Software with your personal and
                      other information. Acumen Software takes all necessary
                      precautions to protect personal information and adheres to
                      the Protection of Personal Information Act 4 of 2013.
                    </li>
                    <li>
                      Refer to Acumen software’s privacy statement
                      https://www.acumensoft.net/privacy-statement/
                    </li>
                  </ol>
                </li>

                <li id="reporting-my-smart-city-platform-privacy-policy-concerns">
                  Reporting My Smart City Platform Privacy Policy Concerns
                  <ol>
                    <li>
                      Concerns regarding the contents of the My Smart City
                      Platform’s Privacy Policy can be reported to
                      privacy@mysmart.city.
                    </li>
                  </ol>
                </li>

                <li id="user-accounts">
                  User Accounts
                  <ol>
                    <li>
                      In the event that a User establishes a User Account on the
                      My Smart City Platform, certain information will be
                      retained such as name, surname, contact details, ID
                      number, account numbers and meta-data. Registration on the
                      My Smart City Platform is possible by using your Google
                      Account or Facebook Account details. Should you use either
                      of these third-party accounts as a basis for your User
                      Account, please refer to their specific Terms of Service /
                      conditions available on the respective websites for
                      additional information.
                    </li>
                    <li>
                      The My Smart City Platform will link all User information
                      to the relevant unique User Account. This includes
                      information received from all Smart Devices and browsers
                      that the User used to sign in to the My Smart City
                      Platform.
                    </li>
                    <li>
                      User Account information will only be visible to the User
                      and to the My Smart City Platform.
                    </li>
                    <li>
                      Certain information relating to issues logged will be made
                      available to other Users of the My Smart City Platform,
                      including Service Providers. Such information will exclude
                      Personal Information.
                    </li>
                    <li>
                      Safeguarding of User’s login credentials is the
                      responsibility of the User. Acumen Software will not be
                      liable for any misuse of a User Account and associated
                      personal information as a result of the User conveying
                      your login details to a third party.
                    </li>
                  </ol>
                </li>

                <li id="information-retained-by-acumen-software">
                  Information Retained by Acumen Software
                  <ol>
                    <li>
                      Account Information: Information required when setting up
                      an Account, such as your name, email address or contact
                      number. The My Smart City Platform will explicitly
                      indicate the fields for mandatory completion. If you do
                      not enter the requisite data in these fields, you may not
                      be able to create your User Account. This information will
                      be stored for as long as the User Account is active to
                      facilitate providing the services offered by the My Smart
                      City platform.
                    </li>
                    <li>
                      Additional Information: Information that you choose to
                      share on the My Smart City Platform, including but not
                      limited to username, reports associated with your
                      username, “favourite locations”, issue/fault locations,
                      search queries, files and images that have been uploaded.
                      Payment information can also be added to your User
                      Account.
                    </li>
                    <li>
                      Communication Information: between My Smart City Platform
                      users or third parties (private service providers) such as
                      chat messages or pin locations will also be stored.
                    </li>
                    <li>
                      My Smart City Activity: Information will be collected
                      based on the User’s activity within the My Smart City
                      Platform. This includes:
                      <ol>
                        <li>
                          features selected by the User which are meant to
                          personalise the user experience;
                        </li>
                        <li>GPS locations;</li>
                        <li>
                          IP address and other information from sensors and
                          receivers on or around your device;
                        </li>
                        <li>
                          your views and interactions with content and ads;
                        </li>
                        <li>
                          your photographic and text descriptions when
                          describing issues/faults/services or events;
                        </li>
                        <li>
                          communication or other content you chose to share with
                          any third party, including My Smart City Users through
                          the Services;
                        </li>
                        <li>
                          your activity on third-party sites and apps that you
                          chose to link to your My Smart City account or that
                          you interact with through the Service;
                        </li>
                        <li>
                          Meta-data (data providing information about one or
                          more aspects of the data; it is used to summarize
                          basic information about data which can make tracking
                          and working with specific data easier).
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li id="use-of-retained-information">
                  Use of Retained Information
                  <ol>
                    <li>
                      Acumen Software will use stored personal information to
                      send updates, notices, announcements, users’ requests and
                      additional information related to the Services (including
                      through push notifications, email address and SMS).
                    </li>
                    <li>
                      Personal Information will also be used to conduct surveys
                      and questionnaires.
                    </li>
                    <li>
                      Subject to your prior approval, email addresses may be
                      used to send you promotional and/or marketing materials.
                      At any time, you may choose not to receive such materials
                      by clicking on the “Unsubscribe” link in the email
                      messages that we may send to you. In any event, My Smart
                      City will not share contact details with any third party,
                      unless explicitly permitted or required by law.
                    </li>
                  </ol>
                </li>

                <li id="third-party-sites">
                  Third Party Sites
                  <ol>
                    <li>
                      Subject to your prior approval, email addresses may be
                      used to send you promotional and/or marketing materials.
                      At any time, you may choose not to receive such materials
                      by clicking on the “Unsubscribe” link in the email
                      messages that we may send to you. In any event, My Smart
                      City will not share contact details with any third party,
                      unless explicitly permitted or required by law.
                    </li>
                  </ol>
                </li>

                <li id="cookies">
                  Cookies
                  <ol>
                    <li>
                      Acumen software uses cookies and similar technologies on
                      both the website and mobile application.
                    </li>
                    <li>
                      Cookies allow us to remember your preferences to improve
                      the way our websites and mobile Apps work so that we can
                      personalise your experience.
                    </li>
                  </ol>
                </li>

                <li id="data-security-plan">
                  Data Security Plan
                  <ol>
                    <li>
                      Acumen software employs industry accepted standards in
                      securing your personal information on our Site. A request
                      for a copy of our data security plan can be motived by
                      sending an email to info@acumensoft.net
                    </li>
                  </ol>
                </li>

                <li id="transfer-of-data">
                  Transfer of Data
                  <ol>
                    <li>
                      Data may be transferred to servers located in the UK and
                      South Africa.
                    </li>
                  </ol>
                </li>

                <li id="data-subject-rights">
                  Data Subject Rights
                  <ol>
                    <li>
                      As a data subject whose information we hold, you have
                      certain rights which may be exercised by emailing
                      Support@mysmart.city. To process your request we may ask
                      for valid forms of identification for verification
                      purposes.
                    </li>
                    <li>
                      Your rights are as follows:
                      <ol>
                        <li>
                          The right to be informed: As a data controller, we are
                          obliged to provide clear and transparent information
                          about our data processing activities. This is provided
                          by this privacy policy and any related communications
                          we may send you.
                        </li>
                        <li>
                          The right of access: You may request a copy of the
                          personal data we hold about you free of charge. Once
                          we have verified your identity and, if relevant, the
                          authority of any third-party requestor, we will
                          provide access to the personal data we hold about you.
                          If there are exceptional circumstances that mean we
                          can refuse to provide the information, we will explain
                          them. If requests are frivolous or vexatious, we
                          reserve the right to refuse them. If answering
                          requests is likely to require additional time or
                          unreasonable expense (which you may have to meet), we
                          will inform you.
                        </li>
                        <li>
                          The right to rectification: If you believe we hold
                          inaccurate or incomplete personal information about
                          you, you may exercise your right to correct or
                          complete this data. This may be used with the right to
                          restrict processing to make sure that
                          incorrect/incomplete information is not processed
                          until it is corrected.
                        </li>
                        <li>
                          The right to erasure: Where no overriding legal basis
                          or legitimate reason continues to exist for processing
                          personal data, you may request that we delete the
                          personal data. This includes personal data that may
                          have been unlawfully processed. We will take all
                          reasonable steps to ensure erasure.
                        </li>
                        <li>
                          The right to restrict processing: You may ask us to
                          stop processing your personal data. We will still hold
                          the data but will not process it any further. This
                          right is an alternative to the right to erasure. If
                          one of the following conditions applies, you may
                          exercise the right to restrict processing:
                          <ol>
                            <li>
                              The accuracy of the personal data is contested.
                            </li>
                            <li>
                              Processing of the personal data is unlawful.
                            </li>
                            <li>
                              We no longer need the personal data for
                              processing, but the personal data is required for
                              part of a legal process.
                            </li>
                            <li>
                              The right to object has been exercised and
                              processing is restricted pending a decision on the
                              status of the processing.
                            </li>
                          </ol>
                        </li>
                        <li>
                          The right to data portability: You may request your
                          set of personal data be transferred to another
                          controller or processor, provided in a commonly used
                          and machine-readable format. This right is only
                          available if the original processing was on the basis
                          of consent, the processing is by automated means and
                          if the processing is based on the fulfilment of a
                          contractual obligation.
                        </li>
                        <li>
                          The right to lodge a complaint: Any person may submit
                          a complaint to the Regulator in the prescribed manner
                          and form alleging interference with the protection of
                          the personal information of a data subject.
                        </li>
                        <li>
                          The right to object: You have the right to object to
                          our processing of your data under one of the following
                          conditions:
                          <ol>
                            <li>Processing is based on legitimate interest.</li>
                            <li>
                              Processing is for the purpose of direct marketing.
                            </li>
                            <li>
                              Processing is for the purposes of scientific or
                              historic research.
                            </li>
                            <li>
                              Processing involves automated decision-making and
                              profiling.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li id="complaints-disputes">
                  Complaints & Disputes
                  <ol>
                    <li>
                      Acumen Software support and provide the My Smart City
                      Platform and related Services using reasonable skill and
                      care. If a User is not satisfied with the Services
                      delivered, you agree to inform us by sending a complaint
                      to complaints@mysmart.city, and agree to consider and
                      assess the issue in an attempt to resolve your concern
                      and/or complaint.
                    </li>
                    <li>
                      The following information is to be included in your
                      complaint:
                      <ol>
                        <li>
                          Your full names, physical address, telephone number
                          and email address;
                        </li>
                        <li>
                          The location and description of the Service feature
                          which is the cause of your complaint;
                        </li>
                        <li>
                          The problem which is the subject of the complaint;
                        </li>
                        <li>
                          The actions you would like us to take to remedy the
                          problem;
                        </li>
                        <li>
                          A statement confirming that you are making the
                          complaint in good faith;
                        </li>
                        <li>
                          A statement confirming that the information you are
                          providing to us is to the best of your knowledge true
                          and correct; and
                        </li>
                        <li>
                          Please incorporate your signature into the complaint.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The use the My Smart City Platform is subject to the laws
                      of the Republic of South Africa, and the exclusive
                      jurisdiction of the Western Cape High Court, Cape Town
                      provided that if any South African Magistrate&#39;s Court
                      has competent jurisdiction over your person to adjudicate
                      on any dispute arising from or in connection with these
                      terms of use, such Magistrate&#39;s Court will also have
                      jurisdiction to adjudicate the dispute notwithstanding
                      that the amount in dispute may exceed such court&#39;s
                      jurisdiction.
                    </li>
                    <li>
                      Acumen Software&#39;s failure to enforce any provision of
                      this Agreement strictly will not be construed as a waiver
                      of any provision or right. In the event that a portion of
                      this agreement is held unenforceable or invalid by any
                      competent authority, the unenforceable portion will be
                      construed in accordance with applicable law as nearly as
                      possible to reflect the original intent thereof, and the
                      remainder of the provisions will remain in force and
                      effect to the fullest extent permitted by the law.
                    </li>
                  </ol>
                </li>

                <li id="severability">
                  Severability
                  <ol>
                    <li>
                      If any provision or term of this Agreement, including this
                      clause, shall be held wholly or partly invalid, illegal or
                      unenforceable for any reason whatsoever, then those
                      clauses, provisions or terms shall be deemed severable
                      from the remaining clauses, provisions or terms of this
                      Agreement and shall in no way affect the validity,
                      legality or enforceability of this Agreement.
                    </li>
                    <li>
                      Furthermore, if any provision of these Terms of Condition
                      is found by a court of competent jurisdiction to be
                      invalid as detailed above, the parties nevertheless agree
                      that the court should endeavour to give effect to the
                      partie&#39;s intentions as reflected in the provision.
                    </li>
                  </ol>
                </li>

                <li id="entire-agreement">
                  Entire Agreement
                  <ol>
                    <li>
                      These general Terms and Conditions represent the entire
                      Agreement between the User and Acumen Software and
                      supersedes all prior offers, contracts, agreements and
                      representations. The Terms and Conditions supersede all
                      promises made to you by Acumen Software client services
                      agents, representatives or employees.
                    </li>
                  </ol>
                </li>

                <li id="petitions">
                  Petitions
                  <ol>
                    <li>
                      Please allow up to 48 hours for our moderators to approve
                      your petition. The My Smart City team reject petitions
                      containing profanity, racial discrimination, slander or
                      inappropriate images and language. Petitions may be
                      reclassified as reports at our discretion, and/or deleted.
                    </li>
                  </ol>
                </li>
                <li id="booking-a-service">
                  Booking a Service
                  <ol>
                    <li>
                      {' '}
                      These terms and conditions (“Terms”) are applicable to
                      anyone who uses Private Services offering of the My Smart
                      City platform run by Acumen Software (Pty) Ltd (“My Smart
                      City”). These terms are in addition to the My Smart City
                      Terms and Conditions and any other My Smart City policies
                      implemented from time to time. Users are responsible for
                      ensuring they read these Terms carefully before accessing
                      or using the My Smart City platform. My Smart City will
                      assume you have read and understood these Terms should you
                      continue to access or make use of the My Smart City
                      Platform.{' '}
                    </li>
                    <li>
                      Relationship Between the Parties
                      <ol>
                        <li>
                          MY SMART CITY PROVIDES SOFTWARE SERVICES AND IS NOT AN
                          AGENT, INTERMEDIARY OR PARTNER OR OTHER ADVISER TO ANY
                          USER. ALL TOOLS PROVIDED ON THE PLATFORM OR AS PART OF
                          THE PLATFORM SERVICES ARE FOR INFORMATION PURPOSES
                          ONLY.{' '}
                        </li>
                        <li>
                          Users understand and expressly agree that a service
                          provider is at all times an independent contractor,
                          and is not an employee, partner or agent of My Smart
                          City&#39;s in any regard. Each service provider is
                          responsible and liable for their services.
                        </li>

                        <li>
                          Although, My Smart City takes reasonable steps to
                          verify the identity and background of service
                          providers joining the platform, it cannot guarantee
                          the reliability, quality, or suitability of any
                          service provider or their provision of services.
                        </li>
                        <li>
                          Where users decide to engage with the services of a
                          service provider on the platform, they do so of their
                          own volition. My Smart City shall in no way be liable
                          for the conduct of any service provider following a
                          user&#39;s engagement with a service provider and use
                          of their services.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Nature of service
                      <ol>
                        <li>
                          My Smart City is a platform which operates as a
                          marketplace allowing users to search through various
                          service providers who provide their private services
                          directly to users.
                        </li>
                        <li>
                          All service providers are independent contractors of
                          My Smart City.
                        </li>
                      </ol>
                    </li>
                    <li>
                      User warranties
                      <ol>
                        Users warrant and agree:
                        <li>
                          to treat service providers courteously and lawfully;{' '}
                        </li>
                        <li>
                          to provide a safe working environment for service
                          providers
                        </li>
                        <li>
                          to provide reasonable co-operation with service
                          providers for the supply of services;
                        </li>
                        <li>
                          to submit any complaints to My Smart City in writing
                          and not to any service provider directly and thereby
                          comply with My Smart City’s complaints provision below
                          and other policies;
                        </li>
                        <li>
                          to acknowledge service providers may be unavailable
                          from time to time due to illness and/or other lawful
                          reasons;
                        </li>
                        <li>
                          not to book any service provider outside of the My
                          Smart City platform. Any user found guilty of
                          violating this warranty shall be liable for a fee of
                          approximately R 1 250 (one thousand two hundred and
                          fifty Rand) excluding VAT due and payable within 7
                          (seven) days following the unscheduled appointment;
                        </li>
                        <li>
                          not to contact, canvass or solicit for direct or
                          indirect employment any service provider for a period
                          of 6 (six) months following a user’s termination of
                          their use of the My Smart City platform. Provided that
                          nothing in these Terms shall prohibit any genuine
                          offer of employment when made in response to a general
                          advertisement for the same.{' '}
                        </li>
                      </ol>
                    </li>
                    <li>
                      Payment terms
                      <ol>
                        <li>
                          The purchasing of private services by a user will
                          attract a fee to be charged by My Smart City and to be
                          paid by the user.
                        </li>
                        <li>
                          Once a service provider is selected by a user My Smart
                          City’s hourly rate for the service requested will be
                          displayed to the user on the platform (“Fee”).{' '}
                        </li>
                        <li>
                          An order placed by a user on the platform is
                          considered an offer to be accepted. An order is only
                          accepted and confirmed as such when an email is sent
                          to the user confirming their order and the appointment
                        </li>

                        <li>
                          The Fee for any service shall be due prior to the
                          requested service date to confirm a user’s
                          appointment.
                        </li>
                        <li>
                          My Smart City reserves the right to establish, remove
                          and/or revise any fees for any or all services
                          obtained through the use of the platform at any time
                          in My Smart City’s sole discretion. My Smart City will
                          use reasonable efforts to inform you of all charges or
                          fees that may apply to you, provided that you will be
                          responsible for the payment of all fees properly
                          incurred through the platform.
                        </li>
                        <li>
                          All amounts stated shall include Value Added Tax
                          (“VAT”) where applicable and will be inclusive of any
                          other applicable taxes/fees, unless otherwise stated
                          and required by law. All applicable taxes, duties or
                          fees will be detailed to you before you affect any
                          transaction.
                        </li>
                        <li>
                          Users that seek to utilize the platform and services
                          on a repeated basis may authorise My Smart City to
                          create a recurring payment scheduled to deduct the
                          requisite Fee after the end time for the previous
                          appointment.
                        </li>
                        <li>
                          To obtain a full record of your payments for any
                          transaction/s conducted via the platform, please
                          contact jade.bertels@acumensoft.net who will gladly
                          assist.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Credits, Vouchers, Promotions and Discounts
                      <p>
                        My Smart City may from time to time, provide certain
                        users with promotional offers and discounts that may
                        result in different amounts charged for the same or
                        similar services obtained through the use of the
                        platform and you agree that such promotional offers and
                        discounts, unless also made available to you, shall have
                        no bearing on your use of the platform or the fees
                        applied to you.
                      </p>
                      <ol>
                        <li>
                          Where a user receives any credit, voucher, or discount
                          from My Smart City they understand and agree that such
                          credit, voucher, or discount:
                          <ol>
                            <li>
                              is not transferable or assignable to any person;
                            </li>
                            <li>
                              must be redeemed in accordance with the
                              instructions and timeframe provided by My Smart
                              City;
                            </li>
                            <li>
                              must only be used on the platform with a valid My
                              Smart City account
                            </li>
                            <li>
                              is only valid for referrals to users who do not
                              have a My Smart City account;
                            </li>
                            <li>must not be abused in any manner;</li>
                          </ol>
                        </li>

                        <ol>
                          <TermsParagraph>
                            {' '}
                            There may be promotions with specific terms and
                            conditions applicable to those promotions.
                          </TermsParagraph>
                        </ol>

                        <li>
                          General coupon rules:
                          <ol>
                            <li>
                              only one coupon shall be issued per user and/or
                              per residence;
                            </li>
                            <li>
                              any coupon provided shall be set off against the
                              value of the order booked;
                            </li>
                            <li>
                              My Smart City takes no responsibility if coupon
                              cannot be applied by a user;
                            </li>
                            <li>
                              where a user cancels their booking they
                              automatically forfeit their coupon
                            </li>
                          </ol>
                        </li>
                        <li>
                          Referral Rules:
                          <ol>
                            <li>
                              referral discount codes are only redeemable on
                              platform
                            </li>
                            <li>
                              a referral code can only be used by friends or
                              family;
                            </li>
                            <li>
                              referral discounts cannot be used to buy vouchers
                              or credit on the platform
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>

                    <li>
                      Cancellations
                      <ol>
                        All appointments and the cancellation and/or
                        rescheduling thereof shall be subject to the My Smart
                        City Cancellation and Rescheduling policies.
                      </ol>
                    </li>
                    <li>
                      Liability
                      <ol>
                        My Smart City, its shareholders, employees, and
                        partners, accept no liability whatsoever for any loss,
                        whether direct or indirect, consequential, or arising
                        from information made available on (or by means of) the
                        platform, other users thereon, and/or transactions or
                        actions resulting therefrom, including a user’s use of
                        any service provider in any way.
                      </ol>
                      <ol>
                        My Smart City, its shareholders, employees, partners,
                        and affiliates, accept no liability whatsoever for any
                        costs, expenses, fines, or damages, including but not
                        limited to direct or indirect loss or damages, including
                        any economic loss, consequential loss, loss of profits
                        or any form of punitive damages, resulting from the
                        facilitation and offering of services on the platform,
                        and access to, or use of, the platform in any manner.
                      </ol>
                      <ol>
                        The user indemnifies and holds harmless My Smart City,
                        its shareholders, employees, and partners from any
                        demand, action, regulation or application or other
                        proceedings, including for attorneys’ fees and related
                        costs such as tracing fees, made by any third party, and
                        arising out of or in connection with the user’s use of
                        the platform or services offered or concluded through
                        the platform in any way.
                      </ol>
                      <ol>
                        The user agrees to indemnify, defend, and hold My Smart
                        City harmless from any direct or indirect liability,
                        loss, claim and expense (including reasonable legal
                        fees) related to the user’s use of the platform and/or
                        the services thereon and for breach of these Terms.
                      </ol>
                    </li>
                    <li>
                      Data Processing and Disclosure
                      <ol>
                        Your use of the platform constitutes your express
                        consent for us to process your personal data. Further,
                        we also rely on our contract of service with you as
                        service provider and/or user to process your data (a
                        lawful ground to process your data other than express
                        consent).
                      </ol>
                      <ol>
                        Users and service providers understand and agree that in
                        order to provide them with our services we need to share
                        their data with third party service providers who assist
                        us to perform our services. Users and service providers
                        therefore expressly agree to the sharing of their
                        personal data with our authorised third-party service
                        providers to ensure they have the best experience on the
                        My Smart City platform.{' '}
                      </ol>
                      <ol>
                        My Smart City warrants that all information shall be
                        processed (by My Smart City and/or third-party service
                        providers) in accordance with the Protection of Personal
                        Information Act 4 of 2013, the United Kingdom’s Data
                        Protection Act, 2018, and the EU’s General Data
                        Protection Regulation, 2017.
                      </ol>
                    </li>
                    <li>
                      Complaints
                      <ol>
                        Where any user is unsatisfied with the service they have
                        received they may submit a complaint to My Smart City
                        via the platform.
                      </ol>
                      <ol>
                        My Smart City will thereafter assess the user’s
                        complaint, and where necessary offer credit to user for
                        the purchase for further services on the platform.
                      </ol>
                      <ol>
                        My Smart City will consider complaints which relate to
                        poorly completed tasks and/or not completed tasks by
                        service providers.
                      </ol>
                      <ol>
                        My Smart City shall not consider complaints where a
                        service does not violate these Terms and is reported
                        after a period of 72 (seventy-two) hours following the
                        date of the appointment.
                      </ol>
                    </li>
                    <li>
                      Insurance
                      <ol>
                        My Smart City works with various partners in the
                        insurance industry and offers users the option of
                        including opt-in insurance for certain services booked
                        on the platform.
                      </ol>
                      <ol>
                        All insurance policies are provided by authorised
                        financial service providers and shall be subject to the
                        insurance providers terms and conditions.
                      </ol>
                      <ol>
                        Any claim submitted by a user shall be processed by My
                        Smart City’s authorised broker, for which My Smart City
                        shall have no further obligation or association
                        thereafter.
                      </ol>
                    </li>
                    <li>
                      Dispute Resolution
                      <ol>
                        Should any dispute, disagreement or claim arise between
                        a user and My Smart City concerning use of the platform
                        or the services, the parties shall endeavour to resolve
                        the dispute amicably, by negotiation, and with the best
                        interests of both parties in mind.{' '}
                      </ol>
                      <ol>
                        Should the parties fail to resolve such dispute in the
                        aforesaid manner or within such further period as the
                        parties may agree to in their negotiation, the parties
                        will approach an independent industry expert who shall
                        mediate the discussion between them, for the purposes of
                        finding a mutually beneficial solution.
                      </ol>
                      <ol>
                        Either party may also always use the dispute resolution
                        services of any applicable legislative tribunal or
                        ombud, as provided for in applicable legislation. They
                        may also use the services of any arbitration body for
                        the purposes of arbitration proceedings.
                      </ol>
                      <ol>
                        The parties both agree that in no circumstance will
                        either party publicize the dispute on any social media
                        or other public platform. The parties understand that
                        any publicity of this nature can cause serious damage to
                        the other party, which damage may result in a financial
                        claim against the infringing party.
                      </ol>
                    </li>
                  </ol>
                </li>

                <li id="contact-us">
                  Contact us
                  <ol>
                    <li>
                      Any comments or queries related to this privacy policy
                      should be directed to Support@mysmart.city. Queries to the
                      Data Protection Officer can be made to the same email
                      address and will be forwarded accordingly.
                    </li>
                  </ol>
                </li>
              </ol>
            </TermsContainer>
          </Col>
        </Row>
      </Section>
      <Footer />
    </>
  );
}
