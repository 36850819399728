import styled from 'styled-components';

export const ModalInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 0 5px;

h4 {
  font: normal normal 500 12px/14px Roboto;
  padding: 0;
  margin: 0;
  color: black;

  @media only screen and (min-width: 800px) {
    font: normal normal 500 16px/19px Roboto;
  }
  }

  p {
    font: normal normal normal 12px/14px Roboto;
    letter-spacing: 0px;
    color: #B2BCC1;

    @media only screen and (min-width: 800px) {
      font: normal normal normal 16px/19px Roboto;
    }
  }
`;

export const ProgressHolder = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;
  margin-bottom: 30px;

  @media only screen and (min-width: 800px) {
    margin-bottom: 50px;
  }
  `;

export const StageCountCircle = styled.div`
  width: 18px;
  height: 18px;
  background: ${({ active }) => ((active) ? '#DF6E21' : 'white')} ${({ done }) => done && 'url(inthecity/purchase/paymentIcon/icon_Select.png)'};
  background-size: 18px;
  background-position: center;
  font: normal normal normal 10px/18px Roboto;
  color: ${({ active }) => (active ? 'white' : '#DEE2E6')};
  color: ${({ done }) => (done && 'transparent')};
  text-align: center;
  border-radius: 50%;
  border: 1px solid ${({ active }) => (active ? '#DF6E21' : '#DEE2E6')};
  user-select: none;
  position: relative;

  @media only screen and (min-width: 800px) {
    width: 25px;
    height: 25px;
    font: normal normal normal 12px/25px Roboto;
    background: ${({ active }) => ((active) ? '#DF6E21' : 'white')} ${({ done }) => done && 'url(inthecity/purchase/paymentIcon/icon_Select.png)'};
  }
  `;

export const ProgressLabel = styled.p`
  position: absolute;
  top: 25px;
  left: -15px;
  text-align: center;
  font: normal normal normal 10px/12px Roboto !important;
  letter-spacing: 0px;
  color: Black !important;
  margin: 0px;
  padding: 0px;
  
  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/19px Roboto !important;
    top: 30px;
  }
`;

export const ConfirmationLabel = styled(ProgressLabel)`
  left: -25px;

  @media only screen and (min-width: 800px) {
    left: -32px;
  }
`;

export const ProgressLine = styled.div`
  height: 2px;
  background: ${({ active }) => (active ? '#DF6E21' : '#DEE2E6')};
  width: 20%;

  @media only screen and (min-width: 800px) {
    height: 3px;
    width: 20%;
  }
`;

export const Line = styled.div`
  opacity: 0.46;
  width: 100%;
  height: 1px;
  background-color: #DEE2E6;
  margin: 15px 0;

  @media only screen and (min-width: 800px) {
  height: 3px;
  margin: 30px 0;
  }
`;

export const Button = styled.div`
  text-align: center;
  background: ${(props) => (props.valid ? '#DF6E21' : '#DEE2E6')} 0% 0% no-repeat padding-box;
  border-radius: 5px; 
  width: 100%;
  height: 45px;
  margin-top: auto;
  cursor: ${(props) => (props.valid ? 'pointer' : 'not-allowed')};

  font: normal normal normal 16px/45px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;

  @media only screen and (min-width: 800px) {
    height: 67px;
    font: normal normal normal 20px/67px Roboto;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  height: 68px;
  margin: 10px 0;
  align-items: center;
  border: 2px solid #DEE2E6;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;

  @media only screen and (min-width: 800px) {
    height: 80px;
  }

  h3 {
    font: normal normal normal 20px/24px Roboto;
    letter-spacing: 0px;
    color: #B2BCC1;
    margin: auto 0;
    margin-left: 30px;
  }
`;

export const Image = styled.img`
  width: 52px;
  height: 45px;
  margin: 12px; 


  @media only screen and (min-width: 800px) {
    width: 52px;
    height: 49px;
    margin: 16px;
  }


`;

export const InfoColumn = styled.div`
display: flex;
flex-direction: column;
width: 109px ;
background-color: white;
height: 49px;
justify-content: center;

h3 {
  font: normal normal normal 14px/19px Roboto;
  margin: auto 0px;
  padding: 0px;
  color: #0A0918;


  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/19px Roboto;
  }
}


h2 {
  font: normal normal normal 12px/14px Roboto;
  letter-spacing: 0px;
  color: #0A0918; 
  margin: 0px;
  padding: 0px;

  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/19px Roboto;
    margin: 0px;
  }
}

p {
  font: normal normal normal 12px/14px Roboto;
  letter-spacing: 0px;
  color: #B2BCC1;
  margin: 0px;
  margin-top: 4px;
  padding: 0px;
}

`;

export const ErrorLabel = styled.p`
  font: normal normal normal 10px/24px, 'Roboto' !important;
  display: ${(props) => (props.valid ? 'none' : 'block')};
  color: red !important;
  margin: 0;
  /* position: relative; */
  padding: 0px;
  margin-top: -10px;
  height: 24px;
  margin-bottom: 10px;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/24px, 'Roboto' !important;
  }
`;
