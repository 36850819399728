/* eslint-disable react/jsx-props-no-spreading */
import { toUnix } from 'functions/date';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { cloneDeep } from 'lodash';
import { useContext, createContext, useEffect } from 'react';
import {
  setBooking as setBookingRedux,
  setBookingStep as setBookingStepRedux,
  setTotalDuration as setTotalDurationRedux,
  setPrice as setPriceRedux,
  setServiceOption as setServiceOptionRedux,
} from 'redux/booking';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SmartCityServiceBooking, SmartCityService } from 'types/types';

type BookingSteps = 'details' | 'summary';

interface SmartCityBookingContext {
  booking: SmartCityServiceBooking;
  price: number;
  priceLoading?: boolean;
  totalDuration: number;
  maxDuration: number;
  getServiceOptionCurrent: (group: string, serviceLabel: string) => void;
  setServiceOptionCurrent: (
    group: string,
    serviceLabel: string,
    current: number,
    renderIdx: number
  ) => void;
  setBooking: (booking: SmartCityServiceBooking) => void;
  setBookingDuration: (duration: number) => void;
  setResource: (id: number, name: string, profileUrl: string) => void;
  setBookingTimes: (date: Date) => void;
  clearResource: () => void;
  bookingStep: BookingSteps;
  setBookingStep: (step: BookingSteps) => void;
}

const initial: SmartCityBookingContext = {
  booking: {
    smartCityServiceGroups: [],
    bookingInformation: null,
  },
  price: 0,
  totalDuration: 1,
  maxDuration: 8,
  bookingStep: 'details',
  getServiceOptionCurrent: () => null,
  setServiceOptionCurrent: () => null,
  setBooking: () => null,
  setBookingDuration: () => null,
  setResource: () => null,
  setBookingTimes: () => null,
  clearResource: () => null,
  setBookingStep: () => null,
};

const MakeABookingContext = createContext(initial);

const MakeABookingProvider = ({ children }: { children: any }) => {
  const {
    booking: { booking, totalDuration, price, bookingStep },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const setBooking = (b: SmartCityServiceBooking) =>
    dispatch(setBookingRedux(b));
  const setTotalDuration = (d: number) => dispatch(setTotalDurationRedux(d));
  const setPrice = (p: number) => dispatch(setPriceRedux(p));
  const setBookingStep = (s: BookingSteps) => dispatch(setBookingStepRedux(s));

  const clearResource = () => {
    setBooking({
      ...booking,
      bookingInformation: {
        ...booking.bookingInformation,
        resourceId: undefined,
        resourceName: undefined,
      },
    });
  };

  const setResource = (id: number, name: string, profileUrl: string) =>
    setBooking({
      ...booking,
      bookingInformation: {
        ...booking.bookingInformation,
        resourceId: id,
        resourceName: name,
        resourceProfileUrl: profileUrl,
      },
    });

  const setBookingTimes = (date: Date) => {
    setBooking({
      ...booking,
      bookingInformation: {
        ...booking.bookingInformation,
        startDateTime: toUnix(date),
        endDateTime: toUnix(
          new Date(date.getTime() + totalDuration * 60 * 60 * 1000)
        ),
      },
    });
  };

  const setBookingDuration = (duration: number) => setTotalDuration(duration);
  const maxDuration = 8;

  const {
    error: priceError,
    callApi: getPrice,
    response: priceResponse,
    // loading: priceLoading,
  } = useSmartCityManager(SCManagerEndpoints.GetEstimatedCost);

  useEffect(() => {
    getPrice({ body: booking });

    if (priceError) {
      console.log('Error fetching price', priceError);
    }
  }, [booking]);

  useEffect(() => {
    if (priceResponse) {
      setPrice(priceResponse);
    }
  }, [priceResponse]);

  const getServiceOption = (group: string, serviceLabel: string) => {
    if (booking.smartCityServiceGroups.length === 0) {
      return null;
    }
    const groupIndex = booking.smartCityServiceGroups.findIndex(
      (g) => g.heading === group
    );

    const serviceIndex = booking.smartCityServiceGroups[
      groupIndex
    ].smartCityServices.findIndex((s) => s.label === serviceLabel);

    if (
      typeof groupIndex !== 'number' ||
      typeof serviceIndex !== 'number' ||
      serviceIndex === -1
    ) {
      return null;
    }
    const serviceGroups = booking.smartCityServiceGroups.slice();
    const serviceToEdit =
      serviceGroups[groupIndex].smartCityServices[serviceIndex];
    return serviceToEdit;
  };

  const setServiceOption: any = (
    service: SmartCityService,
    group: string,
    renderIdx: number
  ) => dispatch(setServiceOptionRedux({ service, group, renderIdx }));

  const getServiceOptionCurrent = (group: string, serviceLabel: string) =>
    getServiceOption(group, serviceLabel)?.current;

  const setServiceOptionCurrent = (
    group: string,
    serviceLabel: string,
    current: number,
    renderIdx: number
  ) => {
    const serviceToEdit = cloneDeep(getServiceOption(group, serviceLabel));
    if (serviceToEdit) {
      serviceToEdit.current = current;
      setServiceOption(serviceToEdit, group, renderIdx);
    } else {
      console.log('NO SERVICE OPTION FOUND :(');
    }
  };

  /* Note: having the priceLoading state available in
   * the future might prove useful, but it's not being used right now.
   */
  const value: SmartCityBookingContext = {
    booking,
    price,
    // priceLoading,
    clearResource,
    getServiceOptionCurrent,
    setServiceOptionCurrent,
    setBooking,
    setBookingDuration,
    totalDuration,
    setResource,
    maxDuration,
    setBookingTimes,
    bookingStep,
    setBookingStep,
  };

  return (
    <MakeABookingContext.Provider value={value}>
      {children}
    </MakeABookingContext.Provider>
  );
};

export const useMakeABookingContext = () => useContext(MakeABookingContext);

export default MakeABookingProvider;
