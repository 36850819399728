/* eslint-disable max-len */
import { parseStringPromise } from 'xml2js';
// import { Cookies } from 'react-cookie';

import { apiExternalUrl } from 'constants/app';

// const cookies = new Cookies();

/**
 * Webtickets is used to get the information for the Whats-on-in-the-city section.
 * When a user clicks on one of the tickets they are to be taken to
 * the webtickets page using an in-app browser to their page with a referral number.
 * Note that their API returns XML and not JSON.
 */

// { Example: [''] } -> { example: '' }
const mapObj = (obj) => Object.fromEntries(Object.entries(obj).map(([k, v]) => {
  if (k === 'ID') return ['id', v[0]];
  return [k.slice(0, 1).toLowerCase() + k.slice(1, k.length), v[0]];
}));

/**
 * Categories for events
 * Populate the categories bar (whats on in my city)
 * @returns {Promise<EventCat[]>}
 */
export const getEventCategories = async () => fetch(
  `${apiExternalUrl}webtickets/passoff/GetEventCategories`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.text())
  .then((xml) => parseStringPromise(xml))
  .then((jsonRes) => jsonRes.ArrayOfEventCatData.EventCatData.map((EventCat) => mapObj(EventCat)))
  .catch((e) => {
    console.log(e);
    return [{ category: '' }];
  });

/**
 * Search Webtickets events
 * Populate search and suggested searches comes from titles of 'featured'
 * @param {string} query search query
 * @returns {Promise<Event[]>}
 */
export const searchEvents = async (query) => fetch(
  `${apiExternalUrl}webtickets/passoff/SearchWebticketsEvents`
  + `?szSearch=${query}`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.text())
  .then((xml) => parseStringPromise(xml))
  .then(({ ArrayOfEventData }) => {
    if (ArrayOfEventData?.EventData) {
      return ArrayOfEventData.EventData.map((Event) => mapObj(Event));
    }
    return [];
  })
  .catch((e) => console.log(e));

/**
 * TODO: Awaiting feedback from Webtickets to resolve this issue.
 *
 * Populate the sections with events
 * Sections to be defined by the first 5 category of the GetEventCategories call
 * @param {number} pageNo
 * @param {number} recsPerPage
 * @param {string} dtFrom
 * @param {string} dtTo
 * @param {number} categoryId
 * @param {number} provinceId
 * @returns {Promise<Event[]>}
 */
export const getAllEventsByDateCategoryLocation = async (
  pageNo = 1,
  recsPerPage,
  dtFrom,
  dtTo,
  categoryId,
  provinceId,
) => fetch(
  `${apiExternalUrl}webtickets/passoff/GetAllEventsByDateCategoryLocation`
  + `?PageNo=${pageNo}`
  + `&RecsPerPage=${recsPerPage}`
  + `&dtFrom=${dtFrom}`
  + `&dtTo=${dtTo}`
  + `&intCategoryId=${categoryId}`
  + `&ProvinceId=${provinceId}`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.text())
  .then((xml) => parseStringPromise(xml))
  .then((jsonRes) => jsonRes.ArrayOfEventData.EventData.map((Event) => mapObj(Event)))
  .catch((e) => console.log(e));

/**
 * Get some events
 * @param {number} categoryId
 * @returns {Promise<Event[]>}
 */
export const getEventsByCategory = async (categoryId = 1184163) => fetch(
  `${apiExternalUrl}webtickets/passoff/GetEventsByCategory`
  + `?intCategoryId=${categoryId}`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.text())
  .then((xml) => parseStringPromise(xml))
  .then((jsonRes) => jsonRes.ArrayOfEventData.EventData.map((Event) => mapObj(Event)))
  .catch((e) => {
    console.log(e);
    return [{ name: 'Service unavailable' }];
  });

/**
 * Event view
 * @param {number} eventID
 * @param {string} countryCode
 * @returns {Promise<Event[]>}
 */
export const getEvent = async (eventID = 1184163, countryCode = 'za') => fetch(
  `${apiExternalUrl}api/webtickets/passoff/GetEventInfo`
  + `?intEventID=${eventID}`
  + `&szCountryCode=${countryCode}`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.text())
  .then((xml) => parseStringPromise(xml))
  .then((jsonRes) => jsonRes.ArrayOfEventData.EventData.map((Event) => mapObj(Event)))
  .catch((e) => console.log(e));

/**
 * Get ticket / performance information for Event
 * @param {number} eventID
 * @param {string} countryCode
 * @param {string} dtFrom The date from NextPerformanceDate in Event
 * @returns {Promise<Performance[]>}
 */
export const getPerformancesByEvent = async (
  eventID = 1184163,
  countryCode = 'za',
  dtFrom,
) => fetch(
  `${apiExternalUrl}api/webtickets/passoff/GetPerformancesByEvent`
  + `?EventID=${eventID}`
  + `&CountryCode=${countryCode}`
  + `&dtFrom=${dtFrom}`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.text())
  .then((xml) => parseStringPromise(xml))
  .then(({ ArrayOfPerformanceData }) => {
    if (ArrayOfPerformanceData?.PerformanceData) {
      return ArrayOfPerformanceData.PerformanceData.map((Performance) => mapObj(Performance));
    }
    return [];
  })
  .catch((e) => console.log(e));
