/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from 'components/elements';
import { Loader } from 'components/inthecity';
import { Box, Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { useEffect } from 'react';
import theme from 'theme';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { GMSurveySummary } from 'types/types';
import { convertToReadableDate } from 'functions/date';
import { useNavigate, useParams } from 'react-router-dom';

const SurveysPage = () => {
  const { cityId } = useParams();
  const {
    loading: gettingSurveys,
    response: availableSurveys,
    callApi: getSurveys,
  } = useSmartCityManager(SCManagerEndpoints.GetAvailableSurveys);
  const navigate = useNavigate();

  useEffect(() => {
    getSurveys();
  }, []);

  return (
    <MunicipalPageWrapper title="Surveys">
      {gettingSurveys ? (
        <Loader />
      ) : (
        <>
          <Text textAlign="center" hideForMobile mb="20px" mt="30px">
            Which survey would you like to complete?
          </Text>
          <Grid
            gridTemplateColumns="repeat(3, 1fr)"
            gridRowGap="10px"
            gridColumnGap="10px"
            mx="auto"
            mobileCss={css`
              grid-template-columns: 1fr;
              margin: 0;
            `}
          >
            {availableSurveys?.map((survey: GMSurveySummary) => (
              <Box>
                <Button
                  onClick={() =>
                    navigate(
                      `/my-municipality/surveys/${cityId}/complete-survey/${survey.id}`
                    )
                  }
                  disabled={survey.availableTill * 1000 < Date.now()}
                  width="100%"
                  boxShadow={theme.cardShadow}
                  p="16px"
                  borderRadius="4px"
                  hoverCss={css`
                    color: ${theme.colors.primary};
                  `}
                >
                  <Text textAlign="center">{survey.description}</Text>
                </Button>
                <Text
                  textAlign="center"
                  my="10px"
                  color="#EA1B1B"
                  fontSize="10px"
                >
                  Closes: {convertToReadableDate(survey.availableTill)}
                </Text>
              </Box>
            ))}
            <Button
              onClick={() =>
                navigate(`/my-municipality/surveys/${cityId}/my-surveys`)
              }
              width="100%"
              height="fit-content"
              boxShadow={theme.cardShadow}
              p="16px"
              borderRadius="4px"
              hoverCss={css`
                color: ${theme.colors.primary};
              `}
            >
              <Text textAlign="center">My surveys</Text>
            </Button>
          </Grid>
        </>
      )}
    </MunicipalPageWrapper>
  );
};

export default SurveysPage;
