import { Button, Image, Modal } from 'components/elements';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import theme from 'theme';

const SuccessModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Confirmation">
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <Image source="icons/successicon.svg" alt="success" width="25%" mt="3%" />
      <Text mt="3%" mb="7%">
        Thank you for your suggestion.
      </Text>
      <Button
        backgroundColor={theme.colors.primary}
        borderRadius={theme.borderRadius.small}
        color="white"
        onClick={onClose}
        p="15px"
        width="100%"
      >
        Go to home
      </Button>
    </Flex>
  </Modal>
);

export default SuccessModal;
