import { useState } from 'react';

const useGetWardAreas = () => {
  const [wardAreas, setWardAreas] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getWardAreas = async () => {
    setLoading(true);
    setError(null);
    fetch('https://www.george.gov.za/wp-json/wp/v2/pages/900')
      .then((res) => res.json())
      .then((res) => {
        const htmlString: string = res.content.rendered
          .replaceAll('\\"', '"')
          .replaceAll('<\\', '<');
        setWardAreas(htmlString);
        setLoading(false);
      })
      .catch((e) => setError(e));
  };

  return { wardAreas, loading, error, getWardAreas };
};

export default useGetWardAreas;
