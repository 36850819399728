import styled, { keyframes } from 'styled-components';

export const Label = styled.p`
  color: #0A0918;
  padding: 0;
  margin: 0;
  margin-right: 25px;
  text-align: right;
  font: normal normal normal 12px/15px Roboto;
  
  @media only screen and (min-width: 800px) {
    font: normal normal normal 18px/20px Roboto;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const QuestionContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const QuestionRow = styled.div`
  display: flex;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
`;

export const Select = styled.select`
  user-select: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DEE2E6;
  border-radius: 2px;
  height: 30px;
  color: #0A0918;
  padding: 0 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  flex-shrink: 0;
  color: #0A0918;
  font: normal normal normal 12px/15px Roboto;
  margin-right: 50px;

  &::placeholder {
    color: #0A0918;
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 0 0 3px #DF6E213E;
    border-color: transparent;
  }

  @media only screen and (min-width: 800px) {
    height: 40px;
    padding: 0 30px;
    border: 2px solid #DEE2E6;
    border-radius: 5px;
    background-size: 12px;
    font: normal normal normal 18px/20px Roboto;
  }
`;

export const Submit = styled.button`
  display: flex;
  align-items: center;
  background: #DF6E21 0% 0% no-repeat padding-box;
  border-style: none;
  border-radius: 5px;
  height: 45px;
  font: normal normal normal 16px/19px 'Roboto';
  color: #FFFFFF;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;
  padding: 0 20px;

  &:hover {
    background: #CB641E 0% 0% no-repeat padding-box;
  }

  &:disabled {
    background: rgba(178, 188, 193, 0.21) 0% 0% no-repeat padding-box;
    color: #B2BCC1;
  }

  @media only screen and (min-width: 800px) {
    height: 67px;
    padding: 0 20px;
    font: normal normal normal 20px/26px Roboto;
  }
`;

export const SubmitContainer = styled.div`
  margin: 20px auto 20px auto;
`;

export const Input = styled.input`
  max-width: 200px;
  margin-left: auto;
  margin-right: 25px;
  border: 1px solid #DEE2E6;
  border-radius: 2px;
  font: normal normal normal 12px/15px Roboto;
  height: 30px;
  color: #0A0918;
  padding: 0 10px;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;

  &::placeholder {
    color: #B2BCC1;
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 0 0 3px #DF6E213E;
    border-color: transparent;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #DF6E213E, 0 0 0 1px #DF6E21;
    border-color: transparent;
  }

  @media only screen and (min-width: 800px) {
    height: 40px;
    padding: 0 20px;
    border: 2px solid #DEE2E6;
    border-radius: 5px;
    font: normal normal normal 18px/20px Roboto;
  }
`;

export const ErrorLabel = styled.p`
  font: normal normal normal 10px/24px Roboto;
  color: #D8000C;
  margin: 0;
  position: absolute;
  bottom: -20px;
  right: 25px;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/24px Roboto;
  }
`;

export const TextButton = styled.div`
  cursor: pointer;
  /* text-align: right; */
  /* margin-left: auto; */
  margin: 0 25px;
  color: #DF6E21;
  padding: 0;
  font: normal normal normal 12px/15px Roboto;
  
  @media only screen and (min-width: 800px) {
    font: normal normal normal 18px/20px Roboto;
  }
`;

export const AddAnotherContainer = styled.div`
  margin: 25px auto 100px auto;
`;

export const MessageContainer = styled.div`
  width: 60%;
  margin: 0 auto;
`;

export const Heading = styled.h2`
  text-align: center;
  font: normal normal 500 16px/21px Roboto;
  color: #333333;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

  @media only screen and (min-width: 800px) {
    font: normal normal 500 25px/33px Roboto;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  font: normal normal normal 10px/12px Roboto;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

  @media only screen and (min-width: 800px) {
    font: normal normal normal 16px/19px Roboto;
  }
`;

export const SubHeading = styled(Paragraph)`
  margin: 0 auto;
  margin-bottom: ${(props) => (props.last ? '100px' : '50px')};
  width: 90%;
  @media only screen and (min-width: 800px) {
    width: 60%;
  }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SmallLoader = styled.div`
  border: 2px solid hsl(23.999999999999993, 74.80314960629921%, 75%);
  border-top: 2px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spinAnimation} 0.5s linear infinite;
  margin-right: 10px;
  padding: 0;
  @media only screen and (min-width: 800px) {
    border: 3px solid hsl(23.999999999999993, 74.80314960629921%, 75%);
    border-top: 3px solid white;
    width: 30px;
    height: 30px;
  }
`;
