/* eslint-disable max-len */
import { apiUrl, apiUrlGMAPS } from 'constants/app';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

// export const errorHandler = (e) => {
//   if (e.error) {
//     switch (e.error) {
//       case 'Invalid token':
//         window.location.href = '/login';
//         break;
//       default:
//         console.log(e);
//     }
//   }
// };

export const isInCityBounds = async (latitude, longitude) =>
  fetch(
    `${apiUrl}smartcitymanager/isInCityBounds2` +
      `?latitude=${latitude}` +
      `&longitude=${longitude}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => console.log(e));

// Get current user
export const getCurrentSmartCityUser = async (uuid = '') =>
  fetch(`${apiUrl}smartcitymanager/getCurrentSmartCityUser?uuid=${uuid}`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));

// Search users
export const getSmartCityUsersByString = async (query) =>
  fetch(
    `${apiUrl}smartcitymanager/getSmartCityUsersByString?` +
      `searchString=${query}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

// Get all account's followed users
export const getSmartCityUsersFollowed = async (
  pageNo = '',
  limitPerPage = ''
) =>
  fetch(
    `${apiUrl}smartcitymanager/getSmartCityUsersFollowed?` +
      `pageNo=${pageNo}` +
      `&limitPerPage=${limitPerPage}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

// Get all account's followers
export const getSmartCityUserFollowers = async (
  pageNo = '',
  limitPerPage = ''
) =>
  fetch(
    `${apiUrl}smartcitymanager/getSmartCityUserFollowers?` +
      `pageNo=${pageNo}` +
      `&limitPerPage=${limitPerPage}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

// Follow user
export const followUser = async (uuid) =>
  fetch(`${apiUrl}smartcitymanager/followUser?` + `uuid=${uuid}`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

// Unfollow user
export const unFollowUser = async (uuid) =>
  fetch(`${apiUrl}smartcitymanager/unFollowUser?` + `uuid=${uuid}`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

// =============
// Notifications
// =============

/**
 * Get all unread notifications
 * @param {string} limitCurrentCity -
 * @returns {Promise<Object[]>}
 */
export const getUnreadNotifications = async (limitCurrentCity) =>
  fetch(
    `${apiUrl}smartcitymanager/getUnreadNotifications?` +
      `limitCurrentCity=${limitCurrentCity}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Unread notifications
 * @returns {number} Number of unread notifications
 */
export const getUnreadNotificationsCount = async () =>
  fetch(`${apiUrl}smartcitymanager/getUnreadNotificationsCount?`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Mark Notification as read
 * @param {string} uuid - Notification uuid
 */
export const markNotificationRead = async (uuid) =>
  fetch(`${apiUrl}smartcitymanager/markNotificationRead?` + `uuid=${uuid}`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * List of users that are marked as a person of interest and their current city matches the users city
 * @param {string} pageNo - Page number
 * @param {string} limitPerPage - Limit users per page
 * @returns {Promise<Object[]>} Returns a list of users
 */
export const getSuggestedConnections = async (pageNo = '', limitPerPage = '') =>
  fetch(
    `${apiUrl}smartcitymanager/getSuggestedConnections?` +
      `pageNo=${pageNo}` +
      `&limitPerPage=${limitPerPage}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

// =============
// News Feed
// =============

/**
 * Get news feed posts
 * Open WR's (flagged as feed) the user posted
 * Reposted feeds from followed users
 * Open WR's (flagged as feed) from users the user is following
 * Include userType
 * @param {number} radius - Radius filter
 * @param {string} dateFilters - E.g. "today", "yesterday", "last week", "last 2 weeks"
 * @param {string} categoryFilters -` Filter by role eg leave blank for all, "community leaders", "government officials", "citizens"
 * @param {number} pageNo - Page number
 * @param {number} startDate - Part of the Date filtering. 
 * @param {number} endDate - Part of the Date filtering. 
 * @returns {List<WorkRequest>}  A promise that contains an array of Post objects when fulfilled.
 */
export const getNewsFeedWorkRequests = async (
  radiusMeters = '',
  dateFilters = '',
  categoryFilters = '',
  pageNo = '',
  limitPerPage = '',
  startDate = '',
  endDate = ''
) =>
  fetch(
    `${apiUrl}smartcitymanager/getNewsFeedWorkRequestsForRadius?` +
      `radiusMeters=${radiusMeters}` +
      `&dateFilters=${dateFilters}` +
      `&categoryFilters=${categoryFilters}` +
      `&pageNo=${pageNo}` +
      `&startDate=${startDate}` +
      `&endDate=${endDate}` +
      `&limitPerPage=${limitPerPage}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get news feed posts for a specific user
 * @param {boolean} petition - if true only get petition posts, else return report and announcement posts
 * @param {number} uuid - user uuid
 * @returns {List<WorkRequest>} Returns an array of posts
 */
export const getNewsFeedWorkRequestsForUser = async (
  petition,
  uuid,
  pageNo = '',
  limitForPage = ''
) =>
  fetch(
    `${apiUrl}smartcitymanager/getNewsFeedWorkRequestsForUser?` +
      `petition=${petition}` +
      `&uuid=${uuid}` +
      `&pageNo=${pageNo}` +
      `&limitForPage=${limitForPage}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get Post
 * Return relevant work request with all data incl. notes (comments) and photo's
 * Include userType
 * @param {number} id - WR id
 * @returns {WorkRequest} Work Request object
 */
export const getWorkRequest = async (id) =>
  fetch(`${apiUrl}smartcitymanager/getWorkRequest?` + `id=${id}`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get comments for post
 * @param {number} workRequestId - WR id
 */
export const getCommentsForWorkrequest = async (id) =>
  fetch(
    `${apiUrl}smartcitymanager/getCommentsForWorkrequest?` +
      `workRequestId=${id}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Add comment (WR note) to post (WR)
 * Save comment entered as a note on the WR
 * @param {number} workRequestId - WR id
 * @param {string} comment - Comment text
 * @returns {Response} Response object
 */
export const commentOnWorkRequest = async (workRequestId, comment) =>
  fetch(
    `${apiUrl}smartcitymanager/commentOnWorkRequest?` +
      `workRequestId=${workRequestId}` +
      `&comment=${comment}`,
    {
      method: 'POST',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Upvote Reports and Announcements WRs
 *  User cannot upvote his/her own post
 *  Limit upvotes per user to 1
 * @param {number} id - WR id
 */
export const upVoteWorkRequest = async (id) =>
  fetch(`${apiUrl}smartcitymanager/upVoteWorkRequest?` + `id=${id}`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 *  Undo upvote
 * @param {number} id - WR id
 */
export const unUpVoteWorkRequest = async (id) =>
  fetch(`${apiUrl}smartcitymanager/unUpVoteWorkRequest?` + `id=${id}`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

export const signPetition = async () =>
  fetch(`${apiUrl}smartcitymanager/signPetition`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Share WR Post
 * @param {number} id - WR id
 * @returns {Response}
 */
export const shareWorkRequest = async (id) =>
  fetch(`${apiUrl}smartcitymanager/shareWorkRequest?` + `workRequestId=${id}`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Repost a post
 * User cannot repost his/her own post
 * @param {number} workRequestId - WR id
 * @returns {Response} Response object
 */
export const rePostWorkRequest = async (workRequestId) =>
  fetch(
    `${apiUrl}smartcitymanager/rePostWorkRequest?` +
      `workRequestId=${workRequestId}`,
    {
      method: 'POST',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Post WR to feed
 * @param {number} workRequestId - WR id
 * @returns {Response} Response object
 */
export const postWorkRequest = async (workRequestId = '', title = '') =>
  fetch(
    `${apiUrl}smartcitymanager/postWorkRequest?` +
      `workRequestId=${workRequestId}` +
      `&title=${title}`,
    {
      method: 'POST',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get open Reports WRs the user has logged
 * @param {number} pageLimit - Page limit
 * @param {number} pageNo = Page number
 * @returns {SmartCityDataResponse}
 */
export const getMyWorkRequests = async (pageLimit = '', pageNo = '') =>
  fetch(
    `${apiUrl}smartcitymanager/getMyWorkRequests?` +
      `pageLimit=${pageLimit}` +
      `&pageNo=${pageNo}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get open Petitions WRs that the user has logged
 * @param {number} pageLimit - Page limit
 * @param {number} pageNo = Page number
 * @returns {SmartCityDataResponse}
 */
export const getMyPetitions = async (pageLimit = '', pageNo = '') =>
  fetch(
    `${apiUrl}smartcitymanager/getMyPetitions?` +
      `pageLimit=${pageLimit}` +
      `&pageNo=${pageNo}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
        // subscriberId,
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get all child announcement types
 * @param {number} parentWorkTypeId - Announcement parent id
 * @returns {List<LabelValue>}
 */
export const getChildWorkTypes = async (parentWorkTypeId) =>
  fetch(
    `${apiUrl}smartcitymanager/getChildWorkTypes?` +
      `parentWorkTypeId=${parentWorkTypeId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
        // subscriberId,
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get all Announcement Types
 * @returns {List<LabelValue>}
 */
export const getTopLevelAnnouncementWorkRequestTypes = async () =>
  fetch(`${apiUrl}smartcitymanager/getTopLevelAnnouncementWorkRequestTypes?`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
      // subscriberId,
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Post a Announcement Post
 * @returns {Response}
 */
export const logWorkRequest = async (workRequest) =>
  fetch(`${apiUrl}smartcitymanager/logWorkRequest`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
      processData: false,
    },
    body: workRequest,
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get all Roles, used for filtering news feed by category
 * @returns {}
 */
export const getAllAvailableSmartCityRoles = async () =>
  fetch(`${apiUrl}smartcitymanager/getAllAvailableSmartCityRoles`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

// Get an address from a lat lng
export const reverseGeocode = async (lat, lng) => fetch(
  `${apiUrlGMAPS}geocode/json?latlng=${lat},${lng}&key=AIzaSyAoMxu7f1QrVK9_xggoZGi4uWKZRuVIZmw`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.json())
  .catch((e) => {
    console.log(e);
    return [];
  });

// Search address
export const searchPlaces = async (query) => fetch(
  `${apiUrlGMAPS}place/textsearch/json?query=${query}&region=za&key=AIzaSyAoMxu7f1QrVK9_xggoZGi4uWKZRuVIZmw`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.json())
  .catch((e) => {
    console.log(e);
    return [];
  });

/**
 * Get user's reports or petitions
 */
export const getMySocialRequests = async (petition = false) =>
  fetch(
    `${apiUrl}smartcitymanager/getMySocialRequests?` + `petition=${petition}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get user's petitions
 */
export const getAllOpenPetitionsForUser = async () =>
  fetch(`${apiUrl}smartcitymanager/getAllOpenPetitionsForUser`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

/**
 * Get user's reports
 */
export const getAllOpenWorkRequestsForUser = async () =>
  fetch(`${apiUrl}smartcitymanager/getAllOpenWorkRequestsForUser`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

export const createFavouriteLocation = async (customerPremise) =>
  fetch(`${apiUrl}smartcitymanager/createCustomerPremise`, {
    method: 'POST',
    async: false,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
    body: JSON.stringify(customerPremise),
  })
    .then((res) => res.json())
    .catch((e) => console.log(e));
