/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import regression from 'regression';
import {
  BarChart,
  Legend,
  Bar,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  ComposedChart,
} from 'recharts';
import {
  getSmartCityCategoryInsightsForPeriodAndCity,
  getSmartCityCategoryInsightsForPeriod,
} from 'interface/inthecity/inthecity';
import {
  Breadcrumbs,
  LocationDataSearch,
} from 'components/inthecity';
import {
  TopRow,
  Title,
  Banner,
} from 'pages/inthecity/placesOfInterest.styled';
import {
  PageContainer,
  WardComparisonContainer,
  SearchContainer,
  CompareRow,
  LocationLabel,
  Row,
  Grid,
  GridItem,
  ItemHeading,
  CompareButton,
  PieChartContainer,
  PieChartWrapperContainer,
  PieLegend,
  PieLegendItem,
  OrgUnitRow,
  OrgUnitList,
  OrgUnitItem,
  ReportCount,
  TimeContainer,
  TimeButton,
  TimeDropdown,
  TimeOption,
  KPIRow,
  KPIContainer,
  KPINumber,
  KPILabel,
  Loader,
  ResolutionChartContainer,
} from './insights.styled';

const colors1 = ['#E6632B', '#653926', '#983B14', '#FF9767', '#FFDDCE', '#D9DEE2'];
const colors2 = ['#2096BA', '#154757', '#02C3FB', '#89E3FD', '#84BED0', '#D9DEE2'];

const TimeSelector = ({ period, setPeriod }) => {
  const [open, setOpen] = useState(false);

  const handleClickPeriod = (p) => {
    setPeriod(p);
    setOpen(false);
  };

  return (
    <TimeContainer>
      <TimeButton
        onClick={() => setOpen(!open)}
      >
        <p>{`Last ${period * 30} days`}</p>
        <img src="social/location-dropdown-icon.svg" alt="dropdown" />
      </TimeButton>

      {open && (
        <TimeDropdown>
          <TimeOption onClick={() => handleClickPeriod(1)}>
            Last 30 days
          </TimeOption>
          <TimeOption onClick={() => handleClickPeriod(2)}>
            Last 60 days
          </TimeOption>
          <TimeOption onClick={() => handleClickPeriod(4)}>
            Last 120 days
          </TimeOption>
          <TimeOption onClick={() => handleClickPeriod(12)}>
            Last 360 days
          </TimeOption>
        </TimeDropdown>
      )}
    </TimeContainer>
  );
};

/**
 * Local Insights Page
 */
const LocalInsightsPage = () => {
  const [comparing, setComparing] = useState(false);
  const [period, setPeriod] = useState(12);
  const [dataType1, setDataType1] = useState(true);
  const [dataType2, setDataType2] = useState(true);

  // raw data for places
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [dataPerformance1, setDataPerformance1] = useState();
  const [dataPerformance2, setDataPerformance2] = useState();
  const [dataPerformanceOpen1, setDataPerformanceOpen1] = useState();
  const [dataPerformanceOpen2, setDataPerformanceOpen2] = useState();

  // TODO: enable later
  // const [geoJsonObject, setGeoJsonObject] = useState();

  // converted data for graphs
  const [barchart1Data, setBarchart1Data] = useState([]);
  const [barchart2Data, setBarchart2Data] = useState([]);
  const [categoryList1Data, setCategoryList1Data] = useState([]);
  const [categoryList2Data, setCategoryList2Data] = useState([]);
  const [piechart1data, setPiechart1Data] = useState([]);
  const [piechart2data, setPiechart2Data] = useState([]);
  const [areachart1Data, setAreachart1Data] = useState([]);
  const [areachart2Data, setAreachart2Data] = useState([]);
  const [ageBarchart1Data, setAgeBarchart1Data] = useState([]);
  const [ageBarchart2Data, setAgeBarchart2Data] = useState([]);
  const [ageOpenBarchart1Data, setAgeOpenBarchart1Data] = useState([]);
  const [ageOpenBarchart2Data, setAgeOpenBarchart2Data] = useState([]);
  const [linechart1Data, setLinechart1Data] = useState([]);
  const [linechart2Data, setLinechart2Data] = useState([]);

  // convert into rechart compatible data
  useEffect(() => {
    console.log('dataType1 and 2', dataType1, dataType2);
    // Sorted Data
    const sortedCategories1 = _.orderBy(data1?.categoryCounts, (x) => parseInt(x.attributes.reportsOpen, 10), 'desc');
    const sortedCategories2 = _.orderBy(data2?.categoryCounts, (x) => parseInt(x.attributes.reportsOpen, 10), 'desc');
    console.log('sortedCategories1 and 2', sortedCategories1, sortedCategories2);
    //
    // Bar chart data (Open & Closed Issues)
    //
    // first dataset
    const bar1 = [];
    sortedCategories1.forEach((cat, i) => {
      if (i < 5) {
        bar1.push({
          name: cat.label,
          opened: Number(cat.attributes.reportsOpen),
          closed: Number(cat.attributes.reportsClosed),
        });
      } else if (i === 5) {
        bar1[5] = {
          name: 'Other Issues',
          opened: Number(cat.attributes.reportsOpen),
          closed: Number(cat.attributes.reportsClosed),
        };
      } else {
        bar1[5].opened += Number(cat.attributes.reportsOpen);
        bar1[5].closed += Number(cat.attributes.reportsClosed);
      }
    });
    const list1 = sortedCategories1?.map((cat) => ({
      name: cat.label,
      icon: cat.attributes.OrgUnitIcon,
      opened: Number(cat.attributes.reportsOpen),
      closed: Number(cat.attributes.reportsClosed),
    }));
    // second dataset
    const bar2 = [];
    sortedCategories2.forEach((cat, i) => {
      if (i < 5) {
        bar2.push({
          name: cat.label,
          opened: Number(cat.attributes.reportsOpen),
          closed: Number(cat.attributes.reportsClosed),
        });
      } else if (i === 5) {
        bar2[5] = {
          name: 'Other Issues',
          opened: Number(cat.attributes.reportsOpen),
          closed: Number(cat.attributes.reportsClosed),
        };
      } else {
        bar2[5].opened += Number(cat.attributes.reportsOpen);
        bar2[5].closed += Number(cat.attributes.reportsClosed);
      }
    });
    const list2 = sortedCategories2?.map((cat) => ({
      name: cat.label,
      icon: cat.attributes.OrgUnitIcon,
      opened: Number(cat.attributes.reportsOpen),
      closed: Number(cat.attributes.reportsClosed),
    }));

    // Sort issues
    console.log('bar1', bar1, list1);
    const temp = bar1.sort((cata, catb) => (cata.opened
       < catb.opened));
    const temp2 = bar2.sort((cata, catb) => (cata.opened
    < catb.opened));
    const temp3 = list1.sort((cata, catb) => (cata.opened
      < catb.opened));
    const temp4 = list2.sort((cata, catb) => (cata.opened
      < catb.opened));
    setBarchart1Data(temp);
    setBarchart2Data(temp2);
    setCategoryList1Data(temp3);
    setCategoryList2Data(temp4);

    //
    // Area chart data (Opened vs Closed Issues)
    //
    // first dataset
    const balance1 = (data1 && data1?.cityId !== 0)
      ? data1.smartCityInsightMonths?.map((m) => ({
        month: m.month,
        year: m.year,
        order: Number(m.year + m.month.padStart(2, '0')),
        delta: Number(m.callsLoggedForTheMonth) - Number(m.callsResolvedForTheMonth),
        opened: Number(m.callsLoggedForTheMonth),
        closed: Number(m.callsResolvedForTheMonth),
      }))
      : [];

    const sortedBalance1 = (data1 && data1?.cityId !== 0) ? _.orderBy(balance1, (x) => x.order, 'asc') : [];

    const sb1 = (data1 && data1?.cityId !== 0) ? sortedBalance1.forEach((balance, i) => {
      if (i === 0) {
        sortedBalance1[i].balance = balance.delta + data1.openingBalance;
      } else {
        sortedBalance1[i].balance = balance.delta + sortedBalance1[i - 1].balance;
      }
    }) : [];
    console.log('sb1', sb1);

    const balance2 = (data2 && data2?.cityId !== 0)
      ? data2.smartCityInsightMonths?.map((m) => ({
        month: m.month,
        year: m.year,
        order: Number(m.year + m.month.padStart(2, '0')),
        delta: Number(m.callsLoggedForTheMonth) - Number(m.callsResolvedForTheMonth),
        opened: Number(m.callsLoggedForTheMonth),
        closed: Number(m.callsResolvedForTheMonth),
      }))
      : [];

    const sortedBalance2 = (data2 && data2?.cityId !== 0) ? _.orderBy(balance2, (x) => x.order, 'asc') : [];

    const sb2 = (data2 && data2?.cityId !== 0) ? sortedBalance2.forEach((balance, i) => {
      if (i === 0) {
        sortedBalance2[i].balance = balance.delta + data2.openingBalance;
      } else {
        sortedBalance2[i].balance = balance.delta + sortedBalance2[i - 1].balance;
      }
    }) : [];

    console.log('sb2', sb2);

    const area1 = (data1 && data1?.cityId !== 0)
      ? sortedBalance1?.map((m) => ({
        name: m.month + '/' + m.year,
        opened: Number(m.opened),
        closed: Number(m.closed),
        balance: Number(m.balance),
      }))
      : [];
    // second dataset
    const area2 = (data2 && data2?.cityId !== 0)
      ? sortedBalance2?.map((m) => ({
        name: m.month + '/' + m.year,
        opened: Number(m.opened),
        closed: Number(m.closed),
        balance: Number(m.balance),
      }))
      : [];
    setAreachart1Data(area1);
    setAreachart2Data(area2);
    console.log('data1', data1);
    //
    // Pie chart data (Open Issues Per Category)
    //
    // first dataset
    const pie1 = [];
    sortedCategories1.forEach((cat, i) => {
      if (i < 5) {
        pie1.push({
          name: cat.label,
          value: Number(cat.attributes.reportsOpen),
          fill: colors1[i],
        });
      } else if (i === 5) {
        pie1[5] = {
          name: 'Other Issues',
          value: Number(cat.attributes.reportsOpen),
          fill: colors1[i],
        };
      } else {
        pie1[5].value += Number(cat.attributes.reportsOpen);
      }
    });
    // second dataset
    const pie2 = [];
    sortedCategories2.forEach((cat, i) => {
      if (i < 5) {
        pie2.push({
          name: cat.label,
          value: Number(cat.attributes.reportsOpen),
          fill: colors2[i],
        });
      } else if (i === 5) {
        pie2[5] = {
          name: 'Other Issues',
          value: Number(cat.attributes.reportsOpen),
          fill: colors2[i],
        };
      } else {
        pie2[5].value += Number(cat.attributes.reportsOpen);
      }
    });
    setPiechart1Data(pie1);
    setPiechart2Data(pie2);
  }, [data1, data2]);

  useEffect(() => {
    let performance1 = [];
    let performance2 = [];

    if (dataPerformance1) {
      const dataPerformance1Array = dataPerformance1.map((x) => (
        [Number(x?.days), Number(x?.amount)]
      )).slice(1, -1);
      const result = regression.linear(dataPerformance1Array);
      console.log('result', result);
      performance1 = dataPerformance1.map((x) => ({
        days: Number(x?.days),
        value: Number(x?.amount),
        trend: Number((result.equation[0] * Number(x?.days) + result.equation[1]) >= 0
          ? (result.equation[0] * Number(x?.days) + result.equation[1]).toFixed(2) : 0),
      }));
    }

    if (dataPerformance2) {
      const dataPerformance2Array = dataPerformance2.map((x) => (
        [Number(x?.days), Number(x?.amount)]
      )).slice(1, -1);
      const result = regression.linear(dataPerformance2Array);
      performance2 = dataPerformance2.map((x) => ({
        days: Number(x?.days),
        value: Number(x?.amount),
        trend: Number((result.equation[0] * Number(x?.days) + result.equation[1]) >= 0
          ? (result.equation[0] * Number(x?.days) + result.equation[1]).toFixed(2) : 0),
      }));
    }

    //
    // Line chart data (Average Resolution Time)
    //
    // first dataset
    console.log('first', performance1);
    const line1 = dataPerformance1
      ? _.orderBy(performance1, (x) => x.days, ['desc']).slice(1, -1).map((d) => ({ // .slice(0, -1)
        name: moment().add(d.days / -30, 'months').format('MMM'), // string formatted month from days ago from now, e.g. 30 -> 'Feb', 60 -> 'Jan', etc.
        value: d.value,
        trend: d.trend,
      }))
      : [];
    // second dataset
    const line2 = dataPerformance2
      ? _.orderBy(performance2, (x) => x.days, ['desc']).slice(1, -1).map((d) => ({ // .slice(0, -1)
        name: moment().add(d.days / -30, 'months').format('MMM'),
        value: d.value,
        trend: d.trend,
      }))
      : [];
    setLinechart1Data(line1);
    setLinechart2Data(line2);

    //
    // Bar chart data (Age Analysis of Open Data)
    //
    // first dataset
    console.log('dataPerformance1', dataPerformance1, performance1, dataPerformance2, performance2);
    const bar1 = (dataPerformance1 && performance1.length > 0) ? performance1.map((d, i) => ({
      name: (i === performance1.length - 1)
        ? '360 +'
        : `${i === 0 ? 0 : d?.days} - ${performance1[i + 1].days - 1}`,
      value: d?.value,
    })) : [];

    // second dataset
    const bar2 = (dataPerformance2 && performance2.length > 0) ? performance2.map((d, i) => ({
      name: (i === performance2.length - 1)
        ? '360 +'
        : `${i === 0 ? 0 : d?.days} - ${performance1[i + 1].days - 1}`,
      value: d?.value,
    })) : [];
    console.log('dataPerformance12', bar1, bar2);
    setAgeBarchart1Data(bar1);
    setAgeBarchart2Data(bar2);
  }, [dataPerformance1, dataPerformance2]);

  useEffect(() => {
    let performance1 = [];
    let performance2 = [];

    if (dataPerformanceOpen1) {
      const dataPerformance1Array = dataPerformanceOpen1.map((x) => (
        [Number(x?.days), Number(x?.amount)]
      )).slice(1, -1);
      const result = regression.linear(dataPerformance1Array);
      performance1 = dataPerformanceOpen1.map((x) => ({
        days: Number(x?.days),
        value: Number(x?.amount),
        trend: Number((result.equation[0] * Number(x?.days) + result.equation[1]) >= 0
          ? (result.equation[0] * Number(x?.days) + result.equation[1]).toFixed(2) : 0),
      }));
    }

    if (dataPerformanceOpen2) {
      const dataPerformance2Array = dataPerformanceOpen2.map((x) => (
        [Number(x?.days), Number(x?.amount)]
      )).slice(1, -1);
      const result = regression.linear(dataPerformance2Array);
      performance2 = dataPerformanceOpen2.map((x) => ({
        days: Number(x?.days),
        value: Number(x?.amount),
        trend: Number((result.equation[0] * Number(x?.days) + result.equation[1]) >= 0
          ? (result.equation[0] * Number(x?.days) + result.equation[1]).toFixed(2) : 0),
      }));
    }

    //
    // Bar chart data (Age Analysis of Open Data)
    //
    // first dataset
    console.log('dataPerformanceOpen1', dataPerformanceOpen1, performance1, dataPerformanceOpen2, performance2);
    const bar1 = (dataPerformanceOpen1 && performance1.length > 0) ? performance1.map((d, i) => ({
      name: (i === performance1.length - 1)
        ? '360 +'
        : `${i === 0 ? 0 : d?.days} - ${performance1[i + 1].days - 1}`,
      value: d?.value,
    })) : [];

    // second dataset
    const bar2 = (dataPerformanceOpen2 && performance2.length > 0) ? performance2.map((d, i) => ({
      name: (i === performance2.length - 1)
        ? '360 +'
        : `${i === 0 ? 0 : d?.days} - ${performance1[i + 1].days - 1}`,
      value: d?.value,
    })) : [];
    console.log('dataPerformance12', bar1, bar2);
    setAgeOpenBarchart1Data(bar1);
    setAgeOpenBarchart2Data(bar2);
  }, [dataPerformanceOpen1, dataPerformanceOpen2]);

  // When period is changed
  useEffect(async () => {
    let categoryData1 = [];
    if (dataType1 === 0) {
      categoryData1 = await getSmartCityCategoryInsightsForPeriod(period, data1?.wardNo);
    } else if (dataType1 === 1 && data1) {
      categoryData1 = await getSmartCityCategoryInsightsForPeriodAndCity(period, data1?.cityId);
    }
    console.log('categoryData1', categoryData1);
    let categoryData2 = [];
    if (dataType2 === 0 && data2 && data2.cityId !== 0) {
      categoryData2 = await getSmartCityCategoryInsightsForPeriod(period, data2?.wardNo);
    } else if (dataType2 === 1 && data2 && data2.cityId !== 0) {
      categoryData2 = await getSmartCityCategoryInsightsForPeriodAndCity(period, data2?.cityId);
    }
    console.log('categoryData2', categoryData2);

    // Sorted Data
    const sortedCategories1 = _.orderBy(categoryData1,
      (x) => Number(x.attributes.reportsOpen), ['desc']);
    const sortedCategories2 = _.orderBy(categoryData2,
      (x) => Number(x.attributes.reportsOpen), ['desc']);
    //
    // Bar chart data (Open & Closed Issues)
    //
    // first dataset
    const bar1 = [];
    sortedCategories1.forEach((cat, i) => {
      if (i < 5) {
        bar1.push({
          name: cat.label,
          opened: Number(cat.attributes.reportsOpen),
          closed: Number(cat.attributes.reportsClosed),
        });
      } else if (i === 5) {
        bar1[5] = {
          name: 'Other Issues',
          opened: Number(cat.attributes.reportsOpen),
          closed: Number(cat.attributes.reportsClosed),
        };
      } else {
        bar1[5].opened += Number(cat.attributes.reportsOpen);
        bar1[5].closed += Number(cat.attributes.reportsClosed);
      }
    });
    const list1 = sortedCategories1?.map((cat) => ({
      name: cat.label,
      icon: cat.attributes.OrgUnitIcon,
      opened: Number(cat.attributes.reportsOpen),
      closed: Number(cat.attributes.reportsClosed),
    }));
    // second dataset
    const bar2 = [];
    sortedCategories2.forEach((cat, i) => {
      if (i < 5) {
        bar2.push({
          name: cat.label,
          opened: Number(cat.attributes.reportsOpen),
          closed: Number(cat.attributes.reportsClosed),
        });
      } else if (i === 5) {
        bar2[5] = {
          name: 'Other Issues',
          opened: Number(cat.attributes.reportsOpen),
          closed: Number(cat.attributes.reportsClosed),
        };
      } else {
        bar2[5].opened += Number(cat.attributes.reportsOpen);
        bar2[5].closed += Number(cat.attributes.reportsClosed);
      }
    });
    const list2 = sortedCategories2?.map((cat) => ({
      name: cat.label,
      icon: cat.attributes.OrgUnitIcon,
      opened: Number(cat.attributes.reportsOpen),
      closed: Number(cat.attributes.reportsClosed),
    }));

    // Sort issues
    console.log('bar1', bar1, list1);
    const temp = bar1.sort((cata, catb) => (cata.opened
       < catb.opened));
    const temp2 = bar2.sort((cata, catb) => (cata.opened
    < catb.opened));
    const temp3 = list1.sort((cata, catb) => (cata.opened
      < catb.opened));
    const temp4 = list2.sort((cata, catb) => (cata.opened
      < catb.opened));
    setBarchart1Data(temp);
    setBarchart2Data(temp2);
    setCategoryList1Data(temp3);
    setCategoryList2Data(temp4);
  }, [period]);

  const handleSetLocation1 = (d) => {
    console.log('handleSetLocation1', d);
    setData1(d[0]);
    setDataPerformance1(d[1]);
    setDataPerformanceOpen1(d[2]);
    setDataType1(d[3]);
    setPeriod(12);
  };

  const handleSetLocation2 = (d) => {
    setData2(d[0]);
    setDataPerformance2(d[1]);
    setDataPerformanceOpen2(d[2]);
    setDataType2(d[3]);
    setPeriod(12);
  };

  const handleRemoveSecondLocation = () => {
    setData2(null);
    setDataPerformance2(null);
    setDataPerformanceOpen2(null);
    setBarchart2Data(null);
    setCategoryList2Data([]);
    setPiechart2Data(null);
    setAreachart2Data(null);
    setAgeBarchart2Data(null);
    setAgeOpenBarchart2Data(null);
    setLinechart2Data(null);
  };

  const formatLegendText = (str) => (str[0].toUpperCase() + str.slice(1).toLowerCase());
  console.log('data1 and 2', data1, data2);
  return (
    <PageContainer>

      <TopRow>
        <Breadcrumbs />
        {/* <LocationFilter /> */}
      </TopRow>

      <Title>Local Insights</Title>

      <Banner src="city/cape_town.jpg" />

      {/* Ward Search */}
      <WardComparisonContainer>
        <SearchContainer>
          <LocationDataSearch
            period={period}
            primary
            setData={(d) => handleSetLocation1(d)}
          />
          {comparing && (
            <LocationDataSearch
              period={period}
              primary={false}
              setData={(d) => handleSetLocation2(d)}
            />
          )}
        </SearchContainer>
        <CompareRow>
          <LocationLabel>
            Viewing:
            {' '}
            <div style={{ color: '#E6632B', display: 'contents' }}>
              {(data1 && data1?.cityId !== 0) ? (data1?.address ? data1?.address : data1?.wardNo) : 'No Data for Selected Location'}
            </div>
            {(data2 && data2?.cityId !== 0) ? ' vs ' : ''}
            {(data2 && data2?.cityId !== 0) ? (
              <div style={{ color: '#299ECC', display: 'contents' }}>
                {' '}
                {(data2 && data2?.cityId !== 0) ? (data2?.address ? data2?.address : data2?.wardNo) : 'No Data for Selected Location'}
                {' '}
              </div>
            ) : ''}
          </LocationLabel>

          <CompareButton
            onClick={() => {
              if (comparing) handleRemoveSecondLocation();
              setComparing(!comparing);
            }}
          >
            {comparing ? 'Remove Location' : '+ Location to Compare'}
          </CompareButton>
        </CompareRow>
      </WardComparisonContainer>

      <Grid>
        <GridItem area="1 / 1 / 2 / 8">

          <Row>
            <ItemHeading>Overview</ItemHeading>
          </Row>

          <KPIRow>
            {(!data1 && data1?.cityId === 0) && <Loader />}

            {(data1 && data1?.cityId !== 0) && (
              <>
                <KPIContainer>
                  {(data2 && data2?.cityId !== 0) && (
                    <KPINumber>
                      {data2?.callsInProgress}
                    </KPINumber>
                  )}
                  {(data1 && data1?.cityId !== 0) && (
                    <KPINumber primary>
                      {data1?.callsInProgress}
                    </KPINumber>
                  )}
                  <KPILabel>Total Open Issues</KPILabel>
                </KPIContainer>

                <KPIContainer>
                  {(data2 && data2?.cityId !== 0) && (
                    <KPINumber>
                      {`${data2?.averageOpenTime.split(':')[0]}d ${data2?.averageOpenTime.split(':')[1]}h`}
                    </KPINumber>
                  )}
                  {(data1 && data1?.cityId !== 0) && (
                    <KPINumber primary>
                      {`${data1?.averageOpenTime.split(':')[0]}d ${data1?.averageOpenTime.split(':')[1]}h`}
                    </KPINumber>
                  )}
                  <KPILabel>Average Age of Open Issues</KPILabel>
                </KPIContainer>

                <KPIContainer>
                  {(data2 && data2?.cityId !== 0) && (
                    <KPINumber>
                      {`${data2?.averageResolveTime.split(':')[0]}d ${data2?.averageResolveTime.split(':')[1]}h`}
                    </KPINumber>
                  )}
                  {(data1 && data1?.cityId !== 0) && (
                    <KPINumber primary>
                      {`${data1?.averageResolveTime.split(':')[0]}d ${data1?.averageResolveTime.split(':')[1]}h`}
                    </KPINumber>
                  )}
                  <KPILabel>Average Resolution Time</KPILabel>
                </KPIContainer>
              </>
            )}
          </KPIRow>
        </GridItem>

        <GridItem area={data2 ? '1 / 8 / 6 / 12' : '1 / 8 / 4 / 12'}>

          <Row>
            <ItemHeading>Open vs Closed Issues</ItemHeading>
          </Row>

          {areachart1Data.length === 0 && <Loader />}

          {(areachart1Data && areachart1Data?.length > 0) && (
          <ResponsiveContainer
            minHeight={400}
          >
            <AreaChart
              data={areachart1Data}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FF9767" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FF9767" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="name"
                stroke="#D9DEE2"
                tick={{ fill: '#B2BCC1' }}
                tickLine={{ stroke: '#D9DEE2' }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#B2BCC1' }}
                label={{ value: 'Number of Issues', angle: -90, position: 'insideLeft' }}
              />
              <Tooltip />
              <Legend formatter={formatLegendText} />
              <Area type="linear" dataKey="opened" stroke="#E6632B" fillOpacity={1} strokeWidth={2} fill="url(#colorUv)" />
              <Area type="linear" dataKey="closed" stroke="#FF9767" fillOpacity={1} strokeDasharray="10 10" strokeWidth={2} fill="url(#colorPv)" />
              <Area type="linear" dataKey="balance" stroke="gray" fillOpacity={0} strokeWidth={2} />
            </AreaChart>
          </ResponsiveContainer>
          )}

          {(areachart2Data && areachart2Data?.length > 0) && (
          <ResponsiveContainer
            minHeight={400}
          >
            <AreaChart
              data={areachart2Data}
            >
              <defs>
                <linearGradient id="colorXx" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#2096BA80" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorYy" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#2096BA80" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="name"
                stroke="#D9DEE2"
                tick={{ fill: '#B2BCC1' }}
                tickLine={{ stroke: '#D9DEE2' }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#B2BCC1' }}
                label={{
                  value: 'Number of Issues', angle: -90, position: 'insideLeft',
                }}
              />
              <Tooltip />
              <Legend formatter={formatLegendText} />
              <Area type="linear" dataKey="opened" stroke="#2096BA" fillOpacity={1} strokeWidth={2} fill="url(#colorXx)" />
              <Area type="linear" dataKey="closed" stroke="#2096BA" fillOpacity={1} strokeDasharray="10 10" strokeWidth={2} fill="url(#colorYy)" />
              <Area type="linear" dataKey="balance" stroke="gray" fillOpacity={0} strokeWidth={2} />
            </AreaChart>
          </ResponsiveContainer>
          )}
        </GridItem>

        <GridItem area={(data2 && data2.cityId !== 0) ? '2 / 1 / 8 / 5' : '2 / 1 / 6 / 5'}>

          <Row>
            <ItemHeading>Open vs Closed Issues Per Category</ItemHeading>
            <TimeSelector
              period={period}
              setPeriod={setPeriod}
            />
          </Row>

          {barchart1Data.length === 0 && <Loader />}

          {(barchart1Data && barchart1Data?.length > 0) && (
            <div style={{ flex: 1 }}>
              <ResponsiveContainer>
                <BarChart
                  data={barchart1Data}
                  layout="vertical"
                >
                  <XAxis
                    axisLine={false}
                    tickLine={false}
                    type="number"
                    // tick={<OrgUnitAxisIcon data={barchart1Data} cityId={city?.value || 431} />}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    type="category"
                    dataKey="name"
                    tick={{ fill: '#0A0918' }}
                    // label={{ value: 'Days', angle: 0, position: 'bottom' }}
                  />
                  <Tooltip />
                  {/* <Label value="Days" position="bottom" /> */}
                  <Legend formatter={formatLegendText} />
                  <Bar dataKey="opened" barSize={6} barGap={0.5} fill="#E6632B" />
                  <Bar dataKey="closed" barSize={6} barGap={2} fill="#D9DEE2" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}

          {(barchart2Data && barchart2Data?.length > 0) && (
            <div style={{ flex: 1 }}>
              <ResponsiveContainer>
                <BarChart
                  data={barchart2Data}
                  layout="vertical"
                >
                  <XAxis
                    axisLine={false}
                    tickLine={false}
                    type="number"
                    // tick={<OrgUnitAxisIcon data={barchart2Data} cityId={city?.value || 431} />}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    type="category"
                    dataKey="name"
                    tick={{ fill: '#0A0918' }}
                    // label={{ value: 'Days', angle: -90, position: 'insideLeft' }}
                  />
                  <Tooltip />
                  <Legend formatter={formatLegendText} />
                  <Bar dataKey="opened" barSize={6} barGap={0.5} fill="#2096BA80" />
                  <Bar dataKey="closed" barSize={6} barGap={2} fill="#D9DEE2" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}

          <OrgUnitRow style={{ flex: 1 }}>
            <OrgUnitList>
              {categoryList1Data.map((item) => (
                <OrgUnitItem primary>
                  <img src={`https://mysmart.city/api/msc/images/custom/${item.icon}?cityId=431`} alt="orgunit" />
                  <p>{item.name}</p>
                  <ReportCount primary>{item.opened}</ReportCount>
                  <ReportCount>{item.closed}</ReportCount>
                </OrgUnitItem>
              ))}
            </OrgUnitList>
            {(data2 && data2.cityId !== 0) && (
              <OrgUnitList>
                {categoryList2Data.map((item) => (
                  <OrgUnitItem>
                    <img src={`https://mysmart.city/api/msc/images/custom/${item.icon}?cityId=431`} alt="orgunit" />
                    <p>{item.name}</p>
                    <ReportCount secondary>{item.opened}</ReportCount>
                    <ReportCount>{item.closed}</ReportCount>
                  </OrgUnitItem>
                ))}
              </OrgUnitList>
            )}
          </OrgUnitRow>
        </GridItem>

        <GridItem area={(data2 && data2.cityId !== 0) ? '2 / 5 / 6 / 8' : '2 / 5 / 4 / 8'}>

          <Row>
            <ItemHeading>Open Issues Per Category</ItemHeading>
          </Row>

          {piechart1data.length === 0 && <Loader />}

          {(piechart1data && piechart1data?.length > 0) && (
            <PieChartContainer>
              <PieChartWrapperContainer>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={piechart1data}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={50}
                      outerRadius={80}
                    >
                      <Label value={data1?.wardNo} offset={0} position="center" />
                      <Tooltip />
                      {piechart1data.map((cat, i) => (
                        <Cell
                          key={`cell-${i}`}
                          fill={cat.fill}
                          strokeWidth={1}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </PieChartWrapperContainer>
              <PieLegend>
                {piechart1data.map((cat) => (
                  <PieLegendItem
                    fill={cat.fill}
                  >
                    <div />
                    <p>{cat.name}</p>
                  </PieLegendItem>
                ))}
              </PieLegend>
            </PieChartContainer>
          )}

          {(piechart2data && piechart2data?.length > 0) && (
            <PieChartContainer>
              <PieChartWrapperContainer>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={piechart2data}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={50}
                      outerRadius={80}
                    >
                      <Label value={data2?.wardNo} offset={0} position="center" />
                      <Tooltip />
                      {piechart2data.map((cat, i) => (
                        <Cell
                          key={`cell-${i}`}
                          fill={cat.fill}
                          strokeWidth={1}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </PieChartWrapperContainer>
              <PieLegend>
                {piechart2data.map((cat) => (
                  <PieLegendItem
                    fill={cat.fill}
                  >
                    <div />
                    <p>{cat.name}</p>
                  </PieLegendItem>
                ))}
              </PieLegend>
            </PieChartContainer>
          )}
        </GridItem>

        <GridItem area={(data2 && data2.cityId !== 0) ? '6 / 5 / 8 / 12' : '4 / 5 / 6 / 8'}>

          <Row>
            <ItemHeading>Age Analysis of Closed Issues</ItemHeading>
          </Row>

          {ageBarchart1Data.length === 0 && <Loader />}

          <ResolutionChartContainer>
            {(ageBarchart1Data && ageBarchart1Data?.length > 0) && (
              <ResponsiveContainer
                minHeight={400}
                minWidth="50%"
              >
                <BarChart
                  data={ageBarchart1Data}
                  layout="vertical"
                >
                  <XAxis
                    stroke="#D9DEE2"
                    tick={{ fill: '#B2BCC1' }}
                    tickLine={{ stroke: '#D9DEE2' }}
                    type="number"
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    type="category"
                    dataKey="name"
                    width={70}
                  >
                    <Label value="Days" position="bottom" />
                  </YAxis>
                  <Tooltip />
                  <Legend formatter={formatLegendText} />
                  <Bar name="No. of Issues Closed over Time" dataKey="value" barSize={8} barGap={1} fill="#DF6E21" />
                </BarChart>
              </ResponsiveContainer>
            )}

            {(ageBarchart2Data && ageBarchart2Data?.length > 0) && (
              <ResponsiveContainer
                minHeight={400}
                minWidth="50%"
              >
                <BarChart
                  data={ageBarchart2Data}
                  layout="vertical"
                >
                  <XAxis
                    stroke="#D9DEE2"
                    tick={{ fill: '#B2BCC1' }}
                    tickLine={{ stroke: '#D9DEE2' }}
                    type="number"
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    // label={{ value: 'Days', angle: -90, position: 'insideLeft' }}
                    type="category"
                    dataKey="name"
                    width={70}
                  >
                    <Label value="Days" position="bottom" />
                  </YAxis>
                  <Tooltip />
                  <Legend formatter={formatLegendText} />
                  <Bar name="No. of Issues Closed over Time" dataKey="value" barSize={8} barGap={1} fill="#2096BA80" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </ResolutionChartContainer>
        </GridItem>

        {/* row-start column-start row-end column-end */}
        <GridItem area={(data2 && data2.cityId !== 0) ? '8 / 1 / 10 / 12' : '4 / 8 / 6 / 12'}>

          <Row>
            <ItemHeading>Age Analysis of Open Issues</ItemHeading>
          </Row>

          {ageOpenBarchart1Data.length === 0 && <Loader />}

          <ResolutionChartContainer>
            {(ageOpenBarchart1Data && ageOpenBarchart1Data?.length > 0) && (
              <ResponsiveContainer
                minHeight={400}
                minWidth="50%"
              >
                <BarChart
                  data={ageOpenBarchart1Data}
                  layout="vertical"
                >
                  <XAxis
                    stroke="#D9DEE2"
                    tick={{ fill: '#B2BCC1' }}
                    tickLine={{ stroke: '#D9DEE2' }}
                    type="number"
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    type="category"
                    dataKey="name"
                    width={70}
                  >
                    <Label value="Days" position="bottom" />
                  </YAxis>
                  <Tooltip />
                  <Legend formatter={formatLegendText} />
                  <Bar name="No. of Issues Open over Time" dataKey="value" barSize={8} barGap={1} fill="#DF6E21" />
                </BarChart>
              </ResponsiveContainer>
            )}

            {(ageOpenBarchart2Data && ageOpenBarchart2Data?.length > 0) && (
              <ResponsiveContainer
                minHeight={400}
                minWidth="50%"
              >
                <BarChart
                  data={ageOpenBarchart2Data}
                  layout="vertical"
                >
                  <XAxis
                    stroke="#D9DEE2"
                    tick={{ fill: '#B2BCC1' }}
                    tickLine={{ stroke: '#D9DEE2' }}
                    type="number"
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    // label={{ value: 'Days', angle: -90, position: 'insideLeft' }}
                    type="category"
                    dataKey="name"
                    width={70}
                  >
                    <Label value="Days" position="bottom" />
                  </YAxis>
                  <Tooltip />
                  <Legend formatter={formatLegendText} />
                  <Bar name="No. of Issues Open over Time" dataKey="value" barSize={8} barGap={1} fill="#2096BA80" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </ResolutionChartContainer>
        </GridItem>

        <GridItem area={(data2 && data2.cityId !== 0) ? '10 / 1 / 12 / 12' : '6 / 1 / 9 / 12'}>

          <Row>
            <ItemHeading>Average Resolution Time</ItemHeading>
          </Row>

          {linechart1Data.length === 0 && <Loader />}

          <ResolutionChartContainer>
            {linechart1Data && linechart1Data?.length > 0 && (
              <ResponsiveContainer
                minHeight={400}
                minWidth="50%"
              >
                <ComposedChart
                  data={linechart1Data}
                  margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    stroke="#D9DEE2"
                    tick={{ fill: '#B2BCC1' }}
                    tickLine={{ stroke: '#D9DEE2' }}
                  />
                  <YAxis
                    label={{ value: 'Days', angle: -90, position: 'insideLeft' }}
                    axisLine={false}
                    tickLine={false}
                    type="number"
                    domain={[0, 'auto']}
                  />
                  <Legend formatter={formatLegendText} verticalAlign="bottom" height={0} />
                  <Tooltip />
                  {/* <Line name="Average resolution time"
                  type="linear" dataKey="value" stroke="#DF6E21" /> */}
                  <Area name="Average resolution time" type="linear" dataKey="value" stroke="#DF6E21" fillOpacity={0} strokeWidth={2} fill="transparent" />
                  <Area name="Trend" type="linear" dataKey="trend" stroke="gray" fillOpacity={0} strokeWidth={2} fill="transparent" />
                </ComposedChart>
              </ResponsiveContainer>
            )}

            {linechart2Data && linechart2Data?.length > 0 && (
              <ResponsiveContainer
                minHeight={400}
                minWidth="50%"
              >
                <ComposedChart
                  data={linechart2Data}
                  margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    stroke="#D9DEE2"
                    tick={{ fill: '#B2BCC1' }}
                    tickLine={{ stroke: '#D9DEE2' }}
                  />
                  <YAxis
                    label={{ value: 'Days', angle: -90, position: 'insideLeft' }}
                    axisLine={false}
                    tickLine={false}
                    type="number"
                  />
                  <Legend formatter={formatLegendText} verticalAlign="bottom" height={0} />
                  <Tooltip />
                  <Area name="Average resolution time" type="linear" dataKey="value" stroke="#2096BA80" fillOpacity={0} strokeWidth={2} fill="transparent" />
                  <Area name="Trend" type="linear" dataKey="trend" stroke="gray" fillOpacity={0} strokeWidth={2} fill="transparent" />
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </ResolutionChartContainer>
        </GridItem>

        {/* TODO: enable later */}
        {/* <GridItem area={data2 ? '10 / 1 / 13 / 12' : '6 / 1 / 9 / 12'}>

          <Row>
            <ItemHeading>Wards in the Area</ItemHeading>
            <MapLegend>
              <p>Ward Rating</p>
              <div />
              <div />
              <div />
              <div />
              <div />
            </MapLegend>
          </Row>

          <MapContainer
            style={{
              zIndex: 1,
              width: '100%',
              height: '100%',
              background: 'transparent',
            }}
            center={[-33.918861, 18.4233]}
            zoom={11}
            minZoom={9}
            attributionControl={false}
            closePopupOnClick={false}
            contextmenu={false}
            zoomControl={false}
            doubleClickZoom
            scrollWheelZoom
            dragging
            animate
            easeLinearity={0.4}
          >
            {geoJsonObject?.features?.length > 0 && geoJsonObject.features?.map((ward, index) => (
              <Polygon
                positions={ward.geometry.geometries[0]}
                fillOpacity={Math.random() * 0.8 + 0.2}
                fillColor="#DF6E21"
                color="#FFFFFF"
                weight="1"
                key={index}
              >
                <Popup>
                  Ward
                  {' '}
                  {ward.properties.wardNo}
                </Popup>
              </Polygon>
            ))}
          </MapContainer>
        </GridItem> */}
      </Grid>
    </PageContainer>
  );
};

export default LocalInsightsPage;
