import { GMAccountLineItem, NivoGraphDataItem } from 'types/types';

export const convertGMLineItemToNivoGraphItem = (
  lineItem: GMAccountLineItem
): NivoGraphDataItem => ({
  id: lineItem.label,
  value: lineItem.amount,
  label: lineItem.label,
  color: lineItem.colour,
});

export const hexToRGBA = (hex: string, opacity: number): string | null => {
  if (!hex) return null;
  // Remove '#' character from the beginning of the HEX code
  const cleanedHex = hex.replace('#', '');

  // Check if the HEX code is valid
  if (!/^[0-9A-Fa-f]{6}$/.test(cleanedHex)) {
    return null;
  }

  // Extract RGB values from the HEX code
  const red = parseInt(cleanedHex.substring(0, 2), 16);
  const green = parseInt(cleanedHex.substring(2, 4), 16);
  const blue = parseInt(cleanedHex.substring(4, 6), 16);

  // Convert the RGB values to RGBA format
  const rgba = `rgba(${red}, ${green}, ${blue}, ${opacity})`;

  return rgba;
};
