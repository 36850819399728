import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Divider = styled.img`
  height: 8px;
  width: 4px;
  margin: 0 12px;
  @media only screen and (min-width: 800px) {
    height: 14px;
    width: 7px;
    margin: 0 16px;
  }
`;

export const PageLink = styled(Link)`
  font: normal normal normal 14px/19px Roboto;
  color: ${({ to }) => (to ? 'rgba(51, 51, 51, 0.5)' : '#333333')};
  &:hover {
    color: ${({ to }) => (to ? 'rgba(51, 51, 51, 0.5)' : '#333333')};
  }
`;
