import styled from 'styled-components';

export const MessageContainer = styled.div`
  text-align: center;
  color: #B2BCC1;
  margin: 50px 50px;

  h2 {
    font: normal normal 500 16px/19px Roboto;
  }
  p {
    font: normal normal normal 10px/12px Roboto;
  }

  @media only screen and (min-width: 800px) {
    margin: 100px 100px;

    h2 {
      font: normal normal 500 20px/26px Roboto;
    }
    p {
      font: normal normal normal 16px/19px Roboto;
    }
  }
`;
