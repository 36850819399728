/** @jsxImportSource @emotion/react */
/* eslint-disable object-curly-newline */
import { useState, useRef } from 'react';
import { useGlobalContext } from 'contexts/global';
import { profileImageUrl } from 'functions/social';
import { useOnClickOutside } from 'functions/home';
import { PageLinksEnum } from 'types/routing';
import Notifications from './notifications';
import {
  Container,
  ProfileButton,
  Dropdown,
  StyledLink,
  Separator,
  StyledLinkButton,
  SignInButton,
  ServiceProviderButton,
  ServiceProviderContainer,
  // Loader,
} from './profile.styled';
import { useAppDispatch } from 'redux/hooks';
import { setMobileDrawerOpen } from 'redux/account';
import { Text } from 'components/typography';
import { css } from '@emotion/react';
import { Box } from 'components/layout';
import { Button } from 'components/elements';

const boardingUrl = 'boarding.'.concat(process.env.REACT_APP_API_BASE);

const Profile = ({ setShowLoginModal }) => {
  const dispatch = useAppDispatch();

  const { globalState, logout } = useGlobalContext();

  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div
      ref={ref}
      css={css`
        position: relative;
      `}
    >
      <Button
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Box opacity={globalState.user.uuid === 'guest' ? 0.3 : 1}>
          <ProfileButton
            src={
              globalState.user
                ? profileImageUrl(globalState.user.uuid)
                : 'chatbot/msc_profile.svg'
            }
            alt="icon"
            active={open}
          />
        </Box>
        {globalState.user.uuid === 'guest' && (
          <Text position="absolute" top="30%" color="white">
            Guest
          </Text>
        )}
      </Button>
      <Dropdown open={open}>
        {globalState.user.uuid !== 'guest' ? (
          <>
            <StyledLink
              to="/account/profile"
              onClick={() => {
                setOpen(false);
                dispatch(setMobileDrawerOpen(true));
              }}
            >
              My Account
            </StyledLink>
            <StyledLink
              to="/account/profile"
              onClick={() => {
                setOpen(false);
                dispatch(setMobileDrawerOpen(false));
              }}
            >
              Profile
            </StyledLink>
            <StyledLink
              to={PageLinksEnum.MyBookings}
              onClick={() => setOpen(false)}
            >
              My Bookings
            </StyledLink>
            <StyledLink
              to="/account/favourite-locations"
              onClick={() => {
                setOpen(false);
                dispatch(setMobileDrawerOpen(false));
              }}
            >
              Favourite Locations
            </StyledLink>
            <StyledLink
              to="/account/utility-accounts"
              onClick={() => {
                setOpen(false);
                dispatch(setMobileDrawerOpen(false));
              }}
            >
              Utility Accounts
            </StyledLink>
            <StyledLink
              to="/account/support"
              onClick={() => {
                setOpen(false);
                dispatch(setMobileDrawerOpen(false));
              }}
            >
              Support
            </StyledLink>
            <Separator />
          </>
        ) : (
          <StyledLinkButton
            role="button"
            tabIndex={0}
            onClick={() => {
              setShowLoginModal(true);
              setOpen(false);
            }}
          >
            Sign in/Create profile
          </StyledLinkButton>
        )}
        <StyledLinkButton role="button" tabIndex={0} onClick={() => logout()}>
          Sign Out
        </StyledLinkButton>
      </Dropdown>
    </div>
  );
};

export const UserNavigation = ({ setShowLoginModal }) => {
  const { globalState } = useGlobalContext();
  if (
    globalState.user.uuid.localeCompare('false') === 0 ||
    globalState.user.uuid.localeCompare('init') === 0
  ) {
    return (
      <Container>
        <ServiceProviderContainer>
          <ServiceProviderButton
            active
            onClick={() => {
              window.open('https://'.concat(boardingUrl));
            }}
            id="home-service-provider"
          >
            Become A Service Provider
          </ServiceProviderButton>
        </ServiceProviderContainer>
        <SignInButton
          active
          onClick={() => setShowLoginModal(true)}
          data-test-id="sign-in-button"
        >
          Sign in
        </SignInButton>
        {globalState.user.uuid === 'guest' && (
          <div style={{ marginLeft: '10px' }}>
            <Profile />
          </div>
        )}
      </Container>
    );
  }
  return (
    <Container>
      {globalState.user.uuid !== 'guest' && <Notifications />}
      <Profile setShowLoginModal={setShowLoginModal} />
    </Container>
  );
};

export default Profile;
