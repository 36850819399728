import { Text } from 'components/typography';
import { css } from '@emotion/react';
import { Button, Image, Link, Modal } from 'components/elements';
import { useState } from 'react';
import { Flex } from '../../../layout';
import theme from '../../../../theme';

export const AwardBlock = ({
  text,
  imageUrl,
}: {
  text: string;
  imageUrl: string;
}) => (
  <Flex
    alignItems="center"
    alignContent="center"
    flexDirection="row"
    backgroundColor="rgb(238, 238, 239)"
    p="7px"
    px="12px"
    mr="15px"
    borderRadius="8px"
    overrideCss={css`
      @media ${theme.mediaQueries.mobileOnly} {
        background-color: #f6f6f9;
        padding: 0;
      }
    `}
  >
    <Text
      color="#707070"
      fontSize="16px"
      m={0}
      p={0}
      fontWeight="medium"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          display: none;
        }
        @media ${theme.mediaQueries.tabletOnly} {
          font-size: 14px;
        }
      `}
    >
      {text}
    </Text>
    <Image
      source={imageUrl}
      alt="text"
      ml="10px"
      height="100%"
      overrideCss={css`
        @media ${theme.mediaQueries.tabletOnly} {
          height: 65%;
        }
      `}
    />
  </Flex>
);

const Hero = () => {
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);
  return (
    <>
      <Modal
        isOpen={isQrCodeModalOpen}
        onClose={() => setIsQrCodeModalOpen(false)}
        title="Download the My Smart City app"
      >
        <Flex
          maxWidth="540px"
          width="100%"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
        >
          <Text mb="30px" textAlign="center">
            Our premium services will be launching on web soon! To book a tutor
            today, scan the QR code to download the latest version of the My
            Smart City app.
          </Text>
          <Image
            px="100px"
            alt="qr code"
            source="/home/images/qrcode_black.png"
          />
        </Flex>
      </Modal>
      <Flex
        height="100%"
        position="relative"
        background="transparent linear-gradient(90deg, #000000 23%,rgba(0,0,0,0.8) 40%, rgba(0,0,0,0.5) 50%, #00000000 80%) 0% 0% no-repeat padding-box;"
        py="140px"
        px="100px"
        overrideCss={css`
          @media ${theme.mediaQueries.tabletDown} {
            padding: 80px;
          }
          @media ${theme.mediaQueries.mobileOnly} {
            padding: 35px;
            padding-top: 10%;
            height: 650px;
            background: transparent
              linear-gradient(180deg, #000000 0%, #000000 20%, #00000000 80%) 0%
              0% no-repeat padding-box;
          }
        `}
        overflow="hidden"
      >
        <Image
          alt="background"
          source="privateServices/tutors.png"
          position="absolute"
          top="0"
          left="12%"
          zIndex={-100}
          height="100%"
          width="100%"
          objectFit="cover"
          hideForMobile
          overrideCss={css`
            @media ${theme.mediaQueries.tabletOnly} {
              object-fit: cover;
              left: 8%;
              width: 100%;
            }
          `}
        />
        <Image
          alt="background"
          source="privateServices/tutors.png"
          position="absolute"
          top="150px"
          left={0}
          zIndex={-100}
          height="100%"
          width="100%"
          objectFit="cover"
          hideForDesktop
          hideForTablet
        />
        <Flex
          maxWidth="55%"
          flexDirection="column"
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              max-width: 100%;
            }
            @media ${theme.mediaQueries.tabletOnly} {
              max-width: 75%;
            }
            @media ${theme.mediaQueries.desktopOnly} {
              margin-left: 40px;
            }
          `}
        >
          <Text
            fontSize="60px"
            color="white"
            fontWeight="bold"
            p={0}
            m={0}
            overrideCss={css`
              @media ${theme.mediaQueries.mobileOnly} {
                font-size: 35px;
              }
            `}
          >
            Less stress. Better results with our online tutors.
          </Text>
          <Text
            color="white"
            p={0}
            m={0}
            mt="3%"
            fontSize="24px"
            overrideCss={css`
              @media ${theme.mediaQueries.mobileOnly} {
                font-size: 16px;
              }
            `}
          >
            Worried about the upcoming exams? Get help today with an online
            tutor. Book now!
          </Text>
          <Flex
            backgroundColor="rgba(200, 200, 200, 0.9)"
            boxShadow="0 0 10px #0000001a;"
            width="fit-content"
            p="10px"
            borderRadius="8px"
            alignItems="center"
            alignContent="center"
            mt="5%"
            overrideCss={css`
              @media ${theme.mediaQueries.mobileOnly} {
                padding: 7px;
              }
            `}
          >
            <Text
              fontFamily="Roboto"
              p={0}
              m={0}
              fontWeight="bold"
              fontSize="13px"
              overrideCss={css`
                @media ${theme.mediaQueries.mobileOnly} {
                  font-size: 10px;
                }
              `}
            >
              Average tutor rating (4.5)
            </Text>
            <Image
              alt="app-rating"
              source="/home/stars.svg"
              height="21px"
              ml="15px"
              overrideCss={css`
                @media ${theme.mediaQueries.mobileOnly} {
                  height: 13px;
                }
              `}
            />
          </Flex>

          <Link
            boxShadow="0 0 10px #0000001a;"
            href="https://mysmartcityapp.page.link/Zi7X"
            mt="7%"
            borderRadius="8px"
            hideForDesktop
            id="book-a-service-cta"
            width="fit-content"
            p="13px"
            backgroundColor={theme.colors.primary}
          >
            <Text fontSize="14px" color="white" fontWeight="bold">
              Download now!
            </Text>
          </Link>
          <Button
            boxShadow="0 0 10px #0000001a;"
            onClick={() => setIsQrCodeModalOpen(true)}
            mt="5%"
            width="fit-content"
            borderRadius="8px"
            hideForTablet
            hideForMobile
            id="book-a-service-cta"
            p="20px"
            backgroundColor={theme.colors.primary}
          >
            <Text fontSize="20px" color="white" fontWeight="bold">
              Download now!
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Flex
        backgroundColor="#F6F6F9"
        flex="1"
        p="10px"
        flexDirection="column"
        justifyContent="center"
        boxShadow="0px 0px 10px #0000001A"
      >
        <Text
          textAlign="center"
          color="#707070"
          fontWeight="medium"
          fontSize="14"
          hideForTablet
          hideForDesktop
        >
          Award-winning app
        </Text>
        <Flex width="100%" justifyContent="space-around">
          <AwardBlock
            text="Finalist - BCX Digital Innovation Awards"
            imageUrl="privateServices/bcx.svg"
          />
          <AwardBlock
            text="Best Enterprise Solution 2022"
            imageUrl="privateServices/mtnAOTY.svg"
          />
          <AwardBlock
            text="Play Your Part Award Winner"
            imageUrl="privateServices/SaComp.svg"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default Hero;
