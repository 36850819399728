import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { Image } from 'components/elements';
import { css } from '@emotion/react';
import theme from '../../../../theme';

const ReasonCard = ({
  reason,
  heading,
  imageUrl,
}: {
  reason: string;
  heading: string;
  imageUrl: string;
}) => (
  <Flex
    width="100%"
    flexDirection="row"
    justifyContent="flex-start"
    mb="32px"
    overrideCss={css`
      @media ${theme.mediaQueries.tabletDown} {
        flex-direction: column;
        justify-content: center;
      }
    `}
  >
    <Flex
      backgroundColor={theme.colors.primary}
      justifyContent="center"
      alignItems="center"
      borderRadius="8px"
      p="20px"
      mr="20px"
      mb="20px"
      maxWidth="67px"
      maxHeight="67px"
      overrideCss={css`
        @media ${theme.mediaQueries.tabletDown} {
          max-height: 12px;
          max-width: 42px;
          margin-right: 0;
          margin-left: 50%;
          transform: translateX(-50%);
        }
      `}
    >
      <Image source={imageUrl} alt={imageUrl} />
    </Flex>
    <Flex flexDirection="column">
      <Text
        fontSize="22px"
        fontWeight="600"
        m={0}
        p={0}
        overrideCss={css`
          @media ${theme.mediaQueries.tabletDown} {
            text-align: center;
          }
        `}
      >
        {heading}
      </Text>
      <Text
        fontSize="16px"
        m={0}
        p={0}
        fontWeight="300"
        overrideCss={css`
          @media ${theme.mediaQueries.tabletDown} {
            text-align: center;
          }
        `}
      >
        {reason}
      </Text>
    </Flex>
  </Flex>
);

const WhySmartCitySection = () => {
  const infoElementRef = useRef();
  useEffect(() => {
    const infoAnimation: any = {
      container: infoElementRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'animations/whyMsc/data.json',
    };
    lottie.loadAnimation(infoAnimation);
    return () => {
      lottie.destroy();
    };
  }, []);
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      mt="40px"
      p="20px"
      mobileCss={css`
        margin-top: 0;
      `}
    >
      <Grid
        gridTemplateColumns="1fr 2fr"
        ml="20%"
        overrideCss={css`
          @media ${theme.mediaQueries.tabletDown} {
            display: flex;
            flex-direction: column-reverse;
            margin-left: 0;
          }
        `}
      >
        <Flex
          flexDirection="column"
          mobileCss={css`
            margin-top: 40px;
          `}
        >
          <Text
            textAlign="left"
            mb="20px"
            color="#000000"
            fontSize="40px"
            fontWeight="bold"
            overrideCss={css`
              @media ${theme.mediaQueries.tabletDown} {
                text-align: center;
                font-size: 30px;
              }
            `}
          >
            Why My Smart City
          </Text>
          <Flex flexDirection="column" width="100%" justifyContent="center">
            <ReasonCard
              imageUrl="privateServices/flag.svg"
              heading="Convenience"
              reason="Book an online tutor with a tap of a button."
            />
            <ReasonCard
              imageUrl="privateServices/training.svg"
              heading="Well-trained professionals"
              reason="Our tutors are trained and screened during onboarding."
            />
            {/* <ReasonCard
              imageUrl="privateServices/Group 4595.svg"
              heading="Integrity"
              reason="Skilled service providers backed by My Smart City and a dedicated support team."
            /> */}
            <ReasonCard
              imageUrl="privateServices/time_clock_icon.svg"
              heading="Complete control of your time"
              reason="Book an online session as and when you need it."
            />
            <ReasonCard
              imageUrl="privateServices/hand_shake.svg"
              heading="Trust and Transparency"
              reason="See the rates and reviews of our reliable and trustworthy tutors."
            />
          </Flex>
        </Flex>
        <Flex
          flex="1"
          alignContent="center"
          justifyContent="center"
          width="100%"
        >
          <Image
            source="privateServices/tutor-gif.gif"
            alt="tutor gif"
            maxWidth="100%"
            objectFit="contain"
          />
        </Flex>
      </Grid>
    </Flex>
  );
};

export default WhySmartCitySection;
