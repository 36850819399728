import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  overflow-y: scroll;
  width: min-content;    
  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar { display: none; } /* Chrome */
`;

export const Button = styled.div`
  cursor: pointer;
  padding: 0 10px;
  border-radius: 4px;
  background: ${(props) => (props.active ? props.theme.colors.primary : 'none')}; 
  font: normal normal normal 14px/19px ${(props) => (props.theme.font)};
  color: ${(props) => (props.active ? '#FFFFFF' : props.theme.colors.dark)};
  line-height: 25px;
  white-space: nowrap;
  transition: all 0.15s;
  margin-right: 5px;

  &:hover {
    background: ${(props) => (props.active ? props.theme.colors.primary : '#00000010')};
  }

  div:not(:last-child) {
    margin-right: 5px;
  }

  div:first-child {
    margin-left: auto;
  }

  div:last-child {
    margin-right: auto;
  }

  @media only screen and (min-width: 800px) {
    padding: 0 15px;
    line-height: 35px;
  }
`;
