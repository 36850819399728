import { MunicipalPageWrapper } from 'components/my-municipality';
import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { useEffect, useState } from 'react';
import {
  CustomerPremises,
  GMMunicipalAccount,
  Meter,
  MeterReading,
  MeterType,
  PastReading,
  UtilityAccount,
} from 'types/types';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { Loader } from 'components/inthecity';
import UtilityAccountItem from 'components/my-municipality/utility-account-item';
import ExternalLink from 'components/elements/external-link';
import { useNavigate, useParams } from 'react-router-dom';
import theme from 'theme';
import useQuery from 'hooks/use-query';
import { peachSuccessCode } from 'constants/peach';
import { hexToRGBA } from 'functions/graph';
import { forEach } from 'lodash';
import { Cookies } from 'react-cookie';
import { convertToReadableDate } from 'functions/date';

const cookies = new Cookies();

const PastReadingsPage = () => {
  // Hooks
  const navigate = useNavigate();
  const { cityId } = useParams();
  const query = useQuery();

  // API Calls
  const {
    loading: loadingMeterTypes,
    callApi: getMeterTypes,
    response: meterTypes,
  } = useSmartCityManager(SCManagerEndpoints.GetMeterTypes);

  // State management and constants
  const premiseId = query.get('premiseId');
  const accountNumber = query.get('accountNumber');
  const [readings, setReadings] = useState<PastReading[]>([]);

  useEffect(() => {
    getMeterTypes();
  }, []);

  useEffect(() => {
    // WHY MUST THIS BE SO OVERLY COMPLEX JUST TO GET THE FULL LIST OF READINGS :(
    if (premiseId && meterTypes && meterTypes.length > 0) {
      const fetchPastReadings = async () => {
        const promises = meterTypes.map(async (meterType: MeterType) => {
          const response = await fetch(
            `https://${process.env.REACT_APP_API_BASE}/api/msc/rest/smartcitymanager/getMeterReadingsForFavLocation?premiseId=${premiseId}&meterTypeId=${meterType.value}`,
            {
              method: 'GET',
              headers: {
                Accept: 'application/json, text/javascript; q=0.01',
                'Content-Type': 'application/problem+json',
                firebase_token: cookies.get('firebaseToken'),
              },
            }
          );
          const data = await response.json();
          return data;
        });

        const tempReadings = await Promise.all(promises);
        const flattenedReadings = tempReadings.flat();
        setReadings(flattenedReadings);
      };

      fetchPastReadings();
    }
  }, [premiseId, meterTypes]);

  return (
    <MunicipalPageWrapper>
      {loadingMeterTypes ? (
        <Loader />
      ) : (
        <Flex
          maxWidth="720px"
          mx="auto"
          width="100%"
          p="24px 28px 54px 28px"
          flexDirection="column"
          borderRadius="4px"
          boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
        >
          {premiseId && accountNumber && (
            <UtilityAccountItem
              account={{
                premiseId: parseInt(premiseId, 10),
                accountNumber,
                orgUnitId: 0,
                orgUnitDescription: 'Municipal Account',
                active: true,
                meters: [],
                externalId: '0',
                statusId: 2,
                status: 'Verified',
              }}
            />
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="2"
            viewBox="0 0 658 2"
            fill="none"
          >
            <path
              d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H658V0H1V2Z"
              fill="#F0F0F0"
            />
          </svg>

          <Flex
            mt="30px"
            flexDirection="column"
            width="100%"
            justifyContent="center"
            gap="24px"
          >
            {readings
              ?.sort((r: PastReading) => r.readingDate)
              .map((reading: PastReading) => (
                <Flex
                  justifyContent="space-between"
                  width="100%"
                  p="16px"
                  key={reading.refNo}
                >
                  <Flex
                    alignItems="center"
                    gap="12px"
                    justifyContent="flex-start"
                    width="100%"
                    color="black"
                  >
                    <Image
                      source={`https://mysmart.city/api/msc/images/custom/${reading.icon}?subscriberId=george`}
                      alt={reading.readingDate.toString()}
                      p="10px"
                      backgroundColor={
                        hexToRGBA(reading.colour, 0.1) ?? 'white'
                      }
                      borderRadius="50%"
                    />
                    <Text>Reading: {reading.reading}</Text>
                  </Flex>
                  <Text fontSize="10px">
                    {convertToReadableDate(reading.readingDate)}
                  </Text>
                </Flex>
              ))}
          </Flex>
          <Grid
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="17px"
            width="100%"
            mt="36px"
          >
            <Button
              onClick={() => navigate(`/my-municipality/services/${cityId}`)}
              p="14px"
              border={`1px solid ${theme.colors.primary}`}
              fontSize="16px"
              color={theme.colors.primary}
            >
              Return Home
            </Button>
            <Button
              p="14px"
              fontSize="16px"
              color="white"
              backgroundColor={theme.colors.primary}
              onClick={() =>
                navigate(
                  `/my-municipality/municipal-accounts/${cityId}?query=self-meter-readings`
                )
              }
            >
              Add another meter reading
            </Button>
          </Grid>
        </Flex>
      )}
    </MunicipalPageWrapper>
  );
};

export default PastReadingsPage;
