import React, { useState, useRef } from 'react';

import { useOnClickOutside } from 'functions/home';
import { useGlobalContext } from 'contexts/global';
import {
  Container,
  NavbarButton,
  Fab,
  Dropdown,
  Item,
  Icon,
  Label,
  Title,
} from './createpostmenu.styled';

const CreatePostMenu = ({ handleCreatePost }) => {
  const {
    globalState: { city, user },
  } = useGlobalContext();
  console.log(city);
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));

  const handleClick = (type) => {
    handleCreatePost(type);
    setOpen(false);
  };

  return (
    <Container ref={ref}>
      {((city.value === 663 &&
        user.smartCityUserRoles.map((r) => r.id).includes(6)) ||
        city.value !== 663) && (
        <>
          <NavbarButton onClick={() => setOpen(!open)}>
            Create Post
          </NavbarButton>
          <Fab onClick={() => setOpen(!open)} active={open} />
        </>
      )}

      <Dropdown open={open}>
        <Title>Create Post</Title>
        <Item onClick={() => handleClick('announcement')}>
          <Icon src="social/alert-icon.svg" />
          <Label>Announcement</Label>
        </Item>
        <Item onClick={() => handleClick('report')}>
          <Icon src="social/report-icon.svg" />
          <Label>Report</Label>
        </Item>
        <Item onClick={() => handleClick('petition')}>
          <Icon src="social/petition-icon.svg" />
          <Label>Petition</Label>
        </Item>
      </Dropdown>
    </Container>
  );
};

export default CreatePostMenu;
