/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, createContext } from 'react';

const SocialContext = createContext();

const initialFilters = {
  date: '',
  category: '',
  startDate: '',
  endDate: ''
};

const SocialProvider = (props) => {
  const [filters, setFilters] = useState(initialFilters);
  const [alerts, setAlerts] = useState([]);
  const [loadsheddingStage, setLoadsheddingStage] = useState(0);

  function addFilter(item) {
    setFilters({ ...filters, ...item });
  }

  function resetFilters() {
    setFilters(initialFilters);
  }

  // function remove(index) {
  //   const copy = [...filters];
  //   copy.splice(index, 1);
  //   setFilters(copy);
  // }

  const data = {
    filters,
    addFilter,
    resetFilters,
    alerts,
    setAlerts,
    loadsheddingStage,
    setLoadsheddingStage,
  };

  return <SocialContext.Provider value={data} {...props} />;
};

const useSocialContext = () => useContext(SocialContext);

export {
  SocialProvider,
  useSocialContext,
};
