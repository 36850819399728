import React from 'react';
import {
  Form,
  Button,
} from 'react-bootstrap';
import 'styles/mapModal.scss';
import { Context } from 'functions/context';
import lottie from 'lottie-web';
import { Scrollbars } from 'react-custom-scrollbars';
import { apiBaseUrlBase } from 'constants/app';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import {
  addWorkRequestNote,
  addWorkRequestAttachment,
  getMyMapWorkRequests,
} from 'interface/map';

export default class EditReportModal extends React.Component {
    static contextType = Context;

    constructor(props) {
      super(props);
      this.state = {
        loadingEditReportModal: false,
        loadingEditReportModalA: false,
        addNote: false,
        addNoteText: '',
        file1: [],
        image1: {},
        fileError1: '',
        file2: [],
        image2: {},
        fileError2: '',
        file3: [],
        image3: {},
        fileError3: '',
        file4: [],
        image4: {},
        fileError4: '',
      };
      this.imageFile1 = React.createRef();
      this.imageFile2 = React.createRef();
      this.imageFile3 = React.createRef();
      this.imageFile4 = React.createRef();
    }

    handleSubmit = () => {
      console.log('EditReportModal: handleSubmit()');
      const {
        showEditReportWR,
        setState,
        currentLocation,
        currentCity,
        getWorkRequests,
        thisOfOverlay,
      } = this.props;
      const {
        file1,
        file2,
        file3,
        file4,
        addNoteText,
      } = this.state;
      // Set loading to true
      this.setState({
        loadingEditReportModal: true,
        loadingEditReportModalA: true,
      });
      const mylittlepromises = [];
      if (addNoteText !== '') {
        mylittlepromises.push(addWorkRequestNote(showEditReportWR.id, addNoteText));
      }
      if (file1.length === 1) {
        const formData = new FormData();
        console.log('Uploaded file to', file1[0]);
        formData.append('workRequestId', showEditReportWR.id);
        formData.append('file', file1[0]);
        mylittlepromises.push(addWorkRequestAttachment(formData));
      }
      if (file2.length === 1) {
        const formData = new FormData();
        console.log('Uploaded file to', file2[0]);
        formData.append('workRequestId', showEditReportWR.id);
        formData.append('file', file2[0]);
        mylittlepromises.push(addWorkRequestAttachment(formData));
      }
      if (file3.length === 1) {
        const formData = new FormData();
        console.log('Uploaded file to', file3[0]);
        formData.append('workRequestId', showEditReportWR.id);
        formData.append('file', file3[0]);
        mylittlepromises.push(addWorkRequestAttachment(formData));
      }
      if (file4.length === 1) {
        const formData = new FormData();
        console.log('Uploaded file to', file4[0]);
        formData.append('workRequestId', showEditReportWR.id);
        formData.append('file', file4[0]);
        mylittlepromises.push(addWorkRequestAttachment(formData));
      }

      Promise.all(mylittlepromises).then(() => {
        // Set loading to false
        getWorkRequests(
          setState,
          currentLocation,
          null,
          currentCity.attributes.subscriberId,
          true,
        ).then(() => {
          getMyMapWorkRequests(1, 200).then((myWorkRequests) => {
            setState({ myWorkRequests });
            thisOfOverlay.setState({
              showEditReport: false,
              showMarkerView: true,
            });
          });
        });
      });
    };

    componentDidMount = async () => {
      // Loading
      const animLogin1 = lottie.loadAnimation({
        name: 'loadingEditReportModal',
        container: this.animateLoading1,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'mscLogoGrey.json',
      });
      animLogin1.setSpeed(1.5);

      animLogin1.onLoopComplete = () => {
        const { loadingEditReportModal, loadingEditReportModalA } = this.state;
        console.log('onLoopComplete', loadingEditReportModal, loadingEditReportModalA);
        if (!loadingEditReportModal) {
          lottie.stop('loadingEditReportModal');
          this.setState({ loadingEditReportModalA: false });
          console.log('onLoopComplete: loadingEditReportModal STOPPED');
        }
      };
    }

    componentWillUnmount = () => {
      lottie.destroy('loadingEditReportModal');
    };

    render() {
      const {
        loadingEditReportModal,
        loadingEditReportModalA,
        addNote,
        addNoteText,
        file1,
        image1,
        fileError1,
        file2,
        image2,
        fileError2,
        file3,
        image3,
        fileError3,
        file4,
        image4,
        fileError4,
      } = this.state;
      const {
        currentUser,
        showEditReportWR,
        setState,
      } = this.props;

      if (loadingEditReportModal) {
        lottie.play('loadingEditReportModal');
      }
      return (
        <div id="editReportModal" className="mapModal">
          <div
            id="LoadingLoginOverlayContainer"
            style={
              loadingEditReportModalA
                ? {
                  display: 'block',
                }
                : {
                  display: 'none',
                }
              }
          >
            <div
              id="LoadingLoginOverlay"
              className={loadingEditReportModal
                ? 'fadeIn'
                : 'fadeOut'}
            />
            <div
              ref={(ref) => { this.animateLoading1 = ref; }}
              id="LoadingLoginOverlayImage"
              style={
                loadingEditReportModalA
                  ? {
                    display: 'block',
                  }
                  : {
                    display: 'none',
                  }
              }
            />
          </div>
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMax="70vh"
          >
            <div className="popupEditContainer">
              <div className="popupEditTitle">
                Edit Issue
              </div>
              <div className="popupEditSubText">
                Here you can
                {' '}
                <div className="textAccentOrange"> add more information</div>
                {' '}
                to you logged issue.
              </div>
              <div className="popupEditSection">
                <div className="popupEditSubTitle">
                  Photos
                </div>
                <Scrollbars autoHeight>
                  <div style={{ display: 'flex', padding: '0px 0px 20px' }}>
                    {file1.length === 1
                      ? (
                        <div
                          className={fileError1 === '' ? 'popupEditUploadImageBlock' : 'popupEditUploadImageBlock popupEditFileError'}
                          style={{ background: `url('${image1}')` }}
                        >
                          <div
                            className="popupEditImageUploadCrossIconContainer"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              this.setState({
                                file1: [],
                                image1: {},
                                fileError1: '',
                              });
                            }}
                          >
                            <img
                              className="popupEditImageUploadCrossIcon"
                              src="map/mapMarker/imageCross.svg"
                              alt="map/mapMarker/imageCross.svg"
                            />

                          </div>
                          <div style={{ height: '135px', width: '135px' }} />
                          <div className="popupEditFileErrorText">
                            {fileError1}
                          </div>
                        </div>
                      )
                      : (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => (this.imageFile1.current.click())}
                        >
                          <img
                            className="popupEditImageUploadContainerIcon"
                            src="map/Profile_Picture_Holder.svg"
                            alt="icons/Profile_Picture_Holder.svg"
                          />
                        </div>
                      )}
                    {file2.length === 1
                      ? (
                        <div
                          className={fileError2 === '' ? 'popupEditUploadImageBlock' : 'popupEditUploadImageBlock popupEditFileError'}
                          style={{
                            background: `url('${image2}')`,
                          }}
                        >
                          <div
                            className="popupEditImageUploadCrossIconContainer"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              this.setState({
                                file2: [],
                                image2: {},
                                fileError2: '',
                              });
                            }}
                          >
                            <img
                              className="popupEditImageUploadCrossIcon"
                              src="map/mapMarker/imageCross.svg"
                              alt="map/mapMarker/imageCross.svg"
                            />
                          </div>
                          <div style={{ height: '135px', width: '135px' }} />
                          <div className="popupEditFileErrorText">
                            {fileError2}
                          </div>
                        </div>
                      )
                      : (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => (this.imageFile2.current.click())}
                        >

                          <img
                            className="popupEditImageUploadContainerIcon"
                            src="map/Profile_Picture_Holder.svg"
                            alt="icons/Profile_Picture_Holder.svg"
                          />
                        </div>
                      )}
                    {file3.length === 1
                      ? (
                        <div
                          className={fileError3 === '' ? 'popupEditUploadImageBlock' : 'popupEditUploadImageBlock popupEditFileError'}
                          style={{
                            background: `url('${image3}')`,
                          }}
                        >
                          <div
                            className="popupEditImageUploadCrossIconContainer"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              this.setState({
                                file3: [],
                                image3: {},
                                fileError3: '',
                              });
                            }}
                          >
                            <img
                              className="popupEditImageUploadCrossIcon"
                              src="map/mapMarker/imageCross.svg"
                              alt="map/mapMarker/imageCross.svg"
                            />
                          </div>
                          <div style={{ height: '135px', width: '135px' }} />
                          <div className="popupEditFileErrorText">
                            {fileError3}
                          </div>
                        </div>
                      )
                      : (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => (this.imageFile3.current.click())}
                        >

                          <img
                            className="popupEditImageUploadContainerIcon"
                            src="map/Profile_Picture_Holder.svg"
                            alt="icons/Profile_Picture_Holder.svg"
                          />
                        </div>
                      )}
                    {file4.length === 1
                      ? (

                        <div
                          className={fileError4 === '' ? 'popupEditUploadImageBlock' : 'popupEditUploadImageBlock popupEditFileError'}
                          style={{
                            background: `url('${image4}')`,
                          }}
                        >
                          <div
                            className="popupEditImageUploadCrossIconContainer"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              this.setState({
                                file4: [],
                                image4: {},
                                fileError4: '',
                              });
                            }}
                          >
                            <img
                              className="popupEditImageUploadCrossIcon"
                              src="map/mapMarker/imageCross.svg"
                              alt="map/mapMarker/imageCross.svg"
                            />
                          </div>
                          <div style={{ height: '135px', width: '135px' }} />
                          <div className="popupEditFileErrorText">
                            {fileError4}
                          </div>
                        </div>
                      )
                      : (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => (this.imageFile4.current.click())}
                        >
                          <img
                            className="popupEditImageUploadContainerIcon"
                            src="map/Profile_Picture_Holder.svg"
                            alt="icons/Profile_Picture_Holder.svg"
                          />
                        </div>
                      )}
                  </div>
                </Scrollbars>
              </div>
              <div className="popupEditSection">
                <div className="popupEditSubTitle">
                  Notes
                  <div
                    className="popupEditAddNote"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (addNote) {
                        this.setState({ addNote: !addNote, addNoteText: '' });
                      } else {
                        this.setState({ addNote: !addNote });
                      }
                    }}
                  >
                    {!addNote
                      ? (
                        <>
                          <img className="popupEditAddNoteImage" src="map/editReportModal/plus.svg" alt="plus" />
                          Add note
                        </>
                      )
                      : (
                        <>
                          <img className="popupEditAddNoteImage" src="map/editReportModal/bin.svg" alt="bin" />
                          Cancel
                        </>
                      ) }
                  </div>
                </div>
                {addNote
                  ? (
                    <>
                      <div className="reportsNoteContainer">
                        <img
                          src={`${apiBaseUrlBase}images/profilepicture/?username=${currentUser.username}`}
                          alt="profileimage"
                          className="notesProfileImage"
                        />
                        <div className="reportsNoteTextContainer">
                          <Form.Group style={{
                            width: '100%',
                            marginBottom: '0px',
                          }}
                          >
                            <Form.Control
                              as="textarea"
                              rows={4}
                              value={addNoteText}
                              onChange={(e) => {
                                this.setState({ addNoteText: e.target.value });
                              }}
                              style={{
                                marginBottom: '5px',
                                resize: 'none',
                                width: '100%',
                                borderRadius: '10px',
                                borderColor: 'grey',
                                fontSize: '1.2rem',
                                padding: '10px',
                              }}
                              placeholder="Your note"
                            />
                            <Form.Text className="text-muted" style={{ margin: '0px 0px 0px' }}>
                              Your note should be less than
                              {' '}
                              <div className="textAccentOrange"> 2000 characters.</div>
                            </Form.Text>
                          </Form.Group>
                        </div>
                      </div>
                    </>
                  )
                  : null}
                {showEditReportWR.notes.map((note) => (
                  <>
                    <div className="reportsNoteContainer">
                      <img
                        src={`${apiBaseUrlBase}images/profilepicture/?username=${note.username}`}
                        alt="profileimage"
                        className="notesProfileImage"
                      />
                      <div className="reportsNoteTextContainer">
                        <div className="reportsNoteText">
                          {note.noteText}
                        </div>
                        <div className="reportsSubNoteText">
                          {note.username}
                          {' '}
                          on the
                          {' '}
                          <Moment
                            tz={moment.tz.guess()}
                            format="DD/MM/YYYY"
                            date={moment.unix(note.timestamp / 1000)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                {
                  file1.length === 0
                  && file1.length === 0
                  && file1.length === 0
                  && file1.length === 0
                  && addNoteText === ''
                    ? (
                      <Button
                        className="modalButton"
                        onClick={() => setState({ showEditReport: false, showMarkerView: true })}
                      >
                        CLOSE
                      </Button>
                    )
                    : (
                      <>
                        { fileError1 === ''
                          && fileError2 === ''
                          && fileError3 === ''
                          && fileError4 === ''
                          && addNoteText.length < 2000
                          ? (
                            <Button
                              className="modalButton"
                              onClick={() => this.handleSubmit()}
                            >
                              SEND
                            </Button>
                          )
                          : (
                            <Button
                              className="modalButton buttonGreyed"
                            >
                              SEND
                            </Button>
                          )}
                      </>
                    )
                }
              </div>
            </div>
          </Scrollbars>
          <input
            type="file"
            id="popupEditFile1"
            ref={this.imageFile1}
            style={{ display: 'none' }}
            onChange={(e) => {
              const allowedExtensions = /(jpg|jpeg|png)$/i;
              if (!allowedExtensions.exec(e.target.files[0].type)) {
                console.log('incorrect file type', e.target.files[0]);
                this.setState({ fileError1: 'Incorrect file type' });
              } else if (e.target.files[0].size > 10000000) {
                console.log('File too large', e.target.files[0].size);
                this.setState({ fileError1: 'File too large' });
              } else {
                this.setState({ fileError1: '' });
              }
              this.setState({
                file1: e.target.files,
                image1: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />
          <input
            type="file"
            id="popupEditFile2"
            ref={this.imageFile2}
            style={{ display: 'none' }}
            onChange={(e) => {
              const allowedExtensions = /(jpg|jpeg|png)$/i;
              if (!allowedExtensions.exec(e.target.files[0].type)) {
                console.log('incorrect file type', e.target.files[0]);
                this.setState({ fileError2: 'Incorrect file type' });
              } else if (e.target.files[0].size > 10000000) {
                console.log('File too large', e.target.files[0].size);
                this.setState({ fileError2: 'File too large' });
              } else {
                this.setState({ fileError2: '' });
              }
              this.setState({
                file2: e.target.files,
                image2: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />
          <input
            type="file"
            id="popupEditFile3"
            ref={this.imageFile3}
            style={{ display: 'none' }}
            onChange={(e) => {
              const allowedExtensions = /(jpg|jpeg|png)$/i;
              if (!allowedExtensions.exec(e.target.files[0].type)) {
                console.log('incorrect file type', e.target.files[0]);
                this.setState({ fileError3: 'Incorrect file type' });
              } else if (e.target.files[0].size > 10000000) {
                console.log('File too large', e.target.files[0].size);
                this.setState({ fileError3: 'File too large' });
              } else {
                this.setState({ fileError3: '' });
              }
              this.setState({
                file3: e.target.files,
                image3: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />
          <input
            type="file"
            id="popupEditFile4"
            ref={this.imageFile4}
            style={{ display: 'none' }}
            onChange={(e) => {
              const allowedExtensions = /(jpg|jpeg|png)$/i;
              if (!allowedExtensions.exec(e.target.files[0].type)) {
                console.log('incorrect file type', e.target.files[0]);
                this.setState({ fileError4: 'Incorrect file type' });
              } else if (e.target.files[0].size > 10000000) {
                console.log('File too large', e.target.files[0].size);
                this.setState({ fileError4: 'File too large' });
              } else {
                this.setState({ fileError4: '' });
              }
              this.setState({
                file4: e.target.files,
                image4: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />
        </div>
      );
    }
}
