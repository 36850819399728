/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Image, Modal } from 'components/elements';
import { Loader } from 'components/inthecity';
import { Flex, Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import {
  GMIDP,
  GMIDPSummary,
  GoogleSuggestedAddress,
  LabelValuePair,
} from 'types/types';
import { useNavigate, useParams } from 'react-router-dom';
import InteractiveAddressSelector from 'components/interactive-address-selector';
import Form from 'components/form';
import SubmitButton from 'components/form/submit-button';
import { InputField } from 'components/form/input';
import { SmallLoader } from 'components/private-services/survey';
import Dropdown from 'components/dropdown';

const IDPContributionPage = () => {
  const { cityId } = useParams();
  const {
    loading: gettingIDPS,
    response: availableIDPS,
    callApi: getAvailableIDPS,
  } = useSmartCityManager(SCManagerEndpoints.GetAvailableIDPS);
  const {
    loading: postingIDP,
    response: postIDPResponse,
    callApi: postIDP,
  } = useSmartCityManager(SCManagerEndpoints.PostIDP);
  const {
    loading: gettingWard,
    response: wardNo,
    callApi: getWardNo,
  } = useSmartCityManager(SCManagerEndpoints.GetWardNoForLocation);

  const postedIDP: GMIDP | null = postIDPResponse;

  const [address, setAddress] = useState<GoogleSuggestedAddress | null>(null);
  const [selectedIDP, setSelectedIDP] = useState<LabelValuePair>({
    label: 'Please select a topic',
    value: -1,
  });
  const [step, setStep] = useState(1);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAvailableIDPS();
  }, []);

  return (
    <MunicipalPageWrapper title="IDP Contribution">
      <Modal
        isOpen={locationModalOpen}
        onClose={() => setLocationModalOpen(false)}
      >
        <InteractiveAddressSelector
          setAddressCallback={(a) => {
            setAddress(a);
            getWardNo({
              queryParams: `latitude=${a?.geometry.location.lat}&longitude=${a?.geometry.location.lng}&subscriberId=${cityId}`,
            });
            setLocationModalOpen(false);
          }}
        />
      </Modal>
      {gettingIDPS || postingIDP ? (
        <Loader />
      ) : (
        <>
          {step === 1 ? (
            <>
              <Grid
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="16px"
                mx="auto"
              >
                <Button
                  boxShadow={theme.cardShadow}
                  borderRadius="4px"
                  py="26px"
                  px="20px"
                  hoverCss={css`
                    color: ${theme.colors.primary};
                  `}
                  onClick={() => setStep(2)}
                >
                  New contribution
                </Button>
                <Button
                  onClick={() =>
                    navigate(`/my-municipality/my-idp-contributions/${cityId}`)
                  }
                  boxShadow={theme.cardShadow}
                  borderRadius="4px"
                  p="16px"
                  hoverCss={css`
                    color: ${theme.colors.primary};
                  `}
                >
                  My contributions
                </Button>
              </Grid>
            </>
          ) : step === 2 && !postedIDP ? (
            <Form
              onSubmit={(data) =>
                postIDP({
                  body: {
                    templateWorkOrderId: selectedIDP.value,
                    comment: data.comment,
                    latitude: address?.geometry.location.lat,
                    longitude: address?.geometry.location.lng,
                  },
                })
              }
            >
              <Grid
                maxWidth="520px"
                mx="auto"
                borderRadius="4px"
                p="24px"
                width="100%"
                boxShadow={theme.cardShadow}
                gridRowGap="20px"
              >
                <Text fontSize="16px" fontWeight={600}>
                  New Contribution
                </Text>
                <Button
                  onClick={() => setLocationModalOpen(true)}
                  position="relative"
                >
                  <Image
                    source="icons/edit.svg"
                    alt="edit"
                    position="absolute"
                    right="10px"
                    top="33%"
                    height="33%"
                    backgroundColor="white"
                    pl="10px"
                    zIndex={1}
                  />
                  <InputField
                    hoverCss={css`
                      cursor: pointer;
                    `}
                    disabled
                    name="address"
                    value={address?.formatted_address}
                    placeholder="Click here to select your address"
                    label="Address"
                    alwaysShowLabel
                  />
                </Button>
                {gettingWard ? (
                  <SmallLoader />
                ) : (
                  <InputField
                    name="ward"
                    label="Ward Number"
                    alwaysShowLabel
                    disabled
                    value={wardNo || 'Select an address...'}
                  />
                )}
                <Dropdown
                  label="Select your topic"
                  value={selectedIDP}
                  options={availableIDPS
                    .filter(
                      (i: GMIDPSummary) =>
                        new Date().getTime() < i.availableTill * 1000
                    )
                    .map((i: GMIDPSummary) => ({
                      label: i.description,
                      value: i.id,
                    }))}
                  onChange={(o) => setSelectedIDP(o)}
                />
                <InputField
                  name="comment"
                  label="Comment"
                  multiline
                  alwaysShowLabel
                  disabled={!address || selectedIDP.value === -1}
                />

                <SubmitButton label="Submit" />
              </Grid>
            </Form>
          ) : postedIDP && step === 2 ? (
            <Flex
              borderRadius="4px"
              boxShadow={theme.cardShadow}
              p="16px"
              mx="auto"
              justifyContent="center"
              width="100%"
              maxWidth="540px"
              flexDirection="column"
              flex="1"
              height="100%"
            >
              <Image
                mx="auto"
                source="my-municipality/smile.svg"
                alt="smile"
                width="85px"
                mb="10px"
              />
              <Text textAlign="center" color="#C0C0C0" mb="10px">
                Thank you for your feedback! Reference number below
              </Text>
              <Text textAlign="center" fontSize="20px" fontWeight={600}>
                {postedIDP?.idpRefNo}
              </Text>
              <Flex width="100%" mt="30px">
                <Button
                  variant="cta"
                  width="100%"
                  mr="10px"
                  onClick={() =>
                    navigate(`/my-municipality/my-idp-contributions`)
                  }
                >
                  View my contributions
                </Button>
                <Button
                  variant="outlined"
                  width="100%"
                  mr="10px"
                  onClick={() => navigate(`/my-municipality/surveys/${cityId}`)}
                >
                  Back to home
                </Button>
              </Flex>
            </Flex>
          ) : (
            <></>
          )}
        </>
      )}
    </MunicipalPageWrapper>
  );
};

export default IDPContributionPage;
