import { css } from '@emotion/react';

import { Flex } from 'components/layout';
import { ReactSVG } from 'react-svg';
import theme from 'theme';

const Stars = ({ isActive }: { isActive: boolean }) => (
  <Flex
    justifyContent="center"
    overrideCss={css`
      svg {
        overflow: visible !important;
      }

      path,
      rect,
      circle,
      ellipse {
        overflow: visible;
        fill: ${theme.colors.lightGrey} !important;
        stroke: ${theme.colors.lightGrey};
        transform: scale(4, 4);
        transition: 0.1s ease-in-out;
        transform-origin: center center;
        ${isActive &&
        css`
          transform: translateX(-25%) translateY(-25%);

          transform: scale(4.1, 4.1);
          fill: ${theme.colors.primary} !important;
          stroke: ${theme.colors.primary};
        `}
      }
    `}
  >
    <ReactSVG src="privateServices/private-services-star.svg" />
  </Flex>
);
export default Stars;
