/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useInTheCityContext } from 'contexts/inthecity';
import { useGlobalContext } from 'contexts/global';
import {
  Search,
  Breadcrumbs,
  PageLinksGrid,
  CategoryList,
  MobileCategoryList,
} from 'components/inthecity';
import {
  getEventCategories,
  getEventsByCategory,
} from 'interface/inthecity/webtickets';
import { getPlaces } from 'interface/inthecity/foursquare';
import { TopRow, PageContainer } from './social.styled';
import {
  ContentContainer,
  SearchContainer,
  ContentRow,
  Title,
} from './inthecity.styled';

/**
 * Home page of In-the-City
 */
const InTheCityPage = () => {
  // context
  const { globalState } = useGlobalContext();
  const { eventCategories, featuredEvents, places, updateState } =
    useInTheCityContext();

  // state
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  useEffect(() => {
    console.log('inthecity():', globalState);
    const fetchData = async () => {
      // get event categories, featured events and places
      if (!eventCategories || !featuredEvents || !places) {
        // TODO: hardcode categories
        // events
        const categoriesRes = await getEventCategories();
        const featuredCategory = categoriesRes.find(
          ({ category }) => category === 'Featured'
        );
        const featuredEventsRes = await getEventsByCategory(
          featuredCategory ? featuredCategory.id : 0
        );

        // places
        const { results } = await getPlaces(
          `${globalState.location.latitude},${globalState.location.longitude}`
        );

        // update context state
        updateState({
          eventCategories: categoriesRes,
          featuredEvents: featuredEventsRes,
          places: results,
        });
      }
    };
    fetchData();
  }, []);

  // set search suggestions from featured events
  useEffect(() => {
    const eventNames = featuredEvents?.map(({ name }) => name);
    setSearchSuggestions(eventNames);
  }, [featuredEvents]);

  return (
    <>
      <PageContainer>
        <TopRow>
          <Breadcrumbs />
          {/* <LocationFilter /> */}
        </TopRow>

        <ContentContainer>
          {/* <Carousel /> */}

          <SearchContainer>
            <Search
              placeholder="Search events or places of interests"
              variation="dark"
              searchType="all"
              suggestions={searchSuggestions}
            />
          </SearchContainer>

          <Title>In-the-City Services</Title>

          <ContentRow>
            <CategoryList hideMobile />
            <PageLinksGrid />
          </ContentRow>

          <MobileCategoryList hideDesktop />
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default InTheCityPage;
