/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import { Modal } from 'components/social';
import { getImageURL } from 'interface/inthecity/inthecity';
import {
  ScrollContainer,
  BottomContainer,
  ProductItem,
  ProductContent,
  ProductTotal,
  Button,
  Line,
  Heading,
  Input,
  ErrorLabel,
} from 'components/inthecity/lottoModal/lottoModal.styled';
import {
  ProgressLabel,
  StageCountCircle,
  ProgressHolder,
  Line as DetailsLine,
  ProgressLine,
  ConfirmationLabel,
} from './amountModal.styled';

const DetailsModal = (props) => {
  // props
  const {
    show,
    handleClose,
    handleBack,
    product,
    mobileNumber,
    handleNext,
    ownAmount,
  } = props;

  // state
  const [num, setNum] = useState(mobileNumber || '');

  const [valid, setValid] = useState();

  useEffect(() => {
    const isValidNumber = (n) => /^(\+27|0)[6-8][0-9]{8}$/.test(n);
    setValid(isValidNumber(num));
  }, [num]);

  return (
    <Modal
      title="Phone Details"
      show={show}
      backButton
      handleClose={handleClose}
      handleBack={() => handleBack(num)}
    >
      <ProgressHolder>
        <StageCountCircle done active>
          1
          <ProgressLabel>Amount</ProgressLabel>
        </StageCountCircle>
        <ProgressLine active />
        <StageCountCircle active>
          2
          <ProgressLabel>Phone</ProgressLabel>
        </StageCountCircle>
        <ProgressLine />
        <StageCountCircle>
          3
          <ProgressLabel>Payment</ProgressLabel>
        </StageCountCircle>
        <ProgressLine />
        <StageCountCircle>
          4
          <ConfirmationLabel>Confirmation</ConfirmationLabel>
        </StageCountCircle>
      </ProgressHolder>

      <ScrollContainer>
        <ProductItem>
          <img src={getImageURL(product?.imageName)} alt={product?.name} />
          <ProductContent>
            <p>{product?.name}</p>
          </ProductContent>
          <ProductTotal>{`R ${(product?.price / 100 || ownAmount).toFixed(2)}`}</ProductTotal>
        </ProductItem>

        <DetailsLine />

        <Heading>Phone Number</Heading>

        <Input
          placeholder="Enter phone number"
          type="text"
          inputmode="numeric"
          value={num}
          onChange={(e) => setNum(e.target.value)}
        />

        {(!valid && num) && (
          <ErrorLabel>
            Please enter a valid phone number.
            The number may start with either a zero, e.g. &quot;0825551234&quot;,
            or the international format country code, e.g. &quot;+27825551234&quot;.
          </ErrorLabel>
        )}
      </ScrollContainer>

      <BottomContainer>
        <Line />
        <Button
          disabled={!valid}
          onClick={valid ? () => handleNext(num) : () => {}}
        >
          Next
        </Button>
      </BottomContainer>
    </Modal>
  );
};

export default DetailsModal;
