import React, {
} from 'react';
import _ from 'lodash';
import 'styles/mapModal.scss';
import {
  Context,
} from 'functions/context';
import CityPicker from 'components/map/cityPicker';
import {
  getGoogleMapsAutoComplete,
  selectCity,
  getGoogleMapsEntryID,
} from 'interface/map';
import {
  Form,
} from 'react-bootstrap';

export default class FloatingNavbar extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      searchAddress: '',
      searchingAddress: '',
      searchAddressResults: [],
    };
  }

  handleAddressBoxChange = async (event) => {
    /* signal to React not to nullify the event object */
    event.persist();
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        getGoogleMapsAutoComplete(event.target.value)
          .then((results) => this.setState({
            searchingAddress: false,
            searchAddressResults: results.predictions,
          }));
      }, 1000);
    }
    this.debouncedFn();
    this.setState({ searchAddress: event.target.value, searchingAddress: true });
  };

  render() {
    const {
      searchAddress,
      searchingAddress,
      searchAddressResults,
    } = this.state;
    const {
      availableCities,
      currentCity,
      sidebarOpen,
      setState,
      changeLocation,
      handleChangeCity,
      city,
      serviceType,
      thisOfMapContainerContainer,
      changeCityDropDown,
    } = this.props;
    return (
      <div className={sidebarOpen ? 'mapNavbar mapNavbarSidebarOpen' : 'mapNavbar'}>
        <div className="mapNavbarTop">
          {sidebarOpen
            ? (
              <div
                className="sidebarButton"
                role="button"
                tabIndex={0}
                onClick={() => setState({ sidebarOpen: !sidebarOpen })}
              >
                <img
                  src="map/arrow.svg"
                  alt="Logo"
                  style={{ height: '100%' }}
                />
              </div>
            )
            : (
              <div
                className="sidebarButton"
                role="button"
                tabIndex={0}
                onClick={() => setState({ sidebarOpen: !sidebarOpen })}
              >
                <img
                  src="map/filter.svg"
                  alt="Logo"
                  style={{ height: '100%' }}
                />
              </div>
            ) }
          <CityPicker
            serviceType={serviceType}
            changeCityDropDown={changeCityDropDown}
            city={city}
            availableCities={availableCities}
            setState={(s) => setState(s)}
            handleChangeCity={handleChangeCity}
            currentCity={currentCity}
          />
        </div>
        <div className="mapNavbarBottom">
          <div style={{ position: 'relative', width: '100%' }}>
            <Form.Control
              id="SearchAddressField"
              autoComplete="off"
              type="text"
              placeholder="Search a location"
              value={searchAddress}
              onChange={(s) => this.handleAddressBoxChange(s)}
            />
            <img className="searchFieldIconMag" alt="FavIcon" src="map/magnifyingGlassIcon.svg" />
            {searchAddress !== ''
              ? (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({ searchAddress: '', searchAddressResults: [] });
                    thisOfMapContainerContainer.setState({ searchMarker: null });
                  }}
                >
                  <img className="searchFieldIconCross" alt="FavIcon" src="map/cross.svg" />
                </div>
              )
              : null}
          </div>

          {searchAddress !== '' && searchAddressResults !== -1
            ? (
              <div className="mapSearchBoxDrop">
                {searchingAddress && <div className="searchResult">Loading...</div>}
                {!searchingAddress && searchAddressResults && searchAddressResults.length === 0 && (
                <div className="searchResult">
                  No results...
                </div>
                ) }
                {searchAddressResults && searchAddressResults.length !== 0 && (
                <div>
                  {searchAddressResults.map((res, i) => (
                    <div
                      className="searchResult"
                      key={`searchResult-${i}`}
                      onClick={() => {
                        thisOfMapContainerContainer.setState({ searchMarker: null });
                        setState({
                          loadingWorkRequests: true,
                          loadingWorkRequestsA: true,
                        });
                        this.setState({
                          searchingAddress: false,
                          searchAddressResults: -1,
                        });
                        getGoogleMapsEntryID(res.place_id).then(
                          (res22) => {
                            selectCity(null,
                              res22.result.geometry.location.lat,
                              res22.result.geometry.location.lng)
                              .then((res1) => {
                                thisOfMapContainerContainer.setState({
                                  searchMarker: {
                                    res: res22.result,
                                    res1,
                                  },
                                });
                                setState({
                                  searchAddressResults: -1,
                                  searchAddress: res.description,
                                  searchingAddress: false,
                                });
                                changeLocation({
                                  lng: res22.result.geometry.location.lng,
                                  lat: res22.result.geometry.location.lat + 0.005000,
                                });
                                setState({ loadingWorkRequests: false });
                              });
                          },
                        );
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      {res.description}
                    </div>
                  ))}
                </div>
                )}
              </div>
            )
            : null }
        </div>
        <div
          style={{
            color: 'black',
            padding: '0px 5px',
            fontSize: '10px',
          }}
        >
          <div
            style={{
              display: 'contents',
            }}
          >
            Right click or long press on the map for log menu.
          </div>
          <div style={{ float: 'right' }}>
            v0.3
          </div>
        </div>
      </div>
    );
  }
}
