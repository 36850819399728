/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { useGlobalContext } from 'contexts/global';
import { getLoadSheddingCalendar } from 'interface/social/loadshedding';
import { Loader } from 'components/social/createpost';
import { Modal, Button } from 'components/social';
import { SelectButton } from 'components/social/createpost/createpost.styled';
import {
  Title,
  Subtitle,
  ScheduleContainer,
  Date,
  TimeRange,
  StageList,
  Stage,
} from './blockSchedule.styled';

// Load shedding block schedule modal
const BlockSchedule = (props) => {
  const { show, block, handleClose, suburb, city } = props;
  const { loadsheddingStage } = useGlobalContext();
  const [stage, setStage] = useState(loadsheddingStage || 1);
  const [scheduleData, setScheduleData] = useState([]);
  const [pageStage, setPageStage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchSchedule() {
      setLoading(true);
      const res = await getLoadSheddingCalendar(block, stage);
      setScheduleData(res);
      setLoading(false);
    }
    fetchSchedule();
  }, [stage]);

  const handleStageChange = (s) => {
    setStage(s);
    setPageStage(0);
  };

  const handleStageList = () => setPageStage(1);

  const renderSchedule = (scheduleArray) =>
    scheduleArray.map(({ start, end }, index) => {
      const current = moment.unix(start / 1000).tz(moment.tz.guess());
      const previous =
        index > 0
          ? moment
              .unix(scheduleArray[index - 1].start / 1000)
              .tz(moment.tz.guess())
          : current;
      // if on the same day
      if (index > 0 && current.isSame(previous, 'day')) {
        return (
          <TimeRange>
            {moment
              .unix(start / 1000)
              .tz(moment.tz.guess())
              .format('HH:mm')}
            {' - '}
            {moment
              .unix(end / 1000)
              .tz(moment.tz.guess())
              .format('HH:mm')}
          </TimeRange>
        );
      }
      // if on a new day
      return (
        <>
          <Date>
            {moment
              .unix(start / 1000)
              .tz(moment.tz.guess())
              .format('ddd - DD MMMM YYYY')}
          </Date>
          <TimeRange>
            {moment
              .unix(start / 1000)
              .tz(moment.tz.guess())
              .format('HH:mm')}
            {' - '}
            {moment
              .unix(end / 1000)
              .tz(moment.tz.guess())
              .format('HH:mm')}
          </TimeRange>
        </>
      );
    });

  switch (pageStage) {
    case 0:
      return (
        <Modal show={show} handleClose={handleClose}>
          <Title>{suburb}</Title>
          <Subtitle>{city}</Subtitle>

          <SelectButton onClick={handleStageList}>
            <p>{`Stage ${stage}`}</p>
          </SelectButton>

          <ScheduleContainer>
            {loading && <Loader />}
            {scheduleData.length > 0 &&
              !loading &&
              renderSchedule(scheduleData)}
          </ScheduleContainer>
        </Modal>
      );
    case 1:
      return (
        <Modal
          title="Loadshedding Stages"
          show={show}
          closeButton={false}
          handleClose={() => {}}
          backButton
          handleBack={() => setPageStage(0)}
        >
          <StageList>
            {[...Array(8)].map((_, i) => {
              const s = i + 1;
              return (
                <Stage onClick={() => handleStageChange(s)}>
                  <p>{`Stage ${s}`}</p>
                  <Button label="Select" active={stage !== s} />
                </Stage>
              );
            })}
          </StageList>
        </Modal>
      );
    default:
      return null;
  }
};

export default BlockSchedule;
