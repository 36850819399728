import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Headline } from 'components';

export const PageHeading = styled(Headline)`
  display: none;
  @media only screen and (min-width: 800px) {
    display: block;
    color: #333333;
    text-align: left;
  }
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  padding: 0 20px;
  @media only screen and (min-width: 800px) {
    padding: 0 50px;
    margin-top: 30px;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
  @media only screen and (min-width: 800px) {
    padding: 0 50px;
    margin: 0 auto;
    padding: 0;
    width: min-content;
  }
  @media only screen and (min-width: 1400px) {
    padding: 0 100px;
  }
`;

export const LeftColumn = styled.div`
  width: 100%;
  @media only screen and (min-width: 800px) {
    width: 500px;
    margin-top: 80px;
    margin-right: 250px;
  }
`;

export const RightColumn = styled.div`
  display: none;
  @media only screen and (min-width: 800px) {
    display: block;
    width: 480px;
    margin-top: 130px;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 36px 0 18px 0;
  @media only screen and (min-width: 800px) {
    margin: 36px 0 30px 0;
  }
`;

export const TabButton = styled.div`
  cursor: pointer;
  font: normal normal normal 12px/14px Roboto;
  letter-spacing: 0px;
  color: ${({ active = false }) => (active ? '#333333' : '#B2BCC1')};
  margin-right: 10px;
  &:hover {
    color: #333333;
  }
  @media only screen and (min-width: 800px) {
    margin-right: 20px;
    font: normal normal normal 16px/26px Roboto;
  }
`;

export const Title = styled.p`
    display: block;
    font: normal normal ${({ weight = 'normal' }) => weight} 16px/19px Roboto;
    color: #333333;
    text-align: left;
    padding: 0;
    margin-bottom: 23px;
`;

export const TextLink = styled(Link)`
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #333333;
  cursor: pointer;
  margin: 23px 0 78px 0;
  &:hover {
    color: #df6e21;
  }
`;

export const SettingsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SettingLabel = styled.p`
  font: normal normal normal 20px/26px Roboto;
  letter-spacing: 0px;
  color: #B2BCC1;
`;

export const Slider = styled.div`
  cursor: pointer;
  background: url(${({ active = false }) => (active ? 'social/slide-active-icon.svg' : 'social/slide-inactive-icon.svg')}) 0% 0% no-repeat padding-box;
`;
