/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Image, Modal, TextField } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { GMMunicipalAccount } from 'types/types';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { Loader } from 'components/inthecity';
import useQuery from 'hooks/use-query';
import UtilityAccountItem from 'components/my-municipality/utility-account-item';
import { capitalize } from 'lodash';
import Form from 'components/form';
import { InputField } from 'components/form/input';
import SubmitButton from 'components/form/submit-button';
import PaymentModal from 'components/payment-modal';
import { useParams } from 'react-router-dom';

type PaymentOption = 'full amount' | 'custom';

const PayAccountPage = () => {
  // Hooks
  const query = useQuery();
  const { cityId } = useParams();

  // API Calls
  const {
    loading: loadingAccount,
    callApi: getAccount,
    response: accountResponse,
  } = useSmartCityManager(SCManagerEndpoints.GetMunicipalAccounts);

  // State management and constants
  const [customerAccount, setCustomerAccount] = useState<GMMunicipalAccount>();
  const [selectedPaymentOption, setSelectedPaymentOption] =
    useState<PaymentOption>('full amount');
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const premiseId = query.get('premiseId');
  const accountNumber = query.get('accountNumber');
  const [paymentMethodsModalOpen, setPaymentMethodsModalOpen] = useState(false);

  // Side effects
  useEffect(() => {
    getAccount({
      queryParams: `premiseId=${premiseId}&accountNumber=${accountNumber}`,
    });
  }, []);

  useEffect(() => {
    if (customerAccount?.total && selectedPaymentOption === 'full amount') {
      setPaymentAmount(customerAccount?.total || 0);
    }
  }, [customerAccount, selectedPaymentOption]);

  useEffect(() => {
    if (accountResponse) {
      setCustomerAccount(accountResponse);
    }
  }, [accountResponse]);

  return (
    <MunicipalPageWrapper>
      {loadingAccount ||
      !(premiseId && accountNumber && customerAccount?.productId) ? (
        <Loader />
      ) : (
        <>
          {paymentMethodsModalOpen && (
            <PaymentModal
              paymentAmount={paymentAmount}
              isOpen={paymentMethodsModalOpen}
              onClose={() => setPaymentMethodsModalOpen(false)}
              peachQueryParams={`accountNumber=${accountNumber}`}
              redirectUrlParams={`premiseId=${premiseId}&accountNumber=${accountNumber}`}
              privateServices={false}
              merchantCode="GRG"
              productId={customerAccount.productId}
              redirectUrl={`/my-municipality/municipal-accounts/${cityId}/payment-status`}
            />
          )}
          <Flex
            maxWidth="650px"
            mx="auto"
            width="100%"
            justifyContent="center"
            flexDirection="column"
          >
            <Text textAlign="center" fontSize="18px" fontWeight={600} mb="12px">
              Your account
            </Text>
            <UtilityAccountItem
              account={{
                premiseId: parseInt(premiseId, 10),
                accountNumber,
                orgUnitId: 0,
                orgUnitDescription: 'Municipal Account',
                active: true,
                meters: [],
                externalId: '0',
                statusId: 2,
                status: 'Verified',
              }}
            />
            <Flex
              alignItems="center"
              p="0px 10px 0px 16px"
              width="100%"
              justifyContent="space-between"
              borderRadius="4px"
              boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
              height="74px"
            >
              <Flex alignItems="center">
                <Image
                  source="my-municipality/info-filled.svg"
                  alt="info"
                  height="34px"
                  mr="11px"
                />
                <Text>Disclaimer</Text>
              </Flex>
              <Button
                color={theme.colors.primary}
                fontWeight={600}
                fontSize="16px"
                mr="14px"
                onClick={() => setShowDisclaimerModal(true)}
              >
                Read
              </Button>
            </Flex>
          </Flex>
          <Modal
            isOpen={showDisclaimerModal}
            onClose={() => setShowDisclaimerModal(false)}
            title="Please note:"
          >
            <Box maxWidth="470px">
              <ol>
                <li>
                  <Text>
                    Payments processed may take up to 10 working days to reflect
                    in your account.
                  </Text>
                </li>
                <br />
                <li>
                  <Text>
                    Your account will be updated between the 25th of the current
                    month and the 4th of the following month.
                  </Text>
                </li>
              </ol>
            </Box>
            <Button
              variant="cta"
              onClick={() => setShowDisclaimerModal(false)}
              width="100%"
              mt="30px"
            >
              Close
            </Button>
          </Modal>

          <Flex
            p="12px 16px 24px 16px"
            width="100%"
            maxWidth="650px"
            mx="auto"
            mt="30px"
            borderRadius="4px"
            boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
            gap="10px"
            flexDirection="column"
          >
            <Text textAlign="left" fontSize="18px" fontWeight={600} mb="12px">
              Payment
            </Text>
            <Text>How much would you like to pay?</Text>
            <Grid gridTemplateColumns="1fr 1fr" gridColumnGap="10px">
              <Button
                fontSize="14px"
                fontWeight={600}
                variant={
                  selectedPaymentOption === 'full amount' ? 'cta' : 'outlined'
                }
                width="100%"
                onClick={() => setSelectedPaymentOption('full amount')}
              >
                Full amount
              </Button>
              <Button
                fontSize="14px"
                fontWeight={600}
                variant={
                  selectedPaymentOption === 'custom' ? 'cta' : 'outlined'
                }
                width="100%"
                onClick={() => setSelectedPaymentOption('custom')}
              >
                Custom
              </Button>
            </Grid>
            <Text fontWeight={600}>{capitalize(selectedPaymentOption)}</Text>
            <Form
              onSubmit={() => {
                setPaymentAmount(paymentAmount);
                setPaymentMethodsModalOpen(true);
              }}
              width="100%"
            >
              <Flex alignItems="center" gap="5px" width="100%">
                <Text fontSize="16px">R</Text>
                <InputField
                  width="100%"
                  step={0.01}
                  min={0}
                  name="paymentAmount"
                  type="number"
                  value={paymentAmount}
                  onChange={(e) => setPaymentAmount(e.target.value)}
                  disabled={selectedPaymentOption === 'full amount'}
                  hideTick
                  defaultValue={customerAccount?.total.toFixed(2) ?? '0'}
                />
              </Flex>
              <SubmitButton label="Confirm payment" />
            </Form>
          </Flex>
        </>
      )}
    </MunicipalPageWrapper>
  );
};

export default PayAccountPage;
