import { convertToReadableDate } from 'functions/date';
import {
  StyledWorkRequestItem,
  WorkRequestContainer,
  WorkRequestContent,
  WorkRequestStatus,
  Button,
  SubmitButton,
  PostedContainer,
  CheckmarkIcon,
  DiscardContainer,
  DiscardButtonRow,
} from './createpost.styled';

export const WorkRequestListItem = ({
  onClick,
  image,
  description,
  address,
  date,
  status,
}) => (
  <StyledWorkRequestItem onClick={onClick}>
    <img src={image} alt="report" />
    <WorkRequestContainer>
      <WorkRequestContent>
        <p>{description}</p>
        <p>{address}</p>
        <p>{convertToReadableDate(date)}</p>
      </WorkRequestContent>
      {status && <WorkRequestStatus>{status}</WorkRequestStatus>}
    </WorkRequestContainer>
  </StyledWorkRequestItem>
);

export const Posted = ({ type, handleClose }) => (
  <PostedContainer>
    <CheckmarkIcon />
    <h2>{`${type} Posted`}</h2>
    <p>
      Keep an eye on your News Feed to see what your community thinks about
      this.
    </p>
    <SubmitButton onClick={handleClose}>Close</SubmitButton>
  </PostedContainer>
);

export const Discard = ({ handleBack, handleClose }) => (
  <DiscardContainer>
    <h2>Discard Post?</h2>
    <p>Your current post progress will be lost.</p>
    <DiscardButtonRow>
      <Button gray blackText onClick={handleBack}>
        No, continue
      </Button>
      <Button onClick={handleClose}>Yes, discard</Button>
    </DiscardButtonRow>
  </DiscardContainer>
);
