/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Modal } from 'components/elements';
import { Box, Flex } from 'components/layout';
import { ResourceSelectorButton } from 'components/private-services/bookings/service-details/widgets/resource-selector';
import SchedulingAssistant from 'components/scheduling-assistant';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { ServiceBookingInformation, SuggestedResource } from 'types/types';
import { Text } from 'components/typography';
import { toUnix } from 'functions/date';
import { Loader } from 'components/inthecity';
import { peachSource } from 'constants/peach';
import { useLocation, useNavigate } from 'react-router-dom';

const hoursToMilliseconds = (hours: number) => hours * 3600 * 1000;

const ReschedulingModal = ({
  isOpen,
  bookingInformation,
  onClose,
}: {
  isOpen: boolean;
  bookingInformation: ServiceBookingInformation;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const {
    callApi: getSuggestedResources,
    response,
    // loading,
  } = useSmartCityManager(SCManagerEndpoints.GetSuggestedResources);

  const {
    loading: rescheduling,
    callApi: rescheduleBooking,
    response: rescheduleResponse,
  } = useSmartCityManager(SCManagerEndpoints.RescheduleBooking);

  const {
    // loading: gettingCost,
    callApi: getCost,
    response: estimatedCost,
  } = useSmartCityManager(SCManagerEndpoints.GetEstimatedReschedulingCost);

  const costResponse =
    estimatedCost && estimatedCost > 0
      ? estimatedCost.toFixed(2)
      : parseInt('0', 10).toFixed(2);

  const {
    loading: peachInitializing,
    response: purchaseId,
    callApi: doInitializePeach,
  } = useSmartCityManager(SCManagerEndpoints.DoInitializePeach);

  const {
    loading: confirmingCostFree,
    callApi: confirmCostFree,
    response: confirmCostFreeResponse,
  } = useSmartCityManager(SCManagerEndpoints.ConfirmCostFreeReschedule);

  const location = useLocation();
  const [state, setState] = useState<'schedule' | 'resource' | 'payment'>(
    'schedule'
  );
  const [suggestedResources, setSuggestedResources] = useState<
    SuggestedResource[] | null
  >(null);
  const [rescheduleAppointment, setRescheduleAppointment] =
    useState<Date | null>(null);
  const [newResource, setNewResource] = useState<number | null>(
    bookingInformation.resourceId || null
  );

  console.log({
    rescheduleAppointment,
    newResource,
    info: bookingInformation,
  });

  useEffect(() => {
    if (rescheduleAppointment) {
      getCost({
        queryParams: `bookingId=${
          bookingInformation.workOrderId
        }&bookingDate=${toUnix(rescheduleAppointment)}`,
      });
    }
  }, [rescheduleAppointment]);

  useEffect(() => {
    if (
      bookingInformation?.latitude &&
      bookingInformation?.longitude &&
      rescheduleAppointment &&
      typeof bookingInformation.estimatedDuration === 'number'
    ) {
      getSuggestedResources({
        queryParams:
          `appointmentStartDate=${toUnix(rescheduleAppointment)}` +
          `&appointmentEndDate=${
            (rescheduleAppointment.getTime() +
              hoursToMilliseconds(bookingInformation?.estimatedDuration)) /
            1000
          }` +
          `&serviceTypeId=${bookingInformation.typeId}` +
          `&latitude=${bookingInformation.latitude}` +
          `&longitude=${bookingInformation.longitude}`,
      });
    }
  }, [rescheduleAppointment]);

  useEffect(() => {
    if (response) {
      setSuggestedResources(response);
    }
  }, [response]);

  useEffect(() => {
    if (bookingInformation && bookingInformation.startDateTime) {
      setRescheduleAppointment(
        new Date(bookingInformation.startDateTime * 1000)
      );
    }
  }, [bookingInformation]);

  const handleReschedule = () => {
    if (
      rescheduleAppointment &&
      newResource &&
      typeof bookingInformation?.estimatedDuration === 'number'
    ) {
      rescheduleBooking({
        queryParams: `bookingId=${
          bookingInformation.workOrderId
        }&appointmentStartDate=${toUnix(
          rescheduleAppointment
        )}&appointmentEndDate=${
          (rescheduleAppointment.getTime() +
            hoursToMilliseconds(bookingInformation?.estimatedDuration)) /
          1000
        }&resourceId=${newResource}`,
      });
      setNewResource(null);
      setRescheduleAppointment(null);
    }
  };

  useEffect(() => {
    if (rescheduleResponse && costResponse && costResponse !== '0.00') {
      setState('payment');
      doInitializePeach({
        queryParams: `price=${costResponse}&productId=${rescheduleResponse}&privateServices=true`,
      });
    } else if (rescheduleResponse) {
      confirmCostFree({
        queryParams: `productId=${rescheduleResponse}`,
      });
    }
  }, [rescheduleResponse]);

  useEffect(() => {
    if (confirmCostFreeResponse) {
      onClose();
      navigate('/my-bookings');
    }
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        slideFromBottomMobile
        title="Schedule service"
        onBack={
          state === 'resource'
            ? () => setState('schedule')
            : state === 'payment'
            ? () => setState('resource')
            : undefined
        }
      >
        {rescheduling || peachInitializing || confirmingCostFree ? (
          <Loader />
        ) : (
          <Flex flexDirection="column" pb="50px" position="relative" flex="1">
            <Box
              overrideCss={css`
                @media ${theme.mediaQueries.mobileOnly} {
                  position: fixed;
                  padding: 20px;
                  bottom: 0;
                  left: 0;
                  z-index: 1;
                  width: 100vw;
                  background-color: white;
                  border-top: ${theme.borders.standard1px};
                  border-radius: 4px 4px 0 0;
                  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1);
                }
                @media ${theme.mediaQueries.tabletUp} {
                  padding: 10px 0px;
                  border-bottom: ${theme.borders.standard1px};
                  margin-bottom: 10px;
                }
              `}
            >
              <Text fontSize="16px">
                Estimated cost: {costResponse ? `R ${costResponse}` : 'R 0.00'}
              </Text>
            </Box>
            {state === 'schedule' ? (
              <SchedulingAssistant
                setAppointmentStart={(d: Date) => setRescheduleAppointment(d)}
                duration={bookingInformation?.estimatedDuration || 8}
                reschedule
                includeLunch={bookingInformation?.typeId === 27147}
                currentDate={rescheduleAppointment || undefined}
                confirmationCallback={() => setState('resource')}
              />
            ) : state === 'resource' ? (
              <Flex
                flexDirection="column"
                overrideCss={css`
                  @media ${theme.mediaQueries.mobileOnly} {
                    padding: 10px 10px 20px 10px;
                  }
                `}
              >
                <Text fontSize="20px" mt="10px !important" mb="10px">
                  Choose a service provider
                </Text>
                {suggestedResources && suggestedResources.length > 0 ? (
                  <Flex
                    overflowX="scroll"
                    mx="-10px"
                    overrideCss={css`
                      ::-webkit-scrollbar {
                        display: none;
                      }
                      @media ${theme.mediaQueries.tabletUp} {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-row-gap: 10px;
                        margin: 0;
                        overflow: unset;
                      }
                      @media ${theme.mediaQueries.desktopOnly} {
                        grid-template-columns: 1fr 1fr;
                      }
                    `}
                  >
                    <Box minWidth="15px" hideForDesktop hideForTablet />
                    {suggestedResources?.map(
                      (resource: SuggestedResource, idx: number) => (
                        <ResourceSelectorButton
                          resource={resource}
                          key={idx}
                          isSelected={newResource === resource.id}
                          onClick={() => setNewResource(resource.id)}
                        />
                      )
                    )}
                  </Flex>
                ) : (
                  <Text>No service providers available for this address</Text>
                )}
              </Flex>
            ) : (
              <iframe
                srcDoc={peachSource(purchaseId, location.pathname, false)}
                width="100%"
                height="400px"
                title="payment"
                css={css`
                  border: none;
                `}
              />
            )}
            {suggestedResources?.map((s) => s.id).includes(newResource || -1) &&
              state === 'resource' && (
                <Button
                  backgroundColor={theme.colors.primary}
                  color="white"
                  borderRadius={theme.borderRadius.small}
                  width="100%"
                  p="15px"
                  mt="20px"
                  onClick={handleReschedule}
                >
                  Confirm and reschedule
                </Button>
              )}
          </Flex>
        )}
      </Modal>
    </>
  );
};

export default ReschedulingModal;
