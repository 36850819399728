import { MunicipalPageWrapper } from 'components/my-municipality';
import { Button, Image, Modal, TextField } from 'components/elements';
import { Box, Flex } from 'components/layout';
import { useCallback, useEffect, useState } from 'react';
import {
  CustomerPremises,
  Meter,
  MeterType,
  UtilityAccount,
} from 'types/types';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { Loader } from 'components/inthecity';
import UtilityAccountItem from 'components/my-municipality/utility-account-item';
import { Link, useParams } from 'react-router-dom';
import useQuery from 'hooks/use-query';
import { ModalProps } from 'components/elements/modal';
import { hexToRGBA } from 'functions/graph';

const AddMeterModal = ({
  meterTypes,
  addMeterCallback,
  ...modalProps
}: {
  meterTypes: MeterType[];
  addMeterCallback: (
    newMeterNumber: string,
    selectedMeterType: MeterType
  ) => void;
} & ModalProps) => {
  const [selectedMeterType, setSelectedMeterType] = useState<MeterType>();
  const [newMeterNumber, setNewMeterNumber] = useState<string>();
  return (
    <Modal {...modalProps}>
      <Box>
        <Text mb="16px">Select your meter type:</Text>
        <Flex gap="10px">
          {meterTypes.map((meter: MeterType) => (
            <Button
              backgroundColor="white"
              boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
              p="20px"
              width="100%"
              onClick={() => setSelectedMeterType(meter)}
              borderRadius="4px"
              border={
                selectedMeterType?.value === meter.value
                  ? '2px solid #2096BA'
                  : 'none'
              }
            >
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="center"
                gap="12px"
              >
                <Image
                  source={`https://mysmart.city/api/msc/images/custom/${meter.attributes.icon}?subscriberId=george`}
                  alt={meter.label}
                />
                <Text>{meter.label}</Text>
              </Flex>
            </Button>
          ))}
        </Flex>
        <Text mt="24px">Your meter number:</Text>
        <TextField
          width="100%"
          value={newMeterNumber}
          onChange={(e) => setNewMeterNumber(e.target.value)}
        />
        <Button
          width="100%"
          mt="24px"
          variant="cta"
          disabled={
            !selectedMeterType?.value ||
            !newMeterNumber ||
            newMeterNumber === ''
          }
          onClick={() => {
            if (newMeterNumber && selectedMeterType)
              addMeterCallback(newMeterNumber, selectedMeterType);
          }}
        >
          Add Meter
        </Button>
      </Box>
    </Modal>
  );
};

const MetersPage = () => {
  // Hooks
  const { cityId } = useParams();
  const query = useQuery();

  // API Calls
  const {
    loading: loadingPremises,
    callApi: getPremises,
    response: customerPremises,
  } = useSmartCityManager(SCManagerEndpoints.GetCustomerPremises);
  const {
    loading: loadingMeterTypes,
    callApi: getMeterTypes,
    response: meterTypes,
  } = useSmartCityManager(SCManagerEndpoints.GetMeterTypes);
  const {
    loading: addingMeter,
    callApi: addMeter,
    response: addedMeter,
  } = useSmartCityManager(SCManagerEndpoints.AddMeter);
  const {
    loading: removingMeter,
    callApi: removeMeter,
    response: removedMeter,
  } = useSmartCityManager(SCManagerEndpoints.RemoveMeter);

  // State management and constants
  const accountNumber = query.get('accountNumber');
  const premiseId = query.get('premiseId');
  const premise: CustomerPremises =
    premiseId &&
    customerPremises?.data?.find(
      (items: CustomerPremises) => items.id === parseInt(premiseId, 10)
    );
  const municipalAccount = premise?.serviceProviderAccounts?.find(
    (items: UtilityAccount) => items.accountNumber === accountNumber
  );
  const [showAddMeterModal, setShowAddMeterModal] = useState(false);

  // Side effects
  useEffect(() => {
    getPremises();
    getMeterTypes();
  }, [addedMeter, removedMeter]);

  // Handlers
  const handleAddMeter = useCallback(
    (newMeterNumber: string, selectedMeterType: MeterType) => {
      if (selectedMeterType && newMeterNumber) {
        addMeter({
          body: {
            premiseId,
            orgUnitId: municipalAccount?.orgUnitId,
            meterNumber: newMeterNumber,
            meterTypeId: selectedMeterType.value,
            description: selectedMeterType.label,
          },
        });
        setShowAddMeterModal(false);
      }
    },
    [premiseId, municipalAccount?.orgUnitId]
  );

  return (
    <MunicipalPageWrapper
      backLink={`/my-municipality/municipal-accounts/${cityId}?query=self-meter-readings`}
    >
      {municipalAccount && meterTypes && (
        <AddMeterModal
          isOpen={showAddMeterModal}
          onClose={() => setShowAddMeterModal(false)}
          title="Add meter"
          slideFromBottomMobile
          meterTypes={meterTypes}
          addMeterCallback={(
            newMeterNumber: string,
            selectedMeterType: MeterType
          ) => handleAddMeter(newMeterNumber, selectedMeterType)}
        />
      )}
      {loadingPremises || addingMeter || loadingMeterTypes || removingMeter ? (
        <Loader />
      ) : (
        <Flex
          maxWidth="720px"
          mx="auto"
          width="100%"
          p="24px 28px 54px 28px"
          flexDirection="column"
          borderRadius="4px"
          boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
          gap="24px"
        >
          {premiseId && accountNumber && (
            <UtilityAccountItem
              account={{
                premiseId: parseInt(premiseId, 10),
                accountNumber,
                orgUnitId: 0,
                orgUnitDescription: 'Municipal Account',
                active: true,
                meters: [],
                externalId: '0',
                statusId: 2,
                status: 'Verified',
              }}
            />
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="2"
            viewBox="0 0 658 2"
            fill="none"
          >
            <path
              d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H658V0H1V2Z"
              fill="#F0F0F0"
            />
          </svg>
          <Text>Meters</Text>
          {municipalAccount?.meters?.map((meter: Meter) => (
            <Flex justifyContent="space-between" width="100%" p="16px">
              <Link
                to={`/my-municipality/self-meter-readings/${cityId}/add-reading?meterNumber=${meter.meterNumber}&accountNumber=${municipalAccount.accountNumber}&premiseId=${premiseId}`}
              >
                <Flex
                  alignItems="center"
                  gap="12px"
                  justifyContent="flex-start"
                  width="100%"
                  color="black"
                >
                  <Image
                    source={`https://mysmart.city/api/msc/images/custom/${meter.icon}?subscriberId=george`}
                    alt={meter.description}
                    p="10px"
                    backgroundColor={hexToRGBA(meter.colour, 0.1) ?? 'white'}
                    borderRadius="50%"
                  />
                  <Text fontSize="16px">{meter.meterNumber}</Text>
                </Flex>
              </Link>
              <Button
                onClick={() =>
                  removeMeter({
                    queryParams: `meterId=${meter.meterId}`,
                  })
                }
              >
                <Image
                  source="municipal-accounts/delete.svg"
                  alt="delete"
                  height="21px"
                />
              </Button>
            </Flex>
          ))}
          <Button onClick={() => setShowAddMeterModal(true)} variant="cta">
            Add meter number
          </Button>
        </Flex>
      )}
    </MunicipalPageWrapper>
  );
};

export default MetersPage;
