import styled from 'styled-components';

export const TermsLinks = styled.div`
  color: #333;
  font: normal normal normal 14px/19px Roboto;

  li, a {
    color: #333;
    padding-bottom: 4rem;
    &:hover, &.selected {
      color: #e17531;
    }
    &.selected {
      border-left: 2px solid lightgray;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  @media only screen and (max-width: 650px) {
    font: normal normal normal 14px/19px Roboto;
    li, a {padding-bottom: 2rem;}
  }
`;

export const Tcheader = styled.div`
  margin-bottom: 2rem;
  padding-left: 20px;

  &.text-bold {
    font-weight: bold;
  }

  @media only screen and (min-width: 650px) {
    padding-left: 0;
  }
`;

export const TermsPopupBox = styled.div`
  padding: 2rem 0rem;
  z-index: 2;
  position: fixed;
  background-color: white;
  width: 70%;
  height: 60vh;
  top: 5.5rem;
  right: 0.5rem;
  transition: all 0.5s;
  border-radius: 0.75rem 0 0 0.75rem;
  -webkit-box-shadow: 1px 1px 8px 0 #00000050;
  box-shadow: 1px 1px 8px 0 #00000050;
`;

export const TermsPopupContent = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 2rem;
  scrollbar-width: thin;
`;

export const TermsPopupButton = styled.div`
  z-index: 3;
  position: fixed;
  top: 6.5rem;
  right: 1.5rem;
  margin-left: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 2rem;
  transition: transform 0.3s;
  &.open {
    -webkit-transform:rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  @media only screen and (min-width: 650px) {
    display: none;
  }
`;

export const TermsSideMenu = styled.div`
position: -webkit-sticky;
  position: sticky;

  top: 0;
  
  
  
  padding: 4rem 3rem;
  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

export const TermsSideMenuContent = styled.div`

  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const TermsContainer = styled.div`
  color: #333;
  padding: 0 4rem;
  @media only screen and (max-width: 650px) {
    padding: 0 1rem;
  }

  .terms-bold {
    font-family: Roboto;
    display: contents;
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    margin-top: 1rem;
    padding: 0;
  
    li {
      counter-increment: item;
      &:before {
        content: counters(item, ".") ". ";
        padding-right: 0.5rem;    
      }
      
      li {
        display: table;
        font-family: Roboto;
        &:before {
          display: table-cell;
          content: counters(item, ".") ". ";
        }
        li:before {
          content: counter(item, lower-alpha) ") ";
        }
        li li:before {
          content: counter(item, lower-roman) ". ";
        }
      }
    }
  }

  > ol > li {
    padding-top: 3rem;
    font: normal normal 500 25px/33px Roboto;
    @media only screen and (max-width: 650px) {
      padding-top: 1rem;

    }
  }

  > ol > li > ol li  {
    margin-bottom: 3rem;

    font: normal normal normal 14px/19px Roboto;
    @media only screen and (max-width: 650px) {
      margin-bottom: 1rem;
    }
  }
`;

export const TermsTitle = styled.div`
  margin-bottom: 5rem;
  font: normal normal normal 35px/46px Roboto;

  &.bold {
    font: normal normal 500 35px/46px Roboto;
    margin-bottom: 8rem;
  }
  @media only screen and (max-width: 650px) {
    &.bold {margin-bottom: 1.5rem;}
    margin-bottom: 0.5rem;
  }
`;

export const TermsTitleAlt = styled.div`
  font: normal normal normal 35px/46px Roboto;
  margin-bottom: 2rem;
  &.bold {
    font: normal normal 500 35px/46px Roboto;
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 650px) {
    &.bold {margin-bottom: 1.5rem;}
    margin-bottom: 0.5rem;
  }
`;

export const TermsParagraph = styled.div`
  margin-bottom: 3rem;
  font: normal normal normal 14px/19px Roboto;
  @media only screen and (max-width: 650px) {
    margin-bottom: 1rem;
  }

  .first {
    margin-top: 2rem;
  }
`;
