import { apiUrl } from 'constants/app';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const getNewsFeedWorkRequest = async (id) => fetch(
  `${apiUrl}smartcitymanager/getNewsFeedWorkRequest?`
  + `workRequestId=${id}`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => {
    console.log(e);
    return [];
  });
