/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Loader } from 'components/inthecity';
import { Box, Flex, Grid } from 'components/layout';
import { Button } from 'components/elements';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { GMIDP } from 'types/types';
import {
  formatForcelinkAddress,
  shortenWithEllipses,
} from 'functions/address-utils';
import { useParams } from 'react-router-dom';

const ContributionItem = ({ idp }: { idp: GMIDP }) => {
  const [readMore, setReadMore] = useState(false);
  return (
    <Box p="16px" borderRadius="4px" boxShadow={theme.cardShadow} width="100%">
      <Grid gridRowGap="5px">
        <Flex width="100%" justifyContent="space-between">
          <Text fontSize="18px" fontWeight={600} color={theme.colors.primary}>
            {idp.idpRefNo}
          </Text>
          <Text fontWeight={600} fontSize="16px" color={theme.colors.blue}>
            {idp.topic}
          </Text>
        </Flex>
        <Text fontWeight={600} fontSize="16px">
          {formatForcelinkAddress(idp.locationString)}
        </Text>
        <Text fontWeight={600} fontSize="16px" color="#C0C0C0">
          Ward {idp.wardNo}
        </Text>
        <Text>
          {readMore ? idp.comment : shortenWithEllipses(idp.comment, 40)}
          {idp.comment.length > 40 && (
            <Button
              onClick={() => setReadMore(!readMore)}
              color={theme.colors.primary}
              ml="10px"
            >
              {readMore ? '  Read less' : 'Read more'}
            </Button>
          )}
        </Text>
      </Grid>
    </Box>
  );
};

const MyIDPContributionsPage = () => {
  const {
    loading: gettingSurveys,
    response: availableSurveys,
    callApi: getSurveys,
  } = useSmartCityManager(SCManagerEndpoints.GetMyIDPS);
  const { cityId } = useParams<{ cityId: string }>();

  useEffect(() => {
    getSurveys();
  }, []);

  return (
    <MunicipalPageWrapper
      title="My IDP contributions"
      backLink={`/my-municipality/idp-contribution/${cityId}`}
    >
      {gettingSurveys ? (
        <Loader />
      ) : (
        <>
          <Grid
            gridTemplateColumns="repeat(4, 1fr)"
            gridRowGap="10px"
            gridColumnGap="10px"
            mx="auto"
            mobileCss={css`
              grid-template-columns: 1fr;
              margin: 0;
            `}
          >
            {availableSurveys?.map((idp: GMIDP) => (
              <ContributionItem idp={idp} key={idp.idpRefNo} />
            ))}
          </Grid>
        </>
      )}
    </MunicipalPageWrapper>
  );
};

export default MyIDPContributionsPage;
