/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';

import { ActionBar } from 'components';
import CreatePostMenu from 'components/social/createpostmenu';
import {
  CreatePost,
  Breadcrumbs,
  Search,
} from 'components/social';
import {
  SuggestionList,
  FollowersList,
  FollowedList,
} from 'components/social/connection';
import { PageContainer } from './social.styled';
import {
  PageHeading,
  TopRow,
  PageContent,
  LeftColumn,
  RightColumn,
  TabContainer,
  TabButton,
  Title,
} from './connections.styled'; // , SettingsRow, Slider

const ConnectionsPage = () => {
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [createPostType, setCreatePostType] = useState(null);
  const [tab, setTab] = useState(0);

  const [followerCount, setFollowerCount] = useState(null);
  const [followingCount, setFollowingCount] = useState(null);

  const handleCreatePost = (type) => {
    if (type !== null) {
      setShowCreatePost(true);
      setCreatePostType(type);
    }
  };

  const updateFollowerCount = (c) => {
    setFollowerCount(c);
  };

  const updateFollowingCount = (c) => {
    setFollowingCount(c);
  };

  return (
    <>
      <CreatePost
        show={showCreatePost}
        type={createPostType}
        handleClose={() => setShowCreatePost(false)}
      />
      <ActionBar>
        <CreatePostMenu handleCreatePost={handleCreatePost} />
        <Search />
      </ActionBar>

      <PageContainer>
        <TopRow>
          <Breadcrumbs />
        </TopRow>

        <PageContent>
          <LeftColumn>
            <PageHeading>
              Connections
            </PageHeading>
            <TabContainer>
              <TabButton active={tab === 0} onClick={() => setTab(0)}>
                {followerCount && `${followerCount} `}
                {' '}
                Followers
              </TabButton>
              <TabButton active={tab === 1} onClick={() => setTab(1)}>
                {followingCount && `${followingCount} `}
                {' '}
                Following
              </TabButton>
            </TabContainer>
            {tab === 0
              ? <FollowersList updateCount={updateFollowerCount} />
              : <FollowedList updateCount={updateFollowingCount} />}
          </LeftColumn>

          <RightColumn>
            <Title>
              Suggested Follows
            </Title>
            <SuggestionList />
            {/* <TextLink to="/social/connections">See more suggestions</TextLink> */}
            {/* <Title style={{ marginBottom: '20px' }}>
              Privacy Settings
            </Title> */}
            {/* <SettingsRow>
              <Slider active={} />
              </Slider>
            </SettingsRow> */}
          </RightColumn>
        </PageContent>
      </PageContainer>
    </>
  );
};

export default ConnectionsPage;
