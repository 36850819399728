import React, { useEffect, useState } from 'react';
import { Box, Flex, Grid } from 'components/layout';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
import theme from 'theme';
import { css } from '@emotion/react';
import { Loader } from 'components/inthecity';
import { Text } from 'components/typography';
import useGoogleAddressFinder from 'hooks/use-google-address-finder';
import { Button, Image, TextField } from 'components/elements';
import { SmallLoader } from 'components/private-services/survey';
import { GoogleSuggestedAddress, LatLng } from 'types/types';
import { useGlobalContext } from 'contexts/global';

const MSCMarker = new L.Icon({
  iconUrl: 'icons/sleek_pin.png',
  iconRetinaUrl: 'icons/sleek_pin.png',
  iconAnchor: new L.Point(10, 30),
});

const LocationMarker = ({
  position,
  setPosition,
}: {
  position: LatLng;
  setPosition: React.Dispatch<React.SetStateAction<LatLng | null>>;
}) => {
  useMapEvents({
    click(e) {
      setPosition(e.latlng);
    },
  });
  return position === null ? null : (
    <Marker position={position} icon={MSCMarker} />
  );
};

const InteractiveAddressSelector = ({
  setAddressCallback,
}: {
  setAddressCallback: (address: GoogleSuggestedAddress) => void;
}) => {
  const [selectedPosition, setSelectedPosition] = useState<LatLng | null>(null);
  const { loading, findAddress } = useGoogleAddressFinder();
  const [addressInput, setAddressInput] = useState('');
  const [addressDisplay, setAddressDisplay] = useState('');
  const [suggestedAddresses, setSuggestedAddresses] = useState<
    GoogleSuggestedAddress[] | null
  >(null);
  const [map, setMap] = useState<any>(null);
  const [confirmedAddress, setConfirmedAddress] =
    useState<GoogleSuggestedAddress | null>(null);
  const {
    globalState: { city },
  } = useGlobalContext();
  const [init, setInit] = useState(true);
  const [debouncing, setDebouncing] = useState(false);

  useEffect(() => {
    if (city.label !== 'init') {
      setSelectedPosition({
        lat: parseFloat(city.attributes.latitude),
        lng: parseFloat(city.attributes.longitude),
      });
      setInit(false);
    }
  }, [city.attributes.latitude, city.attributes.longitude]);

  useEffect(() => {
    setDebouncing(true);
    const timeoutId = setTimeout(() => {
      setDebouncing(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [addressInput]);

  useEffect(() => {
    const query = `${selectedPosition?.lat},${selectedPosition?.lng}`;
    if (selectedPosition?.lat !== 0 && selectedPosition?.lng !== 0 && !init) {
      findAddress(query, setSuggestedAddresses);
    }
  }, [selectedPosition]);

  useEffect(() => {
    setAddressDisplay(addressInput);
    if (addressInput.replaceAll(' ', '').length > 6 && !debouncing) {
      const query = addressInput;
      findAddress(query, setSuggestedAddresses);
    }
  }, [addressInput, debouncing]);

  const handleAddressSelectionClick = (s: GoogleSuggestedAddress) => {
    setAddressDisplay(s.formatted_address);
    setConfirmedAddress(s);
    if (map) {
      map.flyTo(s.geometry.location, map.getZoom());
      setSelectedPosition(s.geometry.location);
    }
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      {selectedPosition ? (
        <Grid
          gridTemplateColumns="1fr 1fr"
          gridColumnGap="30px"
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              grid-template-columns: 1fr;
              width: 100%;
              min-width: 320px;
            }
          `}
        >
          <Grid
            gridTemplateRows="1fr 4fr 1fr"
            overrideCss={css`
              @media ${theme.mediaQueries.mobileOnly} {
                grid-template-rows: 1fr;
                position: relative;
              }
            `}
          >
            <TextField
              mb="10px"
              onChange={(e) => setAddressInput(e.target.value)}
              value={addressDisplay}
              placeholder="Search for an address or select from the map"
              overrideCss={css`
                @media ${theme.mediaQueries.mobileOnly} {
                  margin-bottom: 100px;
                }
              `}
            />
            <Box
              maxWidth="400px"
              width="100%"
              backgroundColor="white"
              zIndex={1200}
              overrideCss={css`
                @media ${theme.mediaQueries.mobileOnly} {
                  position: absolute;
                  top: 50px;
                }
              `}
            >
              {suggestedAddresses && !loading && !debouncing ? (
                suggestedAddresses
                  .slice(0, 5)
                  .map((s: GoogleSuggestedAddress, idx: number) => (
                    <Button
                      key={idx}
                      p="5px"
                      onClick={() => handleAddressSelectionClick(s)}
                      border={`1px solid ${theme.colors.borderGrey}`}
                      borderRadius={theme.borderRadius.medium}
                      mb="5px"
                      width="100%"
                    >
                      <Flex alignItems="center">
                        <Image
                          source="map/pin.svg"
                          alt="icon"
                          width="20px"
                          mr="20px"
                        />
                        <Text
                          backgroundColor="white"
                          m={0}
                          textAlign="left"
                          maxWidth="100%"
                        >
                          {s.formatted_address.length > 55
                            ? `${s.formatted_address.slice(0, 55)}...`
                            : s.formatted_address}
                        </Text>
                      </Flex>
                    </Button>
                  ))
              ) : (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="white"
                >
                  <SmallLoader />
                  <Text>Finding addresses...</Text>
                </Flex>
              )}
            </Box>
            <Flex alignItems="flex-end" width="100%" hideForMobile>
              <Button
                width="100%"
                p="10px"
                mt="20px"
                backgroundColor={
                  confirmedAddress
                    ? theme.colors.primary
                    : theme.colors.backgroundGrey
                }
                height="fit-content"
                borderRadius={theme.borderRadius.small}
                color="white"
                fontWeight="bold"
                disabled={!confirmedAddress}
                onClick={() => {
                  confirmedAddress && setAddressCallback(confirmedAddress);
                }}
              >
                Confirm address
              </Button>
            </Flex>
          </Grid>
          <Flex
            width="400px"
            height="400px"
            overrideCss={css`
              @media ${theme.mediaQueries.mobileOnly} {
                width: 100%;
                height: 300px;
                padding: 3px;
              }
            `}
          >
            <MapContainer
              center={[selectedPosition.lat, selectedPosition.lng]}
              zoom={13}
              scrollWheelZoom={false}
              style={{
                height: '100%',
                width: '100%',
                borderRadius: '5px',
              }}
              whenCreated={setMap}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker
                position={selectedPosition}
                setPosition={setSelectedPosition}
              />
            </MapContainer>
          </Flex>
          <Flex alignItems="flex-end" width="100%" hideForDesktop hideForTablet>
            <Button
              width="100%"
              p="10px"
              mt="20px"
              backgroundColor={
                confirmedAddress
                  ? theme.colors.primary
                  : theme.colors.backgroundGrey
              }
              height="fit-content"
              borderRadius={theme.borderRadius.small}
              color="white"
              fontWeight="bold"
              disabled={!confirmedAddress}
              onClick={() => {
                confirmedAddress && setAddressCallback(confirmedAddress);
              }}
            >
              Confirm address
            </Button>
          </Flex>
        </Grid>
      ) : (
        <Box>
          <Loader />
          <Text textAlign="center" color={theme.colors.primary}>
            Determining your location...
          </Text>
        </Box>
      )}
    </Flex>
  );
};

export default InteractiveAddressSelector;
