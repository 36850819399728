import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import theme from 'theme';

import SEO from 'components/seo';
import { getSmartCityInsightsForCity } from 'interface/inthecity/inthecity';
import PrivateServicesModal from 'components/private-services/private-services-modal';
import { Flex } from 'components/layout';
import { Button } from 'components/elements';
import { Cookies } from 'react-cookie';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { CustomerBooking } from 'types/types';
import RateResourceModal from 'components/rate-resource-modal';
import HeroHomePage from 'components/home/new/hero';
import MediaCarousel from 'components/private-services/campaign/carousel/mediaCarousel';
import BookAServiceSection from 'components/home/new/book-service-section';
import LogAReportSection from 'components/home/new/log-a-report-section';
import ReviewSection from 'components/home/new/review-section';
import MoreFeaturesSection from 'components/home/new/more-features-section';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from 'components/footer';
import { PrivateServicesEvents, logFirebaseEvent } from 'functions/firebase';
import useDidMountEffect from 'hooks/use-did-mount-effect';

const cookies = new Cookies();

const scrollToRef = (ref: MutableRefObject<any>) =>
  ref?.current && ref.current.scrollIntoView();

const HomePage = (props: any) => {
  const isLoggedIn = !!cookies.get('firebaseToken');
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [insights, setInsights] = useState({});
  const [psModalOpen, setPsModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [bookingToRate, setBookingToRate] = useState<CustomerBooking | null>(
    null
  );
  const location = useLocation();

  const bookServiceRef = useRef(null);

  const { response: bookings, callApi } = useSmartCityManager(
    SCManagerEndpoints.GetMyBookings
  );

  useEffect(() => {
    async function fetchData() {
      const insightsResponse = await getSmartCityInsightsForCity(
        2,
        '431',
        'cape_town'
      );
      setInsights(insightsResponse);
    }
    fetchData();
  }, []);

  useEffect(() => {
    isLoggedIn && callApi();
  }, [isLoggedIn]);

  useEffect(() => {
    if (bookings && bookings.completedBookings) {
      const temp = bookings.completedBookings.find(
        (b: CustomerBooking) => b.ratingStatus === 'To Rate'
      );
      if (temp) {
        setBookingToRate(temp);
        setRatingModalOpen(true);
      }
    }
  }, [bookings]);

  useDidMountEffect(() => {
    if (location.hash.includes('book-a-service')) {
      scrollToRef(bookServiceRef);
    }
  }, []);

  console.log('Home props', props);

  return (
    <>
      {isLoggedIn && (
        <PrivateServicesModal
          psModalOpen={psModalOpen}
          closeModal={() => setPsModalOpen(false)}
        />
      )}
      {isLoggedIn && bookingToRate && (
        <RateResourceModal
          booking={bookingToRate}
          isOpen={ratingModalOpen}
          onClose={() => setRatingModalOpen(false)}
        />
      )}
      <SEO
        title="My Smart City | Engage Service Providers In Your City"
        description="My Smart City connects you to the service providers in your city. Municipal services, emergency contacts, private service providers & city events, news & more."
      />
      <Flex flexDirection="column">
        {isLoggedIn && (
          <Flex
            position="fixed"
            justifyContent="center"
            p="10px"
            py="20px"
            backgroundColor="white"
            borderT={theme.borders.standard1px}
            hideForDesktop
            hideForTablet
            zIndex={10000}
            bottom="0"
            width="100vw"
            left={0}
            overrideCss={css`
              box-shadow: ${theme.elevationShadow};
            `}
          >
            <Button
              onClick={() => navigate('/map')}
              borderRadius={theme.borderRadius.small}
              backgroundColor="black"
              color="white"
              p="12px"
              mr="10px"
              width="100%"
              fontWeight="bold"
            >
              Log a Report
            </Button>
            <Button
              onClick={() => {
                setPsModalOpen(true);
                logFirebaseEvent(PrivateServicesEvents.BookAService);
              }}
              fontWeight="bold"
              width="100%"
              borderRadius={theme.borderRadius.small}
              backgroundColor="black"
              color="white"
              p="12px"
            >
              Book a Service
            </Button>
          </Flex>
        )}
        <HeroHomePage
          showPsModal={() => setPsModalOpen(true)}
          isLoggedIn={isLoggedIn}
          scrollToPrivateServices={() => scrollToRef(bookServiceRef)}
        />
        <MediaCarousel />
        <BookAServiceSection
          privateServicesRef={bookServiceRef}
          isLoggedIn={isLoggedIn}
          showPsModal={() => setPsModalOpen(true)}
        />
        <LogAReportSection isLoggedIn={isLoggedIn} />
        <ReviewSection />
        <MoreFeaturesSection />
        <Footer />
      </Flex>
    </>
  );
};

export default HomePage;
