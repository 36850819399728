import styled from 'styled-components';

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #F6F6F9;
  height: calc(100vh - 60px);

  @media only screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 55%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  margin-left: 70px;
  width: 50%;

  h2 {
    font: normal normal bold 25px/33px Roboto;
    color: #000000;
    margin-bottom: 15px;
  }

  p {
    font: normal normal normal 14px/19px Roboto;
    color: #000000;
    margin-bottom: 15px;
  }

  @media only screen and (min-width: 800px) {
    margin-left: 140px;
  }
  
  @media only screen and (min-width: 1200px) {
    h2 {
      font: normal normal bold 50px/66px Roboto;
      margin-bottom: 30px;
    }
    
    p {
      font: normal normal normal 18px/24px Roboto;
      margin-bottom: 30px;
    }
  }
`;

export const ViewButton = styled.div`
  border: 1px solid #DF7531;
  border-radius: 5px;
  font: normal normal normal 14px/30px Roboto;
  color: #DF7531;
  padding: 0 25px;
  width: fit-content;
  cursor: pointer;
  transition: all 0.15s ease-out;
  
  &:hover {
    background: #DF7531;
    color: #FFFFFF;
  }

  @media only screen and (min-width: 1200px) {
    border-radius: 10px;
    font: normal normal normal 22px/62px Roboto;
    padding: 0 52px;
  }
`;

export const AnimationContainer = styled.div`
  flex-basis: 45%;
  background: url(marketing/competition/holiday.jpg) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1300px) {
    ${(props) => (props.left ? `
      margin-right: 70px;
    ` : `
      margin-left: 70px;
    `)}
  }
`;

export const ItemImage = styled.img`
  height: 355px;
  margin-bottom: 25px;
  object-fit: cover;
`;

export const NameRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  p {
    font: normal normal 500 16px/19px Roboto;
    color: #DF7531;
    margin: 0;
    padding: 0;
  }
  
  @media only screen and (min-width: 1200px) {
    p {
      font: normal normal 500 20px/26px Roboto;
    }
  }
`;

export const ItemTitle = styled.h2`
  font: normal normal 500 20px/26px Roboto;
  color: #000000;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  
  @media only screen and (min-width: 1200px) {
    font: normal normal 500 42px/55px Roboto;
  }
`;

export const ItemBody = styled.p`
  font: normal normal normal 14px/19px Roboto;
  color: #00000080;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

  @media only screen and (min-width: 1200px) {
    font: normal normal normal 20px/26px Roboto;
  }
`;

export const ItemTime = styled.p`
  font: normal normal 500 14px/19px Roboto;
  color: #208FB3;
  margin: 0;
  padding: 0;
  
  @media only screen and (min-width: 1200px) {
    font: normal normal 500 20px/26px Roboto;
  }
`;
