import React from 'react';
import { Link } from 'react-router-dom';

import { StyledButton } from './button.styled';

const Button = ({ to = '', label, active }) => {
  if (to.length > 0) {
    return (
      <Link to={to}>
        <StyledButton active={active}>
          {label}
        </StyledButton>
      </Link>
    );
  }
  return (
    <StyledButton active={active}>
      {label}
    </StyledButton>
  );
};

export default Button;
