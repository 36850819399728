import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { GenericDropdown } from './navbar.styled';

export const Container = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const ProfileButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  object-fit: cover;

  ${(props) => props.active && 'box-shadow: 0 0 0 5px #DF6E2133;'}

  &:hover {
    box-shadow: 0 0 0 5px #df6e2133;
  }

  @media only screen and (min-width: 800px) {
    width: 37px;
    height: 37px;
  }
`;

export const Dropdown = styled(GenericDropdown)`
  position: absolute;
  top: 50px;
  right: 10px;
  width: 280px;
  box-shadow: 0px 0px 15px #00000014;
  border-radius: 10px;
  padding: 16px 0;
  z-index: 1000;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  font: normal normal normal 14px/19px Roboto;
  color: #0a0918;
  padding-left: 22px;
  background-color: #ffffff;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: rgba(222, 226, 230, 0.3);
    color: #0a0918;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const StyledLinkButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  font: normal normal normal 16px/19px Roboto;
  color: #0a0918;
  padding-left: 22px;
  background-color: #ffffff;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: rgba(222, 226, 230, 0.3);
    color: #0a0918;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Separator = styled.div`
  width: auto;
  height: 1px;
  background: #dee2e6;
  margin: 4px 20px 8px 20px;
`;

export const ButtonText = styled.div`
  font: normal normal normal 14px/21px Roboto;
  color: #ffffff;
  margin: 0 15px;
  display: flex;
  align-items: center;
  display: none;
  cursor: pointer;
  @media only screen and (min-width: 800px) {
    display: block;
  }
`;
export const SignInButton = styled.div`
  font: normal normal normal 14px/16px Roboto;
  text-align: center;
  color: #ffffff;
  background: #df7531;
  border-radius: 4px;
  margin: 5px;
  padding: 10px;
  ${({ active = true }) => active && 'cursor: pointer;'}
`;

export const ServiceProviderContainer = styled.div`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: contents;
  }
`;

export const ServiceProviderButton = styled.div`
  font: normal normal normal 14px/16px Roboto;
  text-align: center;
  color: #ffffff;
  background: #333333;
  border-radius: 4px;
  margin: 5px;
  padding: 10px;
  border: #b2bcc1 solid 1px;
  ${({ active = true }) => active && 'cursor: pointer;'}
`;

export const StatusAnimation = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 auto;
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  margin: 40px auto;
  border: 3px solid hsl(0, 0%, 30%);
  border-top: 3px solid #df6e21;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spinAnimation} 1s linear infinite;
  flex-shrink: 0;
`;
