/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import findNearest from 'geolib/es/findNearest';
import { useAuthContext } from 'contexts/auth';
import { LoginModal } from 'components';
import { authenticate } from 'functions/app';
import { getAvailableCities, selectCity } from 'interface/map';

const ipLocation = require('iplocation');
const publicIp = require('public-ip');

const LoginPage = () => {
  const [authenticatedState, setAuthenticatedState] = useState(null);
  const [currentUser, setCurrentUser] = useState();
  const [currentCity, setCurrentCity] = useState();
  const [uuid, setUuid] = useState();

  const navigate = useNavigate();
  const { authenticated, setAuth } = useAuthContext();

  const lo = useLocation();
  const from = lo.state?.from?.pathname || '/map';

  useEffect(async () => {
    let location = {};

    // Find location using browser location
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        location = { lat: coords.latitude, lng: coords.longitude };
      },
      async () => {
        const loc = await ipLocation(await publicIp.v4());
        location = { lat: loc.latitude, lng: loc.longitude };
      }
    );

    let cityRes = await selectCity(null, location.lat, location.lng);

    // If location not in SmartCity then pick nearest city
    if (!cityRes && !cityRes.currentCity) {
      const allCities = await getAvailableCities();
      const citiesPositions = allCities.map((c) => ({
        latitude: c.attributes.latitude,
        longitude: c.attributes.longitude,
      }));
      const userPosition = { latitude: location.lat, longitude: location.lng };
      const nearestCityPos = findNearest(userPosition, citiesPositions);
      cityRes = await selectCity(
        null,
        nearestCityPos.latitude,
        nearestCityPos.longitude
      );
      console.log('cityRes', cityRes);
    }
    setCurrentCity(cityRes);

    // Check if authenticated
    const authData = await authenticate();
    setAuthenticatedState(authData.authenticatedState);
    setCurrentUser(authData.currentUser);
    setUuid(authData.firebaseUID);
    // set context
    setAuth(
      authData.authenticatedState,
      authData.firebaseUID,
      authData.currentUser
    );
  }, []);

  // If authenticated go to previous url
  useEffect(() => {
    if (authenticatedState || authenticated)
      navigate(from, { replace: true, state: lo.state });
  }, [authenticatedState, authenticated]);

  const onSignIn = (d) => {
    console.log('onSignIn: ', d);
    setAuthenticatedState(d.authenticatedState);
    setAuth(authenticatedState, uuid, currentUser);
    navigate(from, { replace: true, state: lo.state }); // go to previous url
  };

  if (authenticatedState === null) {
    return null;
  }
  return (
    <>
      {currentCity && (
        <LoginModal
          show
          onHide={() => {}}
          icon="icons/petitionPin.svg"
          title="Sign in"
          stage={1}
          setState={({ loginModal }) => {
            console.log(loginModal);
          }}
          onSignIn={(d) => onSignIn(d)}
          authenticatedState={authenticatedState}
          currentUser={currentUser}
          currentCity={currentCity}
        />
      )}
    </>
  );
};

export default LoginPage;
