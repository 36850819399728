/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useRef } from 'react';

import {
  TabsContainer,
  Container,
  Button,
  ScrollButton,
} from './tabs.styled';

/**
 * Tabs Bar
 */
const Tabs = (props) => {
  const {
    links,
    currentTab,
    setTab,
  } = props;

  const ref = useRef();
  const len = 300; // scroll length of the width of one alert button

  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const handleScroll = (e) => {
    e.preventDefault();
    setShowLeft(ref.current.scrollLeft > 0);
    setShowRight(ref.current.scrollLeft + ref.current.clientWidth < ref.current.scrollWidth);
  };

  const handleScrollRight = () => ref.current.scroll({ top: 0, left: ref.current.scrollLeft + len, behavior: 'smooth' });
  const handleScrollLeft = () => ref.current.scroll({ top: 0, left: ref.current.scrollLeft - len, behavior: 'smooth' });

  useEffect(() => {
    setShowRight((ref.current.scrollLeft + ref.current.clientWidth) < ref.current.scrollWidth);
  }, [links]);

  useEffect(() => {
    ref.current.scroll({ top: 0, left: 0 });
  }, []);

  return (
    <TabsContainer>
      <Container
        ref={ref}
        onScroll={(e) => handleScroll(e)}
      >
        {links?.map((link, index) => (
          <Button
            active={currentTab.id ? link.id === currentTab.id : link.id === links[0].id}
            onClick={() => {
              setTab(link);
            }}
            key={index}
          >
            {link.label}
          </Button>
        ))}
      </Container>

      {showLeft && <ScrollButton left onClick={handleScrollLeft} />}
      {showRight && <ScrollButton right onClick={handleScrollRight} />}
    </TabsContainer>
  );
};

export default Tabs;
