/** @jsxImportSource @emotion/react */
import Modal, { ModalProps } from 'components/elements/modal';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { Button, Image } from 'components/elements';
import { useLocation } from 'react-router-dom';
import { useGlobalContext } from 'contexts/global';
import { UserPaymentMethod } from 'types/types';
import { useEffect, useState } from 'react';
import { peachSource } from 'constants/peach';
import { css } from '@emotion/react';
import theme from 'theme';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { Loader } from 'components/inthecity';

const PaymentModal = ({
  paymentAmount,
  productId,
  peachQueryParams,
  merchantCode,
  privateServices,
  redirectUrl,
  redirectUrlParams,
  ...modalProps
}: {
  paymentAmount: number;
  productId: string;
  peachQueryParams: string;
  merchantCode: string;
  privateServices: boolean;
  redirectUrl?: string;
  redirectUrlParams?: string;
} & ModalProps) => {
  // Hooks
  const location = useLocation();
  const {
    loading: peachInitializing,
    response: checkoutId,
    callApi: doInitializePeach,
    // error: peachInitilizationError,
  } = useSmartCityManager(SCManagerEndpoints.DoInitializePeach);
  const {
    globalState: {
      user: { cards },
    },
  } = useGlobalContext();

  // States and constants
  const savedPaymentMethods: UserPaymentMethod[] = cards || [];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    UserPaymentMethod | undefined
  >(cards?.[0]);
  const formattedPaymentAmount =
    typeof paymentAmount === 'number'
      ? paymentAmount
      : parseFloat(paymentAmount);

  console.log({
    a: typeof paymentAmount === 'number',
    b: paymentAmount,
  });

  // Effects
  useEffect(() => {
    doInitializePeach({
      queryParams: `${
        selectedPaymentMethod ? `cardUUID=${selectedPaymentMethod.uuid}` : ''
      }&price=${formattedPaymentAmount.toFixed(
        2
      )}&productId=${productId}&createRegistration=false&merchantCode=${merchantCode}&privateServices=${privateServices}&${
        peachQueryParams ?? ''
      }`,
    });
  }, [selectedPaymentMethod, paymentAmount]);

  return (
    <Modal isOpen={modalProps.isOpen} onClose={modalProps.onClose}>
      {checkoutId && !selectedPaymentMethod ? (
        <Flex justifyContent="center" width="100%" flexDirection="column">
          <Text textAlign="left" fontSize="18px" fontWeight={600}>
            Payment amount - R {formattedPaymentAmount.toFixed(2)}
          </Text>
          {!checkoutId && peachInitializing ? (
            <Loader />
          ) : (
            <iframe
              srcDoc={peachSource(
                checkoutId,
                `${
                  redirectUrl ?? location.pathname
                }?merchantCode=${merchantCode}&${redirectUrlParams ?? ''}`,
                false
              )}
              width="100%"
              height="370px"
              title="payment"
              css={css`
                border: none;
              `}
            />
          )}
          <Button variant="outlined" onClick={modalProps.onClose} width="100%">
            Cancel
          </Button>
        </Flex>
      ) : (
        <Flex flexDirection="column">
          <Text textAlign="left" fontSize="18px" fontWeight={600}>
            Payment amount - R {formattedPaymentAmount.toFixed(2)}
          </Text>
          {savedPaymentMethods.map((method, idx) => (
            <Button
              onClick={() => {
                setSelectedPaymentMethod(method);
              }}
              key={idx}
              mb="10px"
            >
              <Flex
                border={
                  selectedPaymentMethod?.bin === method.bin
                    ? `1px solid ${theme.colors.primary}`
                    : theme.borders.standard1px
                }
                borderRadius={theme.borderRadius.small}
                p="10px"
                justifyContent="space-between"
                alignItems="center"
                backgroundColor={
                  selectedPaymentMethod?.bin === method.bin
                    ? theme.colors.selectionPrimary
                    : 'white'
                }
              >
                <Image source="icons/card.svg" alt="card" />
                <Text fontWeight="bold">
                  **** **** **** {savedPaymentMethods[0].lastDigits}
                </Text>
                <Text color={theme.colors.lightGrey}>
                  Expiry: {savedPaymentMethods[0].expiryMonth}/
                  {savedPaymentMethods[0].expiryYear}
                </Text>
              </Flex>
            </Button>
          ))}
          <iframe
            srcDoc={peachSource(
              checkoutId,
              `${
                redirectUrl ?? location.pathname
              }?merchantCode=${merchantCode}&${redirectUrlParams ?? ''}`,
              false
            )}
            width="100%"
            height="370px"
            title="payment"
            css={css`
              border: none;
            `}
          />
        </Flex>
      )}
    </Modal>
  );
};

export default PaymentModal;
