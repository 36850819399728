/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ReduxAccountState {
  isMobileDrawerOpen: boolean;
}

const initialState: ReduxAccountState = {
  isMobileDrawerOpen: false,
};

export const accountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    setMobileDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isMobileDrawerOpen = action.payload;
    },
  },
});

export const accountSelector = (state: ReduxAccountState) => state;

export const { setMobileDrawerOpen } = accountSlice.actions;
export default accountSlice.reducer;
