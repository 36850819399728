import styled from 'styled-components';

import { Headline, Body, Title } from 'components';

export const Header = styled(Headline)`
  display: none;
  @media only screen and (min-width: 800px) {
    display: flex;
    width: 100%;
    margin: 30px 0px;
    color: ${(props) => props.theme.colors.dark};

    // text overflow
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  
  @media only screen and (min-width: 800px) {
    margin: 0 100px;
  }
`;

export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  
  @media only screen and (min-width: 1000px) {
    margin-right: 50px;
    flex-shrink: 1;
  }
`;

export const DetailsColumn = styled.div`
  display: none;
  
  @media only screen and (min-width: 1000px) {
    display: flex;
    flex-direction: column;
    flex-basis: 625px;
    margin-top: 100px;
    flex-shrink: 0;
  }
`;

export const IconLabelButton = styled.div`
  @media only screen and (min-width: 800px) {
    cursor: pointer;
    height: 36px;
    padding: 0 15px 0 50px;
    background: rgba(178, 188, 193, 0.2) url(${(props) => props.src}) 0% 0% no-repeat padding-box;
    background-position: 15px 50%;
    background-size: 15px;
    border-radius: 5px;
    font: ${(props) => `normal normal normal ${props.theme.fontSizes[0]} ${props.theme.font}`};
    line-height: 36px;
    color: ${(props) => props.theme.colors.dark};
    width: fit-content;
    margin-left: 17px;
    transition: background-color 0.3s ease-out;
    &:hover {
      background-color: rgba(178, 188, 193, 0.3);
    }
  }
`;

export const IconButton = styled.div`
  cursor: pointer;
  background: white url(${(props) => props.src}) 0% 0% no-repeat padding-box;
  background-position: 50% 50%;
  background-size: 15px;
  height: 36px;
  width: 44px;
  border: 1px solid #DEE2E6;
  border-radius: 5px;
  margin-left: 10px;
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: rgba(222, 226, 230, 0.3);
  }

  @media only screen and (min-width: 800px) {
    display: none;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Image = styled.div`
  background: url(${(props) => props.src}) no-repeat padding-box;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #333;
  border-radius: 3px;
  height: 215px;
  margin-bottom: 0px;
  
  @media only screen and (min-width: 800px) {
    border-radius: 5px;
    height: 500px;
  }
`;

export const Heading = styled(Title)`
  color: black;
  margin-bottom: 17px;
`;

// export const Paragraph = styled.p`
// font: ${(props) => `normal normal normal ${props.theme.mobileFontSizes[1]} ${props.theme.font}`};
//   color: ${(props) => props.theme.colors.dark};
//   margin: 0px;

//   @media only screen and (min-width: 800px) {
//     font: ${(props) => `normal normal normal ${props.theme.fontSizes[1]} ${props.theme.font}`};
//   }
// `;

export const TextBold = styled(Body)`
  display: inline-block;
  margin-right: 10px;
`;

export const Line = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colors.tertiary};
  margin: 22px 0px ;
  
  @media only screen and (min-width: 800px) {
    height: 2px;
    margin: 30px 0px;
  }
`;
