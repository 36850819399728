import styled from 'styled-components';
// import { Link } from 'react-router-dom';

export const AlertContainer = styled.div`
  margin: 18px 0 22px 0;
  position: relative;
  @media only screen and (min-width: 800px) {
    margin: 30px 0 22px 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar { width: 0; height: 0; background: transparent; }
`;

export const ScrollButton = styled.div`
  position: absolute;
  border-radius: 50%;
  ${({ left = false }) => (left ? 'left: -4%;' : 'right: -4%;')}
  top: 45%;
  height: 21px;
  width: 21px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.533) url("social/scroll-icon.svg") 55% 50% no-repeat padding-box;
  background-size: 6px;
  transform: ${({ left = false }) => (left ? 'rotate(180deg)' : 'none')};
  padding: 0 0;
  @media only screen and (min-width: 800px) {
    top: 35%;
    height: 35px;
    width: 35px;
    background-position: 55% 50%;
    background-size: auto;
  }
`;

export const StyledAlert = styled.div`
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: 120px;
  width: 82px;
  min-width: 82px;
  transition: all 0.15s ease-in-out;
  background: ${({ active }) => (active ? '#DF6E21' : '#FFFFFF')};
  border: 1px solid ${({ active }) => (active ? '#DF6E21' : 'rgba(178, 188, 193, 0.2)')};
  border-radius: 3px;
  &:not(:last-child) {
    margin-right: 15px;
  }
  &:hover {
    background: ${({ active }) => (active ? '#DF6E21' : '#f7f8f9')};
  }
  @media only screen and (min-width: 800px) {
    min-height: 200px;
    width: 175px;
    min-width: 175px;
    border-radius: 2px;
    border: 3px solid ${({ active }) => (active ? '#DF6E21' : 'rgba(178, 188, 193, 0.2)')};
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export const Icon = styled.img`
  margin-top: 12px;
  height: 16px;
  filter: ${({ active }) => (active ? 'brightness(0) invert(1)' : 'none')};
  @media only screen and (min-width: 800px) {
    margin-top: 20px;
    height: 35px;
  }
`;

export const Title = styled.h2`
  font: normal normal 500 10px/12px Roboto;
  letter-spacing: 0px;
  color: #0A0918;
  text-align: center;
  padding: 0 6px;
  margin: 10px 0;
  color: ${({ active }) => (active ? '#FFFFFF' : '#0A0918')};
  @media only screen and (min-width: 800px) {
    padding: 0 12px;
    font: normal normal normal 16px/19px Roboto;
  }
`;

export const Description = styled.p`
  font: normal normal normal 10px/12px Roboto;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 12px;
  margin: 0 0 8px 0;
  color: ${({ active }) => (active ? '#FFFFFF' : '#B2BCC1')};
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/19px Roboto;
  }
`;

export const Count = styled.div`
  margin-top: auto;
  font: normal normal 500 7px/20px Roboto;
  color: ${({ active }) => (active ? '#FFFFFF' : '#0A0918')};
  text-align: center;
  width: 100%;
  background: ${({ active }) => (active ? '#ffffff19' : '#b2bcc119')} 0% 0% no-repeat padding-box;
  @media only screen and (min-width: 800px) {
    font: normal normal 500 10px/28px Roboto;
  }
`;

export const New = styled.div`
  position: absolute;
  top: 4%;
  right: 5%;
  text-transform: uppercase;
  padding: 1px 2px;
  font: normal normal normal 7px/9px Roboto;
  font-weight: 500;
  color: #DF6E21;
  background: rgba(223, 109, 33, 0.1);
  @media only screen and (min-width: 800px) {
    padding: 4px 8px;
    font: normal normal normal 10px/12px Roboto;
  }
`;
