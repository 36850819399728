import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { getPrivateServiceImage } from 'functions/private-services';
import theme from 'theme';
import { CustomerBooking } from 'types/types';

const InactiveBooking = ({
  booking,
  pending,
  onRateClick,
  setViewBooking,
}: {
  booking: CustomerBooking;
  pending?: boolean;
  onRateClick?: () => void;
  setViewBooking: () => void;
}) => {
  const appointmentStart = new Date(booking.bookingStartDate * 1000);
  return (
    <Grid
      gridTemplateColumns="2fr 1fr 1.5fr"
      gridColumnGap="10px"
      p="10px"
      mb="10px"
      border={theme.borders.standard1px}
      borderRadius={theme.borderRadius.medium}
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          grid-template-columns: 2fr 1fr;
        }
      `}
    >
      <Flex alignItems="center">
        {!pending && (
          <Image
            source={booking.resourceProfilePic}
            alt="Profile"
            height="40px"
            minWidth="40px"
            maxWidth="40px"
            borderRadius="50%"
            objectFit="cover"
            mr="10px"
          />
        )}
        <Box textAlign="left">
          <Text>{booking.resourceName}</Text>
          <Flex>
            <Image
              source={getPrivateServiceImage(booking.serviceIcon)}
              alt="service"
              height="15px"
              minWidth="15px"
              mr="5px"
            />
            <Text color={theme.colors.primary}>{booking.service}</Text>
          </Flex>
          <Text color={theme.colors.lightGrey}>
            {pending ? 'Scheduled for ' : ''}
            {`${appointmentStart.toLocaleDateString('en-GB', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })} at ${
              appointmentStart.getHours() > 9
                ? appointmentStart.getHours()
                : `0${appointmentStart.getHours()}`
            }:00`}
          </Text>
        </Box>
      </Flex>
      <Box hideForMobile />
      <Flex justifyContent="flex-end" alignItems="center">
        <Text color={theme.colors.lightGrey}>{booking.status}</Text>
        {booking.ratingStatus === 'To Rate' && onRateClick && (
          <Button
            backgroundColor={theme.colors.selectionPrimary}
            color={theme.colors.primary}
            height="fit-content"
            p="7px"
            borderRadius="25px"
            ml="10px"
            fontSize="10px"
            onClick={onRateClick}
          >
            Rate
          </Button>
        )}

        <Button
          onClick={setViewBooking}
          backgroundColor={theme.colors.selectionPrimary}
          color={theme.colors.primary}
          height="fit-content"
          p="7px"
          borderRadius="25px"
          ml="10px"
          fontSize="10px"
        >
          View Booking
        </Button>
      </Flex>
    </Grid>
  );
};

export default InactiveBooking;
