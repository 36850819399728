import { Button, Link, Modal } from 'components/elements';
import { ModalProps } from 'components/elements/modal';
import { Loader } from 'components/inthecity';
import { Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { useGlobalContext } from 'contexts/global';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import theme from 'theme';
import { SmartCityUser } from 'types/types';

const TermsAndConditionsModal = ({
  user,
  ...modalProps
}: {
  user: SmartCityUser;
} & ModalProps) => {
  const { setUserAndCityFromUser } = useGlobalContext();
  const { loading, callApi: updateProfile } = useSmartCityManager(
    SCManagerEndpoints.UpdateProfile
  );

  const handleAccept = () => {
    updateProfile({
      body: {
        ...user,
        accepttc: true,
      },
    });
    setUserAndCityFromUser({
      ...user,
      accepttc: true,
    });
  };

  const handleDecline = () => {
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} title="Important Notice">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex justifyContent="center" flexDirection="column" maxWidth="500px">
            <Text fontSize="16px" textAlign="center">
              Please confirm that you have read our{' '}
              <Link
                href="/terms-and-conditions#introduction"
                target="_blank"
                textDecoration="underline"
                color={theme.colors.primary}
              >
                terms and conditions
              </Link>{' '}
              and{' '}
              <Link
                href="/privacy-policy"
                target="_blank"
                textDecoration="underline"
                color={theme.colors.primary}
              >
                privacy policy
              </Link>{' '}
              before you can continue.
            </Text>
            <Grid gridTemplateColumns="1fr 1fr" mt="40px" gridColumnGap="15px">
              <Button onClick={handleAccept} variant="cta">
                Accept
              </Button>
              <Button
                onClick={handleDecline}
                variant="outlined"
                fontWeight="bold"
              >
                Decline
              </Button>
            </Grid>
          </Flex>
        </>
      )}
    </Modal>
  );
};

export default TermsAndConditionsModal;
