import { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';

import { useGlobalContext } from 'contexts/global';
import { MSCProMunicipalities } from 'constants/msc-pro-municipalities';
import './sidebar.scss';

function Sidebar({ setShowLoginModal }) {
  console.log('Sidebar():');
  const [open, setOpen] = useState(false);

  const boardingUrl = 'boarding.'.concat(process.env.REACT_APP_API_BASE);

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleStateChange = (state) => {
    setOpen(state.isOpen);
  };

  const { globalState } = useGlobalContext();

  return (
    <Menu width="80vw" isOpen={open} onStateChange={handleStateChange}>
      {globalState.user.uuid.localeCompare('false') === 0 ? (
        <div className="container">
          <div
            className="SignInButton"
            role="button"
            tabIndex={0}
            onClick={() => {
              handleCloseMenu();
              setShowLoginModal(true);
            }}
          >
            Sign in
          </div>
          <div
            className="OnboardingButton"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://'.concat(boardingUrl));
            }}
          >
            Become A Service Provider
          </div>
        </div>
      ) : null}
      <Link className="bm-item" to="/" onClick={() => handleCloseMenu()}>
        Home
      </Link>
      <Link className="bm-item" to="/map" onClick={() => handleCloseMenu()}>
        Map
      </Link>
      {globalState?.city?.value &&
        MSCProMunicipalities.includes(globalState.city.value) && (
          <Link to={`/my-municipality/services/${globalState.city.value}`}>
            Municipality
          </Link>
        )}
      <Link
        className="bm-item"
        to="/in-the-city"
        onClick={() => handleCloseMenu()}
      >
        In-the-City
      </Link>
      <Link className="bm-item" to="/social" onClick={() => handleCloseMenu()}>
        Social Feed
      </Link>
      <Link className="bm-item" to="/press" onClick={() => handleCloseMenu()}>
        In the Press
      </Link>
    </Menu>
  );
}

export default Sidebar;
