import { Text } from 'components/typography';
import { Flex, Grid } from 'components/layout';
import { Image, Link } from 'components/elements';
import { css } from '@emotion/react';
import theme from 'theme';

const AppSection = () => (
  <Flex background="#DF6E211A 0 0 no-repeat padding-box" py="50px" px="10%">
    <Image
      source="/marketing/app_download.png"
      mr="80px"
      hideForTablet
      hideForMobile
      maxHeight="450px"
    />

    <Flex flexDirection="column">
      <Text
        fontSize="40px"
        fontWeight="500"
        m={0}
        p={0}
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            font-size: 30px;
          }
        `}
      >
        Download the My Smart City App
      </Text>
      <Text
        fontSize="24px"
        m={0}
        p={0}
        fontWeight="300"
        mt="20px"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            font-size: 16px;
          }
        `}
      >
        Get the app today and look out for more premium services coming soon!
      </Text>
      <Grid gridTemplateColumns="1fr 2fr" mt="20px" gridColumnGap="15px">
        <Image source="/home/images/qrcode_black.png" hideForMobile />

        <Flex flexDirection="column">
          <Link
            href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
            id="home-getmore-playstore"
          >
            <Image source="/home/appSection/playStore.svg" width="192px" />
          </Link>
          <Link
            href="https://apps.apple.com/app/mysmart-city/id1555236570"
            id="home-getmore-appstore"
          >
            <Flex maxWidth="300px">
              <Image
                source="/home/appSection/appStore.svg"
                mt="10px"
                width="192px"
              />
            </Flex>
          </Link>
        </Flex>
      </Grid>
    </Flex>
  </Flex>
);

export default AppSection;
