import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 21px;
`;

export const Line = styled.div`
  height: 0;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: 12px;
  overflow: hidden;
  border-bottom: 7px solid rgba(178, 188, 193, 0.19);
  @media only screen and (min-width: 800px) {
    ${({ isFirst = false }) => (isFirst && 'display: none;')}
    width: 100%;
    margin: 0;
    margin-bottom: 27px;
    border-bottom: 2px solid rgba(178, 188, 193, 0.19);
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProfileRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ repost = false }) => (repost ? '0' : '18px')} 0 20px 0;
  height: 50px;
  @media only screen and (min-width: 800px) {
    height: 80px;
  }
`;

export const Status = styled.div`
  ${({ number = 0 }) => (number === 3 ? `
    background: #04bd6d19 0% 0% no-repeat padding-box;
    color: #04BD6C;
    ` : `
    background: #fdb1251e 0% 0% no-repeat padding-box;
    color: #FDB225;
  `)}
  font: normal normal normal 9px/11px Roboto;
  border-radius: 3px;
  padding: 8px 14px;
  height: min-content;
  align-self: center;
  margin-left: auto;
  flex-shrink: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/21px Roboto;
    padding: 12px 25px;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: auto;
  flex-shrink: 0;
  p {
    text-align: right;
    font: normal normal normal 9px/11px Roboto;
    @media only screen and (min-width: 800px) {
      font: normal normal normal 14px/21px Roboto;
    }
  }
`;

export const StatusBarContainer = styled.div`
  position: relative;
  width: 75px;
  height: 5px;
  margin-bottom: 7px;
  margin-left: auto;
  @media only screen and (min-width: 800px) {
    width: 135px;
    height: 9px;
    margin-bottom: 10px;
  }
`;

export const StatusBar = styled.div`
  position: absolute;
  height: 100%;
  width: ${({ percent }) => (percent ? `${percent}%` : '100%')};
  background: ${({ percent }) => (percent ? '#DF6E21' : '#f5d3bc')};
  border-radius: 2px;
  transition: width 0.5s;
  @media only screen and (min-width: 800px) {
    border-radius: 5px;
  }
`;

export const Reference = styled.p`
  font: normal normal normal 10px/13px Roboto;
  color: #0A0918;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(178, 188, 193, 0.08);
  margin: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/21px Roboto;
    border-bottom: 1px solid rgba(178, 188, 193, 0.08);
    padding-bottom: 16px;
  }
`;

export const ReferenceNumber = styled.p`
  display: inline-block;
  color: #DF6E21;
  padding-left: 0.5rem;
`;

export const ProfileImage = styled.img`
  object-fit: cover;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  @media only screen and (min-width: 800px) {
    height: 80px;
    width: 80px;
  }
`;

export const InfoContainer = styled.div`
  position: relative;
  margin: 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (min-width: 800px) {
    margin: 0 24px;
  }
`;
export const InfoRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Username = styled.p`
  font: normal normal normal 12px/14px Roboto;
  margin-bottom: 4px;
  letter-spacing: 0px;
  color: #333333;
  margin: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/26px Roboto;
    margin-bottom: 8px;
  }
`;

export const VerifiedIcon = styled.img`
  margin-left: 12px;
  height: 11px;
  width: 11px;
  @media only screen and (min-width: 800px) {
    margin-left: 15px;
    height: 14px;
    width: 14px;
  }
`;

export const City = styled.p`
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #B2BCC1;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/21px Roboto;
  }
`;

export const GrayText = styled.p`
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #B2BCC1;
  margin: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/26px Roboto;
  }
`;

export const Dot = styled.p`
  font: normal normal 500 10px/13px Roboto;
  padding: 0 5px;
  color: #B2BCC1;
  margin: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/26px Roboto;
    padding: 0 9px;
  }
`;

export const TypeIcon = styled.img`
  filter: hue-rotate(180deg) saturate(60);
  object-fit: cover;
  width: 8px;
  height: 12px;
  @media only screen and (min-width: 800px) {
    width: 10px;
    height: 15px;
  }
`;

export const Type = styled.p`
  font: normal normal normal 10px/13px Roboto;
  color: #DF6E21;
  margin: 0 0 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  flex: 1;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/26px Roboto;
    margin: 0 0 0 8px;
  }
`;

export const Title = styled.h2`
  overflow-wrap: break-word;
  font: normal normal normal 12px/14px Roboto;
  letter-spacing: 0px;
  color: #333333;
  margin: 0 0 4px 0;
  @media only screen and (min-width: 800px) {
    font: normal normal 500 16px/19px Roboto;
    margin: 0 0 8px 0;
  }
`;

export const Text = styled.p`
  overflow-wrap: break-word;
  font: normal normal 500 10px/12px Roboto;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 13px;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/21px Roboto;
  }
`;

export const ImagesContainer = styled.div`
  height: 224px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1px;
  /* border-radius: 12px;
  overflow: hidden; */
  @media only screen and (min-width: 800px) {
    height: 245px;
    grid-gap: 4px;
  }
`;

export const PreviewImage = styled.div`
  cursor: pointer;
  background: url(${({ src }) => src}) no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 22px/29px Roboto;
  color: #FFFFFF;

  @media only screen and (max-width: 800px) {
    ${({ len }) => len === 1 && `
      &:nth-child(1) {
        grid-column: span 2;
        grid-row: span 2;
      }
    `}
    ${({ len }) => len === 2 && `
      &:nth-child(1) {
        grid-column: span 2;
      }
      &:nth-child(2) {
        grid-column: span 2;
      }
    `}
    ${({ len }) => len === 3 && `
      &:nth-child(1) {
        grid-column: span 2;
      }
    `}
    ${({ len, src }) => len > 3 && `
      &:nth-child(1) {
        grid-column: span 2;
      }
      &:nth-child(3) {
        background: linear-gradient(#0000007f, #0000007f), url(${src}) no-repeat;
        background-size: cover;
      }
      &:nth-child(n + 4) {
        display: none;
      }
    `}
  }
  
  @media only screen and (min-width: 800px) {
    ${({ len }) => len === 1 && `
      &:nth-child(1) {
        grid-column: span 2;
        grid-row: span 2;
      }
    `}
    ${({ len }) => len === 2 && `
      &:nth-child(1) {
        grid-row: span 2;
      }
      &:nth-child(2) {
        grid-row: span 2;
      }
    `}
    ${({ len }) => len === 3 && `
      &:nth-child(1) {
        grid-row: span 2;
      }
    `}
    ${({ len, src }) => len > 3 && `
      &:nth-child(1) {
        grid-row: span 2;
      }
      &:nth-child(3) {
        background: linear-gradient(#0000007f, #0000007f), url(${src}) no-repeat;
        background-size: cover;
      }
      &:nth-child(n + 4) {
        display: none;
      }
    `}
  }
`;

export const ActionRow = styled.div`
  display: flex;
  justify-content: ${({ oneItem = false }) => (oneItem ? 'space-around' : 'space-between')};
  align-items: center;
  height: 50px;
  @media only screen and (min-width: 800px) {
    height: 80px
  }
`;

export const ActionButton = styled.div`
  user-select: none;
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 2px;
  background: #FFFFFF;
  transition: all 0.2s;
  &:hover {
    background: rgba(178, 188, 193, 0.19);
  }
  &:active {
    background: rgba(178, 188, 193, 0.25);
  }
  @media only screen and (min-width: 800px) {
    padding: 12px;
  }
`;

export const ActionIcon = styled.img`
  height: 13px;
  margin-right: 9px;
  @media only screen and (min-width: 800px) {
    height: 22px;
    margin-right: 16px;
  }
  ${({ active = false }) => active && `
    filter: hue-rotate(180deg) saturate(40);
  `}
`;

export const ActionText = styled.p`
  ${({ mobileHide }) => (mobileHide && `
    @media only screen and (max-width: 800px) {
      display: none;
    }
  `)}
  font: normal normal normal 10px/12px Roboto;
  letter-spacing: 0px;
  color: #B2BCC1;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/21px Roboto;
  }
`;

export const Readmore = styled.div`
  cursor: pointer;
  font: normal normal 500 10px/12px Roboto;
  margin-top: -5px;
  margin-bottom: 13px;
  color: #DF6E21;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/21px Roboto;
    margin-top: -5px;
    margin-bottom: 13px;
  }
`;
