import { apiUrl } from 'constants/app';

export const postQuestionnaire = async (survey) => fetch(
  `${apiUrl}smartcitymanager/postQuestionnaire`,
  {
    method: 'POST',
    async: true,
    body: JSON.stringify(survey),
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
)
  .then((res) => res.json())
  .catch((e) => {
    console.log(e);
    return false;
  });
