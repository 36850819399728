import { css } from '@emotion/react';
import AccountNavigator from 'components/account-navigator';
import { Button, Modal, TextField } from 'components/elements';
import Form from 'components/form';
import { Text } from 'components/typography';
import { InputField } from 'components/form/input';
import SubmitButton from 'components/form/submit-button';
import { Box, Flex, Grid } from 'components/layout';
import { useGlobalContext } from 'contexts/global';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useState } from 'react';
import { validateMobileNumber } from 'functions/form';
import Lottie from 'react-lottie';
import animationData from './customer-support.json';

const SupportPage = () => {
  const {
    globalState: { user },
  } = useGlobalContext();
  const [message, setMessage] = useState<string>('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const { loading, response, callApi } = useSmartCityManager(
    SCManagerEndpoints.LogSupportCall
  );
  const handleSubmit = async (data: any) => {
    if (message && message.length > 0) {
      const queryString = `contactName=${data.fullName}&contactNumber=${data.phone}&address=null&body=${message}&subject=${data.subject}`;
      callApi({ queryParams: queryString });
      setMessage('');
      setShowSuccessModal(true);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <AccountNavigator loading={loading}>
      <Modal
        isOpen={showSuccessModal && response}
        onClose={() => setShowSuccessModal(false)}
        title="Success!"
      >
        <Flex flexDirection="column" justifyContent="center">
          <Box my="30px">
            <Lottie options={defaultOptions} height={250} width={250} />
          </Box>
          <Text fontSize="18px" mx="3%" textAlign="center">
            Your support call has been logged successfully. One of our agents
            will be in touch shortly to assist you.
          </Text>
          <Button
            variant="cta"
            mt="30px"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </Button>
        </Flex>
      </Modal>
      <Form
        onSubmit={(data) => handleSubmit(data)}
        mobileCss={css`
          margin-top: 20px;
        `}
      >
        <Grid
          gridRowGap="15px"
          desktopCss={css`
            margin: 0 10%;
          `}
        >
          <Text fontWeight="bold" fontSize="18px">
            Log a support call
          </Text>
          <InputField
            name="fullName"
            defaultValue={user.fullName}
            placeholder="Name and surname"
            required
            alwaysShowLabel
          />
          <InputField
            name="phone"
            defaultValue={user.mobileNumber}
            placeholder="MobileNumber"
            required
            alwaysShowLabel
            validate={(e) => {
              if (!validateMobileNumber(e.target.value)) {
                return {
                  valid: false,
                  message: 'Please enter a valid mobile number',
                  field: 'contactNumber',
                };
              }
              return {
                valid: true,
                message: '',
                field: 'contactNumber',
              };
            }}
          />
          <InputField
            name="subject"
            placeholder="Subject"
            required
            alwaysShowLabel
          />
          <TextField
            multiline
            placeholder="Message"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <SubmitButton
            label="Submit Support Call"
            disabled={message.length === 0}
          />
        </Grid>
      </Form>
    </AccountNavigator>
  );
};

export default SupportPage;
