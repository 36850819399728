import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, Col } from 'react-bootstrap';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Section from 'components/section';

import {
  TermsLinks,
  Tcheader,
  TermsPopupBox,
  TermsPopupContent,
  TermsPopupButton,
  TermsSideMenu,
  TermsSideMenuContent,
  TermsContainer,
  TermsTitle,
  TermsTitleAlt,
  TermsParagraph,
} from './bookingInsurance.styled';

const Links = () => (
  <TermsLinks>
    <Tcheader className="text-bold">My Smart City</Tcheader>
    <ul>
      <li>
        <Link to="/booking-insurance#disclaimer" smooth>
          Use of Booking Insurance Disclaimer
        </Link>
      </li>
    </ul>
    <Tcheader className="text-bold">Booking Insurance</Tcheader>
    <ol>
      <li>
        <Link
          to="/booking-insurance#introduction-to-your-insurance-policy"
          smooth
        >
          Introduction to your Insurance Policy
        </Link>
      </li>
      <li>
        <Link to="/booking-insurance#definitions" smooth>
          Definitions
        </Link>
      </li>

      <li>
        <Link to="/booking-insurance#geographic-scope" smooth>
          Geographic Scope
        </Link>
      </li>
      <li>
        <Link to="/booking-insurance#understanding-this-policy" smooth>
          Understanding this Policy
        </Link>
      </li>
      <li>
        <Link to="/booking-insurance#termination-of-this-policy" smooth>
          Termination of this Policy{' '}
        </Link>
      </li>
      <li>
        <Link
          to="/booking-insurance#conditions-applicable-whole-insurance"
          smooth
        >
          General Conditions Applicable to the whole of this Insurance
        </Link>
      </li>

      <li>
        <Link to="/booking-insurance#scope-of-cover" smooth>
          Scope of Cover - ALL RISK
        </Link>
      </li>
      <li>
        <Link to="/booking-insurance#scope-of-cover-home-contents" smooth>
          Scope of Cover - HOME CONTENTS
        </Link>
      </li>
    </ol>
  </TermsLinks>
);

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && (
        <TermsPopupBox>
          <TermsPopupContent>
            <Links />
          </TermsPopupContent>
        </TermsPopupBox>
      )}
      <TermsPopupButton
        className={`${isOpen ? 'open' : ''}`}
        role="button"
        tabIndex={0}
        onClick={togglePopup}
      >
        <img src="terms/arrow.svg" alt="arrow" title={isOpen} />
      </TermsPopupButton>
    </>
  );
};

const SideMenu = () => (
  <TermsSideMenu>
    <TermsSideMenuContent>
      <Links />
    </TermsSideMenuContent>
  </TermsSideMenu>
);

export default function BookingInsurance() {
  return (
    <>
      <SEO
        title="My Smart City | Booking Insurance"
        description="Booking insurance content"
      />
      <Section color="white" thinPadding>
        <Row noGutters>
          <Col lg={3} sm={12}>
            <Popup />
            <SideMenu />
          </Col>
          <Col lg={9} sm={12}>
            <TermsContainer>
              <TermsTitle>Booking Insurance</TermsTitle>
              <TermsTitleAlt className="bold" id="disclaimer">
                Use of Booking Insurance Disclaimer
              </TermsTitleAlt>

              <TermsParagraph>
                Please note: We have provided this insurance to give peace of
                mind to your booking. If you choose to remove the insurance from
                your booking, we will not be held responsible or liable for any
                damage. MySmart.city is not a Financial Services Provider and
                may not provide advice in relation to the insurance product or
                submit claims on your behalf – this service is provided by the
                intermediary, SureStart Online Financial Services, and all
                queries and claims will be referred to them. This policy is
                underwritten by Mutual and Federal Risk Financing Limited and
                administered by SureStart Online Financial Services FSP. 47233.
                SureStart will assist you if you need to claim and can be
                contacted at: insurance@mysmart.city or 087 550 8014.
              </TermsParagraph>
              <TermsParagraph>
                At MySmart.city we screen our service providers before
                onboarding them onto the platform and provide continuous
                training. However, even with all preventative measures in place,
                small mistakes can happen in the course of providing a service,
                and performing relevant tasks. MySmart.city offers clients an
                insurance policy which may be removed from the booking if you
                would prefer to self-insure.
              </TermsParagraph>
              <TermsParagraph>
                {' '}
                This R15 policy covers damage and theft for:
                <TermsParagraph>
                  Home Contents up to R5000: These are furniture items and
                  appliances, not fixed to the structure of the house. Some
                  common examples are: plates, cups, glasses, chairs, vacuum
                  cleaners and linen.{' '}
                  <TermsParagraph>
                    All Risk items up to R3000: This cover is specifically for
                    clothes but can be extended to other items commonly taken
                    out the house with you.
                  </TermsParagraph>
                </TermsParagraph>
              </TermsParagraph>
              <TermsParagraph>
                Items and damage NOT covered under this policy:
                <li>
                  Superficial damage which leaves an item in working condition.
                  Example: a scratch to a kettle or vacuum cleaner.
                </li>
                <li>
                  The structure of the house or items fixed to the structure of
                  the house (these should be covered under your home insurance
                  policy). Example: Tiles, walls, shower doors, blinds and
                  carpets.
                </li>
                <li>
                  Cash, jewellery and mobile devices. Please do not leave
                  valuable items out in the open and lock them in a safe if
                  possible.
                </li>
                You may read through the policy wording below if you&#39;d like
                more information or contact SureStart on 087 550 8014
              </TermsParagraph>
              <TermsParagraph>
                If you need to claim:
                <li>
                  Please download and complete the{' '}
                  <a className="bm-item" href="/MySmart.City Claim Form.pdf">
                    Claim Form.{' '}
                  </a>
                  This must be sent with the supporting documents to
                  insurance@mysmart.city
                </li>
                <li>
                  Immediately take photos of the damage as this will be required
                  in the claims process. For clothing items, also take a picture
                  of the label.
                </li>
                <li>Do not throw the damaged item/s away.</li>
                <li>
                  If the damage is assessed by a professional (eg. Electrician),
                  ask for a damage report.
                </li>
                <li>
                  Obtain replacement quotes for the item/s being claimed for.
                  These can often be easily found on e-commerce websites.
                </li>
                <li>
                  In the rare event of theft, a SAPS case number must be opened.
                </li>
                <li>10% excess applies to all claims.</li>
              </TermsParagraph>

              <ol>
                <li id="introduction-to-your-insurance-policy">
                  Introduction to Your Insurance Policy
                  <ol>
                    <li>
                      <TermsParagraph>
                        This is your insurance policy and you should keep it in
                        a safe place. It is a contract of insurance between you
                        and us, and is made up of this policy booklet, your
                        schedule and any endorsement applying to your insurance
                        policy.
                      </TermsParagraph>
                    </li>
                    <li>
                      <TermsParagraph>
                        Our acceptance of the risks insured is based on the
                        information and statements you have provided to your
                        intermediary or the information that was given on your
                        behalf when you applied for this insurance. You have an
                        obligation at the start of this insurance and at renewal
                        to supply accurate and complete answers to any questions
                        asked during your application for this insurance, to
                        make sure that all information supplied is true and
                        correct, and to tell us of any changes you have given as
                        soon as possible.
                      </TermsParagraph>
                    </li>
                    <li>
                      <TermsParagraph>
                        The insurance relates ONLY to those sections of the
                        policy which are shown in the schedule as being
                        included.If any details are incorrect or if it does not
                        provide the cover you need you should return the
                        schedule to your intermediary immediately.
                      </TermsParagraph>
                    </li>
                  </ol>
                </li>

                <li id="definitions">
                  Definitions
                  <ol>
                    <li>
                      &#34;Accident&#34; and &#34;accidental&#34; means any
                      direct physical loss caused by one event or series of
                      events arising out of one original cause or source.
                      &#34;Accidental&#34; damage must be caused by acts that
                      were sudden, unexpected and not deliberate.
                    </li>
                    <li>
                      “Damage” means accidental loss of or damage to tangible
                      property.
                    </li>
                    <li>
                      “Excess” means the amount that must be paid by you in
                      respect of a claim or series of claims arising out of one
                      original cause or source before any amount is payable by
                      us.
                    </li>
                    <li>“Insured” means the policyholder.</li>
                    <li>
                      &#39;Named – Peril” means the specific risk or cause of
                      loss as stated in the “Scope of cover” section of the
                      policy wording.
                    </li>
                    <li>
                      “policy” means the wording and schedule read together as
                      the contract of insurance between you and us.
                    </li>
                    <li>
                      “Property, building and home” means your private
                      residential structures insured under this policy. This
                      includes outbuildings such as garages on the same premises
                      as the main structure.
                    </li>
                    <li>“SAPS” means South Africa Police Services.</li>
                    <li>
                      “Schedule” means that part of the policy that specifies
                      details of the insured, the premises, the property insured
                      and any excess, endorsements and conditions applicable.
                    </li>
                    <li>
                      “We or Us or Our” means Transact & Protect as Underwriting
                      Management Agent and/or Mutual & Federal Risk Financing
                      Limited as Insurer or their agent.
                    </li>
                    <li>
                      “You or Your or Yourself” means the policyholder and or
                      insured named in the schedule
                    </li>
                  </ol>
                </li>

                <li id="geographic-scope">
                  Geographic Scope
                  <ol>
                    <li>
                      <TermsParagraph>
                        This policy is only valid for policyholders with their
                        main residence in the Republic of South Africa.
                      </TermsParagraph>
                    </li>
                  </ol>
                </li>
                <li id="understanding-this-policy">
                  Understanding this Policy
                  <ol>
                    <li>
                      It is important that you read your policy booklet together
                      with your schedule very carefully. Please read the whole
                      document. It is arranged in different sections.
                    </li>

                    <ol>
                      <li>
                        It is important that:
                        <ol>
                          <li>
                            You understand what each section covers and does not
                            cover
                          </li>
                          <li>
                            You understand your own duties under each section
                            and under the insurance as a whole
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <li>
                      Certain words have specific meanings wherever they appear
                      in this policy. These words are shown in bold and are
                      explained in the &#39;Definitions&#39; section.
                    </li>
                    <li>
                      In return for payment of the premium shown in the
                      schedule, we agree to insure you, subject to the terms and
                      conditions contained in or endorsed on this policy,
                      against certain financial loss you may sustain for:
                      <ol>
                        <li>
                          accidental, unforeseen physical loss or damage to the
                          ALL RISK ITEM occurring anywhere in the world, as
                          defined in the policy during the policy period shown
                          in the schedule.
                        </li>
                      </ol>
                    </li>

                    <li>
                      Cover is provided for each month for which your premium is
                      paid to your Insurer. Cover is provided for the month in
                      which the premium is paid. In order to have continuous
                      cover and a valid claim, you need to pay your premiums.
                    </li>
                    <li>
                      The insured value of your ALL RISK is contained in your
                      Schedule. This is the maximum value your Insurer will pay
                      in the event of a loss, less your first amount payable and
                      any dual insurance, betterment or depreciation.
                    </li>
                    <li>
                      Your Insurer retains the right to settle any claim on the
                      basis of the cost of repairs, replacement or cash
                      settlement at their sole option. In the event that the ALL
                      RISK ITEM:
                      <ol>
                        <li>
                          Can be repaired, your Insurer will pay the costs
                          reasonably incurred to restore it to its state of
                          serviceability immediately before the damage. Should
                          such costs exceed 60% of the value of the ALL RISK it
                          will be replaced.
                        </li>
                        <li>
                          Is replaced by your Insurer they agree to pay the
                          reasonable cost of replacement with a ALL RISK of the
                          same or similar type but not superior to or more
                          extensive than the existing ALL RISK.
                        </li>
                      </ol>
                    </li>
                    <li>
                      At all times you must take reasonable steps to safeguard
                      the ALL RISK from loss, damage or theft and any repairs to
                      the ALL RISK must be carried out by a repairer authorised
                      by Transact and Protect (Pty) Ltd (“TPI”).
                    </li>
                  </ol>
                </li>
                <li id="termination-of-this-policy">
                  Termination of this Policy
                  <ol>
                    <li>Termination by you</li>
                    <ol>
                      <li>
                        Cooling off Period{' '}
                        <TermsParagraph>
                          If you are a consumer you may cancel this insurance
                          within 30 days (cooling-off period) from the date you
                          receive your insurance policy documentation. You will
                          receive a full refund of any premium you have paid to
                          us provided that no claim has been made. We reserve
                          the right on refunding any premium paid to us if you
                          have made a claim under this policy.
                        </TermsParagraph>
                        <TermsParagraph>
                          To exercise your right to cancel, contact your
                          intermediary who arranged this cover for you. Please
                          be aware that your intermediary may charge a fee for
                          work completed on your behalf.
                        </TermsParagraph>
                      </li>
                      <li>
                        Cancellation
                        <TermsParagraph>
                          You can also cancel this insurance at any time by
                          writing to your intermediary. Any return premium due
                          to you will depend on how long this insurance has been
                          in force and whether you have made a claim. We reserve
                          the right on refunding any premium paid to us if you
                          have made a claim under this policy.
                        </TermsParagraph>
                      </li>
                    </ol>

                    <li>Termination by Us </li>
                    <ol>
                      <li>
                        Cancellation
                        <TermsParagraph>
                          We can cancel this insurance by giving you 30
                          days&#39; notice in writing to the address stated in
                          the Schedule or at the e-mail address stated in the
                          Schedule Any return premium due to you will depend on
                          how long this insurance has been in force and whether
                          you have made a claim. We reserve the right on
                          refunding any premium paid to us if you have made a
                          claim under this policy. If the premium payment is not
                          made within 15 days of the due date, we have the right
                          to terminate this insurance or refuse any claims.
                        </TermsParagraph>
                      </li>
                    </ol>
                  </ol>
                </li>
                <li id="conditions-applicable-whole-insurance">
                  General Conditions Applicable to the Whole Insurance Policy
                  <ol>
                    <li>Disclosure duties</li>
                    <ol>
                      <li>
                        You must ensure that the information provided to your
                        intermediary is correct and you have revealed all facts
                        which might affect our decision to accept this insurance
                        or any terms of this insurance.
                      </li>
                      <li>
                        You must tell your intermediary immediately if any of
                        the information which you have given us about you
                        changes. When we receive this notice, we have the option
                        to terminate this policy or change the conditions of
                        this insurance, but we will notify you of any changes.
                      </li>
                      <li>
                        If you fail to comply with any of the above duties the
                        insurance may become invalid. This Policy may be
                        voidable in the event you do not take reasonable care
                        not to make a misrepresentation of the risks which are
                        relevant as set out under the Introduction to Your
                        Insurance Policy section of this Policy, that is to say
                        that there has been a material misrepresentation,
                        mis-description or non-disclosure in such presentation
                      </li>
                      <li>
                        However, we agree not to void the Policy provided that:
                        <ol>
                          <li>
                            Such misrepresentation, mis-description or
                            non-disclosure has not been deliberate or reckless;
                          </li>
                          <li>
                            If the material particular giving rise to such
                            misrepresentation, mis-description or non-disclosure
                            had been accurately and correctly presented,
                            described or had been disclosed, we would have
                            entered into the Policy on the same or different
                            terms;
                          </li>
                          <li>
                            We shall be entitled to impose appropriate
                            additional terms (other than premium) with effect
                            from inception, or if applicable, the date of the
                            alteration;
                          </li>
                          <li>
                            If at the time of loss the premium charged to you
                            would have been higher but for the
                            misrepresentation, mis-description or non-disclosure
                            in any material particular, our liability for any
                            loss amount payable shall be limited to the
                            proportion that the premium charged bears to the
                            higher premium.
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <li>
                      Enforcing your rights
                      <ol>
                        <li>
                          We may, at our expense, take all necessary steps to
                          enforce your rights against any third party. We can do
                          this before or after we pay a claim. You must not do
                          anything before or after we pay your claim to affect
                          our rights and you must give us any help and
                          information we ask for. You must take reasonable steps
                          to make sure that you protect your rights to recover
                          amounts from third parties.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Reporting a Claim
                      <ol>
                        <TermsParagraph>Your Duties</TermsParagraph>
                        <TermsParagraph>
                          As soon as you know about any incident or circumstance
                          that may result in a claim under your policy, you
                          must:
                        </TermsParagraph>

                        <li>
                          {' '}
                          Take all reasonable steps and precautions to prevent
                          further loss covered by your policy;
                        </li>
                        <li>
                          Report your loss to your intermediary – refer to
                          policy disclosure notice for details providing full
                          details, as soon as practicable but in no event more
                          than 182 days after the date your loss is first
                          discovered by you
                        </li>
                        <li>
                          Co-operate with us fully and provide all the
                          information we need to investigate your claim
                        </li>
                        <li>
                          Where reasonably required by us to respond to a claim
                          under your policy, to provide us with a power of
                          attorney specifically for this purpose
                        </li>
                        <li>
                          Give us details of any other insurances you may have
                          which may cover part or all of the loss covered by
                          this policy
                        </li>
                        <li>
                          Demonstrate that you sought to recover financial loss
                          relating to your claim from a third party, bank or
                          financial institution that may be responsible for
                          refunding all or part of the loss
                        </li>
                        <li>
                          Advise us if you recover money from a third party,
                          bank, financial institution or credit provider in
                          relation to a claim made under the policy
                        </li>
                        <li>
                          If a claim has been paid under this policy you agree
                          to assign your rights to us to pursue a claim and/or
                          control any proceedings which are brought against a
                          third party to recoup all or some of the indemnity
                          paid
                        </li>
                        <li>
                          If a claim has been paid under this policy which you
                          subsequently recover from a third party, bank or
                          financial institution we are entitled to recover the
                          indemnity paid by us to the extent that your recovery
                          exceeds your uninsured losses
                        </li>
                      </ol>
                    </li>
                    <li>
                      Fraudulent Claims
                      <ol>
                        <TermsParagraph>If any:</TermsParagraph>
                        <li>
                          {' '}
                          Claim made under this policy by you or anyone acting
                          on your behalf is fraudulent or intentionally
                          exaggerated in any way{' '}
                        </li>
                        <li>False declaration or statement is made</li>
                        <li>Fraudulent device is used to support the claim</li>
                        <li>
                          Loss is caused by your wilful act or with your
                          connivance
                        </li>
                      </ol>
                      <TermsParagraph>Then we will:</TermsParagraph>
                      <ol>
                        <li>
                          Have no liability to pay any part of or the whole of
                          the fraudulent claim
                        </li>
                        <li>
                          Be entitled to refuse all claims arising after the
                          fraudulent action
                        </li>
                        <li>
                          Remain liable for legitimate claims before the
                          fraudulent action
                        </li>
                        <li>
                          Be entitled to terminate the policy from the date of
                          the fraudulent action whether or not the policy had
                          expired before the discovery of the fraud
                        </li>
                      </ol>
                    </li>
                    <li>
                      Claims Settlements
                      <TermsParagraph>
                        Your claims will be dealt with by Your Intermediary –
                        refer to policy disclosure notice for details
                      </TermsParagraph>
                    </li>
                    <li>Data Protection Act</li>
                    <ol>
                      <li>
                        <TermsParagraph>
                          Any information disclosed to us will be treated in
                          strict confidence and in compliance with The
                          Protection of Personal Information Act, No 4 of 2013.
                          By accepting your insurance policy, you consent to
                          Mutual and Federal Risk Finance or Transact and
                          Protect Insure, using the information we may hold
                          about you for the purposes of providing insurance and
                          handling claims, if any, and to process sensitive
                          personal data about you where this is necessary (for
                          example criminal convictions). This may mean we have
                          to give some details to third parties involved in
                          providing insurance cover. These may include insurance
                          carriers, third party claims adjusters, fraud
                          detection and prevention services, reinsurance
                          companies and insurance regulatory authorities. Where
                          such sensitive personal information relates to anyone
                          other than you, you must obtain the explicit consent
                          of the person to whom the information relates to the
                          disclosure of such information to us and its use by us
                          as set out above. You have the right to apply for a
                          copy of your information (for which we may charge a
                          small fee) and have any inaccuracies corrected.
                        </TermsParagraph>
                      </li>
                    </ol>
                    <li>Contracts (Rights of Third Parties)</li>
                    <ol>
                      <li>
                        <TermsParagraph>
                          A person who is not a party to this insurance has no
                          right under the Contracts (to enforce any term of this
                          insurance.)
                        </TermsParagraph>
                      </li>
                    </ol>
                  </ol>
                </li>
                <li id="scope-of-cover">
                  Scope of Cover- ALL RISK
                  <ol>
                    <li>
                      What we cover you for
                      <ol>
                        <li>
                          All Unspecified All Risk items are collectively
                          referred to as All Risk in terms of this policy. In
                          return for paying your premium, we will cover your ALL
                          RISK as detailed in your Schedule for accidental,
                          unforeseen physical loss or damage to the ALL RISK
                          occurring anywhere in the world.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Exclusions - What is not covered
                      <ol>
                        <li>
                          The costs of replacing, reinstating or making good
                          wear and tear, gradual deterioration, scratching or
                          other superficial damage to outer casings, aerials or
                          keypads.
                        </li>
                        <li>
                          Any loss or damage during the hire or loan of your ALL
                          RISK to another person.
                        </li>
                        <li>
                          Loss of or damage to your ALL RISK resulting from
                          theft or any attempt thereat from any unattended
                          vehicle, unless the vehicle is locked and the ALL RISK
                          is in a concealed compartment or boot. Any such loss
                          must involve forcible and violent entry into the
                          vehicle.
                        </li>
                        <li>
                          {' '}
                          Loss or damage to batteries, other than when they are
                          stolen or lost together with your ALL RISK.{' '}
                        </li>
                        <li>Consequential loss of any kind whatsoever.</li>
                        <li>Loss of or damage to accessories.</li>
                        <li>
                          Loss of or damage to your ALL RISK arising from or
                          contributed to by gross negligence or wilful conduct
                          by you.
                        </li>
                        <li>
                          Loss or damage arising from a manufacturers defect.
                        </li>
                        <li>
                          Loss or damage resulting from political or
                          non-political riot, strike or civil commotion, public
                          disorder, war, invasion, terrorism or public violence
                          or which is insurable by SASRIA (South African Special
                          Risks Insurance Association) is excluded from this
                          cover.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li id="scope-of-cover-home-contents">
                  Scope of Cover - HOME CONTENTS
                  <ol>
                    <li>
                      This section covers the contents of your home which
                      includes the following:
                      <ol>
                        <li>
                          Household contents (belonging to you or for which you
                          are responsible)
                        </li>
                        <li>
                          Personal property (including office and home- industry
                          equipment belonging to you in your private capacity)
                        </li>
                        <li>
                          Fixtures and fittings that belong to you as the
                          tenant, not the owner, of the private residence.
                        </li>
                      </ol>
                    </li>
                    <li>
                      What we cover you for
                      <ol>
                        <li>
                          accidental, unforeseen physical loss or damage to your
                          Home Contents occurring at your place of residence
                        </li>
                        <li>fire, lightning and explosion;</li>
                        <li>
                          storm, wind, water, hail or snow. We will not cover
                          the following:
                          <ol>
                            <li>
                              loss or damage caused by any process which uses or
                              applies water;
                            </li>
                            <li>loss or damage caused by wear and tear;</li>
                            <li>
                              loss or damage caused by gradual deterioration;
                            </li>
                            <li>
                              loss or damage caused by mildew, rust or
                              corrosion.
                            </li>
                          </ol>
                        </li>
                        <li>earthquake;</li>
                        <li>
                          impact with the private residence by animals,
                          vehicles, aircraft or aerial devices or other objects
                          falling from them, or falling trees, except when
                          felled by someone;
                        </li>
                        <li>
                          collapse or breakage of aerial systems and satellite
                          dishes;
                        </li>
                        <li>leakage of oil from oil heaters;</li>
                        <li>
                          malicious damage, but we do not cover malicious damage
                          while your private residence is lent, let or sublet to
                          a tenant.
                        </li>
                        <li>
                          Debris removal in relation to an insured event
                          (limited to R 5 000 per insured event)
                        </li>
                        <li>
                          Fire extinguishing charges in relation to an insured
                          event (limited to R 5 000 per insured event)
                        </li>
                      </ol>
                    </li>
                    <li>
                      Terms and Conditions
                      <ol>
                        <li>
                          Insured amount, basis of indemnity and limit of
                          compensation
                          <ol>
                            <li>
                              The insured amount for the property insured, as
                              shown in the Schedule, must throughout the period
                              of this policy represent the current replacement
                              value of similar new property. The basis of
                              indemnity for the loss of or damage to the insured
                              property, or part of it, will be the current
                              replacement value of similar new property, limited
                              to the insured amount as shown in the Schedule.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Inflation Protection
                          <ol>
                            <li>
                              The insured amount for the property insured under
                              Section I will be increased each month to cater
                              for the effect of inflation, according to the
                              percentage we apply at the renewal date. However,
                              the Schedule will not reflect this monthly
                              increase. No extra premium will be charged during
                              the period of the policy, but the premium will be
                              recalculated each year on the renewal date, as
                              shown in the Schedule.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Average
                          <ol>
                            <li>
                              <TermsParagraph>
                                If, according to our calculations, the amount
                                needed to replace all your private residential
                                structures with similar new structures at the
                                time of any loss or damage, is more than the
                                insured amount, we will not pay you the full
                                amount of the loss or damage. You will be your
                                own insurer for the difference between the
                                insured amount and the amount needed to replace
                                all the private residential structures.
                                Therefore, you will be responsible for a
                                proportional share of the loss or damage.{' '}
                              </TermsParagraph>
                            </li>
                            <li>
                              <TermsParagraph>
                                Example: You allocated a sum insured of R
                                500 000 to your property insured. The actual
                                replacement value of your property is R 1000
                                000. You have a claim in term of this policy to
                                the amount of R 100 000. The claim will be
                                settled as follow:
                              </TermsParagraph>
                              <div>
                                R 500 000 ( insured value selected) &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; R 100 000
                              </div>

                              <div>
                                &nbsp;
                                ---------------------------------------------------------------
                                &nbsp; &nbsp; X &nbsp;&nbsp;
                                --------------------&nbsp; = &nbsp;R 50 000
                              </div>

                              <div>
                                R 1 000 000 (actual replacement value) &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; 1
                              </div>
                            </li>
                          </ol>
                        </li>
                        <li>
                          Security measures
                          <ol>
                            Burglar bars
                            <TermsParagraph>
                              {' '}
                              If we require burglar bars, as described in the
                              Schedule, we will compensate you for theft or
                              burglary only if:
                            </TermsParagraph>
                            <li>the required burglar bars are fitted;</li>
                            <li>
                              the required burglar bars have not been removed
                              without our permission.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Security gates
                          <ol>
                            <TermsParagraph>
                              {' '}
                              If we require security gates, as described in the
                              Schedule, we will compensate you for theft or
                              Burglary only if:
                            </TermsParagraph>
                            <li>the required security gates are fitted;</li>
                            <li>
                              the required security gates are locked when you or
                              any person you have authorised to look after your
                              private residence leaves your private residence
                              unattended;
                            </li>
                            <li>
                              the required security gates have not been removed
                              without our permission;
                            </li>
                          </ol>
                        </li>
                        <li>
                          Alarm systems
                          <ol>
                            <TermsParagraph>
                              If we require an alarm system, as described in the
                              Schedule, we will compensate you for theft and
                              burglary only if:
                            </TermsParagraph>
                            <li>the required alarm system is installed;</li>
                            <li>
                              the required alarm system is in working order;
                            </li>
                            <li>
                              none of the “passive infrared motion detectors” of
                              the required alarm system are obstructed or
                              bypassed;
                            </li>
                            <li>
                              your private residence and your outbuildings are
                              left unattended and the required alarm system has
                              been set by you or any person you have authorised
                              to look after your private residence and
                              outbuildings, or your private residence, but not
                              your outbuildings, is left unattended and the
                              required alarm system has been set by you or any
                              person you have authorised to look after your
                              private residence; and
                            </li>
                            <li>
                              the required alarm system has not been removed
                              without our permission.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Perimeter security
                          <ol>
                            <TermsParagraph>
                              If we require perimeter security, as described in
                              the Schedule, we will compensate you for theft and
                              burglary only if:{' '}
                            </TermsParagraph>
                            <li>
                              the required perimeter security is maintained and
                              kept in working condition;
                            </li>
                            <li>
                              the required perimeter security has not been
                              altered or removed without our permission.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <TermsParagraph>What we do not cover</TermsParagraph>
                      <TermsParagraph>
                        The following are not covered, unless specifically shown
                        otherwise in the Schedule:
                      </TermsParagraph>
                      <ol>
                        <li>property that is more specifically insured,</li>
                        <li>any other policy,</li>
                        <li>
                          loss or damage arising from claims occurring outside
                          the countries set out in this policy;
                        </li>
                        <li>
                          property, whether it is processed or not, obtained
                          with the purpose of disposing of it in a business
                          transaction;
                        </li>
                        <li>
                          money, securities for money, deeds, bonds, bills of
                          exchange, promissory notes, negotiable and other
                          documents, stamps, manuscripts, rare books, medals and
                          coins;
                        </li>
                        <li>
                          vehicles, watercraft (excluding surfboards, kite
                          boards, paddle skis, kayaks, canoes, surf skis,
                          windsurf boards, sailboards and model boats),
                          aircraft, other aerial devices (excluding model
                          aircraft), and all tools, spare parts and accessories
                          of these vehicles, aircraft or watercraft that are on,
                          in or attached to it;
                        </li>
                        <li>animals;</li>
                        <li>
                          loss or damage from or relating to any exchange, cash
                          or credit sale agreement, including theft under false
                          pretence and fraud;
                        </li>
                        <li>
                          loss or damage to property in the open caused by
                          storm, water, wind, hail or snow unless the insured
                          property is designed to exist in the open;
                        </li>
                        <li>
                          the cost of reproduction or repair of data of any
                          kind;
                        </li>
                        <li>
                          theft or attempted theft while your private residence
                          is lent, let or sublet to a tenant;
                        </li>
                        <li>
                          loss, damage or breakage covered by a
                          manufacturer&#39;s purchase agreement, guarantee or
                          service contract.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </TermsContainer>
          </Col>
        </Row>
      </Section>
      <Footer />
    </>
  );
}
