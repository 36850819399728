import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { getEvent } from 'interface/inthecity/quicket';
import {
  Breadcrumbs,
  EventDetails,
  MobileEventDetails,
  LocationMap,
} from 'components/inthecity';
import { Body, Title } from 'components';
import {
  TopRow,
} from './placesOfInterest.styled';
import {
  Header,
  ContentRow,
  MainColumn,
  DetailsColumn,
  Image,
  Line,
} from './event.styled';

/**
 * Detailed Event View
 */
const EventPage = () => {
  const navigate = useNavigate();
  const event = useLocation()?.state?.event;
  const [tempEvent, setTempEvent] = useState({
    id: '',
    name: '',
    description: '',
    url: '',
    imageUrl: '',
    startDate: '',
    endDate: '',
    tickets: '',
    venue: '',
    dateCreated: '',
    lastModified: '',
    locality: '',
    organiser: '',
    categories: '',
  });
  if (event && tempEvent.id === '') {
    setTempEvent(event);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!event) {
        navigate('/in-the-city/whats-on-in-your-city');
      }
      if (event && event.ProductName) {
        // Go and get the event from quicket.
        const eventsRes = await getEvent(event.objectID);
        setTempEvent(eventsRes);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <TopRow>
        <Breadcrumbs />
      </TopRow>

      <ContentRow>
        <MainColumn>
          {/* Details section with title */}
          <MobileEventDetails
            event={tempEvent}
          />

          <Header>
            {tempEvent.name}
          </Header>

          <Image src={`https:${tempEvent.imageUrl}`} />

          <Line />

          <Title style={{ marginBottom: '17px' }}>About</Title>
          <Body style={{ wordBreak: 'break-word' }}>
            {tempEvent.description ? parse(tempEvent.description) : 'No description supplied'}
          </Body>

          <Line />

          {tempEvent.venue && (
            <>
              <Title style={{ marginBottom: '17px' }}>Location</Title>
              <LocationMap position={[tempEvent.venue.latitude, tempEvent.venue.longitude]} />
              <Line />
            </>
          )}

        </MainColumn>

        <DetailsColumn>
          <EventDetails
            event={tempEvent}
          />
        </DetailsColumn>
      </ContentRow>
    </>
  );
};

export default EventPage;
