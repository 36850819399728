import { MunicipalPageWrapper } from 'components/my-municipality';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GMVacancy } from 'types/types';
import { Text } from 'components/typography';
import { Box, Flex, Grid } from 'components/layout';
import theme from 'theme';
import { Loader } from 'components/inthecity';
import { Button, TextField } from 'components/elements';
import { SmallLoader } from 'components/private-services/survey';
import ExternalLink from 'components/elements/external-link';
import { capitalize } from '@material-ui/core';

const getDocumentLinks = (vacancy: GMVacancy) => {
  const documentLinks: { fieldName: string; documentLink: string }[] = [];
  Object.entries(vacancy['toolset-meta']['human-resources']).forEach(
    ([fieldName, fieldData]) => {
      if (fieldData.type === 'file' && fieldData.raw) {
        const documentLink = fieldData.raw;
        documentLinks.push({ fieldName, documentLink });
      }
    }
  );
  return documentLinks;
};

const VacancyItem = ({ vacancy }: { vacancy: GMVacancy }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Flex boxShadow={theme.elevationShadow} p="16px" flexDirection="column">
      <Grid gridTemplateColumns="1fr 100px" width="100%" gridColumnGap="10px">
        <Text>
          {vacancy.title.rendered
            .replace('\\', '')
            .replace('&#8211;', '-')
            .replace('&amp;', '&')}
        </Text>
        <Button
          color={theme.colors.primary}
          fontWeight={600}
          my="auto"
          onClick={() => setShowMore((s) => !s)}
        >
          {showMore ? 'Show less' : 'Show more'}
        </Button>
      </Grid>
      {showMore && (
        <Grid gridRowGap="10px" width="100%">
          <ExternalLink
            href={vacancy.link}
            target="__blank"
            color={theme.colors.primary}
            textDecoration="underline"
          >
            View on george.gov.za
          </ExternalLink>
          <Text fontWeight="bold">Details:</Text>
          {vacancy['toolset-meta']['human-resources']['enquiries-hr'].raw && (
            <Text>
              Enquiries:{' '}
              {vacancy['toolset-meta']['human-resources']['enquiries-hr'].raw}
            </Text>
          )}
          {vacancy['toolset-meta']['human-resources']['salary-scale'].raw && (
            <Text>
              Salary Scale:{' '}
              {vacancy['toolset-meta']['human-resources']['salary-scale'].raw}
            </Text>
          )}
          {vacancy['toolset-meta']['human-resources']['closing-date-hr']
            .formatted && (
            <Text>
              Closing date:{' '}
              {
                vacancy['toolset-meta']['human-resources']['closing-date-hr']
                  .formatted
              }
            </Text>
          )}
          <Text fontWeight="bold">Document Downloads:</Text>
          {getDocumentLinks(vacancy).map(({ fieldName, documentLink }) => (
            <ExternalLink
              href={documentLink}
              target="__blank"
              color={theme.colors.primary}
              textDecoration="underline"
            >
              <Flex alignItems="center">
                <Box
                  mr="15px"
                  backgroundColor={theme.colors.primary}
                  borderRadius="50%"
                  p="2px"
                >
                  <img
                    className="profileNavDrop"
                    src="profile/download.svg"
                    alt="Logo"
                    style={{ height: '20px', width: '20px' }}
                  />
                </Box>
                <Text>{capitalize(fieldName.split('-').join(' '))}</Text>
              </Flex>
            </ExternalLink>
          ))}
        </Grid>
      )}
    </Flex>
  );
};

const VacanciesPage = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [vacancies, setVacancies] = useState<GMVacancy[]>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const getVacancies = useCallback(async (pageNumber: number) => {
    if (pageNumber === 1) {
      setLoading(true);
    } else {
      !loading && setLoadingMore(true);
    }
    setPage(pageNumber + 1);
    fetch(
      `https://www.george.gov.za/wp-json/wp/v2/human-resources-docs?filter[category_name]=current-job-opportunities&per_page=10&page=${pageNumber}&orderby=modified`
    )
      .then((res) => res.json())
      .then((data: GMVacancy[]) => {
        setVacancies((v) => [...v, ...data]);
        if (pageNumber === 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (pageNumber === 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
      });
  }, []);

  useEffect(() => {
    getVacancies(page);
  }, []);

  const vacanciesToDisplay = useMemo(
    () =>
      search === ''
        ? vacancies
        : vacancies.filter((vacancy) =>
            vacancy.title.rendered.toLowerCase().includes(search.toLowerCase())
          ),
    [search, vacancies]
  );

  return (
    <MunicipalPageWrapper title="Vacancies">
      {loading ? (
        <Loader />
      ) : (
        <>
          <TextField
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            mb="20px"
          />
          <Grid gridRowGap="20px">
            {vacanciesToDisplay.length > 0 ? (
              vacanciesToDisplay.map((vacancy) => (
                <VacancyItem
                  key={`${vacancy.id}-${vacancy.date}`}
                  vacancy={vacancy}
                />
              ))
            ) : (
              <Text textAlign="center">
                No results on this page, please load more to view more results
              </Text>
            )}
          </Grid>
        </>
      )}
      <Flex width="100%" justifyContent="center" mt="20px">
        {loadingMore ? (
          <SmallLoader />
        ) : (
          <Button onClick={() => getVacancies(page)} variant="cta" px="16px">
            Load more
          </Button>
        )}
      </Flex>
    </MunicipalPageWrapper>
  );
};

export default VacanciesPage;
