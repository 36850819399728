/** @jsxImportSource @emotion/react */
import { Box, Flex, Grid } from 'components/layout';
import theme from 'theme';
import {
  SmartCityServiceBooking,
  DomesticCleaningGroups,
  GardeningGroups,
} from 'types/types';
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { PrivateServicesEvents, logFirebaseEvent } from 'functions/firebase';
import ExtrasWidget from './widgets/extras';
import ServiceQuantityWidget from './widgets/quantity-selector';
import SummaryWidget from './widgets/summary';
import ResourceSelectorWidget from './widgets/resource-selector';
import SchedulingModal from '../scheduling-modal';
import {
  LATEST_WORKING_HOUR,
  earliestSchedule,
} from '../../../scheduling-assistant/utils';

const ServiceDetails = ({
  booking,
  price,
  setServiceOptionCurrent,
  duration,
  setBookingDuration,
  setResource,
  continueBooking,
  setBookingTimes,
  scheduleModalOpen,
  setScheduleModalOpen,
}: {
  booking: SmartCityServiceBooking;
  price: number;
  setServiceOptionCurrent: (
    group: string,
    serviceLabel: string,
    current: number,
    renderIdx: number
  ) => void;
  duration: number;
  setBookingDuration: (duration: number) => void;
  setResource: (id: number, name: string, profileUrl: string) => void;
  continueBooking: () => void;
  setBookingTimes: (time: Date) => void;
  scheduleModalOpen: boolean;
  setScheduleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [appointmentStart, setAppointmentStart] = useState<Date>(
    booking?.bookingInformation?.startDateTime &&
      new Date(booking.bookingInformation.startDateTime * 1000).getHours() !== 0
      ? new Date(booking.bookingInformation.startDateTime * 1000)
      : earliestSchedule()
  );

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (
      !(
        LATEST_WORKING_HOUR -
          (booking.bookingInformation?.typeId === 27147 ? 1 : 0) -
          appointmentStart.getHours() >=
          duration && appointmentStart.getHours() !== 0
      )
    ) {
      setError('The duration of the service exceeds the working hours.');
    } else if (
      !booking.bookingInformation?.longitude ||
      !booking.bookingInformation?.latitude
    ) {
      setError('Please select a valid location');
    } else if (!booking.bookingInformation.resourceId) {
      setError('Please select a service provider');
    } else {
      setError(null);
    }
  }, [booking, appointmentStart]);

  useEffect(() => {
    setBookingTimes(appointmentStart);
  }, [appointmentStart]);

  return (
    <>
      <SchedulingModal
        currentDate={
          booking.bookingInformation?.startDateTime
            ? new Date(booking.bookingInformation.startDateTime * 1000)
            : undefined
        }
        setAppointmentStart={setAppointmentStart}
        includeLunch={booking.bookingInformation?.typeId === 27147}
        duration={duration}
        isOpen={scheduleModalOpen}
        confirmationCallback={() => setScheduleModalOpen(false)}
      />
      <Flex
        justifyContent="center"
        width="100%"
        py="50px"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            padding: 10px 0;
            height: 100%;
          }
        `}
      >
        <Grid
          gridColumnGap="70px"
          gridTemplateColumns="1fr 1fr"
          width="100%"
          maxWidth="900px"
          mx="30px"
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
          `}
        >
          <Box>
            <ServiceQuantityWidget
              setQuantityCurrent={(
                group: string,
                hour: string,
                current: number,
                renderIdx: number
              ) => setServiceOptionCurrent(group, hour, current, renderIdx)}
              serviceGroups={booking.smartCityServiceGroups
                .filter(
                  (group) =>
                    group.heading === DomesticCleaningGroups.ROOMS ||
                    group.heading === GardeningGroups.HOURS
                )
                .shift()}
              duration={duration}
              setBookingDuration={setBookingDuration}
            />
            <ExtrasWidget
              setExtraCurrent={(
                extra: string,
                current: number,
                renderIdx: number
              ) =>
                setServiceOptionCurrent(
                  DomesticCleaningGroups.EXTRAS,
                  extra,
                  current,
                  renderIdx
                )
              }
              serviceGroups={booking.smartCityServiceGroups
                .filter(
                  (group) => group.heading === DomesticCleaningGroups.EXTRAS
                )
                .shift()}
              shouldRender={
                !!booking.smartCityServiceGroups.filter(
                  (group) => group.heading === DomesticCleaningGroups.EXTRAS
                )
              }
              duration={duration}
              setBookingDuration={setBookingDuration}
            />
            <ResourceSelectorWidget
              setResource={setResource}
              bookingInformation={booking.bookingInformation}
            />
            <Box height="250px" hideForTablet hideForDesktop />
          </Box>
          <Box hideForMobile>
            <SummaryWidget
              appointmentStart={appointmentStart}
              error={error}
              includeLunch={booking.bookingInformation?.typeId === 27147}
              price={price}
              duration={duration}
              openScheduleModal={() => setScheduleModalOpen(true)}
              continueBooking={() => {
                logFirebaseEvent(
                  booking.bookingInformation?.typeId === 27147
                    ? PrivateServicesEvents.CheckoutDomestic
                    : PrivateServicesEvents.CheckoutGardener
                );
                continueBooking();
              }}
            />
          </Box>
        </Grid>
        <Box hideForDesktop hideForTablet>
          <SummaryWidget
            appointmentStart={appointmentStart}
            error={error}
            includeLunch={booking.bookingInformation?.typeId === 27147}
            price={price}
            duration={duration}
            openScheduleModal={() => setScheduleModalOpen(true)}
            continueBooking={() => {
              logFirebaseEvent(
                booking.bookingInformation?.typeId === 27147
                  ? PrivateServicesEvents.CheckoutDomestic
                  : PrivateServicesEvents.CheckoutGardener
              );
              continueBooking();
            }}
          />
        </Box>
      </Flex>
    </>
  );
};

export default ServiceDetails;
