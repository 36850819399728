import { css } from '@emotion/react';
import { Box, Flex } from 'components/layout';
import { Image } from 'components/elements';
import { Text } from 'components/typography';
import theme from 'theme';

type progressBarState = 'inactive' | 'active' | 'done';

const Node = ({
  state,
  isLast,
  index,
  label,
}: {
  state: progressBarState;
  isLast: boolean;
  index: number;
  label: string;
}) => (
  <Box width={isLast ? 'fit-content' : '100%'} position="relative">
    <Flex py="10px" alignItems="center" justifyContent="flex-start">
      <Box
        backgroundColor={
          state === 'active' || state === 'done'
            ? theme.colors.primary
            : theme.colors.borderGrey
        }
        minWidth="26px"
        height="26px"
        borderRadius="50%"
      >
        {state === 'done' ? (
          <Image
            source="privateServices/tick.png"
            alt="tick"
            width="100%"
            height="100%"
          />
        ) : (
          <Text color="white" mt="15% !important" textAlign="center">
            {(index + 1).toString()}
          </Text>
        )}
      </Box>

      {!isLast && (
        <Flex
          alignContent="center"
          minWidth="190px"
          width="100%"
          height="3px"
          backgroundColor={
            state === 'done' ? theme.colors.primary : theme.colors.borderGrey
          }
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              min-width: 10px;
              width: 100%;
            }
          `}
        />
      )}
    </Flex>
    <Text
      position="absolute"
      color={theme.colors.lightGrey}
      top="87%"
      fontSize="12px"
      overrideCss={css`
        transform: translateX(calc(13px - 50%));
      `}
    >
      {label}
    </Text>
  </Box>
);

const ProgressBar = ({ items, step }: { items: string[]; step: number }) => (
  <Flex justifyContent="center" width="100%">
    <Flex
      maxWidth="500px"
      px="30px"
      pb="25px"
      width="100%"
      justifyContent="center"
    >
      {items.map((item, index) => (
        <Node
          key={index}
          isLast={index === items.length - 1}
          index={index}
          label={item}
          state={
            step < index
              ? 'inactive'
              : step === index
              ? 'active'
              : step > index
              ? 'done'
              : 'inactive'
          }
        />
      ))}
    </Flex>
  </Flex>
);

export default ProgressBar;
