import AccountNavigator from 'components/account-navigator';
import AddressManager from 'components/address-manager';
import { Flex } from 'components/layout';

const FavouriteLocationsPage = () => (
  <AccountNavigator>
    {' '}
    <Flex p="20px" width="100%">
      <AddressManager showCloseButton />
    </Flex>
  </AccountNavigator>
);

export default FavouriteLocationsPage;
