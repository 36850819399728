import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { useGlobalContext } from 'contexts/global';
import { logFirebaseEvent, HomePageEvents } from 'functions/firebase';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';
import { PageLinksEnum } from 'types/routing';

const LogAReportSection = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { setShowLoginModal } = useGlobalContext();
  const navigate = useNavigate();

  return (
    <>
      <Text
        textAlign="center"
        fontSize="40px"
        pt="15px"
        mb="10px"
        mx="10%"
        fontWeight="bold"
        borderT={theme.borders.standard1px}
        mobileCss={css`
          font-size: 30px;
        `}
      >
        Log a Report
      </Text>
      <Text
        textAlign="center"
        fontSize="24px"
        mb="20px"
        mx="5%"
        mobileCss={css`
          font-size: 18px;
        `}
      >
        Log all your municipal concerns. We help you keep track of the report
        from creation to resolution.
      </Text>
      <Grid
        gridTemplateColumns="3fr 2fr"
        pb="70px"
        pr="7%"
        mobileCss={css`
          margin-top: 20px;
          padding: 10px;
          display: flex;
          flex-direction: column;
        `}
        tabletCss={css`
          margin-top: 20px;
          padding: 10px 10%;
          display: flex;
          flex-direction: column;
        `}
      >
        <Flex width="100%">
          <Image
            source="new-homepage/log-report-image.png"
            alt="Log a Report"
            maxWidth="100%"
            objectFit="cover"
          />
        </Flex>
        <Box
          mt="20%"
          mobileCss={css`
            margin-top: 20px;
            padding: 0 20px;
          `}
          tabletCss={css`
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 0 20px;
          `}
        >
          <Text
            fontSize="30px"
            mobileCss={css`
              font-size: 24px;
            `}
          >
            Report Concerns with your Local Municipality and Track the
            Resolution.
          </Text>
          <Text
            mt="20px"
            fontSize="18px"
            mobileCss={css`
              font-size: 14px;
              margin-top: 10px;
            `}
          >
            My Smart City connects you to your local municipality like never
            before. Potholes, water outages, power outages and more. Simply log
            these issues, and we will keep you posted on the progress and
            resolution.
          </Text>
          <Button
            onClick={() => {
              if (!isLoggedIn) {
                setShowLoginModal(true);
              } else {
                navigate(PageLinksEnum.Map);
              }
              logFirebaseEvent(HomePageEvents.LogReport);
            }}
            mt="20px"
            variant="filled"
            color="white"
            fontWeight="bold"
            fontSize="20px"
            width="100%"
            overrideCss={css`
              transition: transform 0.2s ease-in-out;
              &:hover {
                transform: scale(1.04);
              }
            `}
          >
            Log a Report
          </Button>
          {/* App download buttons */}
          {/* <Flex justifyContent="center" flexDirection="column" mt="20px">
            <Text pb="20px" fontSize="18px">
              Logging a report is even easier using our mobile app. Download
              now!
            </Text>
            <Flex alignContent="center">
              <Link
                href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
                target="_blank"
              >
                <Image
                  p="0px 10px 0px 10px"
                  source="/footer/playStore.svg"
                  alt="playstore"
                  width="100%"
                  hoverCss={css`
                    transform: scale(1.1);
                    transform-origin: center;
                  `}
                />
              </Link>
              <Link
                href="https://apps.apple.com/app/mysmart-city/id1555236570"
                target="_blank"
              >
                <Image
                  mobileCss={css`
                    margin-right: 10px;
                  `}
                  source="/footer/appStore.svg"
                  alt="appstore"
                  width="100%"
                  hoverCss={css`
                    transform: scale(1.1);
                    transform-origin: center;
                  `}
                />
              </Link>
            </Flex>
          </Flex> */}
        </Box>
      </Grid>
    </>
  );
};

export default LogAReportSection;
