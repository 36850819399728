import { css } from '@emotion/react';
import { Button, Image, TextField } from 'components/elements';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import usePollApi from 'hooks/use-poll-api';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { ResourceChatMessage } from 'types/types';

const ChatMessage = ({ message }: { message: ResourceChatMessage }) => (
  <Flex
    width="100%"
    mb="10px"
    justifyContent={
      message.originator === 'Resource' ? 'flex-start' : 'flex-end'
    }
  >
    <Flex
      maxWidth="80%"
      p="7px"
      overrideCss={css`
        border-radius: ${message.originator !== 'Resource'
          ? '10px 10px 0 10px'
          : '10px 10px 10px 0'};
        background-color: ${message.originator === 'Resource'
          ? 'rgb(177, 221, 241)'
          : theme.colors.backgroundGrey};
      `}
    >
      <Text>{message.text}</Text>
    </Flex>
  </Flex>
);

const Chat = ({
  resourceId,
  bookingId,
  close,
  resourceName,
}: {
  resourceId: number;
  bookingId: number;
  close: () => void;
  resourceName?: string;
}) => {
  const [chatMessages, setChatMessages] = useState<ResourceChatMessage[]>([]);
  const [opacity, setOpacity] = useState(0);
  const [activeMessage, setActiveMessage] = useState('');

  const {
    // loading,
    // error,
    response: chatHistory,
  } = usePollApi(SCManagerEndpoints.GetResourceChat, 2000);

  const {
    loading: sending,
    //  response: messageStatus,
    callApi: sendMessage,
  } = useSmartCityManager(SCManagerEndpoints.SendMessageToResource);

  useEffect(() => {
    setOpacity(1);
  }, []);

  const handleSendMessage = (text: string) => {
    if (text !== '') {
      sendMessage({
        body: {
          bookingId,
          resourceId,
          text,
        },
      });
      setActiveMessage('');
    }
  };

  const handleClose = () => {
    setOpacity(0);
    setTimeout(() => {
      close();
    }, 200);
  };

  useEffect(() => {
    if (chatHistory && chatHistory.length !== chatMessages.length) {
      setChatMessages(chatHistory);
    }
  }, [chatHistory]);

  return (
    <Box
      position="fixed"
      bottom="60px"
      right="60px"
      borderRadius={theme.borderRadius.medium}
      zIndex={100002}
      minWidth="400px"
      minHeight="500px"
      opacity={opacity.toString()}
      overrideCss={css`
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        transition: opacity 0.2s ease-in-out;
        @media ${theme.mediaQueries.mobileOnly} {
          min-width: 100%;
          min-height: unset;
          bottom: 0;
          right: 0;
        }
      `}
    >
      <Flex
        backgroundColor={theme.colors.primary}
        justifyContent="space-between"
        p="10px"
        overrideCss={css`
          border-radius: 10px 10px 0 0;
        `}
      >
        <Text color="white" fontWeight="bold" fontSize="17px">
          {`Chat with ${resourceName || 'Service provider'}`}
        </Text>
        <Button onClick={handleClose}>
          <Image source="icons/modal_close.svg" height="25px" alt="close" />
        </Button>
      </Flex>
      <Flex
        backgroundColor="rgba(255, 255, 255, 0.9)"
        flexDirection="column"
        p="20px"
        height="100%"
        minHeight="500px"
        maxHeight="400px"
        overflowY="scroll"
        hideScrollBar
        overrideCss={css`
          backdrop-filter: blur(10px);
          @media ${theme.mediaQueries.mobileOnly} {
            background-color: white;
            max-width: 100%;
            height: 300px;
          }
        `}
      >
        {chatMessages.length > 0 &&
          chatMessages.map((message, index) => (
            <ChatMessage key={index} message={message} />
          ))}
      </Flex>
      <Flex
        backgroundColor="white"
        p="10px"
        borderRadius="0 0 10px 10px"
        width="100%"
      >
        <TextField
          onChange={(event) => setActiveMessage(event.target.value)}
          placeholder="Type message..."
          width="100%"
          value={activeMessage}
          disabled={sending}
        />
        <Button
          onClick={() => handleSendMessage(activeMessage)}
          disabled={sending}
          ml="10px"
          px="10px"
        >
          {sending ? (
            <Text color={theme.colors.lightGrey}>Sending...</Text>
          ) : (
            <Text color={theme.colors.primary} fontWeight="bold">
              Send
            </Text>
          )}
        </Button>
      </Flex>
    </Box>
  );
};

const ResourceChat = ({
  resourceId,
  bookingId,
  resourceName,
  onClose,
  isOpen,
}: {
  resourceId: number;
  bookingId: number;
  resourceName?: string;
  onClose?: () => void;
  isOpen?: boolean;
}) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    setIsChatOpen(true);
  }, [bookingId]);

  useEffect(() => {
    if (isChatOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isChatOpen]);

  if (typeof isOpen === 'boolean' && isOpen === false) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => setIsChatOpen(!isChatOpen)}
        position="fixed"
        bottom="20px"
        right="20px"
        boxShadow={theme.elevationShadow}
        borderRadius="50%"
        p="6px"
        backgroundColor={theme.colors.primary}
        zIndex={100}
        hideForMobile
      >
        <Image source="resource-banner-assets/chaticon.png" alt="chatIcon" />
      </Button>
      {isChatOpen && (
        <Chat
          resourceId={resourceId}
          bookingId={bookingId}
          resourceName={resourceName}
          close={() => {
            setIsChatOpen(false);
            onClose && onClose();
          }}
        />
      )}
    </>
  );
};

export default ResourceChat;
