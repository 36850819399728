/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash.debounce';

import { ActionBar } from 'components';
import CreatePostMenu from 'components/social/createpostmenu';
import { getNewsFeedWorkRequests } from 'interface/social';
import { useSocialContext } from 'contexts/social';
import { useGlobalContext } from 'contexts/global';
import {
  Post,
  CreatePost,
  Filter,
  Breadcrumbs,
  Alerts,
  Search,
} from 'components/social';
import { SuggestionList } from 'components/social/connection';
import { Heading } from 'components/social/text';
import { Loader } from 'components/social/createpost';
import { FilterMenu } from 'components/social/filter';
import {
  NotFoundContainer,
  ContentRow,
  TopRow,
  PageContainer,
  FilterColumn,
  FeedColumn,
  ConnectionsColumn,
  FeedHeader,
  Feed,
} from './social.styled';
import { Title, TextLink } from './connections.styled';

const PAGE_SIZE = 10;

const SocialPage = () => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [createPostType, setCreatePostType] = useState(null);
  const [updateFeed, setUpdateFeed] = useState(false);

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [meta, setMeta] = useState({
    currentPage: 0,
    total: null,
    lastPage: null,
    currentPageCount: null,
  });

  const { filters } = useSocialContext();
  const { globalState } = useGlobalContext();
  const handleCreatePost = (type) => {
    if (type !== null) {
      setShowCreatePost(true);
      setCreatePostType(type);
    }
  };

  const handleRefreshNewsFeed = async () => {
    const res = await getNewsFeedWorkRequests(
      '',
      filters.date,
      filters.category,
      1,
      PAGE_SIZE,
      filters.startDate,
      filters.endDate
    );
    setPosts(res.data);
    setMeta(res.meta);
  };

  const handleNextPage = async () => {
    const nextPage = meta.currentPage + 1;
    const res = await getNewsFeedWorkRequests(
      '',
      filters.date,
      filters.category,
      nextPage,
      PAGE_SIZE,
      filters.startDate,
      filters.endDate
    );
    setPosts([...posts, ...res.data]);
    setMeta(res.meta);
  };

  // Update Posts with loader, update on filter and city change
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setPosts([]);
      const res = await getNewsFeedWorkRequests(
        '',
        filters.date,
        filters.category,
        1,
        PAGE_SIZE,
        filters.startDate,
        filters.endDate
      );
      setPosts(res.data);
      setMeta(res.meta);
      setLoading(false);
      setUpdateFeed(false);
    }
    debounce(() => fetchData(), 300)();
  }, [filters, globalState.city, updateFeed]);

  // Update Posts without loader, update on post created
  useEffect(() => {
    async function fetchData() {
      const res = await getNewsFeedWorkRequests(
        '',
        filters.date,
        filters.category,
        1,
        PAGE_SIZE,
        filters.startDate,
        filters.endDate
      );
      setPosts(res.data);
      setMeta(res.meta);
    }
    fetchData();
  }, [showCreatePost, globalState.user.uuid]);

  return (
    <>
      <CreatePost
        show={showCreatePost}
        type={createPostType}
        handleClose={() => setShowCreatePost(false)}
        handleRefreshNewsFeed={handleRefreshNewsFeed}
      />
      <ActionBar>
        <CreatePostMenu handleCreatePost={handleCreatePost} />
        <Search />
      </ActionBar>

      <PageContainer>
        <TopRow>
          <Breadcrumbs />
        </TopRow>

        <ContentRow>
          <FilterColumn>
            <Filter />
          </FilterColumn>

          <FeedColumn>
            <Alerts />

            <FeedHeader>
              <h2>News Feed</h2>
              <img
                src="social/filter-icon.svg"
                alt="Toggle Filters Menu"
                onClick={() => setShowFilterMenu(!showFilterMenu)}
              />
            </FeedHeader>
            {showFilterMenu && <FilterMenu />}
            <Feed>
              {loading && posts && posts.length > 0 && <Loader />}
              {posts?.length === 0 && !loading && (
                <NotFoundContainer>
                  <h2>No posts to show</h2>
                  <p>Create a post or follow someone to see their posts.</p>
                </NotFoundContainer>
              )}
              <InfiniteScroll
                style={{ overflow: 'inherit' }}
                dataLength={meta?.currentPage}
                next={handleNextPage}
                hasMore={meta ? meta?.currentPage < meta?.lastPage : true}
                loader={!loading && <Loader />}
                // endMessage={<Loadmore visible>End of your feed</Loadmore>}
              >
                {Array.isArray(posts) &&
                  posts.map(
                    (
                      {
                        title,
                        rePostDate,
                        reposted,
                        repostedUser,
                        canRepost,
                        id,
                        typeDescription,
                        customIcon,
                        user: userData,
                        code,
                        address,
                        createdDate,
                        statusDescription,
                        custom1,
                        description,
                        imageIds,
                        upVoteCount,
                        commentCount,
                        repostCount,
                        shareCount,
                        canUpVote,
                        loggedByMe,
                        petition,
                        currentStatusProgress,
                        totalNumberOfStatus,
                        announcement,
                      },
                      index
                    ) => (
                      <Post
                        updateFeed={() => setUpdateFeed(true)}
                        id={id}
                        isFirst={index === 0}
                        uuid={userData.uuid}
                        refNum={code}
                        username={userData.fullName}
                        verified={userData.verified}
                        role={userData.smartCityUserRoles[0].description}
                        location={address}
                        timestamp={createdDate}
                        status={statusDescription}
                        wrIcon={customIcon}
                        type={typeDescription}
                        title={announcement || petition ? custom1 : title}
                        text={description}
                        images={imageIds}
                        upvotes={upVoteCount}
                        comments={commentCount}
                        reposts={repostCount}
                        shares={shareCount}
                        canUpVote={canUpVote}
                        canRepost={canRepost}
                        petition={petition}
                        announcement={announcement}
                        loggedByMe={loggedByMe}
                        currentStatusProgress={currentStatusProgress}
                        totalNumberOfStatus={totalNumberOfStatus}
                        key={index}
                        handleRefreshNewsFeed={handleRefreshNewsFeed}
                        isRepost={reposted}
                        repostedUser={repostedUser}
                        rePostDate={rePostDate}
                      />
                    )
                  )}
              </InfiniteScroll>
            </Feed>
          </FeedColumn>

          <ConnectionsColumn>
            <Heading style={{ marginBottom: '23px' }}>Connections</Heading>
            <Title>Suggested Follows</Title>
            <SuggestionList />
            <TextLink to="/social/connections">See more suggestions</TextLink>
          </ConnectionsColumn>
        </ContentRow>
      </PageContainer>
    </>
  );
};

export default SocialPage;
