import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { useState } from 'react';
import theme from 'theme';
import { LabelValuePair } from 'types/types';

const DropdownItem = ({
  option,
  setOption,
  lastChild = false,
}: {
  option: LabelValuePair;
  setOption: () => void;
  lastChild: boolean;
}) => (
  <Button
    onClick={setOption}
    textAlign="left"
    borderB={!lastChild ? theme.borders.standard1px : undefined}
    p="10px"
    position="relative"
    hoverCss={css`
      color: ${theme.colors.primary};
    `}
  >
    {option.label}
  </Button>
);

const Dropdown = ({
  value,
  options,
  onChange,
  label,
  dataTestId,
}: {
  label?: string;
  value: LabelValuePair;
  options: LabelValuePair[];
  dataTestId?: string;
  onChange: (option: LabelValuePair) => void;
}) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <Button
      dataTestId={dataTestId}
      position="relative"
      p="12px"
      border={`1px solid ${theme.colors.lightGrey}`}
      borderRadius={theme.borderRadius.small}
      onClick={() => setShowOptions(!showOptions)}
    >
      {label && (
        <Text
          overrideCss={css`
            font-size: 14px;
            position: absolute;
            top: -9px;
            left: 10px;
            background-color: white;
            padding: 0 10px;
            color: ${theme.colors.lightGrey};
          `}
        >
          {label}
        </Text>
      )}
      <Text textAlign="left" fontSize="14px" dataTestId={`${dataTestId}-label`}>
        {value?.label || ''}
      </Text>
      <Image
        position="absolute"
        top="25%"
        right="15px"
        source="icons/arrow-right-black.svg"
        alt="back arrow"
        overrideCss={css`
          transform: rotate(90deg);
        `}
      />
      {showOptions && (
        <Flex
          p="10px"
          px="4px"
          position="absolute"
          top="110%"
          minWidth="200px"
          left="0"
          backgroundColor="white"
          flexDirection="column"
          zIndex={1000}
          borderRadius={theme.borderRadius.small}
          overrideCss={css`
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
          `}
        >
          {options.map((option: LabelValuePair, idx: number) => (
            <DropdownItem
              option={option}
              setOption={() => onChange(option)}
              key={idx}
              lastChild={idx === options.length - 1}
            />
          ))}
        </Flex>
      )}
    </Button>
  );
};
export default Dropdown;
