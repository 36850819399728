import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledNavbar = styled.div`
  top: 0;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 60px;
  padding: 0 25px;
  background: #333333;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Logo = styled.img`
  width: 45px;
  height: 40px;
`;

export const ProfileIcon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  @media only screen and (min-width: 1150px) {
    width: 37px;
    height: 37px;
  }
`;

export const GenericDropdown = styled.div`
  top: 62px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 40px #00000029;
  z-index: 1;
  cursor: default;
  opacity: 0;
  visibility: hidden;
  ${({ open }) => (open
    ? `
    transform: translateY(0);
    transition-timing-function: ease-out;
    transition: 0.2s;
    opacity: 1;
    visibility: visible;`
    : `
    transform: translateY(5%);
    transition-timing-function: ease-in;
    transition: 0.15s;
    z-index: -1;`
  )}
`;

export const StyledLink = styled(Link)`
  margin-left: 24px;
  @media only screen and (min-width: 1150px) {
    margin-left: 50px;
  }
`;

export const Icon = styled.img`
  /* filter: hue-rotate(180deg) saturate(40); */
  height: 20px;
  @media only screen and (min-width: 1150px) {
    height: 25px;
  }
`;
