/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { Loader } from 'components/inthecity';
import {
  getImageURL,
  lookUpKinektekProduct,
  initializePurchaseOrder,
} from 'interface/inthecity/inthecity';
import { Modal } from 'components/social';
import {
  Heading,
  // BoardButton,
  ScrollContainer,
  BottomContainer,
  Line,
  Button,
  BoardPreview,
  ButtonLabel,
  ButtonBall,
  ProductItem,
  ProductContent,
  ProductTotal,
  PeachDisclaimer,
} from 'components/inthecity/lottoModal/lottoModal.styled';
import {
  ProgressLabel,
  StageCountCircle,
  ProgressHolder,
  Line as DetailsLine,
  ProgressLine,
  ConfirmationLabel,
} from './amountModal.styled';

const prefix = process.env.REACT_APP_API_BASE.split('.')[0];
const peachTestEnv = prefix.includes('dev');

/**
 * Payment Modal
 */
const PaymentModal = (props) => {
  // props
  const {
    show,
    ownAmount,
    handleBack,
    handleClose,
    product, // get product id from this object
    mobileNumber,
    category,
    subcategory,
    buy,
  } = props;

  // state
  const [loading, setLoading] = useState(false);
  const [checkout, setCheckout] = useState();
  const [lookup, setLookup] = useState();

  // timeout counter
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          console.log('countdown reached');
          handleBack();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  // Step 1: Get SessionID if Dual Step, and get new checkout ID from Peach
  useEffect(() => {
    async function initializePayment() {
      setLoading(true);

      const { productID } = product;

      // if dual step, do lookup
      if (product.dualStep) {
        const reference = mobileNumber;
        const lookupResult = await lookUpKinektekProduct(productID, reference);
        setLookup(lookupResult);
      }

      let priceInRands;
      if (buy) {
        priceInRands = ownAmount;
      } else {
        priceInRands = ((category === 'Airtime' && subcategory === 'Direct Topup')
          ? ownAmount
          : product?.price / 100
        );
      }

      const checkoutResult = await initializePurchaseOrder(priceInRands, productID);
      setCheckout(checkoutResult);

      setLoading(false);
    }
    if (product && !checkout && !lookup) initializePayment();
  }, []);

  // Step 2: Get Peach Payment widget
  useEffect(() => {
    window.wpwlOptions = { style: 'plain' }; // copy&pay style
    const script = document.createElement('script'); // copy&pay script
    script.src = checkout ? `https://${peachTestEnv ? 'test.' : ''}oppwa.com/v1/paymentWidgets.js?checkoutId=${checkout}` : '';
    script.async = true;
    document.body.appendChild(script);

    // cleanup function
    return () => {
      console.log('cleaned up');
      document.body.removeChild(script);
      delete window.wpwlOptions;
    };
  }, [checkout]);

  const redirectStateString = encodeURI(JSON.stringify({
    ownAmount,
    product,
    checkout,
    lookup,
    mobileNumber,
  }));

  console.log('lotto:payment:redirectState', redirectStateString);

  return (
    <Modal
      title="Payment"
      show={show}
      closeButton={buy}
      handleClose={handleClose}
      backButton={!buy}
      handleBack={handleBack}
    >
      <ProgressHolder>
        <StageCountCircle done active>
          1
          <ProgressLabel>Amount</ProgressLabel>
        </StageCountCircle>
        <ProgressLine active />
        <StageCountCircle done active>
          2
          <ProgressLabel>Phone</ProgressLabel>
        </StageCountCircle>
        <ProgressLine active />
        <StageCountCircle active>
          3
          <ProgressLabel>Payment</ProgressLabel>
        </StageCountCircle>
        <ProgressLine />
        <StageCountCircle>
          4
          <ConfirmationLabel>Confirmation</ConfirmationLabel>
        </StageCountCircle>
      </ProgressHolder>

      <ScrollContainer style={{ fontFamily: 'Roboto' }}>
        <ProductItem>
          <img src={getImageURL(product?.imageName)} alt={product?.name} />
          <ProductContent>
            <p>{product?.name}</p>
            <p>{mobileNumber}</p>
          </ProductContent>
          <ProductTotal>{`R ${(product?.price / 100 || ownAmount).toFixed(2)}`}</ProductTotal>
        </ProductItem>

        {/* Peach Payments Form */}
        {(product && checkout) && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Heading>Card Information</Heading>
              <Heading>{`Timeout ${minutes}:${seconds}`}</Heading>
            </div>
            <form
              action={`https://${process.env.REACT_APP_API_BASE}/in-the-city/purchase/airtime/${redirectStateString}/`}
              className="paymentWidgets"
              data-brands="VISA MASTER"
            />

            <PeachDisclaimer>
              <p>
                Peach Payments is PCI DSS compliant.
                This security standard is recognised by Visa, Mastercard,
                etc as an industry standard for security.
              </p>
              <img src="inthecity/purchase/peach-logo.svg" alt="PeachPayments Logo" />
            </PeachDisclaimer>
          </>
        )}

        {loading && <Loader />}
      </ScrollContainer>
    </Modal>
  );
};

export default PaymentModal;
