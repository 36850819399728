import styled from 'styled-components';

export const Container = styled.div`
  /* margin-left: auto; */
  @media only screen and (max-width: 1150px) {
    position: fixed;
    z-index: 1;
    bottom: 32px;
    right: 20px;
    margin: 0;
  }
`;

export const NavbarButton = styled.div`
  font: normal normal normal 14px/44px Roboto;
  cursor: pointer;
  user-select: none;
  height: 44px;
  padding: 0 35px;
  background-color: #DF6E21;
  color: #FFFFFF;
  border-radius: 5px;
  transition: all 0.2s;

  &:hover {
    background: hsl(24.315789473684212, 74.80314960629921%, 55%);
  }

  @media only screen and (max-width: 1150px) {
    display: none;
  }
`;

export const Fab = styled.div`
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: #DF6E21 url('social/create-post-icon.svg') 55% 45% no-repeat padding-box;
  ${({ active }) => (active && `
    background: #DF6E21 url('social/cancel-icon.svg') 50% 50% no-repeat padding-box;
  `)}
  box-shadow: 0px 3px 24px #3333332B;
  cursor: pointer;
  &:hover, &:focus {
    background-color: #cb641e;
  }
  @media only screen and (min-width: 1150px) {
    display: none;
  }
`;

export const GenericDropdown = styled.div`
  /* top: 62px; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 40px #00000029;
  z-index: 1;
  cursor: default;
  opacity: 0;
  visibility: hidden;
  ${({ open }) => (open
    ? `
    transform: translateY(0);
    transition-timing-function: ease-out;
    transition: 0.2s;
    opacity: 1;
    visibility: visible;`
    : `
    transform: translateY(5%);
    transition-timing-function: ease-in;
    transition: 0.15s;
    z-index: -1;`
  )}
`;

export const Dropdown = styled(GenericDropdown)`
  /* right: 250px; */
  top: 100%;
  position: absolute;
  border-radius: 12px;
  padding: 25px;
  width: 304px;
  @media only screen and (max-width: 1150px) {
    right: 56px;
    top: -200px;
    padding: 18px;
    width: 220px;
  }
`;

export const Item = styled.div`
  display: flex;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 13px;
  }
  @media only screen and (min-width: 1150px) {
    border-radius: 3px;
    padding: 12px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &:hover {
      background: rgba(178, 188, 193, 0.1);
    }
  }
`;

export const Icon = styled.div`
  background: url(${({ src }) => src}) 50% 50% no-repeat padding-box;
  background-color: rgba(178, 188, 193, 0.1);
  border-radius: 50%;
  background-size: auto 12px;
  width: 35px;
  height: 35px;
  @media only screen and (min-width: 1150px) {
    background-size: auto;
    width: 42px;
    height: 42px;
  }
`;

export const Label = styled.p`
  font: normal normal normal 12px/35px Roboto;
  letter-spacing: 0px;
  color: #333333;
  margin: 0 0 0 25px;
  @media only screen and (min-width: 1150px) {
    margin: 0 0 0 30px;
    font: normal normal normal 14px/42px Roboto;
  }
`;

export const Title = styled.p`
  font: normal normal 500 12px/16px Roboto;
  margin: 0 0 22px 0;
  padding: 0;
  @media only screen and (min-width: 1150px) {
    display: none;
    margin: 0;
  }
`;
