import { MunicipalPageWrapper } from 'components/my-municipality';
import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { useEffect, useState } from 'react';
import {
  CustomerPremises,
  GMMunicipalAccount,
  UtilityAccount,
} from 'types/types';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { Loader } from 'components/inthecity';
import UtilityAccountItem from 'components/my-municipality/utility-account-item';
import ExternalLink from 'components/elements/external-link';
import { useNavigate, useParams } from 'react-router-dom';
import theme from 'theme';
import useQuery from 'hooks/use-query';
import { peachSuccessCode } from 'constants/peach';

const MunicipalAccountPaymentStatusPage = () => {
  // Hooks
  const navigate = useNavigate();
  const { cityId } = useParams();
  const query = useQuery();

  // API Calls
  const {
    loading: paymentStatusLoading,
    error: paymentStatusError,
    callApi: getPaymentStatus,
    response: paymentStatusResponse,
  } = useSmartCityManager(SCManagerEndpoints.GetPeachPaymentStatus);

  // State management and constants
  const checkoutId = query.get('id');
  const merchantCode = query.get('merchantCode');
  const premiseId = query.get('premiseId');
  const accountNumber = query.get('accountNumber');

  // Side effects
  useEffect(() => {
    if (checkoutId) {
      getPaymentStatus({
        queryParams: `checkoutId=${checkoutId}&createRegistration=false&merchantCode=${merchantCode}`,
      });
    }
  }, [checkoutId]);

  return (
    <MunicipalPageWrapper>
      {paymentStatusLoading ? (
        <Loader />
      ) : (
        <Flex
          maxWidth="720px"
          mx="auto"
          width="100%"
          p="24px 28px 54px 28px"
          flexDirection="column"
          borderRadius="4px"
          boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
        >
          {premiseId && accountNumber && (
            <UtilityAccountItem
              account={{
                premiseId: parseInt(premiseId, 10),
                accountNumber,
                orgUnitId: 0,
                orgUnitDescription: 'Municipal Account',
                active: true,
                meters: [],
                externalId: '0',
                statusId: 2,
                status: 'Verified',
              }}
            />
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="2"
            viewBox="0 0 658 2"
            fill="none"
          >
            <path
              d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H658V0H1V2Z"
              fill="#F0F0F0"
            />
          </svg>

          <Flex
            mt="30px"
            flexDirection="column"
            width="100%"
            justifyContent="center"
            gap="24px"
          >
            {paymentStatusResponse?.result.code === peachSuccessCode ? (
              <>
                <Image
                  source="my-municipality/payment-success.svg"
                  alt="info"
                  width="90px"
                  mx="auto"
                />
                <Text textAlign="center">Thank you for your payment.</Text>
                <Box fontWeight={600}>
                  <Text textAlign="center">Reference Number:</Text>
                  <Text
                    fontSize="22px"
                    color={theme.colors.primary}
                    textAlign="center"
                  >
                    {paymentStatusResponse?.referenceCode}
                  </Text>
                </Box>
              </>
            ) : (
              <Text
                textAlign="center"
                fontSize="22px"
                fontWeight={600}
                color={theme.colors.primary}
              >
                There was a problem processing your payment. Please try again
              </Text>
            )}
            <Text textAlign="center">
              For more information please contact George Municipality.
            </Text>
            <Flex
              justifyContent="space-evenly"
              mobileCss={css`
                flex-direction: column;
                align-items: center;
                gap: 12px;
              `}
            >
              <ExternalLink href="tel:0448019111">
                <Flex alignItems="center">
                  <Box
                    p="10px"
                    borderRadius="50%"
                    backgroundColor="#FCF1E9"
                    mr="12px"
                  >
                    <Image
                      source="my-municipality/phone.svg"
                      alt="phone"
                      width="20px"
                    />
                  </Box>
                  <Text fontSize="18px" fontWeight={600}>
                    044 801 9111
                  </Text>
                </Flex>
              </ExternalLink>
              <ExternalLink href="mailto:accounts@george.gov.za">
                <Flex alignItems="center">
                  <Box
                    p="10px"
                    borderRadius="50%"
                    backgroundColor="#FCF1E9"
                    mr="12px"
                  >
                    <Image
                      source="my-municipality/email.svg"
                      alt="mail"
                      width="20px"
                    />
                  </Box>
                  <Text fontSize="18px" fontWeight={600}>
                    accounts@george.gov.za
                  </Text>
                </Flex>
              </ExternalLink>
            </Flex>
          </Flex>
          <Grid
            gridTemplateColumns="1fr 1fr"
            gridColumnGap="17px"
            width="100%"
            mt="36px"
          >
            <Button
              p="14px"
              border={`1px solid ${theme.colors.primary}`}
              fontSize="16px"
              color={theme.colors.primary}
            >
              Municipal Accounts
            </Button>
            <Button
              p="14px"
              fontSize="16px"
              color="white"
              backgroundColor={theme.colors.primary}
              onClick={() =>
                navigate(
                  `/my-municipality/municipal-accounts/${cityId}/payment-history?accountNumber=${accountNumber}&premiseId=${premiseId}`
                )
              }
            >
              View past payments
            </Button>
          </Grid>
        </Flex>
      )}
    </MunicipalPageWrapper>
  );
};

export default MunicipalAccountPaymentStatusPage;
