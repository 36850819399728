import React, { useState } from 'react';

import { useGlobalContext } from 'contexts/global';
import LocationModal from 'components/social/filter/locationmodal';
import { LocationButton, Label, DropdownIcon } from './citySelect.styled';

const CitySelect = () => {
  const [show, setShow] = useState(false);
  const { globalState } = useGlobalContext();

  return (
    <>
      <LocationButton onClick={() => setShow(true)}>
        <Label>{globalState.city?.label}</Label>
        <DropdownIcon
          open={show}
          src="social/location-dropdown-icon.svg"
          alt="dropdown"
        />
      </LocationButton>
      <LocationModal show={show} handleClose={() => setShow(false)} />
    </>
  );
};

export default CitySelect;
