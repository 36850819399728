import styled from 'styled-components';

export const SearchInput = styled.input`
  width: 100%;
  height: 35px;
  color: #333333;
  background: url('inthecity/search-icon.svg') no-repeat padding-box;
  background-position: 14px 50%;
  background-size: 18px;
  background-color: ${(props) => (props.variation === 'dark' ? '#f0f2f3' : '#FFFFFF')}; 
  border-radius: 2px;
  border: 1px solid ${(props) => (props.variation === 'dark' ? '#f0f2f3' : '#FFFFFF')};
  transition: 0.2s ease-in-out;
  box-shadow: 0px 0px 30px ${(props) => (props.variation === 'dark' ? 'hsla(0, 0%, 0%, 0.0118)' : '#00000014')};
  padding: 0 40px;
  font: normal normal normal 12px/14px ${(props) => (props.theme.font)};
  
  @media only screen and (min-width: 800px) {
    background-position: 28px 50%;
    background-size: 25px;
    height: 62px;
    border-radius: 5px;
    padding: 0 90px;
    font: normal normal normal 14px/24px ${(props) => (props.theme.font)};
  }

  &::placeholder {
    color: #B2BCC1;
    opacity: 1;
    border: none;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding: 20px 25px;
  border-radius: 10px;
  box-shadow: 0px 3px 15px #0000001F;
  position: absolute;
  background: #FFFFFF;
  opacity: 0;
  visibility: hidden;
  ${({ show }) => (show
    ? `
    // transform: translateY(0);
    transition-timing-function: ease-out;
    transition: 0.2s;
    opacity: 1;
    z-index: 1;
    visibility: visible;`
    : `
    // transform: translateY(5%);
    transition-timing-function: ease-in;
    transition: 0.15s;
    z-index: -1;`
  )}

  @media only screen and (min-width: 800px) {
    padding: 20px 53px;
    max-height: 500px;
  }
`;

export const DropdownCategory = styled.h2`
  font: normal normal 500 14px/19px ${(props) => (props.theme.font)};
  color: #0A0918;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
`;

export const ResultContainer = styled.div`
  display: flex;
  background-color: #FFFFFF;
  width: 100%;
  padding: 6px 6px 0 15px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: #F5F6F7;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  img {
    width: 45px;
    height: 40px;
    object-fit: cover;
    flex-shrink: 0;
  }

  @media only screen and (min-width: 800px) {
    padding: 12px 12px 0 33px;
  }
`;

export const ResultDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  height: 50px;
  width: 385px;
  border-bottom: 1px solid #F6F6F9;
`;

export const ResultTitle = styled.h3`
  font: normal normal normal 12px/14px ${(props) => (props.theme.font)};
  color: #0A0918;
  margin-bottom: 4px;

  // text overflow is hidden and displays an ellipsis
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/19px ${(props) => (props.theme.font)};
  }
`;

export const ResultPlaceRow = styled.div`
  display: flex;
`;

export const ResultAddress = styled.p`
  font: normal normal normal 10px/12px ${(props) => (props.theme.font)};
  color: #0A0918;
  padding: 0;
  margin: 0;
  flex-shrink: 1;

  // text overflow is hidden and displays an ellipsis
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const ResultType = styled.p`
  font: normal normal normal 10px/12px ${(props) => (props.theme.font)};
  color: #DF6E21;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
`;

export const ResultPlaceSeparator = styled.p`
  font: normal normal normal 10px/12px ${(props) => (props.theme.font)};
  padding: 0;
  margin: 0 8px;
  flex-shrink: 0;
`;

export const SuggestedContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 25px;
  @media only screen and (min-width: 800px) {
    padding: 17px 25px 0 25px;
  }
`;

export const SuggestedText = styled.p`
  display: none;
  @media only screen and (min-width: 800px) {
    display: block;
    color: ${(props) => props.theme.colors.dark};
    font: normal normal normal 14px/19px ${(props) => (props.theme.font)};
    padding: 0;
    margin: 0;
    margin-right: 21px;
    flex-shrink: 0;
  }
`;

export const SuggestedItems = styled.div`
  display: flex;
  overflow-y: auto;
  width: 100%;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar { display: none; } /* Chrome */

  p {
    cursor: pointer;
    flex-shrink: 0;
    border-bottom: 1px solid #333333;
    color: #333333;
    font: normal normal normal 12px/18px ${(props) => (props.theme.font)};
    padding: 0;
    margin: 0;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:hover {
      color: #DF6E21;
      border-bottom: 1px solid #DF6E21;
    }
  }

  @media only screen and (min-width: 800px) {
    p {
      font: normal normal normal 12px/18px ${(props) => (props.theme.font)};
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
`;

export const ResetButton = styled.div`
  display: ${({ show = true }) => (show ? 'block' : 'none')};
  z-index: 3;
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 14px;
  width: 12px;
  height: 12px;
  background: url('social/clear-icon.svg') 50% 50% no-repeat padding-box;
  background-size: 12px 12px;

  @media only screen and (min-width: 800px) {
    top: 40%;
    right: 28px;
    background-size: 19px 19px;
    width: 19px;
    height: 19px;
  }
`;
