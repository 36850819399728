/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ActionBar } from 'components';
import CreatePostMenu from 'components/social/createpostmenu';
import { cityBackgroundUrl, profileImageUrl } from 'functions/social';
import { useGlobalContext } from 'contexts/global';
import { Loader } from 'components/social/createpost';
import {
  getCurrentSmartCityUser,
  getNewsFeedWorkRequestsForUser,
  followUser,
  unFollowUser,
} from 'interface/social';
import {
  Button,
  CreatePost,
  Breadcrumbs,
  Post,
  Search,
} from 'components/social';
import { NotFoundContainer, PageContainer, Feed } from './social.styled';
import {
  TopRow,
  PageContent,
  HeaderContainer,
  LocationImageBanner,
  ProfileImage,
  InfoColumn,
  InfoRow,
  HeaderText,
  Divider,
  TabRow,
  TabButton,
  ButtonContainer,
} from './profile.styled';

const PAGE_SIZE = 10;

function getCityLabel(id, cities) {
  if (Array.isArray(cities) && cities.length > 0) {
    return cities.find((obj) => obj.value === id)?.label;
  }
  return 'No city found';
}

const ProfilePage = () => {
  const { uuid } = useParams();
  const { globalState } = useGlobalContext();
  // Navbar state
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [createPostType, setCreatePostType] = useState(null);
  const [userData, setUserData] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [followState, setFollowState] = useState(false);

  const handleCreatePost = (type) => {
    if (type !== null) {
      setShowCreatePost(true);
      setCreatePostType(type);
    }
  };

  const handleFollow = () => {
    if (followState) {
      setFollowState(false);
      unFollowUser(uuid);
    } else {
      setFollowState(true);
      followUser(uuid);
    }
  };

  useEffect(() => {
    // set suggested followers
    const fetchData = async () => {
      // get user profile data
      setUserLoading(true);
      const userRes = uuid
        ? await getCurrentSmartCityUser(uuid)
        : await getCurrentSmartCityUser();
      setUserData(userRes);
      setFollowState(userRes?.followed);
      setUserLoading(false);
    };
    fetchData();
  }, [uuid]);

  return (
    <>
      <CreatePost
        show={showCreatePost}
        type={createPostType}
        handleClose={() => setShowCreatePost(false)}
      />
      <ActionBar>
        <CreatePostMenu handleCreatePost={handleCreatePost} />
        <Search />
      </ActionBar>

      <PageContainer>
        {userLoading && <Loader />}
        {userData.fullName && !userLoading && (
          <>
            <TopRow>
              <Breadcrumbs userName={userData?.fullName} />
            </TopRow>

            <PageContent>
              <HeaderContainer>
                <LocationImageBanner
                  src={cityBackgroundUrl(userData?.currentCitySubscriberId)}
                >
                  <img src="social/location-white-icon.svg" alt="pin" />
                  <p>
                    {getCityLabel(
                      userData?.currentCitySubscriberId,
                      globalState.availableCities
                    )}
                  </p>
                  <ProfileImage src={profileImageUrl(userData?.uuid)} />
                </LocationImageBanner>
                <InfoColumn>
                  <InfoRow>
                    <HeaderText bold color="#333333">
                      {userData?.fullName}
                    </HeaderText>
                    <Divider>·</Divider>
                    <HeaderText color="#B2BCC1">
                      {userData?.smartCityUserRoles
                        ? _.startCase(
                            _.toLower(
                              userData.smartCityUserRoles[0].description
                            )
                          )
                        : ''}
                    </HeaderText>
                  </InfoRow>
                  <InfoRow>
                    <HeaderText color="#B2BCC1">
                      {getCityLabel(
                        userData?.currentCitySubscriberId,
                        globalState.availableCities
                      )}
                    </HeaderText>
                  </InfoRow>
                  <InfoRow>
                    <HeaderText color="#DF6E21">
                      {`${userData?.reportsCount} Reports Created`}
                    </HeaderText>
                    <Divider color="#B2BCC1">|</Divider>
                    <HeaderText color="#DF6E21">
                      {`${userData?.petitionsCount} Petitions`}
                    </HeaderText>
                  </InfoRow>
                  {/* {(userData.followable && uuid) && ( // if no uuid then user's own profile */}
                  {uuid && (
                    <ButtonContainer onClick={handleFollow}>
                      <Button
                        label={followState ? 'Following' : 'Follow'}
                        active={followState}
                      />
                    </ButtonContainer>
                  )}
                </InfoColumn>
              </HeaderContainer>
              <TabRow>
                <TabButton active={tab === 0} onClick={() => setTab(0)}>
                  Petitions
                </TabButton>
                <TabButton active={tab === 1} onClick={() => setTab(1)}>
                  Posts
                </TabButton>
              </TabRow>
              <Feed>
                {tab === 0 ? (
                  <UserFeed petition uuid={uuid || globalState.user.uuid} />
                ) : (
                  <UserFeed
                    petition={false}
                    uuid={uuid || globalState.user.uuid}
                  />
                )}
              </Feed>
            </PageContent>
          </>
        )}
      </PageContainer>
    </>
  );
};

export default ProfilePage;

export const UserFeed = ({ petition = false, uuid }) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    currentPage: 0,
    total: null,
    lastPage: null,
    currentPageCount: null,
  });
  const [loading, setLoading] = useState(false);

  const handleNextPage = async () => {
    const nextPage = meta.currentPage + 1;
    const res = await getNewsFeedWorkRequestsForUser(
      petition,
      uuid,
      nextPage,
      PAGE_SIZE
    );
    setData([...data, ...res.data]);
    setMeta(res.meta);
  };

  useEffect(() => {
    setData([]);
    async function fetchData() {
      setLoading(true);
      const res = await getNewsFeedWorkRequestsForUser(
        petition,
        uuid,
        1,
        PAGE_SIZE
      );
      setData(res.data);
      setMeta(res.meta);
      setLoading(false);
    }
    fetchData();
  }, [petition, uuid]);

  return (
    <>
      {loading && data !== [] && <Loader />}
      {data?.length === 0 && !loading && (
        <NotFoundContainer>
          <h2>{petition ? 'No petitions to show' : 'No posts to show'}</h2>
          <p>
            {petition
              ? 'Create a petition post to see your posts here.'
              : 'Create a post to see your posts here.'}
          </p>
        </NotFoundContainer>
      )}
      <InfiniteScroll
        style={{ overflow: 'inherit' }}
        dataLength={meta?.currentPage}
        next={handleNextPage}
        hasMore={meta ? meta?.currentPage < meta?.lastPage : true}
        loader={!loading && <Loader />}
      >
        {Array.isArray(data) &&
          data.map(
            (
              {
                announcement,
                title,
                reposted,
                repostedUser,
                rePostDate,
                canRepost,
                id,
                typeDescription,
                customIcon,
                user: userData,
                code,
                address,
                createdDate,
                statusDescription,
                custom1,
                description,
                imageIds,
                upVoteCount,
                commentCount,
                repostCount,
                shareCount,
                canUpVote,
                loggedByMe,
                petition: isPetition,
                currentStatusProgress,
                totalNumberOfStatus,
              },
              index
            ) => (
              <Post
                id={id}
                isFirst={index === 0}
                uuid={userData.uuid}
                refNum={code}
                username={userData.fullName}
                verified={userData.verified}
                role={userData.smartCityUserRoles[0].description}
                location={address}
                timestamp={createdDate}
                status={statusDescription}
                wrIcon={customIcon}
                type={typeDescription}
                title={announcement || petition ? custom1 : title}
                text={description}
                images={imageIds}
                upvotes={upVoteCount}
                comments={commentCount}
                reposts={repostCount}
                shares={shareCount}
                canUpVote={canUpVote}
                canRepost={canRepost}
                petition={isPetition}
                announcement={announcement}
                loggedByMe={loggedByMe}
                currentStatusProgress={currentStatusProgress}
                totalNumberOfStatus={totalNumberOfStatus}
                isRepost={reposted}
                repostedUser={repostedUser}
                rePostDate={rePostDate}
                key={index}
              />
            )
          )}
      </InfiniteScroll>
    </>
  );
};
