/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';

import { postQuestionnaire } from 'interface/privateServices/survey';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Section, { PageWrapper } from 'components/section';
import Hero, { DownloadBar, HeroCarousel } from 'components/home/hero';
import {
  Form,
  Input,
  Label,
  QuestionContainer,
  QuestionRow,
  Select,
  Submit,
  ErrorLabel,
  TextButton,
  AddAnotherContainer,
  SubmitContainer,
  MessageContainer,
  Heading,
  Paragraph,
  SmallLoader,
  SubHeading,
} from 'components/private-services/survey';

/**
 * Private Services Survey Page
 */
const PrivateServicesSurveyPage = () => {
  const fields = [
    'Dog Walker',
    'Baby Sitter',
    'Pool Guy',
    'Domestic cleaner',
    'Window cleaning (home)',
    'Gardener',
    'Tree felling',
    'Maths Tutor',
    'Guitar Teacher',
    'Piano Teacher',
    'Music theory Teacher',
    'Car washer',
    'Casual Worker',
    'Casual painter',
    'Handyman',
    'Rubble removal',
    'Pest Control',
    'Pet grooming',
    'Hair stylist',
    'Tech Guy (Internet / WiFi / TV etc)',
    'Home & pet sitter',
    'Concierge services',
  ];

  const cities = [
    {
      label: 'Tshwane',
      value: 65,
    },
    {
      label: 'Ethekwini',
      value: 155,
    },
    {
      label: 'Johannesburg',
      value: 430,
    },
    {
      label: 'Cape Town',
      value: 431,
    },
    {
      label: 'Ekurhuleni',
      value: 433,
    },
    {
      label: 'Steve Tshwete',
      value: 448,
    },
  ];

  const suburbs = {
    65: [
      'Akasia',
      'Atteridgeville',
      'Baviaanspoort',
      'Bon Accord',
      'Boschkop',
      'Bronkhorstspruit',
      'Bultfontein',
      'Centurion',
      'Cullinan',
      'Dilopye',
      'Donkerhoek',
      'Eersterust',
      'Ekangala',
      'Ga-Rankuwa',
      'Haakdoornboom',
      'Hammanskraal',
      'Hebron',
      'Kameeldrift',
      'Kekana Garden',
      'Kungwini Part',
      'Laudium',
      'Mabopane',
      'Majaneng',
      'Mamelodi',
      'Mandela Village',
      'Marokolong',
      'Mashemong',
      'Mooiplaas',
      'Nellmapius',
      'New Eersterus',
      'Olievenhoutbos',
      'Onverwacht',
      'Pretoria',
      'Ramotse',
      'Rayton',
      'Refilwe',
      'Rethabiseng',
      'Roodepoort',
      'Saulsville',
      'Soshanguve',
      'Soutpan',
      'Stinkwater',
      'Suurman',
      'Temba',
      'Thembisile',
      'Tierpoort',
      'Tsebe',
      'Tshwane NU',
      'Vaalbank',
      'Waterval',
      'Winterveld',
      'Zithobeni',
      'Zwavelpoort',
    ],
    155: [
      'Desainager',
      'eMdloti',
      'Genazzano',
      'La Lucia',
      'La Mercy',
      'Mount Edgecombe',
      'Mount Moreland',
      'oThongathi',
      'Tongaat Beach',
      'uMhlanga',
      'Verulam',
      'Westbrook',
      'uMhlanga',
      'Arena Park',
      'Berea',
      'Bluff',
      'Cato Manor',
      'Durban North',
      'Essenwood',
      'Glen Park',
      'Glenwood',
      'Greyville',
      'Hillary',
      'Inanda',
      'Jacobs Ladder',
      'KwaMashu',
      'Manor Gardens',
      'Mayville',
      'Memorial Park',
      'Merebank',
      'Merewent',
      'Mobeni',
      'Montclair',
      'Mount Vernon',
      'Northdene',
      'North Park',
      'Ntuzuma',
      'Overport',
      'Phoenix',
      "Poet's Corner",
      'Prospecton',
      'Rossburgh',
      'Springfield',
      'Stamford Hill',
      'Sydenham',
      'Treasure Beach',
      'Wentworth',
      'Umhlatuzana',
      'Umlazi',
      'Umbilo',
      'Windermere',
      'Woodhaven',
      'Woodlands',
      'Yellowwood Park',
      'Amahlongwa',
      'Amandawe',
      'Amanzimtoti',
      'Athlone Park',
      'Breamar',
      'Clansthal',
      'Craigieburn',
      'Ilfracombe',
      'Illovo',
      'Isipingo',
      'Kingsburgh',
      'KwaMakhutha',
      'Lovu',
      'Magabeni',
      'Malangeni',
      'Reunion',
      'Umbogintwini',
      'Umkomaas',
      'Warner Beach',
      'Widenham',
      'Alverstone',
      'Assagay',
      "Botha's Hill",
      'Cato Ridge',
      'Crestholm',
      'Cliffdale',
      'Clifton Canyon',
      'Drummond',
      'Everton',
      'Emberton',
      'Forest Hills',
      'Fredville',
      'Gillitts',
      'Hammarsdale',
      'Harrison',
      'Hillcrest',
      'Inchanga',
      'Kloof',
      'KwaXimba',
      'Molweni',
      'Monteseel',
      'Mpumalanga',
      'Peacevale',
      'Shongweni',
      'Summerveld',
      'Waterfall',
      'Winston Park',
      'Wyebank',
      'Berea West',
      'Chiltern Hills',
      'Clermont',
      'Cowies Hill',
      'Dassenhoek',
      'Klaarwater',
      'KwaDabeka',
      'KwaNdengezi',
      'Escombe',
      'Malvern',
      'Maryvale',
      'Marianridge',
      'Marianhill',
      'Moseley',
      'Nazareth',
      'New Germany',
      'Pinetown',
      'Chatsworth',
      'Bellair',
      'Avoca',
      'Queensburgh',
      'Reservoir Hills',
      'Sarnia',
      'Savanna Park',
      'St Wendolins Ridge',
      'Seaview',
      'Shallcross',
      'Thornwood',
      'Welbedacht',
      'Westville',
    ],
    430: [
      'Alexandra',
      'Chartwell',
      'City',
      'Dainfern',
      'Diepsloot',
      'Drie Ziek',
      'Ebony Park',
      'Ennerdale',
      'Farmall',
      'Itsoseng',
      'Ivory Park',
      'Johannesburg CBD',
      'Kaalfontein',
      'Kagiso',
      'Kanana Park',
      'Lakeside',
      'Lanseria',
      'Lawley',
      'Lehae',
      'Lenasia',
      'Lenasia',
      'Lucky',
      'Malatjie',
      'Mayibuye',
      'Midrand',
      'Millgate Farm',
      'Orange Farm',
      'Poortjie',
      'Rabie Ridge',
      'Randburg',
      'Randfontein',
      'Rietfontein',
      'Roodepoort',
      'Sandton',
      'Soweto',
      'Stretford',
      'Tshepisong',
      'Vlakfontein',
      'Zakariyya Park',
      'Zevenfontein',
    ],
    431: [
      'Bakoven',
      'Bantry Bay',
      'Camps Bay',
      'Clifton',
      'Fresnaye',
      'Green Point',
      'Hout Bay',
      'Imizamo Yethu',
      'Llandudno',
      'Mouille Point',
      'Sea Point',
      'Three Anchor Bay',
      'Athlone',
      'Bishop Lavis',
      'Bonteheuwel',
      'Crawford',
      'Crossroads',
      'Epping',
      'Gugulethu',
      'Hanover Park',
      'Kalksteenfontein',
      'Khayelitsha',
      'Langa',
      'Lavender Hill',
      'Manenberg',
      'Mitchells Plain',
      'Nyanga',
      'Ottery',
      'Pelican Park',
      'Philippi',
      'Samora Machel',
      'Strandfontein',
      'Valhalla Park',
      'Delft',
      'Belhar',
      "Elsie's River",
      'Vrygrond',
      'Bo-Kaap',
      "Devil's Peak Estate",
      'De Waterkant',
      'Foreshore',
      'Gardens',
      'Higgovale',
      'Lower Vrede',
      'Oranjezicht',
      'Salt River',
      'Schotsche Kloof',
      'Tamboerskloof',
      'University Estate',
      'Vredehoek',
      'Walmer Estate',
      'Woodstock',
      'Zonnebloem',
      'Kuils River',
      'Eersterivier',
      'Blue Downs',
      'Blackheath',
      'Firgove',
      "Gordon's Bay",
      'Macassar',
      'Croydon',
      "Sir Lowry's Pass Village",
      'Somerset West',
      'Strand',
      'Bellville',
      'Brackenfell',
      'Bothasig',
      'Brooklyn',
      'Durbanville',
      'Edgemead',
      'Goodwood',
      'Kensington',
      'Kraaifontein',
      'Loevenstein',
      'Maitland',
      'Pinelands',
      'Monte Vista',
      'Panorama',
      'Thornton',
      'Parow',
      'Plattekloof',
      'Rugby',
      'Bergvliet',
      'Bishopscourt',
      'Claremont',
      'Constantia',
      'Diep River',
      'Grassy Park',
      'Harfield Village',
      'Heathfield',
      'Kenilworth',
      'Kenwyn',
      'Kirstenhof',
      'Kreupelbosch',
      'Lansdowne',
      'Lotus River',
      'Meadowridge',
      'Mowbray',
      'Ndabeni',
      'Newlands',
      'Observatory',
      'Plumstead',
      'Retreat',
      'Rondebosch',
      'Rondebosch East',
      'Rosebank',
      'South Field',
      'Steenberg',
      'Tokai',
      'Wynberg',
      'Wetton',
      'Capri Village',
      'Clovelly',
      'Fish Hoek',
      'Glencairn',
      'Kalk Bay',
      'Kommetjie',
      'Lakeside',
      'Marina da Gama',
      'Masiphumelele',
      'Muizenberg',
      'Noordhoek',
      'Ocean View',
      'Scarborough',
      "Simon's Town",
      'St James',
      'Sunnydale',
      'Sun Valley',
      'Vrygrond',
      'Hout Bay',
      'Atlantis',
      'Bloubergstrand',
      'Mamre',
      'Melkbosstrand',
      'Milnerton',
      'Montague Gardens',
      'Parklands',
      'Table View',
      'West Beach',
    ],
    433: [
      'Alberton',
      'Bapsfontein',
      'Benoni',
      'Boksburg',
      'Brakpan',
      'Breswol',
      'Centurion',
      'Chief A Luthuli Park',
      'Clayville',
      'Daveyton',
      'Duduza',
      'Dukathole',
      'Edenvale',
      'Ekurhuleni NU',
      'Etwatwa',
      'Geluksdal',
      'Germiston',
      'Harry Gwala',
      'Holfontein',
      'Kanana',
      'Katlehong',
      'Kempton Park',
      'Kwa-Thema',
      'Langaville',
      'Lindelani Village',
      'Nigel',
      'Springs',
      'Tembisa',
      'Thinasonke',
      'Tokoza',
      'Tsakane',
      'Tweefontein',
      'Vosloorus',
      'Wattville',
    ],
    448: [
      'Aerorand',
      'Blinkpan SP',
      'Chromville',
      'Clubville',
      'Dennesig',
      'Eastdene',
      'Emaskopasini',
      'Golfsig',
      'Hendrina SP',
      'Hlalamnandi',
      'Kanonkop',
      'Komati SP',
      'Koornfontein Mine',
      'Kranspoort dorp',
      'Kwazamokuhle SP',
      'Mafred',
      'Mapehla',
      'Mathaylini',
      'Mhluzi Ext. 2',
      'Mhluzi Ext. 3',
      'Mhluzi Ext. 4',
      'Mhluzi Ext. 5 & 6',
      'Mhluzi Ext. 7',
      'Mhluzi Ext. 8',
      'Mhluzi SP',
      'Middelburg Central',
      'Middelburg Ext. 24',
      'Middelburg Industrial',
      'Middelburg Mine',
      'Middelburg SP1',
      'Middelburg SP2',
      'Midlands Estate',
      'Mineralia',
      'Mountain View',
      'Nazareth',
      'Pullens Hope SP',
      'Rietkuil SP',
      'Steve Tshwete NU',
      'Tokologo',
      'Tycoon',
    ],
  };

  console.log('form:', {
    cities,
    suburbs,
  });

  // hooks
  const { uuid } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    isValid,
    setValue,
    watch,
  } = useForm();

  // state
  const [customFields, setCustomFields] = useState([]);
  const [submitted, setSubmitted] = useState();
  const [loading, setLoading] = useState(false);
  const [notInterested, setNotInterested] = useState(false);

  const citySelected = watch('City', false);

  const onSubmit = (data) => {
    async function postData() {
      let answers = [];

      if (notInterested) {
        answers.push({
          question: 'Not interested',
          answer: 'Not interested',
        });
      } else {
        const keys = Object.keys(data);
        answers = keys.map((item) =>
          item.includes('custom')
            ? data[item]
            : {
                question: item,
                answer: data[item],
              }
        );
      }

      const survey = {
        smartcityuser_uuid: uuid,
        surveyName: 'private_services_survey_2',
        answers,
      };

      setLoading(true);
      await postQuestionnaire(survey);
      setLoading(false);
      setSubmitted(true);
    }
    postData();
  };

  // scroll to submit message after the form is submitted
  useEffect(
    () => document.getElementById('submitted-container')?.scrollIntoView(),
    [submitted]
  );

  const handleAddCustomField = () => {
    setCustomFields([...customFields, `custom${customFields.length + 1}`]);
  };

  const handleRemoveCustomField = (fieldToRemove) => {
    const temp = [];
    customFields.forEach((field) => {
      if (field !== fieldToRemove) temp.push(field);
      else {
        setValue(`${fieldToRemove}`, {});
      }
    });
    setCustomFields(temp);
  };

  return (
    <PageWrapper>
      <SEO
        title="My Smart City | Private Services Survey"
        description="Private Services Survey"
      />

      <HeroCarousel excludeExtraSlides>
        <Hero
          image="privateServices/survey_banner.jpg"
          title="Win a R3000 Voucher*"
          desktopSubtitle="Answer three simple questions for the chance to win."
          mobileWidth="90%"
          transparent
        />
      </HeroCarousel>
      <DownloadBar dark />

      <Section color="white">
        {!submitted && (
          <>
            <Heading>
              You could stand a chance to win a R3000 Takealot voucher for just
              2 mins of your time.
            </Heading>
            <SubHeading>
              My Smart City is launching a service that will allow you to find,
              hire and rate reputable service providers and we are looking for
              your guidance on what would be the best services in your area.
              Help us create more jobs in your area by answering two simple
              questions.
            </SubHeading>

            <Form onSubmit={handleSubmit(onSubmit)}>
              {!notInterested && (
                <>
                  {/* City question */}

                  <Row>
                    <Col
                      lg={12}
                      style={{ marginBottom: '30px', paddingRight: '10%' }}
                    >
                      <QuestionContainer
                        style={{
                          justifyContent: 'center',
                          marginBottom: '25px',
                        }}
                      >
                        <Label>What city do you reside in?</Label>
                        <Select
                          type="select"
                          {...register('City', { required: true })}
                        >
                          <option value="" disabled defaultValue="selected">
                            Please select a city
                          </option>
                          {cities.map((c, index) => (
                            <option value={c.value} key={index}>
                              {c.label}
                            </option>
                          ))}
                        </Select>
                        {errors.City && (
                          <ErrorLabel style={{ position: 'relative' }}>
                            City is required
                          </ErrorLabel>
                        )}
                      </QuestionContainer>
                    </Col>
                  </Row>

                  {/* Suburb question */}

                  {citySelected && (
                    <Row>
                      <Col
                        lg={12}
                        style={{ marginBottom: '30px', paddingRight: '10%' }}
                      >
                        <QuestionContainer
                          style={{
                            justifyContent: 'center',
                            marginBottom: '25px',
                          }}
                        >
                          <Label>What suburb do you reside in?</Label>
                          <Select
                            type="select"
                            {...register('Suburb', { required: true })}
                          >
                            <option value="" disabled defaultValue="selected">
                              Please select a suburb
                            </option>
                            {suburbs[citySelected].map((s, index) => (
                              <option value={s} key={index}>
                                {s}
                              </option>
                            ))}
                          </Select>
                          {errors.Suburb && (
                            <ErrorLabel style={{ position: 'relative' }}>
                              Suburb is required
                            </ErrorLabel>
                          )}
                        </QuestionContainer>
                      </Col>
                    </Row>
                  )}

                  <SubHeading>
                    Which of the below services providers would you be likely to
                    use and how frequently would you use the service?
                  </SubHeading>

                  {/* Fields */}
                  <Row>
                    {fields.map((field, index) => (
                      <Col
                        lg={6}
                        md={12}
                        style={{ marginBottom: '25px', paddingRight: '10%' }}
                        key={index}
                      >
                        <QuestionContainer>
                          <Label>{field}</Label>
                          <Select
                            type="select"
                            defaultValue="Never"
                            {...register(field, { required: true })}
                          >
                            <option value="Never">Never</option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Fortnightly">Fortnightly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Randomly">Randomly</option>
                          </Select>
                        </QuestionContainer>
                      </Col>
                    ))}
                  </Row>

                  {/* Custom additional fields */}
                  <Row style={{ marginTop: '50px' }}>
                    {customFields.map((custom, index) => (
                      <Col
                        lg={6}
                        md={12}
                        style={{ marginBottom: '25px' }}
                        key={index}
                      >
                        <QuestionContainer>
                          <div style={{ position: 'relative' }}>
                            <Input
                              type="text"
                              placeHolder="Please specify"
                              {...register(`${custom}.question`, {
                                required: true,
                              })}
                            />
                            {errors[custom] && (
                              <ErrorLabel>
                                Please enter a service type
                              </ErrorLabel>
                            )}
                          </div>
                          <Select
                            type="select"
                            defaultValue="Never"
                            {...register(`${custom}.answer`, {
                              required: true,
                            })}
                          >
                            <option value="Never">Never</option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Fortnightly">Fortnightly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Randomly">Randomly</option>
                          </Select>
                          <TextButton
                            onClick={() => handleRemoveCustomField(custom)}
                          >
                            Remove
                          </TextButton>
                        </QuestionContainer>
                      </Col>
                    ))}
                  </Row>

                  <AddAnotherContainer>
                    <TextButton onClick={handleAddCustomField}>
                      Add another?
                    </TextButton>
                  </AddAnotherContainer>
                </>
              )}

              <QuestionRow>
                <Label>
                  None of the above, I would not be interested in such a service
                </Label>
                <input
                  type="checkbox"
                  checked={notInterested || false}
                  value={notInterested || false}
                  onChange={(e) => {
                    setNotInterested(e.target.checked);
                  }}
                />
              </QuestionRow>

              <SubmitContainer>
                <Submit type="submit" disabled={isValid || loading}>
                  {loading && <SmallLoader />}
                  <p>SUBMIT SURVEY</p>
                </Submit>
              </SubmitContainer>
            </Form>
          </>
        )}

        {/* Success message, after submission */}
        {submitted && (
          <MessageContainer id="submitted-container">
            <Heading>Thank you</Heading>
            <Paragraph>
              for participating in our marketing research survey.
            </Paragraph>
            <Paragraph>
              We will be collecting responses over the next week, with entries
              closing on the 15th June 2022. The winner of the R3000 Takealot
              voucher will be contacted by 30th June 2022. Keep those fingers
              crossed and keep checking our
              <Link to="/winners">&nbsp; competitions winners &nbsp;</Link>
              page to see if you are one of our lucky winners.
            </Paragraph>
            <Paragraph>
              <Link to="/private-services-survey/terms-and-conditions">
                See Terms &amp; Conditions
              </Link>
            </Paragraph>
          </MessageContainer>
        )}
      </Section>
      <Footer />
    </PageWrapper>
  );
};

export default PrivateServicesSurveyPage;
