import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Breadcrumbs,
  PlaceDetails,
  MobilePlaceDetails,
  LocationMap,
} from 'components/inthecity';
import { Body, Title } from 'components';
import {
  TopRow,
} from './placesOfInterest.styled';
import {
  Header,
  ContentRow,
  MainColumn,
  DetailsColumn,
  Image,
  Line,
  TextBold,
} from './event.styled';

/**
 * Detailed Place View
 */
const PlacePage = () => {
  const navigate = useNavigate();
  const place = useLocation()?.state?.place;
  const [tempPlace, setTempPlace] = useState({
    name: '',
    geocodes: { main: { latitude: 0, longitude: 0 } },
    location: '',
    photos: '',
    rating: '',
    stats: '',
    description: '',
    website: '',
    categories: '',
    hours: '',
    tel: '',
    distance: '',
  });
  if (place && tempPlace.name === '') {
    setTempPlace(place);
  }

  useEffect(() => {
    const fetchData = async () => {
      console.log('place', place, tempPlace, !place && tempPlace.name === '');
      if (!place && tempPlace.name === '') {
        navigate('/in-the-city/places-of-interest');
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <TopRow>
        <Breadcrumbs />
      </TopRow>
      <ContentRow>
        <MainColumn>
          {/* Details section with title */}
          <MobilePlaceDetails
            place={tempPlace}
          />
          <Header>
            {tempPlace.name}
          </Header>
          <Image
            style={tempPlace.photos[0]?.prefix ? null : { backgroundSize: 'initial' }}
            src={tempPlace.photos[0]?.prefix ? `${tempPlace.photos[0]?.prefix}original${tempPlace.photos[0]?.suffix}` : '/mscdark.png'}
          />
          <Line />

          <Title style={{ marginBottom: '17px' }}>About</Title>
          {tempPlace.description
            ? <Body>{tempPlace.description}</Body>
            : <Body>No description given</Body>}
          <Line />

          <Title style={{ marginBottom: '17px' }}>Contact</Title>
          {!tempPlace.tel && !tempPlace.website
            ? <Body>No contact information given</Body>
            : null}

          {tempPlace.tel || tempPlace.website
            ? (
              <>
                {tempPlace.tel
                  ? (
                    <Body>
                      <TextBold>Phone: </TextBold>
                      <a href={`tel:${tempPlace.tel}`}>{tempPlace.tel}</a>
                    </Body>
                  )
                  : null}
                {tempPlace.website
                  ? (
                    <Body>
                      <TextBold style={{ marginRight: '10px' }}>Website: </TextBold>
                      <a href={tempPlace.website}>{tempPlace.website}</a>
                    </Body>
                  )
                  : null}
              </>
            )
            : null}
          <Line />

          <Title>Location</Title>
          <LocationMap
            position={[tempPlace.geocodes.main.latitude, tempPlace.geocodes.main.longitude]}
          />
          <Line />
        </MainColumn>

        <DetailsColumn>
          <PlaceDetails
            place={tempPlace}
          />
        </DetailsColumn>
      </ContentRow>
    </>
  );
};

export default PlacePage;
