import styled from 'styled-components';

export const Background = styled.div`
  display: flex;
  z-index: 2;
  background: #00000049;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';

  padding: 1rem;
  @media only screen and (min-width: 800px) {
    padding: 0;
  }
`;

export const StyledModal = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: white;
  height: auto;
  position: relative;
  
  border-radius: 10px;
  padding: 24px;
  width: 100%;
  height: 100%;
  
  @media only screen and (min-width: 800px) {
    border-radius: 18px;
    margin: auto;
    padding: 45px;
    height: auto;
    max-height: 90vh;
    width: 667px;
  }
`;

export const CloseButton = styled.div`
  user-select: none;
  background: rgba(51, 51, 51, 0.1) url('social/close-icon.svg') 50% 50% no-repeat;
  background-size: 12px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 20px;
  transition: all 0.2s ease-in-out; 
  &:hover {
    background: rgba(51, 51, 51, 0.15) url('social/close-icon.svg') 50% 50% no-repeat;
    background-size: 12px;
  }
  @media only screen and (min-width: 800px) {
    width: 48px;
    height: 48px;
    top: 25px;
    right: 35px;
  }
`;

export const BackButton = styled.div`
  user-select: none;
  background: rgba(51, 51, 51, 0.1) url('social/back-icon.svg') 50% 50% no-repeat;
  background-size: 8px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 12px;
  left: 20px;
  transition: all 0.2s ease-in-out; 
  &:hover {
    background: rgba(51, 51, 51, 0.15) url('social/back-icon.svg') 50% 50% no-repeat;
    background-size: 8px;
  }
  @media only screen and (min-width: 800px) {
    width: 48px;
    height: 48px;
    top: 25px;
    right: 35px;
  }
`;

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 25px;
  font: normal normal 500 15px/20px 'Roboto';
  padding: 0 30px;
  @media only screen and (min-width: 800px) {
    margin-bottom: 50px;
    font: normal normal 500 20px/39px 'Roboto';
    padding: 0 40px;
  }
`;
