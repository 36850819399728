import React, { useEffect, useState } from 'react';
import { Loader } from 'components/social/createpost';
import {
  getEvents,
} from 'interface/inthecity/quicket';
// import { useGlobalContext } from 'contexts/global';
import {
  Search,
  CardsViewer,
  Breadcrumbs,
  Tabs,
} from 'components/inthecity';
import { useInTheCityContext } from 'contexts/inthecity';
import {
  TopRow,
  Title,
  ContentContainer,
  SearchContainer,
  // TabsContainer,
} from './placesOfInterest.styled';
import { PageLine } from './inthecity.styled';
/**
 * Whats on in your city Page, view events
 */
const WhatsUpInYourCityPage = () => {
  const {
    selectedWOIYCCategory,
  } = useInTheCityContext();
  // State
  const [tab, setTab] = useState({ id: 0, label: 'All', link: '/in-the-city/whats-on-in-your-city' });
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [tabLinks, setTabLinks] = useState(null);
  const [events, setEvents] = useState(null);
  const [allEvents, setAllEvents] = useState(null);
  console.log('WhatsUpInYourCityPage', selectedWOIYCCategory);

  useEffect(() => {
    const fetchData = async () => {
      // get events
      const eventsRes = await getEvents();
      console.log('eventRes', eventsRes);
      setAllEvents(eventsRes);

      // get categories and tabs
      const suggestions = [];
      const tabs = [tab];

      console.log(eventsRes);
      if (selectedWOIYCCategory !== -1) {
        tabs.push(selectedWOIYCCategory);
        setTab(selectedWOIYCCategory);
      }

      eventsRes.forEach((event) => {
        event.categories.forEach((cat) => {
          let flag = true;
          for (let i = 0; i < tabs.length; i += 1) {
            if (tabs[i].id === cat.id) {
              flag = false;
            }
          }
          if (flag) {
            tabs.push({
              id: cat.id,
              label: cat.name,
              link: '/in-the-city/whats-on-in-your-city',
            });
          }
        });
      });
      eventsRes.forEach((event) => {
        suggestions.push(event.name);
      });
      setSearchSuggestions(suggestions.slice(-5));
      setTabLinks(tabs);
      console.log('tabs', tab, tabs);
    };
    fetchData();
  }, []);

  // filter places by category if tab is selected
  useEffect(() => {
    async function fetchEvents() {
      setEvents([]);
      const eventsRes = await getEvents(tab.id);
      setEvents(eventsRes);
    }
    if (tab && tab?.label !== 'All') fetchEvents();
  }, [tab]);

  return (
    <>
      <TopRow>
        <Breadcrumbs />
        {/* <LocationFilter /> */}
      </TopRow>

      <Title>What&apos;s on in your City</Title>

      <Tabs
        links={tabLinks || []}
        currentTab={tab}
        setTab={(s) => setTab(s)}
      />
      {/* <TabsContainer>
      </TabsContainer> */}

      <PageLine />

      <SearchContainer>
        <Search
          placeholder="Search events"
          variation="dark"
          searchType="events"
          suggestions={searchSuggestions}
        />
      </SearchContainer>

      <ContentContainer>
        {!allEvents && <Loader />}

        {(tab?.id !== 0 && events) && (
          <CardsViewer
            events={events}
            title={tab?.label}
          />
        )}
        <CardsViewer
          events={allEvents}
          title="All Events"
        />
      </ContentContainer>
    </>
  );
};

export default WhatsUpInYourCityPage;
