import { css } from '@emotion/react';
import { AddressSelectionModal } from 'components/address-manager';
import { Button, TextField, Image } from 'components/elements';
import Footer from 'components/footer';
import { Loader } from 'components/inthecity';
import { Box, Flex, Grid } from 'components/layout';
import { SuggestedServiceButton } from 'components/private-services/service-button';
import { Text } from 'components/typography';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';
import {
  CustomerPremises,
  ServiceSelection,
  SuggestedService,
} from 'types/types';
import {
  formatForcelinkAddress,
  shortenWithEllipses,
} from 'functions/address-utils';
import { useGlobalContext } from 'contexts/global';
import { HomePageEvents, logFirebaseEvent } from 'functions/firebase';

import SuccessModal from './success-modal';

const CUSTOM_SUGGESTED_SERVICE_VALUE = 27183;

const SuggestAServicePage = () => {
  const {
    loading,
    response: services,
    callApi: getSuggestedServices,
  } = useSmartCityManager(SCManagerEndpoints.SuggestedServices);
  const {
    loading: submissionLoading,
    response: submissionResponse,
    error: submissionError,
    callApi: callSubmit,
  } = useSmartCityManager(SCManagerEndpoints.SuggestNewService);

  const {
    globalState: {
      user: { uuid },
      city: { label },
    },
    setShowLoginModal,
  } = useGlobalContext();

  const [serviceCategories, setServiceCategories] = useState<string[]>([]);
  const [selectedServices, setSelectedServices] = useState<ServiceSelection[]>(
    []
  );
  const [customService, setCustomService] = useState('');
  const [SuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(
    !(!uuid || uuid === '' || uuid === 'guest' || uuid === 'init')
  );
  const [location, setLocation] = useState<CustomerPremises | null>(null);
  const navigate = useNavigate();

  const displayAddress =
    location && shortenWithEllipses(location.addressString, 40);

  const handleSubmit = () => {
    logFirebaseEvent(HomePageEvents.SubmitSuggestion);

    const selectedServicesFormatted = selectedServices.map((s) => ({
      label: s.label,
      value: s.value,
    }));
    const body =
      customService !== ''
        ? [
            ...selectedServicesFormatted,
            { label: customService, value: CUSTOM_SUGGESTED_SERVICE_VALUE },
          ]
        : [...selectedServicesFormatted];

    callSubmit({
      body,
      queryParams: `latitude=${location?.latitude}&longitude=${location?.longitude}`,
    });
  };

  const handleSelectionClick = (
    service: ServiceSelection,
    isSelected?: boolean
  ) => {
    if (isSelected) {
      setSelectedServices(
        selectedServices.filter((s) => s.value !== service.value)
      );
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  useEffect(() => {
    if (submissionResponse) {
      setCustomService('');
      setSelectedServices([]);
      setSuccessModalOpen(true);
    }
  }, [submissionResponse, submissionError]);

  useEffect(() => {
    if (
      !uuid ||
      uuid === '' ||
      uuid === 'guest' ||
      (uuid === 'init' && !label)
    ) {
      setShowLoginModal(true);
    } else {
      if (!services) {
        getSuggestedServices();
      }
      if (!loading && !!services) {
        setServiceCategories(
          Array.from(
            new Set(
              services.map(
                (service: SuggestedService) => service.attributes.Category
              )
            )
          )
        );
      }
    }
  }, [loading, services, uuid]);

  const buttonsDisabled =
    !(selectedServices.length > 0 || customService !== '') ||
    loading ||
    submissionLoading;

  const submitButtonText = () => {
    // This was way too much effort for such a simple feature
    let numSuggestions = selectedServices.length;
    if (customService !== '') {
      numSuggestions += 1;
    }
    if (submissionLoading) {
      return 'Submitting suggestions...';
    }
    if (buttonsDisabled) {
      return 'Submit suggestions';
    }
    return `Submit ${numSuggestions} suggestion${
      numSuggestions > 1 ? 's' : ''
    }`;
  };

  return (
    <>
      <SuccessModal
        isOpen={SuccessModalOpen}
        onClose={() => {
          setSuccessModalOpen(false);
          navigate('/');
        }}
      />
      <AddressSelectionModal
        isOpen={locationModalOpen}
        setAddress={(address) => setLocation(address)}
        onClose={() => setLocationModalOpen(false)}
      />
      <Flex justifyContent="center" flexDirection="column">
        <Flex
          py="2%"
          borderB={`2px solid ${theme.colors.borderGrey}`}
          justifyContent="center"
        >
          <Box>
            <Text
              textAlign="center"
              color={theme.colors.darkGrey}
              fontSize="25px"
              fontWeight="500"
            >
              Suggest services
            </Text>
            <Text textAlign="center" fontSize="16px">
              Please select services to suggest
            </Text>
            {location && (
              <Button
                width="100%"
                onClick={() => setLocationModalOpen(true)}
                mt="3%"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Image
                    source="privateServices/gray-pin.svg"
                    alt="pin"
                    mr="10px"
                    height="20px"
                  />
                  <Text color={theme.colors.primary} fontWeight="bold">
                    {formatForcelinkAddress(displayAddress) ||
                      'Please select an address'}
                  </Text>
                </Flex>
              </Button>
            )}
          </Box>
        </Flex>

        <Flex
          px="15%"
          flexDirection="column"
          pt="20px"
          overrideCss={css`
            @media ${theme.mediaQueries.mobileOnly} {
              padding-left: 0;
              padding-right: 0;
            }
          `}
        >
          {!location ? (
            <Button
              backgroundColor={theme.colors.primary}
              p="10px"
              mb="200px"
              borderRadius={theme.borderRadius.small}
              onClick={() => setLocationModalOpen(true)}
            >
              <Text color="white">
                Please select a location to suggest services at
              </Text>
            </Button>
          ) : (
            <>
              {loading || !services ? (
                <Loader />
              ) : (
                serviceCategories.map((category: string, idx: number) => (
                  <Box
                    key={idx}
                    mb="20px"
                    pb="20px"
                    borderB={`1px solid ${theme.colors.borderGrey}`}
                  >
                    <Text fontSize="18px" ml="20px" color="#a8a8a8">
                      {category}
                    </Text>
                    <Grid
                      gridTemplateColumns="repeat(6, 1fr)"
                      gridRowGap="20px"
                      gridColumnGap="20px"
                      hideForMobile
                      overrideCss={css`
                        @media ${theme.mediaQueries.tabletOnly} {
                          grid-template-columns: repeat(4, 1fr);
                        }
                      `}
                    >
                      {services
                        .filter(
                          (s: SuggestedService) =>
                            s.attributes.Category === category
                        )
                        .map((service: SuggestedService) => (
                          <SuggestedServiceButton
                            onClick={handleSelectionClick}
                            service={service}
                            key={service.value}
                            isSelected={selectedServices
                              .map((s) => s.value)
                              .includes(service.value)}
                          />
                        ))}
                    </Grid>
                    <Flex
                      overflowX="scroll"
                      hideForTablet
                      hideForDesktop
                      overrideCss={css`
                        ::-webkit-scrollbar {
                          display: none;
                        }
                      `}
                    >
                      <Box minWidth="20px" />
                      {services
                        .filter(
                          (s: SuggestedService) =>
                            s.attributes.Category === category
                        )
                        .map((service: SuggestedService) => (
                          <SuggestedServiceButton
                            service={service}
                            key={service.value}
                            onClick={handleSelectionClick}
                            isSelected={selectedServices
                              .map((s) => s.value)
                              .includes(service.value)}
                          />
                        ))}
                      <Box minWidth="30px" />
                    </Flex>
                  </Box>
                ))
              )}
              <Box mb="10px">
                <Text fontSize="18px" ml="20px" color="#a8a8a8">
                  Other services
                </Text>
              </Box>
              <TextField
                value={customService}
                onChange={(event) => setCustomService(event.target.value)}
                placeholder="Suggest Service e.g Dog Walker"
                color="#828181"
                overrideCss={css`
                  @media ${theme.mediaQueries.mobileOnly} {
                    margin: 0 20px;
                  }
                `}
              />
              <Box hideForDesktop hideForTablet height="120px" />
              <Flex
                width="100%"
                justifyContent="flex-end"
                my="20px"
                overrideCss={css`
                  @media ${theme.mediaQueries.mobileOnly} {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    background-color: white;
                    padding: 20px;
                    margin: 0;
                    justify-content: center;
                    box-shadow: 0px -3px 15px #0000000d;
                  }
                `}
              >
                <Button
                  disabled={buttonsDisabled}
                  border={`1px solid ${theme.colors.primary}`}
                  color={theme.colors.primary}
                  borderRadius={theme.borderRadius.small}
                  p="20px"
                  mr="10px"
                  fontSize="18px"
                  overrideCss={css`
                    @media ${theme.mediaQueries.mobileOnly} {
                      font-size: 16px;
                      padding: 10px;
                      width: 100%;
                    }
                    ${buttonsDisabled &&
                    css`
                      border-color: ${theme.colors.borderGrey};
                      color: ${theme.colors.borderGrey};
                    `}
                  `}
                  onClick={() => {
                    setSelectedServices([]);
                    setCustomService('');
                  }}
                >
                  Clear selection
                </Button>
                <Button
                  onClick={handleSubmit}
                  backgroundColor={theme.colors.primary}
                  color="white"
                  width="fit-content"
                  p="20px"
                  fontSize="18px"
                  borderRadius={theme.borderRadius.small}
                  disabled={buttonsDisabled}
                  overrideCss={css`
                    @media ${theme.mediaQueries.mobileOnly} {
                      font-size: 16px;
                      padding: 10px;
                      width: 100%;
                    }
                    ${buttonsDisabled &&
                    css`
                      background-color: ${theme.colors.borderGrey};
                    `}
                  `}
                >
                  {submitButtonText()}
                </Button>
              </Flex>
            </>
          )}
        </Flex>
        <Box hideForMobile>
          <Footer />
        </Box>
      </Flex>
    </>
  );
};

export default SuggestAServicePage;
