import styled from 'styled-components';

import { GenericDropdown } from '../navbar/navbar.styled';

export const Dropdown = styled(GenericDropdown)`
  position: absolute;
  right: 0px;
  top: -235px;
  border-radius: 12px;
  padding: 25px;
  width: 200px;
  @media only screen and (min-width: 800px) {
    border-radius: 25px;
    padding: 25px;
    width: 307px;
    top: -375px;
  }
`;

export const Item = styled.div`
  display: flex;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 13px;
  }
  @media only screen and (min-width: 1150px) {
    border-radius: 3px;
    padding: 12px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &:hover {
      background: rgba(178, 188, 193, 0.1);
    }
  }
`;

export const Icon = styled.div`
  background: url(${({ src }) => src}) 50% 50% no-repeat padding-box;
  background-color: rgba(178, 188, 193, 0.1);
  border-radius: 50%;
  background-size: auto 12px;
  width: 35px;
  height: 35px;
  @media only screen and (min-width: 1150px) {
    background-size: auto;
    width: 42px;
    height: 42px;
  }
`;

export const Label = styled.p`
  font: normal normal normal 12px/35px Roboto;
  letter-spacing: 0px;
  color: #333333;
  margin: 0 0 0 25px;
  @media only screen and (min-width: 1150px) {
    margin: 0 0 0 30px;
    font: normal normal normal 14px/42px Roboto;
  }
`;
