import styled from 'styled-components';

import { Body, Subtitle } from 'components';

export const ItemContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  &:not(:last-child) {
    margin-bottom: 18px;
    @media only screen and (min-width: 800px) {
      margin-bottom: 30px; 
    }
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid hsla(210.0000000000001, 13.793103448275856%, 88.62745098039215%, 0.46);
  margin-left: 12px;
  height: calc(48px + 16px);
  padding-bottom: 16px;
  @media only screen and (min-width: 800px) {
    border-bottom: 2px solid hsla(210.0000000000001, 13.793103448275856%, 88.62745098039215%, 0.46);
    margin-left: 24px;
    height: calc(80px + 25px);
    padding-bottom: 25px;
  }
`;

export const Username = styled(Body)`
  /* font: normal normal normal 12px/14px SF Pro, Roboto; */
  letter-spacing: 0px;
  color: #0A0918;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    /* font: normal normal normal 20px/24px SF Pro, Roboto; */
  }
`;

export const City = styled(Body)`
  /* font: normal normal normal 10px/12px SF Pro, Roboto; */
  letter-spacing: 0px;
  color: #B2BCC1;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    /* font: normal normal normal 16px/19px SF Pro, Roboto; */
  }
`;

export const Category = styled(Body)`
  /* font: normal normal normal 10px/12px SF Pro, Roboto; */
  letter-spacing: 0px;
  color: #DF6E21;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    /* font: normal normal normal 16px/19px SF Pro, Roboto; */
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 25%;
  right: 0;
`;

export const Title = styled(Subtitle)`
  /* font: normal normal 500 12px/14px Roboto; */
  color: #0A0918;
  margin-bottom: 23px;
  @media only screen and (min-width: 800px) {
    /* font: normal normal 500 20px/26px Roboto; */
  }
`;

export const ProfileImage = styled.img`
  object-fit: cover;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  @media only screen and (min-width: 800px) {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }
`;

export const VerifiedIcon = styled.img`
  margin-left: 12px;
  height: 11px;
  width: 11px;
  @media only screen and (min-width: 800px) {
    margin-left: 15px;
    height: 14px;
    width: 14px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
