import { apiUrl } from 'constants/app';
import { Cookies } from 'react-cookie';
import {
  auth,
  providerGoogle,
  providerFacebook,
  errorHandler,
} from 'interface/firebase';
import { validateUserModel } from 'functions/login';
import { updatePassword } from 'firebase/auth';

const cookies = new Cookies();

//---------------------------------------------------------------
// USER SESSION AUTHENTICATION
//---------------------------------------------------------------

/**
 * Checks if user firebaseToken is still valid on MSC Backend
 * @returns {boolean} Returns true firebaseToken is still valid
 */
export const isAuthenticated = () => {
  const firebaseToken = cookies.get('firebaseToken');
  return fetch(`${apiUrl}smartcitymanager/isAuthenticated`, {
    method: 'POST',
    async: true,
    body: firebaseToken,
    headers: { firebase_token: firebaseToken },
  }).then((res) =>
    res
      .json()
      .then((result) => {
        if (result) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      })
  );
};

/**
 * Checks if user firebaseToken is still valid on MSC Backend
 * @returns {boolean} Returns true firebaseToken is still valid
 */
export const isAnonymousToken = () => {
  const firebaseToken = cookies.get('firebaseToken');
  return fetch(`${apiUrl}smartcitymanager/isAnonymousTokenValid`, {
    method: 'POST',
    async: true,
    body: firebaseToken,
    headers: { firebase_token: firebaseToken },
  }).then((res) =>
    res
      .json()
      .then((result) => {
        if (result) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      })
  );
};

//---------------------------------------------------------------
// GET CURRENT USER
//---------------------------------------------------------------

/**
 * Uses firebaseToken to query forcelink to get current user model
 * @returns {JSON} Returns usermodel
 */
export const getUser = () => {
  const firebaseToken = cookies.get('firebaseToken');
  return fetch(`${apiUrl}smartcitymanager/getSmartCityUser`, {
    method: 'POST',
    async: true,
    body: firebaseToken,
    headers: { firebase_token: firebaseToken },
  }).then((res) =>
    res
      .json()
      .then((result) => result)
      .catch((err) => {
        console.error(err);
        return false;
      })
  );
};

//---------------------------------------------------------------
// USER CREATION
//---------------------------------------------------------------

/**
 * MSC REST Call to create a user in firebaseAuth
 * @param {string} email User's email address
 * @param {string} password User's password
 * @returns {string} Array containing the user's firebase and UID
 */
export const createEmailUser = async (email, password, setState) => {
  console.log('Signing in with email:', email);
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      const { user } = userCredential;
      const { uid } = user;
      return uid;
    })
    .catch((error) => {
      console.log(error.message);
      errorHandler(error, setState);
      return 'false';
    });
};

/**
 * MSC REST Call to create a user in firebaseAuth using google oauth
 * @returns {Array<string>} Array containing the user's email and firebase UID
 */
// eslint-disable-next-line max-len
export const signUpWithGoogle = async (setState) =>
  auth
    .signInWithPopup(providerGoogle)
    .then((result) => {
      const { user } = result;
      return user;
    })
    .catch((error) => {
      errorHandler(error, setState);
      return 'false';
    });

/**
 * Calls firebaseAuth to authenticate via facebook popup
 * @returns {Array<string>} Array containing the user's firebase token and UID
 */
// eslint-disable-next-line max-len
export const signUpWithFacebook = async (setState) =>
  auth
    .signInWithPopup(providerFacebook)
    .then((result) => {
      const { user } = result;
      console.log('signUpWithFacebook', result);
      return user;
    })
    .catch((error) => {
      errorHandler(error, setState);
      return 'false';
    });

/**
 * MSC REST Call to create a user that has been initialised in firebaseAuth
 * @param {JSON} smartCityUser MSC User object created for MSC REST Service Consumption
 * @returns {JSON} MSC User object if successful
 */
export const createUser = async (smartCityUser, setState) =>
  fetch(`${apiUrl}smartcitymanager/signUp2`, {
    method: 'POST',
    async: true,
    body: JSON.stringify(smartCityUser),
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw res;
    })
    .catch((error) =>
      error.text().then((errorM) => {
        errorHandler({ message: errorM }, setState);
        return 'false';
      })
    );

//---------------------------------------------------------------
// USER SIGN IN
//---------------------------------------------------------------

/**
 * MSC REST Call to sign in a user that has been authenticated via firebaseAuth
 * @param {string} firebaseToken Token given via firebaseAuth
 */
const loginForcelink = (firebaseToken, user) =>
  fetch(`${apiUrl}smartcitymanager/signIn`, {
    method: 'POST',
    async: true,
    body: firebaseToken,
    headers: { firebase_token: firebaseToken },
  }).then((res) =>
    res
      .json()
      .then((result) => {
        console.log('user!', user);
        if (validateUserModel(result)) {
          cookies.set('firebaseToken', firebaseToken, { path: '/' });
          cookies.set('currentUser', result.uuid, { path: '/' });
          return result;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
      })
  );

/**
 * Calls firebaseAuth to authenticate via email with given credentials
 * @param {string} email User's email address
 * @param {*} password User's password
 * @param {Function} setState SetState function to map errors back to the login modal
 * @returns {user} user if successful
 */
export const signInWithEmail = async (email, password, setState) =>
  auth
    .signInWithEmailAndPassword(email, password)
    .then((userCredential) =>
      userCredential.user
        .getIdToken()
        .then((idToken) => loginForcelink(idToken))
    )
    .catch((error) => {
      errorHandler(error, setState);
    });

/**
 * Calls firebaseAuth to authenticate via google popup
 * @param {Function} setState SetState function to map errors back to the login modal
 * @returns {user} user if successful
 */
export const signInWithGoogle = async (setState) =>
  auth
    .signInWithPopup(providerGoogle)
    .then((userCredential) =>
      userCredential.user
        .getIdToken()
        .then((idToken) => loginForcelink(idToken, userCredential.user))
    )
    .catch((error) => {
      errorHandler(error, setState);
    });

/**
 * Calls firebaseAuth to authenticate via facebook popup
 * @param {Function} setState SetState function to map errors back to the login modal
 * @returns {user} user if successful
 */
export const signInWithFacebook = async (setState) =>
  auth
    .signInWithPopup(providerFacebook)
    .then((userCredential) => {
      userCredential.user
        .getIdToken()
        .then((idToken) => loginForcelink(idToken));
    })
    .catch((error) => {
      errorHandler(error, setState);
    });

//---------------------------------------------------------------
// USER CREDENTIALS
//---------------------------------------------------------------

/**
 * Calls firebaseAuth to get firebaseToken
 * @returns {string} firebaseToken to be used for authentication
 */
export const getFirebaseToken = async () =>
  auth.currentUser
    .getIdToken(true)
    .then((idToken) => idToken)
    .catch((error) => {
      console.log('', error);
    });

/**
 * Calls firebaseAuth to get UID from firebaseAuth
 * @returns {string} UID used in firebaseAuth
 */
export const getFirebaseUserUID = async () => {
  const user = auth.currentUser;
  if (user) {
    return user.uid;
  }
  return null;
  // No user is signed in.
};

/**
 * Calls firebaseAuth to update the current users email
 * @param {string} newEmail The users new email
 */
export const updateEmail = async (newEmail) => {
  const user = auth.currentUser;

  user
    .updateEmail(newEmail)
    .then(() => '')
    .catch((error) => {
      console.log(`An error occurred updating your email ${error}`);
      return error;
    });
};

/**
 * Calls firebaseAuth to update the current users password
 * @param {string} newPassword The users new password
 */
export const updateUserPassword = async (newPassword) => {
  updatePassword(newPassword)
    .then(() => console.log('Updating password'))
    .catch((error) => {
      console.log(`An error occurred updating your password ${error}`);
      return error;
    });
};

/**
 * Calls firebaseAuth to send password reset email
 * @param {string} email User's email (username) from firebase to send password reset email
 * @param {Function} setState SetState function to map errors back to the login modal
 */
export const sendResetEmail = async (email, setState) => {
  auth
    .sendPasswordResetEmail(email)
    .then(() => '')
    .catch((e) => {
      errorHandler(e, setState);
    });
};

export const userExists = async (email) =>
  fetch(`${apiUrl}smartcitymanager/userExists?email=${email}`, {
    method: 'GET',
    async: false,
    credentials: 'include',
    headers: {
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        // You have a user and the reponse has google.com | facebook.com | password (email)
        return res.text();
      }
      return false;
    })
    .catch((e) => e);

export const isEmailVerified = async (email) =>
  fetch(`${apiUrl}smartcitymanager/isEmailVerified?email=${email}`, {
    method: 'GET',
    async: false,
    credentials: 'include',
    headers: {
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

/**
 * Calls firebaseAuth to send verification email
 * @param {string} email User's email (username) from firebase to send verification email
 * @param {Function} setState SetState function to map errors back to the login modal
 */
export const sendVerificationEmail = async (setState) => {
  auth.currentUser
    .sendEmailVerification()
    .then(() => '')
    .catch((e) => {
      errorHandler(e, setState);
    });
};

export const getProviders = async () => auth.currentUser;

//---------------------------------------------------------------
// USER LOGOUT
//---------------------------------------------------------------

/**
 * Calls firebaseAuth to logOut and then remove cookies from browser
 */
export const logOut = () => {
  auth
    .signOut()
    .then(() => {
      cookies.remove('firebaseToken', { path: '/' });
      cookies.remove('currentUser', { path: '/' });
      console.log('Signed Out');
    })
    .catch(() => {
      console.log('Uhmmmm No Signing out for you');
    });
};
