import styled from 'styled-components';

export const StyledFilterBar = styled.div`
  height: min-content;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const SearchInput = styled.input`
  font-family: Gotham;
  color: #b3b3b3;
  border: 1px solid ${({ error = false }) => (error ? '#de6e21' : '#b0b0b0')};
  border-radius: 8px;
  padding: 1.75rem;
  margin-bottom: 6rem;
  font-size: 1.75rem;
  width: min-content;

  background-image: url(/${({ icon }) => icon});
  background-size: 2rem;
  background-position: 1.5rem 50%;
  background-repeat: no-repeat;
  padding-left: 6rem;
  @media only screen and (max-width: 950px) {
    font-size: 1.25rem;
    padding: 1.25rem;
    margin-bottom: 2rem;

    background-size: 1.25rem;
    background-position: 1.25rem 50%;
    padding-left: 4rem;
    width: 100%;
  }
  ::placeholder {
    opacity: 1;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 10px #cccccc;
  }
`;

export const Select = styled.select`
  appearance: none;
  font-family: Gotham;
  color: #b3b3b3;
  background-color: white;
  border: 1px solid ${({ error = false }) => (error ? '#de6e21' : '#b0b0b0')};
  border-radius: 8px;
  padding: 1.75rem;
  ${({ icon }) => icon && `
    padding-left: 6rem;
  `}
  width: min-content;
  margin-bottom: 6rem;
  font-size: 1.75rem;
  background-image: url('/home/dropdown.svg'), url(/${({ icon }) => icon});
  background-size: 2rem, 2rem;
  background-position: 95% 50%, 1.5rem 50%;
  background-repeat: no-repeat, no-repeat;
  @media only screen and (max-width: 950px) {
    font-size: 1.25rem;
    padding: 1.25rem;
    ${({ icon }) => icon && `
      padding-left: 4rem;
    `}
    margin-bottom: 2rem;
    background-size: 1.25rem;
    width: 100%;
  }
  ::placeholder {
    opacity: 1;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 10px ${({ error = false }) => (error ? '#de6e21' : '#cccccc')};
  }
`;

export const Option = styled.option`
  padding: 1.75rem;
  font-size: 1.75rem;
  color: #b3b3b3;
  @media only screen and (max-width: 950px) {
    font-size: 1.25rem;
    padding: 1.25rem;
  }
`;
