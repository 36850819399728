import React, { useState } from 'react';

import { useGlobalContext } from 'contexts/global';
import { Modal } from 'components/elements';
import { Text } from 'components/social/text';
import { Flex } from 'components/layout';
import theme from 'theme';
import { css } from '@emotion/react';
import { UserEvents, logFirebaseEvent } from 'functions/firebase';
import {
  SelectContainer,
  CitySelect,
  SubmitButton,
  Option,
} from './locationmodal.styled';
import { Radio } from './filter.styled';

const LocationForm = ({ handleClose }: { handleClose: () => void }) => {
  // context
  const { setCurrentCity, globalState } = useGlobalContext();

  // state
  const [open, setOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(globalState.city);

  const handleClickCity = (c: any) => {
    setOpen(false);
    setSelectedCity(c);
  };
  const handleSubmit = async () => {
    logFirebaseEvent(UserEvents.SelectCity, {
      selectedCity: selectedCity.label,
    });
    handleClose();
    setCurrentCity(selectedCity);
  };

  return (
    <>
      <SelectContainer>
        <CitySelect open={open} onClick={() => setOpen(!open)}>
          {selectedCity?.label || 'Please select a city'}
        </CitySelect>
        {open && (
          <Flex
            flexDirection="column"
            position="fixed"
            maxHeight="30%"
            width="100%"
            p="10px"
            borderRadius={theme.borderRadius.medium}
            backgroundColor="white"
            boxShadow={theme.elevationShadow}
            overflowY="scroll"
            maxWidth="450px"
            zIndex={1000}
            mobileCss={css`
              max-height: 300px;
              max-width: 200px;
            `}
          >
            {globalState.availableCities.map((c: any) => (
              <Option onClick={() => handleClickCity(c)}>
                <Text>{c.label}</Text>
                <Radio
                  type="radio"
                  checked={c.value === globalState.city.value}
                />
              </Option>
            ))}
          </Flex>
        )}
      </SelectContainer>

      <SubmitButton type="submit" onClick={handleSubmit}>
        Apply Changes
      </SubmitButton>
    </>
  );
};

type LocationModalProps = {
  show: boolean;
  handleClose: () => void;
};

const LocationModal = ({ ...props }: LocationModalProps) => (
  <Modal title="Change city" isOpen={props.show} onClose={props.handleClose}>
    <LocationForm handleClose={props.handleClose} />
  </Modal>
);

export default LocationModal;
