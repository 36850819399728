import { css } from '@emotion/react';
import { Button, Image, TextField } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import theme from 'theme';
import { GMSurveyCategory, GMSurveyQuestion } from 'types/types';

const surveyQuestionColorIndex = [
  {
    active: '#EA1B1B',
    inactive: '#FDE8E8',
  },
  {
    active: '#E7B183',
    inactive: '#F8E7D9',
  },
  {
    active: '#DF6E21',
    inactive: '#FAE9DE',
  },
  {
    active: '#FFD600',
    inactive: '#FFF6C9',
  },
  {
    active: '#00A300',
    inactive: '#E6F6E6',
  },
];

const SurveyQuestion = ({
  category,
  answer,
  onChange,
  updateComment,
}: {
  category?: GMSurveyCategory;
  answer?: number;
  onChange: (questionId: number, answer: number) => void;
  updateComment?: (questionId: number, comment: string) => void;
}) => (
  <Flex
    flexDirection="column"
    maxWidth="520px"
    width="100%"
    mb="20px"
    mx="auto"
    p="8px 16px 16px 16px"
    boxShadow={theme.cardShadow}
  >
    <Text fontWeight="bold" my="8px">
      {category?.description}
    </Text>
    {category?.questions.map((question: GMSurveyQuestion, idx: number) => (
      <>
        <Text>{question?.question}</Text>
        <Grid
          gridTemplateColumns="repeat(5, 1fr)"
          gridColumnGap="10px"
          mt="20px"
        >
          {Array.from({ length: question.min }, (_, i) => i + 1).map((num) => (
            <Button
              width="100%"
              backgroundColor={
                answer || category.questions[idx].answer === num
                  ? surveyQuestionColorIndex[num - 1].active
                  : surveyQuestionColorIndex[num - 1].inactive
              }
              borderRadius="4px"
              color={
                answer || category.questions[idx].answer === num
                  ? '#fff'
                  : '#000'
              }
              hoverCss={css`
                background-color: ${surveyQuestionColorIndex[num - 1].active};
              `}
              py="6px"
              onClick={() => onChange(question?.id, num)}
            >
              {num}
            </Button>
          ))}
        </Grid>
        <Box my="10px" width="100%">
          <Flex justifyContent="space-between" width="100%" my="10px">
            <Image source="my-municipality/sad.svg" alt="sad" />
            <Image source="my-municipality/happy.svg" alt="happy" />
          </Flex>
          {question.requiresTextBox && updateComment && (
            <TextField
              width="100%"
              placeholder="Write your comments here"
              multiline
              onChange={(e) => updateComment(question.id, e.target.value)}
            />
          )}
        </Box>
      </>
    ))}
  </Flex>
);

export default SurveyQuestion;
