/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import { imageUrl } from 'functions/social';
import { useGlobalContext } from 'contexts/global';
import {
  getAllOpenWorkRequestsForUser,
  postWorkRequest,
} from 'interface/social';
import { Modal } from 'components/social';
import {
  Form,
  SelectButton,
  SubmitButton,
  Loader,
  SmallLoader,
  NotFoundContainer,
  WorkRequestListContainer,
} from './createpost.styled';
import { WorkRequestListItem, Posted, Discard } from './createpostItems';

const defaultValues = {
  loading: false,
  wrList: [],
  wr: null,
  title: '',
};

const CreateReportPost = ({ show, handleClose, handleRefreshNewsFeed }) => {
  const [stage, setStage] = useState(0);
  const [state, setState] = useState(defaultValues);
  const { globalState } = useGlobalContext();

  const update = (updatedState) => setState({ ...state, ...updatedState });

  useEffect(() => {
    async function fetchData() {
      update({ loading: true });
      const res = await getAllOpenWorkRequestsForUser();
      update({
        wrList: res.data,
        loading: false,
      });
    }
    fetchData();
  }, []);

  const handleClickClose = () =>
    _.isEqual(state, defaultValues) ? handleClose() : setStage(3);

  switch (stage) {
    case 0:
      return (
        <Modal
          title="Create Report Post"
          show={show}
          handleClose={handleClickClose}
        >
          <ReportForm
            state={state}
            update={update}
            handleReportsList={() => setStage(1)}
            handlePosted={() => setStage(2)}
          />
        </Modal>
      );
    case 1:
      return (
        <Modal
          title="Choose Reports"
          show={show}
          closeButton={false}
          backButton
          handleBack={() => setStage(0)}
          handleClose={handleClickClose}
        >
          {state.loading && <Loader />}
          {state.wrList.length === 0 && !state.loading && (
            <NotFoundContainer>
              <h2>No reports found</h2>
              <p>
                <Link to="/map">Create a report</Link>
                &nbsp;and then post about it here.
              </p>
            </NotFoundContainer>
          )}
          <WorkRequestListContainer>
            {Array.isArray(state.wrList) &&
              state.wrList
                .slice()
                .reverse()
                .map((item, index) => (
                  <WorkRequestListItem
                    address={item.address}
                    date={item.createdDate}
                    description={item.description}
                    image={
                      item.imageIds?.length > 0
                        ? imageUrl(
                            item.imageIds[0],
                            globalState.city?.attributes?.subscriberId
                          )
                        : 'social/no-image.png'
                    }
                    status={item.systemStatus}
                    onClick={() => {
                      update({ wr: item });
                      setStage(0);
                    }}
                    key={index}
                  />
                ))}
          </WorkRequestListContainer>
        </Modal>
      );
    case 2:
      return (
        <Modal
          show={show}
          handleClose={() => {
            setStage(0);
            setState(defaultValues);
            handleClose();
          }}
        >
          <Posted
            type="Report"
            handleClose={() => {
              setStage(0);
              setState(defaultValues);
              handleClose();
              handleRefreshNewsFeed();
            }}
          />
        </Modal>
      );
    case 3:
      return (
        <Modal
          show={show}
          closeButton={false}
          handleClose={() => {}}
          noFullHeight
        >
          <Discard
            handleBack={() => setStage(0)}
            handleClose={() => {
              setStage(0);
              setState(defaultValues);
              handleClose();
            }}
          />
        </Modal>
      );
    default:
      return null;
  }
};

const ReportForm = (props) => {
  const { state, update, handleReportsList, handlePosted } = props;

  const [loading, setLoading] = useState(false);

  const {
    // register,
    handleSubmit,
    formState: { isValid }, //  errors,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    console.log('form: ', data);
    console.log('form wr: ', state.wr);
    setLoading(true);
    await postWorkRequest(state.wr.id, data.title);
    setLoading(false);
    handlePosted();
  };

  const handleClick = () => {
    update({ form: getValues() });
    handleReportsList();
  };

  console.log('state', state);
  console.log('state formdata', getValues());

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <SelectButton onClick={handleClick}>
        <p>{state.wr?.description || 'Choose from my reports'}</p>
      </SelectButton>

      {/* <Input
        largeMargin
        error={errors.title}
        placeholder="Add a Title (optional)"
        defaultValue={state.form?.title}
        {...register('title', { required: false })}
        autocomplete="off"
      />
      {errors.title && <ErrorLabel>Required field</ErrorLabel>} */}

      <SubmitButton type="submit" disabled={!isValid || !state.wr}>
        {loading && <SmallLoader />}
        {loading ? 'Publishing Post...' : 'Publish Post'}
      </SubmitButton>
    </Form>
  );
};

export default CreateReportPost;
