/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Loader } from 'components/inthecity';
import {
  getImageURL,
  getKinektekTransactionHistory,
} from 'interface/inthecity/inthecity';
import {
  // ProductItem,
  ProductContent,
  // ProductButton,
} from 'components/inthecity/lottoModal/lottoModal.styled';
import { Line } from 'pages/inthecity/purchase.styled';
import {
  Container,
  Header,
  List,
  Title,
  TextButton,
  ProductItem,
  ProductButton,
  ProductWrapper,
} from './transactionHistoryList.style';

const TransactionHistoryList = (props) => {
  // props
  const { id, handleBuyAgain, handleShowOrderDetails, refresh } = props;

  // state
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const airtimeTransactions =
    transactions &&
    transactions.filter((t) => t.customerDetail?.issuer !== 'iThuba');
  const lottoTransactions =
    transactions &&
    transactions.filter((t) => t.customerDetail?.issuer === 'iThuba');

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setTransactions(await getKinektekTransactionHistory());
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function refreshData() {
      setTransactions(await getKinektekTransactionHistory());
    }
    refreshData();
  }, [refresh]);

  useEffect(() => setShowMore(false), [transactions]);

  return (
    <Container>
      <Line />

      <Header>
        <Title>Recent Purchases</Title>
        {id === 0 && airtimeTransactions.length > 5 && (
          <TextButton onClick={() => setShowMore(!showMore)}>
            {showMore ? 'See Less' : 'See More'}
          </TextButton>
        )}
        {id === 1 && lottoTransactions.length > 5 && (
          <TextButton onClick={() => setShowMore(!showMore)}>
            {showMore ? 'See Less' : 'See More'}
          </TextButton>
        )}
      </Header>

      {loading && <Loader />}

      <List>
        {id === 0 &&
          airtimeTransactions.slice(0, showMore ? -1 : 5).map(
            (t, i) =>
              t.customerDetail?.issuer !== 'iThuba' && (
                <ProductWrapper key={i}>
                  <ProductButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleBuyAgain(t);
                    }}
                  >
                    Buy Again
                  </ProductButton>

                  <ProductItem
                    onClick={() => handleShowOrderDetails(null, t?.sessionId)}
                  >
                    <img
                      src={
                        t?.customerDetail?.imageName
                          ? getImageURL(t?.customerDetail?.imageName)
                          : `inthecity/purchase/${t?.customerDetail?.issuer}.png`
                      }
                      alt="Airtime"
                    />
                    <ProductContent>
                      <p>{t?.customerDetail?.name || 'Unknown product'}</p>
                      <p>{`R ${t.amount.toFixed(2)} · ${
                        t?.customerDetail?.mobileNumber
                      } · ${moment(t.timeStamp).fromNow()}`}</p>
                    </ProductContent>
                  </ProductItem>
                </ProductWrapper>
              )
          )}

        {id === 1 &&
          lottoTransactions.slice(0, showMore ? -1 : 5).map(
            (t, i) =>
              t.customerDetail?.issuer === 'iThuba' && (
                <ProductWrapper key={i}>
                  <ProductButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleBuyAgain(t);
                    }}
                  >
                    Buy Again
                  </ProductButton>

                  <ProductItem
                    onClick={() => handleShowOrderDetails(null, t?.sessionId)}
                  >
                    <img
                      src={
                        t?.customerDetail?.imageName
                          ? getImageURL(t?.customerDetail?.imageName)
                          : 'inthecity/purchase/iThuba.png'
                      }
                      alt="iThuba"
                    />
                    <ProductContent>
                      <p>{t?.customerDetail?.name || 'Unknown product'}</p>
                      <p>{`R ${t.amount.toFixed(2)} · ${
                        t?.customerDetail?.mobileNumber
                      } · ${moment(t.timeStamp).fromNow()}`}</p>
                    </ProductContent>
                  </ProductItem>
                </ProductWrapper>
              )
          )}
      </List>
    </Container>
  );
};

export default TransactionHistoryList;
