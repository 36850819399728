import React from 'react';
import styled from 'styled-components';

const getColor = (color) => {
  const lookup = {
    orange: '#e17531',
    blue: '#0098b5',
    dark: '#333333',
    light: '#dfeaef',
  };
  return lookup[color] ? lookup[color] : color;
};

export const Heading = styled.h2`
  z-index: 10;
  position: relative;
  font-family: GothamMedium;
  text-align: ${({ align = 'left' }) => align};
  ${({ color }) => color && `
    color: ${getColor(color)};
  `}
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  ::first-letter {
    ${({ color }) => color && `
      color: ${getColor(color)};
    `}
    background-image: url(/${({ icon = '' }) => icon});
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0.75rem 0.5rem 0 0.75rem;
    margin: -0.75rem -0.5rem 0 -0.75rem;
  }
  @media only screen and (min-width: 600px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
    ::first-letter {
      padding: 1rem 0.5rem 0 1rem;
      margin: -1rem -0.5rem 0 -1rem;
    }
  }
  @media only screen and (min-width: 900px) {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 3rem;
    ::first-letter {
      padding: 1.5rem 0.5rem 0 1.5rem;
      margin: -1.5rem -0.5rem 0 -1.5rem;
    }
  }
  @media only screen and (min-width: 1400px) {
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 4rem;
    ::first-letter {
      padding: 2rem 1rem 0 2rem;
      margin: -2rem -1rem 0 -2rem;
    }
  }
`;

const TextParagraph = styled.div`
  transition: all 0.2s;
  font-family: Gotham;
  color: black;
  text-align: left;
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: 1rem;
  &.margin-top { margin-top: 1rem; }
  &.align-right { text-align: right; }
  &.align-center { text-align: center; }
  &.no-margin { margin: 0; }
  @media only screen and (min-width: 600px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
    &.align-right { text-align: right; }
    &.align-center { text-align: center; }
    &.no-margin { margin: 0; }
  }
  @media only screen and (min-width: 900px) {
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: 2rem;
    &.align-right { text-align: right; }
    &.align-center { text-align: center; }
    &.no-margin { margin: 0; }
  }
  @media only screen and (min-width: 1400px) {
    font-size: 2.1rem;
    line-height: 2.75rem;
    margin-bottom: 3rem;
    &.margin-top { margin-top: 3rem; }
    &.align-right { text-align: right; }
    &.align-center { text-align: center; }
    &.no-margin { margin: 0; }
  }
`;

export function Paragraph(props) {
  const {
    alignRight = false,
    alignCenter = false,
    noMargin = false,
    marginTop = false,
    text,
    children,
  } = props;

  return (
    <TextParagraph className={`${alignRight ? 'align-right' : ''} ${alignCenter ? 'align-center' : ''} ${marginTop ? 'margin-top' : ''} ${noMargin ? 'no-margin' : ''}`}>
      {text}
      {children}
    </TextParagraph>
  );
}

export function Text(props) {
  const {
    bold = false,
    color = 'dark',
    text,
    children,
  } = props;

  return (
    <div
      style={{
        color: getColor(color),
        fontFamily: bold ? 'GothamMedium' : 'Gotham',
        display: 'contents',
      }}
    >
      {text}
      {children}
    </div>
  );
}
