import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, Col } from 'react-bootstrap';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Section from 'components/section';

import {
  TermsLinks,
  TermsPopupBox,
  TermsPopupContent,
  TermsPopupButton,
  TermsSideMenu,
  TermsSideMenuContent,
  TermsContainer,
  TermsTitle,
} from './terms.styled';

const Links = () => (
  <TermsLinks>
    <ol>
      <li>
        <Link to="/competition-terms-and-conditions#promoters" smooth>
          Promoters
        </Link>
      </li>
      <li>
        <Link to="/competition-terms-and-conditions#who-may-enter" smooth>
          Who may enter
        </Link>
      </li>
      <li>
        <Link
          to="/competition-terms-and-conditions#agreement-and-amendments-to-these-terms-and-conditions"
          smooth
        >
          Agreement and amendments to these terms and conditions
        </Link>
      </li>
      <li>
        <Link to="/competition-terms-and-conditions#competition-period" smooth>
          Competition Period
        </Link>
      </li>
      <li>
        <Link to="/competition-terms-and-conditions#prizes" smooth>
          Prize/s
        </Link>
      </li>
      <li>
        <Link to="/competition-terms-and-conditions#how-to-enter" smooth>
          How to enter
        </Link>
      </li>
      <li>
        <Link to="/competition-terms-and-conditions#winners" smooth>
          Winner/s
        </Link>
      </li>
      <li>
        <Link
          to="/competition-terms-and-conditions#accuracy-of-information"
          smooth
        >
          Accuracy of information
        </Link>
      </li>
      <li>
        <Link
          to="/competition-terms-and-conditions#redemption-of-the-prize"
          smooth
        >
          Redemption of the Prize
        </Link>
      </li>
      <li>
        <Link to="/competition-terms-and-conditions#disputes" smooth>
          Disputes
        </Link>
      </li>
      <li>
        <Link
          to="/competition-terms-and-conditions#rejection-or-foreiture-of-prize"
          smooth
        >
          Rejection or Forfeiture of Prize
        </Link>
      </li>
      <li>
        <Link to="/competition-terms-and-conditions#prize-availability" smooth>
          Prize Availability
        </Link>
      </li>
      <li>
        <Link to="/competition-terms-and-conditions#winner-rules" smooth>
          Winner Rules
        </Link>
      </li>
    </ol>
  </TermsLinks>
);

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && (
        <TermsPopupBox>
          <TermsPopupContent>
            <Links />
          </TermsPopupContent>
        </TermsPopupBox>
      )}
      <TermsPopupButton
        className={`${isOpen ? 'open' : ''}`}
        role="button"
        tabIndex={0}
        onClick={togglePopup}
      >
        <img src="terms/arrow.svg" alt="arrow" title={isOpen} />
      </TermsPopupButton>
    </>
  );
};

const SideMenu = () => (
  <TermsSideMenu>
    <TermsSideMenuContent>
      <Links />
    </TermsSideMenuContent>
  </TermsSideMenu>
);

export default function CompetitionTermsPage() {
  return (
    <>
      <SEO
        title="My Smart City | Competition Terms and Conditions"
        description="These Terms and Conditions constitute an agreement between you as user and Acumen Software as it relates to the use of the My Smart City Platform and related Services."
      />
      <Section color="white" thinPadding>
        <Row noGutters>
          <Col lg={3} sm={12}>
            <Popup />
            <SideMenu />
          </Col>
          <Col lg={9} sm={12}>
            <TermsContainer>
              <TermsTitle>
                My Smart City - Download and Register Competition
              </TermsTitle>
              <TermsTitle className="bold">Terms and Conditions</TermsTitle>
              <ol>
                <li id="promoters">
                  Promoters
                  <ol>
                    <li>
                      The promoters to this competition are Acumen Software,
                      trading as My Smart City registration number:
                      2006/008627/07) (“Acumen Software”).
                    </li>
                  </ol>
                </li>

                <li id="who-may-enter">
                  Who may enter
                  <ol>
                    <li>
                      Entry into this competition is automatic and open only to
                      you if you are a permanent resident and citizen of South
                      Africa, are 18 years of age or older, in possession of a
                      valid South African Identity Document (“natural person”)
                      and excludes any employee, director, member, partner,
                      agent or consultant or any person directly or indirectly
                      who controls or is controlled by the Promoter/s and/or
                      immediate family members of any employee, director,
                      member, partner, agent or consultant of or person
                      indirectly or directly in control or controlled by the
                      Promoter/s, their advertising agencies, advisers, dealers,
                      suppliers, its affiliates and/or associated companies;
                    </li>
                    <li>
                      The prize will only be awarded:
                      <ol>
                        <li>
                          to a natural person as stipulated in these rules and
                          will not be awarded to any legal entity;
                        </li>
                        <li>
                          if it is not unlawful to supply the prize to a person
                          in terms of any legislation or public regulation and
                          the Promoter/s reserve the right to require proof that
                          it will not be unlawful to supply a prize (for example
                          to request the winner of a trip to provide a copy of
                          his/her South African Identify Document to the
                          Promotor/s before handing over the prize).
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li id="agreement-and-amendments-to-these-terms-and-conditions">
                  Agreement and amendments to these terms and conditions
                  <ol>
                    <li>
                      By entering this competition, you agree to be bound by
                      these rules which will be interpreted by the Promoter/s,
                      whose decision regarding any dispute will be final and
                      binding.
                    </li>
                    <li>
                      The Promoter/s reserve the right to amend, modify, change,
                      postpone, suspend or cancel this competition and any
                      prizes (which have not yet been subject to a draw), or any
                      aspect thereof, without notice at any time, for any reason
                      which the Promoter reasonably deems necessary.
                    </li>
                    <li>
                      The Promoter/s reserve the right to extend the duration of
                      the Competition Period in the event that they have not
                      received the minimum number of qualifying entries for the
                      Competition Period.
                    </li>
                  </ol>
                </li>

                <li id="competition-period">
                  Competition Period
                  <ol>
                    <li>
                      This competition commences on 1 January 2022 and ends on
                      28 February 2022 (both days inclusive).
                    </li>
                  </ol>
                </li>

                <li id="prizes">
                  Prize/s
                  <ol>
                    <li>The Prize/s is/are: A Garmin Fenix 6S Smartwatch</li>
                    <li>
                      You agree and understand that you stand a chance to win a
                      prize only and that there is no guarantee that you will
                      receive a prize.
                    </li>
                    <li>
                      The Prize/s is/are not transferable by you and may not be
                      deferred, changed or exchanged for cash or any other item.
                    </li>
                    <li>
                      The Promoter/s reserve the right to substitute the Prize
                      with any other gift of comparable commercial value.
                    </li>
                    <li>
                      Although the Promoter/s have taken care to make sure that
                      the prize/s is/are of a high standard, they do not give
                      any warranties about the prize/s and you agree to accept
                      the prize/s “as is”. Subject to applicable law, you also
                      indemnify the Promoter/s against any damages that anyone
                      may suffer as a result of the prize/s or this competition,
                      including consequential and economic loss.
                    </li>
                    <li>
                      Any costs or expenses incurred in respect of items not
                      specifically included in the prize/s are for the winner’s
                      own account. The Promoter’s and its affiliates, will not
                      be responsible for any other expenses which the winner/s
                      may incur as a result of their acceptance and/ or use of a
                      prize, whether foreseen or not.
                    </li>
                    <li>
                      To the extent that any taxes, duties, levies or other
                      charges may be levied on a prize by the State or any other
                      competent government or regulatory body, the winner/s will
                      be liable therefore, and the Promoter/s will not increase
                      the value of the prize/s to compensate for such charges.
                    </li>
                    <li>
                      The value of the prize includes VAT, and the prize will be
                      given to the winner at the ruling price which is available
                      to any customer on the day.
                    </li>
                  </ol>
                </li>

                <li id="how-to-enter">
                  How to enter
                  <ol>
                    <li>
                      You will be eligible to enter the competition when you
                      download and register on the My Smart City platform, this
                      includes the iOS, Android and website interfaces.
                    </li>
                    <li>
                      Those that have already downloaded and registered on the
                      application from the time we launched, 15th July 2021
                      until the competition closes on the 28th February 2022 at
                      23:59, will be automatically entered into the competition
                      and as such stand a chance to win the prize.
                    </li>
                  </ol>
                </li>

                <li id="winners">
                  Winner/s
                  <ol>
                    <li>
                      There will be 1 winner drawn from the Republic of South
                      Africa.
                    </li>
                    <li>
                      The winner/s will be randomly selected from eligible
                      entrants for the relevant competition period, no later
                      than one month after the closing date of the competition
                      or a later date as is possible for the Promoters.
                    </li>
                    <li>
                      The winner/s will be selected by an independent third
                      party.
                    </li>
                    <li>
                      In order to be eligible to win a prize, you must not have
                      committed any act of fraud or other criminal act against
                      the Promoters or associated parties.
                    </li>
                    <li>
                      The winner/s name, photo and town/city will be published
                      on the My Smart City website and other media. The winner/s
                      may also be required to have their image published and/or
                      to participate in marketing activities, but the winner/s
                      may refuse either of these if they so wish.
                    </li>
                  </ol>
                </li>

                <li id="accuracy-of-information">
                  Accuracy of information
                  <ol>
                    <li>
                      It is your responsibility to ensure that all information
                      provided by or on behalf of you to Acumen Software is
                      accurate, complete and up to date at all times.
                    </li>
                    <li>
                      Acumen Software will notify a winner of the prize by
                      contacting the winner using the contact details supplied
                      upon registration within My Smart City. Where Acumen
                      Software cannot reach a winner at the details supplied by
                      them, Acumen Software will be excused and legally relieved
                      of the duty to provide such notification and may at its
                      discretion re-draw a new winner.
                    </li>
                  </ol>
                </li>

                <li id="redemption-of-the-prize">
                  Redemption of the Prize
                  <ol>
                    <li>
                      Acumen Software will contact the winner to arrange for the
                      redemption of the prize.
                    </li>
                    <li>
                      Acumen Software accepts no liability for any further and
                      or additional costs and/or expenses in relation to the
                      delivery of the prize, and the winner indemnifies Acumen
                      Software, its Directors, employees, shareholders and
                      agents against any claim of whatsoever nature howsoever
                      arising.
                    </li>
                  </ol>
                </li>

                <li id="disputes">
                  Disputes
                  <ol>
                    <li>
                      In the event of a dispute, the decision of the competition
                      judges is final and binding and no correspondence will be
                      entered into.
                    </li>
                  </ol>
                </li>

                <li id="rejection-or-forfeiture-of-prize">
                  Rejection or Forfeiture of Prize
                  <ol>
                    <li>
                      If the winner/s fail/s to comply with any of these rules
                      or the terms of acceptance of the prizes, or if they
                      refuse to sign the Promoter/s winner/s declaration or the
                      Promoter/s winner/s prize acceptance form, this will be
                      construed as a rejection of the prize and then, without
                      prejudice to any other remedy which the Promoter/s may
                      have, the winner will be automatically disqualified and
                      will forfeit the prize.
                    </li>
                  </ol>
                </li>

                <li id="prize-availability">
                  Prize Availability
                  <ol>
                    <li>
                      The prize will be subject to stock availability of which
                      may affect redemption timelines.
                    </li>
                    <li>No cash refund or spending money will be given.</li>
                  </ol>
                </li>

                <li id="winner-rules">
                  Winner Rules
                  <ol>
                    <li>
                      The winner will forfeit their prize in the event that
                      he/she fails to comply with the Promoter/s requirements
                      within the time period provided to you by the Promoter in
                      order for the Promoter to process the prize.
                    </li>
                  </ol>
                </li>
              </ol>
            </TermsContainer>
          </Col>
        </Row>
      </Section>
      <Footer />
    </>
  );
}
