/** @jsxImportSource @emotion/react */
import AccountNavigator from 'components/account-navigator';
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* Modules */
import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import _ from 'lodash';
/* Local */
import { useGlobalContext } from 'contexts/global';
import 'styles/notifications.scss';
import {
  getNotificationSettings,
  updateNotificationSettings,
  markAllNotificationsAsRead,
} from 'interface/notifications';
import { apiBaseUrlBase } from 'constants/app';
import { Button } from 'components/elements';
import { SmallLoader } from 'components/private-services/survey';

const defaultGlobals = {
  email: false,
  escalatedByMe: false,
  loggedByMe: false,
  nearMe: false,
  upvotedByMe: false,
};

const NotificationSettingsPage = () => {
  const { globalState } = useGlobalContext();

  const [settingsSection, setSettingsSection] = useState('Municipal');
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [showGlobalNotifications, setShowGlobalNotifications] = useState(false);
  const [globalNotificationSettings, setGlobalNotificationSettings] = useState({
    ...defaultGlobals,
  });
  const [globalNotificationSettingToggle, setGlobalNotificationSettingToggle] =
    useState(false);
  const [notificationSettingsActive, setNotificationSettingsActive] = useState(
    {}
  );
  const [loading, setLoading] = useState(false);

  async function fetchSettings() {
    setLoading(true);
    const res = await getNotificationSettings();
    setNotificationSettings(res.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchSettings();
  }, []);

  const changeNotificationSetting = async (setting, key) => {
    for (let i = 0; i < notificationSettings.length; i++) {
      if (notificationSettings[i].referenceCode === setting.referenceCode) {
        const temp = _.cloneDeep(notificationSettings);
        temp[i][key] = !temp[i][key];
        setNotificationSettings(temp);
        updateNotificationSettings(JSON.stringify(temp));
        break;
      }
    }
  };

  const handleGlobalToggle = () => {
    if (globalNotificationSettingToggle) {
      const temp = _.cloneDeep(notificationSettings);
      for (let i = 0; i < notificationSettings.length; i++) {
        temp[i] = { ...i, ...defaultGlobals };
      }
      console.log('change global toggle', temp);
      setGlobalNotificationSettings(defaultGlobals);
      setNotificationSettings(temp);
      updateNotificationSettings(JSON.stringify(temp));
    }
    setGlobalNotificationSettingToggle(!globalNotificationSettingToggle);
  };

  const changeGlobalNotificationSettings = (setting) => {
    const temp = _.cloneDeep(globalNotificationSettings);
    temp[setting] = !temp[setting];
    setGlobalNotificationSettings(temp);
    setGlobalNotificationSettingToggle(true);
  };

  useEffect(() => {
    console.log('notificationSettings', notificationSettings);
    const temp = _.cloneDeep(notificationSettings);
    for (let i = 0; i < notificationSettings.length; i++) {
      temp[i] = { ...i, ...globalNotificationSettings };
    }
    console.log('change notifications', temp);
    setNotificationSettings(temp);
    updateNotificationSettings(JSON.stringify(temp));
  }, [globalNotificationSettings]);

  return (
    <AccountNavigator>
      <Col id="Notifications" className="p-0">
        <div className="notificationsSubHeaderList">Notifications</div>
        <div className="notificationsSettings" style={{ width: '100%' }}>
          <div className="notificationsSubSubHeader">Filters</div>
          <div className="notificationText">
            Set which notifications you would like to receive based on both the
            municipal and petitions categories. Click on edit preferences in one
            of the smaller tiles to toggle the notifications on or off.
          </div>

          <div className="settingsContainer">
            <div className="settingsHeader">
              <div
                className={
                  settingsSection === 'Municipal'
                    ? 'SelectedSettingText SettingText'
                    : 'SettingText'
                }
                onClick={() => setSettingsSection('Municipal')}
                role="button"
                tabIndex={0}
              >
                Municipal
              </div>
              <div className="textAccentOrange SettingText"> | </div>
              <div
                className={
                  settingsSection === 'Petition'
                    ? 'SelectedSettingText SettingText'
                    : 'SettingText'
                }
                onClick={() => setSettingsSection('Petition')}
                role="button"
                tabIndex={0}
              >
                Petition
              </div>
              <div className="notificationGlobalContainer">
                <div
                  className={
                    showGlobalNotifications
                      ? 'notificationGlobal notificationGlobalSelected'
                      : 'notificationGlobal'
                  }
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setShowGlobalNotifications(!showGlobalNotifications);
                  }}
                >
                  <img
                    src="/notifications/sidebar/settings.svg"
                    className="notificationGlobalIcon"
                    alt="recent"
                  />
                </div>
                {showGlobalNotifications ? (
                  <div className="globalNotificationPopupContainer">
                    <div className="globalNotificationPopup">
                      <div style={{ fontFamily: 'GothamMedium' }}>
                        Global notification settings
                      </div>
                      <div className="globalToggleContainer">
                        <div className="globalToggleSettingsGlobal">
                          <Toggle
                            className="toggleSettings"
                            onClick={() => handleGlobalToggle()}
                            width={15}
                            height={15}
                            on=""
                            off=""
                            active={globalNotificationSettingToggle}
                          />
                          Enable global settings
                          <hr />
                        </div>
                        <div className="globalToggleSettings">
                          <Toggle
                            className="toggleSettings"
                            onClick={() =>
                              changeGlobalNotificationSettings('email')
                            }
                            width={15}
                            height={15}
                            on=""
                            off=""
                            active={globalNotificationSettings.email}
                          />
                          Email notifications
                        </div>
                        <div className="globalToggleSettings">
                          <Toggle
                            className="toggleSettings"
                            onClick={() =>
                              changeGlobalNotificationSettings('escalatedByMe')
                            }
                            width={15}
                            height={15}
                            on=""
                            off=""
                            active={globalNotificationSettings.escalatedByMe}
                          />
                          Self-escalated
                        </div>
                        <div className="globalToggleSettings">
                          <Toggle
                            className="toggleSettings"
                            onClick={() =>
                              changeGlobalNotificationSettings('loggedByMe')
                            }
                            width={15}
                            height={15}
                            on=""
                            off=""
                            active={globalNotificationSettings.loggedByMe}
                          />
                          Logged by me
                        </div>
                        <div className="globalToggleSettings">
                          <Toggle
                            className="toggleSettings"
                            onClick={() =>
                              changeGlobalNotificationSettings('nearMe')
                            }
                            width={15}
                            height={15}
                            on=""
                            off=""
                            active={globalNotificationSettings.nearMe}
                          />
                          Near me
                        </div>
                        <div className="globalToggleSettings">
                          <Toggle
                            className="toggleSettings"
                            onClick={() =>
                              changeGlobalNotificationSettings('upvotedByMe')
                            }
                            width={15}
                            height={15}
                            on=""
                            off=""
                            active={globalNotificationSettings.upvotedByMe}
                          />
                          Upvoted by me
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="settingsBody">
              <Row
                style={{
                  marginRight: '-10px',
                  marginLeft: '-10px',
                  marginBottom: '-20px',
                }}
              >
                {notificationSettings &&
                  notificationSettings.length > 0 &&
                  notificationSettings.map((ns) => {
                    let currentNotificationSetting;
                    if (
                      settingsSection === 'Municipal' &&
                      ns.referenceType === 'OrgUnit'
                    ) {
                      currentNotificationSetting = ns;
                    } else if (
                      settingsSection === 'Petition' &&
                      ns.referenceType === 'Petition'
                    ) {
                      currentNotificationSetting = ns;
                    } else {
                      return null;
                    }
                    return (
                      <Col
                        sm={6}
                        md={6}
                        lg={3}
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      >
                        <div className="flip-card">
                          <div
                            className={
                              notificationSettingsActive ===
                              ns.referenceDescription
                                ? 'flip-card-inner flippityFlop'
                                : 'flip-card-inner'
                            }
                          >
                            <div
                              className="flip-card-front"
                              tabIndex={0}
                              role="button"
                              onClick={() => {
                                if (
                                  ns.referenceDescription ===
                                  notificationSettingsActive
                                ) {
                                  setNotificationSettingsActive('');
                                } else {
                                  setNotificationSettingsActive(
                                    ns.referenceDescription
                                  );
                                }
                              }}
                            >
                              <img
                                src={`${apiBaseUrlBase}/images/custom/${
                                  currentNotificationSetting?.customIcon &&
                                  currentNotificationSetting?.customIcon.split(
                                    '.'
                                  )[0]
                                }.${
                                  currentNotificationSetting?.customIcon &&
                                  currentNotificationSetting?.customIcon.split(
                                    '.'
                                  )[1]
                                }?cityId=${globalState?.city?.value}`}
                                alt="Avatar"
                                className="notificationSettingImage"
                                style={{
                                  filter:
                                    'invert(47%) sepia(85%) saturate(1261%) hue-rotate(350deg) brightness(93%) contrast(87%)',
                                }}
                              />
                              <div className="notificationSettingTitle">
                                {
                                  currentNotificationSetting?.referenceDescription
                                }
                              </div>
                              <div className="notificationSettingEdit">
                                Edit preferences
                              </div>
                            </div>
                            <div className="flip-card-back">
                              <div
                                className="closeSettings showOnMobile"
                                tabIndex={0}
                                aria-label="close"
                                role="button"
                                onClick={() => {
                                  setNotificationSettingsActive('');
                                }}
                              >
                                <img
                                  src="notifications/crossShadowBlack.svg"
                                  alt="close menu icon"
                                  style={{
                                    height: '15px',
                                    float: 'right',
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>

                              <div>
                                <Toggle
                                  className="toggleSettings"
                                  onClick={() =>
                                    changeNotificationSetting(
                                      currentNotificationSetting,
                                      'email'
                                    )
                                  }
                                  width={15}
                                  height={8}
                                  on=""
                                  off=""
                                  active={currentNotificationSetting.email}
                                />
                                Email notifications
                              </div>
                              <div>
                                <Toggle
                                  className="toggleSettings"
                                  onClick={() =>
                                    changeNotificationSetting(
                                      currentNotificationSetting,
                                      'escalatedByMe'
                                    )
                                  }
                                  width={15}
                                  height={8}
                                  on=""
                                  off=""
                                  active={
                                    currentNotificationSetting.escalatedByMe
                                  }
                                />
                                Self-escalated
                              </div>
                              <div>
                                <Toggle
                                  className="toggleSettings"
                                  onClick={() =>
                                    changeNotificationSetting(
                                      currentNotificationSetting,
                                      'loggedByMe'
                                    )
                                  }
                                  width={15}
                                  height={8}
                                  on=""
                                  off=""
                                  active={currentNotificationSetting.loggedByMe}
                                />
                                Logged by me
                              </div>
                              <div>
                                <Toggle
                                  className="toggleSettings"
                                  onClick={() =>
                                    changeNotificationSetting(
                                      currentNotificationSetting,
                                      'nearMe'
                                    )
                                  }
                                  width={15}
                                  height={8}
                                  on=""
                                  off=""
                                  active={currentNotificationSetting.nearMe}
                                />
                                Near me
                              </div>
                              <div>
                                <Toggle
                                  className="toggleSettings"
                                  onClick={() =>
                                    changeNotificationSetting(
                                      currentNotificationSetting,
                                      'upvotedByMe'
                                    )
                                  }
                                  width={15}
                                  height={8}
                                  on=""
                                  off=""
                                  active={
                                    currentNotificationSetting.upvotedByMe
                                  }
                                />
                                Upvoted by me
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </div>
          {loading ? (
            <SmallLoader />
          ) : (
            <Button
              variant="outlined"
              onClick={() => {
                updateNotificationSettings(JSON.stringify([]));
                fetchSettings();
              }}
            >
              Reset to default
            </Button>
          )}
        </div>
      </Col>
    </AccountNavigator>
  );
};

export default NotificationSettingsPage;
