import { css } from '@emotion/react';
import { Button, Image, Modal } from 'components/elements';
import { Box, Flex, Grid } from 'components/layout';
import { Text } from 'components/typography';
import { useMakeABookingContext } from 'contexts/booking';
import { useEffect, useState } from 'react';
import theme from 'theme';
import {
  DomesticCleaningGroups,
  SmartCityService,
  SmartCityServiceGroup,
} from 'types/types';

const QuantitySelectionField = ({
  numRooms,
  service,
  clickAdd,
  clickRemove,
  maxDuration,
}: {
  numRooms: number;
  service: SmartCityService;
  clickAdd: () => void;
  clickRemove: () => void;
  maxDuration: number;
}) => (
  <Flex
    p="7px"
    justifyContent="space-between"
    flexDirection="row"
    alignItems="center"
    alignContent="center"
    border={`1px solid ${theme.colors.borderGrey}`}
    borderRadius={theme.borderRadius.small}
  >
    <Text m={0} ml="10px" fontWeight="medium">
      {service.label}
    </Text>
    <Grid gridTemplateColumns="1fr 1fr 1fr">
      <Button
        disabled={service.current <= service.min}
        onClick={clickRemove}
        m={0}
        p="6px"
        px="10px"
        border={`1px solid ${theme.colors.borderGrey}`}
        borderRadius={theme.borderRadius.small}
        fontSize="18px"
        color={
          service.current <= service.min ? theme.colors.borderGrey : 'black'
        }
      >
        -
      </Button>
      <Text
        m={0}
        textAlign="center"
        my="auto"
        color={theme.colors.primary}
        fontWeight="500"
      >
        {service.current}
      </Text>
      <Button
        disabled={numRooms + 1 > maxDuration || service.current >= service.max}
        onClick={clickAdd}
        m={0}
        p="8px"
        px="10px"
        border={`1px solid ${theme.colors.borderGrey}`}
        borderRadius={theme.borderRadius.small}
        color={
          numRooms + 1 > maxDuration || service.current >= service.max
            ? theme.colors.borderGrey
            : 'black'
        }
      >
        +
      </Button>
    </Grid>
  </Flex>
);

const ServiceQuantityWidget = ({
  serviceGroups,
  setQuantityCurrent,
  duration,
  setBookingDuration,
}: {
  serviceGroups: SmartCityServiceGroup | undefined;
  setQuantityCurrent: (
    group: string,
    service: string,
    current: number,
    renderIdx: number
  ) => void;
  duration: number;
  setBookingDuration: (duration: number) => void;
}) => {
  const { maxDuration } = useMakeABookingContext();
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    const totalQuantity = serviceGroups?.smartCityServices
      .map((s) => s.current)
      .reduce((a, b) => a + b, 0);
    setBookingDuration(totalQuantity);
  }, []);

  if (!serviceGroups) {
    return null;
  }

  return (
    <Box
      borderB={`1px solid ${theme.colors.borderGrey}`}
      pb="20px"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          padding: 0 10px 20px 10px;
          border-bottom: 5px solid ${theme.colors.borderGrey};
        }
      `}
    >
      <Box>
        <Modal
          isOpen={infoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          title="General Cleaning"
        >
          <Box
            borderB={`1px solid ${theme.colors.borderGrey}`}
            pb="20px"
            mb="15px"
          >
            <Text fontSize="15px" fontWeight="bold">
              Living Room
            </Text>
            <Text color={theme.colors.lightGrey}>
              General cleaning of living room and other areas includes:
            </Text>
            <Grid gridTemplateColumns="1fr 10fr">
              <Image
                source="icons/tick_no_background.svg"
                alt="tick"
                width="20px"
                mt="3px"
              />
              <Text>Dusting of furniture and surfaces</Text>
              <Image
                source="icons/tick_no_background.svg"
                alt="tick"
                width="20px"
                mt="3px"
              />
              <Text>Mopping and vacuuming of floors</Text>
              <Image
                source="icons/tick_no_background.svg"
                alt="tick"
                width="20px"
                mt="3px"
              />
              <Text>Dusting and wiping of light switches and other fixes</Text>
            </Grid>
          </Box>
          <Box>
            <Text fontSize="15px" fontWeight="bold">
              Kitchen
            </Text>
            <Text color={theme.colors.lightGrey}>
              General cleaning of kitchen includes:
            </Text>
            <Grid gridTemplateColumns="1fr 10fr">
              <Image
                source="icons/tick_no_background.svg"
                alt="tick"
                width="20px"
                mt="3px"
              />
              <Text>Wiping of surfaces, sinks and appliances</Text>
              <Image
                source="icons/tick_no_background.svg"
                alt="tick"
                width="20px"
                mt="3px"
              />
              <Text>Washing of dishes</Text>
            </Grid>
          </Box>
        </Modal>
        <Text fontSize="20px" m={0}>
          {serviceGroups.heading}
        </Text>
        {serviceGroups.heading === DomesticCleaningGroups.ROOMS && (
          <Flex m={0}>
            <Text m={0} mr="3px">
              Common areas
            </Text>
            <Button onClick={() => setInfoModalOpen(true)}>
              <Flex>
                <Text color={theme.colors.primary} m={0}>
                  included
                </Text>
                <Image
                  source="icons/info.svg"
                  alt="info"
                  height="15px"
                  ml="5px"
                  mt="2px"
                />
              </Flex>
            </Button>
          </Flex>
        )}
        <Grid gridRowGap="10px" mt="10px">
          {serviceGroups.smartCityServices.map(
            (group: SmartCityService, idx: number) => (
              <QuantitySelectionField
                numRooms={duration}
                maxDuration={maxDuration}
                service={group}
                key={idx}
                clickAdd={() => {
                  setQuantityCurrent(
                    serviceGroups.heading,
                    group.label,
                    group.current + 1,
                    idx
                  );
                  setBookingDuration(duration + 1);
                }}
                clickRemove={() => {
                  setQuantityCurrent(
                    serviceGroups.heading,
                    group.label,
                    group.current - 1,
                    idx
                  );
                  setBookingDuration(duration - 1);
                }}
              />
            )
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ServiceQuantityWidget;
