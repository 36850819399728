import styled, { keyframes } from 'styled-components';

export const ErrorLabel = styled.p`
  font: normal normal normal 10px/24px Roboto;
  color: #d8000c; // #DF6E21;
  margin: 0;
  position: relative;
  top: -15px;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 12px/24px Roboto;
    top: -30px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 3px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  margin-right: -24px;
  padding-right: 24px;
  @media only screen and (min-width: 800px) {
    margin-right: -45px;
    padding-right: 45px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;

// Take the user to a different section with options,
// then replace placeholder text with selected option.
export const SelectButton = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  background-color: #ffffff 0% 0% no-repeat padding-box;
  background: url('social/forward-icon.svg') 95% 50% no-repeat padding-box;
  background-size: 6px;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  height: 52px;
  width: 100%;
  color: #0a0918;
  margin-bottom: 15px;
  padding: 0 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  flex-shrink: 0;
  &::placeholder {
    color: #0a0918;
    opacity: 1;
  }
  &:hover {
    box-shadow: 0 0 0 3px #df6e213e;
    border-color: transparent;
  }
  p {
    font: normal normal normal 12px/52px Roboto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 10px 0 0;
    padding: 0;
  }
  @media only screen and (min-width: 800px) {
    height: 67px;
    padding: 0 30px;
    margin-bottom: 30px;
    border: 2px solid #dee2e6;
    border-radius: 5px;
    background-size: 12px;
    p {
      font: normal normal normal 14px/67px Roboto;
      margin: 0 20px 0 0;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  font: normal normal normal 12px/14px Roboto;
  height: 52px;
  color: #0a0918;
  padding: 0 20px;
  transition: all 0.2s ease-in-out;
  margin-bottom: ${({ largeMargin = false }) =>
    largeMargin ? '50px' : '15px'};
  flex-shrink: 0;
  &::placeholder {
    color: #b2bcc1;
    opacity: 1;
  }
  &:hover {
    box-shadow: 0 0 0 3px #df6e213e;
    border-color: transparent;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #df6e213e, 0 0 0 1px #df6e21;
    border-color: transparent;
  }
  @media only screen and (min-width: 800px) {
    height: 67px;
    padding: 0 30px;
    margin-bottom: 30px;
    border: 2px solid #dee2e6;
    border-radius: 5px;
    font: normal normal normal 14px/24px Roboto;
    margin-bottom: ${({ largeMargin = false }) =>
      largeMargin ? '100px' : '30px'};
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  font: normal normal normal 12px/14px Roboto;
  color: #0a0918;
  padding: 17px 20px 0 20px;
  height: 52px;
  min-height: 40px;
  max-height: 10rem;
  margin-bottom: 15px;
  transition: all 0.2s ease-in-out, height 0s;
  flex-shrink: 0;
  &::placeholder {
    color: #b2bcc1;
    opacity: 1;
  }
  &:hover {
    box-shadow: 0 0 0 3px #df6e213e;
    border-color: transparent;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #df6e213e, 0 0 0 1px #df6e21;
    border-color: transparent;
  }
  @media only screen and (min-width: 800px) {
    min-height: 67px;
    height: 67px;
    padding: 20px 30px 0 30px;
    margin-bottom: 30px;
    border: 2px solid #dee2e6;
    border-radius: 5px;
    font: normal normal normal 14px/24px Roboto;
  }
`;

export const SubmitButton = styled.button`
  background: #df6e21 0% 0% no-repeat padding-box;
  border-style: none;
  border-radius: 5px;
  margin-top: auto;
  padding: 0;
  width: 100%;
  height: 45px;
  font: normal normal normal 16px/19px 'Roboto';
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;
  position: relative;
  &:hover {
    background: #cb641e 0% 0% no-repeat padding-box;
  }
  &:disabled {
    background: rgba(178, 188, 193, 0.21) 0% 0% no-repeat padding-box;
    color: #b2bcc1;
  }
  @media only screen and (min-width: 800px) {
    height: 67px;
    margin: 0;
    font: normal normal normal 14px/26px 'Roboto';
  }
`;

// These are only Announcement specific components
export const LocationIcon = styled.img`
  opacity: 0.56;
  margin-right: 17px;
  height: 15px;
  @media only screen and (min-width: 800px) {
    height: 22px;
  }
`;

export const LocationRow = styled.div`
  display: flex;
  align-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
  flex-shrink: 0;
  @media only screen and (min-width: 800px) {
    margin-bottom: 30px;
  }
`;

export const LocationLabel = styled.p`
  font: normal normal normal 12px/14px Roboto;
  color: #0a0918;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  margin: 0 17px 0 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/26px Roboto;
  }
`;

export const EditLocationButton = styled.div`
  user-select: none;
  background: #df6d2119 url('social/pen-icon.svg') 50% 50% no-repeat padding-box;
  background-size: 40%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: rgba(223, 109, 33, 0.15);
  }
  @media only screen and (min-width: 800px) {
    background-size: auto;
    width: 48px;
    height: 48px;
  }
`;

export const UploadContainer = styled.div`
  border: 1px dashed #dee2e6;
  border-radius: 5px;
  width: 100%;
  height: 150px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @media only screen and (min-width: 800px) {
    border: 2px dashed #dee2e6;
    height: 200px;
  }
`;

export const CameraIcon = styled.img`
  margin-bottom: 15px;
  height: 15px;
  @media only screen and (min-width: 800px) {
    margin-bottom: 30px;
    height: 26px;
  }
`;

export const GrayImageText = styled.p`
  font: normal normal normal 14px/21px Roboto;
  color: #b2bcc1;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/26px Roboto;
  }
`;

// Announcement
// Work Type List

export const WorkTypeListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  margin-right: -24px;
  padding-right: 24px;
  @media only screen and (min-width: 800px) {
    margin-right: -45px;
    padding-right: 45px;
  }
`;

export const WorkTypeItem = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    background: gray;
    flex-shrink: 0;
  }
  @media only screen and (min-width: 800px) {
    img {
      width: 96px;
      height: 96px;
    }
  }
`;

export const WorkTypeRow = styled.div`
  display: flex;
  width: 100%;
  margin-left: 12px;
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(222, 226, 230, 0.46);
  @media only screen and (min-width: 800px) {
    margin-left: 21px;
    padding-bottom: 30px;
  }
`;

export const WorkTypeInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  h3 {
    font: normal normal normal 16px/21px Roboto;
    color: #0a0918;
    margin-bottom: 8px;
    padding: 0;
  }
  p {
    font: normal normal normal 12px/14px Roboto;
    letter-spacing: 0px;
    color: #b2bcc1;
    margin: 0;
    padding: 0;
  }
  @media only screen and (min-width: 800px) {
    margin-right: 50px;
    h3 {
      font: normal normal normal 16px/21px Roboto;
      margin-bottom: 14px;
    }
    p {
      font: normal normal normal 14px/19px Roboto;
    }
  }
`;

export const WorkTypeButton = styled.div`
  flex-shrink: 0;
  align-self: center;
  user-select: none;
  cursor: pointer;
  font: normal normal normal 10px/28px Roboto;
  text-align: center;
  width: 76px;
  height: 28px;
  border-radius: 1px;
  border: 1px solid #df6e21;
  border-radius: 1px;
  margin-left: auto;
  background: ${({ selected = false }) => (selected ? '#DF6E21' : '#FFFFFF')};
  color: ${({ selected = false }) => (selected ? '#FFFFFF' : '#DF6E21')};
  &:hover {
    background: #df6e21;
    color: white;
  }
  @media only screen and (min-width: 800px) {
    height: 48px;
    width: 131px;
    font: normal normal normal 14px/48px Roboto;
  }
`;

export const PostedContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10vw;
  h2 {
    text-align: center;
    font: normal normal normal 16px/19px Roboto;
    font-weight: 500;
    color: #333333;
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
  }
  p {
    text-align: center;
    font: normal normal normal 12px/14px Roboto;
    color: #b2bcc1;
    margin: 0;
    padding: 0;
    margin-bottom: 60px;
  }
  @media only screen and (min-width: 800px) {
    padding: 50px 100px;
    h2 {
      font: normal normal normal 30px/39px Roboto;
      margin-bottom: 15px;
    }
    p {
      font: normal normal normal 20px/26px Roboto;
    }
  }
`;

export const CheckmarkIcon = styled.div`
  background: #df6e21 url('social/checkmark-icon.svg') 50% 50% no-repeat
    padding-box;
  background-size: 50%;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin-bottom: 18px;
  @media only screen and (min-width: 800px) {
    background-size: auto;
    width: 125px;
    height: 125px;
    margin-bottom: 22px;
  }
`;

export const DiscardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  h2 {
    text-align: center;
    font: normal normal normal 20px/20px Roboto;
    font-weight: 500;
    color: #333333;
    margin: 0;
    padding: 0;
    margin-bottom: 18px;
  }
  p {
    text-align: center;
    font: normal normal normal 12px/14px Roboto;
    color: #b2bcc1;
    margin: 0;
    padding: 0;
    margin-bottom: 47px;
  }
  @media only screen and (min-width: 800px) {
    height: auto;
    margin-bottom: 40px;
    h2 {
      font: normal normal normal 20px/39px Roboto;
    }
    p {
      font: normal normal normal 14px/21px Roboto;
    }
  }
`;

export const DiscardButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: auto;
  @media only screen and (min-width: 800px) {
    margin-top: 0;
  }
`;

export const Button = styled.button`
  background: ${({ gray }) => (gray ? 'rgba(178, 188, 193, 0.21)' : '#DF6E21')}
    0% 0% no-repeat padding-box;
  border-style: none;
  border-radius: 5px;
  margin: 0;
  padding: 0;
  flex-basis: 100%;
  flex: 1;
  height: 45px;
  font: normal normal normal 14px/19px 'Roboto';
  color: ${({ gray }) => (gray ? '#B2BCC1' : '#FFFFFF')};
  transition: all 0.2s ease-in-out;
  &:first-child {
    margin-right: 12px;
  }
  &:hover {
    background: ${({ gray }) => gray || '#CB641E'} 0% 0% no-repeat padding-box;
  }

  ${(props) =>
    props.blackText &&
    `
    color: black;
    &:hover { color: black; }
  `}

  @media only screen and (min-width: 800px) {
    height: 67px;
    font: normal normal normal 14px/26px 'Roboto';
    &:first-child {
      margin-right: 24px;
    }
  }
`;

export const SearchContainer = styled.div`
  position: absolute;
  top: 3%;
  left: 5%;
  width: 90%;
  z-index: 1000;
  @media only screen and (min-width: 800px) {
    top: 5%;
  }
`;

export const StyledSearch = styled.input`
  background: #ffffff url('social/location-icon.svg') 13px 50% no-repeat
    padding-box;
  font: normal normal normal 12px/12px Roboto;
  color: #0a0918;
  box-shadow: 0px ${({ show = false }) => (show ? '1px 0px' : '3px 6px')}
    #00000029;
  background-size: 10px;
  border-radius: 2px;
  width: 100%;
  color: #333333;
  height: 40px;
  border-style: hidden;
  padding-left: 30px;
  z-index: 1000;
  &::placeholder {
    color: #b2bcc1;
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
  @media only screen and (min-width: 800px) {
    background: #ffffff url('social/location-icon.svg') 25px 50% no-repeat
      padding-box;
    font: normal normal normal 18px/21px Roboto;
    height: 60px;
    padding-left: 60px;
    background-size: 15px;
  }
`;

export const SearchDropdown = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  padding: 6px 0;
  background: #ffffff;
  z-index: 1000;
`;

export const SearchResult = styled.p`
  cursor: pointer;
  font: normal normal normal 10px/21px Roboto;
  color: #333333;
  padding: 4px 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
  &:hover {
    background: #b2bcc12e;
  }
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/21px Roboto;
    padding: 4px 60px;
  }
`;

export const CancelImageIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  background: url('social/clear-icon.svg') 0% 0% no-repeat padding-box;
  background-size: 30px;
  height: 30px;
  width: 30px;
  z-index: 10;
  cursor: pointer;
`;

export const ImagesContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
  height: 150px;
  flex-shrink: 0;
  margin-bottom: 15px;
  @media only screen and (min-width: 800px) {
    height: 200px;
    margin-bottom: 30px;
  }
`;

export const PreviewImage = styled.div`
  position: relative;
  background: url(${({ src }) => src}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  flex-basis: 50%;
  flex-shrink: 0;
  margin-right: 2px;
  @media only screen and (min-width: 800px) {
    margin-right: 4px;
  }
`;

// Work Request List items

export const WorkRequestListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  margin-right: -24px;
  padding-right: 24px;
  @media only screen and (min-width: 800px) {
    margin-right: -45px;
    padding-right: 45px;
  }
`;

export const StyledWorkRequestItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  img {
    width: 57px;
    height: 56px;
    background: #fff;
    flex-shrink: 0;
    object-fit: cover;
  }
  @media only screen and (min-width: 800px) {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    img {
      width: 97px;
      height: 95px;
    }
  }
`;

export const WorkRequestContainer = styled.div`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(222, 226, 230, 0.46);
  @media only screen and (min-width: 800px) {
    height: 125px;
    padding-bottom: 30px;
    border-bottom: 2px solid rgba(222, 226, 230, 0.46);
  }
`;

export const WorkRequestContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap;  */
    font: normal normal normal 12px/16px Roboto;
    margin: 0;
    padding: 0;
    color: #b2bcc1;
    &:first-child {
      color: #0a0918;
    }
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  @media only screen and (min-width: 800px) {
    p {
      font: normal normal normal 14px/24px Roboto;
    }
  }
`;

export const WorkRequestStatus = styled.div`
  font: normal normal normal 9px/11px Roboto;
  background: #fdb1251e 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 8px 14px;
  color: #fdb225;
  ${({ number = 0 }) =>
    number === 3
      ? `
    background: #04bd6d19 0% 0% no-repeat padding-box;
    color: #04BD6C;
    `
      : `
    background: #fdb1251e 0% 0% no-repeat padding-box;
    color: #FDB225;
  `}
  height: min-content;
  align-self: center;
  margin-left: auto;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/21px Roboto;
    padding: 12px 25px;
  }
`;

export const FileInput = styled.input`
  width: inherit;
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

export const FileInputText = styled.div`
  font: normal normal normal 14px/21px Roboto;
  color: #cb641e;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0;
  position: relative;
  @media only screen and (min-width: 800px) {
    font: normal normal normal 20px/26px Roboto;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  margin: 40px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #df6e21;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spinAnimation} 1s linear infinite;
  flex-shrink: 0;
`;

export const SmallLoader = styled.div`
  position: absolute;
  border: 2px solid hsl(23.999999999999993, 74.80314960629921%, 75%);
  border-top: 2px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spinAnimation} 0.5s linear infinite;
  @media only screen and (min-width: 800px) {
    border: 3px solid hsl(23.999999999999993, 74.80314960629921%, 75%);
    border-top: 3px solid white;
    width: 30px;
    height: 30px;
  }
`;

export const NotFoundContainer = styled.div`
  text-align: center;
  color: #b2bcc1;
  margin: 25px 70px;
  h2 {
    font: normal normal 500 16px/19px Roboto;
  }
  p {
    font: normal normal normal 10px/12px Roboto;
  }
  a {
    color: #b2bcc1;
    &:hover {
      color: #b2bcc1;
      text-decoration: underline;
    }
  }
  @media only screen and (min-width: 800px) {
    margin: 50px 130px;
    h2 {
      font: normal normal 500 20px/26px Roboto;
    }
    p {
      font: normal normal normal 16px/19px Roboto;
    }
  }
`;
