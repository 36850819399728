/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Image, Modal, TextField } from 'components/elements';
import { Loader } from 'components/inthecity';
import { Flex, Grid } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import useGetWardAreas from 'hooks/use-get-ward-areas';
import useGetWardCouncillors from 'hooks/use-get-ward-councillors';
import { useEffect, useRef, useState } from 'react';
import theme from 'theme';

const getElementsByText = (
  str: string,
  tag: string = 'p',
  ref: React.MutableRefObject<HTMLDivElement | null>
) =>
  ref.current &&
  Array.prototype.slice
    .call(ref.current.getElementsByTagName(tag))
    .filter((el) => el.textContent.toLowerCase().includes(str.toLowerCase()));

const WardcouncillorsPage = () => {
  // NOTE: This is a hack to get the ward councillors
  // It will only work for George Municipality!
  const { wardCouncillors, getWardCouncillors } = useGetWardCouncillors();
  const { wardAreas, getWardAreas } = useGetWardAreas();
  const [searchTerm, setSearchTerm] = useState('');
  const [wardAreasSearchTerm, setWardAreasSearchTerm] = useState('');
  const [wardAreasModalOpen, setWardAreasModalOpen] = useState(false);
  const modalContentRef = useRef<HTMLDivElement | null>(null);

  const data =
    wardCouncillors &&
    (searchTerm !== ''
      ? wardCouncillors
          .split('~')
          .filter((c) => c.includes('Councillor'))
          .filter((c) => c.toLowerCase().includes(searchTerm))
      : wardCouncillors.split('~').filter((c) => c.includes('Councillor')));

  useEffect(() => {
    getWardCouncillors();
    getWardAreas();
  }, []);

  useEffect(() => {
    if (
      wardAreasModalOpen &&
      modalContentRef.current &&
      wardAreasSearchTerm !== '' &&
      wardAreasSearchTerm.length > 2
    ) {
      const results = getElementsByText(
        wardAreasSearchTerm,
        'p',
        modalContentRef
      );
      if (results && results.length > 0) {
        console.log(results[0]);
        results[0].scrollIntoView();
        results[0].style.backgroundColor = '#fae2cc';
        setTimeout(() => {
          results[0].style.backgroundColor = 'transparent';
        }, 500);
      }
    }
  }, [wardAreasSearchTerm, modalContentRef.current, wardAreasModalOpen]);

  return (
    <MunicipalPageWrapper title="Ward Councillors">
      {!data ? (
        <Loader />
      ) : (
        <>
          <Modal
            isOpen={wardAreasModalOpen}
            onClose={() => setWardAreasModalOpen(false)}
            slideFromBottomMobile
            title="Find your ward"
          >
            <TextField
              minWidth="50%"
              onChange={(e) => setWardAreasSearchTerm(e.target.value)}
              placeholder="Search for your area"
              my="20px"
            />

            <div
              css={css`
                max-height: 450px;
                display: flex;
                max-width: 800px;
                overflow-y: scroll;
                @media ${theme.mediaQueries.mobileOnly} {
                  max-height: 100%;
                  width: 100%;
                }
              `}
              ref={modalContentRef}
            >
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: wardAreas.replace('<p>&nbsp;</p>', ''),
                }}
                css={css`
                  strong {
                    color: ${theme.colors.primary};
                  }
                  a {
                    text-decoration: none;
                    color: ${theme.colors.primary};
                    font-weight: bold;
                  }
                  p {
                    transition: background-color 0.5s ease;
                  }
                `}
              />
            </div>
          </Modal>
          <Flex
            mb="20px"
            mt="10px"
            width="100%"
            justifyContent="space-between"
            mobileCss={css`
              flex-direction: column;
            `}
          >
            <Flex width="100%">
              <Image
                source="blog/search.svg"
                width="20px"
                height="20px"
                alt="search"
                mr="10px"
                my="auto"
              />
              <TextField
                onChange={(e) => setSearchTerm(e.target.value)}
                width="35%"
                placeholder="Search..."
                mobileCss={css`
                  width: 100%;
                `}
                tabletCss={css`
                  width: 45%;
                `}
              />
            </Flex>
            <Button
              onClick={() => setWardAreasModalOpen(true)}
              variant="outlined"
              px="20px"
              overrideCss={css`
                white-space: nowrap;
              `}
              mobileCss={css`
                margin-top: 10px;
                margin-left: 0px;
              `}
            >
              Which ward am I in?
            </Button>
          </Flex>
          <Grid
            gridRowGap="20px"
            gridColumnGap="20px"
            desktopCss={css`
              grid-template-columns: 1fr 1fr 1fr;
            `}
            tabletCss={css`
              grid-template-columns: 1fr 1fr;
            `}
          >
            {data.map((c: string) => (
              <div
                dangerouslySetInnerHTML={{ __html: c }}
                css={css`
                  .item-list {
                    height: 100%;
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    grid-column-gap: 20px;
                    padding: 10px;
                    box-shadow: 0px -2px 10px 0px #00000012;
                  }
                  .cl-title {
                    font-weight: bold;
                  }
                  h6 {
                    color: ${theme.colors.primary};
                    font-size: 14px;
                  }
                  a {
                    font-size: 14px;
                    color: black;
                  }
                  img {
                    width: 100%;
                  }
                `}
              />
            ))}
          </Grid>
        </>
      )}
    </MunicipalPageWrapper>
  );
};

export default WardcouncillorsPage;
