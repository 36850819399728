import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';

export const StyledMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 30px;
  position: relative;
  @media only screen and (min-width: 800px) {
    border-radius: 5px;
    height: 400px;
  }

  @media only screen and (min-width: 1000px) {
    border-radius: 5px;
    height: 500px;
  }
`;
