import { MunicipalPageWrapper } from 'components/my-municipality';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GMBusiness } from 'types/types';
import { Text } from 'components/typography';
import { decodeHtml, fixWordPressString } from 'functions/george-municipality';
import { Loader } from 'components/inthecity';
import { Box, Flex, Grid } from 'components/layout';
import { Image } from 'components/elements';
import ExternalLink from 'components/elements/external-link';
import theme from 'theme';
import { css } from '@emotion/react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { shortenWithEllipses } from 'functions/address-utils';

const MSCMarker = new L.Icon({
  iconUrl: 'my-municipality/location-pin-orange.svg',
  iconRetinaUrl: 'my-municipality/location-pin-orange.svg',
  iconAnchor: new L.Point(10, 30),
});

const RelatedListing = ({ id }: { id: number }) => {
  const [business, setBusiness] = useState<GMBusiness | null>(null);

  useEffect(() => {
    fetch(`https://visitgeorge.co.za/wp-json/directorist/v1/listings/${id}`)
      .then((result) => result.json())
      .then((data) => {
        setBusiness(data);
      });
  }, []);
  const imageUrl =
    business && business.images && business.images.length > 0
      ? fixWordPressString(business.images[0].src)
      : 'my-municipality/placeholder.webp';
  return (
    <>
      {business && (
        <Flex
          flexDirection="column"
          position="relative"
          p="10px"
          boxShadow={theme.cardShadow}
          borderRadius="4px"
          backgroundColor="white"
        >
          <Link
            to={`/my-municipality/business-directory/663/single-listing/${business.id}`}
          >
            <Text
              backgroundColor="white"
              borderRadius="10px"
              p="5px"
              position="absolute"
              right="20px"
              top="20px"
              fontSize="8px"
            >
              {business.categories[0].name}
            </Text>

            <Image
              source={imageUrl}
              alt="image"
              borderRadius="4px"
              width="100%"
              objectFit="cover"
              height="140px"
            />
          </Link>

          <Text my="10px" fontSize="16px">
            {decodeHtml(business.name)}
          </Text>
          {business.address && (
            <Flex alignItems="center">
              <Image
                source="my-municipality/location-pin.png"
                alt="phone-icon"
                mr="10px"
                width="15px"
                objectFit="contain"
                height="15px"
              />{' '}
              <Text color="#B2BCC1">
                {shortenWithEllipses(business.address, 28)}
              </Text>
            </Flex>
          )}
          {business.phone && (
            <Flex my="10px">
              <Image
                source="my-municipality/phone-filled.svg"
                alt="phone-icon"
                mr="10px"
                width="15px"
              />{' '}
              <ExternalLink
                color="#B2BCC1"
                href={`tel:${business.phone}`}
                hoverCss={css`
                  color: ${theme.colors.primary};
                `}
              >
                {business.phone}
              </ExternalLink>
            </Flex>
          )}
          {business.email && (
            <Flex>
              <Image
                source="my-municipality/email-blue.svg"
                alt="phone-icon"
                mr="10px"
                width="15px"
                objectFit="contain"
                height="15px"
              />{' '}
              <ExternalLink
                href={`mailto:${business.email}`}
                hoverCss={css`
                  color: ${theme.colors.primary};
                `}
              >
                {business.email}
              </ExternalLink>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

const SingleListing = () => {
  const { businessId, cityId } = useParams();
  const [business, setBusiness] = useState<GMBusiness | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://visitgeorge.co.za/wp-json/directorist/v1/listings/${businessId}`
    )
      .then((result) => result.json())
      .then((data) => {
        setBusiness(data);
        setLoading(false);
      });
  }, [businessId]);

  return (
    <MunicipalPageWrapper
      title={business ? decodeHtml(business.name) : 'Business Listing'}
      backLink={`/my-municipality/business-directory/${cityId}`}
    >
      {loading || !business ? (
        <Loader />
      ) : (
        <Grid
          gridTemplateColumns="5fr 2fr"
          mobileCss={css`
            grid-template-columns: 1fr;
          `}
          gridColumnGap="20px"
        >
          <Grid
            width="100%"
            gridRowGap="10px"
            gridTemplateRows="repeat(4, auto)"
          >
            <Image
              source={fixWordPressString(business.images[0].src)}
              alt="image"
              borderRadius="4px"
              width="100%"
              objectFit="cover"
              hideForMobile
            />
            <Grid
              p="20px"
              boxShadow={theme.cardShadow}
              borderRadius="4px"
              gridTemplateColumns="1fr 1fr"
              mobileCss={css`
                grid-template-columns: 1fr;
                grid-row-gap: 20px;
              `}
            >
              <Image
                source={fixWordPressString(business.images[0].src)}
                alt="image"
                borderRadius="4px"
                width="100%"
                objectFit="cover"
                hideForDesktop
                hideForTablet
              />
              <Box>
                <Text fontSize="20px" fontWeight="bold" mb="20px">
                  {' '}
                  {decodeHtml(business.name)}
                </Text>
                {business.phone && (
                  <Flex mb="10px">
                    <Image
                      source="my-municipality/phone-outlined.svg"
                      alt="phone"
                      width="21px"
                      mr="10px"
                    />
                    <ExternalLink href={`tel:${business.phone}`}>
                      <Text fontSize="14px" color={theme.colors.primary}>
                        {business.phone}
                      </Text>
                    </ExternalLink>
                  </Flex>
                )}
                {business.email && (
                  <Flex>
                    <Image
                      source="my-municipality/email.svg"
                      alt="mail"
                      width="21px"
                      mr="10px"
                    />
                    <ExternalLink href={`mailto:${business.email}`}>
                      <Text fontSize="14px" color={theme.colors.primary}>
                        {business.email}
                      </Text>
                    </ExternalLink>
                  </Flex>
                )}
              </Box>
              {business.description && (
                <Box>
                  <Text fontSize="16px" fontWeight="bold" mb="20px">
                    About
                  </Text>
                  <Text>
                    {decodeHtml(business.yoast_head_json.og_description)}
                  </Text>
                </Box>
              )}
            </Grid>
            <Flex
              flexDirection="column"
              p="20px"
              boxShadow={theme.cardShadow}
              borderRadius="4px"
              flex="1"
              pb="auto"
              height="100%"
            >
              <Text fontSize="16px" fontWeight="bold" mb="20px">
                Address
              </Text>
              <Flex alignItems="center">
                <Image
                  source="my-municipality/location-pin.png"
                  alt="loc"
                  width="21px"
                  height="21px"
                  mr="10px"
                  objectFit="contain"
                />
                <Text fontSize="14px">{business.address}</Text>
              </Flex>
              <Flex width="100%" mt="10px">
                <MapContainer
                  center={[
                    parseFloat(business.latitude),
                    parseFloat(business.longitude),
                  ]}
                  zoom={18}
                  scrollWheelZoom={false}
                  style={{
                    height: '300px',
                    width: '100%',
                    borderRadius: '4px',
                  }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    icon={MSCMarker}
                    position={[
                      parseFloat(business.latitude),
                      parseFloat(business.longitude),
                    ]}
                  >
                    <Popup>{decodeHtml(business.name)}</Popup>
                  </Marker>
                </MapContainer>
              </Flex>
            </Flex>
          </Grid>
          <Flex
            p="20px"
            borderRadius="4px"
            flex="1"
            width="100%"
            flexDirection="column"
            backgroundColor="rgba(240,240,240, 0.5)"
            mb="20px"
            mobileCss={css`
              background-color: white;
              padding: 0;
            `}
          >
            <Text
              textAlign="center"
              fontSize="16px"
              fontWeight="bold"
              mb="10px"
            >
              Related Listings
            </Text>
            <Grid gridRowGap="20px" hideScrollBar>
              {business.related_ids.slice(0, 4).map((relatedBusinessId) => (
                <RelatedListing id={relatedBusinessId} />
              ))}
            </Grid>
          </Flex>
        </Grid>
      )}
    </MunicipalPageWrapper>
  );
};

export default SingleListing;
