import React, { useState, useEffect } from 'react';

import { Modal } from 'components/social';
import { Input } from 'components/social/createpost/createpost.styled';
import {
  BottomContainer,
  Line,
  Heading,
  Button,
  ScrollContainer,
  BoardPreview,
  ButtonLabel,
  ButtonBall,
  CheckboxPlus,
  ErrorLabel,
} from './lottoModal.styled';

const getBoardChar = (i) => String.fromCharCode(i + 65);

/**
 * Details Boards
 */
const DetailsModal = (props) => {
  // props
  const {
    show,
    boards,
    handleClose,
    handleBack,
    handleNext,
    mobileNumber,
    product,
    category,
    subcategory,
    handleChangePlus,
    plus,
  } = props;

  // state
  const [num, setNum] = useState(mobileNumber || '');

  const [valid, setValid] = useState();

  useEffect(() => {
    const isValidNumber = (n) => /^(\+27|0)[6-8][0-9]{8}$/.test(n);
    setValid(isValidNumber(num));
  }, [num]);

  const getMaxBoardNumbers = () => {
    if (product?.name === 'Daily Lotto Selfpick') return 5;
    return 6;
  };

  return (
    <Modal
      title="Lotto Details"
      show={show}
      handleClose={handleClose}
      backButton
      handleBack={() => handleBack(num)}
    >
      <ScrollContainer>
        {category === 'Lotto Selfpick' && (
          <>
            {boards.map((b, i) => (
              <BoardPreview>
                <ButtonLabel>{`Board ${getBoardChar(i)}`}</ButtonLabel>
                {[...Array(getMaxBoardNumbers())].map((_, j) => (
                  <ButtonBall
                    number={j}
                    empty={j >= b.length}
                  >
                    {b[j]}
                  </ButtonBall>
                ))}
              </BoardPreview>
            ))}
          </>
        )}

        <Heading>Phone Number</Heading>

        <Input
          placeholder="Enter phone number"
          type="text"
          inputmode="numeric"
          value={num}
          onChange={(e) => setNum(e.target.value)}
        />

        {(!valid && num) && (
          <ErrorLabel>
            Please enter a valid phone number.
            The number may start with either a zero, e.g. &quot;0825551234&quot;,
            or the international format country code, e.g. &quot;+27825551234&quot;.
          </ErrorLabel>
        )}

        {(subcategory === 'Lotto') && (
          <>
            <Heading>Add Plus 1 and 2</Heading>

            <CheckboxPlus
              active={plus === 1 || plus === 2}
              onClick={() => handleChangePlus(1)}
            >
              <img
                src={(plus === 1 || plus === 2) ? 'inthecity/purchase/select-icon.svg' : 'inthecity/purchase/unselect-icon.svg'}
                alt="checkmark"
              />
              <p>Lotto Plus 1</p>
            </CheckboxPlus>
            <CheckboxPlus
              active={plus === 2}
              onClick={() => handleChangePlus(2)}
            >
              <img
                src={(plus === 2) ? 'inthecity/purchase/select-icon.svg' : 'inthecity/purchase/unselect-icon.svg'}
                alt="checkmark"
              />
              <p>Lotto Plus 2</p>
            </CheckboxPlus>
          </>
        )}

        {(subcategory === 'Powerball') && (
          <>
            <Heading>Add Plus</Heading>

            <CheckboxPlus
              active={plus === 1}
              onClick={() => handleChangePlus(1)}
            >
              <img
                src={(plus === 1) ? 'inthecity/purchase/select-icon.svg' : 'inthecity/purchase/unselect-icon.svg'}
                alt="checkmark"
              />
              <p>Powerball Plus</p>
            </CheckboxPlus>
          </>
        )}
      </ScrollContainer>

      <BottomContainer>
        <Line />
        <Button
          disabled={!valid}
          onClick={valid ? () => handleNext(num) : () => {}}
        >
          Next
        </Button>
      </BottomContainer>
    </Modal>
  );
};

export default DetailsModal;
