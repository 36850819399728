import { getAnalytics, logEvent } from 'firebase/analytics';

const analytics = getAnalytics();

export enum PrivateServicesEvents {
  BookAService = 'book_a_service_WEB',
  BookDomestic = 'book_domestic_WEB',
  BookGardener = 'book_gardener_WEB',
  CheckoutDomestic = 'continue_to_checkout_domestic_WEB',
  CheckoutGardener = 'continue_to_checkout_gardener_WEB',
  PaymentSuccess = 'payment_success_WEB',
  BookInsurance = 'book_insurance_WEB',
  CancelBooking = 'cancel_booking_WEB',
  MakeBooking = 'make_booking_WEB',
  RescheduleBooking = 'reschedule_booking_WEB', // functionality of reschedule booking is not implemented yet
}

export enum HomePageEvents {
  LogReport = 'log_report_WEB',
  EmergencyServices = 'emergency_services_WEB',
  AirtimeAndData = 'airtime_and_data_WEB',
  Lotto = 'lotto_WEB',
  PlacesOfInterest = 'places_of_interest_WEB',
  Events = 'events_WEB',
  SuggestService = 'suggest_service_WEB',
  SubmitSuggestion = 'submit_suggestion_WEB',
}

export enum UserEvents {
  Login = 'login_WEB',
  Logout = 'logout_WEB',
  CreateAccount = 'create_account_WEB',
  Register = 'register_WEB',
  LoginAsGuest = 'login_as_guest_WEB',
  SelectCity = 'select_city_WEB',
  ResetPassword = 'reset_password_WEB',
  NavigateToPage = 'navigate_to_page_WEB',
}
export const logFirebaseEvent = (
  eventName: string,
  params?: Record<string, any>
) => {
  logEvent(analytics, eventName, params);
};
