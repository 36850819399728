/* eslint-disable no-unused-vars */

/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Loader } from 'components/social/createpost';
import { profileImageUrl } from 'functions/social';
import { useGlobalContext } from 'contexts/global';
import {
  getCommentsForWorkrequest,
  commentOnWorkRequest,
} from 'interface/social';
import {
  Readmore,
  Loadmore,
  Container,
  CommentContainer,
  ContentContainer,
  ProfileImage,
  InputContainer,
  Input,
  Submit,
  Header,
  Content,
  Name,
  Category,
  Time,
} from './comment.styled';

const CommentSection = ({ workRequestId, handlePostComment }) => {
  const [showSubmit, setShowSubmit] = useState(false);
  const [text, setText] = useState('');
  const [commentsData, setCommentsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);

  const {
    globalState: { user },
  } = useGlobalContext();

  useEffect(() => {
    async function fetchData() {
      const res = await getCommentsForWorkrequest(workRequestId);
      setCommentsData(res?.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await getCommentsForWorkrequest(workRequestId);
      setCommentsData(res?.data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const handleSubmit = () => {
    const comment = `${text}`;

    const post = async () => {
      setPosting(true);

      const res = await commentOnWorkRequest(workRequestId, comment);
      if (res) {
        const { data } = await getCommentsForWorkrequest(workRequestId);
        setCommentsData(data);
      }

      setText('');
      setShowSubmit(false);

      setPosting(false);
    };

    if (comment.length > 0 && !posting) {
      post();
      handlePostComment();
    }
  };

  return (
    <Container>
      <CommentContainer>
        <ProfileImage src={profileImageUrl(user?.uuid)} alt="profile" />
        <ContentContainer>
          <InputContainer expand={showSubmit}>
            <Input
              type="text"
              value={text}
              onChange={(e) => handleChange(e)}
              placeholder="Add comment"
              onFocus={() => setShowSubmit(true)}
              onBlur={() => {
                if (text.length === 0) {
                  setShowSubmit(false);
                }
              }}
            />
            {showSubmit && (
              <Submit onClick={handleSubmit}>
                {posting ? 'Adding comment...' : 'Add comment'}
              </Submit>
            )}
          </InputContainer>
        </ContentContainer>
      </CommentContainer>

      {loading && <Loader />}
      {Array.isArray(commentsData) &&
        commentsData.map(({ timestamp, comment, user: userData }) => (
          <Comment timestamp={timestamp} comment={comment} user={userData} />
        ))}
    </Container>
  );
};

const Comment = (props) => {
  const { timestamp, comment, user } = props;
  const [limit, setLimit] = useState(256);

  const handleReadMore = () => {
    setLimit(limit + 400);
  };

  return (
    <CommentContainer>
      <ProfileImage src={profileImageUrl(user?.uuid)} alt="profile" />
      <ContentContainer>
        <Header>
          <Name>{user?.fullName}</Name>
          <Category>
            &nbsp;&nbsp;·&nbsp;&nbsp;
            {_.startCase(_.toLower(user?.smartCityUserRoles[0]?.description))}
          </Category>
        </Header>
        <Content>
          {comment.slice(0, limit)}
          {comment.length > limit && '...'}
          {comment.length > limit && (
            <Readmore onClick={handleReadMore}>Read more</Readmore>
          )}
        </Content>
        <Time>
          {moment.unix(timestamp?.toString()).tz(moment.tz.guess()).fromNow()}
        </Time>
      </ContentContainer>
    </CommentContainer>
  );
};

export default CommentSection;
