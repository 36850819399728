import React, { useState } from 'react';

import { Modal } from 'components/social';
import {
  BoardPreview,
  ButtonLabel,
  ButtonBall,
  BottomContainer,
  Line,
  Button,
  BallRow,
  Ball,
  ScrollContainer,
} from './lottoModal.styled';

// Get character from number, e.g. 1 -> A
const getBoardChar = (i) => String.fromCharCode(i + 65);

/**
 * Edit Board Modal
 */
const EditBoardModal = (props) => {
  // props
  const {
    handleClose,
    show,
    boards,
    currentBoard,
    updateBoard,
    handleBack,
    product,
    category,
  } = props;

  console.log('lotto:edit:props', props);

  // state
  const [board, setBoard] = useState(boards[currentBoard] || []);

  // Board rules
  const getMaxRange = (index = 0) => {
    if (product?.name === 'Daily Lotto Selfpick') return 36;

    if (product?.name.includes('Powerball')) {
      if (index < 5) return 50;
      return 20;
    }

    return 52;
  };

  const getMaxBoardNumbers = () => {
    if (product?.name === 'Daily Lotto Selfpick') return 5;
    return 6;
  };

  const getMaxBoards = () => {
    if (category === 'Lotto Selfpick') return 8;
    return 20; // 'Lotto Quickpick'
  };

  // event handler
  const handleClickNumber = (v) => {
    if (board.includes(v)) {
      const newBoard = board.filter((value) => (value !== v));
      setBoard(newBoard);
    } else if (board.length < getMaxBoardNumbers()) {
      const newBoard = [...board, v];
      setBoard(newBoard);
    }
  };

  const handleSubmitBoard = () => {
    if (board.length >= getMaxBoardNumbers()) {
      updateBoard(board, currentBoard);
      handleBack();
    }
  };

  console.log('lotto:editboard:', {
    boardIndex: board.length,
    maxRange: getMaxRange(board.length),
    maxBoardNumbers: getMaxBoardNumbers(),
    maxBoards: getMaxBoards(),
  });

  return (
    <Modal
      title={`Board ${getBoardChar(currentBoard)}`} // e.g. title 'Board A' for first board
      show={show}
      handleClose={handleClose}
      backButton
      handleBack={handleBack}
    >
      <BoardPreview>
        <ButtonLabel>{`Board ${getBoardChar(currentBoard)}`}</ButtonLabel>
        {[...Array(getMaxBoardNumbers())].map((_, i) => (
          <ButtonBall
            number={i}
            empty={i >= board.length}
          >
            {board[i]}
          </ButtonBall>
        ))}
      </BoardPreview>

      <ScrollContainer>
        {/* <BallGrid /> */}
        {[...Array(Math.round(getMaxRange(board.length) / 5) + 1)].map((_, row) => (
          <BallRow>
            {[...Array(5)].map((__, col) => {
              const value = (5 * row) + (col + 1);
              if (value <= getMaxRange(board.length)) {
                return (
                  <Ball
                    active={board.includes(value)}
                    onClick={() => handleClickNumber(value)}
                  >
                    {value}
                  </Ball>
                );
              }
              return (
                <div style={{ width: '43px', height: '43px' }} />
              );
            })}
          </BallRow>
        ))}
      </ScrollContainer>

      <BottomContainer>
        <Line />
        <Button
          disabled={board.length < getMaxBoardNumbers()}
          onClick={handleSubmitBoard}
        >
          Save Board
        </Button>
      </BottomContainer>
    </Modal>
  );
};

export default EditBoardModal;
