/* eslint-disable arrow-body-style */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Container, Button } from './categoryBar.styled';

/**
 * Category Bar
 */
const CategoryBar = (props) => {
  const {
    categories = [],
    link,
  } = props;
  const location = useLocation();
  const activeCategory = location.state?.categoryId || '1184162'; // defaults to all/featured id

  return (
    <Container>
      {categories?.slice(0, 5).map((cat) => (
        <Link
          to={link}
          state={{ categoryId: cat.categoryId }}
        >
          <Button
            active={cat.categoryId === activeCategory}
          >
            {cat.category === 'Featured' ? 'All' : cat.category}
          </Button>
        </Link>
      ))}
    </Container>
  );
};

export default CategoryBar;
