import styled from 'styled-components';

export const Grid = styled.div`
  width: 100%;
  max-width: 1120px;
  display: grid;
  grid-column-gap: 11px;
  grid-row-gap: 11px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 160px 160px;
  margin: 0px 0px 30px 0px;

  @media only screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 260px 260px;
    grid-column-gap: 80px;
    grid-row-gap: 30px;
    margin: 0 auto;
  }
`;

export const Tile = styled.div`
  position: relative;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: background-size 0.5s;

  &:hover {
    background-size: auto 120%;
  }
`;

export const TileTitle = styled.h2`
  color: #FFFFFF;
  font: normal normal 500 13px/15px Roboto;
  padding-left: 11px;
  padding-top: 11px;
  max-width: 80%;

  @media only screen and (min-width: 800px) {
    font: normal normal 500 20px/26px Roboto;
    color: #FFFFFF;
    padding-left: 30px;
    padding-top: 20px;
  }
`;

export const TileInfo = styled.div`
  font: normal normal normal 12px/14px Roboto;
  color: #FFFFFF;
  text-align: bottom;
  padding-left: 11px;
  padding-bottom: 11px;
  position: absolute;
  bottom: 0px;

  @media only screen and (min-width: 800px) {
    padding-bottom: 25px;
    padding-left: 30px;
    font: normal normal normal 14px/19px Roboto;
  }
`;

export const TileIcon = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  width: 16px;
  height: 16px;
  position: absolute;
  margin-top: 8px;
  margin-right: 11px;
  margin-left: 11px;
  top: 0px;
  right: 0px;

  @media only screen and (min-width: 800px) {
    width: 32px;
    height: 32px;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
`;
