import styled from 'styled-components';

export const Title = styled.h1`
  font: normal normal 500 ${(props) => (props.theme.mobileFontSizes[3])} ${(props) => (props.theme.font)};
  color: ${(props) => (props.theme.colors.dark)};
  width: 100%;
  text-align: center;
  margin: 18px 0;

  @media only screen and (min-width: 800px) {
    font: normal normal 500 ${(props) => (props.theme.fontSizes[3])} ${(props) => (props.theme.font)};
    margin: 35px 0;
  }
`;

export const Banner = styled.div`
  background-image: url("city/cape_town.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90px;
  width:  100%;
  position: relative;
  opacity: 1;
  display: none;

  @media only screen and (min-width: 800px) {
    display: block
  }
`;

export const PurchaseGrid = styled.div`
  width: 100%;
  max-width: 1120px;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin: 14px auto;

  @media only screen and (min-width: 600px) {
  }

  @media only screen and (min-width: 800px) {
    grid-template-columns: 50% 50%;
    grid-row-gap: 22px;
  }
`;

export const PurchaseTile = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto 0;
`;

export const Line = styled.div`
  opacity: 0.46;
  width: 100%;
  height: 2px;
  background-color: #DEE2E6;
  margin: 20px 0px;

  @media only screen and (min-width: 800px) {
    margin: 40px 0px;
  }
`;

export const TileContainer = styled.div`
  margin: 0;
  padding: 0px 20px;
  width: 100%;

  @media only screen and (min-width: 800px) {
    margin: 0px auto;
    padding: 0;
    width: 700px;
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;

  @media only screen and (min-width: 800px) {
    margin-bottom: 24px;
  }
`;
