import { GMGISFeature, MSCMapPoint } from 'types/types';

export const GIStoMSCObject = (
  data: GMGISFeature,
  customIcon: string,
  orgUnitId: number
): MSCMapPoint => {
  const point: MSCMapPoint = {
    business_status: 'OPERATIONAL',
    formatted_address: '',
    icon: '',
    icon_background_color: '',
    icon_mask_base_uri: '',
    opening_hours: {},
    place_id: '',
    plus_code: {
      compound_code: '',
      global_code: '',
    },
    rating: 0,
    reference: '',
    user_ratings_total: 0,
    id: '',
    orgUnitId,
    customIcon,
    lat: 0,
    lng: 0,
    address: '',
    typeDescription: '',
    imageIds: [],
    custom1: '',
    emergencyService: true,
  };

  if (data.properties && data.properties.Name) {
    point.formatted_address = data.properties.Name;
  }

  if (data.geometry && data.geometry.coordinates) {
    [point.lng, point.lat] = data.geometry.coordinates;
  }

  // Copy other properties if available
  if (data.properties) {
    point.id = data.properties.OBJECTID.toString();
    point.typeDescription = data.properties.Name || '';
    point.reference = data.properties.OBJECTID
      ? data.properties.OBJECTID.toString()
      : '';
    point.custom1 = data.properties.Facility_category || '';
  }

  return point;
};
