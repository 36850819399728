import { useState } from 'react';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export type endpointType = {
  method: 'GET' | 'POST';
  endpoint: string;
  requiresAuth?: boolean;
  responseType?: 'json' | 'blob' | 'text';
  isFileUpload?: boolean;
};

export const SCManagerEndpoints: Record<string, endpointType> = {
  SuggestNewService: {
    method: 'POST',
    endpoint: 'suggestService',
    requiresAuth: true,
  },
  SuggestedServices: {
    method: 'GET',
    endpoint: 'getSuggestedServices',
    requiresAuth: true,
  },
  AllServices: {
    method: 'GET',
    endpoint: 'getAvailablePrivateServices',
    requiresAuth: true,
  },
  GetServiceInfo: {
    method: 'GET',
    endpoint: 'getSmartCityServicePage',
    requiresAuth: true,
  },
  GetEstimatedCost: {
    method: 'POST',
    endpoint: 'getEstimatedCost',
    requiresAuth: true,
  },
  GetSuggestedResources: {
    method: 'GET',
    endpoint: 'getSuggestedResources',
    requiresAuth: true,
  },
  GetSuggestedResourcesForGuest: {
    method: 'GET',
    endpoint: 'getSuggestedResourcesForGuest',
    requiresAuth: true,
  },
  GetCustomerPremises: {
    method: 'GET',
    endpoint: 'getCustomerPremises',
    requiresAuth: true,
  },
  GetServicesForWard: {
    method: 'GET',
    endpoint: 'getServicesForWard',
    requiresAuth: true,
  },
  GetPremiseTypes: {
    method: 'GET',
    endpoint: 'getPremiseTypes',
    requiresAuth: true,
  },
  CreateCustomerPremise: {
    method: 'POST',
    endpoint: 'createCustomerPremise',
    requiresAuth: true,
  },
  UpdateCustomerPremise: {
    method: 'POST',
    endpoint: 'updateCustomerPremise',
    requiresAuth: true,
  },
  GetPrivateServicesResource: {
    method: 'GET',
    endpoint: 'getPrivateServicesResource',
    requiresAuth: true,
  },
  BookService: {
    method: 'POST',
    endpoint: 'bookService',
    requiresAuth: true,
    responseType: 'text',
  },
  DoInitializePeach: {
    method: 'POST',
    endpoint: 'doInitializePeachPurchase',
    requiresAuth: true,
    responseType: 'text',
  },
  GetPeachPaymentStatus: {
    method: 'GET',
    endpoint: 'getPeachPaymentStatus',
    requiresAuth: true,
  },
  GetResourceChat: {
    method: 'GET',
    endpoint: 'getResourceChat',
    requiresAuth: true,
  },
  SendMessageToResource: {
    method: 'POST',
    endpoint: 'sendMessageToResource',
    requiresAuth: true,
  },
  GetMyBookings: {
    method: 'GET',
    endpoint: 'getMyBookings',
    requiresAuth: true,
  },
  GetEnRouteResources: {
    method: 'GET',
    endpoint: 'getEnRouteResources',
    requiresAuth: true,
  },
  GetResourceTrackingPosition: {
    method: 'GET',
    endpoint: 'getResourceTrackingPosition',
    requiresAuth: true,
  },
  SetPreferredSupplier: {
    method: 'POST',
    endpoint: 'setPreferredSupplier',
    requiresAuth: true,
  },
  AddStarRating: {
    method: 'POST',
    endpoint: 'addStarRating',
    requiresAuth: true,
  },
  GetBooking: {
    method: 'GET',
    endpoint: 'getBooking',
    requiresAuth: true,
  },
  CancelBooking: {
    method: 'POST',
    endpoint: 'cancelBooking',
    requiresAuth: true,
  },
  RescheduleBooking: {
    method: 'POST',
    endpoint: 'rescheduleBooking',
    requiresAuth: true,
    responseType: 'text',
  },
  GetEstimatedReschedulingCost: {
    method: 'POST',
    endpoint: 'getEstimatedCostReschedule',
    requiresAuth: true,
  },
  ConfirmCostFreeReschedule: {
    method: 'POST',
    endpoint: 'comfirmCostFreeReschedule',
    requiresAuth: true,
    responseType: 'text',
  },
  GetSmartCityUser: {
    method: 'GET',
    endpoint: 'getCurrentSmartCityUser',
    requiresAuth: true,
  },
  UpdateProfile: {
    method: 'POST',
    endpoint: 'updateProfile',
    requiresAuth: true,
  },
  UpdateMarketingFlag: {
    method: 'POST',
    endpoint: 'updateMarketingFlag',
    requiresAuth: true,
  },
  GetEmergencyContacts: {
    method: 'GET',
    endpoint: 'getEmergencyContacts',
    requiresAuth: true,
  },
  AddEmergencyContact: {
    method: 'POST',
    endpoint: 'addEmergencyContact',
    requiresAuth: true,
  },
  DeleteEmergencyContact: {
    method: 'POST',
    endpoint: 'deleteEmergencyContact',
    requiresAuth: true,
  },
  GetFavouriteCities: {
    method: 'GET',
    endpoint: 'getFavoriteCities', // Yes only this one is the American spelling
    requiresAuth: true,
  },
  AddFavouriteCity: {
    method: 'POST',
    endpoint: 'addFavoriteCity',
    requiresAuth: true,
  },
  AddProfilePic: {
    method: 'POST',
    endpoint: 'addProfilePic',
    requiresAuth: true,
    responseType: 'text',
    isFileUpload: true,
  },
  LogSupportCall: {
    method: 'POST',
    endpoint: 'logSupportCall',
    requiresAuth: true,
    responseType: 'text',
  },
  GetMeterReadingsForLocation: {
    method: 'GET',
    endpoint: 'getMeterReadingsForFavLocation',
    requiresAuth: true,
  },
  PostMeterReading: {
    method: 'POST',
    endpoint: 'postMeterReading',
    requiresAuth: true,
  },
  GetAvailableSurveys: {
    method: 'GET',
    endpoint: 'getAvailableSurveys',
    requiresAuth: true,
  },
  PostDemographic: {
    method: 'POST',
    endpoint: 'postDemographic',
    requiresAuth: true,
  },
  PostSurvey: {
    method: 'POST',
    endpoint: 'postSurvey',
    requiresAuth: true,
  },
  GetMySurveys: {
    method: 'GET',
    endpoint: 'getMySurveys',
    requiresAuth: true,
  },
  GetAvailableIDPS: {
    method: 'GET',
    endpoint: 'getAvailableIDPS',
    requiresAuth: true,
  },
  PostIDP: {
    method: 'POST',
    endpoint: 'postIDP',
    requiresAuth: true,
  },
  GetMyIDPS: {
    method: 'GET',
    endpoint: 'getMyIDPS',
    requiresAuth: true,
  },
  GetWardNoForLocation: {
    method: 'GET',
    endpoint: 'getWardNoForCoordinates',
    requiresAuth: true,
  },
  AddAccountToPremise: {
    method: 'POST',
    endpoint: 'addAccountToPremise',
    requiresAuth: true,
  },
  RemoveAccountFromPremise: {
    method: 'POST',
    endpoint: 'removeAccountFromPremise',
    requiresAuth: true,
  },
  GetTopLevelOrgUnits: {
    method: 'GET',
    endpoint: 'getTopLevelOrgUnits',
    requiresAuth: true,
  },
  GetChildOrgUnits: {
    method: 'GET',
    endpoint: 'getChildOrgUnits',
    requiresAuth: true,
  },
  GetMunicipalAccounts: {
    method: 'GET',
    endpoint: 'getMunicipalAccounts',
    requiresAuth: true,
  },
  GetPaymentHistoryForAccount: {
    method: 'GET',
    endpoint: 'getPaymentHistoryForAccount',
    requiresAuth: true,
  },
  GetMeterTypes: {
    method: 'GET',
    endpoint: 'getMeterTypes',
    requiresAuth: true,
  },
  AddMeter: {
    method: 'POST',
    endpoint: 'addMeter',
    requiresAuth: true,
  },
  RemoveMeter: {
    method: 'POST',
    endpoint: 'removeMeterFromAccount',
    requiresAuth: true,
  },
  GetMeterReadingsForFavLocation: {
    method: 'GET',
    endpoint: 'getMeterReadingsForFavLocation',
    requiresAuth: true,
  },
  GetChats: {
    method: 'GET',
    endpoint: 'getChats',
    requiresAuth: true,
  },
  SendMessageToBackOffice: {
    method: 'POST',
    endpoint: 'sendMessageToBackOffice',
    requiresAuth: true,
  },
  IsValidCoupon: {
    method: 'POST',
    endpoint: 'isValidCoupon',
    requiresAuth: true,
  },
};

export interface SCManagerParams {
  body?: any;
  queryParams?: string;
  headers?: Record<string, string>;
}

const useSmartCityManager = (endpoint: endpointType) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const firebaseToken = cookies.get('firebaseToken');
  const api = `https://${process.env.REACT_APP_API_BASE}/api/msc/rest/smartcitymanager/`;

  const headers = new Headers();

  if (endpoint.requiresAuth)
    headers.append('firebase_token', firebaseToken || '');

  if (!endpoint.isFileUpload) {
    headers.append('Content-Type', 'application/json');
  }

  const callApi = async (params?: SCManagerParams) => {
    setResponse(null);
    if (params?.headers) {
      console.log(params.headers);
      Object.keys(params.headers).forEach((key) => {
        params.headers && headers.append(key, params.headers[key]);
      });
    }
    const requestBody = () => {
      if (endpoint.isFileUpload && params) {
        const formData = new FormData();
        formData.append('file', params.body);
        return formData;
      }
      return JSON.stringify(params?.body);
    };
    const config = {
      method: endpoint.method,
      headers,
      body: params?.body ? requestBody() : null,
    };
    setLoading(true);
    if (!endpoint.responseType || endpoint.responseType === 'json') {
      fetch(
        `${api}${endpoint.endpoint}?${
          params?.queryParams ? params.queryParams : ''
        }`,
        config
      )
        .then((res) => res.json())
        .then((res) => {
          setResponse(res);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    } else if (endpoint.responseType === 'text') {
      fetch(
        `${api}${endpoint.endpoint}?${
          params?.queryParams ? params.queryParams : ''
        }`,
        config
      )
        .then((res) => res.text())
        .then((res) => {
          setResponse(res);
          setLoading(false);
        })
        .catch((err) => setError(err));
    }
  };

  return { loading, response, error, callApi };
};

export default useSmartCityManager;
