import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 280px;

  @media only screen and (min-width: 800px) {
    min-height: 450px;
  }
`;

// Header bar and navigation buttons

export const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  align-items: center;
  
  @media only screen and (min-width: 800px) {
    margin-bottom: 30px;
  }
`;

export const SeeAll = styled.div`
  display: none;

  @media only screen and (min-width: 800px) {
    cursor: pointer;
    display: block;
    margin-left: auto;
    text-decoration: underline;
    font: normal normal normal 14px/19px ${(props) => (props.theme.font)};
    color: ${(props) => (props.theme.colors.dark)};
    margin-right: 15px;
  }
`;

export const LeftButton = styled.div`
  display: none;

  @media only screen and (min-width: 800px) {
    display: block;
    background: url(${(props) => (props.active ? 'inthecity/navigation-active-icon.svg' : 'inthecity/navigation-icon.svg')}) no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    height: 30px;
    width: 30px;
    transform: scaleX(-1);
    margin-right: 15px;
  }
`;

export const RightButton = styled.div`
  display: none;

  @media only screen and (min-width: 800px) {
    display: block;
    background: url(${(props) => (props.active ? 'inthecity/navigation-active-icon.svg' : 'inthecity/navigation-icon.svg')}) no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    height: 30px;
    width: 30px;
  }
`;

// Cards container

export const CardsGrid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(${(props) => (props.columns)}, minmax(0, 1fr));
  grid-auto-rows: 450px;
  width: 100%;
  margin-bottom: 35px;

  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar { display: none; } /* Chrome */

  @media only screen and (min-width: 800px) {
    grid-template-columns: repeat(${(props) => (props.columns)}, minmax(0, 1fr));
    grid-auto-rows: 450px;
    grid-gap: 25px;
    margin-bottom: 55px;
  }
`;
