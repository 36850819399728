import { apiUrl, apiUrlGMAPS } from 'constants/app';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const getMapPetitionsNearMeInViewPort = async (
  includeMyWork,
  orgUnitIds,
  pageNo,
  limitPerPage,
  viewport,
  subscriberId,
  authenticatedState,
  cluster,
  clusterColumns,
  clusterRows
) => {
  if (authenticatedState) {
    return fetch(
      `${apiUrl}smartcitymanager/getMapPetitionsNearMeInViewPort?` +
        `includeMyWork=${includeMyWork}` +
        `&workTypeIds=${orgUnitIds}` +
        `&northEastLong=${viewport.northEast.lng}` +
        `&northEastLat=${viewport.northEast.lat}` +
        `&southWestLat=${viewport.southWest.lat}` +
        `&southWestLong=${viewport.southWest.lng}` +
        `&cluster=${cluster}` +
        `&limitPerPage=${limitPerPage}` +
        `&pageNo=${pageNo}` +
        `&clusterColumns=${clusterColumns}` +
        `&clusterRows=${clusterRows}`,
      {
        method: 'GET',
        async: true,
        headers: {
          Accept: 'application/json, text/javascript; q=0.01',
          'Content-Type': 'application/problem+json',
          firebase_token: cookies.get('firebaseToken'),
        },
      }
    )
      .then(async (res) => {
        let temp = [];
        try {
          temp = await res.json();
          if (!temp.clusters && `${temp.clusters}` === 'undefined') {
            temp = { clusters: [], mapWorkRequests: temp.data };
          }
        } catch (e) {
          console.log(e);
          temp = [];
        }
        return temp;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  }
  return fetch(
    `${apiUrl}smartcitymanager/getMapPetitionsNearMeInViewPort?` +
      `includeMyWork=${includeMyWork}` +
      `&workTypeIds=${orgUnitIds}` +
      `&northEastLong=${viewport.northEast.lng}` +
      `&northEastLat=${viewport.northEast.lat}` +
      `&southWestLat=${viewport.southWest.lat}` +
      `&southWestLong=${viewport.southWest.lng}` +
      `&cluster=${cluster}` +
      `&limitPerPage=${limitPerPage}` +
      `&pageNo=${pageNo}` +
      `&clusterColumns=${clusterColumns}` +
      `&clusterRows=${clusterRows}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        subscriberId,
      },
    }
  )
    .then(async (res) => {
      let temp = [];
      try {
        temp = await res.json();
        if (!temp.clusters && `${temp.clusters}` === 'undefined') {
          temp = { clusters: [], mapWorkRequests: temp.data };
        }
      } catch (e) {
        console.log(e);
        temp = [];
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};

export const getMapWorkRequestsNearMeInViewPort = async (
  includeMyWork,
  orgUnitIds,
  pageNo,
  limitPerPage,
  viewport,
  subscriberId,
  authenticatedState,
  cluster,
  clusterColumns,
  clusterRows
) => {
  console.log('getMapWorkRequestsNearMeInViewPort viewport', viewport);
  if (authenticatedState) {
    return fetch(
      `${apiUrl}smartcitymanager/getMapWorkRequestsNearMeInViewPort?` +
        `includeMyWork=${includeMyWork}` +
        `&orgUnitIds=${orgUnitIds}` +
        `&northEastLong=${viewport.northEast.lng}` +
        `&northEastLat=${viewport.northEast.lat}` +
        `&southWestLat=${viewport.southWest.lat}` +
        `&southWestLong=${viewport.southWest.lng}` +
        `&cluster=${cluster}` +
        `&limitPerPage=${limitPerPage}` +
        `&pageNo=${pageNo}` +
        `&clusterColumns=${clusterColumns}` +
        `&clusterRows=${clusterRows}`,
      {
        method: 'GET',
        async: true,
        headers: {
          Accept: 'application/json, text/javascript; q=0.01',
          'Content-Type': 'application/problem+json',
          firebase_token: cookies.get('firebaseToken'),
        },
      }
    )
      .then(async (res) => {
        let temp = [];
        try {
          temp = await res.json();
          if (!temp.clusters && `${temp.clusters}` === 'undefined') {
            temp = { clusters: [], mapWorkRequests: temp.data };
          }
        } catch (e) {
          console.log(e);
          temp = { clusters: [], mapWorkRequests: [] };
        }
        return temp;
      })
      .catch((e) => {
        console.log(e);
        return { clusters: [], mapWorkRequests: [] };
      });
  }
  return fetch(
    `${apiUrl}smartcitymanager/getMapWorkRequestsNearMeInViewPort?` +
      `includeMyWork=${includeMyWork}` +
      `&orgUnitIds=${orgUnitIds}` +
      `&northEastLong=${viewport.northEast.lng}` +
      `&northEastLat=${viewport.northEast.lat}` +
      `&southWestLat=${viewport.southWest.lat}` +
      `&southWestLong=${viewport.southWest.lng}` +
      `&cluster=${cluster}` +
      `&limitPerPage=${limitPerPage}` +
      `&pageNo=${pageNo}` +
      `&clusterColumns=${clusterColumns}` +
      `&clusterRows=${clusterRows}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        subscriberId,
      },
    }
  )
    .then(async (res) => {
      let temp = [];
      try {
        temp = await res.json();
        if (!temp.clusters && `${temp.clusters}` === 'undefined') {
          temp = { clusters: [], mapWorkRequests: temp.data };
        }
      } catch (e) {
        console.log(e);
        temp = { clusters: [], mapWorkRequests: [] };
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return { clusters: [], mapWorkRequests: [] };
    });
};

export const getMyMapWorkRequests = async (pageNo, pageLimit) =>
  fetch(
    `${apiUrl}smartcitymanager/getMyMapWorkRequests?` +
      `pageLimit=${pageLimit}` +
      `&pageNo=${pageNo}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      let temp = [];
      try {
        temp = res.json().then((re) => {
          console.log('getMyMapWorkRequests', re);
          return re.data;
        });
      } catch (e) {
        console.log(e);
        temp = [];
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });

export const getMyMapPetitions = async (pageNo, pageLimit) =>
  fetch(
    `${apiUrl}smartcitymanager/getMyMapPetitions?` +
      `pageLimit=${pageLimit}` +
      `&pageNo=${pageNo}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then(async (res) => {
      let temp = [];
      try {
        temp = res.json().then((re) => {
          console.log('getMyMapPetitions', re);
          return re.data;
        });
      } catch (e) {
        console.log(e);
        temp = [];
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });

export const getWorkRequest = async (id, subscriberId, authenticatedState) => {
  if (authenticatedState) {
    return fetch(`${apiUrl}smartcitymanager/getWorkRequest?` + `id=${id}`, {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    })
      .then((res) => {
        let temp = -1;
        try {
          temp = res.json().then((re) => {
            console.log('getWorkRequest', re);
            return re;
          });
        } catch (e) {
          console.error(e);
        }
        return temp;
      })
      .catch((e) => {
        console.error(e);
        return { imageIds: [], notes: [] };
      });
  }
  return fetch(
    `${apiUrl}smartcitymanager/getWorkRequest?` +
      `id=${id}` +
      `&subscriberId=${subscriberId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        subscriberId,
      },
    }
  )
    .then((res) => {
      let temp = -1;
      try {
        temp = res.json().then((re) => {
          console.log('getWorkRequest', re);
          return re;
        });
      } catch (e) {
        console.error(e);
        temp = { imageIds: [], notes: [] };
      }
      return temp;
    })
    .catch((e) => {
      console.error(e);
      return { imageIds: [], notes: [] };
    });
};

export const getServiceProvider = async (orgUnitId, latitude, longitude) =>
  fetch(
    `${apiUrl}smartcitymanager/getServiceProvider?orgUnitId=${orgUnitId}&latitude=${latitude}&longitude=${longitude}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

export const getGoogleMapsAutoComplete = async (input) => fetch(
  `${apiUrlGMAPS}place/autocomplete/json?input=${input}&components=country:za&key=AIzaSyAoMxu7f1QrVK9_xggoZGi4uWKZRuVIZmw`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const getCustomerPremiseAccountNo = async (
  premiseId,
  orgUnitId,
  workTypeId
) =>
  fetch(
    `${apiUrl}smartcitymanager/getCustomerPremiseAccountNo?premiseId=${premiseId}&orgUnitId=${orgUnitId}&workTypeId=${workTypeId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

export const sendEmergencyMessage = async (lat, long) =>
  fetch(
    `${apiUrl}smartcitymanager/sendEmergencyMessage?latitude=${lat}&longitude=${long}`,
    {
      method: 'POST',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

// Google maps calls for the ES
export const getGoogleMapsEntries = async (query, location, radius) => fetch(
  `${apiUrlGMAPS}place/textsearch/json?query=${query}&location=${location}&radius=${radius}&rankedby=distance&key=AIzaSyAoMxu7f1QrVK9_xggoZGi4uWKZRuVIZmw`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const getGoogleMapsEntryID = async (placeid) => fetch(
  `${apiUrlGMAPS}place/details/json?placeid=${placeid}&key=AIzaSyAoMxu7f1QrVK9_xggoZGi4uWKZRuVIZmw`,
  {
    method: 'GET',
    async: true,
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const getWorkRequestsNearMe = async (centreLat, centreLong, pageLimit) =>
  fetch(
    `${apiUrl}smartcitymanager/getWorkRequestsNearMe?centreLat=${centreLat}&centreLong=${centreLong}&pageLimit=${pageLimit}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

export const getWorkRequestsNearMeWithRadius = async (
  centreLat,
  centreLong,
  pageLimit,
  radius,
  subscriberId,
  authenticatedState
) => {
  if (authenticatedState) {
    return fetch(
      `${apiUrl}smartcitymanager/getWorkRequestsNearMeWithRadius?` +
        `centreLat=${centreLat}` +
        `&centreLong=${centreLong}` +
        `&pageLimit=${pageLimit}` +
        `&radius=${radius}`,
      {
        method: 'GET',
        async: true,
        headers: {
          Accept: 'application/json, text/javascript; q=0.01',
          'Content-Type': 'application/problem+json',
          firebase_token: cookies.get('firebaseToken'),
        },
      }
    )
      .then((res) => {
        let temp = [];
        try {
          temp = res.json().then((re) => re.data);
        } catch (e) {
          console.log(e);
          temp = [];
        }
        return temp;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  }
  return fetch(
    `${apiUrl}smartcitymanager/getWorkRequestsNearMeWithRadius?` +
      `centreLat=${centreLat}` +
      `&centreLong=${centreLong}` +
      `&pageLimit=${pageLimit}` +
      `&radius=${radius}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        subscriberId,
      },
    }
  )
    .then((res) => {
      let temp = [];
      try {
        temp = res.json().then((re) => re.data);
      } catch (e) {
        console.log(e);
        temp = [];
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};

export const getAllLookupData = async (id) =>
  fetch(`${apiUrl}smartcitymanager/getAllLookupData?subscriberId=${id}`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return { orgUnitTypes: [], orgUnits: [] };
    });

export const getNotes = async (workRequestId, subscriberId) =>
  fetch(
    `${apiUrl}smartcitymanager/getNotes?id=${workRequestId}&subscriberId=${subscriberId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

export const logWorkRequest = async (workRequest) =>
  fetch(`${apiUrl}smartcitymanager/logWorkRequest2`, {
    method: 'POST',
    async: true,
    body: workRequest,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
      processData: false,
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

export const updateWorkRequest = async (workRequest) =>
  fetch(`${apiUrl}smartcitymanager/updateWorkRequest`, {
    method: 'POST',
    async: true,
    body: workRequest,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

// reason is a string
export const cancelWorkRequest = async (workRequestId, reason) =>
  fetch(
    `${apiUrl}smartcitymanager/cancelWorkRequest?workRequestId=${workRequestId}&reason=${reason}`,
    {
      method: 'POST',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

export const addWorkRequestNote = async (workRequestId, noteText) =>
  fetch(
    `${apiUrl}smartcitymanager/addWorkRequestNote?workRequestId=${workRequestId}&noteText=${noteText}`,
    {
      method: 'POST',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

// Thisconsumes form data to add an image
// @FormDataParam("file") InputStream uploadedInputStream,
// @FormDataParam("file") FormDataContentDisposition fileDetail,
// @FormDataParam("workRequestId") long workRequestId)

export const addWorkRequestAttachment = (formData) =>
  fetch(`${apiUrl}smartcitymanager/addWorkRequestAttachment`, {
    method: 'POST',
    async: false,
    credentials: 'include',
    body: formData,
    processData: false,
    contentType: false,
    headers: {
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.error('Comms error:', e);
      return [];
    });

export const escalateWorkRequest = async (workRequestId) =>
  fetch(`${apiUrl}smartcitymanager/escalateWorkRequest?id=${workRequestId}`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

export const upVoteWorkRequest = async (workRequestId) =>
  fetch(`${apiUrl}smartcitymanager/upVoteWorkRequest?id=${workRequestId}`, {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

// Get top level org units
export const getTopLevelOrgUnits = async (type, subscriberId) =>
  fetch(
    `${apiUrl}smartcitymanager/getTopLevelOrgUnits?orgUnitTypeCode=${type}&subscriberId=${subscriberId}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.error(e);
      return { data: [] };
    });

// Get getChildOrgUnits
export const getChildOrgUnits = async (type) =>
  fetch(
    `${apiUrl}smartcitymanager/getChildOrgUnits?parentOrgUnitId=${type}&pageNo=1&pageLimit=1000`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json().then((re) => re.data))
    .catch((e) => e);

export const getTopLevelWorkTypes = async (type) =>
  fetch(
    `${apiUrl}smartcitymanager/getTopLevelWorkTypes?orgUnitId=${type}&pageNo=1&pageLimit=1000`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

export const getChildWorkTypes = async (type) =>
  fetch(
    `${apiUrl}smartcitymanager/getChildWorkTypes?parentWorkTypeId=${type}&pageNo=1&pageLimit=1000`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

//  getAllWorkTypesForOrgUnit?orgUnitId=1058
export const getAllWorkTypesForOrgUnit = async (type) =>
  fetch(
    `${apiUrl}smartcitymanager/getAllWorkTypesForOrgUnit?orgUnitId=${type}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

export const getTopLevelPetitionWorkRequestTypes = async (id) =>
  fetch(
    `${apiUrl}smartcitymanager/getTopLevelPetitionWorkRequestTypes?subscriberId=${id}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => e);

export const getOrgUnitsForWorkType = async (type) =>
  fetch(`${apiUrl}smartcitymanager/getOrgUnitsForWorkType?workTypeId=${type}`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  })
    .then((res) => res.json())
    .catch((e) => e);

export const getPetitionsNearMeWithRadius = async (
  centreLat,
  centreLong,
  pageLimit,
  radius,
  subscriberId,
  authenticatedState
) => {
  if (authenticatedState) {
    return fetch(
      `${apiUrl}smartcitymanager/getPetitionsNearMeWithRadius?` +
        `centreLat=${centreLat}` +
        `&centreLong=${centreLong}` +
        `&pageLimit=${pageLimit}` +
        `&radius=${radius}`,
      {
        method: 'GET',
        async: true,
        headers: {
          Accept: 'application/json, text/javascript; q=0.01',
          'Content-Type': 'application/problem+json',
          firebase_token: cookies.get('firebaseToken'),
        },
      }
    )
      .then((res) => {
        let temp = [];
        try {
          temp = res.json().then((re) => {
            console.log('getPetitionsNearMeWithRadius', re.data);
            return re.data;
          });
        } catch (e) {
          console.log(e);
          temp = [];
        }
        return temp;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  }
  return fetch(
    `${apiUrl}smartcitymanager/getPetitionsNearMeWithRadius?` +
      `centreLat=${centreLat}` +
      `&centreLong=${centreLong}` +
      `&pageLimit=${pageLimit}` +
      `&radius=${radius}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        subscriberId,
      },
    }
  )
    .then((res) => {
      let temp = [];
      try {
        temp = res.json().then((re) => re.data);
      } catch (e) {
        console.log(e);
        temp = [];
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};

export const getOpenPetitions = async (id) =>
  fetch(
    `${apiUrl}smartcitymanager/getOpenPetitions?pageLimit=1000&subscriberId=${id}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
      },
    }
  )
    .then((res) =>
      res.json().then((re) => {
        console.log('getOpenPetitions', re.data);
        return re.data;
      })
    )
    .catch((e) => e);

export const getAvailableCities = async () =>
  fetch(`${apiUrl}smartcitymanager/getAvailableCities`, {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return [];
    });

export const getCustomerPremises = async () =>
  fetch(
    `${apiUrl}smartcitymanager/getCustomerPremises?pageLimit=100&pageNo=0`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) =>
      res.json().then((re) => {
        if (re.data) {
          console.log('getCustomerPremises', re.data);
          return re.data;
        }
        return [];
      })
    )
    .catch(() => []);

export const selectCity = async (id, lat, lng, usetoken) => {
  if (id && !lat && !lng) {
    return fetch(`${apiUrl}smartcitymanager/selectCity?cityId=${id}`, {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return [];
      });
  }
  if (!id && lat && lng) {
    return fetch(
      `${apiUrl}smartcitymanager/selectCity?latitude=${lat}&longitude=${lng}`,
      {
        method: 'GET',
        async: true,
        headers: {
          Accept: 'application/json, text/javascript; q=0.01',
          'Content-Type': 'application/problem+json',
        },
      }
    )
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return [];
      });
  }
  if (id && lat && lng && !usetoken) {
    return fetch(
      `${apiUrl}smartcitymanager/selectCity?cityId=${id}&latitude=${lat}&longitude=${lng}`,
      {
        method: 'GET',
        async: true,
        headers: {
          Accept: 'application/json, text/javascript; q=0.01',
          'Content-Type': 'application/problem+json',
        },
      }
    )
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  if (id && lat && lng && usetoken) {
    return fetch(
      `${apiUrl}smartcitymanager/selectCity?cityId=${id}&latitude=${lat}&longitude=${lng}`,
      {
        method: 'GET',
        async: true,
        headers: {
          Accept: 'application/json, text/javascript; q=0.01',
          'Content-Type': 'application/problem+json',
          firebase_token: cookies.get('firebaseToken'),
        },
      }
    )
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return [];
      });
  }
  return 'FAILED';
};

export const getMyWorkRequests = async (pageLimit, pageNo) =>
  fetch(
    `${apiUrl}smartcitymanager/getMyWorkRequests?pageLimit=${pageLimit}&pageNo=${pageNo}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      let temp = [];
      try {
        temp = res.json().then((re) => {
          console.log('getMyWorkRequests', re.data);
          return re.data;
        });
      } catch (e) {
        console.log(e);
        temp = [];
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });

export const getMyPetitions = async (pageLimit, pageNo) =>
  fetch(
    `${apiUrl}smartcitymanager/getMyPetitions?pageLimit=${pageLimit}&pageNo=${pageNo}`,
    {
      method: 'GET',
      async: true,
      headers: {
        Accept: 'application/json, text/javascript; q=0.01',
        'Content-Type': 'application/problem+json',
        firebase_token: cookies.get('firebaseToken'),
      },
    }
  )
    .then((res) => {
      let temp = [];
      try {
        temp = res.json().then((re) => {
          console.log('getMyWorkRequests', re.data);
          return re.data;
        });
      } catch (e) {
        console.log(e);
        temp = [];
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
