import styled from 'styled-components';

import { Headline } from 'components';

export const PageHeading = styled(Headline)`
  color: #333333;
  text-align: left;
  padding: 0;
  margin: 0;
`;

export const Heading = styled.h2`
  font: normal normal 500 16px/21px Roboto;
  color: #333333;
  text-align: left;
  padding: 0;
  margin: 0;
`;

export const Title = styled.p`
  font: normal normal normal 14px/19px Roboto;
  color: #333333;
  text-align: left;
  padding: 0;
  margin: 0;
`;

export const Text = styled.p`
  font: normal normal normal 14px/19px Roboto;
  color: #333333;
  text-align: left;
  padding: 0;
  margin: 0;
`;
