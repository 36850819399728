import AccountNavigator from 'components/account-navigator';
import DropZone from 'components/dropzone';
import { Button, Image, Link } from 'components/elements';
import Form from 'components/form';
import { Checkbox, InputField } from 'components/form/input';
import SubmitButton from 'components/form/submit-button';
import { Flex, Grid } from 'components/layout';
import { validateEmail, validateMobileNumber } from 'functions/form';
import theme from 'theme';
import { css } from '@emotion/react';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { useGlobalContext } from 'contexts/global';
import { useState } from 'react';
import { SmartCityUser } from 'types/types';
import { auth } from 'interface/firebase';
import { updatePassword } from 'firebase/auth';
import { Text } from 'components/typography';

const EditProfilePage = () => {
  // Hooks
  const {
    globalState: { user },
    setUserAndCityFromUser,
  } = useGlobalContext();
  const {
    response: updateUserResponse,
    loading: updatingUser,
    callApi: updateUser,
  } = useSmartCityManager(SCManagerEndpoints.UpdateProfile);
  const { callApi: updateProfilePic } = useSmartCityManager(
    SCManagerEndpoints.AddProfilePic
  );

  // State and constants
  const userContext: SmartCityUser = updateUserResponse || user;
  const userFirebase = auth.currentUser;
  const providerData =
    userFirebase?.providerData &&
    userFirebase?.providerData.length > 0 &&
    userFirebase?.providerData[0].providerId;
  const [showUpdateProfilePic, setShowUpdateProfilePic] = useState(false);
  const [profilePic, setProfilePic] = useState<File | null>(null);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  // Handlers
  const handleChangePassword = async (p: string) => {
    if (userFirebase) {
      setUpdatingPassword(true);
      updatePassword(userFirebase, p).then(() => {
        setUpdatingPassword(false);
      });
    }
  };

  const handleSubmit = async (
    data: {
      fullName: string;
      email: string;
      mobileNumber: string;
      acceptmarketing: 'on' | 'off';
      password?: string;
    },
    ppic: File | null
  ) => {
    // Update profile pic
    if (ppic) {
      await updateProfilePic({ body: ppic });
    }

    // Update password for email sign in users
    data.password &&
      data.password.length >= 6 &&
      handleChangePassword(data.password);
    // Update user details and global state
    updateUser({
      body: {
        ...userContext,
        fullName: data.fullName,
        email: data.email,
        mobileNumber: data.mobileNumber,
        acceptmarketing: data.acceptmarketing === 'on',
      },
    });

    setUserAndCityFromUser({
      ...userContext,
      fullName: data.fullName,
      email: data.email,
      mobileNumber: data.mobileNumber,
      acceptmarketing: data.acceptmarketing === 'on',
    });
  };

  return (
    <AccountNavigator
      loading={updatingUser || updatingPassword || user.uuid === 'init'}
    >
      <Flex justifyContent="center" flexDirection="column">
        {userContext.hasProfilePic && userContext.uuid !== '0' && (
          <Image
            source={`https://${
              process.env.REACT_APP_API_BASE
            }/api/msc/images/profilepicture?uuid=${
              userContext.uuid
            }&_=${new Date().getTime()}`}
            alt="profile pic"
            height="75px"
            width="75px"
            mx="auto"
            mb="10px"
            borderRadius="50%"
            objectFit="cover"
          />
        )}

        {showUpdateProfilePic ? (
          <Flex alignContent="center">
            <DropZone
              dataTestId="profile-pic-input"
              referenceFile={profilePic}
              setReferenceFile={(f) => setProfilePic(f)}
            />
            <Button
              onClick={() => setShowUpdateProfilePic(false)}
              color={theme.colors.primary}
              fontWeight="500"
              textAlign="center"
              fontSize="18px"
              p="5px"
              ml="10px"
            >
              Cancel
            </Button>
          </Flex>
        ) : (
          <Button
            onClick={() => setShowUpdateProfilePic(true)}
            color={theme.colors.primary}
            dataTestId="edit-profile-pic"
            fontWeight="500"
            textAlign="center"
            fontSize="18px"
            p="5px"
          >
            Edit profile picture
          </Button>
        )}

        <Form onSubmit={(data) => handleSubmit(data, profilePic)}>
          <Grid
            gridRowGap="25px"
            mt="25px"
            desktopCss={css`
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 50px;
            `}
          >
            <InputField
              name="fullName"
              defaultValue={userContext.fullName}
              label="Name and surname"
              alwaysShowLabel
              required
            />
            <InputField
              name="email"
              defaultValue={userContext.email}
              label="Email"
              alwaysShowLabel
              required
              instructions={
                providerData === 'google.com' || providerData === 'facebook.com'
                  ? 'You cannot change your email address since your account is associated with a social login method'
                  : undefined
              }
              disabled={
                providerData === 'google.com' || providerData === 'facebook.com'
              }
              validate={(e) => {
                if (!validateEmail(e.target.value)) {
                  return {
                    field: 'email',
                    message: 'Please enter a valid email address',
                    valid: false,
                  };
                }
                return {
                  field: 'email',
                  message: '',
                  valid: true,
                };
              }}
            />
            <InputField
              name="mobileNumber"
              defaultValue={userContext.mobileNumber}
              label="Mobile number"
              alwaysShowLabel
              validate={(e) => {
                if (!validateMobileNumber(e.target.value)) {
                  return {
                    field: 'mobileNumber',
                    message: 'Please enter a valid mobile number',
                    valid: false,
                  };
                }
                return {
                  field: 'mobileNumber',
                  message: '',
                  valid: true,
                };
              }}
            />
          </Grid>
          <Grid
            gridRowGap="25px"
            mt="25px"
            desktopCss={css`
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 50px;
            `}
          >
            {providerData &&
              providerData !== 'google.com' &&
              providerData !== 'facebook.com' && (
                <>
                  <InputField
                    dataTestId="password-field"
                    label="New Password"
                    placeholder="New Password"
                    name="password"
                    type="password"
                    instructions="Your password must be at least 6 characters long."
                    validate={(e) => {
                      setNewPassword(e.target.value);
                      if (!(e.target.value.length >= 6))
                        return {
                          field: 'password',
                          valid: false,
                          message:
                            'Your password must be at least 6 characters long',
                        };
                      return { field: 'password', valid: true, message: '' };
                    }}
                  />
                  <InputField
                    dataTestId="confirm-password"
                    label="Confirm New Password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    validate={(e) => {
                      if (!(e.target.value.length >= 6))
                        return {
                          field: 'confirmPassword',
                          valid: false,
                          message:
                            'Your password must be at least 6 characters long',
                        };
                      if (e.target.value !== newPassword) {
                        return {
                          field: 'confirmPassword',
                          valid: false,
                          message: 'Passwords do not match',
                        };
                      }
                      return {
                        field: 'confirmPassword',
                        valid: true,
                        message: '',
                      };
                    }}
                  />
                </>
              )}
            <Checkbox
              name="acceptmarketing"
              label="I want to receive marketing communications"
              defaultChecked={userContext.acceptmarketing}
            />
          </Grid>
          <Flex
            width="100%"
            justifyContent="center"
            flexDirection="column"
            desktopCss={css`
              width: auto;
              padding: 0 25%;
            `}
          >
            <SubmitButton label="Save Changes" />
          </Flex>
        </Form>
        <Flex
          width="100%"
          justifyContent="flex-start"
          flexDirection="column"
          mt="10px"
        >
          <Text color={theme.colors.primary} fontSize="18px" fontWeight={500}>
            Danger zone
          </Text>
          <Link
            href="/delete-my-account"
            color="white"
            fontWeight="500"
            textAlign="left"
            fontSize="18px"
            p="8px"
            backgroundColor="#EA1B1B"
            borderRadius="8px"
            width="fit-content"
            opacity="0.8"
            mt="10px"
            hoverCss={css`
              opacity: 1;
              color: white;
            `}
          >
            Delete my account
          </Link>
        </Flex>
      </Flex>
    </AccountNavigator>
  );
};

export default EditProfilePage;
