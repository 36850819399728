import { Image } from 'components/elements';
import Form from 'components/form';
import { InputField } from 'components/form/input';
import { Flex } from 'components/layout';
import { MunicipalPageWrapper } from 'components/my-municipality';
import { useCallback, useEffect, useState } from 'react';
import {
  CustomerPremises,
  Meter,
  MeterReading,
  MeterType,
  UtilityAccount,
} from 'types/types';
import ImageDropZone from 'components/image-dropzone';
import SubmitButton from 'components/form/submit-button';
import useSmartCityManager, {
  SCManagerEndpoints,
} from 'hooks/use-smart-city-manager';
import { Loader } from 'components/inthecity';
import useQuery from 'hooks/use-query';
import { useNavigate, useParams } from 'react-router-dom';
import UtilityAccountItem from 'components/my-municipality/utility-account-item';
import InfoBox from 'components/info-box';
import { toUnix } from 'functions/date';

const SelfMeterReadingsPage = () => {
  // Hooks
  const navigate = useNavigate();
  const { cityId } = useParams();
  const query = useQuery();

  // API Calls
  const {
    loading: loadingPremises,
    callApi: getPremises,
    response: customerPremises,
  } = useSmartCityManager(SCManagerEndpoints.GetCustomerPremises);
  const {
    loading: loadingMeterTypes,
    callApi: getMeterTypes,
    response: meterTypes,
  } = useSmartCityManager(SCManagerEndpoints.GetMeterTypes);
  const {
    loading: gettingPastReadings,
    callApi: getPastReadings,
    response: pastReadings,
  } = useSmartCityManager(SCManagerEndpoints.GetMeterReadingsForFavLocation);
  const {
    loading: submittingReading,
    callApi: submitReading,
    response: submitReadingResponse,
  } = useSmartCityManager(SCManagerEndpoints.PostMeterReading);

  // State management and constants
  const accountNumber = query.get('accountNumber');
  const meterNumber = query.get('meterNumber');
  const premiseId = query.get('premiseId');
  const premise: CustomerPremises =
    premiseId &&
    customerPremises?.data?.find(
      (items: CustomerPremises) => items.id === parseInt(premiseId, 10)
    );
  const municipalAccount = premise?.serviceProviderAccounts?.find(
    (items: UtilityAccount) => items.accountNumber === accountNumber
  );
  const meter: Meter = municipalAccount?.meters?.find(
    (items) => items.meterNumber === meterNumber
  );
  const meterType: MeterType = meterTypes?.find(
    (items: MeterType) => items.value === meter?.meterTypeId
  );
  const latestReading: MeterReading = pastReadings && pastReadings[0];
  const [meterImage, setMeterImage] = useState<File | null>(null);

  // Side effects
  useEffect(() => {
    if (!customerPremises) getPremises();
    if (!meterTypes) getMeterTypes();
    if (meter?.meterTypeId && premiseId) {
      getPastReadings({
        queryParams: `meterTypeId=${meter.meterTypeId}&premiseId=${premiseId}`,
      });
    }
  }, [premiseId, meter]);

  useEffect(() => {
    if (submitReadingResponse) {
      navigate(
        `/my-municipality/self-meter-readings/${cityId}/reading-status?premiseId=${premiseId}&accountNumber=${accountNumber}&reference=${submitReadingResponse}`
      );
    }
  }, [submitReadingResponse]);

  // Handlers
  const handleSubmitReading = useCallback(
    (data: { currentReading: number }) => {
      submitReading({
        body: {
          reading: data.currentReading,
          readingDate: toUnix(new Date()),
          premiseId,
          meterNumber,
          meterId: meter.meterId,
        },
      });
    },
    [premiseId, meterNumber, meter]
  );

  return (
    <MunicipalPageWrapper
      backLink={`/my-municipality/self-meter-readings/${cityId}?accountNumber=${accountNumber}&premiseId=${premiseId}`}
    >
      {loadingPremises ||
      loadingMeterTypes ||
      !premise ||
      !municipalAccount ||
      !meter ||
      !meterType ||
      gettingPastReadings ||
      submittingReading ? (
        <Loader />
      ) : (
        <Flex
          maxWidth="720px"
          mx="auto"
          width="100%"
          p="24px 28px 54px 28px"
          flexDirection="column"
          borderRadius="4px"
          boxShadow="0px 3px 10px 0px rgba(0, 0, 0, 0.05)"
          gap="24px"
        >
          {premiseId && accountNumber && (
            <UtilityAccountItem
              account={{
                premiseId: parseInt(premiseId, 10),
                accountNumber,
                orgUnitId: 0,
                orgUnitDescription: 'Municipal Account',
                active: true,
                meters: [],
                externalId: '0',
                statusId: 2,
                status: 'Verified',
              }}
            />
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="2"
            viewBox="0 0 658 2"
            fill="none"
          >
            <path
              d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H658V0H1V2Z"
              fill="#F0F0F0"
            />
          </svg>
          <Form onSubmit={(data) => handleSubmitReading(data)}>
            <Flex width="100%" flexDirection="column" gap="24px">
              <InputField
                label="Your meter number"
                alwaysShowLabel
                name="meterNumber"
                disabled
                defaultValue={meter.meterNumber}
              />
              <InputField
                label="Previous reading"
                alwaysShowLabel
                name="previousReading"
                disabled
                defaultValue={
                  latestReading?.reading.toString() ??
                  'No readings submitted for this meter yet'
                }
              />
              <InfoBox>
                Please take note of where to locate your reading below:
              </InfoBox>
              <Image
                source={`https://mysmart.city/api/msc/images/custom/${meterType.attributes.examplePic}?subscriberId=george`}
                alt={meterType?.label}
                mx="10%"
              />
              <ImageDropZone
                label="Add image:"
                image={meterImage}
                setImage={(f) => setMeterImage(f)}
              />
              <InputField
                label="Current reading"
                placeholder="Enter your current reading"
                name="currentReading"
                required
                type="number"
                validate={(e) => {
                  if (!e.target.value || e.target.value === '') {
                    return {
                      valid: false,
                      message: 'Please enter a reading',
                      field: 'currentReading',
                    };
                  }
                  return {
                    valid: true,
                    message: '',
                    field: 'currentReading',
                  };
                }}
              />
              <SubmitButton label="Submit reading" disabled={!meterImage} />
            </Flex>
          </Form>
        </Flex>
      )}
    </MunicipalPageWrapper>
  );
};

export default SelfMeterReadingsPage;
