/* eslint-disable arrow-body-style */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SEO from 'components/seo';
import Footer from 'components/footer';
import { DownloadBar } from 'components/home/hero';
import { Heading, Text, Paragraph } from 'components/home/text';
import MSCButton from 'components/home/button';
import Section, { PageWrapper } from 'components/section';
import {
  QRCode,
  QRCodeImage,
  Overlay,
  QRText,
  OrangeLink,
  BeachImage,
  FirstStepImage,
  StepImage,
  Background,
  Container,
  Title,
  TitleHoliday,
  Subtitle,
  CenterRow,
  ButtonColumn,
  Button,
} from './competition.styled';

export const CompetitionHero = () => (
  <Background image="marketing/competition/background.png">
    <Container>
      <Title>
        WIN A <TitleHoliday>GARMIN</TitleHoliday> SMARTWATCH
      </Title>
      <Subtitle>
        Winning has never been this easy. Download, register and wait to see if
        you are the lucky winner.
      </Subtitle>
      <MSCButton
        label="get started now"
        to="/competition#how-to-enter"
        smoothTransition
      />
    </Container>
  </Background>
);

const CompetitionPage = () => {
  return (
    <PageWrapper>
      <SEO title="My Smart City | Competition" description="Competition" />
      <CompetitionHero />
      <DownloadBar />

      <Section color="orange">
        <Row noGutters>
          <Col lg={7} sm={12}>
            <Heading color="white" icon="marketing/competition/win.svg">
              How to WIN with My Smart City?
            </Heading>
            <Paragraph>
              <Text color="white">
                2022 is here and you have some ambitious goals set out. Work,
                fitness, community and more. It’s time to make a difference. My
                Smart City wants to help you achieve your goals and we are
                giving away a Garmin Fenix 6s Outdoor Smartwatch in support of
                your resolutions. All you need to do to kickstart that active
                lifestyle is to register with My Smart City and you will
                automatically be entered into the draw for a chance to win a
                Garmin Fenix 6s Outdoor Smartwatch.
              </Text>
            </Paragraph>
          </Col>
        </Row>
      </Section>

      <Section color="white" diagonal>
        <BeachImage src="marketing/competition/runners.png" alt="runners" />

        <Row noGutters>
          <Col lg={7} sm={12}>
            <Heading
              color="dark"
              icon="marketing/competition/enter.svg"
              id="how-to-enter"
            >
              How to enter?
            </Heading>

            <Paragraph>
              You are three easy steps away from standing a chance to win a
              Garmin Smartwatch courtesy of My Smart City.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={12}>
            <FirstStepImage src="marketing/competition/step1.png" alt="steps" />
            <Paragraph>Download the My Smart City app.</Paragraph>
          </Col>
          <Col lg={4} sm={12}>
            <StepImage src="marketing/competition/step2.png" alt="steps" />
            <Paragraph>
              Create a profile using an active email and phone number.
            </Paragraph>
          </Col>
          <Col lg={4} sm={12}>
            <StepImage src="marketing/competition/step3.png" alt="steps" />
            <Paragraph>
              Your profile will be automatically entered into the competition.
              Check our T&amp;Cs to find out when the winners will be announced.
            </Paragraph>
          </Col>
        </Row>
        <CenterRow>
          <ButtonColumn>
            <Button href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp">
              <img src="home/citizens/playStore.svg" alt="googleplay" />
            </Button>
            <Button
              href="https://apps.apple.com/app/mysmart-city/id1555236570"
              right
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="home/citizens/appStore.svg" alt="appstore" />
            </Button>
          </ButtonColumn>
          <QRCode>
            <Overlay>
              <div />
              <div />
              <div />
              <div />
              <QRCodeImage src="marketing/competition/qr.svg" />
            </Overlay>
            <QRText>Scan to Download App</QRText>
          </QRCode>
        </CenterRow>
        <CenterRow>
          <OrangeLink to="/competition-terms-and-conditions">
            Terms and Conditions Apply
          </OrangeLink>
        </CenterRow>
      </Section>

      <Footer />
    </PageWrapper>
  );
};

export default CompetitionPage;
