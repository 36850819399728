/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, createContext } from 'react';
import { Cookies } from 'react-cookie';
import { authenticate } from 'functions/app';

const AuthContext = createContext();
const cookies = new Cookies();
const initialAuth = {
  authenticated: false,
  firebaseToken: '',
  user: null,
};

const AuthProvider = (props) => {
  const [authenticated, setAuthenticated] = useState(initialAuth.authenticated);
  const [firebaseToken, setFirebaseToken] = useState(initialAuth.firebaseToken);
  const [user, setUser] = useState(initialAuth.user);

  async function checkAuth() {
    const { authenticatedState, firebaseUID, currentUser } =
      await authenticate();

    setAuthenticated(authenticatedState);
    setFirebaseToken(firebaseUID);
    setUser(currentUser);

    return {
      authenticated: authenticatedState,
      firebaseToken: firebaseUID,
      user: currentUser,
    };
  }

  function setAuth(authenticatedState, firebaseUID, currentUser) {
    setAuthenticated(authenticatedState);
    setFirebaseToken(firebaseUID);
    setUser(currentUser);
  }

  function logout() {
    setAuthenticated(initialAuth.authenticatedState);
    setFirebaseToken(initialAuth.firebaseUID);
    setUser(initialAuth.user);
    cookies.remove('currentUser');
    cookies.remove('firebaseToken');
  }

  const data = {
    authenticated,
    firebaseToken,
    user,
    checkAuth,
    setAuth,
    logout,
  };

  return <AuthContext.Provider value={data} {...props} />;
};

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, useAuthContext, AuthContext };
