import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Section, { PageWrapper } from 'components/section';
import { Heading, Paragraph, Text } from 'components/home/text';
import Hero, { DownloadBar, HeroCarousel } from 'components/home/hero';
import { Link, useParams } from 'react-router-dom';

import {
  OurStoryContainer,
  AboutIcons,
  AboutIconsRow,
  AboutSectionContainer,
  AboutOurstoryImage1,
  AboutCareersImage1,
  AboutCareersContainer,
  AboutCareersImage2,
  AboutNavbarContainer,
  AboutNavbarItem,
} from './about.styled';

export default function AboutPage() {
  const { tab } = useParams();

  return (
    <PageWrapper>
      <SEO
        title="About My Smart City | Our Story | The Team | Careers"
        description="Find out more about My Smart City, how the idea was founded, the team that has been involve in bringing it to market and the career opportunities available."
      />
      <HeroCarousel excludeExtraSlides>
        <Hero
          image="about/hero.jpg"
          title="About My Smart City"
          desktopSubtitle="The platform that was designed for citizens to give
            you the voice to improve your city."
          mobileWidth="90%"
        />
      </HeroCarousel>
      <DownloadBar />

      <AboutNavbarContainer>
        <Link to="/about/ourstory">
          <AboutNavbarItem
            selected={tab.localeCompare('ourstory') === 0}
            role="button"
            tabIndex="0"
          >
            Our Story
          </AboutNavbarItem>
        </Link>
        <Link to="/about/theteam">
          <AboutNavbarItem
            selected={tab.localeCompare('theteam') === 0}
            className={`about-navbar-item ${
              tab.localeCompare('theteam') === 0 ? 'selected' : ''
            }`}
            role="button"
            tabIndex="0"
          >
            The Team
          </AboutNavbarItem>
        </Link>
        <Link to="/about/careers">
          <AboutNavbarItem
            selected={tab.localeCompare('careers') === 0}
            role="button"
            tabIndex="0"
          >
            Careers
          </AboutNavbarItem>
        </Link>
      </AboutNavbarContainer>

      {tab.localeCompare('ourstory') === 0 ? <StoryContent /> : null}
      {tab.localeCompare('theteam') === 0 ? <TeamContent /> : null}
      {tab.localeCompare('careers') === 0 ? <CareersContent /> : null}

      <Footer />
      {/* <Chatbot /> */}
    </PageWrapper>
  );
}

const StoryContent = () => (
  <>
    <Section color="white">
      <OurStoryContainer md={7} xs={12}>
        <Heading icon="about/ourstory/book.svg" color="orange">
          Our
          <Text bold color="blue">
            {' '}
            Story
          </Text>
        </Heading>
        <Paragraph>
          <Text>The idea of </Text>
          <Text bold>My Smart City </Text>
          <Text>started in </Text>
          <Text bold>2018, </Text>
          <Text>when </Text>
          <Text bold>
            Joao Zoio (CEO), Kennedy Mogotsi (COO) and Peter Hellberg (CTO),{' '}
          </Text>
          <Text>realised that there was an opportunity to </Text>
          <Text bold>create a platform </Text>
          <Text>that would put the </Text>
          <Text bold>
            power to drive change back into the hands of the people.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>Obsessed with </Text>
          <Text bold>
            citizen empowerment, customer centricity and technology{' '}
          </Text>
          <Text>the partners began the journey to </Text>
          <Text bold>create a platform to improve the lives of citizens </Text>
          <Text> and the cities that they lived in.</Text>
        </Paragraph>

        <AboutIconsRow>
          <AboutIcons src="about/ourstory/icons.gif" alt="icon" />
        </AboutIconsRow>

        <Paragraph marginTop>
          <Text>
            In 2020, the partners began to realise this dream and develop the
            platform after
          </Text>
          <Text bold>
            {' '}
            receiving an investment from Empowerment Capital (Capitec and
            Sappi).{' '}
          </Text>
          <Text>
            With the cash injection the journey began. Resources were then hired
            and design and development began. We started to reach out to cities
            with the prospect of
          </Text>
          <Text bold> joining us on this exciting journey.</Text>
        </Paragraph>
        <Paragraph>
          <Text bold>After extensive testing, </Text>
          <Text>
            {' '}
            user experience design and development, the platform was released to
            the{' '}
          </Text>
          <Text bold>App store on the 24th of February 2021. </Text>
          <Text>
            The journey has just begun and we are busy looking to become the{' '}
          </Text>
          <Text bold>platform for the people, </Text>
          <Text>as well as a platform that </Text>
          <Text bold>
            empowers citizen through technology, simplicity and accountability.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>The platform is </Text>
          <Text bold> yours - take it and make it your own. </Text>
          <Text>
            Make it the change agent to improve your life and your city.
          </Text>
        </Paragraph>
      </OurStoryContainer>
    </Section>
    <Section color="light" diagonal>
      <AboutSectionContainer />
      <AboutOurstoryImage1 src="about/ourstory/phone.png" alt="icon" />
    </Section>
  </>
);

const TeamContent = () => (
  <>
    <Section color="white">
      <Col md={6} xs={12}>
        <Heading icon="about/theteam/users.svg" color="orange">
          Meet the
          <Text bold color="blue">
            {' '}
            Team
          </Text>
        </Heading>
        <Paragraph>
          <Text bold>My Smart City </Text>
          <Text>is a product of </Text>
          <Text bold>Acumen Software, </Text>
          <Text>a software technology company in South Africa </Text>
          <Text bold>backed by Empowerment Capital (Capitec and Sappi).</Text>
        </Paragraph>
      </Col>

      <img
        src="about/theteam/collage.png"
        alt="theteam"
        style={{ width: '100%' }}
      />
    </Section>
    {/* <Section
      color="light"
      diagonal
    >
      <div className="about-section-container" />
    </Section> */}
  </>
);

// const CareerLink = styled(Link)`
//   font-family: GothamMedium;
//   color: rgba(0,0,0,.87);
//   &:hover {
//     color: rgba(0,0,0,.87);
//     border-bottom: 2px solid rgba(0,0,0,.87);
//   }
// `;

// const ReadMoreLink = styled(Link)`
//   font-family: GothamMedium;
//   color: #e17531;
//   &:hover {
//     color: #e17531;
//     border-bottom: 2px solid #e17531;
//   }
// `;

const CareersContent = () => (
  <>
    <Section color="white">
      <Row noGutters>
        <Col md={6} xs={12}>
          <Heading icon="about/careers/briefcase.svg" color="orange">
            Careers
          </Heading>
          <Paragraph>
            <Text>
              We are a software technology company that is obsessed with{' '}
            </Text>
            <Text bold>
              bringing the best technological solutions to citizens to empower
              them.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>
              We are focused on providing the citizen with a platform that
            </Text>
            <Text bold> improves cities </Text>
            <Text>
              for the betterment of the citizens that live within them. This is
              done through a number of value-added features that are
              encapsulated by My Smart City, from
            </Text>
            <Text bold>
              {' '}
              providing citizens with the tools, resources and technologies{' '}
            </Text>
            <Text>to providing </Text>
            <Text bold>new opportunities.</Text>
          </Paragraph>
        </Col>
        <Col sm={12}>
          <AboutCareersContainer />
        </Col>
      </Row>
      <AboutCareersImage1 src="about/careers/phone1.png" alt="theteam" />
    </Section>
    <Section color="light" diagonal>
      <Row noGutters>
        <Col>
          <AboutCareersImage2 src="about/careers/phone2.png" alt="theteam" />
        </Col>
        <Col lg={6} sm={12}>
          <Heading
            icon="about/careers/lightbulb.svg"
            color="dark"
            align="right"
          >
            <Text bold>Available </Text>
            <Text bold color="blue">
              opportunities
            </Text>
          </Heading>

          <Paragraph alignRight>
            <Text>There are currently</Text>
            <Text bold> no positions available </Text>
            <Text>at this point in time.</Text>
          </Paragraph>

          {/* <Paragraph
            alignRight
          >
            <CareerLink to="/careers/social-media-specialist">
            Social Media Specialist | Stellenbosch</CareerLink>
          </Paragraph>
          <Paragraph
            alignRight
          >
            Acumen Software is looking for an experienced, enthusiastic and driven self-starter
            to join our My Smart City social media team. Help us define the brand that is
            giving South Africans their voices back.
          </Paragraph>
          <Paragraph
            alignRight
          >
            <ReadMoreLink to="/careers/social-media-specialist">Read More</ReadMoreLink>
          </Paragraph> */}
        </Col>
      </Row>
    </Section>
  </>
);
