import { css } from '@emotion/react';
import { Button, Image } from 'components/elements';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { getPrivateServiceImage } from 'functions/private-services';
import theme from 'theme';
import {
  PrivateService,
  ServiceSelection,
  SuggestedService,
} from 'types/types';

export const PrivateServiceButton = ({
  service,
  onClick,
}: {
  service: PrivateService;
  onClick: () => void;
}) => (
  <Button onClick={onClick}>
    <Flex
      border={`1px solid ${theme.colors.borderGrey}`}
      p="15px"
      py="25px"
      borderRadius={theme.borderRadius.medium}
      justifyContent="center"
      width="100%"
      flexDirection="column"
      height="100%"
    >
      <Image
        source={getPrivateServiceImage(service.attributes.customIcon)}
        alt={service.label}
        maxHeight="60px"
        mb="10px"
      />
      <Text textAlign="center" fontWeight="bold">
        {service.label}
      </Text>
    </Flex>
  </Button>
);

export const SuggestedServiceButton = ({
  service,
  isSelected,
  onClick,
}: {
  service: SuggestedService;
  isSelected: boolean;
  onClick: (service: ServiceSelection, isSelected?: boolean) => void;
}) => (
  <Button
    height="100%"
    border={`1px solid ${
      isSelected ? theme.colors.primary : theme.colors.borderGrey
    }`}
    onClick={() => {
      onClick(service, isSelected);
    }}
    borderRadius={theme.borderRadius.small}
    backgroundColor={isSelected ? 'rgba(223, 117, 49, 0.26)' : 'white'}
    p="15px"
    overrideCss={css`
      @media ${theme.mediaQueries.mobileOnly} {
        min-width: 130px;
        margin-right: 10px;
      }
    `}
  >
    <Box
      border={`1px solid ${
        isSelected ? theme.colors.primary : theme.colors.borderGrey
      }`}
      borderRadius="50%"
      width="20px"
      height="20px"
      p={0}
    >
      {isSelected && (
        <Image
          m={0}
          p={0}
          source="icons/successicon.svg"
          alt="tick"
          width="20px"
          height="20px"
          ml="-1px"
          mt="-1px"
        />
      )}
    </Box>
    <Flex
      justifyContent="center"
      flexDirection="column"
      height="110px"
      overrideCss={css`
        @media ${theme.mediaQueries.mobileOnly} {
          height: 90px;
        }
      `}
    >
      <Image
        source={getPrivateServiceImage(service.attributes.customIcon)}
        alt={service.label}
        maxHeight="60px"
        overrideCss={css`
          @media ${theme.mediaQueries.mobileOnly} {
            max-height: 30px;
            margin-bottom: 7px;
          }
        `}
        mb="10px"
      />
      <Text textAlign="center" fontWeight="bold">
        {service.label}
      </Text>
    </Flex>
  </Button>
);
