import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import theme from '../../../../theme';

export const CaroucelImg = styled.img`
  max-height: 30px;
  object-fit: contain;
  @media only screen and (max-width: 1200px) {
    max-height: 30px;
  }
  @media only screen and (max-width: 799px) {
    max-height: 20px;
  }
`;

export const CaroucelContainer = styled(Row)`
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h2`
  font: normal normal normal 24px/58px Roboto;
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
  justify-content: center;
  color: #000000;
  @media ${theme.mediaQueries.mobileOnly} {
    font-size: 14px;
  }
`;
