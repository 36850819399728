import React, { useRef } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from 'functions/home';
import { Text } from 'components/home/text';

const Popup = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: fixed;
  overflow: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999999;
  background-color: rgba(255, 255, 255, 0.25);
`;

const Content = styled.div`
  position: relative;
  margin: auto auto;
  background-color: #333333;
  background-image: url('/home/popupBackground.png'), url('/home/popupBackground.png');
  background-position: -35% 400%, 135% -300%;
  background-repeat: no-repeat, no-repeat;
  background-size: 55%;
  padding: 140px;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  height: 800px;
  @media only screen and (max-width: 1000px) {
    background-image: none;
    justify-content: space-around;
    height: auto;
    margin: 0.5rem;
    padding: 10%;
  }
`;

const Heading = styled.div`
  font-family: GothamMedium;
  font-weight: bolder;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  margin: 0;
  @media only screen and (min-width: 550px) {
    font-size: 3rem;
    line-height: 4rem;
  }
  @media only screen and (min-width: 700px) {
    font-size: 3.5rem;
    line-height: 4.5rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 4rem;
    line-height: 5rem;
  }
`;

const Logo = styled.img`
  display: block;
  width: 8rem;
  margin: 0;
  @media only screen and (min-width: 1000px) {
    width: 14rem;
    margin-top: -3rem;
  }
`;

const Close = styled.span`
  position: absolute;
  left: 2rem;
  top: 2rem;
  font-size: 4rem;
  cursor: pointer;
  color: white;
  :hover {color: lightgray;}
  @media only screen and (max-width: 1000px) {
    font-size: 3rem;
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Button = styled.a`
  height: 7rem;
  @media only screen and (max-width: 1000px) {
    height: 4rem;
    align-self: center;
  }
  > img {
    height: 100%;
  }
  &:first-of-type {
    margin-bottom: 2rem;
  }
`;

export default function DownloadPopup({ show = false, onHide }) {
  const ref = useRef();
  useOnClickOutside(ref, () => onHide());

  return (
    <Popup
      show={show}
    >
      <Content
        ref={ref}
      >
        <Close onClick={() => onHide()}>&times;</Close>
        <Logo src="logo.svg" alt="logo" />
        <Heading>
          <Text bold color="orange">Manage your city at the </Text>
          <Text bold color="white">push of a button.</Text>
        </Heading>
        <ButtonRow>
          <Button
            id="popup-applink-appstore"
            href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src="home/citizens/playStore.svg"
              alt="googleplay"
            />
          </Button>
          <Button
            id="popup-applink-appstore"
            href="https://apps.apple.com/app/mysmart-city/id1555236570"
            right
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src="home/citizens/appStore.svg"
              alt="appstore"
            />
          </Button>
        </ButtonRow>
      </Content>
    </Popup>
  );
}
