import React from 'react';
// import { Routes, Route } from 'react-router-dom';

// import CreatePostMenu from 'components/social/createpostmenu/createpostmenu';
import {
  StyledActionBar,
  Button,
} from './actionbar.styled';

/**
 * Action Bar that may contains action buttons and any custom elements
 */
const ActionBar = (props) => {
  const {
    buttons,
    children,
  } = props;

  return (
    <StyledActionBar>
      {buttons && buttons.map((action) => (
        <Button
          onClick={action.onClick}
        >
          {action.label}
        </Button>
      ))}
      {children}
    </StyledActionBar>
  );
};

export default ActionBar;
