import React from 'react';

import CreateAnnouncementPost from './createAnnouncementPost';
import CreateReportPost from './createReportPost';
import CreatePetitionPost from './createPetitionPost';

const CreatePost = (props) => {
  const {
    show, handleClose, type, handleRefreshNewsFeed,
  } = props;

  switch (type) {
    case 'announcement':
      return (
        <CreateAnnouncementPost
          show={show}
          handleClose={handleClose}
          handleRefreshNewsFeed={handleRefreshNewsFeed}
        />
      );
    case 'report':
      return (
        <CreateReportPost
          show={show}
          handleClose={handleClose}
          handleRefreshNewsFeed={handleRefreshNewsFeed}
        />
      );
    case 'petition':
      return (
        <CreatePetitionPost
          show={show}
          handleClose={handleClose}
          handleRefreshNewsFeed={handleRefreshNewsFeed}
        />
      );
    default:
      return null;
  }
};

export default CreatePost;
