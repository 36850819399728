import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Text } from 'components/home/text';

export function DownloadBar({ dark = false }) {
  const Row = styled.div`
    display: none;
    justify-content: space-between;
    align-items: center;
    background: ${dark ? '#333333' : '#0098b5'};
    padding: 1rem 1rem 1rem 4rem;
    font-size: 1.5rem;
    @media only screen and (min-width: 1100px) {
      display: flex; 
      height: 4rem;
    }
    @media only screen and (min-width: 1400px) {
      height: 5rem;
    }
  `;
  const ButtonContainer = styled.div`
    height: 3.25rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    a {
      height: 100%;
      margin-right: 1rem;
      :last-child { margin: 0; }
      img { height: 100%; }
    }
    @media only screen and (min-width: 1400px) {
      height: 3.75rem;
    }
  `;
  return (
    <Row>
      <Text bold color={dark ? 'orange' : 'dark'}>Download</Text>
      <Text bold color="white"> the My Smart City App from your App store </Text>
      <Text bold color={dark ? 'orange' : 'dark'}>today.</Text>
      <ButtonContainer>
        <a
          id="banner-applink-playstore"
          href="https://play.google.com/store/apps/details?id=net.acumensoft.mysmartcityapp"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src="home/citizens/home/playStore.svg"
            alt="My Smart City Banner"
          />
        </a>
        <a
          id="banner-applink-appstore"
          href="https://apps.apple.com/app/mysmart-city/id1555236570"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src="home/citizens/home/appStore.svg"
            alt="My Smart City Banner"
          />
        </a>
      </ButtonContainer>
    </Row>
  );
}

export default function Hero(
  {
    image,
    title,
    desktopSubtitle,
    mobileSubtitle,
    mobileWidth = '100%',
    mediumWidth = '70%',
    desktopWidth = '50%',
    slide = false,
    transparent = false,
  },
) {
  const Background = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(/${image});
    background-size: cover;
    background-color: #333;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 30vh;
    height: 100%;
    padding: 1rem 0;
    @media only screen and (min-width: 650px) {
      height: 100%;
    }
    @media only screen and (min-width: 900px) {
      min-height: calc(100vh - 60px);
      padding: 1.5rem;
    }
    @media only screen and (min-width: 1000px) {
      min-height: calc(100vh - 60px - 56px);
      padding: 1.5rem;
    }
    @media only screen and (min-width: 1400px) {
      padding: 2.5rem;
    }
  `;
  const Container = styled.div`
    text-align: left;
    margin-top: auto;
    color: white;
    width: ${mobileWidth};
    @media only screen and (min-width: 900px) {
      width: ${mediumWidth};
    }
    @media only screen and (min-width: 1400px) {
      width: ${desktopWidth};
    }
  `;
  const TitleContainer = styled.div`
    width: 100%;
    background: ${transparent ? 'transparent' : '#df7531'};
    padding-right: 5rem;
    padding-left: 2rem;
    @media only screen and (max-width: 650px) {
      padding-right: 2rem;
      padding-left: 1rem;
    }
  `;
  const TitleText = styled.h1`
    position: relative;
    font-family: GothamMedium;
    transition: all 0.5s;

    margin: 0;
    font-weight: normal;
    padding: 0;

    top: -0.8rem; // half the font-size
    font-size: 1.75rem;
    line-height: 1.75rem;
    @media only screen and (min-width: 600px) {
      top: -2rem; // half the font-size
      font-size: 4rem;
      line-height: 4rem;
    }
    @media only screen and (min-width: 900px) {
      top: -3rem;// half the font-size
      font-size: 6rem;
      line-height: 6rem;
    }
    @media only screen and (min-width: 1400px) {
      top: -4rem;// half the font-size
      font-size: 8rem;
      line-height: 8rem;
    }
  `;
  const SlideTitleText = styled.h2`
    position: relative;
    font-family: GothamMedium;
    transition: all 0.5s;

    margin: 0;
    font-weight: normal;
    padding: 0;

    top: -0.8rem; // half the font-size
    font-size: 1.75rem;
    line-height: 1.75rem;
    @media only screen and (min-width: 600px) {
      top: -2rem; // half the font-size
      font-size: 4rem;
      line-height: 4rem;
    }
    @media only screen and (min-width: 900px) {
      top: -3rem;// half the font-size
      font-size: 6rem;
      line-height: 6rem;
    }
    @media only screen and (min-width: 1400px) {
      top: -4rem;// half the font-size
      font-size: 8rem;
      line-height: 8rem;
    }
  `;
  const Subtitle = styled.div`
    width: 100%;
    background-color: ${transparent ? 'transparent' : 'rgba(255, 117, 49, 0.5)'};
    font-family: Gotham !important;
  `;
  const DesktopSubtitle = styled(Subtitle)`
    display: none;
    font-family: GothamMedium;
    font-weight: bolder;
    padding: 2rem;
    @media only screen and (min-width: 650px) {
      display: block;
      font-size: 1.25rem;
      line-height: 2rem;
    }
    @media only screen and (min-width: 1400px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  `;
  const MobileSubtitle = styled(Subtitle)`
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1rem;
    @media only screen and (min-width: 650px) {
      display: none;
    }
  `;
  return (
    <Background
      image={image}
    >
      <Container>
        <TitleContainer>
          {slide
            ? (
              <SlideTitleText>
                {title}
              </SlideTitleText>
            )
            : (
              <TitleText>
                {title}
              </TitleText>
            )}
        </TitleContainer>
        <DesktopSubtitle>{desktopSubtitle}</DesktopSubtitle>
        <MobileSubtitle>{mobileSubtitle || desktopSubtitle}</MobileSubtitle>
      </Container>
    </Background>
  );
}

export function BlogPostHero(
  {
    image, imageHeight, title, author, date,
  },
) {
  const Background = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(/${image});
    background-size: cover;
    background-color: #333;
    background-position: center;
    background-repeat: no-repeat;
    min-height: ${imageHeight || 'calc(100vh - 10rem)'};
    justify-content: end;
    @media only screen and (max-width: 650px) {
      min-height: 30vh;
    }
  `;
  const Container = styled.div`
    height: auto;
    width: 100%;
    background-color: hsla(0, 0%, 100%, 0.7);
    z-index: 10;
    font-family: GothamMedium;
    padding: 2rem 10vw;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    @media only screen and (max-width: 650px) {
      padding: 1rem 1rem;
    }
  `;
  const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &:first-of-type {
      margin-bottom: 2rem;
      @media only screen and (max-width: 650px) {
        margin-bottom: 1rem;
      }
    }
  `;
  const Title = styled.h1`
    background: white;
    color: #333333;
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 3rem;
    padding: 2.5rem;
    @media only screen and (max-width: 650px) {
      font-size: 1.5rem;
      line-height: 1.5rem;
      padding: 1rem;
    }
  `;
  const Author = styled.div`
    background: white;
    color: #e17531;
    font-style: italic;
    font-size: 1.5rem;
    padding: 1.5rem;
    @media only screen and (max-width: 650px) {
      font-size: 1.25rem;
      padding: 0.5rem;
    }
  `;
  const Date = styled.div`
    background: white;
    color: #e17531;
    font-size: 1.5rem;
    padding: 1.5rem;
    @media only screen and (max-width: 650px) {
      font-size: 1.25rem;
      padding: 0.5rem;
      margin-left: 1rem;
      text-align: center;
    }
  `;
  return (
    <Background
      image={image}
    >
      <Container>
        <Row>
          <Title>{title}</Title>
        </Row>
        <Row>
          <Author>{author}</Author>
          <Date>{date}</Date>
        </Row>
      </Container>
    </Background>
  );
}

const StyledCarousel = styled(Carousel)`
  .carousel .control-dots {
    margin: 0px 0;
    .dot {
      width: 6px;
      height: 6px;
    }
  }
`;

export function HeroCarousel({ children, excludeExtraSlides = false, onlyFirstSlide = false }) {
  if (onlyFirstSlide) return (React.Children.toArray(children)[0]);
  if (excludeExtraSlides) return (children);
  return (
    <StyledCarousel
      showStatus={false}
      showIndicators
      showThumbs={false}
      autoPlay
      infiniteLoop
      stopOnHover={false}
      interval={5000}
    >
      {children}
    </StyledCarousel>
  );
}
