/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MSCGlobalState, SmartCity, SmartCityUser } from 'types/types';

export const initialState: MSCGlobalState = {
  user: {
    followable: false,
    followed: false,
    reportsCount: 0,
    petitionsCount: 0,
    followDate: 0,
    accepttc: true,
    acceptmarketing: false,
    cards: [],
    uuid: 'init',
    fullName: '',
    mobileNumber: '',
    password: null,
    email: '',
    currentCitySubscriberId: 0,
    firebaseUid: '',
    theme: 'light',
    issuesNearMeNotficationDistanceThreshold: null,
    hasProfilePic: false,
    schemaOptions: null,
    userOptions: null,
    verified: false,
    smartCityUserRoles: [],
    twitterHandle: null,
    councillorForWard: null,
  },
  city: {
    label: 'init',
    value: 0,
    attributes: {
      latitude: '0.0',
      longitude: '0.0',
      subscriberId: 'init',
      distanceKm: '0.0',
    },
  },
  availableCities: [],
  location: {
    latitude: '0.0',
    longitude: '0.0',
  },
  showLoginModal: false,
  authenticated: false,
  FirebaseToken: '',
};

export const globalSlice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<SmartCityUser>) => {
      state.user = action.payload;
    },
    updateCity: (state, action: PayloadAction<SmartCity>) => {
      state.city = action.payload;
    },
    clearUser: (state) => {
      state.user = initialState.user;
    },
  },
});

export const accountSelector = (state: MSCGlobalState) => state;

export const { updateCity, updateUser, clearUser } = globalSlice.actions;
export default globalSlice.reducer;
