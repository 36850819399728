import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0px 20px;
  width: 100%;

  @media only screen and (min-width: 800px) {
    margin: 0px auto;
    padding: 0;
    width: 700px;
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
`;

export const Title = styled.p`
  font: normal normal 500 16px/19px Roboto;
  color: #333333;
  padding: 0;
  margin: 0;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 18px;

  @media only screen and (min-width: 800px) {
    margin-bottom: 28px;
  }
`;

export const TextButton = styled.p`
  cursor: pointer;
  font: normal normal normal 10px/12px Roboto;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  margin-left: auto;

  @media only screen and (min-width: 800px) {
    font: normal normal normal 14px/19px Roboto;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 15px;

  @media only screen and (min-width: 800px) {
    margin-bottom: 30px;
  }
`;

export const ProductItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #DEE2E689;
  border-radius: 5px;
  padding: 0 12px;
  height: 68px;
  width: 100%;

  img {
    object-fit: cover;
    border-radius: 3px;
    max-height: 45px;
    min-height: 0px;
    width: 52px;
  }

  @media only screen and (min-width: 800px) {
    height: 82px;
    border: 2px solid #DEE2E689;
    padding: 0 16px;
  }
`;

export const ProductButton = styled.div`
  position: absolute;
  right: 12px;
  cursor: pointer;
  background: rgba(223, 109, 33, 0.05);
  color: #DF6E21;
  padding: 0 12px;
  border-radius: 12px;
  height: 24px;
  font: normal normal normal 10px/24px Roboto;

  &:hover {
    background: rgba(223, 109, 33, 0.09);
  }

  @media only screen and (min-width: 800px) {
    padding: 0 22px;
    border-radius: 21px;
    height: 43px;
    font: normal normal normal 14px/43px Roboto;
  }
`;
