export const MSCProMunicipalities: number[] = [663];
export const MSCProMunicipalitiesTitles: string[] = ['George Municipality'];

export interface MunicipalServiceListing {
  label: string;
  icon: string;
  link: string;
  category: 'Self Service' | 'Documents and Links' | 'Map Related';
  query?: string;
  requiresAuth?: boolean;
}

export interface GroupedMunicipalServices {
  [category: string]: MunicipalServiceListing[];
}

export const groupMunicipalServicesByCategory = (
  services: MunicipalServiceListing[]
): GroupedMunicipalServices => {
  const groupedServices: GroupedMunicipalServices = {};

  services.forEach((service) => {
    const { category } = service;
    if (!groupedServices[category]) {
      groupedServices[category] = [];
    }
    groupedServices[category].push(service);
  });

  return groupedServices;
};

export const MunicipalServices: MunicipalServiceListing[] = [
  {
    category: 'Self Service',
    label: 'Ward councillors',
    icon: 'ward-councillors',
    link: '/ward-councillors',
  },
  {
    category: 'Self Service',
    label: 'Business Directory',
    icon: 'business-directory',
    link: '/business-directory',
  },
  {
    label: 'Self Meter Readings',
    icon: 'self-meter-readings',
    link: '/municipal-accounts',
    category: 'Self Service',
    query: 'self-meter-readings',
    requiresAuth: true,
  },
  {
    label: 'Municipal Account',
    icon: 'accounts',
    link: '/municipal-accounts',
    category: 'Self Service',
    requiresAuth: true,
  },
  {
    category: 'Self Service',
    label: 'Surveys',
    icon: 'surveys',
    link: '/surveys',
    requiresAuth: true,
  },
  {
    category: 'Self Service',
    label: 'Chat',
    icon: 'chat',
    link: '/chat',
    requiresAuth: true,
  },
  {
    category: 'Documents and Links',
    label: 'IDP Contribution',
    icon: 'idp-contribution',
    link: '/idp-contribution',
    requiresAuth: true,
  },
  {
    category: 'Documents and Links',
    label: 'Tenders',
    icon: 'tenders',
    link: '/supply-chain',
    query: 'current-tenders',
  },
  {
    category: 'Documents and Links',
    label: 'RFQs',
    icon: 'rfqs',
    link: '/supply-chain',
    query: 'current-formal-quotations',
  },
  {
    category: 'Documents and Links',
    label: 'Application Forms',
    icon: 'applications',
    link: '/applications',
  },
  {
    category: 'Documents and Links',
    label: 'Vacancies',
    icon: 'vacancies',
    link: '/vacancies',
  },
  {
    category: 'Map Related',
    label: 'Go George',
    icon: 'bus-icon',
    link: '/go-george',
  },
  {
    category: 'Map Related',
    label: 'Parking',
    icon: 'parking',
    link: '/parking',
  },
];
