export const peachSource = (
  checkoutId: number | string,
  redirectUrl: string,
  debug?: boolean
) => `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="height=300px, initial-scale=1, maximum-scale=1, user-scalable=no"
    />
    <script
      src="https://code.jquery.com/jquery.js"
      type="text/javascript"
    ></script>
    <script>
      var wpwlOptions = {
        style: "plain",
        brandDetection: true,
        brandDetectionType: "binlist",
        brandDetectionPriority: ["VISA", "MASTER"],
        registrations: { requireCvv: true, hideInitialPaymentForms: true },
        showCVVHint: true,
        onReady: function () {
          $(".wpwl-group-cardNumber").after($(".wpwl-group-brand").detach());
          $(".wpwl-group-cvv").after($(".wpwl-group-cardHolder").detach());
          var createRegistrationHtml =
            '<div class="customInput"><input type="checkbox" name="createRegistration" value="true" style="font-family: sans-serif; margin-top: 20px; accent-color: #df6e21"> Save Card Details?</div>';
          $("form.wpwl-form-card")
            .find(".wpwl-button")
            .before(createRegistrationHtml);
        },
      };
    </script>
    <script src="https://${
      debug ? 'eu-test.' : ''
    }oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}"></script>
    <style type="text/css">
      .cnpBillingCheckoutWrapper {
        position: relative;
      }
      .cnpBillingCheckoutHeader {
        width: 100%;
        border-bottom: 1px solid #c0c0c0;
        margin-bottom: 10px;
      }
      .cnpBillingCheckoutLeft {
        width: 240px;
        margin-left: 5px;
        margin-bottom: 10px;
        border: 1px solid #c0c0c0;
        display: inline-block;
        vertical-align: top;
        padding: 10px;
      }
      .cnpBillingCheckoutRight {
        width: 50%;
        margin-left: 5px;
        border: 1px solid #c0c0c0;
        display: inline-block;
        vertical-align: top;
        padding: 10px;
      }
      .cnpBillingCheckoutOrange {
        font-size: 110%;
        color: rgb(255, 60, 22);
        font-weight: bold;
      }
      div.wpwl-wrapper,
      div.wpwl-label,
      div.wpwl-sup-wrapper {
        width: 100%;
      }
      div.wpwl-group-expiry {
        width: 30%;
        float: left;
        font-family: sans-serif;
        font-size: 12px;
      }
      div.wpwl-group-cvv {
        width: 68%;
        float: right;
        margin-left: 2%;
        font-family: sans-serif;
        font-size: 12px;
      }
      div.wpwl-group-cardHolder,
      div.wpwl-sup-wrapper-street1,
      div.wpwl-group-expiry {
        clear: both;
      }
      div.wpwl-group-brand {
        display: none;
      }
      .wpwl-control-cardNumber {
        border-radius: 3px;
        height: 40px;
        background-color: #dee2e6;
        border-color: #dee2e6;
        opacity: 70%;
      }
      .wpwl-control-expiry {
        border-radius: 3px;
        height: 40px;
        background-color: #dee2e6;
        border-color: #dee2e6;
        opacity: 50%;
      }
      .wpwl-control-cvv {
        border-radius: 3px;
        height: 40px;
        background-color: #dee2e6;
        border-color: #dee2e6;
        opacity: 70%;
      }
      .wpwl-control-cardHolder {
        border-radius: 3px;
        height: 40px;
        background-color: #dee2e6;
        border-color: #dee2e6;
        opacity: 50%;
      }
      .wpwl-label-brand {
        display: none;
      }
      .wpwl-label-cardNumber {
        padding-bottom: 12px;
      }
      .wpwl-label-expiry {
        padding-bottom: 12px;
        font-size: 12px;
      }
      .wpwl-label-cvv {
        padding-bottom: 12px;
        font-size: 12px;
      }
      .wpwl-label-cardHolder {
        padding-bottom: 12px;
        margin-top: 10px;
      }
      .wpwl-button-pay {
        background-color: #df6e21;
        border-color: #df6e21;
        width: 100%;
        margin-top: 20px;
        font-size: 14px;
        height: 40px;
        border-radius: 3px;
      }
      div.wpwl-group-submit {
        float: left;
        font-size: 12px;
        font-family: sans-serif;
        margin-bottom: 10px;
      }
      div.wpwl-group-cardNumber {
        width: 100%;
        float: left;
        font-size: 12px;
        font-family: sans-serif;
      }
      div.wpwl-group-cardHolder {
        font-family: sans-serif;
        font-size: 12px;
      }
    </style>
  </head>
  <body>
    <form
    action="${redirectUrl}"
      class="paymentWidgets"
      data-brands="VISA MASTER "
      method="dialog"
    ></form>
  </body>
</html>
`;

export const peachSuccessCode = '000.000.000';
