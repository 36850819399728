import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, Col } from 'react-bootstrap';
import SEO from 'components/seo';
import Footer from 'components/footer';
import Section from 'components/section';

import {
  TermsLinks,
  Tcheader,
  TermsPopupBox,
  TermsPopupContent,
  TermsPopupButton,
  TermsSideMenu,
  TermsSideMenuContent,
  TermsContainer,
  TermsTitle,
  TermsTitleAlt,
} from './reschedulePolicy.styled';

const Links = () => (
  <TermsLinks>
    <Tcheader className="text-bold">My Smart City</Tcheader>

    <ol>
      <li>
        <Link to="/reschedule-policy#introduction" smooth>
          Introduction
        </Link>
      </li>
      <li>
        <Link to="/reschedule-policy#reschedule-by-client" smooth>
          Reschedule/Edit by Client
        </Link>
      </li>
      <li>
        <Link to="/reschedule-policy#placing-a-booking-on-hold" smooth>
          Placing a Booking on Hold
        </Link>
      </li>
    </ol>
  </TermsLinks>
);

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && (
        <TermsPopupBox>
          <TermsPopupContent>
            <Links />
          </TermsPopupContent>
        </TermsPopupBox>
      )}
      <TermsPopupButton
        className={`${isOpen ? 'open' : ''}`}
        role="button"
        tabIndex={0}
        onClick={togglePopup}
      >
        <img src="terms/arrow.svg" alt="arrow" title={isOpen} />
      </TermsPopupButton>
    </>
  );
};

const SideMenu = () => (
  <TermsSideMenu>
    <TermsSideMenuContent>
      <Links />
    </TermsSideMenuContent>
  </TermsSideMenu>
);

export default function ReschedulePolicy() {
  return (
    <>
      <SEO
        title="My Smart City | Rescheduling Policy"
        description="Rescheduling policy"
      />
      <Section color="white" thinPadding>
        <Row noGutters>
          <Col lg={3} sm={12}>
            <Popup />
            <SideMenu />
          </Col>
          <Col lg={9} sm={12}>
            <TermsContainer>
              <TermsTitle>My Smart City</TermsTitle>
              <TermsTitleAlt className="bold">
                Rescheduling Policy
              </TermsTitleAlt>
              <ol>
                <li id="introduction">
                  Introduction
                  <ol>
                    <li>
                      This Rescheduling Policy (“the Policy”) seeks to regulate
                      the situations that arise when:
                    </li>
                    <ol>
                      <li>
                        a client of My Smart City (“Client”) reschedules or
                        otherwise edits a booking after payment has already been
                        made;
                      </li>
                      <li>
                        a client of My Smart City places a booking on hold.
                      </li>
                    </ol>
                    <li>
                      This policy shall apply to all My Smart City Contractors
                      and Clients.
                    </li>
                  </ol>
                </li>
                <li id="reschedule-by-client">
                  Reschedule/Edit by Client
                  <ol>
                    <li>
                      The Client may, at any time prior to the scheduled time
                      for a booking, edit any individual or recurring booking
                      via the My Smart City platform (“the Platform”).
                    </li>
                    <li>
                      Should the Client reschedule or edit the booking within 12
                      (twelve) hours prior to the scheduled start of their
                      booked appointment, My Smart City shall be entitled to
                      charge an amount equal to 50% (fifty percent) of the total
                      cost of the booking in order to account for the
                      Contractor&#39;s loss of potential earnings, transport
                      costs, and/or other associated fees where applicable, as
                      stated in the Terms and Conditions and booking
                      confirmation email received by the Client upon making the
                      booking. The remaining 50% (fifty percent) of the total
                      cost of the booking shall be applied to the cost of the
                      new booking appointment.
                    </li>
                    <ol>
                      <li>
                        This credit can be kept for a period of up to 3 (three)
                        months, and will thereafter be forfeited, if the booking
                        is not completed in that time.
                      </li>
                    </ol>
                    <li>
                      Should the Client reschedule or edit the booking 12
                      (twelve) or more hours prior to the scheduled start of
                      their booked appointment, MSC shall be entitled to charge
                      the Client an amount equal to the difference between the
                      initial booking, and the rescheduled booking (if the cost
                      of the rescheduled booking is higher). Should the cost of
                      the rescheduled booking be lower than the cost of the
                      initial booking, then in such event the Client shall be
                      entitled to elect from the following alternative options:
                    </li>
                    <ol>
                      <li>
                        My Smart City shall offer a value-added service to the
                        same value (for example, an extra hour of Services, or
                        an additional task to be performed); or
                      </li>
                      <li>
                        the Client shall be entitled to request a refund,
                        subject to payment of an associated refund fee of R5
                        (five Rand) exclusive of VAT.
                      </li>
                    </ol>
                    <li>
                      Should the Client seek to edit or reschedule their
                      booking, the Client may access their account, and navigate
                      to “My Bookings”. Once at the relevant booking, selecting
                      the pencil icon will allow the booking date and/or time to
                      be edited.
                    </li>
                    <li>
                      Once the booking has been edited, a confirmation popup
                      will allow the Client to confirm their new date and/or
                      time, with specific reference to any additional payments
                      that may need to be made.
                    </li>
                    <li>
                      The Client shall receive an updated booking notification,
                      and the new booking details can be accessed under the
                      account “My Bookings” page.
                    </li>
                  </ol>
                </li>
                <li id="placing-a-booking-on-hold">
                  Placing a Booking on Hold
                  <ol>
                    <li>
                      Alternatively, the Client may mark the booking as
                      “on-hold”, which allows them to temporarily pause the
                      booking and allows them to provide an acceptable date/time
                      may be selected. It is provided in this regard that a
                      booking may remain on-hold for a maximum period of 3
                      (three) calendar months.
                    </li>
                    <li>
                      Should the Client reschedule the booking, the
                      considerations set forth in clause 2.3 above shall apply.
                    </li>
                    <li>
                      Should the Client elect to cancel the on-hold booking, the
                      standard cancellation policy (found here) shall apply.
                    </li>
                    <li>
                      Should the Client fail and/or neglect and/or refuse to
                      reschedule the booking within the timeframe referred to in
                      clause 3.1 above:
                    </li>
                    <ol>
                      <li>
                        1 (one) calendar month prior to the expiry of the
                        timeframe, My Smart City shall send an email
                        notification to the Client &#39;s registered email
                        address;
                      </li>
                      <li>
                        1 (one) calendar week prior to the expiry of the
                        timeframe, My Smart City shall send an email
                        notification to the Client&#39;s registered email
                        address; and
                      </li>
                      <li>
                        the on-hold booking shall then expire, and My Smart City
                        shall be entitled to deem such failure to be a
                        cancellation within the 12-hour timeframe as
                        contemplated in clause 2.2 above, and apply the
                        procedure set forth in that clause but without the
                        obligation to refund the Client any amount whatsoever.
                      </li>
                    </ol>
                  </ol>
                </li>
              </ol>
            </TermsContainer>
          </Col>
        </Row>
      </Section>
      <Footer />
    </>
  );
}
