import React, {
  useRef,
  useState,
  useMemo,
} from 'react';
import {
  Marker,
} from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';

export default function DraggableMarker({ center, handleChangeCurrentLocation }) {
  const [position, setPosition] = useState({
    lat: center.lat,
    lng: center.lng,
  });
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          console.log('DraggableMarker', marker.getLatLng());
          handleChangeCurrentLocation(marker.getLatLng());
        }
      },
    }),
    [],
  );
  const iconTemp = 'map/draggableMarker.svg';
  const icon = L.divIcon({
    className: 'custom icon',
    iconAnchor: [50, 60],
    html: ReactDOMServer.renderToString(
      <img style={{ height: '60px' }} src={iconTemp} alt={iconTemp} />,
    ),
  });

  return (
    <Marker
      draggable
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={icon}
    />
  );
}
