export type Review = {
  reviewer: string;
  review: string;
  date: string;
};
export const reviews: Review[] = [
  {
    reviewer: 'Nicole Greig',
    review:
      'This app is so user-friendly; it is so easy to report any municipal issues without having to wait in a queue on the phone, you can report issues instantly. This app is a game changer and highly recommend it !!',
    date: '07 April 2022',
  },
  {
    reviewer: 'Mambalana',
    review:
      'I would like to applaud you on your response to my complaints (Dustbins overflowing and not being collected for weeks; litter on the side of the road). They were resolved within 24 hrs. Please keep up the good work.',
    date: '23 June 2022',
  },
  {
    reviewer: 'Abieda Charles',
    review:
      'I read about Woodstock Community and how this app assisted them when reporting incidences in their community and am most impressed. I downloaded MySmart.City app to make a change in Bo-Kaap Community as we have many issues of a variety nature, from youth vandalism, drug trafficking, non-attendance teenagers of school, etc, etc',
    date: '06 May 2022',
  },
  {
    reviewer: 'Jenny Botha',
    review:
      'Awesome App. Now I can finally get some clarity on the issues in my area.',
    date: '10 April 2022',
  },
];
