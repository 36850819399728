import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { apiBaseUrlBase } from 'constants/app';

export const StyledCarousel = styled(Carousel)`
  @media only screen and (min-width: 800px) {
    .carousel .control-dots {
      margin: 0px 0px 0px 0px;
    }
  }

  .carousel {
    border-radius: 5px;
  }

  .carousel .control-dots {
    margin: 0px 0px 5px 0px;

    .dot {
      width: 5px;
      height: 5px;
      background: #656565;
      box-shadow: none;
      opacity: 1;

      @media only screen and (min-width: 800px) {
        width: 13px;
        height: 13px;
      }
    }
    .selected {
      background: white;
      opacity: 1;
    }
  }
`;

export const Slide = styled.div`
  background: url(${({ src }) => `"${src}"`}) no-repeat padding-box;
  background-position: center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Title = styled.h2`
  font: normal normal 500 20px/22px Roboto;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  
  @media only screen and (min-width: 800px) {
    font: normal normal 500 39px/46px Roboto;
    margin-bottom: 16px;
  }
`;

export const Subtitle = styled.p`
  font: normal normal normal 12px/15px Roboto;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
  
  @media only screen and (min-width: 800px) {
    font: normal normal normal 24px/29px Roboto;
  }
`;

export const Button = styled(Link)`
  display: flex;
  height: 25px;
  color: #0A0918;
  background: #FFFFFF;
  padding: 0 15px;
  transition: all 0.15s ease-out;
  border-radius: 12px;
  margin-top: auto;
  align-items: center;
  width: fit-content;

  &:hover {
    background: hsl(0, 0%, 95%);
    img {
      left: 5%;
    }
  }

  p {
    font: normal normal normal 10px/25px Roboto;
    color: #0A0918;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    margin-right: 15px;
  }

  img {
    width: 14px;
    height: 7px;
    position: relative;
    transition: all 0.15s ease-out;
    left: 0;
  }
  
  @media only screen and (min-width: 800px) {
    height: 45px;
    padding: 0 30px;
    border-radius: 23px;
    
    p {
      font: normal normal normal 19px/23px Roboto;
      margin-right: 15px;
    }

    img {
      width: 28px;
      height: 15px;
    }
  }
`;
export const BannerImage = styled.img`
  content:url(${apiBaseUrlBase}images/smartcitybannermobile?id=${(props) => (props.idPass)});
  @media only screen and (min-width: 800px) {
    content:url(${apiBaseUrlBase}images/smartcitybannerweb?id=${(props) => (props.idPass)});
  }
`;
