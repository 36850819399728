import { apiUrl } from 'constants/app';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const subcribeToWorkRequestUpdateNotifications = async (workRequestId) => fetch(
  `${apiUrl}smartcitymanager/subcribeToWorkRequestUpdateNotifications?workRequestId=${workRequestId}`,
  {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const unSubcribeToWorkRequestUpdateNotifications = async (workRequestId) => fetch(
  `${apiUrl}smartcitymanager/unSubcribeToWorkRequestUpdateNotifications?workRequestId=${workRequestId}`,
  {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const getNotificationSettings = async () => fetch(
  `${apiUrl}smartcitymanager/getNotificationSettings`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const getWorkRequest = async (id, subscriberId) => fetch(
  `${apiUrl}smartcitymanager/getWorkRequest?`
            + `id=${id}`
            + `&subscriberId=${subscriberId}`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
    },
  },
).then((res) => res.json()).catch((e) => {
  console.log(e);
  return {};
});

export const updateNotificationSettings = async (notificationSettings) => fetch(
  `${apiUrl}smartcitymanager/updateNotificationSettings`,
  {
    method: 'POST',
    async: true,
    body: notificationSettings,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const markNotificationRead = async (uuid) => fetch(
  `${apiUrl}smartcitymanager/markNotificationRead?uuid=${uuid}`,
  {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const deleteNotification = async (uuid) => fetch(
  `${apiUrl}smartcitymanager/deleteNotification?uuid=${uuid}`,
  {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const getNotifications = async () => fetch(
  `${apiUrl}smartcitymanager/getNotifications`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);

export const getUnreadNotifications = async () => fetch(
  `${apiUrl}smartcitymanager/getUnreadNotifications?limitCurrentCity=false`,
  {
    method: 'GET',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => {
    console.error(e);
    return -1;
  });

export const markAllNotificationsAsRead = async () => fetch(
  `${apiUrl}smartcitymanager/markAllNotificationsAsRead`,
  {
    method: 'POST',
    async: true,
    headers: {
      Accept: 'application/json, text/javascript; q=0.01',
      'Content-Type': 'application/problem+json',
      firebase_token: cookies.get('firebaseToken'),
    },
  },
)
  .then((res) => res.json())
  .catch((e) => e);
