import React from 'react';

import { MessageContainer } from './error.styled';

const BadRequestPage = () => (
  <MessageContainer>
    <h2>Oops! Something went wrong.</h2>
    <p>Please try again later.</p>
  </MessageContainer>
);

export default BadRequestPage;
