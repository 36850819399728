import styled from 'styled-components';

export const TopRow = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
  padding: 0 20px;
  flex-direction: column;
  align-items: flex-start;
  &::after {
    flex: 1;
    content: '';
  }
  @media only screen and (min-width: 800px) {
    margin-top: 22px;
    padding: 0 50px;
    flex-direction: row;
    align-items: center;
  }
  @media only screen and (min-width: 1000px) {
    /* padding: 0 75px; */
  }
  @media only screen and (min-width: 1400px) {
    padding: 0 100px;
  }
`;

export const Title = styled.h1`
  font: normal normal 500 20px/26px ${(props) => (props.theme.font)};
  color: ${(props) => (props.theme.colors.dark)};
  width: 100%;
  text-align: center;
  margin: 18px 0;

  @media only screen and (min-width: 800px) {
    margin: 35px 0;
  }
`;

// TODO: delete
export const CategoryBarContainer = styled.div`
  width: 100%;
  margin: 0 20px 12px 20px;
  @media only screen and (min-width: 800px) {
    margin: 0 20px 24px 20px;
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;
  overflow-x: auto;
  scrollbar-width: none;

  @media only screen and (min-width: 800px) {
    margin-bottom: 24px;
  }
`;

export const ContentContainer = styled.div`
  margin: 0 15px;

  @media only screen and (min-width: 800px) {
    margin: 0 75px;  
  }
`;

export const Banner = styled.div`
  height: 100px;
  width: 100%;

  background: lightgray url(${(props) => props.src}) no-repeat;
  background-position: center;
  background-size: cover;
`;

export const SearchContainer = styled.div`
  margin: 20px 20px 47px 20px;
  @media only screen and (min-width: 800px) {
    margin: 42px auto 20px auto;
    width: 780px;
  }
`;
